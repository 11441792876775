import { AppState } from 'app';
import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import { DispatchFn } from 'lib/duck/interfaces';
import { imgproxy } from 'lib/helpers';
import { Button } from 'lib/metronic/components';
import { ReceptionOrderStatus, VehicleReceptionOrder } from 'model';
import moment from 'moment';
import { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { vehicleReceptionOrderService } from 'services';
import {
  InspectionOrderPriorityLabel,
  InspectionOrderTypeLabel,
  OrgName,
  ReceptionOrderStatusLabel,
  ScopedTranslate,
  StringLabel,
  getString,
} from 'shared/components';
import { formatTime } from 'utils';
import { formatTimeElapsed } from 'utils/formatTimeElapsed';

interface Props {
  dispatch: DispatchFn<AppState>;
  order: VehicleReceptionOrder | null | undefined;
  onDelete: (order: VehicleReceptionOrder) => void;
  onRollback: (order: VehicleReceptionOrder) => void;
}

interface State {
  isRollingBack: boolean;
}

export class OrderDetailView extends Component<Props, State> {
  state: State = { isRollingBack: false };

  onDelete = () => {
    this.props.onDelete(this.props.order!);
  };

  onRollback = async () => {
    if (!this.props.order || this.state.isRollingBack) return;

    if (!confirm(getString('orders.modal.confirm_rollback.msg'))) {
      return;
    }

    const reason = prompt(getString('orders.rollback.reason_msg'))?.trim();
    if (!reason) {
      alert(getString('orders.rollback.reason_required'));
      return;
    }

    this.setState({ isRollingBack: true });

    this.props.dispatch(
      showAppLoading({
        status: 'loading',
        message: getString('orders.rollback.status.rolling_back'),
      }),
    );

    try {
      await vehicleReceptionOrderService.rollback(
        this.props.order.orderNo,
        reason,
      );
      this.props.dispatch(
        showAppLoading({
          status: 'success',
          message: getString('orders.rollback.status.success'),
          timeout: 3000,
        }),
      );
      this.props.onRollback(this.props.order!);
    } catch (e) {
      this.props.dispatch(hideAppLoading());
      console.error(e);
      alert(
        getString('orders.rollback.status.error', {
          errorMsg: (e as any).message,
        }),
      );
    } finally {
      this.setState({ isRollingBack: false });
    }
  };

  render() {
    const { order } = this.props;
    if (!order) return null;
    return (
      <ScopedTranslate scope="orders.detail.label">
        {({ trans }) => (
          <div className="order-detail">
            <dl>
              <dt>{trans('org_name')}</dt>
              <dd>
                <OrgName />
              </dd>
            </dl>
            <dl>
              <dt>{trans('store_name')}</dt>
              <dd>{order.storeName}</dd>
            </dl>
            <dl>
              <dt>{trans('order_no')}</dt>
              <dd>{order.orderNo}</dd>
            </dl>
            <dl>
              <dt>{trans('order_type')}</dt>
              <dd>
                <InspectionOrderTypeLabel value={order.orderType} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('vin')}</dt>
              <dd>
                {order.vin}
                {order.vinImgUrl && (
                  <p>
                    <a
                      href={imgproxy(order.vinImgUrl)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={order.vinImgUrl}
                        alt={order.vin}
                        style={{ width: 100 }}
                      />
                    </a>
                  </p>
                )}
              </dd>
            </dl>
            <dl>
              <dt>{trans('license_plate_no')}</dt>
              <dd>
                {order.licensePlateNo}
                {order.licensePlateNoImgUrl && (
                  <p>
                    <a
                      href={imgproxy(order.licensePlateNoImgUrl)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={order.licensePlateNoImgUrl}
                        alt={order.licensePlateNo}
                        style={{ width: 100 }}
                      />
                    </a>
                  </p>
                )}
              </dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_name')}</dt>
              <dd>{order.vehicleName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_brand')}</dt>
              <dd>{order.vehicleBrandName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_model')}</dt>
              <dd>{order.vehicleName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_mileage')}</dt>
              {order.vehicleMileage! < 1
                ? order.vehicleMileage! * 10000
                : order.vehicleMileage}{' '}
              <StringLabel value="value_unit.km" />
            </dl>
            <dl>
              <dt>{trans('customer_name')}</dt>
              <dd>{order.customerName}</dd>
            </dl>
            <dl>
              <dt>{trans('customer_mobile')}</dt>
              <dd>{order.customerMobile}</dd>
            </dl>
            <dl>
              <dt>{trans('org_staff_name')}</dt>
              <dd>{order.serviceAgentName}</dd>
            </dl>
            <dl>
              <dt>{trans('status')}</dt>
              <dd>
                <ReceptionOrderStatusLabel value={order.status} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('priority')}</dt>
              <dd>
                <InspectionOrderPriorityLabel value={order.priority} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('created_at')}</dt>
              <dd>{formatTime(order.createdAt)}</dd>
            </dl>
            {order.startedAt && (
              <dl>
                <dt>{trans('started_at')}</dt>
                <dd>{formatTime(order.startedAt)}</dd>
              </dl>
            )}
            {order.finishedAt && (
              <dl>
                <dt>{trans('finished_at')}</dt>
                <dd>{formatTime(order.finishedAt)}</dd>
              </dl>
            )}
            <dl>
              <dt>{trans('remark')}</dt>
              <dd>{order.remark}</dd>
            </dl>
            {typeof order.elapsedTime === 'number' && (
              <dl>
                <dt>{trans('elapsed_time')}</dt>
                <dd>
                  {formatTimeElapsed(
                    order.finishedAt != null
                      ? moment(order.finishedAt).diff(
                          moment(order.createdAt),
                          'second',
                        )
                      : order.elapsedTime * 3600,
                    trans,
                  )}
                </dd>
              </dl>
            )}
            {this.props.order?.status === ReceptionOrderStatus.Finished && (
              <Button
                block
                color="warning"
                onClick={this.onRollback}
                style={{ marginTop: '2rem' }}
                disabled={this.state.isRollingBack}
              >
                <Translate id="orders.rollback.button" />
              </Button>
            )}
            <Button
              block
              color="danger"
              onClick={this.onDelete}
              style={{ marginTop: '1rem' }}
            >
              <Translate id="orders.delete.button" />
            </Button>
          </div>
        )}
      </ScopedTranslate>
    );
  }
}
