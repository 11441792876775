/**
 * @file: SeverityLevelLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Badge, BadgeProps } from 'lib/metronic/components';
import { SeverityLevel } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { LabelColors } from '../LabelColors';

interface Props extends BadgeProps {
  value: SeverityLevel | null | undefined;
}

const Values = {
  [SeverityLevel.None]: 'none',
  [SeverityLevel.Slight]: 'slight',
  [SeverityLevel.Notice]: 'notice',
  [SeverityLevel.Warning]: 'warning',
  [SeverityLevel.Danger]: 'danger',
};

export const SeverityLevelLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  let enumValue = '';
  if (value !== null && value !== undefined) {
    enumValue = Values[value];
  }

  const labelColor =
    value && LabelColors.SeverityLevel?.[enumValue]
      ? LabelColors.SeverityLevel[enumValue]
      : color;

  return (
    <Badge inline color={labelColor} {...otherProps}>
      <Translate id={`severity_level.${enumValue}`} />
    </Badge>
  );
});
