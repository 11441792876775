/**
 * @file: InspectionToolService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { InspectionToolListFilter, InspectionTool, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class InspectionToolServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<InspectionToolListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<InspectionTool> : InspectionTool[]> {
    return await this.api.get()
      .url('/inspection-tools', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<InspectionTool | null> {
    return await this.api.get()
      .url('/inspection-tools/:id', { id })
      .future();
  }

  async getToolByCode(code: string): Promise<InspectionTool | null> {
    if (!code) {
      throw new Error('missing code parameter');
    }
    return await this.api.get()
      .url('/inspection-tools/query/code', { code })
      .future();
  }
}