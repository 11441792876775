/**
 * @file: VehicleInspectionTaskJobService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionTaskJobListFilter, VehicleInspectionTaskJob, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions, VehicleInspectionTaskJobDetail, VehicleInspectionTaskJobMedia } from 'model';

export class VehicleInspectionTaskJobServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskJobListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionTaskJob> : VehicleInspectionTaskJob[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-jobs', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionTaskJob | null> {
    return await this.api.get()
      .url('/vehicle-inspection-task-jobs/:id', { id })
      .future();
  }

  async create(vehicleInspectionTaskJob: Partial<VehicleInspectionTaskJob>): Promise<VehicleInspectionTaskJob> {
    return await this.api.post()
      .url('/vehicle-inspection-task-jobs')
      .data(vehicleInspectionTaskJob)
      .future();
  }

  async update(vehicleInspectionTaskJob: Partial<VehicleInspectionTaskJob>): Promise<VehicleInspectionTaskJob> {
    return await this.api.put()
      .url('/vehicle-inspection-task-jobs/:id', { id: vehicleInspectionTaskJob.id })
      .data(vehicleInspectionTaskJob)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/vehicle-inspection-task-jobs/:id', { id })
      .future();
  }

  async getDetailsByJobId(id: number, recursive = false): Promise<VehicleInspectionTaskJobDetail[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-jobs/:id/details', { id, recursive })
      .future();
  }

  async createVehicleInspectionTaskJobDetail(detail: Partial<VehicleInspectionTaskJobDetail>): Promise<VehicleInspectionTaskJobDetail> {
    const id = detail.jobId;
    if (!id) {
      throw new Error('detail.jobId is required. ');
    }
    return await this.api.post()
      .url('/vehicle-inspection-task-jobs/:id/details')
      .data(detail)
      .future();
  }

  async updateVehicleInspectionTaskJobDetail(detail: VehicleInspectionTaskJobDetail): Promise<VehicleInspectionTaskJobDetail> {
    const id = detail.jobId;
    if (!id) {
      throw new Error('detail.jobId is required. ');
    }
    return await this.api.put()
      .url('/vehicle-inspection-task-jobs/:id/details')
      .data(detail)
      .future();
  }

  async deleteVehicleInspectionTaskJobDetail(
    jobId: number | VehicleInspectionTaskJobDetail, detailId?: number
  ): Promise<void> {
    if (jobId && typeof jobId === 'object') {
      detailId = jobId.id;
      if (!jobId.jobId) {
        throw new Error('jobId cannot be null or undefined');
      }
      jobId = jobId.jobId;
    }
    if (!jobId || !detailId) {
      throw new Error('jobId and detailId are both required');
    }
    return void await this.api.delete()
      .url('/vehicle-inspection-task-jobs/:jobId/details/:detailId', { jobId, detailId })
      .future();
  }

  async getMediasByJobId(id: number, recursive = false): Promise<VehicleInspectionTaskJobMedia[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-jobs/:id/medias', { id, recursive })
      .future();
  }

  async createVehicleInspectionTaskJobMedia(media: Partial<VehicleInspectionTaskJobMedia>): Promise<VehicleInspectionTaskJobMedia> {
    const id = media.jobId;
    if (!id) {
      throw new Error('media.jobId is required. ');
    }
    return await this.api.post()
      .url('/vehicle-inspection-task-jobs/:id/medias')
      .data(media)
      .future();
  }

  async updateVehicleInspectionTaskJobMedia(media: VehicleInspectionTaskJobMedia): Promise<VehicleInspectionTaskJobMedia> {
    const id = media.jobId;
    if (!id) {
      throw new Error('media.jobId is required. ');
    }
    return await this.api.put()
      .url('/vehicle-inspection-task-jobs/:id/medias')
      .data(media)
      .future();
  }

  async deleteVehicleInspectionTaskJobMedia(
    jobId: number | VehicleInspectionTaskJobMedia, mediaId?: number
  ): Promise<void> {
    if (jobId && typeof jobId === 'object') {
      mediaId = jobId.id;
      if (!jobId.jobId) {
        throw new Error('jobId cannot be null or undefined');
      }
      jobId = jobId.jobId;
    }
    if (!jobId || !mediaId) {
      throw new Error('jobId and mediaId are both required');
    }
    return void await this.api.delete()
      .url('/vehicle-inspection-task-jobs/:jobId/medias/:mediaId', { jobId, mediaId })
      .future();
  }
}