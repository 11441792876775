import { CategoryPanel } from './CategoryPanel';
import { TemplatePanel } from './TemplatePanel';
import React, { memo } from 'react';

export const LeftSidebar = memo(() => {
  return (
    <div className="construction-tpl-man__sidebar">
      <CategoryPanel />
      <TemplatePanel />
    </div>
  );
});
