import { AppContext } from 'app/AppContext';
import { Identity, OrgMember, VehicleInspectionTemplate } from 'model';
import {
  InspectionTemplatePredefinedTypeOptions,
  InspectionTemplateSceneTypeOptions,
} from 'model/EnumOptions';
import { InspectionTemplateCustomization } from 'model/InspectionTemplateCustomization';
import { ChangeEvent, CSSProperties } from 'react';
import { Translate } from 'react-localize-redux';
import {
  getString,
  Select,
  StorePicker,
  withEntityEditorSidebarBuilder,
} from 'shared/components';
import { inspectionTemplateActions } from '../duck/actions';
import { Content as BarriersSettings } from './BarriersSettings';
import { TemplatesManagerProps } from './manager';

export const TemplateEditor =
  withEntityEditorSidebarBuilder<VehicleInspectionTemplate>()
    .withI18nPrefix('inspection_template')
    .withForm(builder =>
      builder
        .custom({
          label: 'inspection_template.editor.label.store',
          helpText: 'inspection_template.editor.help_text.store',
          key: 'store',
          render: ({ props }: { props: TemplatesManagerProps }) => {
            const { dispatch } = props;
            // tslint:disable-next-line: no-shadowed-variable
            const onChange = (storeId: number | undefined) => {
              if (props.templates.itemBeingCreated) {
                dispatch(
                  inspectionTemplateActions.itemBeingCreatedChanged({
                    storeId: storeId || undefined,
                  }),
                );
              } else {
                dispatch(
                  inspectionTemplateActions.itemBeingUpdatedChanged({
                    storeId: storeId || undefined,
                  }),
                );
              }
            };

            const storeId = props.templates.itemBeingCreated
              ? props.templates.itemBeingCreated.storeId
              : props.templates.itemBeingUpdated
                ? props.templates.itemBeingUpdated.storeId
                : undefined;

            const shouldDisable = (identity: Identity) => {
              return Boolean(
                identity.storeId && identity.visibleStores.length === 1,
              );
            };
            return (
              <AppContext.Consumer>
                {({ identity }) => (
                  <StorePicker
                    storeId={storeId}
                    disabled={shouldDisable(identity)}
                    onChange={onChange}
                  />
                )}
              </AppContext.Consumer>
            );
          },
        })
        .text({
          prop: 'name',
          label: 'inspection_template.editor.label.name',
          placeholder: 'inspection_template.editor.placeholder.name',
        })
        .select({
          prop: 'sceneType',
          options: InspectionTemplateSceneTypeOptions,
          label: 'inspection_template.editor.label.scene_type',
          placeholder: 'inspection_template.editor.placeholder.scene_type',
          helpText: 'inspection_template.editor.help_text.scene_type',
        })
        .select({
          prop: 'predefinedType',
          options: InspectionTemplatePredefinedTypeOptions,
          label: 'inspection_template.editor.label.predefined_type',
          placeholder: 'inspection_template.editor.placeholder.predefined_type',
        })
        .multiTextInput({
          prop: 'tags',
          label: 'inspection_template.editor.label.tags',
          placeholder: 'inspection_template.editor.placeholder.tags',
          serialize: values => values.map(x => x.trim()).join(','),
          deserialize: (value: string) =>
            value
              .split(/,/g)
              .map(x => x.trim())
              .filter(x => x),
        })
        .image({
          prop: 'icon',
          label: 'inspection_template.editor.label.icon',
          filePicker: {
            cover: false,
            mediaSize: 200,
            realm: 'template/icons',
            accept: {
              'image/*': ['.png', '.jpg', '.jpeg'],
            },
          },
        })
        .custom({
          label: 'inspection_template.editor.label.auto_service_subject',
          render: ({ props }: { props: TemplatesManagerProps }) => {
            if (
              !props.templates.itemBeingUpdated &&
              !props.templates.itemBeingCreated
            ) {
              return null;
            }
            const item =
              props.templates.itemBeingUpdated ||
              props.templates.itemBeingCreated;
            const customization: InspectionTemplateCustomization | undefined =
              item?.customization ? JSON.parse(item.customization) : undefined;
            const members = item?.storeId
              ? props.members.result?.filter(
                  x => x.storeId === item.storeId && x.role?.includes('tech'),
                )
              : [];
            const memberId =
              customization?.autoAddToOrderServiceSubjects?.assignTo?.id;

            const labelStyle: CSSProperties = {
              color: '#333',
              fontSize: '0.9rem',
              marginBottom: '0.5rem',
            };

            const onMemberChange = (member: OrgMember | undefined) => {
              const obj: InspectionTemplateCustomization = {
                ...customization,
                autoAddToOrderServiceSubjects: {
                  ...(customization?.autoAddToOrderServiceSubjects as any),
                  assignTo: member
                    ? {
                        id: member.id,
                        name: member.name,
                      }
                    : undefined,
                },
              };
              if (props.templates.itemBeingUpdated) {
                props.dispatch(
                  inspectionTemplateActions.itemBeingUpdatedChanged({
                    customization: JSON.stringify(obj),
                  }),
                );
              } else {
                props.dispatch(
                  inspectionTemplateActions.itemBeingCreatedChanged({
                    customization: JSON.stringify(obj),
                  }),
                );
              }
            };

            const onOrderTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
              const obj: InspectionTemplateCustomization = {
                ...customization,
                autoAddToOrderServiceSubjects: {
                  ...(customization?.autoAddToOrderServiceSubjects as any),
                  select: {
                    when: 'orderType',
                    matches: {
                      op: 'contains',
                      value: e.target.value,
                    },
                  },
                },
              };
              if (props.templates.itemBeingUpdated) {
                props.dispatch(
                  inspectionTemplateActions.itemBeingUpdatedChanged({
                    customization: JSON.stringify(obj),
                  }),
                );
              } else {
                props.dispatch(
                  inspectionTemplateActions.itemBeingCreatedChanged({
                    customization: JSON.stringify(obj),
                  }),
                );
              }
            };
            return (
              <div
                style={{
                  backgroundColor: '#fafafa',
                  border: '1px solid #ddd',
                  padding: '0.75rem 0.75rem',
                }}
              >
                <div style={labelStyle}>
                  <Translate id="inspection_template.editor.auto_service_subject_settings.when_order_type_contains" />
                </div>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    style={{ marginBottom: '1rem' }}
                    value={
                      customization?.autoAddToOrderServiceSubjects?.select
                        ?.matches.value ?? ''
                    }
                    onChange={onOrderTypeChange}
                  />
                </div>
                <div style={labelStyle}>
                  <Translate id="inspection_template.editor.auto_service_subject_settings.assign_to.label" />
                </div>
                <Select
                  isClearable
                  disabled={!item?.storeId}
                  values={members ?? []}
                  valueProp="id"
                  labelProp="name"
                  placeholder={getString(
                    'inspection_template.editor.auto_service_subject_settings.assign_to.placeholder',
                  )}
                  selectedValue={
                    memberId ? members?.find(x => x.id === memberId) : undefined
                  }
                  onChange={onMemberChange}
                />
              </div>
            );
          },
        })
        .custom({
          label: '',
          key: 'barriers',
          hidden: entity =>
            Boolean(!entity.id || entity.agentId || entity.storeId),
          render: ({ props }: { props: TemplatesManagerProps }) => {
            if (
              !props.templates.itemBeingUpdated ||
              props.templates.itemBeingUpdated.storeId
            ) {
              return null;
            }
            return (
              <BarriersSettings
                template={props.templates.itemBeingUpdated}
                style={{ padding: 0 }}
              />
            );
          },
        })
        .checkbox({
          prop: 'isSystemDefault',
          tick: true,
          color: 'brand',
          label: 'inspection_template.editor.label.is_system_default',
          checkedStyle: {
            tick: false,
            solid: true,
          },
        })
        .checkbox({
          prop: 'disabled',
          inverse: true,
          tick: true,
          color: 'brand',
          label: 'inspection_template.editor.label.enabled',
          checkedStyle: {
            tick: false,
            solid: true,
          },
        })
        .checkbox({
          prop: 'isHardwareInspectionEnabled',
          label:
            'inspection_template.editor.label.is_hardware_inspection_enabled',
        })
        .textArea({
          prop: 'description',
          label: 'inspection_template.editor.label.description',
          placeholder: 'inspection_template.editor.placeholder.description',
        })
        .text({
          prop: 'sortOrder',
          type: 'number',
          label: 'inspection_template.editor.label.sort_order',
          placeholder: 'inspection_template.editor.placeholder.sort_order',
        }),
    )
    .withValidator(template => {
      let code: string | undefined = undefined;
      if (!template.name?.trim()) {
        code = 'template_name_required';
      } else if (!template.sceneType) {
        code = 'scene_type_required';
      } else if (!template.predefinedType) {
        code = 'template_type_required';
      }
      if (code) {
        const msg = getString(`inspection_template.editor.error.${code}`);
        throw new Error(msg);
      }
    })
    .getClass();
