/**
 * @file: ServiceEnrollmentStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ServiceEnrollmentStatus {
  Active = 'active',
  Finished = 'finished',
}

export const ServiceEnrollmentStatusValueSet = new Set([
  ServiceEnrollmentStatus.Active,
  ServiceEnrollmentStatus.Finished,
]);
