import { store } from 'app/duck/store';
import {
  MpTemplateIndustry,
  OrgWeixinTemplateConfDetail,
  TemplateIndustrySettingInfo,
} from 'model';

export function normalizedIndustryName(name: string) {
  return name.replace(/\|/g, '/');
}

const templateIndustryNameMap = new Map<string, MpTemplateIndustry>();
const templateIndustryCodeMap = new Map<string, MpTemplateIndustry>();

export function getTemplateIndustryNameKey(
  firstClass: string,
  secondClass: string,
) {
  return firstClass + ':' + normalizedIndustryName(secondClass);
}

export function formatTemplateIndustryDisplayName(
  firstClass: string,
  secondClass: string,
) {
  return [firstClass, secondClass.replace(/\//, ',')].filter(x => x).join('/');
}

export function subscribeTemplateIndustryList() {
  return store.subscribe(() => {
    const state = store.getState();
    if (state.integration.templateIndustries.result) {
      for (const item of state.integration.templateIndustries.result) {
        const name = getTemplateIndustryNameKey(
          item.firstClass,
          item.secondClass,
        );
        templateIndustryNameMap.set(name, item);
        templateIndustryCodeMap.set(item.industryNo, item);
      }
    }
  });
}

export function getTemplateIndustryByCode(
  code: string,
): MpTemplateIndustry | undefined {
  return templateIndustryCodeMap.get(code);
}

export function getTemplateIndustryCodeByName(
  firstClass: string,
  secondClass: string,
): MpTemplateIndustry | undefined {
  const key = getTemplateIndustryNameKey(firstClass, secondClass);
  return templateIndustryNameMap.get(key);
}

export function isTemplateIndustrySet(
  setting?: TemplateIndustrySettingInfo | null,
) {
  return setting?.firstClass && setting.secondClass;
}

export function parseTemplateParams(content: string): string[] {
  const regex = /{{(.+?)\.DATA}}/g;
  let match: RegExpMatchArray | null = null;
  const params: string[] = [];
  // tslint:disable-next-line: no-conditional-assignment
  while ((match = regex.exec(content))) {
    params.push(match[1]);
  }
  return params;
}

export function applyTemplateParamsExample(
  content: string,
  example: { [property: string]: string },
  conf: OrgWeixinTemplateConfDetail,
): string {
  return content.replace(/{{(.+?)\.DATA}}/g, (m, param) => {
    const property = conf.mapping[param];
    if (!property || !example[property]) return m;
    return example[property];
  });
}
