import { constructionTplActions } from 'app/inspection/duck/actions';
import {
  ConstructionTemplateCategory,
  ConstructionTemplateGroup,
  ConstructionTemplateWorkItem,
} from 'model';
import { nanoid } from 'nanoid/non-secure';
import {
  ChangeEvent,
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { commonService } from 'services';
import { getString } from 'shared/components';
import { array_move } from 'utils';
import {
  WithFullAccess,
  useHasFullAccess,
} from '../../shared/components/WithFullAccess';
import { useConstructionTemplateConfigContext } from './Context';
import { Group } from './Group';
import { GroupModal } from './GroupModal';
import { ItemModal } from './ItemModal';
import { TemplatePicker } from './TemplatePicker';

export const CategoryDetail = memo(
  ({ category }: { category: ConstructionTemplateCategory }) => {
    const { templateConfig } = useConstructionTemplateConfigContext();
    const dispatch = useDispatch();
    const categoryId = category.id;
    const hasFullAccess = useHasFullAccess();

    const [groupBeingEdited, setGroupBeingEdited] =
      useState<ConstructionTemplateGroup>();

    const [itemBeingEdited, setItemBeingEdited] = useState<
      ConstructionTemplateWorkItem & { groupId: string }
    >();

    useEffect(() => {
      setGroupBeingEdited(undefined);
    }, [categoryId]);

    const updateCategory = useCallback(
      (update: (category: ConstructionTemplateCategory) => void) => {
        dispatch(
          constructionTplActions.applyChangesToTemplateConfig(draft => {
            const targetCategory = draft.categories.find(
              x => x.id === categoryId,
            );
            if (targetCategory) {
              update(targetCategory);
            }
          }),
        );
      },
      [categoryId, dispatch],
    );

    const onNameChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        updateCategory(x => (x.name = e.target.value));
      },
      [updateCategory],
    );

    const onDescriptionChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        updateCategory(x => (x.description = e.target.value));
      },
      [updateCategory],
    );

    const onTemplateChange = useCallback(
      (templateId: string | undefined) => {
        updateCategory(x => (x.templateId = templateId));
      },
      [updateCategory],
    );

    const onMoveGroupUp = useCallback(
      ({ id }: ConstructionTemplateGroup) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        updateCategory(category => {
          const index = category.groups.findIndex(x => x.id === id);
          if (index > 0) {
            array_move(category.groups, index, index - 1);
          }
        });
      },
      [updateCategory],
    );

    const onMoveGroupDown = useCallback(
      ({ id }: ConstructionTemplateGroup) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        updateCategory(category => {
          const index = category.groups.findIndex(x => x.id === id);
          if (index < category.groups.length - 1) {
            array_move(category.groups, index, index + 1);
          }
        });
      },
      [updateCategory],
    );

    const onAddWorkItem = useCallback(
      ({ id }: ConstructionTemplateGroup, name: string) => {
        commonService
          .getPyInitial(name)
          .then(pyInitial => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            updateCategory(category => {
              const group = category.groups.find(x => x.id === id);
              if (!group) return;
              group.items.push({
                id: nanoid(8),
                name,
                pyInitial,
              });
            });
          })
          .catch(err => {
            alert(err.message);
          });
      },
      [updateCategory],
    );

    const onGroupSorted = useCallback(
      (e: { oldIndex: number; newIndex: number }) => {
        const { oldIndex, newIndex } = e;
        if (oldIndex === newIndex) return;
        // eslint-disable-next-line @typescript-eslint/no-shadow
        updateCategory(category => {
          array_move(category.groups, oldIndex, newIndex);
        });
      },
      [updateCategory],
    );

    const onCancelGroupModal = useCallback(() => {
      setGroupBeingEdited(undefined);
    }, []);

    const onSaveGroupInfo = useCallback(
      ({
        id,
        name,
        description,
        templateId,
      }: {
        id: string;
        name: string;
        description?: string;
        templateId?: string;
      }) => {
        setGroupBeingEdited(undefined);
        // eslint-disable-next-line @typescript-eslint/no-shadow
        updateCategory(category => {
          if (!id) {
            // new group.
            category.groups.push({
              id: nanoid(8),
              name,
              description,
              templateId,
              items: [],
            });
          } else {
            // update group
            const group = category.groups.find(x => x.id === id);
            if (!group) return;
            group.name = name;
            group.description = description;
            group.templateId = templateId;
          }
        });
      },
      [updateCategory],
    );

    const onEditGroup = useCallback((group: ConstructionTemplateGroup) => {
      setGroupBeingEdited(group);
    }, []);

    const onAddGroup = useCallback((e: MouseEvent) => {
      e.preventDefault();
      setGroupBeingEdited({
        id: '',
        name: getString('construction_tpl.group.default_name'),
        items: [],
      });
    }, []);

    const onRemoveGroup = useCallback(
      ({ id }: ConstructionTemplateGroup) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        updateCategory(category => {
          const index = category.groups.findIndex(x => x.id === id);
          if (index >= 0) {
            category.groups.splice(index, 1);
          }
        });
      },
      [updateCategory],
    );

    const onCancelItemModal = useCallback(() => {
      setItemBeingEdited(undefined);
    }, []);

    const onSaveItemInfo = useCallback(
      ({
        id,
        groupId,
        name,
        templateId,
      }: {
        id: string;
        groupId: string;
        name: string;
        templateId?: string;
      }) => {
        setItemBeingEdited(undefined);
        // eslint-disable-next-line @typescript-eslint/no-shadow
        updateCategory(category => {
          // update group
          const group = category.groups.find(x => x.id === groupId);
          if (!group) return;
          const item = group.items.find(x => x.id === id);
          if (!item) return;
          item.name = name;
          item.templateId = templateId;
        });
      },
      [updateCategory],
    );

    const onItemClick = useCallback(
      (item: ConstructionTemplateWorkItem, groupId: string) => {
        setItemBeingEdited({
          ...item,
          groupId,
        });
      },
      [],
    );

    const onItemRemoved = useCallback(
      ({ id }: ConstructionTemplateWorkItem, groupId: string) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        updateCategory(category => {
          const group = category.groups.find(x => x.id === groupId);
          if (!group) return;
          const index = group.items.findIndex(x => x.id === id);
          if (index >= 0) {
            group.items.splice(index, 1);
          }
        });
      },
      [updateCategory],
    );

    const onItemMoved = useCallback(
      (
        { id: groupId }: ConstructionTemplateGroup,
        from: number,
        to: number,
      ) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        updateCategory(category => {
          const group = category.groups.find(x => x.id === groupId);
          if (!group) return;
          array_move(group.items, from, to);
        });
      },
      [updateCategory],
    );

    return (
      <>
        <div className="construction-tpl-man__detail">
          <dl>
            <dt>
              <Translate id="construction_tpl.category.label.name" />
            </dt>
            <dd>
              <input
                type="text"
                name="name"
                className="form-control"
                value={category.name}
                onChange={onNameChange}
                readOnly={!hasFullAccess}
                placeholder={getString(
                  'construction_tpl.category.placeholder.name',
                )}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <Translate id="construction_tpl.category.label.description" />
            </dt>
            <dd>
              <input
                type="text"
                name="description"
                className="form-control"
                value={category.description ?? ''}
                readOnly={!hasFullAccess}
                onChange={onDescriptionChange}
                placeholder={getString(
                  'construction_tpl.category.placeholder.description',
                )}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <Translate id="construction_tpl.category.label.template" />
            </dt>
            <dd>
              <TemplatePicker
                templates={templateConfig.templates}
                value={category.templateId}
                disabled={!hasFullAccess}
                onChange={onTemplateChange}
                placeholder={getString(
                  'construction_tpl.category.placeholder.template',
                )}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <Translate id="construction_tpl.category.label.groups" />
              <WithFullAccess>
                <a
                  href="#"
                  onClick={onAddGroup}
                  style={{ marginLeft: '0.25rem' }}
                >
                  <i className="la la-plus" />
                </a>
              </WithFullAccess>
            </dt>
            {category.groups.length === 0 ? (
              <dd>
                <Translate id="construction_tpl.group.empty_text" />
              </dd>
            ) : null}
          </dl>
          <SortableGroupList
            category={category}
            lockAxis="y"
            helperClass="construction-tpl-man__group construction-tpl-man__group--being-dragged"
            useWindowAsScrollContainer={true}
            useDragHandle
            distance={3}
            onSortEnd={onGroupSorted}
            onEditGroup={onEditGroup}
            onRemoveGroup={onRemoveGroup}
            onMoveGroupDown={onMoveGroupDown}
            onMoveGroupUp={onMoveGroupUp}
            onAddWorkItem={onAddWorkItem}
            onItemClick={onItemClick}
            onItemMove={onItemMoved}
            onItemRemove={onItemRemoved}
          />
        </div>
        <GroupModal
          key={`${categoryId}-group-modal`}
          group={groupBeingEdited}
          onCancel={onCancelGroupModal}
          onConfirm={onSaveGroupInfo}
        />
        <ItemModal
          key={`${categoryId}-item-modal`}
          item={itemBeingEdited}
          groupId={itemBeingEdited?.groupId}
          onCancel={onCancelItemModal}
          onConfirm={onSaveItemInfo}
        />
      </>
    );
  },
);

const GroupList = memo(
  ({
    category,
    onEditGroup,
    onRemoveGroup,
    onMoveGroupUp,
    onMoveGroupDown,
    onAddWorkItem,
    onItemClick,
    onItemRemove,
    onItemMove,
  }: {
    category: ConstructionTemplateCategory;
    onEditGroup?: (group: ConstructionTemplateGroup) => void;
    onRemoveGroup?: (group: ConstructionTemplateGroup) => void;
    onMoveGroupUp?: (group: ConstructionTemplateGroup) => void;
    onMoveGroupDown?: (group: ConstructionTemplateGroup) => void;
    onAddWorkItem?: (group: ConstructionTemplateGroup, name: string) => void;
    onItemClick?: (item: ConstructionTemplateWorkItem, groupId: string) => void;
    onItemRemove?: (
      item: ConstructionTemplateWorkItem,
      groupId: string,
    ) => void;
    onItemMove?: (
      group: ConstructionTemplateGroup,
      from: number,
      to: number,
    ) => void;
  }) => {
    return (
      <div className="construction-tpl-man__group-list">
        {category.groups.map((group, index) => (
          <SortableGroup
            key={group.id}
            index={index}
            group={group}
            onEdit={onEditGroup}
            onRemove={onRemoveGroup}
            onMoveDown={
              index < category.groups.length - 1 ? onMoveGroupDown : undefined
            }
            onMoveUp={index > 0 ? onMoveGroupUp : undefined}
            onAddWorkItem={onAddWorkItem}
            onItemClick={onItemClick}
            onItemMove={onItemMove}
            onItemRemove={onItemRemove}
          />
        ))}
      </div>
    );
  },
);

const SortableGroupList = SortableContainer(GroupList);
const SortableGroup = SortableElement(Group);
