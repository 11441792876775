/**
 * @file: modal.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { AppModalState } from '../states';
import { ActionTypes } from '../types';

const initialState: AppModalState = {
  isOpen: false,
  title: '',
  message: '',
};

export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AppModalState = initialState,
  action: StandardAction<any>,
): AppModalState {
  switch (action.type) {
    case ActionTypes.ShowAppModal: {
      const { title, message, onClose } = action.payload as {
        title: string;
        message: string;
        onClose?: () => void;
      };
      return { ...state, isOpen: true, title, message, onClose };
    }
    case ActionTypes.CloseAppModal: {
      return { ...state, isOpen: false, title: '', message: '' };
    }
    default:
      return state;
  }
}
