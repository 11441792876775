import {
  TemplateProperty,
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
} from './types';

export class ServiceUpdatedTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  getOverriddenProperties(): TemplateProperty[] {
    return ['startedAt', 'updatedAt', 'updateDetail'].map(x => ({
      property: x,
      label: `integration.templates.props.service_updated.${x}`,
    }));
  }

  getOverriddenExample(destType: TemplateMsgDestType) {
    if (destType === 'customer') {
      return {
        title: '车主您好，您的爱车服务进度已有更新，点击查看更多服务进度详情',
        remark: '若对本次服务有任何疑问，请联系门店服务顾问',
        status: '服务已更新',
      };
    }
    return {
      title: '您所服务的车辆进度已有更新，请及时跟进服务进度',
      remark: '点击进入查看服务进度详情',
      status: '服务已更新',
    };
  }
}
