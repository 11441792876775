/**
 * @file: TaskAssignmentTargetTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, TaskAssignmentTargetType } from 'model';

export const TaskAssignmentTargetTypeOptions: Array<
  Option<TaskAssignmentTargetType>
> = [
  {
    value: TaskAssignmentTargetType.PreInspection,
    label: 'task_assignment_target_type.pre_inspection',
  },
  {
    value: TaskAssignmentTargetType.Inspection,
    label: 'task_assignment_target_type.inspection',
  },
  {
    value: TaskAssignmentTargetType.Construction,
    label: 'task_assignment_target_type.construction',
  },
  {
    value: TaskAssignmentTargetType.DeliveryCheck,
    label: 'task_assignment_target_type.delivery_check',
  },
];

export const TaskAssignmentTargetTypeOptionsWithDefault: Array<
  Option<TaskAssignmentTargetType | null>
> = [
  { value: null, label: 'task_assignment_target_type.__default__' },
  {
    value: TaskAssignmentTargetType.PreInspection,
    label: 'task_assignment_target_type.pre_inspection',
  },
  {
    value: TaskAssignmentTargetType.Inspection,
    label: 'task_assignment_target_type.inspection',
  },
  {
    value: TaskAssignmentTargetType.Construction,
    label: 'task_assignment_target_type.construction',
  },
  {
    value: TaskAssignmentTargetType.DeliveryCheck,
    label: 'task_assignment_target_type.delivery_check',
  },
];
