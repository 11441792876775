import { createKeyedAsyncListActionReducers } from 'lib/duck/reducers';
import { WeixinPermanentMaterialListItem, WeixinPermanentMaterialType } from 'model';
import { WeixinPermanentMaterials } from '../states';

const initialState: WeixinPermanentMaterials = {
  keyedLists: {},
  currentKey: undefined
};

export default createKeyedAsyncListActionReducers<
  WeixinPermanentMaterialListItem,
  WeixinPermanentMaterials,
  WeixinPermanentMaterialType,
  any,
  string
  >('integration.materials', initialState, {
    pagingMode: 'normal',
    mapItemKey(item: WeixinPermanentMaterialListItem) { return item.mediaId; },
    getInitialStateForKeyedList(key: WeixinPermanentMaterialType) {
      let limit = 8;
      if (key === WeixinPermanentMaterialType.Image) {
        limit = 15;
      }
      return { result: null, isLoading: false, error: null, offset: 0, limit };
    }
  });