import React, { Component } from 'react';
import { ToolbarItemsBuilder, ToolbarItem, ListToolbar } from 'shared/components';
import { InspectionSiteNodeFilter } from 'model';

interface Props {
  filter: Partial<InspectionSiteNodeFilter>;
  onAddCategory: () => void;
  onFilterChange: (filter: Partial<InspectionSiteNodeFilter>) => void;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}

export class InspectionSiteToolbar extends Component<Props> {
  private readonly items: Array<ToolbarItem<any>> = [];

  constructor(props: Props) {
    super(props);

    const builder = new ToolbarItemsBuilder<InspectionSiteNodeFilter>();
    this.items = builder.text({
      prop: 'keyword',
      label: 'inspection_site.toolbar.label.keyword',
      placeholder: 'inspection_site.toolbar.placeholder.keyword',
      immediate: true
    }).button({
      text: 'inspection_site.toolbar.button.add_category',
      color: 'brand',
      onClick: () => {
        this.props.onAddCategory();
      }
    }).buttonGroup(group => group
      .withPlacement('right')
      .button({
        key: 'expand_all',
        text: 'inspection_site.toolbar.button.expand_all',
        color: 'secondary',
        onClick: () => {
          this.props.onExpandAll();
        }
      })
      .button({
        key: 'collapse_all',
        text: 'inspection_site.toolbar.button.collapse_all',
        color: 'secondary',
        onClick: () => {
          this.props.onCollapseAll();
        }
      })
    ).build();
  }

  render() {
    return (
      <ListToolbar
        filter={this.props.filter}
        items={this.items}
        onFilterChange={this.onFilterChange}
        onGetExtraInfo={this.onGetExtraInfo}
        />
    );
  }

  onFilterChange = (filter: Partial<InspectionSiteNodeFilter>) => {
    this.props.onFilterChange(filter);
  }

  onGetExtraInfo = () => this.props
}


