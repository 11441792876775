import extend from 'extend';
import { StandardAction } from 'lib/duck/interfaces';
import {
  DeliveryCheckTemplateConf,
  DeliveryCheckTemplateItem,
  DeliveryCheckTemplateItemOption,
} from 'model';
import { array_moved, guid, setAdded, setRemoved } from 'utils';
import {
  DeliveryCheckTemplateDetail,
  DeliveryCheckTemplateDetailValidationInfo,
} from '../states';
import { ActionTypes } from '../types';

const initialState: DeliveryCheckTemplateDetail = {
  checkedOptionSet: new Set(),
  validationResult: {},
};

export const DefaultDeliveryCheckTemplateItemOptions: DeliveryCheckTemplateItemOption[] =
  [
    {
      id: guid('n'),
      title: '是',
      isDefaultChecked: true,
      remark: '',
    },
    {
      id: guid('n'),
      title: '否',
      isDefaultChecked: false,
      remark: '',
    },
  ];

export default function (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: DeliveryCheckTemplateDetail = initialState,
  action: StandardAction<any>,
): DeliveryCheckTemplateDetail {
  switch (action.type) {
    case ActionTypes.DeliveryCheckTemplateDetailReady: {
      const { templateId, conf } = action.payload as {
        templateId: number;
        conf: DeliveryCheckTemplateConf;
      };
      return {
        ...state,
        templateId,
        conf,
        // reset detail states
        isSaving: false,
        saveError: undefined,
        itemIdBeingRemoved: undefined,
        optionIdBeingRemoved: undefined,
        checkedOptionSet: new Set(),
      };
    }

    case ActionTypes.ApplyDefaultDeliveryCheckTemplateConf: {
      return {
        ...state,
        conf: extend(true, {}, action.payload),
      };
    }

    case ActionTypes.ValidateDeliveryCheckTemplateDetail: {
      return {
        ...state,
        validationResult: action.payload,
      };
    }

    case ActionTypes.AddDeliveryCheckTemplateItem: {
      const item: DeliveryCheckTemplateItem = {
        id: guid('n'),
        subject: '',
        requiresPhoto: false,
        options: DefaultDeliveryCheckTemplateItemOptions,
      };
      return stateWithItemAdded(state, item);
    }

    case ActionTypes.DeliveryCheckTemplateItemChanged: {
      const { itemId, updateInfo } = action.payload as {
        itemId: string;
        updateInfo: Partial<DeliveryCheckTemplateItem>;
      };
      return stateWithItemUpdated(state, itemId, updateInfo, true);
    }

    case ActionTypes.RemoveDeliveryCheckTemplateItem:
      return {
        ...state,
        itemIdBeingRemoved: action.payload,
      };

    case ActionTypes.RemoveDeliveryCheckTemplateItemConfirmed:
      if (!state.itemIdBeingRemoved) return state;
      return {
        ...stateWithItemRemoved(state, state.itemIdBeingRemoved),
        itemIdBeingRemoved: undefined,
      };

    case ActionTypes.RemoveDeliveryCheckTemplateItemCancelled:
      return {
        ...state,
        itemIdBeingRemoved: undefined,
      };

    case ActionTypes.DeliveryCheckTemplateItemMoved: {
      const { from, to } = action.payload as {
        from: number;
        to: number;
      };
      const items = array_moved(state.conf!.items, from, to);
      return {
        ...state,
        conf: { items },
      };
    }

    case ActionTypes.AddDeliveryCheckTemplateItemOption: {
      const option: DeliveryCheckTemplateItemOption = {
        id: guid('n'),
        title: '',
        isDefaultChecked: false,
      };
      return stateWithItemOptionAdded(state, action.payload.itemId, option);
    }

    case ActionTypes.DeliveryCheckTemplateItemOptionChanged:
      return stateWithItemOptionUpdated(
        state,
        action.payload.itemId,
        action.payload.optionId,
        action.payload.updateInfo,
      );

    case ActionTypes.DeliveryCheckTemplateItemOptionDefaultCheckChanged: {
      const { itemId, optionId, checked } = action.payload;
      const i = state.conf!.items.findIndex(x => x.id === itemId);
      const item = { ...state.conf!.items[i] };
      const options = item.options.map(x => ({ ...x }));
      const j = options.findIndex(x => x.id === optionId);
      if (checked) options.forEach(x => (x.isDefaultChecked = false));
      options[j].isDefaultChecked = checked;
      return stateWithItemUpdated(state, itemId, { options });
    }

    case ActionTypes.DeliveryCheckTemplateItemOptionIsExpectedChanged: {
      const { itemId, optionId, checked } = action.payload;
      const i = state.conf!.items.findIndex(x => x.id === itemId);
      const item = { ...state.conf!.items[i] };
      const options = item.options.map(x => ({ ...x }));
      const j = options.findIndex(x => x.id === optionId);
      options[j].isExpected = checked;
      return stateWithItemUpdated(state, itemId, { options });
    }

    case ActionTypes.RemoveDeliveryCheckTemplateItemOption:
      return {
        ...state,
        optionIdBeingRemoved: action.payload,
      };

    case ActionTypes.RemoveDeliveryCheckTemplateItemOptionConfirmed: {
      const optionId = state.optionIdBeingRemoved!;
      const item = state.conf!.items.find(x =>
        x.options.some(y => y.id === optionId),
      );
      if (!item) return state;
      return {
        ...stateWithItemOptionRemoved(state, item.id, optionId),
        optionIdBeingRemoved: undefined,
      };
    }

    case ActionTypes.RemoveDeliveryCheckTemplateItemOptionCancelled:
      return {
        ...state,
        optionIdBeingRemoved: undefined,
      };
  }

  return state;
}

function stateWithItemAdded(
  state: DeliveryCheckTemplateDetail,
  item: DeliveryCheckTemplateItem,
): DeliveryCheckTemplateDetail {
  const conf: DeliveryCheckTemplateConf = state.conf
    ? { items: state.conf.items.slice() }
    : {
        items: [],
      };
  conf.items.push(item);
  return {
    ...state,
    conf,
    checkedOptionSet: setAdded(state.checkedOptionSet, item.options[0].id),
  };
}

function stateWithItemUpdated(
  state: DeliveryCheckTemplateDetail,
  itemId: string,
  updateInfo: Partial<DeliveryCheckTemplateItem>,
  validate?: boolean,
): DeliveryCheckTemplateDetail {
  const conf: DeliveryCheckTemplateConf = state.conf
    ? { items: state.conf.items.slice() }
    : {
        items: [],
      };
  const idx = conf.items.findIndex(x => x.id === itemId);
  if (idx < 0) return state;

  const item = { ...conf.items[idx] };
  conf.items[idx] = Object.assign(item, updateInfo);

  if (validate) {
    return deliveryCheckTemplateDetailValidated({
      ...state,
      conf,
    });
  }

  return {
    ...state,
    conf,
  };
}

function stateWithItemRemoved(
  state: DeliveryCheckTemplateDetail,
  itemId: string,
): DeliveryCheckTemplateDetail {
  const conf: DeliveryCheckTemplateConf = state.conf
    ? { items: state.conf.items.slice() }
    : {
        items: [],
      };
  const idx = conf.items.findIndex(x => x.id === itemId);
  if (idx < 0) return state;

  const checkedOption = conf.items[idx].options.find(x =>
    state.checkedOptionSet.has(x.id),
  );
  conf.items.splice(idx, 1);

  return {
    ...state,
    conf,
    checkedOptionSet: checkedOption
      ? setRemoved(state.checkedOptionSet, checkedOption.id)
      : state.checkedOptionSet,
  };
}

function stateWithItemOptionAdded(
  state: DeliveryCheckTemplateDetail,
  itemId: string,
  option: DeliveryCheckTemplateItemOption,
): DeliveryCheckTemplateDetail {
  const item = state.conf!.items.find(x => x.id === itemId)!;
  const options = [...item.options, option];
  state = stateWithItemUpdated(state, itemId, { options });
  const key = `item.${itemId}.options.required`;
  if (state.validationResult[key] && options.length > 1) {
    return {
      ...state,
      validationResult: Object.keys(state.validationResult).reduce<any>(
        (acc, k) => {
          if (k !== key) acc[k] = state.validationResult[k];
          return acc;
        },
        {},
      ),
    };
  }
  return state;
}

function stateWithItemOptionUpdated(
  state: DeliveryCheckTemplateDetail,
  itemId: string,
  optionId: string,
  updateInfo: Partial<DeliveryCheckTemplateItemOption>,
): DeliveryCheckTemplateDetail {
  const item = state.conf!.items.find(x => x.id === itemId)!;
  const options = item.options.map(x =>
    x.id === optionId ? { ...x, ...updateInfo } : x,
  );
  return deliveryCheckTemplateDetailValidated(
    stateWithItemUpdated(state, itemId, { options }),
  );
}

function stateWithItemOptionRemoved(
  state: DeliveryCheckTemplateDetail,
  itemId: string,
  optionId: string,
): DeliveryCheckTemplateDetail {
  const item = state.conf!.items.find(x => x.id === itemId)!;
  const idx = item.options.findIndex(x => x.id === optionId);
  if (idx < 0) return state;
  const options = [...item.options];
  options.splice(idx, 1);
  return deliveryCheckTemplateDetailValidated(
    stateWithItemUpdated(state, itemId, { options }),
  );
}

export function deliveryCheckTemplateDetailValidated(
  detail: DeliveryCheckTemplateDetail,
) {
  const validationResult: DeliveryCheckTemplateDetailValidationInfo = {};
  if (detail.conf) {
    for (const item of detail.conf.items) {
      if (!item.subject.trim()) {
        validationResult[`item.${item.id}.subject.required`] = true;
      }
      if (item.options.length < 2) {
        validationResult[`item.${item.id}.options.required`] = true;
      }
      for (const option of item.options) {
        if (!option.title.trim()) {
          validationResult[`option.${option.id}.title.required`] = true;
        }
      }
    }
  }
  return {
    ...detail,
    validationResult,
  };
}
