import { createAsyncObjectMapActionReducers } from 'lib/duck/reducers';
import { OrgUserAccessRights } from 'model';
import { OrgUsersAcl } from '../states';

const initialState: OrgUsersAcl = {
  result: {},
  statusMap: {}
};

export default createAsyncObjectMapActionReducers<number, OrgUserAccessRights, OrgUsersAcl>(
  'org.users.acl', initialState
  );