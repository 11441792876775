import {
  AppState,
  TransFunction,
  getDateRangePickerLocale,
  getDefaultInputRanges,
  getDefaultStaticRanges,
} from 'app';
import { fetchAreas } from 'app/duck/actions';
import { History } from 'history';
import { RouteViewProps } from 'lib';
import { DispatchFn } from 'lib/duck/interfaces';
import { Dropdown, Page, Portlet } from 'lib/metronic/components';
import { AclObjectList, DatePartitionType, DateRange } from 'model';
import moment from 'moment';
import { Component } from 'react';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import {
  Translate,
  TranslateFunction,
  getTranslate,
} from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  dashboardActiveStatAspectChanged,
  fetchDashboardCompanionSummaryStats,
  fetchDashboardRecentLists,
  fetchDashboardSummaryStats,
  invalidateDashboardCompanionSummaryStats,
  invalidateDashboardCurrentRecentList,
  invalidateDashboardSummaryStats,
  updateDashboardDateRange,
  updateStatsByTimeDatePartitionType,
} from './duck/actions';
import { StatAspect, SummaryStatsState } from './duck/states';
import {
  RecentListsConfiguations,
  RecentListsProps,
} from './RecentListsConfig';
import { StatAspectList } from './StatAspectList';
import {
  StatsByTimeProps,
  fetchStatAspectStatsByTime,
  getStatsByTimeByStatAspect,
  invalidateStatAspectStatsByTime,
} from './StatAspectsConfig';
import { StatsByTimeChart } from './StatsByTimeChart';

import { getString } from 'shared/components';
import './Dashboard.scss';

interface Props extends StatsByTimeProps, RecentListsProps {
  trans: TransFunction;
  translate: TranslateFunction;
  history: History;
  dispatch: DispatchFn<AppState>;

  dateRange: DateRange;
  activeStatAspect: StatAspect;

  summaryStats: SummaryStatsState;
  companionSummaryStats: SummaryStatsState;
}

interface State {
  dummy?: string;
}

const mapStateToProps = (state: AppState, ownProps: RouteViewProps) => {
  return {
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    history: ownProps.history,
    areas: state.areas,
    dateRange: state.dashboard.dateRange,
    activeStatAspect: state.dashboard.activeStatAspect,
    summaryStats: state.dashboard.summaryStats,
    companionSummaryStats: state.dashboard.companionSummaryStats,
    customerStatsByTime: state.dashboard.customerStatsByTime,
    userStatsByTime: state.dashboard.userStatsByTime,
    receptionOrderStatsByTime: state.dashboard.receptionOrderStatsByTime,
    inspectionTaskStatsByTime: state.dashboard.inspectionTaskStatsByTime,
    inspectionReportStatsByTime: state.dashboard.inspectionReportStatsByTime,
    inspectionCommentStatsByTime: state.dashboard.inspectionCommentStatsByTime,
    recentCustomers: state.dashboard.recentCustomers,
    recentSubscribedUsers: state.dashboard.recentSubscribedUsers,
    recentReceptionOrders: state.dashboard.recentReceptionOrders,
    recentInspectionTasks: state.dashboard.recentInspectionTasks,
    recentInspectionReports: state.dashboard.recentInspectionReports,
    recentInspectionComments: state.dashboard.recentInspectionComments,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};

export class DashboardComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch((_: never, getState: () => AppState) => {
      const state = getState() as AppState;
      if (!state.areas.result && !state.areas.isLoading) {
        dispatch(fetchAreas());
      }
    });

    this.fetchStatsData();

    dispatch(fetchDashboardRecentLists());
  }

  render() {
    const {
      trans,
      dateRange,
      activeStatAspect,
      summaryStats,
      companionSummaryStats,
    } = this.props;

    const statsByTime = getStatsByTimeByStatAspect(
      this.props,
      activeStatAspect,
    );
    const listConfiguration = RecentListsConfiguations.find(
      x => x.statAspect === activeStatAspect,
    );
    return (
      <Page
        fluid
        title={trans('dashboard.title')}
        fullAccessRight={AclObjectList.StatsFullAccess}
        readonlyAccessRight={AclObjectList.StatsReadonlyAccess}
      >
        <Page.Header>
          <Page.Header.Main title={trans('dashboard.title')} />
          <Page.Header.Toolbar>
            {this.renderDateRangePicker()}
            <Page.Header.ToolbarButton
              iconOnly
              label
              color="primary"
              onClick={this.onRefresh}
            >
              <i className="flaticon-refresh" />
            </Page.Header.ToolbarButton>
          </Page.Header.Toolbar>
        </Page.Header>
        <Page.Content>
          <Portlet>
            <Portlet.Body>
              <StatAspectList
                stats={summaryStats}
                prevStats={companionSummaryStats}
                activeAspect={activeStatAspect}
                onAspectChange={this.onAspectChange}
              />

              <StatsByTimeChart
                aspectType={activeStatAspect}
                dateRange={dateRange}
                stats={statsByTime}
                onPartitionChange={this.onPartitionChange}
              />

              {listConfiguration && (
                <div className="dashboard-section">
                  <h2>
                    {trans(
                      `dashboard.recents.${listConfiguration.listType}.title`,
                    )}
                  </h2>
                  {listConfiguration.render(this.props)}
                </div>
              )}
            </Portlet.Body>
          </Portlet>
        </Page.Content>
      </Page>
    );
  }

  renderCustomers() {
    return null;
  }

  renderDateRangePicker() {
    const { startDate, endDate } = this.props.dateRange;
    const text = (
      <span>
        <i
          className="flaticon2-calendar-1"
          style={{ margin: '0 6px 0 0', fontSize: '0.95em' }}
        />
        <span style={{ marginRight: 6 }}>
          {moment(startDate).format('YYYY/MM/DD')}
          &nbsp;-&nbsp;
          {moment(endDate).format('YYYY/MM/DD')}
        </span>
      </span>
    );
    return (
      <Dropdown
        buttonContents={text}
        color="brand"
        label
        data-toggle="tooltip"
        title={getString('dashboard.date_range_tip')}
        data-placement="left"
        dropdownProps={{
          placement: 'bottom-end',
          dismissOnClick: false,
          style: { padding: 20, width: 'auto', float: 'none' },
        }}
      >
        <Translate>
          {({ activeLanguage }) => (
            <DateRangePicker
              locale={getDateRangePickerLocale(activeLanguage.code)}
              dateDisplayFormat={this.props.trans('date_range.date_format')}
              ranges={[
                {
                  startDate: moment(startDate).toDate(),
                  endDate: moment(endDate).toDate(),
                },
              ]}
              onChange={this.onDateRangeChange as any}
              staticRanges={getDefaultStaticRanges(this.props.trans)}
              inputRanges={getDefaultInputRanges(this.props.trans)}
            />
          )}
        </Translate>
      </Dropdown>
    );
  }

  onDateRangeChange = (ranges: RangeKeyDict) => {
    const { dispatch } = this.props;
    const key = Object.keys(ranges)[0];
    if (!key) return;
    const range = ranges[key];
    const startDate = moment(range.startDate).format('YYYY-MM-DD');
    const endDate = moment(range.endDate).format('YYYY-MM-DD');
    dispatch(updateDashboardDateRange({ startDate, endDate }));
  };

  onAspectChange = (aspect: StatAspect) => {
    const { dispatch } = this.props;
    dispatch(dashboardActiveStatAspectChanged(aspect));
  };

  onPartitionChange = (partition: DatePartitionType) => {
    const { dispatch, activeStatAspect } = this.props;
    dispatch(updateStatsByTimeDatePartitionType(activeStatAspect, partition));
  };

  onRefresh = () => {
    this.invalidateStatsData();
  };

  private fetchStatsData() {
    const { dispatch, activeStatAspect } = this.props;
    dispatch(fetchDashboardSummaryStats());
    dispatch(fetchDashboardCompanionSummaryStats());
    dispatch(fetchStatAspectStatsByTime(this.props, activeStatAspect));
  }

  private invalidateStatsData() {
    const { dispatch, activeStatAspect } = this.props;
    dispatch(invalidateDashboardSummaryStats());
    dispatch(invalidateDashboardCompanionSummaryStats());
    dispatch(invalidateStatAspectStatsByTime(this.props, activeStatAspect));
    dispatch(invalidateDashboardCurrentRecentList());
  }
}

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardComponent);
