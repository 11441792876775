import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import { createStandardAction } from 'lib/duck/actions';
import { ActionThunk } from 'lib/duck/interfaces';
import {
  DeliveryCheckTemplateConf,
  DeliveryCheckTemplateItem,
  DeliveryCheckTemplateItemOption,
  VehicleDeliveryCheckTemplate,
} from 'model';
import { vehicleDeliveryCheckTemplateService } from 'services';
import { deliveryCheckTemplateDetailValidated } from '../reducers/delivery-check-template-detail';
import { DeliveryCheckTemplateDetailValidationInfo } from '../states';
import { ActionTypes } from '../types';
import { deliveryCheckTemplateActions } from './delivery-check-templates';

export class DeliveryCheckTemplateDetailActionCreators {
  ready(templateId: number, conf: DeliveryCheckTemplateConf) {
    return createStandardAction(ActionTypes.DeliveryCheckTemplateDetailReady, {
      templateId,
      conf,
    });
  }

  applyDefaultConfiguration(conf: DeliveryCheckTemplateConf) {
    return createStandardAction(
      ActionTypes.ApplyDefaultDeliveryCheckTemplateConf,
      conf,
    );
  }

  addItem() {
    return createStandardAction(ActionTypes.AddDeliveryCheckTemplateItem);
  }

  itemChanged(itemId: string, updateInfo: Partial<DeliveryCheckTemplateItem>) {
    return createStandardAction(ActionTypes.DeliveryCheckTemplateItemChanged, {
      itemId,
      updateInfo,
    });
  }

  removeItem(itemId: string) {
    return createStandardAction(
      ActionTypes.RemoveDeliveryCheckTemplateItem,
      itemId,
    );
  }

  confirmRemoveItem() {
    return createStandardAction(
      ActionTypes.RemoveDeliveryCheckTemplateItemConfirmed,
    );
  }

  cancelRemoveItem() {
    return createStandardAction(
      ActionTypes.RemoveDeliveryCheckTemplateItemCancelled,
    );
  }

  itemMoved(from: number, to: number) {
    return createStandardAction(ActionTypes.DeliveryCheckTemplateItemMoved, {
      from,
      to,
    });
  }

  addOption(itemId: string) {
    return createStandardAction(
      ActionTypes.AddDeliveryCheckTemplateItemOption,
      { itemId },
    );
  }

  optionChanged(
    itemId: string,
    optionId: string,
    updateInfo: Partial<DeliveryCheckTemplateItemOption>,
  ) {
    return createStandardAction(
      ActionTypes.DeliveryCheckTemplateItemOptionChanged,
      { itemId, optionId, updateInfo },
    );
  }

  optionDefaultCheckChanged(
    itemId: string,
    optionId: string,
    checked: boolean,
  ) {
    return createStandardAction(
      ActionTypes.DeliveryCheckTemplateItemOptionDefaultCheckChanged,
      { itemId, optionId, checked },
    );
  }

  optionIsExpectedChanged(itemId: string, optionId: string, checked: boolean) {
    return createStandardAction(
      ActionTypes.DeliveryCheckTemplateItemOptionIsExpectedChanged,
      { itemId, optionId, checked },
    );
  }

  removeOption(optionId: string) {
    return createStandardAction(
      ActionTypes.RemoveDeliveryCheckTemplateItemOption,
      optionId,
    );
  }

  confirmRemoveOption() {
    return createStandardAction(
      ActionTypes.RemoveDeliveryCheckTemplateItemOptionConfirmed,
    );
  }

  cancelRemoveOption() {
    return createStandardAction(
      ActionTypes.RemoveDeliveryCheckTemplateItemOptionCancelled,
    );
  }

  validate(
    callback?: (result: DeliveryCheckTemplateDetailValidationInfo) => void,
  ): ActionThunk<AppState> {
    return (dispatch, getState) => {
      const state = getState();
      const detail = state.inspection.deliveryCheckTemplateDetail;
      const validated = deliveryCheckTemplateDetailValidated(detail);
      dispatch(
        createStandardAction(
          ActionTypes.ValidateDeliveryCheckTemplateDetail,
          validated.validationResult,
        ),
      );
      callback?.(validated.validationResult);
    };
  }

  save(template: VehicleDeliveryCheckTemplate): ActionThunk<AppState> {
    return (dispatch, getState) => {
      const state = getState();
      const currentTemplate = {
        ...template,
        conf: JSON.stringify(
          state.inspection.deliveryCheckTemplateDetail!.conf!,
        ),
      };
      dispatch(
        showAppLoading({ message: 'delivery_check_template.detail.saving' }),
      );
      dispatch(
        createStandardAction(ActionTypes.DeliveryCheckTemplateDetailSave),
      );
      vehicleDeliveryCheckTemplateService
        .update(currentTemplate)
        .then(res => {
          dispatch(
            createStandardAction(
              ActionTypes.DeliveryCheckTemplateDetailSaveSuccess,
            ),
          );
          dispatch(deliveryCheckTemplateActions.updateSuccess(template, res));
          dispatch(hideAppLoading());
        })
        .catch(err => {
          dispatch(
            createStandardAction(
              ActionTypes.DeliveryCheckTemplateDetailSaveFailed,
              err,
            ),
          );
          dispatch(hideAppLoading());
        });
    };
  }
}

export const deliveryCheckTemplateDetailActions =
  new DeliveryCheckTemplateDetailActionCreators();
