import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import {
  constructionTemplateConfigListActions,
  constructionTplActions,
} from 'app/inspection/duck/actions';
import { Button, Switch } from 'lib/metronic/components';
import { memo, useCallback, useRef } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { constructionTemplateConfigService } from 'services';
import { getString } from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';
import { WithFullAccess } from '../../shared/components/WithFullAccess';
import { useConstructionTemplateConfigContext } from './Context';
import {
  isConstructionTemplateConfigValid,
  storeIdFromConstructionTemplateConfigTarget,
} from './util';

export const Actions = memo(() => {
  const { target, configInfo, templateConfigBeingEdited } =
    useConstructionTemplateConfigContext();
  const dispatch = useDispatch();

  const onReset = useCallback(() => {
    dispatch(constructionTplActions.resetChanges());
  }, [dispatch]);

  const isSaving = useRef(false);

  const onSave = usePersistFn(async () => {
    if (templateConfigBeingEdited == null || isSaving.current) {
      return;
    }
    try {
      isSaving.current = true;
      dispatch(
        showAppLoading({
          message: getString('construction_tpl.changes.saving'),
          status: 'loading',
        }),
      );
      const res =
        await constructionTemplateConfigService.saveConstructionTemplateConfig(
          storeIdFromConstructionTemplateConfigTarget(target),
          templateConfigBeingEdited,
        );
      dispatch(constructionTplActions.resetChanges(templateConfigBeingEdited));
      dispatch(
        showAppLoading({
          message: getString('construction_tpl.changes.save_success'),
          status: 'success',
          timeout: 3000,
        }),
      );
      dispatch(constructionTplActions.saveConstructionConfigSuccess(res));
      dispatch(constructionTemplateConfigListActions.invalidate());
    } catch (e) {
      alert(e.message);
    } finally {
      isSaving.current = false;
      dispatch(hideAppLoading());
    }
  });

  const onSwitchChange = usePersistFn(() => {
    dispatch(constructionTplActions.toggleIsConstructionConfigEnabled());
  });

  return (
    <div className="construction-tpl-man__actions">
      {templateConfigBeingEdited != null && (
        <>
          <div className="construction-tpl-man__actions-msg">
            <i className="fa fa-info-circle" />
            <Translate id="construction_tpl.changes.tip" />
          </div>
          <Button color="secondary" size="small" onClick={onReset}>
            <Translate id="construction_tpl.changes.btn.reset" />
          </Button>
          <Button
            color="primary"
            size="small"
            disabled={
              !isConstructionTemplateConfigValid(templateConfigBeingEdited)
            }
            onClick={onSave}
          >
            <Translate id="construction_tpl.changes.btn.save" />
          </Button>
        </>
      )}
      {configInfo != null && (
        <WithFullAccess>
          <Switch
            color="brand"
            outline
            checked={configInfo.enabled}
            onChange={onSwitchChange}
          />
        </WithFullAccess>
      )}
    </div>
  );
});
