import React, { MouseEvent } from 'react';
import {
  WeixinOpenAuthorizedInfo,
  OfficialAccountCertificateType,
  OfficialAccountGrantedPermissionTypeOptions,
} from 'model';
import {
  OfficialAccountCertificateTypeLabel,
  OfficialAccountTypeLabel,
  OfficialAccountGrantedPermissionTypeLabel,
  Checkmark,
} from 'shared/components';
import { Button } from 'lib/metronic/components';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { RequiredAuthorizedPermissionSet } from './helpers/required-permissions';

interface Props {
  authorizedInfo: WeixinOpenAuthorizedInfo;
  reauthorizable?: boolean;
  menuConfiguable?: boolean;
  onReauthorize?: (e: MouseEvent) => void;
}

export const AuthorizationDetail = React.memo<Props>(
  ({ authorizedInfo, reauthorizable, menuConfiguable, onReauthorize }) => {
    const { authorizerInfo, authorizationInfo } = authorizedInfo;
    const grantedPermissionSet = new Set(
      authorizationInfo.funcInfo.map(x => x.funcscopeCategory.id),
    );
    return (
      <div className="weixin-authorization-detail">
        <div className="authorizer-info">
          <div className="kt-widget__top">
            {authorizerInfo.headImg && (
              <div className="kt-widget__media">
                <img
                  src={authorizerInfo.headImg}
                  alt={authorizerInfo.nickName}
                />
              </div>
            )}
            {!authorizerInfo.headImg && (
              <div className="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-boldest kt-font-light kt-hidden">
                {authorizerInfo.headImg}
              </div>
            )}
            <div className="kt-widget__content">
              <div className="kt-widget__head">
                <span className="kt-widget__username">
                  <span className="kt-font-bolder">
                    {authorizerInfo.nickName}
                  </span>
                  {authorizerInfo.verifyTypeInfo.id ===
                  OfficialAccountCertificateType.Uncertified ? (
                    <span className="kt-font-danger">
                      <i className="fa fa-exclamation-circle" />
                      <OfficialAccountCertificateTypeLabel
                        value={authorizerInfo.verifyTypeInfo.id}
                        plain
                      />
                    </span>
                  ) : (
                    <span className="kt-font-success">
                      <i className="flaticon2-correct" />
                      <OfficialAccountCertificateTypeLabel
                        value={authorizerInfo.verifyTypeInfo.id}
                        plain
                      />
                    </span>
                  )}
                </span>
                <div className="kt-widget__action">
                  <Button
                    color="success"
                    size="small"
                    href="/settings/weixin-open/menu"
                    disabled={!menuConfiguable}
                    uppercased
                    link
                  >
                    <Translate id="integration.authorization.button.configure_menu" />
                  </Button>
                  <Button
                    color="brand"
                    size="small"
                    uppercased
                    disabled={!reauthorizable}
                    onClick={onReauthorize}
                  >
                    <Translate id="integration.authorization.button.reauthorize" />
                  </Button>
                </div>
              </div>
              <div className="kt-widget__subhead">
                <a>
                  <i className="flaticon2-layers-2" />
                  <OfficialAccountTypeLabel
                    value={authorizerInfo.serviceTypeInfo.id}
                    plain
                  />
                </a>
                <a>
                  <i className="flaticon2-calendar-3" />
                  {authorizerInfo.userName}
                </a>
                <a>
                  <i className="flaticon2-user-outline-symbol" />
                  {authorizationInfo.authorizationAppid}
                </a>
              </div>
              <div className="kt-widget__info">
                <div className="kt-widget__desc">
                  {authorizerInfo.principalName}
                </div>
                <div className="kt-widget__desc">
                  <div className="kt-font-success">
                    <Translate
                      id="integration.authorization.label.authorized"
                      data={{
                        authorizedAt: formatTime(
                          authorizationInfo.authorizedAt || 0,
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-widget__bottom">
            <div className="weixin-authorization-detail__permission-set">
              {OfficialAccountGrantedPermissionTypeOptions.map((option, i) => (
                <dl
                  className="col col-md-6 col-sm-12 col-xs-12 weixin-authorization-detail__permission"
                  key={i}
                >
                  <dt>
                    <OfficialAccountGrantedPermissionTypeLabel
                      value={option.value}
                      plain
                    />
                  </dt>
                  <dd>
                    <Checkmark
                      value={grantedPermissionSet.has(option.value)}
                      style={{ fontSize: '1.2rem' }}
                      falsePlaceholder={
                        <i
                          className={`la ${
                            RequiredAuthorizedPermissionSet.has(option.value)
                              ? 'la-exclamation-circle'
                              : 'la-circle'
                          }`}
                          style={{
                            color: RequiredAuthorizedPermissionSet.has(
                              option.value,
                            )
                              ? 'red'
                              : '#ddd',
                            fontSize: '1.6rem',
                          }}
                        />
                      }
                    />
                  </dd>
                </dl>
              ))}
              {OfficialAccountGrantedPermissionTypeOptions.length % 2 === 1 ? (
                <dl className="col col-md-6 col-sm-12 col-xs-12 weixin-authorization-detail__permission">
                  <dt>&nbsp;</dt>
                  <dd />
                </dl>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
