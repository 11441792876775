import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { QuotationTemplateInfo } from 'model';
import { QuotationTemplates } from '../states';

const initialState: QuotationTemplates = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {},
};

export default createAsyncListActionReducers<
  QuotationTemplateInfo,
  QuotationTemplates
>('inspection.quotation-templates', initialState, {
  mapItemKey: x => x.id,
});
