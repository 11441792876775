/**
 * @file: makeUniqueId.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { customAlphabet, nanoid } from 'nanoid';
import { NUMERIC_ALPHABETIC_CHARACTERS_ALL } from './randstr';

export function makeUniqueId(len = 21): string {
  return nanoid(len);
}

export function makeUniqueIdAlphabetic(len = 21): string {
  return makeUniqueIdCustom(NUMERIC_ALPHABETIC_CHARACTERS_ALL, len);
}

export function makeUniqueIdCustom(alphabet: string, len = 21): string {
  return customAlphabet(alphabet, len)();
}
