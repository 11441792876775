/**
 * @file: GenderOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, Gender } from 'model';

export const GenderOptions: Array<Option<Gender>> = [
  { value: Gender.Unknown, label: 'gender.unknown' },
  { value: Gender.Male, label: 'gender.male' },
  { value: Gender.Female, label: 'gender.female' }
];

export const GenderOptionsWithDefault: Array<Option<Gender | null>> = [
  { value: null, label: 'gender.__default__' },
  { value: Gender.Unknown, label: 'gender.unknown' },
  { value: Gender.Male, label: 'gender.male' },
  { value: Gender.Female, label: 'gender.female' }
];