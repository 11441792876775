import { Alert, Button, Checkbox, CheckboxList } from 'lib/metronic/components';
import { ChangeEvent, Component } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { getString } from 'shared/components';

interface Props {
  isSuperUser: boolean;
  error?: Error | null;
  selected: string[];
  acl: string[];
  onChange: (values: string[]) => void;
  onSave?: () => void;
}

export class AclEditor extends Component<Props> {
  render() {
    const { acl, selected, error, isSuperUser } = this.props;
    return (
      <div className="acl-editor">
        <CheckboxList
          style={{
            overflowY: 'auto',
            position: 'fixed',
            left: 20,
            right: 20,
            top: 88,
            bottom: 68,
          }}
        >
          {error && <Alert color="danger">{error.message}</Alert>}
          {acl.map(x => (
            <Checkbox
              key={x}
              value={x}
              solid
              color={
                isSuperUser || (selected || []).includes(x)
                  ? 'success'
                  : undefined
              }
              checked={isSuperUser || (selected || []).includes(x)}
              onChange={this.onChange(x)}
              label={x}
              style={{ fontSize: '0.9rem' }}
              controlStyle={{ top: '0px' }}
            />
          ))}
        </CheckboxList>
        <div
          className="acl-settings__actions"
          style={{
            position: 'fixed',
            bottom: 0,
            height: 60,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: '3px solid rgba(93, 120, 255, .1)',
            left: 0,
            right: 0,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Button color="brand" onClick={this.onSave} disabled={isSuperUser}>
            <Translate id="save_btn_text" />
          </Button>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 8px',
              }}
            >
              <ReactMarkdown>
                {getString('org_user.acl.label.selected', {
                  selected: isSuperUser ? acl.length : selected.length,
                })}
              </ReactMarkdown>
            </div>
            <Button
              color="info"
              onClick={this.onSelectAll}
              label
              disabled={isSuperUser}
            >
              <Translate id="org_user.acl.label.select_all" />
            </Button>
            <Button
              color="info"
              onClick={this.onDeselectAll}
              label
              disabled={isSuperUser}
              style={{ marginLeft: 4 }}
            >
              <Translate id="org_user.acl.label.deselect_all" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  onChange(value: string) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (this.props.isSuperUser) {
        e.preventDefault();
        return false;
      }
      let { selected } = this.props;
      if (e.target.checked) {
        selected = [...selected, value];
      } else {
        selected = selected.filter(x => x !== value);
      }
      this.props.onChange(selected);
      return true;
    };
  }

  onSave = () => {
    this.props.onSave && this.props.onSave();
  };

  onSelectAll = () => {
    this.props.onChange(this.props.acl);
  };

  onDeselectAll = () => {
    this.props.onChange([]);
  };
}
