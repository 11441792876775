import React from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { inspectionToolActions } from '../duck/actions';
import { InspectionTool, InspectionToolListFilter, AclObjectList } from 'model';
import { EntityListProps, EntityListComponentClassBuilder, getString, QuantitativeTypeLabel } from 'shared/components';

interface Props extends EntityListProps<InspectionTool, InspectionToolListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  InspectionTool,
  InspectionToolListFilter,
  number,
  Props
  >();

export const InspectionToolList = componentClassBuilder
  .i18nPrefix('inspection_tool')
  .pageIcon(require('!@svgr/webpack!lib/metronic/assets/icons/svg/shopping/box1.svg').default)
  .accessRights({
    readonly: AclObjectList.InspectionToolReadonlyAccess
  })
  .breadcrumbs([
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.tools" /> }
  ])
  .entities(state => state.inspection.tools)
  .actions(inspectionToolActions)
  .columns([{
    prop: 'code',
    width: 100,
    text: 'inspection_tool.col.code'
  }, {
    prop: 'name',
    width: 150,
    text: 'col.name'
  }, {
    prop: 'type',
    width: 120,
    align: 'center',
    text: 'inspection_tool.col.type',
    render: ({ quantitativeType }) => <QuantitativeTypeLabel value={quantitativeType} />
  }, {
    prop: 'isExternal',
    width: 80,
    align: 'center',
    text: 'inspection_tool.col.is_external',
    render: ({ isExternal }) => isExternal ? 'Y' : '-'
  }, {
    prop: 'protocol',
    width: 80,
    align: 'center',
    text: 'inspection_tool.col.protocol'
  }, {
    prop: 'createdAt',
    text: 'col.created_at',
    width: 150,
    align: 'center',
    render: ({ createdAt }) => formatTime(createdAt)
  }])
  .addActionButtons(['edit', 'remove'])
  .validate(entity => {
    const name = entity.name?.trim();
    const code = entity.code?.trim();

    if (!code) {
      throw new Error(
        getString('inspection_tool.editor.error.code_required')
        );
    }

    if (!name) {
      throw new Error(
        getString('inspection_tool.editor.error.name_required')
        );
    }
  })
  .getClass();