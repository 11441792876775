/**
 * @file: members.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { OrgMember } from 'model';
import { OrgMembers } from '../states';
import { ActionTypes } from '../types';

const initialState: OrgMembers = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
};

const defaultReducer = createAsyncListActionReducers<OrgMember, OrgMembers>(
  'org.members',
  initialState,
  {
    mapItemKey: x => x.id,
  },
);

export default function userReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: OrgMembers = initialState,
  action: StandardAction<any>,
): OrgMembers {
  switch (action.type) {
    case ActionTypes.OrgMemberQrcodeAuth: {
      return Object.assign({}, state, {
        currentQrcodeAuthUserId: action.payload,
      });
    }
    default: {
      return defaultReducer(state, action);
    }
  }
}
