import { ReactNode, createContext, memo, useContext, useMemo } from 'react';

export type PropertyEditorContextProps = {
  onOptionErrorStateChange: (
    optionId: string,
    field: string,
    error: ReactNode | undefined,
  ) => void;
};

export const PropertyEditorContext = createContext<PropertyEditorContextProps>(
  null as any,
);

export const PropertyEditorContextProvider = memo(
  ({
    children,
    onOptionErrorStateChange,
  }: {
    onOptionErrorStateChange: PropertyEditorContextProps['onOptionErrorStateChange'];
    children?: ReactNode;
  }) => {
    const value = useMemo<PropertyEditorContextProps>(
      () => ({ onOptionErrorStateChange }),
      [onOptionErrorStateChange],
    );

    return (
      <PropertyEditorContext.Provider value={value}>
        {children}
      </PropertyEditorContext.Provider>
    );
  },
);

export const usePropertyEditorContext = () => useContext(PropertyEditorContext);
