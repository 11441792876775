/**
 * @file: OfficialAccountMenuItemMediaTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, OfficialAccountMenuItemMediaType } from 'model';

export const OfficialAccountMenuItemMediaTypeOptions: Array<Option<OfficialAccountMenuItemMediaType>> = [
  { value: OfficialAccountMenuItemMediaType.Text, label: 'official_account_menu_item_media_type.text' },
  { value: OfficialAccountMenuItemMediaType.Image, label: 'official_account_menu_item_media_type.image' },
  { value: OfficialAccountMenuItemMediaType.Voice, label: 'official_account_menu_item_media_type.voice' },
  { value: OfficialAccountMenuItemMediaType.Video, label: 'official_account_menu_item_media_type.video' },
  { value: OfficialAccountMenuItemMediaType.Music, label: 'official_account_menu_item_media_type.music' },
  { value: OfficialAccountMenuItemMediaType.News, label: 'official_account_menu_item_media_type.news' }
];

export const OfficialAccountMenuItemMediaTypeOptionsWithDefault: Array<Option<OfficialAccountMenuItemMediaType | null>> = [
  { value: null, label: 'official_account_menu_item_media_type.__default__' },
  { value: OfficialAccountMenuItemMediaType.Text, label: 'official_account_menu_item_media_type.text' },
  { value: OfficialAccountMenuItemMediaType.Image, label: 'official_account_menu_item_media_type.image' },
  { value: OfficialAccountMenuItemMediaType.Voice, label: 'official_account_menu_item_media_type.voice' },
  { value: OfficialAccountMenuItemMediaType.Video, label: 'official_account_menu_item_media_type.video' },
  { value: OfficialAccountMenuItemMediaType.Music, label: 'official_account_menu_item_media_type.music' },
  { value: OfficialAccountMenuItemMediaType.News, label: 'official_account_menu_item_media_type.news' }
];