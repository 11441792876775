import { LinkButton } from 'app/inspection/quotation-template-config/LinkButton';
import { FocusEvent, KeyboardEvent, memo, useCallback } from 'react';
import { Translate } from 'react-localize-redux';
import { getString } from 'shared/components';

export const NewGroup = memo(
  ({
    editMode,
    onAdd,
    onEndEdit,
    onCancelEdit,
  }: {
    editMode?: boolean;
    onAdd?: () => void;
    onEndEdit?: (name: string) => void;
    onCancelEdit?: () => void;
  }) => {
    const onBlur = useCallback(
      (e: FocusEvent) => {
        const el = e.target as HTMLInputElement;
        const name = el.value.trim();
        if (name.length) {
          onEndEdit?.(name);
        } else {
          onCancelEdit?.();
        }
      },
      [onCancelEdit, onEndEdit],
    );

    const onKeyUp = useCallback(
      (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          // cancel
          onCancelEdit?.();
        } else if (e.key === 'Enter') {
          // enter
          const el = e.target as HTMLInputElement;
          const name = el.value.trim();
          if (name.length) {
            onEndEdit?.(name);
          }
        }
      },
      [onCancelEdit, onEndEdit],
    );

    return (
      <div className="tpl-detail__group tpl-detail__group--new">
        {editMode ? (
          <input
            type="text"
            className="form-control form-control-sm m-input"
            placeholder={getString('quotation_tpl.group.placeholder.name')}
            defaultValue={''}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            ref={c => c?.focus()}
          />
        ) : (
          <LinkButton onClick={onAdd}>
            <span>
              <i className="la la-plus" />
              <Translate id="quotation_tpl.group.btn.add_group" />
            </span>
          </LinkButton>
        )}
      </div>
    );
  },
);
