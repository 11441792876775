import React from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { customerActions } from '../duck/actions';
import { Customer, CustomerListFilter, AclObjectList } from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  GenderLabel,
  OrgName,
} from 'shared/components';

interface Props extends EntityListProps<Customer, CustomerListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  Customer,
  CustomerListFilter,
  number,
  Props
>();

export const CustomerList = componentClassBuilder
  .i18nPrefix('org_customer')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/communication/adress-book2.svg')
      .default,
  )
  .accessRights({
    full: AclObjectList.CustomerFullAccess,
    readonly: AclObjectList.CustomerReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="org.breadcrumb.it" /> },
    { text: <Translate id="org.breadcrumb.customers" /> },
  ])
  .features({ addEntity: false })
  .entities(state => state.org.customers)
  .actions(customerActions)
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'fullName',
        label: 'org_customer.toolbar.label.full_name',
        placeholder: 'org_customer.toolbar.placeholder.full_name',
        width: 150,
      })
      .text({
        prop: 'mobile',
        label: 'org_customer.toolbar.label.mobile',
        placeholder: 'org_customer.toolbar.placeholder.mobile',
        width: 150,
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(customerActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'storeId',
      width: 150,
      text: 'col.store_name',
      render: ({ storeName }) => storeName || <OrgName />,
    },
    {
      prop: 'fullName',
      width: 100,
      text: 'org_customer.col.full_name',
    },
    {
      prop: 'gender',
      width: 100,
      align: 'center',
      text: 'col.gender',
      render: ({ gender }) => (gender ? <GenderLabel value={gender} /> : '-'),
    },
    {
      prop: 'mobile',
      width: 100,
      align: 'center',
      text: 'col.mobile',
    },
    {
      prop: 'email',
      width: 100,
      text: 'col.email',
    },
    {
      prop: 'qq',
      width: 100,
      text: 'col.qq',
    },
    {
      prop: 'wechatId',
      width: 100,
      text: 'org_customer.col.wechat_id',
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .componentDidUpdate((props, prevProps) => {
    if (props.activeStoreId !== prevProps.activeStoreId) {
      props.dispatch(customerActions.invalidate(true));
    }
  })
  .getClass();
