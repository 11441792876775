import { TransFunction } from 'app';

const secondsPerHour = 3600;

export function formatTimeElapsed(value: number, trans: TransFunction) {
  let sec = value;
  let day = 0,
    hour = 0,
    min = 0;
  day = Math.floor(sec / (secondsPerHour * 24));
  sec -= day * (secondsPerHour * 24);
  hour = Math.floor(sec / secondsPerHour);
  sec -= hour * secondsPerHour;
  min = Math.floor(sec / 60);
  sec -= min * 60;

  const components = [];
  if (day) {
    components.push(`${day}${trans('elapsed_time_unit_day')}`);
    if (hour) {
      components.push(`${hour}${trans('elapsed_time_unit_hour')}`);
    }
  } else if (hour) {
    components.push(`${hour}${trans('elapsed_time_unit_hour')}`);
  } else if (min) {
    components.push(`${min}${trans('elapsed_time_unit_min')}`);
  } else {
    components.push(`${sec}${trans('elapsed_time_unit_sec')}`);
  }

  return components.join('');
}
