import { AppState } from 'app';
import { buildTreeModel, imgproxy, SimpleTreeNode } from 'lib/helpers';
import {
  ClassNameType,
  OptionValueType,
  VehicleInspectionSite,
  VehicleInspectionSiteCategory,
  VehicleInspectionSiteCheckItem,
  VehicleInspectionSiteCheckItemOption,
} from 'model';
import { OptionValueTypeOptions } from 'model/EnumOptions';
import { CheckState, getString, Node } from 'shared/components';
import { sprintf } from 'sprintf-js';
import { arr2map, comparer, guid } from 'utils';
import {
  InspectionSiteCategories,
  InspectionSiteInventoryUIState,
  InspectionSiteItemOptions,
  InspectionSiteItems,
  InspectionSites,
  InspectionTemplateDetail,
} from '../duck/states';

function formatValue(value: number, format?: string | null) {
  if (!format) return String(value);
  return sprintf(format, value);
}

function valueUnitFormat(unit: string | undefined | null) {
  if (!unit) return '';
  if (unit === '%') return '%%';
  return unit;
}

export function makeId() {
  return guid('n');
}

export function formatItemOptionLabel(
  item: VehicleInspectionSiteCheckItem,
  option: VehicleInspectionSiteCheckItemOption,
) {
  if (!option.labelFormat) return option.label;
  const values: any[] = [];
  if (typeof option.lower === 'number' && typeof option.upper === 'number') {
    values.push(option.lower, option.upper);
    const lowerLabel = formatValue(
      option.lower,
      `%g${valueUnitFormat(item.valueUnit)}`,
    );
    const upperLabel = formatValue(
      option.upper,
      `%g${valueUnitFormat(item.valueUnit)}`,
    );
    if (option.lowerInclusive && option.upperInclusive) {
      return [lowerLabel, upperLabel].join('-');
    }
    const lowerOp = option.lowerInclusive ? '[' : '(';
    const upperOp = option.upper ? ']' : ')';
    return `${lowerOp}${lowerLabel}, ${upperLabel}${upperOp}`;
  } else if (typeof option.lower === 'number') {
    values.push(option.lower);
    const op = option.lowerInclusive ? '>=' : '>';
    return `${op} ${formatValue(option.lower, `%g${valueUnitFormat(item.valueUnit)}`)}`;
  } else if (typeof option.upper === 'number') {
    values.push(option.upper);
    const op = option.upper ? '<=' : '<';
    return `${op} ${formatValue(option.upper, `%g${valueUnitFormat(item.valueUnit)}`)}`;
  } else {
    return sprintf(option.labelFormat, ...values);
  }
}

const valueType2UnitMap = new Map<OptionValueType, string>();
for (const option of OptionValueTypeOptions) {
  if (
    option.value === OptionValueType.Number ||
    option.value === OptionValueType.String
  ) {
    continue;
  }
  valueType2UnitMap.set(option.value, `value_unit.${option.value}`);
}

export function getValueUnitByValueType(valueType: OptionValueType): string {
  const unitStringRes = valueType2UnitMap.get(valueType);
  if (unitStringRes) return getString(unitStringRes);
  return '';
}

export function isNodeExpanded(
  nodeId: string,
  expandAllByDefault: boolean,
  uiState: InspectionSiteInventoryUIState,
): boolean {
  return (
    (expandAllByDefault && !uiState.collapsedNodes.has(nodeId)) ||
    (!expandAllByDefault && uiState.expandedNodes.has(nodeId))
  );
}

export function getCategoryNodeId(categoryId: number) {
  return `category:${categoryId}`;
}

export function getSiteNodeId(siteId: number) {
  return `site:${siteId}`;
}

export function getItemNodeId(itemId: number) {
  return `item:${itemId}`;
}

export function getOptionNodeId(optionId: number) {
  return `option:${optionId}`;
}

export function isCategoryNode(node: Node) {
  return (node.id as string).startsWith('category');
}

export function isSiteNode(node: Node) {
  return (node.id as string).startsWith('site:');
}

export function isItemNode(node: Node) {
  return (node.id as string).startsWith('item:');
}

export function isOptionNode(node: Node) {
  return (node.id as string).startsWith('option:');
}

export function getNodeDataAsCategory(node: Node) {
  return node.data as VehicleInspectionSiteCategory;
}

export function getNodeDataAsSite(node: Node) {
  return node.data as VehicleInspectionSite;
}

export function getNodeDataAsItem(node: Node) {
  return node.data as VehicleInspectionSiteCheckItem;
}

export function getNodeDataAsOption(node: Node) {
  return node.data as VehicleInspectionSiteCheckItemOption;
}

export function createNode(
  uiState: InspectionSiteInventoryUIState,
  type: string,
  id: () => string,
  text: () => string,
  cls: () => ClassNameType,
  icon: ((expanded: boolean, selected: boolean) => string) | null,
  data: any,
  expandAllByDefault: boolean,
  isLeaf = false,
  checked: ((nodeId: string) => CheckState) | null = null,
  loading: ((nodeId: string) => boolean) | null = null,
): Node {
  const nodeId = id();
  const expanded = isNodeExpanded(nodeId, expandAllByDefault, uiState);
  const selected = uiState.selectedNodeId === nodeId;
  const nodeIcon = icon ? icon(expanded, selected) : undefined;
  return {
    id: nodeId,
    type,
    text: text(),
    className: cls(),
    expanded,
    selected,
    checked: checked ? checked(nodeId) : undefined,
    isLoading: loading ? loading(nodeId) : undefined,
    iconCls: nodeIcon,
    children: isLeaf ? undefined : [],
    data,
    isLeaf,
  };
}

export function buildInventoryTree(
  categories: InspectionSiteCategories,
  sites: InspectionSites,
  items: InspectionSiteItems,
  options: InspectionSiteItemOptions,
  uiState: InspectionSiteInventoryUIState,
  keyword: string = '',
  expandAllByDefault = false,
  includeOptionNodes = true,
  showCheckbox = false,
  checkedNodeIds: Set<string> = new Set(),
) {
  if (
    !categories.result ||
    !sites.result ||
    !items.result ||
    (includeOptionNodes && !options.result)
  ) {
    return [];
  }

  const nodeMap = new Map<string, Node>();
  const categoryNodeMap = new Map<number, Node>();

  const nodes = buildTreeModel(categories.result!, {
    itemKey: x => getCategoryNodeId(x.id),
    parentItemKey: x =>
      x.parentCategoryId ? getCategoryNodeId(x.parentCategoryId) : '',
    createNode(category): Node {
      const node = createNode(
        uiState,
        'category',
        () => getCategoryNodeId(category.id),
        () => category.name,
        () => 'inspection-site-inventory__category-node',
        expanded =>
          expanded ? 'fa fa-fw fa-folder-open' : 'fa fa-fw fa-folder',
        category,
        expandAllByDefault,
        false,
        checkStateRenderer,
      );
      nodeMap.set(node.id as string, node);
      categoryNodeMap.set(category.id, node);
      return node as Node;
    },
    appendChildNode(node: Node, childNode: Node) {
      if (!node.children) {
        node.children = [];
      }
      node.children.push(childNode);
      node.childType = 'category';
    },
  });

  const checkStateRenderer = showCheckbox
    ? (nodeId: string) => checkedNodeIds.has(nodeId)
    : null;

  const siteNodeMap = new Map<number, Node>();
  for (const site of sites.result || []) {
    const siteNode = createNode(
      uiState,
      'site',
      () => getSiteNodeId(site.id),
      () => site.name,
      () => 'inspection-site-inventory__site-node',
      // () => 'fa fa-cubes', // fa-layer-group
      () => 'fa fa-fw fa-layer-group',
      site,
      expandAllByDefault,
      false,
      checkStateRenderer,
    );

    const categoryNode = categoryNodeMap.get(site.categoryId);
    if (categoryNode) {
      siteNode.parentNodeId = categoryNode.id;
      categoryNode.children?.push(siteNode);
    }

    nodeMap.set(siteNode.id as string, siteNode);
    siteNodeMap.set(site.id, siteNode);
  }

  const itemNodeMap = new Map<number, Node>();
  for (const item of items.result || []) {
    const itemNode = createNode(
      uiState,
      'item',
      () => getItemNodeId(item.id),
      () => item.name,
      () => 'inspection-site-inventory__item-node',
      () => 'fa fa-fw fa-cube',
      item,
      expandAllByDefault,
      !includeOptionNodes,
      checkStateRenderer,
    );

    const siteNode = siteNodeMap.get(item.siteId);
    if (siteNode) {
      itemNode.parentNodeId = siteNode.id;
      siteNode.children?.push(itemNode);
    }
    nodeMap.set(itemNode.id as string, itemNode);
    itemNodeMap.set(item.id, itemNode);
  }

  if (includeOptionNodes) {
    for (const option of options.result || []) {
      const itemNode = itemNodeMap.get(option.itemId);
      const optionNode = createNode(
        uiState,
        'option',
        () => getOptionNodeId(option.id),
        () => (itemNode ? formatItemOptionLabel(itemNode.data, option) : ''),
        () => 'inspection-site-inventory__option-node',
        () => 'fa fa-fw fa-wrench',
        option,
        expandAllByDefault,
        true,
        checkStateRenderer,
      );

      if (itemNode) {
        optionNode.parentNodeId = itemNode.id;
        itemNode.children?.push(optionNode);
      }

      nodeMap.set(optionNode.id as string, optionNode);
    }
  }

  if (showCheckbox) {
    const checkNodes = (node: Node) => {
      if (!node.children?.length) {
        node.checked = checkedNodeIds.has(node.id as string);
      } else {
        for (const childNode of node.children) {
          checkNodes(childNode);
        }
        if (node.children.every(x => x.checked === true)) {
          node.checked = true;
        } else if (node.children.some(x => x.checked)) {
          node.checked = 'intermediate';
        } else {
          node.checked = false;
        }
      }
    };

    for (const node of nodes) {
      checkNodes(node);
    }
  }

  // filter nodes
  keyword = keyword.trim();
  if (keyword) {
    return filteredNodesByKeyword(nodes, keyword, nodeMap);
  }

  return nodes;
}

export function filteredNodesByKeyword(
  nodes: Node[],
  keyword: string,
  nodeMap: Map<string, Node>,
): Node[] {
  const foundNodes: Node[] = [];
  const predict = (node: Node) => node.text.includes(keyword);
  for (const node of nodes) {
    filteredTraversal(foundNodes, node, predict);
  }
  if (!foundNodes.length) return [];

  // build the partial tree
  const rootNodes: Node[] = [];

  // clear children
  const clearChildren = (node: Node) => {
    if (!node.children) return;
    for (const childNode of node.children) {
      clearChildren(childNode);
    }
    node.children = [];
  };

  for (const node of nodes) {
    clearChildren(node);
  }

  for (const node of foundNodes) {
    if (!node.parentNodeId) {
      rootNodes.push(node);
    } else {
      let tempNode = node;
      while (tempNode.parentNodeId) {
        const nodeId = tempNode.id;
        const parentNode = nodeMap.get(tempNode.parentNodeId as string)!;
        if (!parentNode.children) parentNode.children = [];
        if (!parentNode.children.some(x => x.id === nodeId)) {
          parentNode.children.push(tempNode);
        }
        tempNode = parentNode;
      }
      if (!rootNodes.some(x => x.id === tempNode.id)) {
        rootNodes.push(tempNode);
      }
    }
  }

  return rootNodes;
}

export function mapStateToTree(
  state: AppState,
  keyword: string = '',
  expandAllByDefault = false,
  includeOptionNodes = true,
  showCheckbox = false,
  checkedNodeIds: Set<string> = new Set(),
) {
  const categories = state.inspection.categories;
  const sites = state.inspection.sites;
  const items = state.inspection.items;
  const options = state.inspection.options;
  const uiState = state.inspection.uiState;

  return buildInventoryTree(
    categories,
    sites,
    items,
    options,
    uiState,
    keyword,
    expandAllByDefault,
    includeOptionNodes,
    showCheckbox,
    checkedNodeIds,
  );
}

function extractNodeIds(node: Node, ids: string[]) {
  ids.push(node.id as string);
  if (node.children) {
    for (const childNode of node.children) {
      extractNodeIds(childNode, ids);
    }
  }
}

export function getAllNodeIds(nodes: Node[]): string[] {
  const ids: string[] = [];
  for (const node of nodes) {
    extractNodeIds(node, ids);
  }
  return ids;
}

function filteredTraversal(
  nodes: Node[],
  node: Node,
  predict: (node: Node) => boolean,
) {
  if (predict(node)) {
    nodes.push(node);
  }
  if (!node.children) return;
  for (const childNode of node.children) {
    filteredTraversal(nodes, childNode, predict);
  }
}

export function findNodeById(id: string, node: Node | Node[]): Node | null {
  if (Array.isArray(node)) {
    for (const nod of node) {
      const found = findNodeById(id, nod);
      if (found) return found;
    }
    return null;
  }
  if (node.id === id) return node;
  if (!node.children) return null;
  for (const childNode of node.children) {
    const found = findNodeById(id, childNode);
    if (found) return found;
  }
  return null;
}

type SimpleCategoryNode = SimpleTreeNode<VehicleInspectionSiteCategory, number>;

export function getCategoryNodeDecendants(
  node: SimpleCategoryNode,
): SimpleCategoryNode[] {
  if (!node.children?.length) return [];
  const results: SimpleCategoryNode[] = [];
  for (const childNode of node.children) {
    results.push(childNode);
    results.push(...getCategoryNodeDecendants(childNode));
  }
  return results;
}

export function getSiteIconSvgUrl(iconUrl: string | null | undefined): string {
  return iconUrl ? imgproxy(iconUrl) : 'public/img/default-site-icon.svg';
}

export function findTargetGroupIdBySiteId(
  state: InspectionTemplateDetail,
  siteId: number,
): string | null | undefined {
  let groupId = state.selectedGroupId;
  if (!groupId && state.allGroupsSelectedCategoryId) {
    // determine the group id by selected site id
    for (const category of state.conf!.categories) {
      for (const group of category.groups) {
        if (group.siteIds.includes(siteId)) {
          groupId = group.id;
          break;
        }
      }
      if (groupId) break;
    }
  }
  return groupId;
}

export function createOrderComparerFromMap<T>(
  list: T[],
  mapper: (element: T) => number,
  orderMap: { [key: number]: number } | null | undefined,
  defaultOrderMap?: { [key: number]: number },
): (a: T, b: T) => number {
  const idOrderMap = arr2map(
    list,
    x => mapper(x),
    (_, i) => i,
  );
  const getSortOrder = (id: number) => {
    if (orderMap && Object.prototype.hasOwnProperty.call(orderMap, id)) {
      return orderMap[id];
    }
    if (
      defaultOrderMap &&
      Object.prototype.hasOwnProperty.call(defaultOrderMap, id)
    ) {
      return defaultOrderMap[id];
    }
    return idOrderMap[id];
  };
  return (a, b) => {
    const [x, y] = [getSortOrder(mapper(a)), getSortOrder(mapper(b))];
    return comparer(x, y);
  };
}
