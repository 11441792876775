/**
 * @file: stores.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { Store, StoreListFilter } from 'model';
import { storeService } from 'services';

export const storeActions = createListAsyncActionCreators<AppState, Store, StoreListFilter, number>(
  'org.stores',
  {
    shouldInvalidateForFilter: true,
    shouldFetchOnInvalidate: true,
    getItemBeingUpdated: (state: AppState) => state.org.stores.itemBeingUpdated,
    async update(store: Store) {
      return await storeService.update(store);
    },
    async fetchHandler(state: AppState) {
      const orgId = state.identity!.orgId;
      return await storeService.list(
        Object.assign({}, state.org.stores.filter, { orgId }),
        state.org.stores.sorts?.[0],
        state.org.stores.offset,
        state.org.stores.limit,
        { count: true, recursive: true }
        );
    }
  }
);