import React, { PureComponent, FocusEvent } from 'react';
import { MpMediaPicker } from '../shared/MediaPicker';
import { WeixinMusicInfo, WeixinPermanentMaterialListItem } from 'model';
import { Translate } from 'react-localize-redux';

interface Props {
  musicInfo?: WeixinMusicInfo;
  onChange: (musicInfo: Partial<WeixinMusicInfo>) => void;
}

export class MusicEditor extends PureComponent<Props> {
  render() {
    const { musicInfo } = this.props;
    return (
      <>
        <div className="form-group" style={{ marginTop: '1rem' }}>
          <label>
            <Translate id="integration.menu.label.music.title" />
          </label>
          <input
            type="text"
            className="form-control"
            key={musicInfo?.title || ''}
            defaultValue={musicInfo?.title || ''}
            onBlur={this.onTitleBlur}
            />
        </div>
        <div className="form-group">
          <label>
            <Translate id="integration.menu.label.music.description" />
          </label>
          <input
            type="text"
            className="form-control"
            key={musicInfo?.description || ''}
            defaultValue={musicInfo?.description || ''}
            onBlur={this.onDescriptionBlur}
            />
        </div>
        <div className="form-group">
          <label>
            <Translate id="integration.menu.label.music.music_url" />
          </label>
          <input
            type="text"
            className="form-control"
            key={musicInfo?.musicUrl || ''}
            defaultValue={musicInfo?.musicUrl || ''}
            onBlur={this.onMusicUrlBlur}
            />
        </div>
        <div className="form-group">
          <label>
            <Translate id="integration.menu.label.music.hq_music_url" />
          </label>
          <input
            type="text"
            className="form-control"
            key={musicInfo?.hqMusicUrl || ''}
            defaultValue={musicInfo?.hqMusicUrl || ''}
            onBlur={this.onHqMusicUrlBlur}
            />
        </div>
        <div className="form-group">
          <label>
            <Translate id="integration.menu.label.music.cover" />
          </label>
          <MpMediaPicker
            type="image"
            mediaId={musicInfo?.thumbMediaId}
            onChange={this.onCoverChange}
            onMediaSelected={this.onCoverMediaSelected}
            />
        </div>
      </>
    );
  }

  onTitleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const oldTitle = this.props.musicInfo?.title;
    const title = e.target.value.trim();
    if (oldTitle !== title) {
      this.props.onChange({ title });
    }
  }

  onDescriptionBlur = (e: FocusEvent<HTMLInputElement>) => {
    const oldDesc = this.props.musicInfo?.description;
    const desc = e.target.value.trim();
    if (oldDesc !== desc) {
      this.props.onChange({ description: desc });
    }
  }

  onMusicUrlBlur = (e: FocusEvent<HTMLInputElement>) => {
    const oldMusicUrl = this.props.musicInfo?.musicUrl;
    const musicUrl = e.target.value.trim();
    if (oldMusicUrl !== musicUrl) {
      this.props.onChange({ musicUrl });
    }
  }

  onHqMusicUrlBlur = (e: FocusEvent<HTMLInputElement>) => {
    const oldHqMusicUrl = this.props.musicInfo?.hqMusicUrl;
    const hqMusicUrl = e.target.value.trim();
    if (oldHqMusicUrl !== hqMusicUrl) {
      this.props.onChange({ hqMusicUrl });
    }
  }

  onCoverChange = (mediaId: string) => {
    this.props.onChange({ thumbMediaId: mediaId });
  }

  onCoverMediaSelected = (item: WeixinPermanentMaterialListItem) => {
    this.props.onChange({ thumbMediaId: item.mediaId });
  }
}