/**
 * @file: formatTime.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import moment from 'moment';

export function formatTime(
  time?: string | Date | number | null,
  format = 'YYYY-MM-DD HH:mm:ss',
  fallback = '',
): string {
  if (!time) return fallback;
  return moment(time).format(format);
}

export function formatDate(
  time?: string | Date | number | null,
  format = 'YYYY-MM-DD',
  fallback = '',
): string {
  if (!time) return fallback;
  return moment(time).format(format);
}
