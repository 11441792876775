import classNames from 'classnames';
import React, { ReactNode, HTMLProps } from 'react';
import { ColorType } from 'lib/metronic';

interface Props extends Omit<HTMLProps<HTMLElement>, 'value'> {
  value?: boolean;
  trueColor?: ColorType;
  falseColor?: ColorType;
  showFalseIcon?: boolean;
  falsePlaceholder?: ReactNode;
}

// import CheckIcon from '!@svgr/webpack!lib/metronic/assets/icons/svg/navigation/check.svg';

export const Checkmark = React.memo<Props>(
  ({
    value,
    trueColor = 'success',
    falseColor = 'danger',
    showFalseIcon = false,
    falsePlaceholder = '-',
    ...props
  }) => (
    <>
      {value ? (
        <i
          className={classNames('fa fa-check-circle', {
            [`kt-font-${trueColor}`]: trueColor,
          })}
          style={{ fontSize: '1.2rem' }}
          {...props}
        />
      ) : showFalseIcon && value === false ? (
        <i
          className={classNames('fa fa-minus-circle', {
            [`kt-font-${falseColor}`]: falseColor,
          })}
          style={{ fontSize: '1.2rem' }}
          {...props}
        />
      ) : (
        falsePlaceholder
      )}
    </>
  ),
);
