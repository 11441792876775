import { createAsyncActionReducers } from 'lib/duck/reducers';
import { TemplateIndustrySettings } from 'model';
import { TemplateIndustrySettingsState } from '../states';

const initialState: TemplateIndustrySettingsState = {
  isLoading: false,
  error: null,
  result: null
};

export default createAsyncActionReducers<TemplateIndustrySettings, TemplateIndustrySettingsState>(
  'integration.templates.industry-settings',
  initialState
);