import { quotationTplActions } from 'app/inspection/duck/actions';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AsyncConfirmModal } from 'shared/components/ConfirmModal/async';
import { useQuotationTemplateConfigContext } from '../Context';

export const ConfirmRemoveItemModal = memo(() => {
  const { state } = useQuotationTemplateConfigContext();
  const dispatch = useDispatch();

  const onConfirmRemoveItem = useCallback(() => {
    dispatch(quotationTplActions.commitRemoveItem());
  }, [dispatch]);

  const onCancelRemoveItem = useCallback(() => {
    dispatch(quotationTplActions.cancelRemoveItem());
  }, [dispatch]);

  return (
    <AsyncConfirmModal
      open={state.itemRefBeingRemoved != null}
      title="quotation_tpl.item.modal.confirm_remove.title"
      content="quotation_tpl.item.modal.confirm_remove.msg"
      onConfirm={onConfirmRemoveItem}
      onCancel={onCancelRemoveItem}
    />
  );
});
