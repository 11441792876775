import { combineReducers } from 'redux';

import stores from './stores';
import groups from './groups';
import teams from './teams';
import members from './members';
import users from './users';
import customers from './customers';
import subscribers from './subscribers';
import usersAcl from './users-acl';

export default combineReducers({
  stores,
  groups,
  teams,
  members,
  users,
  usersAcl,
  customers,
  subscribers
});
