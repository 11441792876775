/**
 * @file index.tsx
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

import { store } from 'app/duck/store';
import { ElementQueries } from 'css-element-queries';
import { enableMapSet, enablePatches } from 'immer';
import { storageService, tokenService } from 'lib';
import { AuthProviderType } from 'model/auth';
import { createRoot } from 'react-dom/client';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Slot, Provider as SlotFillProvider } from 'react-slot-fill';
import { authService } from 'services';
import { App } from './app/App';
import './chartjs-defaults';
import './polyfills';
import './support';

enableMapSet();
enablePatches();

function init() {
  require('./init');

  const mainNavStatus = storageService.ls_get('mainNavStatus');
  if (mainNavStatus === 'minimized') {
    document.body.classList.add('kt-aside--minimize');
  }

  if (location.pathname === '/otc-login') {
    const url = new URL(location.href);
    const code = url.searchParams.get('code');
    if (code) {
      authService
        .loginV2({ type: AuthProviderType.Otc, code })
        .then(res => {
          tokenService.setToken(res.token);
          location.replace('/');
        })
        .catch(err => {
          console.error(err);
          location.replace('/');
        });
      return;
    }
    location.replace('/');
    return;
  }

  const root = createRoot(document.getElementById('application')!);
  root.render(
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <SlotFillProvider>
          <Router>
            <App />
          </Router>
          <Slot name="portal" />
        </SlotFillProvider>
      </LocalizeProvider>
    </Provider>,
  );
}

init();

document.addEventListener('DOMContentLoaded', () => {
  ElementQueries.listen();
});
