import { showAppLoading } from 'app/duck/actions';
import { Button, Checkbox, Dropdown, Nav } from 'lib/metronic/components';
import { OrderTag } from 'model';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { systemService } from 'services';
import {
  EntityEditorForm,
  EntityEditorFormBuilder,
  getString,
} from 'shared/components';

export const OrderTagsEditor = memo(
  ({
    tags,
    onAdd,
    onCheckChange,
    onRemove,
  }: {
    tags?: OrderTag[];
    onAdd: (name: string) => void;
    onCheckChange: (name: string, checked: boolean) => void;
    onRemove: (name: string) => void;
  }) => {
    const [showInput, setShowInput] = useState(false);
    const [predefinedTags, setPredefinedTags] = useState<OrderTag[]>();
    const dispatch = useDispatch();
    const [currentTag, setCurrentTag] = useState<OrderTag>({ name: '' });

    const form = useMemo(() => {
      const builder = new EntityEditorFormBuilder<OrderTag>();
      builder.text({
        prop: 'name',
        label: '',
        placeholder: 'store.config.placeholder.order_tag_name',
        helpText: 'store.config.help_text.order_tag_name',
      });
      return builder.build();
    }, []);

    useEffect(() => {
      systemService
        .getPredefinedOrderTags()
        .then(setPredefinedTags)
        .catch(err => {
          dispatch(showAppLoading({ status: 'error', message: err.message }));
        });
    }, [dispatch]);

    const onChange = useCallback((values: Partial<OrderTag>) => {
      setCurrentTag(tag => ({ ...tag, ...values }));
    }, []);

    const isAddButtonDisabled = !/^[\u4E00-\uFA29a-z0-9]{2,20}$/i.test(
      currentTag.name,
    );

    return (
      <div className="order-tags-editor">
        <ul>
          {tags?.map(tag => (
            <li key={tag.name}>
              <Checkbox
                label={tag.name}
                solid
                color="brand"
                tick={tag.disabled}
                checked={!tag.disabled}
                onChange={e => onCheckChange(tag.name, e.currentTarget.checked)}
                style={{ marginBottom: 0 }}
              />
              <Button
                iconOnly
                color="danger"
                label
                size="small"
                circle
                onClick={() => onRemove(tag.name)}
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                  marginLeft: '1rem',
                }}
              >
                <i className="la la-close" style={{ fontSize: '0.8rem' }} />
              </Button>
            </li>
          ))}
          {!showInput && (
            <li style={{ marginTop: '1rem' }}>
              <Dropdown
                color="brand"
                size="small"
                label
                buttonContents={
                  <Translate id="store.config.label.add_order_tag" />
                }
                dropdownProps={{
                  placement: 'right-center',
                  dismissOnClick: true,
                  style: {
                    padding: 20,
                    width: 'auto',
                    float: 'none',
                    marginLeft: 4,
                  },
                }}
              >
                <Nav>
                  {predefinedTags?.map(tag => (
                    <Nav.Item
                      key={tag.name}
                      text={tag.name}
                      onClick={() => onAdd(tag.name)}
                    />
                  ))}
                  <Nav.Separator />
                  <Nav.Item
                    text={getString('store.config.label.add_custom_order_tag')}
                    onClick={() => setShowInput(true)}
                  />
                </Nav>
              </Dropdown>
            </li>
          )}
        </ul>
        {showInput && (
          <form
            className="entity-editor-form m-form"
            style={{ width: '50%', marginTop: '1rem' }}
          >
            <div className="m-portlet__body">
              <div className="m-form__section m-form__section--first">
                <EntityEditorForm
                  entity={currentTag}
                  onChange={onChange}
                  elements={form.elements}
                  autocomplete={form.autocomplete}
                  useUncontrolled={form.useUncontrolled}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    color="brand"
                    label
                    size="small"
                    disabled={isAddButtonDisabled}
                    onClick={() => {
                      onAdd(currentTag.name);
                      setCurrentTag({ name: '' });
                      setShowInput(false);
                    }}
                  >
                    <Translate id="store.config.button.add_order_tag" />
                  </Button>
                  <Button
                    color="secondary"
                    label
                    size="small"
                    onClick={() => {
                      setCurrentTag({ name: '' });
                      setShowInput(false);
                    }}
                  >
                    <Translate id="store.config.button.cancel_order_tag" />
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  },
);
