/**
 * @file: InspectionTaskDiagnosticJobMediaCategoryOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionTaskDiagnosticJobMediaCategory } from 'model';

export const InspectionTaskDiagnosticJobMediaCategoryOptions: Array<Option<InspectionTaskDiagnosticJobMediaCategory>> = [
  { value: InspectionTaskDiagnosticJobMediaCategory.DiagnosticResult, label: 'inspection_task_diagnostic_job_media_category.diagnostic_result' },
  { value: InspectionTaskDiagnosticJobMediaCategory.Procedure, label: 'inspection_task_diagnostic_job_media_category.procedure' }
];

export const InspectionTaskDiagnosticJobMediaCategoryOptionsWithDefault: Array<Option<InspectionTaskDiagnosticJobMediaCategory | null>> = [
  { value: null, label: 'inspection_task_diagnostic_job_media_category.__default__' },
  { value: InspectionTaskDiagnosticJobMediaCategory.DiagnosticResult, label: 'inspection_task_diagnostic_job_media_category.diagnostic_result' },
  { value: InspectionTaskDiagnosticJobMediaCategory.Procedure, label: 'inspection_task_diagnostic_job_media_category.procedure' }
];