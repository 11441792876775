import { Dropdown } from 'lib/metronic/components';
import { memo } from 'react';
import { SketchPicker } from 'react-color';
import { getString } from 'shared/components';

export const ColorDropdown = memo(
  ({
    value,
    tooltip,
    onChange,
  }: {
    value: string | undefined;
    tooltip?: string;
    onChange: (value: string | undefined) => void;
  }) => {
    return (
      <Dropdown
        iconOnly
        showToggleButton={false}
        size={'small'}
        clean
        data-toggle="tooltip"
        title={
          tooltip ??
          getString('store.config.help_text.extended_property.set_label_color')
        }
        dropdownProps={{
          placement: 'bottom-end',
          style: { padding: 0 },
          dismissOnClick: false,
        }}
        renderCustomButton={() => (
          <div
            data-tooltip
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            {value ? (
              <a
                href="#"
                style={{
                  position: 'absolute',
                  top: -8,
                  right: -8,
                  width: 16,
                  height: 16,
                  borderRadius: 16,
                  backgroundColor: '#ccc',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '0.75rem',
                }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onChange(undefined);
                }}
              >
                <i className="la la-close" />
              </a>
            ) : (
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 0,
                }}
              >
                <path
                  d="M85.333333 955.733333a17.0496 17.0496 0 0 1-12.066133-29.1328l853.333333-853.333333a17.0496 17.0496 0 1 1 24.132267 24.132267l-853.333333 853.333333A17.015467 17.015467 0 0 1 85.333333 955.733333z"
                  fill="red"
                ></path>
              </svg>
            )}
          </div>
        )}
        elStyle={{
          position: 'absolute',
          right: 2,
          top: 2,
          bottom: 2,
          borderRadius: 3,
          cursor: 'pointer',
          border: '1px solid #eee',
          backgroundColor: value || '#fff',
          width: 35,
        }}
      >
        <SketchPicker
          color={value}
          disableAlpha={true}
          onChangeComplete={color => onChange(color.hex)}
        />
      </Dropdown>
    );
  },
);
