import React from 'react';

export function renderFilePreviewer(
  type: 'image' | 'video',
  url: string,
  width: number,
  height: number,
  posterUrl?: string
) {
  if (!url) {
    const defaultImage = type === 'image' ?
      'default-image' : 'default-video-image';
    return (
      <div
        style={{
          width,
          height,
          backgroundImage: `url(public/img/${defaultImage}.png)`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center'
        }}
        />
    );
  }
  if (type === 'image') {
    return (
      <div
        style={{
          width,
          height,
          backgroundImage: `url("${url}")`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center'
        }}
        />
    );
  }

  return (
    <video
      poster={posterUrl}
      controls
      width={width}
      height={height}
      style={{ objectFit: 'cover'}}
      src={url}
      key={url}
      >
      <source
        src={url}
        type="video/mp4"
        />
    </video>
  );
}