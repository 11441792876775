import { APIService } from 'lib/restful-client/api.service';
import { ProductAgent, ProductBrand } from 'model';

export class ProductService {
  constructor(private readonly api: APIService) {}

  async getProductBrands(): Promise<ProductBrand[]> {
    return await this.api.get().url('/products/brands').future();
  }

  async searchProductSuppliers(params?: {
    keyword?: string;
  }): Promise<ProductAgent[]> {
    return await this.api.get().url('/products/suppliers', params).future();
  }
}
