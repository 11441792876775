/**
 * @file: loading.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { AppLoadingOptions } from 'shared/types';
import { AppLoadingState } from '../states';
import { ActionTypes } from '../types';

const initialState: AppLoadingState = {
  show: false,
  message: '',
};

export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AppLoadingState = initialState,
  action: StandardAction<any>,
): AppLoadingState {
  switch (action.type) {
    case ActionTypes.ShowAppLoading: {
      const { options, timer } = action.payload as {
        options?: AppLoadingOptions;
        timer?: any;
      };
      return {
        ...state,
        show: true,
        message: options?.message,
        status: options?.status,
        timeout: options?.timeout,
        timer,
      };
    }
    case ActionTypes.HideAppLoading: {
      return {
        ...state,
        show: false,
        timeout: undefined,
        timer: null,
      };
    }
    default:
      return state;
  }
}
