/**
 * @file: loading.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createStandardAction } from 'lib/duck/actions';
import { ActionThunk } from 'lib/duck/interfaces';
import { AppLoadingOptions } from 'shared/types';
import { AppState } from '../states';
import { ActionTypes } from '../types';

export function showAppLoading(
  options?: AppLoadingOptions,
): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let timer: any;
    if (state.loading.timer) {
      clearTimeout(state.loading.timer);
      console.log('app loading timer cleared');
    }
    if (options?.timeout) {
      timer = setTimeout(() => {
        dispatch(hideAppLoading());
      }, options?.timeout);
      console.log('app loading timer set');
    }
    dispatch(
      createStandardAction(ActionTypes.ShowAppLoading, { options, timer }),
    );
  };
}

export function hideAppLoading(): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();
    if (state.loading.timer) {
      clearTimeout(state.loading.timer);
      console.log('app loading timer cleared');
    }
    dispatch(createStandardAction(ActionTypes.HideAppLoading));
  };
}
