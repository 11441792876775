import { useAppContext } from 'app/AppContext';
import { Identity } from 'model';
import React, { createContext, memo, useContext } from 'react';
import { Translate } from 'react-localize-redux';

export type WithFullAccessContextProps = {
  hasRights: boolean;
};

export const WithFullAccessContext = createContext<WithFullAccessContextProps>(
  null as any,
);

export const useHasFullAccess = () => {
  return useContext(WithFullAccessContext).hasRights;
};

export const WithFullAccess = memo(
  ({
    accessDeniedView,
    silent = true,
    children,
  }: {
    accessDeniedView?: any;
    silent?: boolean;
    children?: ((identity: Identity, hasRights: boolean) => any) | any;
  }) => {
    const { identity } = useAppContext();
    const { hasRights } = useContext(WithFullAccessContext);

    if (typeof children === 'function') {
      return (children as any)(identity, hasRights);
    }

    if (!hasRights) {
      if (silent) return null;

      return (
        <div className="restricted-no-rights kt-grid__item kt-grid__item--fluid">
          {accessDeniedView || (
            <div className="restricted-no-rights--default-content">
              <i className="la la-lightbulb-o icon" />
              <Translate id="access_denied_placeholder" />
            </div>
          )}
        </div>
      );
    }

    const count = React.Children.count(children);

    if (count === 1) return children;

    return <>{children}</>;
  },
);
