/**
 * @file: MaterialType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum MaterialType {
  Oem = 'oem',
  Brand = 'brand',
  Aftermarket = 'aftermarket',
  Salvaged = 'salvaged',
  Refurbished = 'refurbished',
  Counterfeit = 'counterfeit',
  Other = 'other',
}

export const MaterialTypeValueSet = new Set([
  MaterialType.Oem,
  MaterialType.Brand,
  MaterialType.Aftermarket,
  MaterialType.Salvaged,
  MaterialType.Refurbished,
  MaterialType.Counterfeit,
  MaterialType.Other,
]);
