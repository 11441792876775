/**
 * @file: InspectionTaskSiteStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionTaskSiteStatus } from 'model';

export const InspectionTaskSiteStatusOptions: Array<Option<InspectionTaskSiteStatus>> = [
  { value: InspectionTaskSiteStatus.Pending, label: 'inspection_task_site_status.pending' },
  { value: InspectionTaskSiteStatus.InProgress, label: 'inspection_task_site_status.inprogress' },
  { value: InspectionTaskSiteStatus.Cancelled, label: 'inspection_task_site_status.cancelled' },
  { value: InspectionTaskSiteStatus.Finished, label: 'inspection_task_site_status.finished' }
];

export const InspectionTaskSiteStatusOptionsWithDefault: Array<Option<InspectionTaskSiteStatus | null>> = [
  { value: null, label: 'inspection_task_site_status.__default__' },
  { value: InspectionTaskSiteStatus.Pending, label: 'inspection_task_site_status.pending' },
  { value: InspectionTaskSiteStatus.InProgress, label: 'inspection_task_site_status.inprogress' },
  { value: InspectionTaskSiteStatus.Cancelled, label: 'inspection_task_site_status.cancelled' },
  { value: InspectionTaskSiteStatus.Finished, label: 'inspection_task_site_status.finished' }
];