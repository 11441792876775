/**
 * @file: ProductAuthorizationContractStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ProductAuthorizationContractStatus {
  Effective = 'effective',
  Ended = 'ended',
  Canceled = 'canceled',
  Closed = 'closed',
}

export const ProductAuthorizationContractStatusValueSet = new Set([
  ProductAuthorizationContractStatus.Effective,
  ProductAuthorizationContractStatus.Ended,
  ProductAuthorizationContractStatus.Canceled,
  ProductAuthorizationContractStatus.Closed,
]);
