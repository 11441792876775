/**
 * @file: service-subject.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { VehicleServiceSubject, VehicleServiceSubjectListFilter } from 'model';
import { vehicleServiceSubjectService } from 'services';

// tslint:disable-next-line: max-line-length
export const serviceSubjectActions = createListAsyncActionCreators<
  AppState,
  VehicleServiceSubject,
  VehicleServiceSubjectListFilter,
  number
>('inspection.service_subjects', {
  shouldInvalidateForFilter: false,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.serviceSubjects.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.serviceSubjects.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.serviceSubjects.itemsBeingDeleted,
  async fetchHandler(_state: AppState) {
    return await vehicleServiceSubjectService.list(
      // state.inspection.serviceSubjects.filter,
      // state.inspection.serviceSubjects.sorts && state.inspection.serviceSubjects.sorts[0],
      // state.inspection.serviceSubjects.offset,
      // state.inspection.serviceSubjects.limit,
      {},
      null,
      0,
      0,
      { count: false, recursive: true },
    );
  },
  async create(site: Partial<VehicleServiceSubject>) {
    return await vehicleServiceSubjectService.create(site);
  },
  async update(site: VehicleServiceSubject) {
    return await vehicleServiceSubjectService.update(site);
  },
  async delete(site: VehicleServiceSubject) {
    await vehicleServiceSubjectService.delete(site.id);
  },
  async deleteMulti(sites: VehicleServiceSubject[]) {
    if (sites.length === 1) {
      await vehicleServiceSubjectService.delete(sites[0].id);
      return;
    }
    throw new Error('delete multi vehicle service subjects is not supported. ');
  },
});
