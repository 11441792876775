import { AppState } from 'app/duck/states';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { ActionThunk } from 'lib/duck/interfaces';
import {
  VehicleInspectionTemplate,
  VehicleInspectionTemplateListFilter,
} from 'model';
import { vehicleInspectionTemplateService } from 'services';
import { ItemSourceFilter } from 'shared/types';
import { ActionTypes } from '../types';

export const inspectionTemplateActions = createListAsyncActionCreators<
  AppState,
  VehicleInspectionTemplate,
  VehicleInspectionTemplateListFilter,
  number
>('inspection.templates', {
  shouldInvalidateForFilter: false,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.templates.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.templates.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.templates.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await vehicleInspectionTemplateService.list(
      // { storeId: state.activeStoreId || undefined },
      {},
      state.inspection.templates.sorts?.[0],
      state.inspection.templates.offset,
      state.inspection.templates.limit,
      { count: true, recursive: true },
    );
  },
  async create(template: Partial<VehicleInspectionTemplate>) {
    const entity = { ...template };
    if (entity.predefinedType) {
      entity.isPredefined = true;
    }
    return await vehicleInspectionTemplateService.create(entity);
  },
  async update(template: VehicleInspectionTemplate) {
    return await vehicleInspectionTemplateService.update({
      ...template,
      storeId: template.storeId ?? null,
    });
  },
  async delete(template: VehicleInspectionTemplate) {
    await vehicleInspectionTemplateService.delete(template.id);
  },
  async deleteMulti(templates: VehicleInspectionTemplate[]) {
    if (templates.length === 1) {
      await vehicleInspectionTemplateService.delete(templates[0].id);
      return;
    }
    throw new Error('delete multi inspection template is not supported. ');
  },
});

export function duplicateTemplate(
  template: VehicleInspectionTemplate,
): ActionThunk<AppState> {
  return async dispatch => {
    const duplicate: Partial<VehicleInspectionTemplate> = {
      ...template,
      id: undefined,
      disabled: true,
      createdAt: undefined,
      updatedAt: undefined,
      isSystemDefault: false,
      cid: undefined,
    };
    try {
      const res = await vehicleInspectionTemplateService.create(duplicate);
      dispatch(inspectionTemplateActions.createSuccess(duplicate, res));
    } catch (e) {
      dispatch(inspectionTemplateActions.createFailed(duplicate, e));
    }
  };
}

export function templateSourceChanged(source: ItemSourceFilter) {
  return createStandardAction(ActionTypes.TemplateSourceChanged, source);
}
