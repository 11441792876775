/**
 * @file: InspectionJobProductAuthorType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum InspectionJobProductAuthorType {
  ServiceAgent = 'sa',
  Technicians = 'tech',
}

export const InspectionJobProductAuthorTypeValueSet = new Set([
  InspectionJobProductAuthorType.ServiceAgent,
  InspectionJobProductAuthorType.Technicians,
]);
