import {
  ExtendedOrderPropertyOption,
  ExtendedPropertyDefAttachment,
  ExtendedPropertyVisibility,
} from 'model';
import { MouseEvent, memo, useMemo } from 'react';
import {
  EntityEditorForm,
  EntityEditorFormBuilder,
  getString,
} from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';

const kVisibilities: ExtendedPropertyVisibility[] = ['public', 'private'];

const kTypes: ExtendedPropertyDefAttachment['type'][] = [
  'qrcode',
  'image',
  'video',
];

export const ExtendedPropertyAttachmentEditor = memo(
  ({
    attachment,
    onChange,
    onRemove,
  }: {
    attachment: ExtendedPropertyDefAttachment;
    onChange?: (attachment: ExtendedPropertyDefAttachment) => void;
    onRemove?: (attachment: ExtendedPropertyDefAttachment) => void;
  }) => {
    const handleChange = usePersistFn((values: ExtendedOrderPropertyOption) => {
      onChange?.({ ...attachment, ...values });
    });

    const handleRemove = usePersistFn((e: MouseEvent) => {
      e.preventDefault();
      if (
        confirm(
          getString('store.config.extended_property.confirm_remove_option'),
        )
      ) {
        onRemove?.(attachment);
      }
    });

    const form = useMemo(() => {
      const builder =
        new EntityEditorFormBuilder<ExtendedPropertyDefAttachment>();
      builder
        .group(g => {
          g.withClassName('property-editor__attachment-file');
          if (attachment.type === 'video') {
            g.video({
              prop: 'content',
              label: '',
              filePicker: {
                realm: 'config/property/attachments',
                maxSize: 5 * 1024 * 1024,
              },
            });
          } else {
            g.image({
              prop: 'content',
              label: '',
              filePicker: {
                realm: 'config/property/attachments',
                maxSize: 5 * 1024 * 1024,
              },
            });
          }
          return g;
        })
        .group(group =>
          group
            .withClassName('property-editor__attachment-metadata')
            .group(g =>
              g
                .withClassName('property-editor__attachment-type-visibility')
                .select({
                  prop: 'type',
                  label: 'store.config.label.extended_property.attachment.type',
                  options: kTypes.map(type => ({
                    value: type,
                    label: getString(
                      `store.config.label.extended_property.attachment_type.${type}`,
                    ),
                  })),
                })
                .select({
                  prop: 'visibility',
                  label:
                    'store.config.label.extended_property.attachment.visibility',
                  options: kVisibilities.map(type => ({
                    value: type,
                    label: getString(`store.config.prop.visibility.${type}`),
                  })),
                }),
            )
            .text({
              prop: 'title',
              label: 'store.config.label.extended_property.attachment.title',
              placeholder:
                'store.config.placeholder.extended_property.attachment.title',
            }),
        );
      return builder.build();
    }, [attachment.type]);

    return (
      <div className="m-form__section m-form__section--first property-editor__attachment-editor">
        <EntityEditorForm
          entity={attachment}
          onChange={handleChange}
          elements={form.elements}
          autocomplete={form.autocomplete}
          useUncontrolled={form.useUncontrolled}
          helpTextPlacement="after"
        />
        <a
          href="#"
          className="property-editor__attachment-editor-close-button"
          onClick={handleRemove}
          style={{
            position: 'absolute',
            right: '-0.35rem',
            top: '-0.35rem',
            backgroundColor: 'red',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            borderRadius: '50%',
            width: '1.25rem',
            height: '1.25rem',
          }}
        >
          <i className="la la-close" />
        </a>
      </div>
    );
  },
);
