/**
 * @file: TaskAssignmentTargetType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum TaskAssignmentTargetType {
  PreInspection = 0,
  Inspection = 1,
  Construction = 2,
  DeliveryCheck = 3,
}

export const TaskAssignmentTargetTypeValueSet = new Set([
  TaskAssignmentTargetType.PreInspection,
  TaskAssignmentTargetType.Inspection,
  TaskAssignmentTargetType.Construction,
  TaskAssignmentTargetType.DeliveryCheck,
]);
