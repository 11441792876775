import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import { formatMediaUrl } from 'app/integration/helpers';
import { createStandardAction } from 'lib/duck/actions';
import { ActionThunk } from 'lib/duck/interfaces';
import {
  MpConditionalMenuMatchRule,
  MpKeyBasedMenuItem,
  MpKeyBasedMenuItemTypeSet,
  MpMenuInfo,
  MpMenuItem,
  MpMenuItemClick,
  MpMenuItemType,
  WeixinMsg,
  WeixinMsgType,
  WeixinNewsMsg,
  WeixinPermanentMaterialType,
} from 'model';
import { weixinService } from 'services';
import { getString } from 'shared/components';
import { ActionTypes } from '../types';

function loadMenus(): ActionThunk<AppState> {
  return async dispatch => {
    dispatch(
      showAppLoading({ message: getString('integration.menu.loading') }),
    );
    dispatch(createStandardAction(ActionTypes.LoadMenus));
    try {
      const menus = await weixinService.getMenus();
      dispatch(createStandardAction(ActionTypes.LoadMenusSuccess, menus));
    } catch (e) {
      dispatch(createStandardAction(ActionTypes.LoadMenusFailed, e));
    } finally {
      dispatch(hideAppLoading());
    }
  };
}

function activeMenuChanged(id: number) {
  return createStandardAction(ActionTypes.ActiveMenuChanged, id);
}

function addMenu() {
  return createStandardAction(ActionTypes.AddMenu);
}

function removeMenu(id: number) {
  return createStandardAction(ActionTypes.RemoveMenu, id);
}

function removeMenuCommitted() {
  return createStandardAction(ActionTypes.RemoveMenuCommitted);
}

function removeMenuCancelled() {
  return createStandardAction(ActionTypes.RemoveMenuCancelled);
}

function cloneButtonList(buttons: MpMenuItem[]): MpMenuItem[] {
  return buttons.map(button =>
    button.subButton
      ? ({
          name: button.name,
          subButton: cloneButtonList(button.subButton),
        } as any)
      : {
          ...button,
          keys: undefined,
          subButton: undefined,
        },
  );
}

function saveMenu(): ActionThunk<AppState> {
  return async (dispatch, getState) => {
    dispatch(createStandardAction(ActionTypes.SaveMenu));

    const state = getState();
    const defaultMenuEditor = state.integration.menus.editors.find(
      x => x.isDefaultMenu,
    )!;
    const defaultMenu: MpMenuInfo = {
      button: cloneButtonList(defaultMenuEditor.menu.button),
    };
    const conditionalMenus: MpMenuInfo[] = state.integration.menus.editors
      .filter(x => !x.isDefaultMenu)
      .map(x => ({
        button: cloneButtonList(x.menu.button),
        matchrule: x.menu.matchrule,
      }));

    const cleanedData = (data: any) => {
      if (data && typeof data === 'object') {
        if ('__isTempMedia' in data) {
          data = { ...data, __isTempMedia: undefined };
        } else {
          for (const p in data) {
            if (Object.prototype.hasOwnProperty.call(data, p)) {
              data[p] = cleanedData(data[p]);
            }
          }
        }
        return data;
      }
      return data;
    };

    const keyData: { [key: string]: any } = {};

    for (const editor of state.integration.menus.editors) {
      for (const button of editor.menu.button) {
        if (button.type && MpKeyBasedMenuItemTypeSet.has(button.type)) {
          const item = button as MpKeyBasedMenuItem;
          const data = state.integration.menus.keyData[item.key];
          if (!data) {
            throw new Error(`menu item key data of ${item.key} does not exist`);
          }
          keyData[item.key] = cleanedData(data);
        }
        if (button.subButton) {
          for (const subButton of button.subButton) {
            if (MpKeyBasedMenuItemTypeSet.has(subButton.type)) {
              const subItem = subButton as MpKeyBasedMenuItem;
              const subData = state.integration.menus.keyData[subItem.key];
              if (!subData) {
                throw new Error(
                  `sub menu item key data of ${subItem.key} does not exist`,
                );
              }
              keyData[subItem.key] = cleanedData(subData);
            }
          }
        }
      }
    }

    try {
      console.log({ defaultMenu, conditionalMenus, keyData });

      // setTimeout(() => {
      //   // dispatch(createStandardAction(ActionTypes.SaveMenuSuccess, { conditionalMenuIds: [] }));
      //   dispatch(createStandardAction(ActionTypes.SaveMenuFailed, new Error('service temporarily unavailable. ')));
      // }, 2000);

      // return;

      const conditionalMenuIds = await weixinService.saveMenus(
        defaultMenu,
        conditionalMenus,
        keyData,
      );
      dispatch(
        createStandardAction(ActionTypes.SaveMenuSuccess, {
          conditionalMenuIds,
        }),
      );
    } catch (e) {
      dispatch(createStandardAction(ActionTypes.SaveMenuFailed, e));
    }
  };
}

function addMenuItem(editorId: number, name: string, parentIndex?: number) {
  return createStandardAction(ActionTypes.AddMenuItem, {
    editorId,
    name,
    parentIndex,
  });
}

function removeMenuItem(editorId: number, index: number, parentIndex?: number) {
  return createStandardAction(ActionTypes.RemoveMenuItem, {
    editorId,
    index,
    parentIndex,
  });
}

function removeMenuItemCommitted() {
  return createStandardAction(ActionTypes.RemoveMenuItemCommitted);
}

function removeMenuItemCancelled() {
  return createStandardAction(ActionTypes.RemoveMenuItemCancelled);
}

function menuItemTypeChanged(
  editorId: number,
  path: number[],
  type: MpMenuItemType,
) {
  return createStandardAction(ActionTypes.MenuItemTypeChanged, {
    editorId,
    path,
    type,
  });
}

function menuItemChanged(
  editorId: number,
  path: number[],
  changes: any,
  data?: any,
) {
  return createStandardAction(ActionTypes.MenuItemChanged, {
    editorId,
    path,
    changes,
    data,
  });
}

function clickMenuItemMsgTypeChanged(
  editorId: number,
  path: number[],
  msgType: WeixinMsgType,
) {
  return createStandardAction(ActionTypes.ClickMenuItemMsgTypeChanged, {
    editorId,
    path,
    msgType,
  });
}

function menuItemMoved(
  editorId: number,
  parentIndex: number | undefined,
  fromIndex: number,
  toIndex: number,
) {
  return createStandardAction(ActionTypes.MenuItemMoved, {
    editorId,
    parentIndex,
    fromIndex,
    toIndex,
  });
}

function activeMenuItemChanged(editorId: number, indexPath: number[] | null) {
  return createStandardAction(ActionTypes.ActiveMenuItemChanged, {
    editorId,
    indexPath,
  });
}

function loadMenuItemData(
  editorId: number,
  indexPath: number[],
): ActionThunk<AppState> {
  return async (dispatch, getState) => {
    const state = getState();
    const editor = state.integration.menus.editors.find(
      x => x.id === editorId,
    )!;
    if (
      editor.menuItemDataBeingLoadedIndexPath &&
      editor.menuItemDataBeingLoadedIndexPath.length === indexPath.length &&
      editor.menuItemDataBeingLoadedIndexPath.join('/') === indexPath.join('/')
    ) {
      return;
    }

    const button =
      indexPath.length === 1
        ? editor.menu.button[indexPath[0]]
        : editor.menu.button[indexPath[0]].subButton![indexPath[1]];
    if (button.type === MpMenuItemType.Click) {
      const clickButton = button as MpMenuItemClick;
      const data = state.integration.menus.keyData[
        clickButton.key
      ] as WeixinMsg;
      if (data.msgType === WeixinMsgType.News) {
        let newsData = data as WeixinNewsMsg;
        if (newsData.mediaId) {
          dispatch(
            createStandardAction(ActionTypes.LoadMenuItemData, {
              editorId,
              indexPath,
              msgType: data.msgType,
            }),
          );
          try {
            const newsInfo = await weixinService.fetchPermanentMaterial(
              newsData.mediaId,
              WeixinPermanentMaterialType.News,
            );

            newsData = { ...newsData };
            newsData.articleCount = newsInfo.newsItem.length;
            newsData.items = newsInfo.newsItem.map(x => ({
              title: x.title,
              description: x.digest,
              picUrl: x.thumbMediaId
                ? formatMediaUrl(x.thumbMediaId, 'image', false)
                : '',
              url: x.url,
            }));
            dispatch(
              createStandardAction(ActionTypes.LoadMenuItemDataSuccess, {
                editorId,
                indexPath,
                msgType: data.msgType,
                data: newsData,
              }),
            );
          } catch (e) {
            dispatch(
              createStandardAction(ActionTypes.LoadMenuItemDataFailed, {
                editorId,
                indexPath,
                msgType: data.msgType,
                error: e,
              }),
            );
          }
        }
      }
    }
  };
}

export function onMenuItemError(
  editorId: number,
  indexPath: number[],
  name: string,
  error: string | null,
) {
  return createStandardAction(ActionTypes.OnMenuItemError, {
    editorId,
    indexPath,
    name,
    error,
  });
}

export function conditionalMenuMatchRuleChanged(
  editorId: number,
  changes: Partial<MpConditionalMenuMatchRule>,
) {
  return createStandardAction(ActionTypes.ConditionalMenuMatchRuleChanged, {
    editorId,
    changes,
  });
}

export function dismissConditionalMenuTip() {
  return createStandardAction(ActionTypes.HideConditionaMenuTip);
}

export const weixinMenuActions = {
  loadMenus,
  activeMenuChanged,
  addMenu,
  removeMenu,
  removeMenuCommitted,
  removeMenuCancelled,
  saveMenu,
  addMenuItem,
  removeMenuItem,
  removeMenuItemCommitted,
  removeMenuItemCancelled,
  menuItemTypeChanged,
  menuItemChanged,
  menuItemMoved,
  activeMenuItemChanged,
  clickMenuItemMsgTypeChanged,
  loadMenuItemData,
  onMenuItemError,
  conditionalMenuMatchRuleChanged,
  dismissConditionalMenuTip,
};
