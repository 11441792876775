import { CSSProperties, memo, useEffect, useState } from 'react';
import { storeService } from 'services';

export const StoreNameLabel = memo(
  ({ storeId, style }: { storeId: number; style?: CSSProperties }) => {
    const [name, setName] = useState<string>();
    useEffect(() => {
      storeService
        .get(storeId)
        .then(store => {
          setName(store?.name);
        })
        .catch(err => {
          console.error(err);
        });
    }, [storeId]);
    if (!name) return null;
    return <span style={style}>{name}</span>;
  },
);
