/**
 * @file: template-detail.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* eslint no-shadow: "off" */
/* tslint:disable no-shadowed-variable */

import {
  createOrderComparerFromMap,
  findTargetGroupIdBySiteId,
  makeId,
} from 'app/inspection/common/helpers';
import { StandardAction } from 'lib/duck/interfaces';
import {
  InspectionTemplateCategory,
  InspectionTemplateConf,
  InspectionTemplateGroup,
} from 'model/viewmodel/InspectionTemplateConf';
import { arr2map, array_move, array_moved } from 'utils';
import { InspectionTemplateDetail } from '../states';
import { ActionTypes } from '../types';

const initialState: InspectionTemplateDetail = {
  conf: null,
  selectedGroupId: null,
  showPreview: false,
  showSiteList: false,
  categoryIdBeingRemoved: null,
  groupIdBeingRemoved: null,
  siteIdToGroupIdMap: new Map(),
  groupIdToCategoryIdMap: new Map(),
  allGroupsSiteListSortType: 'workflow',
  siteListSortType: 'default',
};

function buildConfMaps(conf: InspectionTemplateConf) {
  const siteIdToGroupIdMap = new Map<number, string>();
  const groupIdToCategoryIdMap = new Map<string, string>();
  for (const category of conf.categories) {
    for (const group of category.groups) {
      groupIdToCategoryIdMap.set(group.id, category.id);
      for (const siteId of group.siteIds) {
        siteIdToGroupIdMap.set(siteId, group.id);
      }
    }
  }
  return {
    siteIdToGroupIdMap,
    groupIdToCategoryIdMap,
  };
}

export default function (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: InspectionTemplateDetail = initialState,
  action: StandardAction<any>,
): InspectionTemplateDetail {
  switch (action.type) {
    case ActionTypes.TemplateDetailReady: {
      const { templateId, conf } = action.payload as {
        templateId: number;
        conf: InspectionTemplateConf;
      };
      return {
        ...state,
        templateId,
        conf,
        // reset detail states
        selectedGroupId: null,
        showPreview: false,
        showSiteList: false,
        categoryIdBeingRemoved: null,
        groupIdBeingRemoved: null,
        categoryIdWhoseNameIsBeingEdited: null,
        categoryNameBeingEdited: null,
        groupIdWhoseNameIsBeingEdited: null,
        categoryIdForNewGroup: null,
        groupNameBeingEdited: null,
        siteListSortType: 'default',
        ...buildConfMaps(conf),
      };
    }
    case ActionTypes.TemplateDetailUpdated: {
      const conf = action.payload as InspectionTemplateConf;
      return { ...state, conf, ...buildConfMaps(conf) };
    }

    ////////////////////////////////////////////////////////////////
    // begin category related action handlers
    ////////////////////////////////////////////////////////////////
    case ActionTypes.TemplateDetailAddCategory: {
      return { ...state, categoryNameBeingEdited: '' };
    }
    case ActionTypes.TemplateDetailEditCategory: {
      const categoryId = action.payload as string;
      const category = state.conf!.categories.find(x => x.id === categoryId)!;
      return {
        ...state,
        categoryIdWhoseNameIsBeingEdited: categoryId,
        categoryNameBeingEdited: category.name,
      };
    }
    case ActionTypes.TemplateDetailEditCategoryChanged: {
      return {
        ...state,
        categoryNameBeingEdited: action.payload,
      };
    }
    case ActionTypes.TemplateDetailEditCategoryCommitted: {
      const categoryId = state.categoryIdWhoseNameIsBeingEdited;
      if (categoryId) {
        return stateWithCategoryUpdated(
          state,
          categoryId,
          category => {
            category.name = action.payload || state.categoryNameBeingEdited!;
            // tslint:disable-next-line: no-shadowed-variable
          },
          // eslint-disable-next-line @typescript-eslint/no-shadow
          state => {
            return {
              ...state,
              categoryIdWhoseNameIsBeingEdited: null,
              categoryNameBeingEdited: null,
            };
          },
        );
      } else {
        return stateWithCategoriesUpdated(
          state,
          categories => {
            categories.push({
              id: makeId(),
              name: action.payload || state.categoryNameBeingEdited!,
              groups: [],
              expanded: true,
            });
            // tslint:disable-next-line: no-shadowed-variable
          },
          // eslint-disable-next-line @typescript-eslint/no-shadow
          state => {
            return {
              ...state,
              categoryIdWhoseNameIsBeingEdited: null,
              categoryNameBeingEdited: null,
            };
          },
        );
      }
    }
    case ActionTypes.TemplateDetailEditCategoryCancelled: {
      return {
        ...state,
        categoryIdWhoseNameIsBeingEdited: null,
        categoryNameBeingEdited: null,
      };
    }
    case ActionTypes.TemplateDetailRemoveCategory: {
      return { ...state, categoryIdBeingRemoved: action.payload };
    }
    case ActionTypes.TemplateDetailCommitRemoveCategory: {
      if (!state.categoryIdBeingRemoved) return state;
      return stateWithCategoryUpdated(
        state,
        state.categoryIdBeingRemoved,
        'remove',
        // eslint-disable-next-line @typescript-eslint/no-shadow
        state => {
          return { ...state, categoryIdBeingRemoved: null };
        },
      );
    }
    case ActionTypes.TemplateDetailCancelRemoveCategory: {
      if (!state.categoryIdBeingRemoved) return state;
      return { ...state, categoryIdBeingRemoved: null };
    }
    case ActionTypes.TemplateDetailExpandCategory: {
      const id = action.payload as string;
      return stateWithCategoryUpdated(state, id, category => {
        category.expanded = true;
      });
    }
    case ActionTypes.TemplateDetailCollapseCategory: {
      const id = action.payload as string;
      return stateWithCategoryUpdated(state, id, category => {
        category.expanded = false;
      });
    }
    case ActionTypes.TemplateDetailCategoryMoved: {
      const { from, to } = action.payload as {
        id: string;
        from: number;
        to: number;
      };
      return stateWithCategoriesUpdated(state, categories => {
        array_move(categories, from, to);
      });
    }

    ////////////////////////////////////////////////////////////////
    // begin group related action handlers
    ////////////////////////////////////////////////////////////////
    case ActionTypes.TemplateDetailAddGroup: {
      const categoryId = action.payload as string;
      return {
        ...state,
        categoryIdForNewGroup: categoryId,
        groupNameBeingEdited: '',
        groupIdWhoseNameIsBeingEdited: null,
      };
    }
    case ActionTypes.TemplateDetailEditGroup: {
      const { id, name } = action.payload;
      return {
        ...state,
        categoryIdForNewGroup: null,
        groupIdWhoseNameIsBeingEdited: id,
        groupNameBeingEdited: name,
      };
    }
    case ActionTypes.TemplateDetailEditGroupChanged: {
      return {
        ...state,
        groupNameBeingEdited: action.payload,
      };
    }
    case ActionTypes.TemplateDetailEditGroupCommitted: {
      const groupId = state.groupIdWhoseNameIsBeingEdited;
      if (!groupId) {
        const categoryId = state.categoryIdForNewGroup!;
        const id = makeId();
        const name = action.payload || state.groupNameBeingEdited!;
        return stateWithCategoryUpdated(
          state,
          categoryId,
          category => {
            const group = { id, categoryId, name, siteIds: [] };
            category.groups = [...category.groups, group];
          },
          // eslint-disable-next-line @typescript-eslint/no-shadow
          state => {
            const groupIdToCategoryIdMap = mapUpdated(
              state.groupIdToCategoryIdMap,
              id,
              categoryId,
            );
            return {
              ...state,
              groupIdToCategoryIdMap,
              selectedGroupId: id,
              groupIdWhoseNameIsBeingEdited: null,
              groupNameBeingEdited: null,
              categoryIdForNewGroup: null,
            };
          },
        );
      } else {
        return stateWithGroupUpdated(
          state,
          groupId,
          group => {
            group.name = action.payload || state.groupNameBeingEdited!;
          },
          // eslint-disable-next-line @typescript-eslint/no-shadow
          state => {
            return {
              ...state,
              groupIdWhoseNameIsBeingEdited: null,
              groupNameBeingEdited: null,
              categoryIdForNewGroup: null,
            };
          },
        );
      }
    }
    case ActionTypes.TemplateDetailEditGroupCancelled: {
      return {
        ...state,
        groupIdWhoseNameIsBeingEdited: null,
        categoryIdForNewGroup: null,
        groupNameBeingEdited: null,
      };
    }
    case ActionTypes.TemplateDetailRemoveGroup: {
      return {
        ...state,
        groupIdBeingRemoved: action.payload,
      };
    }
    case ActionTypes.TemplateDetailCommitRemoveGroup: {
      const id = state.groupIdBeingRemoved!;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      return stateWithGroupUpdated(state, id, 'remove', state => {
        return { ...state, groupIdBeingRemoved: null };
      });
    }
    case ActionTypes.TemplateDetailCancelRemoveGroup: {
      return {
        ...state,
        groupIdBeingRemoved: null,
      };
    }
    case ActionTypes.TemplateDetailGroupMoved: {
      const { id, from, to } = action.payload as {
        id: string;
        from: number;
        to: number;
      };
      const categoryId = state.groupIdToCategoryIdMap.get(id)!;
      return stateWithCategoryUpdated(state, categoryId, category => {
        category.groups = array_moved(category.groups, from, to);
      });
    }

    ////////////////////////////////////////////////////////////////
    // begin site related action handlers
    ////////////////////////////////////////////////////////////////
    case ActionTypes.TemplateDetailAddSite: {
      const { groupId, siteId } = action.payload as {
        groupId: string;
        siteId: number;
      };

      return stateWithSiteAdded(state, groupId, siteId);
    }
    case ActionTypes.TemplateDetailAddSites: {
      const { groupId, siteIds } = action.payload as {
        groupId: string;
        siteIds: number[];
      };
      return siteIds.reduce((res, siteId) => {
        return stateWithSiteAdded(res, groupId, siteId);
      }, state);
    }

    case ActionTypes.TemplateDetailRemoveSite: {
      const payload = action.payload as {
        groupId: string;
        siteId: number;
      };
      const groupId = findTargetGroupIdBySiteId(state, payload.siteId);
      if (groupId) {
        return stateWithSiteRemoved(state, payload.groupId, payload.siteId);
      }
      return state;
    }

    case ActionTypes.TemplateDetailRemoveSites: {
      const { groupId, siteIds, confirm } = action.payload as {
        groupId: string;
        siteIds: number[];
        confirm?: boolean;
      };
      if (confirm) {
        return {
          ...state,
          siteIdsBeingDeleted: siteIds,
        };
      }
      return siteIds.reduce((res, siteId) => {
        return stateWithSiteRemoved(res, groupId, siteId);
      }, state);
    }

    case ActionTypes.TemplateDetailRemoveSitesCommitted: {
      const siteIdSet = new Set(state.siteIdsBeingDeleted!);
      return stateWithGroupUpdated(
        state,
        state.selectedGroupId!,
        group => {
          group.siteIds = group.siteIds.filter(x => !siteIdSet.has(x));
          if (group.selection) {
            group.selection = group.selection.filter(x => !siteIdSet.has(x));
          }
          if (group.disabledSiteIds) {
            group.disabledSiteIds = group.disabledSiteIds.filter(
              x => !siteIdSet.has(x),
            );
          }
          if (group.defaultHiddenSiteIds) {
            group.defaultHiddenSiteIds = group.defaultHiddenSiteIds.filter(
              x => !siteIdSet.has(x),
            );
          }
          if (group.requiredSiteIds) {
            group.requiredSiteIds = group.requiredSiteIds.filter(
              x => !siteIdSet.has(x),
            );
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        state => {
          const siteIdToGroupIdMap = state.siteIdsBeingDeleted!.reduce(
            (res, siteId) => mapKeyRemoved(res, siteId),
            state.siteIdToGroupIdMap,
          );
          return {
            ...state,
            siteIdToGroupIdMap,
            siteIdsBeingDeleted: undefined,
          };
        },
      );
    }

    case ActionTypes.TemplateDetailRemoveSitesCancelled: {
      return {
        ...state,
        siteIdsBeingDeleted: undefined,
      };
    }
    case ActionTypes.TemplateDetailSiteSelected: {
      const siteId = action.payload as number;
      const groupId = findTargetGroupIdBySiteId(state, siteId);
      if (!groupId) return state;
      return stateWithGroupUpdated(state, groupId, group => {
        group.selection = [...(group.selection || []), siteId];
      });
    }
    case ActionTypes.TemplateDetailSiteDeselected: {
      const siteId = action.payload as number;
      const groupId = findTargetGroupIdBySiteId(state, siteId);
      if (!groupId) return state;
      return stateWithGroupUpdated(state, groupId, group => {
        group.selection = group.selection!.filter(x => x !== siteId);
      });
    }
    case ActionTypes.TemplateDetailToggleSelectAll: {
      const isAllSelectedOfGroup = (group: InspectionTemplateGroup) => {
        let isAllSelected = false;
        if (group.selection) {
          const set = new Set(group.selection);
          isAllSelected = group.siteIds.every(x => set.has(x));
        }
        return isAllSelected;
      };

      const performToggleAllSelection = (
        group: InspectionTemplateGroup,
        isAllSelected: boolean,
      ) => {
        if (isAllSelected) {
          group.selection = [];
        } else {
          group.selection = group.siteIds.slice();
        }
      };

      if (state.allGroupsSelectedCategoryId) {
        return stateWithCategoryUpdated(
          state,
          state.allGroupsSelectedCategoryId,
          category => {
            const allSelectedGroupIds = new Set<string>();
            for (const group of category.groups) {
              const isAllSelected = isAllSelectedOfGroup(group);
              if (isAllSelected) {
                allSelectedGroupIds.add(group.id);
              }
            }
            const isAllGroupsSelected = category.groups.every(x =>
              allSelectedGroupIds.has(x.id),
            );
            const groups = [...category.groups];
            for (let i = 0; i < groups.length; i++) {
              const group = { ...groups[i] };
              performToggleAllSelection(group, isAllGroupsSelected);
              groups[i] = group;
            }
            category.groups = groups;
          },
        );
      } else {
        return stateWithGroupUpdated(state, state.selectedGroupId!, group => {
          const isAllSelected = isAllSelectedOfGroup(group);
          performToggleAllSelection(group, isAllSelected);
        });
      }
    }

    case ActionTypes.TemplateDetailSiteMoved: {
      const { groupId, from, to } = action.payload as {
        groupId: string;
        siteId: number;
        from: number;
        to: number;
      };

      if (state.allGroupsSelectedCategoryId) {
        const categoryId = state.allGroupsSelectedCategoryId;
        if (state.allGroupsSiteListSortType === 'default') {
          return stateWithCategoryUpdated(state, categoryId, category => {
            let list = category.groups.reduce<number[]>((res, g) => {
              return res.concat(g.siteIds);
            }, []);
            const listComparer = createOrderComparerFromMap(
              list,
              x => x,
              category.defaultOrders,
            );
            list.sort(listComparer);
            list = array_moved(list, from, to);
            category.defaultOrders = arr2map(
              list,
              x => x,
              (_, i) => i + 1,
            );
          });
        } else {
          return stateWithCategoryUpdated(state, categoryId, category => {
            let list = category.groups.reduce<number[]>((res, g) => {
              return res.concat(g.siteIds);
            }, []);
            const listComparer = createOrderComparerFromMap(
              list,
              x => x,
              category.workflowOrders,
            );
            list.sort(listComparer);
            list = array_moved(list, from, to);
            category.workflowOrders = arr2map(
              list,
              x => x,
              (_, i) => i + 1,
            );
          });
        }
      } else {
        if (state.siteListSortType === 'default') {
          return stateWithGroupUpdated(state, groupId, group => {
            group.siteIds = array_moved(group.siteIds, from, to);
          });
        }

        return stateWithGroupUpdated(state, groupId, group => {
          let siteIds = [...group.siteIds];
          const listComparer = createOrderComparerFromMap(
            group.siteIds,
            x => x,
            group.workflowOrders,
          );
          siteIds.sort(listComparer);
          siteIds = array_moved(siteIds, from, to);
          group.workflowOrders = arr2map(
            siteIds,
            x => x,
            (_, i) => i + 1,
          );
        });
      }
    }
    case ActionTypes.TemplateDetailSiteDisabled: {
      const { groupId, siteId } = action.payload as {
        groupId: string;
        siteId: number;
      };
      return stateWithGroupSiteEnableOrDisable(state, groupId, siteId, true);
    }
    case ActionTypes.TemplateDetailSiteEnabled: {
      const { groupId, siteId } = action.payload as {
        groupId: string;
        siteId: number;
      };
      return stateWithGroupSiteEnableOrDisable(state, groupId, siteId, false);
    }
    case ActionTypes.TemplateDetailSiteDefaultHiddenSet: {
      const { groupId, siteId, isDefaultHidden } = action.payload as {
        groupId: string;
        siteId: number;
        isDefaultHidden: boolean;
      };
      return stateWithGroupSiteDefaultHiddenStatusChanged(
        state,
        groupId,
        siteId,
        isDefaultHidden,
      );
    }
    case ActionTypes.TemplateDetailSiteRequiredSet: {
      const { groupId, siteId, required } = action.payload as {
        groupId: string;
        siteId: number;
        required: boolean;
      };
      return stateWithGroupSiteRequiredStatusChanged(
        state,
        groupId,
        siteId,
        required,
      );
    }
    case ActionTypes.TemplateDetailSiteListKeywordChange: {
      const keyword = action.payload as string;
      if (state.selectedGroupId) {
        return stateWithGroupUpdated(state, state.selectedGroupId!, group => {
          group.keyword = keyword;
        });
      }
      if (state.allGroupsSelectedCategoryId) {
        return stateWithCategoryUpdated(
          state,
          state.allGroupsSelectedCategoryId,
          category => {
            category.keyword = keyword;
          },
        );
      }
      return state;
    }
    case ActionTypes.TemplateDetailEditSiteRels: {
      return {
        ...state,
        siteIdWhoseRelsAreBeingEdited: action.payload,
      };
    }
    case ActionTypes.TemplateDetailEndEditSiteRels: {
      return {
        ...state,
        siteIdWhoseRelsAreBeingEdited: undefined,
      };
    }
    case ActionTypes.TemplateDetailSiteRelsChanged: {
      const { siteId, rels } = action.payload as {
        siteId: number;
        rels: number[];
      };
      const groupId = findTargetGroupIdBySiteId(state, siteId);
      if (!groupId) return state;
      return stateWithGroupUpdated(state, groupId, group => {
        group.siteRels = { ...(group.siteRels || {}) };
        group.siteRels[siteId] = rels;
      });
    }
    case ActionTypes.TemplateDetailRemoveSiteRels: {
      const { siteId, rels } = action.payload as {
        siteId: number;
        rels: number[];
      };
      const groupId = findTargetGroupIdBySiteId(state, siteId);
      if (!groupId) return state;
      return stateWithGroupUpdated(state, groupId, group => {
        const set = new Set(rels);
        group.siteRels = { ...(group.siteRels || {}) };
        const oldRels = group.siteRels[siteId] || [];
        group.siteRels[siteId] = oldRels.filter(x => !set.has(x));
      });
    }
    case ActionTypes.TemplateDetailSiteRelMoved: {
      const { siteId, from, to } = action.payload as {
        siteId: number;
        from: number;
        to: number;
      };
      const groupId = findTargetGroupIdBySiteId(state, siteId);
      if (!groupId) return state;
      return stateWithGroupUpdated(state, groupId, group => {
        const rels = array_moved(group.siteRels![siteId]!, from, to);
        group.siteRels = { ...group.siteRels! };
        group.siteRels[siteId] = rels;
      });
    }
    case ActionTypes.TemplateDetailGroupSelected: {
      return {
        ...state,
        selectedGroupId: action.payload,
        allGroupsSelectedCategoryId: null,
      };
    }
    case ActionTypes.TemplateDetailAllGroupsSelected: {
      return {
        ...state,
        selectedGroupId: null,
        allGroupsSelectedCategoryId: action.payload,
      };
    }
    case ActionTypes.TemplateDetailShowPreview: {
      return { ...state, showPreview: true };
    }
    case ActionTypes.TemplateDetailHidePreview: {
      return { ...state, showPreview: false };
    }
    case ActionTypes.TemplateDetailShowSiteList: {
      return { ...state, showSiteList: true };
    }
    case ActionTypes.TemplateDetailHideSiteList: {
      return { ...state, showSiteList: false };
    }
    case ActionTypes.TemplateDetailSave: {
      return { ...state, isSaving: true, saveError: undefined };
    }
    case ActionTypes.TemplateDetailSaveSuccess: {
      const conf = action.payload as InspectionTemplateConf;
      return { ...state, conf, isSaving: false };
    }
    case ActionTypes.TemplateDetailSaveFailed: {
      return { ...state, isSaving: false, saveError: action.payload };
    }
    case ActionTypes.TemplateDetailSiteListSortTypeChanged: {
      if (state.allGroupsSelectedCategoryId) {
        return {
          ...state,
          allGroupsSiteListSortType: action.payload,
        };
      }
      return {
        ...state,
        siteListSortType: action.payload,
      };
    }
    default:
      return state;
  }
}

function stateWithSiteAdded(
  state: InspectionTemplateDetail,
  groupId: string,
  siteId: number,
) {
  let siteIdToGroupIdMap = state.siteIdToGroupIdMap;

  // remove the site from other groups if necessary
  const existingGroupId = siteIdToGroupIdMap.get(siteId);
  if (existingGroupId) {
    if (existingGroupId === groupId) return state;
    state = stateWithGroupUpdated(state, existingGroupId, group => {
      group.siteIds = group.siteIds.slice();
      const idx = group.siteIds.indexOf(siteId);
      if (idx >= 0) group.siteIds.splice(idx, 1);
    });
  }

  return stateWithGroupUpdated(
    state,
    groupId,
    group => {
      group.siteIds = [...group.siteIds, siteId];
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    state => {
      siteIdToGroupIdMap = mapUpdated(siteIdToGroupIdMap, siteId, groupId);
      return { ...state, siteIdToGroupIdMap };
    },
  );
}

function stateWithSiteRemoved(
  state: InspectionTemplateDetail,
  groupId: string,
  siteId: number,
) {
  return stateWithGroupUpdated(
    state,
    groupId,
    group => {
      let k = group.siteIds.findIndex(x => x === siteId);
      if (k >= 0) {
        group.siteIds = group.siteIds.slice();
        group.siteIds.splice(k, 1);
      }
      if (group.disabledSiteIds?.length) {
        k = group.disabledSiteIds.findIndex(x => x === siteId);
        if (k >= 0) {
          group.disabledSiteIds = group.disabledSiteIds.slice();
          group.disabledSiteIds.splice(k, 1);
        }
      }
      if (group.requiredSiteIds?.length) {
        k = group.requiredSiteIds.findIndex(x => x === siteId);
        if (k >= 0) {
          group.requiredSiteIds = group.requiredSiteIds.slice();
          group.requiredSiteIds.splice(k, 1);
        }
      }
      if (group.defaultHiddenSiteIds?.length) {
        k = group.defaultHiddenSiteIds.findIndex(x => x === siteId);
        if (k >= 0) {
          group.defaultHiddenSiteIds = group.defaultHiddenSiteIds.slice();
          group.defaultHiddenSiteIds.splice(k, 1);
        }
      }
      if (group.selection?.length) {
        k = group.selection.findIndex(x => x === siteId);
        if (k >= 0) {
          group.selection = group.selection.slice();
          group.selection.splice(k, 1);
        }
      }
      if (
        group.workflowOrders &&
        Object.prototype.hasOwnProperty.call(group.workflowOrders, siteId)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete group.workflowOrders[siteId];
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    state => {
      const siteIdToGroupIdMap = mapKeyRemoved(
        state.siteIdToGroupIdMap,
        siteId,
      );
      if (state.allGroupsSelectedCategoryId) {
        return stateWithCategoryUpdated(
          state,
          state.allGroupsSelectedCategoryId,
          category => {
            if (category.defaultOrders) {
              // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
              delete category.defaultOrders[siteId];
            }
            if (category.workflowOrders) {
              // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
              delete category.workflowOrders[siteId];
            }
          },
        );
      }
      return { ...state, siteIdToGroupIdMap };
    },
  );
}

function stateWithCategoriesUpdated(
  state: InspectionTemplateDetail,
  update: (categories: InspectionTemplateCategory[]) => void,
  stateUpdated?: (state: InspectionTemplateDetail) => InspectionTemplateDetail,
): InspectionTemplateDetail {
  const categories = state.conf!.categories.slice();
  update(categories);
  const conf = { ...state.conf!, categories };
  state = { ...state, conf };
  return stateUpdated ? stateUpdated(state) : state;
}

function stateWithCategoryUpdated(
  state: InspectionTemplateDetail,
  categoryId: string,
  update: ((category: InspectionTemplateCategory) => void) | 'remove',
  stateUpdated?: (state: InspectionTemplateDetail) => InspectionTemplateDetail,
) {
  return stateWithCategoriesUpdated(
    state,
    categories => {
      const index = categories.findIndex(x => x.id === categoryId);
      if (index < 0) {
        return;
      }
      const category = { ...categories[index] };
      if (update === 'remove') {
        categories.splice(index, 1);
      } else {
        update(category);
        categories[index] = category;
      }
    },
    stateUpdated,
  );
}

function categoriesWithGroupUpdated(
  state: InspectionTemplateDetail,
  groupId: string,
  update: ((group: InspectionTemplateGroup) => void) | 'remove',
): InspectionTemplateCategory[] {
  const categories = [...state.conf!.categories];
  const categoryId = state.groupIdToCategoryIdMap.get(groupId)!;
  const i = categories.findIndex(x => x.id === categoryId);
  const category = { ...categories[i] };
  category.groups = category.groups.slice();
  const j = category.groups.findIndex(x => x.id === groupId);
  if (update === 'remove') {
    category.groups.splice(j, 1);
  } else {
    const group = { ...category.groups[j] };
    update(group);
    category.groups[j] = group;
  }
  categories[i] = category;

  return categories;
}

function stateWithGroupUpdated(
  state: InspectionTemplateDetail,
  groupId: string,
  update: ((group: InspectionTemplateGroup) => void) | 'remove',
  stateUpdated?: (state: InspectionTemplateDetail) => InspectionTemplateDetail,
): InspectionTemplateDetail {
  const categories = categoriesWithGroupUpdated(state, groupId, update);
  const conf = { ...state.conf!, categories };
  state = { ...state, conf };
  return stateUpdated ? stateUpdated(state) : state;
}

function stateWithGroupSiteEnableOrDisable(
  state: InspectionTemplateDetail,
  groupId: string,
  siteId: number,
  disabled: boolean,
): InspectionTemplateDetail {
  return stateWithGroupUpdated(state, groupId, group => {
    group.disabledSiteIds = group.disabledSiteIds
      ? group.disabledSiteIds.slice()
      : [];
    if (disabled) {
      if (!group.disabledSiteIds.includes(siteId)) {
        group.disabledSiteIds.push(siteId);
      }
    } else {
      const index = group.disabledSiteIds.findIndex(x => x === siteId);
      if (index >= 0) group.disabledSiteIds.splice(index, 1);
    }
  });
}

function stateWithGroupSiteDefaultHiddenStatusChanged(
  state: InspectionTemplateDetail,
  groupId: string,
  siteId: number,
  isDefaultHidden: boolean,
): InspectionTemplateDetail {
  return stateWithGroupUpdated(state, groupId, group => {
    group.defaultHiddenSiteIds = group.defaultHiddenSiteIds
      ? group.defaultHiddenSiteIds.slice()
      : [];
    if (isDefaultHidden) {
      if (!group.defaultHiddenSiteIds.includes(siteId)) {
        group.defaultHiddenSiteIds.push(siteId);
      }
    } else {
      const index = group.defaultHiddenSiteIds.findIndex(x => x === siteId);
      if (index >= 0) group.defaultHiddenSiteIds.splice(index, 1);
    }
  });
}

function stateWithGroupSiteRequiredStatusChanged(
  state: InspectionTemplateDetail,
  groupId: string,
  siteId: number,
  required: boolean,
): InspectionTemplateDetail {
  return stateWithGroupUpdated(state, groupId, group => {
    group.requiredSiteIds = group.requiredSiteIds
      ? group.requiredSiteIds.slice()
      : [];
    if (required) {
      if (!group.requiredSiteIds.includes(siteId)) {
        group.requiredSiteIds.push(siteId);
      }
    } else {
      const index = group.requiredSiteIds.findIndex(x => x === siteId);
      if (index >= 0) group.requiredSiteIds.splice(index, 1);
    }
  });
}

// map operation helpers
function mapUpdated<T = any, U = any>(
  map: Map<T, U> | undefined | null,
  key: T,
  value: U,
): Map<T, U> {
  const newMap = map ? new Map<T, U>([...map]) : new Map<T, U>();
  newMap.set(key, value);
  return newMap;
}

function mapKeyRemoved<T = any, U = any>(
  map: Map<T, U> | undefined | null,
  key: T,
): Map<T, U> {
  const newMap = map ? new Map<T, U>([...map]) : new Map<T, U>();
  newMap.delete(key);
  return newMap;
}
