import { AppState } from 'app/duck/states';
import { permanentMaterialActions } from 'app/integration/duck/actions';
import { WeixinPermanentMaterialList } from 'app/integration/duck/states';
import { formatMediaUrl, getProxiedImgUrl } from 'app/integration/helpers';
import classNames from 'classnames';
import { DispatchFn } from 'lib/duck/interfaces';
import { Block, Button } from 'lib/metronic/components';
import {
  WeixinPermanentMaterialListItem,
  WeixinPermanentMaterialType,
  WeixinPermanentNewsMaterialListItem,
  WeixinPermanentNonNewsMaterialListItem,
  WeixinPermanentVideoMaterialListItem,
} from 'model';
import React, { MouseEvent, PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { ThunkDispatch } from 'redux-thunk';
import { getString } from 'shared/components';
import { formatDate, formatTime } from 'utils';

interface Props {
  show: boolean;
  type: WeixinPermanentMaterialType;
  materials?: WeixinPermanentMaterialList;
  dispatch?: DispatchFn<AppState>;
  onCloseMediaLibrary: () => void;
  onMediaSelected: (item: WeixinPermanentMaterialListItem) => void;
}

function mapStateToProps(state: AppState, props: Props) {
  return {
    materials: state.integration.materials.keyedLists[props.type as string],
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

export class MediaLibraryImpl extends PureComponent<Props> {
  private readonly pageInputRef = React.createRef<HTMLInputElement>();
  private audio: HTMLAudioElement | null = null;

  componentDidMount() {
    this.props.dispatch!((dispatch, getState) => {
      const key = this.getMaterialListKey();
      const state = getState();
      const listState = state.integration.materials.keyedLists[key];
      if (!listState || (!listState.result && !listState.isLoading)) {
        dispatch(permanentMaterialActions.fetchListByKey(this.props.type));
      } else {
        dispatch(
          permanentMaterialActions.invalidateListByKey(
            this.props.type,
            true,
            undefined,
            false,
          ),
        );
      }
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.show && prevProps.show && this.audio) {
      this.audio.pause();
      this.audio = null;
    }
  }

  componentWillUnmount() {
    if (this.audio) {
      this.audio.pause();
      this.audio = null;
    }
  }

  getMaterialListKey(): string {
    return this.props.type as string;
  }

  render() {
    const { materials, show, type } = this.props;
    return (
      <Modal
        isOpen={show}
        onClosed={this.props.onCloseMediaLibrary}
        toggle={this.props.onCloseMediaLibrary}
        className={classNames('mp-media-library-modal-dialog', {
          [`mp-media-library-modal-dialog--${type}`]: type,
        })}
      >
        <Block active={Boolean(materials?.result && materials.isLoading)}>
          <ModalHeader toggle={this.props.onCloseMediaLibrary}>
            <Translate id="integration.menu.media_library.title" />
          </ModalHeader>
          <ModalBody>{this.renderModalBody()}</ModalBody>
          <ModalFooter style={{ justifyContent: 'flex-end' }}>
            <Button
              color="secondary"
              wide
              onClick={this.props.onCloseMediaLibrary}
            >
              <Translate id="cancel_btn_text" />
            </Button>
            <Button
              color="brand"
              wide
              onClick={this.onSelectMedia}
              disabled={!this.isSomeMediaItemSelected()}
            >
              <Translate id="integration.menu.media_library.button.choose" />
            </Button>
          </ModalFooter>
        </Block>
      </Modal>
    );
  }

  renderModalBody() {
    const { materials } = this.props;
    return (
      <div className="mp-media-library">
        {!materials?.result ? (
          !materials || materials.isLoading ? (
            <div className="mp-media-library__loading">
              <Translate id="integration.menu.media_library.loading" />
            </div>
          ) : (
            <div className="mp-media-library__error">
              <ReactMarkdown>
                {getString('integration.menu.media_library.error', {
                  error: materials.error
                    ? materials.error.message
                    : getString('integration.menu.media_library.general_error'),
                })}
              </ReactMarkdown>
            </div>
          )
        ) : (
          <>
            {this.renderMedias(materials)}
            {this.renderPager(materials)}
          </>
        )}
      </div>
    );
  }

  renderMedias(materials: WeixinPermanentMaterialList) {
    const { type } = this.props;
    return (
      <div
        className={classNames('mp-media-library__list', {
          [`mp-media-library__list--${type}`]: type,
        })}
      >
        {materials.result!.map(x => this.renderItem(x))}
      </div>
    );
  }

  renderPager(materials: WeixinPermanentMaterialList) {
    const { pageIndex, pageCount } = this.getPageInfo(materials);

    if (pageCount <= 1) return null;

    return (
      <div className="mp-media-library__pager">
        <div className="mp-media-library__pager-nav">
          {pageIndex > 0 && (
            <a
              href="#"
              className="mp-media-library__pager-nav-button mp-media-library__pager-prev-button"
              onClick={this.onPrevPage}
            >
              <i className="flaticon2-arrow" />
            </a>
          )}
          <span className="mp-media-library__pager-num">
            <span>{pageIndex + 1}</span>
            <i>/</i>
            <span>{pageCount}</span>
          </span>
          {pageIndex < pageCount - 1 && (
            <a
              href="#"
              className="mp-media-library__pager-nav-button mp-media-library__pager-next-button"
              onClick={this.onNextPage}
            >
              <i className="flaticon2-arrow" />
            </a>
          )}
        </div>
        <div className="mp-media-library__pager-form">
          <input
            type="number"
            className="mp-media-library__pager-input"
            ref={this.pageInputRef}
          />
          <a
            href="#"
            className="mp-media-library__pager-jump-button"
            onClick={this.onNavigateToPage}
          >
            <Translate id="integration.menu.media_library.pager.go" />
          </a>
        </div>
      </div>
    );
  }

  renderItem(item: WeixinPermanentMaterialListItem) {
    const { type } = this.props;
    if (type === WeixinPermanentMaterialType.Image) {
      return this.renderImage(item as WeixinPermanentNonNewsMaterialListItem);
    }
    if (type === WeixinPermanentMaterialType.Video) {
      return this.renderVideo(item as WeixinPermanentVideoMaterialListItem);
    }
    if (type === WeixinPermanentMaterialType.Voice) {
      return this.renderVoice(item as WeixinPermanentNonNewsMaterialListItem);
    }
    if (type === WeixinPermanentMaterialType.News) {
      return this.renderNews(item as WeixinPermanentNewsMaterialListItem);
    }
    return null;
  }

  renderImage(item: WeixinPermanentNonNewsMaterialListItem) {
    return (
      <div
        className={classNames('mp-media-library__image', {
          'mp-media-library__image--selected': this.isMediaItemSelected(item),
        })}
        key={item.mediaId}
        data-media-id={item.mediaId}
        onClick={this.onMediaItemClick}
      >
        <div
          className="mp-media-library__image-cover"
          style={{ backgroundImage: `url("${getProxiedImgUrl(item.url!)}")` }}
        />
        <div className="mp-media-library__image-title">{item.name}</div>
      </div>
    );
  }

  renderVideo(item: WeixinPermanentVideoMaterialListItem) {
    return (
      <div
        className={classNames('mp-media-library__video', {
          'mp-media-library__video--selected': this.isMediaItemSelected(item),
        })}
        key={item.mediaId}
        data-media-id={item.mediaId}
        onClick={this.onMediaItemClick}
      >
        <div className="mp-media-library__video-wrap">
          <div
            className="mp-media-library__video-cover"
            style={{
              backgroundImage: `url("${getProxiedImgUrl(item.coverUrl!)}")`,
            }}
          />
          <div className="mp-media-library__video-info">
            <div className="mp-media-library__video-title">{item.name}</div>
            <div className="mp-media-library__video-desc">
              {item.description || formatDate(item.updateTime * 1000)}
            </div>
          </div>
          <div className="mp-media-library__video-mask">
            <i className="mp-media-library__video-play-button" />
            <span className="mp-media-library__video-duration">00:00</span>
          </div>
        </div>
      </div>
    );
  }

  renderVoice(item: WeixinPermanentNonNewsMaterialListItem) {
    return (
      <div
        className={classNames('mp-media-library__voice', {
          'mp-media-library__voice--selected': this.isMediaItemSelected(item),
        })}
        key={item.mediaId}
        data-media-id={item.mediaId}
        onClick={this.onMediaItemClick}
      >
        <div className="mp-media-library__voice-info">
          <div className="mp-media-library__voice-title">{item.name}</div>
          <div className="mp-media-library__voice-timestamp">
            {formatDate(item.updateTime * 1000)}
          </div>
        </div>
        <div className="mp-media-library__voice-preview">
          <i
            className="mp-media-library__voice-listen-btn"
            onClick={this.onPlayVoice}
            data-media-id={item.mediaId}
          />
          <div>00:00</div>
        </div>
        <audio data-media-id={item.mediaId} />
      </div>
    );
  }

  renderNews(item: WeixinPermanentNewsMaterialListItem) {
    return (
      <div
        className={classNames('mp-media-library__news', {
          'mp-media-library__news--selected': this.isMediaItemSelected(item),
        })}
        key={item.mediaId}
        data-media-id={item.mediaId}
        onClick={this.onMediaItemClick}
      >
        <div className="mp-media-library__news-info">
          <Translate
            id="integration.menu.media_library.news_info_label"
            data={{
              updatedAt: formatTime(item.updateTime * 1000),
            }}
          />
        </div>
        {item.content.newsItem.map((newItem, i) => (
          <div
            className={classNames({
              'mp-news-cover-item': i === 0,
              'mp-news-item': i > 0,
            })}
            key={newItem.url}
            data-url={newItem.url}
          >
            <div className="mp-news-item__title">{newItem.title}</div>
            <div
              className="mp-news-item__pic"
              style={{
                backgroundImage: `url("${
                  newItem.thumbUrl
                    ? getProxiedImgUrl(newItem.thumbUrl)
                    : formatMediaUrl(
                        newItem.thumbMediaId,
                        WeixinPermanentMaterialType.Image,
                        false,
                      )
                }")`,
              }}
            />
          </div>
        ))}
      </div>
    );
  }

  onMediaItemClick = (e: MouseEvent<HTMLElement>) => {
    const mediaId = e.currentTarget.getAttribute('data-media-id')!;
    const { dispatch, type } = this.props;
    const materials = this.props.materials!;
    if (!materials.selection?.includes(mediaId)) {
      const selectedMediaId = materials.selection?.[0];
      if (selectedMediaId) {
        const selected = this.getMediaItemByMediaId(selectedMediaId);
        selected &&
          dispatch!(
            permanentMaterialActions.itemDeselectedByKey(type, selected),
          );
      }
      const item = this.getMediaItemByMediaId(mediaId);
      item && dispatch!(permanentMaterialActions.itemSelectedByKey(type, item));
    }
  };

  onNextPage = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const { materials, dispatch, type } = this.props;

    const { pageIndex, pageCount, limit } = this.getPageInfo(materials!);

    if (pageIndex === pageCount - 1) return;

    const offset = (pageIndex + 1) * limit;

    dispatch!(permanentMaterialActions.updateDataOffsetByKey(type, offset));
  };

  onPrevPage = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const { materials, dispatch, type } = this.props;

    const { pageIndex, limit } = this.getPageInfo(materials!);

    if (pageIndex === 0) return;

    const offset = (pageIndex - 1) * limit;

    dispatch!(permanentMaterialActions.updateDataOffsetByKey(type, offset));
  };

  onNavigateToPage = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const { materials, dispatch, type } = this.props;

    const pageStr = this.pageInputRef?.current?.value.trim();

    if (!pageStr) return;

    const { pageCount, limit } = this.getPageInfo(materials!);

    const page = Number(pageStr) - 1;

    if (isNaN(page) || page < 0 || page >= pageCount) return;

    const offset = page * limit;

    dispatch!(permanentMaterialActions.updateDataOffsetByKey(type, offset));
  };

  onSelectMedia = () => {
    const mediaId = this.props.materials!.selection![0];
    const media = this.getMediaItemByMediaId(mediaId)!;
    this.props.onMediaSelected(media);
    setTimeout(() => {
      this.props.dispatch!(
        permanentMaterialActions.clearSelectionByKey(this.props.type),
      );
    }, 500);
  };

  onPlayVoice = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const playingCls = 'mp-media-library__voice-listen-btn--playing';

    const el = e.currentTarget;
    const mediaId = e.currentTarget.getAttribute('data-media-id')!;
    const audio = document.querySelector(
      `.mp-media-library__voice[data-media-id="${mediaId}"] audio`,
    )! as HTMLAudioElement;

    let playing = false;

    if (!audio.src) {
      audio.src = formatMediaUrl(
        mediaId,
        WeixinPermanentMaterialType.Voice,
        false,
      );

      audio.addEventListener('ended', () => {
        el.classList.remove(playingCls);
      });

      playing = true;
    } else if (audio.paused) {
      playing = true;
    } else {
      audio.pause();
    }

    if (playing) {
      if (this.audio) {
        const playingMediaId = this.audio.getAttribute('data-media-id');
        const playingButton = document.querySelector(
          `.mp-media-library__voice-listen-btn[data-media-id="${playingMediaId}"]`,
        )!;
        playingButton.classList.remove(playingCls);
        this.audio.pause();
        this.audio = null;
      }
      void audio.play();
      playing = true;
      el.classList.add(playingCls);
      this.audio = audio;
    } else {
      el.classList.remove(playingCls);
    }
  };

  getPageInfo(materials: WeixinPermanentMaterialList) {
    const total = materials.total!;
    const limit = materials.limit!;
    const offset = materials.offset!;

    const pageIndex = Math.floor(offset / limit);
    const pageCount = Math.ceil(total / limit);

    return { total, limit, offset, pageIndex, pageCount };
  }

  getMediaItemByMediaId(mediaId: string) {
    return this.props.materials?.result?.find(x => x.mediaId === mediaId);
  }

  isMediaItemSelected(item: WeixinPermanentMaterialListItem) {
    return Boolean(this.props.materials?.selection?.includes(item.mediaId));
  }

  isSomeMediaItemSelected() {
    return Boolean(this.props.materials?.selection?.length);
  }
}

export const MediaLibrary = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaLibraryImpl);
