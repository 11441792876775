/**
 * @file: loadAsyncStates.ts
 * @author: eric <eric.blueplus@gmail.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  AsyncListState,
  KeyedAsyncListState,
  AsyncState,
  ObjectKeyType,
  AsyncObjectMapState,
} from 'lib/duck/interfaces';

export function loadAsyncState<T>(
  state: AsyncState<T>,
  load: () => void,
  force = false,
) {
  if (force || (!state.result && !state.isLoading)) {
    load();
  }
}

export function loadAsyncList<T>(
  listState: AsyncListState<T>,
  load: () => void,
  force = false,
) {
  if (force || (!listState.result && !listState.isLoading)) {
    load();
  }
}

export function loasAsyncKeyedList<T, TListKey extends string | number>(
  state: KeyedAsyncListState<T, TListKey>,
  key: TListKey,
  load: () => void,
  force = false,
) {
  if (
    force ||
    !state.keyedLists[key] ||
    (!state.keyedLists[key].result && !state.keyedLists[key].isLoading)
  ) {
    load();
  }
}

export function loadAsyncObjectMapState<T, TKey extends ObjectKeyType>(
  state: AsyncObjectMapState<TKey, T>,
  key: TKey,
  load: () => void,
  force = false,
) {
  if (
    force ||
    ((!state.result || !state.result[key]) &&
      (!state.statusMap[key] || !state.statusMap[key].isLoading))
  ) {
    load();
  }
}
