/**
 * @file: DataExportTaskStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { DataExportTaskStatus, Option } from 'model';

export const DataExportTaskStatusOptions: Array<Option<DataExportTaskStatus>> =
  [
    {
      value: DataExportTaskStatus.Queued,
      label: 'data_export_task_status.queued',
    },
    {
      value: DataExportTaskStatus.Running,
      label: 'data_export_task_status.running',
    },
    {
      value: DataExportTaskStatus.Error,
      label: 'data_export_task_status.error',
    },
    {
      value: DataExportTaskStatus.Finished,
      label: 'data_export_task_status.finished',
    },
  ];

export const DataExportTaskStatusOptionsWithDefault: Array<
  Option<DataExportTaskStatus | null>
> = [
  { value: null, label: 'data_export_task_status.__default__' },
  {
    value: DataExportTaskStatus.Queued,
    label: 'data_export_task_status.queued',
  },
  {
    value: DataExportTaskStatus.Running,
    label: 'data_export_task_status.running',
  },
  { value: DataExportTaskStatus.Error, label: 'data_export_task_status.error' },
  {
    value: DataExportTaskStatus.Finished,
    label: 'data_export_task_status.finished',
  },
];
