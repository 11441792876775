import { quotationTplActions } from 'app/inspection/duck/actions';
import { useSubjectStagedRef } from 'app/inspection/quotation-template-config/hooks/refs';
import {
  SortableItemList,
  SortableItemListItem,
} from 'app/inspection/quotation-template-config/items/ItemPanel';
import { LinkButton } from 'app/inspection/quotation-template-config/LinkButton';
import { EmptySubjects } from 'app/inspection/quotation-template-config/subject/EmptySubjects';
import { makeItemRef } from 'app/inspection/quotation-template-config/util';
import {
  QuotationTemplateItemStaged,
  QuotationTemplateSubjectStaged,
} from 'model';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SortEndHandler } from 'react-sortable-hoc';
import { useHasFullAccess } from 'shared/components/WithFullAccess';

export const SubjectListWithItems = memo(
  ({ subjects }: { subjects: QuotationTemplateSubjectStaged[] }) => {
    if (!subjects.length) {
      return <EmptySubjects editable />;
    }
    return (
      <div className="quotation-tpl__subjects-with-items">
        {subjects.map(subject => (
          <SubjectItemList key={subject.id} subject={subject} />
        ))}
      </div>
    );
  },
);

const SubjectItemList = memo(
  ({ subject }: { subject: QuotationTemplateSubjectStaged }) => {
    const dispatch = useDispatch();
    const editable = useHasFullAccess();

    const subjectRef = useSubjectStagedRef(subject);

    const onAddItem = useCallback(() => {
      dispatch(quotationTplActions.addItem(subjectRef));
    }, [dispatch, subjectRef]);

    const onEditItem = useCallback(
      (item: QuotationTemplateItemStaged) => {
        dispatch(
          quotationTplActions.editItem(makeItemRef(subjectRef, item.id)),
        );
      },
      [dispatch, subjectRef],
    );

    const onRemoveItem = useCallback(
      (item: QuotationTemplateItemStaged) => {
        dispatch(
          quotationTplActions.removeItem(makeItemRef(subjectRef, item.id)),
        );
      },
      [dispatch, subjectRef],
    );

    const onCheckChange = useCallback(
      (item: QuotationTemplateItemStaged, checked: boolean) => {
        dispatch(
          quotationTplActions.itemCheckChanged(
            makeItemRef(subjectRef, item.id),
            checked,
          ),
        );
      },
      [dispatch, subjectRef],
    );

    const onItemSortEnd: SortEndHandler = useCallback(
      e => {
        const { newIndex, oldIndex } = e;
        if (newIndex === oldIndex) return;
        dispatch(quotationTplActions.itemMoved(subjectRef, oldIndex, newIndex));
      },
      [dispatch, subjectRef],
    );

    const onAddMaterial = useCallback(
      (item: QuotationTemplateItemStaged) => {
        dispatch(
          quotationTplActions.addMaterial(makeItemRef(subjectRef, item.id)),
        );
      },
      [dispatch, subjectRef],
    );

    return (
      <div className="quotation-tpl__subject-with-items-list">
        <div className="quotation-tpl__item-panel-hd">
          <h3>
            <span>
              <i className="fa fa-tag" />
              <span>{subject.name}</span>
            </span>
            {editable && (
              <LinkButton onClick={onAddItem}>
                <i className={`la la-plus`} />
              </LinkButton>
            )}
          </h3>
        </div>
        <SortableItemList
          helperClass="quotation-tpl__item--being-dragged"
          onSortEnd={onItemSortEnd}
          lockAxis="y"
          useWindowAsScrollContainer={true}
          distance={4}
          useDragHandle
        >
          {subject.items.map((item, index) => (
            <SortableItemListItem
              key={item.id}
              index={index}
              item={item}
              onAddMaterial={onAddMaterial}
              onEdit={onEditItem}
              onRemove={onRemoveItem}
              onCheckChange={onCheckChange}
            />
          ))}
        </SortableItemList>
      </div>
    );
  },
);
