import classNames from 'classnames';
import { Organization, OrgTeam } from 'model';
import { Component } from 'react';
import { Options } from 'react-select';
import { orgTeamService } from 'services';
import { Select } from '../Select';
import { getString } from '../StringLabel';

interface Props {
  orgId: number;
  storeId?: number | null;
  groupId?: number | null;
  teamId?: number | null;
  className?: string;
  disabled?: boolean;
  onChange: (teamId: number | undefined, team: OrgTeam | null) => void;
}

interface State {
  teams: OrgTeam[] | null;
  isLoadingTeams: boolean;
}

export class TeamPicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      teams: null,
      isLoadingTeams: false,
    };
  }

  render() {
    const { teams, isLoadingTeams } = this.state;
    const { storeId, groupId, teamId, className, disabled } = this.props;

    const selectedTeam =
      teams && groupId
        ? teams.find(
            x =>
              x.storeId === storeId && x.groupId === groupId && x.id === teamId,
          )
        : null;

    return (
      <div className={classNames('team-picker', className)}>
        <Select<OrgTeam>
          key={`stores/${storeId || 0}/groups/${groupId || 0}`}
          values={[]}
          valueProp="id"
          labelProp="name"
          disabled={disabled}
          className="team-picker__component team-picker__team"
          selectedValue={selectedTeam}
          placeholder={getString('team_picker.placeholder.select_team')}
          isLoading={isLoadingTeams}
          isClearable
          noOptionsMessage={getString('team_picker.no_values_msg.team')}
          async
          defaultValues
          onLoadValues={this.onLoadTeams}
          onChange={this.onTeamChange}
        />
      </div>
    );
  }

  onLoadTeams = async (_inputValue: string): Promise<Organization[]> => {
    const { orgId, storeId, groupId } = this.props;
    if (!storeId || !groupId) return [];

    this.setState({ isLoadingTeams: true });
    try {
      const teams = (await orgTeamService.list(
        { orgId, storeId, groupId },
        null,
        0,
        0,
      )) as any;
      this.setState({ isLoadingTeams: false, teams });
      return teams;
    } catch (e) {
      console.error(e);
      this.setState({ isLoadingTeams: false, teams: null });
    }

    return [];
  };

  onTeamChange = async (value: Options<OrgTeam>) => {
    if (Array.isArray(value)) {
      return;
    }

    if (!value) {
      this.props.onChange(undefined, null);
      return;
    }

    const team = value as unknown as OrgTeam;

    this.props.onChange(team.id, team);
  };
}
