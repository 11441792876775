import classNames from 'classnames';
import { Store } from 'model';
import { Component } from 'react';
import { Options } from 'react-select';
import { storeService } from 'services';
import { Select } from '../Select';
import { getString } from '../StringLabel';

interface Props {
  storeId?: number | null;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: (storeId: number | undefined, store: Store | null) => void;
}

interface State {
  stores: Store[] | null;
  loading: boolean;
  error?: Error | null;
}

export class StorePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      stores: null,
      loading: false,
    };
  }

  async componentDidMount() {
    // load associated stores
    this.setState({ loading: true, error: null });
    try {
      const stores = (await storeService.list(null, null, 0, 0)) as any;
      this.setState({
        stores,
        loading: false,
      });
    } catch (e) {
      this.setState({
        stores: null,
        error: e,
        loading: false,
      });
    }
  }

  render() {
    const { stores, loading } = this.state;
    const { storeId, className, disabled, placeholder } = this.props;

    const selectedStore =
      stores && storeId ? stores.find(x => x.id === storeId) : null;

    return (
      <div className={classNames('store-picker', className)}>
        <Select<Store>
          values={stores || []}
          valueProp="id"
          labelProp="name"
          disabled={disabled}
          className="store-picker__component store-picker__store"
          selectedValue={selectedStore}
          placeholder={getString(
            placeholder ?? 'store_picker.placeholder.select_store',
          )}
          isLoading={loading}
          isClearable
          noOptionsMessage={this.onNoStoresMessage}
          onChange={this.onStoreChange}
        />
      </div>
    );
  }

  onNoStoresMessage = (_obj: { inputValue: string }): string | null => {
    if (this.state.error) {
      return this.state.error.message;
    }
    return getString('store_picker.no_values_msg.store');
  };

  onStoreChange = (value: Options<Store>) => {
    if (Array.isArray(value)) {
      return;
    }

    if (!value) {
      this.props.onChange(undefined, null);
      return;
    }

    const store = value as unknown as Store;

    this.props.onChange(store.id, store);
  };
}
