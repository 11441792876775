/**
 * @file: DataExportTaskStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum DataExportTaskStatus {
  Queued = 'queued',
  Running = 'running',
  Error = 'error',
  Finished = 'finished',
}

export const DataExportTaskStatusValueSet = new Set([
  DataExportTaskStatus.Queued,
  DataExportTaskStatus.Running,
  DataExportTaskStatus.Error,
  DataExportTaskStatus.Finished,
]);
