import classNames from 'classnames';
import React, { PureComponent, MouseEvent } from 'react';
import { WeixinMenuEditorInfo } from 'app/integration/duck/states';
import { WeixinOpenAuthorizedInfo } from 'model';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { getString } from 'shared/components';
import { Button } from 'lib/metronic/components';
import { Translate } from 'react-localize-redux';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { store } from 'app/duck/store';
import { weixinMenuActions } from '../duck/actions';

interface Props {
  editor: WeixinMenuEditorInfo;
  authorizedInfo: WeixinOpenAuthorizedInfo;
  menuBeingRemoved?: number | null;
  onAddMenuItem: (editorId: number, name: string, parentIndex?: number) => void;
  onMenuItemMoved: (editorId: number, parentIndex: number | undefined, fromIndex: number, toIndex: number) => void;
  onActiveMenuItemChanged: (editorId: number, path: number[]) => void;
}

function ButtonList({ children, className, vertical, ...props }: any) {
  return (
    <div className={classNames('mp-custom-menu-editor__button-list', className, {
      'mp-custom-menu-editor__button-list--vertical': vertical
    })} {...props}>
      {children}
    </div>
  );
}

function MenuButton({ children, className, active, open, ...props }: any) {
  return (
    <div className={classNames('mp-custom-menu-editor__button', className, {
      'mp-custom-menu-editor__button--active': active,
      'mp-custom-menu-editor__button--open': open
    })} {...props}>
      {children}
    </div>
  );
}

const SortableButtonList = SortableContainer(ButtonList);
const SortableMenuButton = SortableElement(MenuButton);

export class Preview extends PureComponent<Props> {
  render() {
    const { authorizedInfo, editor } = this.props;
    const buttonCount = Math.min(3, editor.menu.button.length + 1);

    return (
      <div className="mp-custom-menu-editor__preview__wrap">
        <div className="mp-custom-menu-editor__preview">
          <div className="mp-custom-menu-editor__preview-head">
            {authorizedInfo.authorizerInfo.nickName}
          </div>
          <div className="mp-custom-menu-editor__preview-body" />
          <div className="mp-custom-menu-editor__preview-foot">
            <SortableButtonList
              helperClass="mp-custom-menu-editor__button--being-dragged"
              className={`mp-custom-menu-editor__button-list--${buttonCount}`}
              onSortEnd={this.onButtonMoved()}
              pressDelay={200}
              axis="x"
              lockAxis="x"
              >
              {editor.menu.button.map((button, i) => (
                <SortableMenuButton
                  key={i}
                  index={i}
                  data-index={i}
                  onClick={this.onMenuItemClick}
                  active={
                    editor.activeMenuItemIndexPath &&
                    editor.activeMenuItemIndexPath.length === 1 &&
                    editor.activeMenuItemIndexPath[0] === i
                  }
                  open={
                    editor.activeMenuItemIndexPath &&
                    editor.activeMenuItemIndexPath.length === 2 &&
                    editor.activeMenuItemIndexPath[0] === i
                  }
                  >
                  {button.subButton && <i className="la la-pause rotate90" />}
                  {button.name}
                  {button.subButton && (
                    <SortableButtonList
                      helperClass="mp-custom-menu-editor__button--being-dragged"
                      className="mp-custom-menu-editor__button-list--vertical"
                      onSortEnd={this.onButtonMoved(i)}
                      pressDelay={200}
                      axis="y"
                      lockAxis="y"
                      vertical
                      >
                      {button.subButton.map((subButton, j) => (
                        <SortableMenuButton
                          key={j}
                          index={j}
                          data-parent-index={i}
                          data-index={j}
                          onClick={this.onMenuItemClick}
                          active={
                            editor.activeMenuItemIndexPath &&
                            editor.activeMenuItemIndexPath.length === 2 &&
                            editor.activeMenuItemIndexPath[1] === j
                          }
                          >
                          {subButton.name}
                        </SortableMenuButton>
                      ))}
                      <div
                        className="mp-custom-menu-editor__button mp-custom-menu-editor__button--new"
                        data-parent-index={i}
                        onClick={this.onAddMenuItem}
                        data-toggle="tooltip"
                        title={getString('integration.menu.tooltip.add_sub_menu_item')}
                        style={{ display: button.subButton.length < 5 ? 'flex' : 'none' }}
                        >
                        <i className="la la-plus" />
                      </div>
                    </SortableButtonList>
                  )}
                  <div
                    className="mp-custom-menu-editor__button-list mp-custom-menu-editor__button-list--vertical"
                    style={{
                      display: editor.activeMenuItemIndexPath &&
                        editor.activeMenuItemIndexPath.length === 1 &&
                        editor.activeMenuItemIndexPath[0] === i &&
                        !button.subButton ? 'flex' : 'none'
                      }}
                    >
                    <div
                      className="mp-custom-menu-editor__button mp-custom-menu-editor__button--new"
                      data-parent-index={i}
                      onClick={this.onAddMenuItem}
                      data-toggle="tooltip"
                      title={getString('integration.menu.tooltip.add_sub_menu_item')}
                      >
                      <i className="la la-plus" />
                    </div>
                  </div>
                </SortableMenuButton>
              ))}
              <div
                className="mp-custom-menu-editor__button mp-custom-menu-editor__button--new"
                onClick={this.onAddMenuItem}
                data-toggle="tooltip"
                title={getString('integration.menu.tooltip.add_menu_item')}
                style={{ display: editor.menu.button.length < 3 ? 'flex': 'none' }}
                >
                <i className="la la-plus" />
              </div>
            </SortableButtonList>
          </div>
        </div>
        <Button
          color="danger"
          block label pill style={{ borderRadius: 0 }}
          onClick={this.onRemoveMenu}
          >
          <Translate id="integration.menu.button.remove_menu" />
        </Button>
        {this.renderRemoveMenuConfirmModal()}
      </div>
    );
  }

  renderRemoveMenuConfirmModal() {
    const msg = 'integration.menu.modal.remove_menu.msg.' + (
      this.props.editor.isDefaultMenu ? 'default_menu' : 'conditional_menu'
    );

    return (
      <Modal
        isOpen={Boolean(this.props.menuBeingRemoved)}
        onClosed={this.onRemoveMenuDialogClose}
        toggle={this.onRemoveMenuDialogClose}
        className="mp-media-picker-video-player-modal"
        >
        <ModalHeader toggle={this.onRemoveMenuDialogClose}>
          <Translate id="integration.menu.modal.remove_menu.title" />
        </ModalHeader>
        <ModalBody>
          <Translate id={msg} />
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center'}}>
          <Button
            color="brand"
            wide
            onClick={this.onConfirmRemoveMenu}
            >
            <Translate id="remove_btn_text" />
          </Button>
          <Button
            color="secondary"
            wide
            onClick={this.onCancelRemoveMenu}
            >
            <Translate id="cancel_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  onButtonMoved(parentIndex?: number) {
    return (e: { oldIndex: number; newIndex: number }) => {
      this.props.onMenuItemMoved(this.props.editor.id, parentIndex, e.oldIndex, e.newIndex);
    };
  }

  onMenuItemClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const parentIndex = e.currentTarget.getAttribute('data-parent-index') ? Number(
      e.currentTarget.getAttribute('data-parent-index')
    ) : undefined
    const index = Number(e.currentTarget.getAttribute('data-index'));
    const path = parentIndex !== undefined ? [parentIndex, index] : [index];
    this.props.onActiveMenuItemChanged(this.props.editor.id, path);
  }

  onAddMenuItem = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const parentIndex = e.currentTarget.getAttribute('data-parent-index') ? Number(
      e.currentTarget.getAttribute('data-parent-index')
    ) : undefined
    this.props.onAddMenuItem(
      this.props.editor.id,
      getString('integration.menu.default_menu_item_name'),
      parentIndex
      );
  }

  onRemoveMenu = () => {
    store.dispatch(weixinMenuActions.removeMenu(this.props.editor.id));
  }

  onRemoveMenuDialogClose = () => {
    if (this.props.menuBeingRemoved) {
      store.dispatch(weixinMenuActions.removeMenuCancelled());
    }
  }

  onCancelRemoveMenu = () => {
    store.dispatch(weixinMenuActions.removeMenuCancelled());
  }

  onConfirmRemoveMenu = () => {
    store.dispatch(weixinMenuActions.removeMenuCommitted());
  }
}