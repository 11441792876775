import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleInspectionSite } from 'model';
import { InspectionSites } from '../states';
import { ActionTypes } from '../types';

const initialState: InspectionSites = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {},
  activeGroupKey: 'all',
  source: 'all',
};

const defaultReducer = createAsyncListActionReducers<
  VehicleInspectionSite,
  InspectionSites
>('inspection.sites', initialState, {
  mapItemKey: x => x.id,
});

export default function (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: InspectionSites | undefined = initialState,
  action: StandardAction<any>,
): InspectionSites {
  switch (action.type) {
    case ActionTypes.SiteKeywordFilterChanged:
      return {
        ...state,
        keyword: action.payload,
      };
    case ActionTypes.InventoryManagerSetTargetCategoryId:
      return {
        ...state,
        targetCategoryId: action.payload,
      };
    case ActionTypes.SiteSourceChanged:
      return {
        ...state,
        source: action.payload,
      };
    default:
      return defaultReducer(state, action);
  }
}
