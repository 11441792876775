/**
 * @file: VehicleInspectionCommentService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionComment,
  VehicleInspectionCommentListFilter,
} from 'model';

export class VehicleInspectionCommentServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionCommentListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionComment>
      : VehicleInspectionComment[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-comments',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionComment | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-comments/:id', { id })
      .future();
  }

  async getCommentByOrderNo(
    orderNo: string,
  ): Promise<VehicleInspectionComment | null> {
    if (!orderNo) {
      throw new Error('missing orderNo parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-inspection-comments/query/orderno', { orderNo })
      .future();
  }

  async create(
    vehicleInspectionComment: Partial<VehicleInspectionComment>,
  ): Promise<VehicleInspectionComment> {
    return await this.api
      .post()
      .url('/vehicle-inspection-comments')
      .data(vehicleInspectionComment)
      .future();
  }

  async update(
    vehicleInspectionComment: Partial<VehicleInspectionComment>,
  ): Promise<VehicleInspectionComment> {
    return await this.api
      .put()
      .url('/vehicle-inspection-comments/:id', {
        id: vehicleInspectionComment.id,
      })
      .data(vehicleInspectionComment)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-comments/:id', { id })
      .future();
  }
}
