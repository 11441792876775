/**
 * @file: ProductDetailContentTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ProductDetailContentType } from 'model';

export const ProductDetailContentTypeOptions: Array<
  Option<ProductDetailContentType>
> = [
  {
    value: ProductDetailContentType.ImageList,
    label: 'product_detail_content_type.image_list',
  },
  {
    value: ProductDetailContentType.HtmlTemplate,
    label: 'product_detail_content_type.html_tpl',
  },
];

export const ProductDetailContentTypeOptionsWithDefault: Array<
  Option<ProductDetailContentType | null>
> = [
  { value: null, label: 'product_detail_content_type.__default__' },
  {
    value: ProductDetailContentType.ImageList,
    label: 'product_detail_content_type.image_list',
  },
  {
    value: ProductDetailContentType.HtmlTemplate,
    label: 'product_detail_content_type.html_tpl',
  },
];
