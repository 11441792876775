/**
 * @file: ProductAuthorizationContractClientEntityTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Badge, BadgeProps } from 'lib/metronic/components';
import { ProductAuthorizationContractClientEntityType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { LabelColors } from '../LabelColors';

interface Props extends BadgeProps {
  value: ProductAuthorizationContractClientEntityType | null | undefined;
}

export const ProductAuthorizationContractClientEntityTypeLabel =
  React.memo<Props>(props => {
    const { value, color, ...otherProps } = props;

    const labelColor =
      value && LabelColors.ProductAuthorizationContractClientEntityType?.[value]
        ? LabelColors.ProductAuthorizationContractClientEntityType[value]
        : color;

    return (
      <Badge inline color={labelColor} {...otherProps}>
        <Translate
          id={`product_authorization_contract_client_entity_type.${value}`}
        />
      </Badge>
    );
  });
