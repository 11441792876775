/**
 * @file: authorized-products.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { ProductWithContractId } from 'model/app/product';
import { AuthorizedProducts } from '../states';

const initialState: AuthorizedProducts = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
  sorts: undefined,
};

const defaultReducer = createAsyncListActionReducers<
  ProductWithContractId,
  AuthorizedProducts
>('authorized_products', initialState, {
  mapItemKey: x => x.id,
});

export default function authorizedProductsReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AuthorizedProducts = initialState,
  action: StandardAction<any>,
): AuthorizedProducts {
  switch (action.type) {
    default: {
      return defaultReducer(state, action);
    }
  }
}
