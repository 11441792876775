import { FileUploadResult, ImageFileExtraInfo } from 'model';
import { PureComponent } from 'react';
import { Accept } from 'react-dropzone';
import { FilePicker } from 'shared/components/FilePicker';
import { renderFilePreviewer } from '../helpers';
import { FormElementFile } from '../types';

const DEFAULT_MEDIA_WIDTH = 244;
const DEFAULT_MEDIA_HEIGHT = 88;

const kImageAccept: Accept = {
  'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
};

const kVideoAccept: Accept = {
  'video/*': ['.mp4', '.mov'],
};

interface Props<T> {
  element: FormElementFile<T>;
  disabled?: boolean;
  value: any;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormFilePicker<T> extends PureComponent<Props<T>> {
  render() {
    const { element, value, disabled } = this.props;
    const pickerInfo = element.filePicker || {};
    return (
      <FilePicker
        cover={pickerInfo.cover}
        imageSize={pickerInfo.mediaSize}
        imageWidth={pickerInfo.mediaWidth}
        imageHeight={pickerInfo.mediaHeight}
        coverSize={pickerInfo.coverSize}
        coverWidth={pickerInfo.coverWidth}
        coverHeight={pickerInfo.coverHeight}
        maxSize={pickerInfo.maxSize}
        accept={
          pickerInfo.accept ??
          (element.type === 'image' ? kImageAccept : kVideoAccept)
        }
        realm={pickerInfo.realm}
        value={value || ''}
        disabled={disabled}
        onChange={this.onChange}
      >
        {this.renderFilePickerContents}
      </FilePicker>
    );
  }

  onChange = (file: FileUploadResult) => {
    if (file == null) return;
    const { element, onChange, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const pickerInfo = element.filePicker || {};
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = file.url as any;
    if (element.type === 'image') {
      if (pickerInfo.cover && pickerInfo.coverProp && file.extra) {
        const coverUrl = (file.extra as ImageFileExtraInfo).cover;
        changes[pickerInfo.coverProp] = coverUrl as any;
      }
    }
    void element.onChange?.(changes, extra);
    onChange(changes);
  };

  getSize() {
    const { element } = this.props;
    const pickerInfo = element.filePicker || {};
    const width = DEFAULT_MEDIA_WIDTH;
    let height = DEFAULT_MEDIA_HEIGHT;
    if (
      pickerInfo.mediaWidth &&
      pickerInfo.mediaWidth > 0 &&
      pickerInfo.mediaHeight &&
      pickerInfo.mediaHeight > 0
    ) {
      const aspectRatio = pickerInfo.mediaHeight / pickerInfo.mediaWidth;
      height = width * aspectRatio;
    }
    return { width, height };
  }

  renderFilePickerContents = (url: string) => {
    const { element } = this.props;
    const pickerInfo = element.filePicker || {};
    const { width, height } = this.getSize();
    return renderFilePreviewer(
      element.type as any,
      url,
      width,
      height,
      pickerInfo.posterUrl,
    );
  };
}
