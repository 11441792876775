/**
 * @file: QuotationPartCatalogService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { QuotationPartCatalogServiceBase } from './base/QuotationPartCatalogService';

export class QuotationPartCatalogService extends QuotationPartCatalogServiceBase {
  // add custom service implementation here
}
