/**
 * @file: VehicleInspectionSiteMaintenancePeriodService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { VehicleInspectionSiteMaintenancePeriodServiceBase } from './base/VehicleInspectionSiteMaintenancePeriodService';

export class VehicleInspectionSiteMaintenancePeriodService extends VehicleInspectionSiteMaintenancePeriodServiceBase {
  // add custom service implementation here
}
