import React from 'react';
import { ProductAuthorizationContract } from 'model';
import {
  ProductAuthorizationContractClientEntityTypeLabel,
  ProductAuthorizationContractStatusLabel,
  ScopedTranslate,
} from 'shared/components';
import { formatTime } from 'utils';
import { Alert } from 'lib/metronic/components';

export const ProductAuthorizationContractDetail = ({
  contract,
  error,
}: {
  contract: ProductAuthorizationContract;
  error?: Error;
}) => {
  return (
    <ScopedTranslate scope="product_authorization_contract.detail.label">
      {({ trans }) => (
        <div className="common-detail-view">
          {error ? <Alert color="danger">{error.message}</Alert> : null}
          <dl>
            <dt>{trans('id')}</dt>
            <dd>{contract.id}</dd>
          </dl>
          <dl>
            <dt>{trans('client_type')}</dt>
            <dd>
              <ProductAuthorizationContractClientEntityTypeLabel
                value={contract.clientEntityType}
              />
            </dd>
          </dl>
          <dl>
            <dt>{trans('client_name')}</dt>
            <dd>{contract.clientEntityName}</dd>
          </dl>
          <dl>
            <dt>{trans('supplier_id')}</dt>
            <dd>{contract.supplierEntityId}</dd>
          </dl>
          <dl>
            <dt>{trans('supplier_name')}</dt>
            <dd>{contract.supplierEntityName}</dd>
          </dl>
          <dl>
            <dt>{trans('created_at')}</dt>
            <dd>{formatTime(contract.createdAt)}</dd>
          </dl>
          <dl>
            <dt>{trans('status')}</dt>
            <dd>
              <ProductAuthorizationContractStatusLabel
                value={contract.status}
              />
            </dd>
          </dl>
          <dl>
            <dt>{trans('started_at')}</dt>
            <dd>{formatTime(contract.startedAt)}</dd>
          </dl>
          <dl>
            <dt>{trans('end_time')}</dt>
            <dd>{formatTime(contract.endTime, undefined, '-')}</dd>
          </dl>
          {contract.closedAt && (
            <dl>
              <dt>{trans('closed_at')}</dt>
              <dd>{formatTime(contract.closedAt, undefined, '-')}</dd>
            </dl>
          )}
          {contract.endedAt && (
            <dl>
              <dt>{trans('ended_at')}</dt>
              <dd>{formatTime(contract.endedAt, undefined, '-')}</dd>
            </dl>
          )}
        </div>
      )}
    </ScopedTranslate>
  );
};
