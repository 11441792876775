/**
 * @file: ProductAuthorizationContractClientEntityTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ProductAuthorizationContractClientEntityType } from 'model';

export const ProductAuthorizationContractClientEntityTypeOptions: Array<
  Option<ProductAuthorizationContractClientEntityType>
> = [
  {
    value: ProductAuthorizationContractClientEntityType.Organization,
    label: 'product_authorization_contract_client_entity_type.org',
  },
  {
    value: ProductAuthorizationContractClientEntityType.Store,
    label: 'product_authorization_contract_client_entity_type.store',
  },
  {
    value: ProductAuthorizationContractClientEntityType.Agent,
    label: 'product_authorization_contract_client_entity_type.agent',
  },
];

export const ProductAuthorizationContractClientEntityTypeOptionsWithDefault: Array<
  Option<ProductAuthorizationContractClientEntityType | null>
> = [
  {
    value: null,
    label: 'product_authorization_contract_client_entity_type.__default__',
  },
  {
    value: ProductAuthorizationContractClientEntityType.Organization,
    label: 'product_authorization_contract_client_entity_type.org',
  },
  {
    value: ProductAuthorizationContractClientEntityType.Store,
    label: 'product_authorization_contract_client_entity_type.store',
  },
  {
    value: ProductAuthorizationContractClientEntityType.Agent,
    label: 'product_authorization_contract_client_entity_type.agent',
  },
];
