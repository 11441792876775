/**
 * @file: id.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */
import { customAlphabet, nanoid } from 'nanoid/non-secure';

export const NUMBER_CHARACTERS = '0123456789';
export const NUMBER_CHARACTERS_NON_LEADING_ZERO =
  NUMBER_CHARACTERS.substring(1);
export const ALPHABETIC_LOWER_CHARACTERS = 'abcdefghijklmnopqrstuvwxyz';
export const ALPHABETIC_UPPER_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const ALPHABETIC_CHARACTERS_ALL =
  ALPHABETIC_LOWER_CHARACTERS + ALPHABETIC_UPPER_CHARACTERS;
export const HEXADECIMAL_LOWER_CHARACTERS = '0123456789abcdef';
export const HEXADECIMAL_UPPER_CHARACTERS = '0123456789ABCDEF';
export const NUMERIC_ALPHABETIC_CHARACTERS_ALL =
  NUMBER_CHARACTERS + ALPHABETIC_CHARACTERS_ALL;
export const NUMERIC_ALPHABETIC_LOWER_CHARACTERS =
  NUMBER_CHARACTERS + ALPHABETIC_LOWER_CHARACTERS;
export const NUMERIC_ALPHABETIC_UPPER_CHARACTERS =
  NUMBER_CHARACTERS + ALPHABETIC_UPPER_CHARACTERS;

export function makeUniqueId(len = 21): string {
  return nanoid(len);
}

export function makeUniqueIdAlphabetic(len = 21): string {
  return makeUniqueIdCustom(NUMERIC_ALPHABETIC_CHARACTERS_ALL, len);
}

export function makeUniqueIdCustom(alphabet: string, len = 21): string {
  return customAlphabet(alphabet, len)();
}
