import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleInspectionSiteCategory } from 'model';
import { InspectionSiteCategories } from '../states';

const initialState: InspectionSiteCategories = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {}
};

export default createAsyncListActionReducers<VehicleInspectionSiteCategory, InspectionSiteCategories>(
  'inspection.categories',
  initialState,
  {
    mapItemKey: x => x.id,
    expandAllTreeListNodeByDefault: true
  }
);