/**
 * @file arr2map.ts
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

type KeyFunc<T> = (obj: T, i: number) => any;
type ValueFunc<T, TValue> = (obj: T, i: number) => TValue;

export function arr2map<T, TValue = T>(
  arr: T[],
  keyFunc: KeyFunc<T>,
  valueFunc?: ValueFunc<T, TValue>
): {[key: string]: TValue} {
  return arr.reduce((map: { [key: string]: TValue }, x, i) => {
    if (valueFunc) {
      map[keyFunc(x, i)] = valueFunc(x, i);
    } else {
      map[keyFunc(x, i)] = (x as any) as TValue;
    }
    return map;
  }, {});
}
