import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { OrgSubscribedUser } from 'model';
import { RecentSubscribedUsers } from '../states';

const initialState: RecentSubscribedUsers = {
  isLoading: false,
  error: null,
  result: null,
  filter: {},
  sorts: [{ property: 'createdAt', dir: 'desc' }],
  offset: 0,
  limit: 15,
};

export default createAsyncListActionReducers<
  OrgSubscribedUser,
  RecentSubscribedUsers
>('dashboard.recent_subscribed_users', initialState, {
  mapItemKey: x => x.id,
});
