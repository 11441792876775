import {
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
  TemplateProperty,
} from './types';

export class StoreStatsRemindTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  get supportedDestTypes(): TemplateMsgDestType[] {
    return ['service_agent'];
  }

  getOverriddenProperties(): TemplateProperty[] {
    return ['statsType', 'statsTime'].map(x => ({
      property: x,
      label: `integration.templates.props.store_stats_remind.${x}`,
    }));
  }

  getOverriddenExample() {
    return {
      title:
        '尊敬的用户您好，您所管理的门店（XX店）今日统计报告已生成，请点击查阅!',
      remark: '点击进入统计数据小程序页面',
      statsType: '经营状况',
      statsTime: '2020年1月6日 20:00',
    };
  }
}
