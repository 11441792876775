/**
 * @file: VehicleIssueStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, VehicleIssueStatus } from 'model';

export const VehicleIssueStatusOptions: Array<Option<VehicleIssueStatus>> = [
  { value: VehicleIssueStatus.New, label: 'vehicle_issue_status.new' },
  { value: VehicleIssueStatus.Resolved, label: 'vehicle_issue_status.resolved' },
  { value: VehicleIssueStatus.Closed, label: 'vehicle_issue_status.closed' }
];

export const VehicleIssueStatusOptionsWithDefault: Array<Option<VehicleIssueStatus | null>> = [
  { value: null, label: 'vehicle_issue_status.__default__' },
  { value: VehicleIssueStatus.New, label: 'vehicle_issue_status.new' },
  { value: VehicleIssueStatus.Resolved, label: 'vehicle_issue_status.resolved' },
  { value: VehicleIssueStatus.Closed, label: 'vehicle_issue_status.closed' }
];