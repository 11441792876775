import { AppState, Areas } from 'app/duck/states';
import { ActionThunk, StandardAction } from 'lib/duck/interfaces';
import { ReactNode } from 'react';
import {
  RecentCustomers,
  RecentInspectionComments,
  RecentInspectionReports,
  RecentInspectionTasks,
  RecentReceptionOrders,
  RecentSubscribedUsers,
  StatAspect,
} from './duck/states';
import { RecentCustomerList } from './RecentCustomerList';
import { RecentVehicleInspectionCommentList } from './RecentInspectionCommentList';
import { RecentVehicleInspectionReportList } from './RecentInspectionReportList';
import { RecentVehicleInspectionTaskList } from './RecentInspectionTaskList';
import { RecentVehicleReceptionOrderList } from './RecentReceptionOrderList';
import { RecentSubscribedUserList } from './RecentUserList';

import {
  fetchDashboardRecentCustomers,
  fetchDashboardRecentInspectionComments,
  fetchDashboardRecentInspectionReports,
  fetchDashboardRecentInspectionTasks,
  fetchDashboardRecentReceptionOrders,
  fetchDashboardRecentSubscribedUsers,
  invalidateDashboardRecentCustomers,
  invalidateDashboardRecentInspectionComments,
  invalidateDashboardRecentInspectionReports,
  invalidateDashboardRecentInspectionTasks,
  invalidateDashboardRecentReceptionOrders,
  invalidateDashboardRecentSubscribedUsers,
} from './duck/actions';

export enum RecentListType {
  Customers = 'customers',
  SubscribedUsers = 'users',
  ReceptionOrders = 'reception_orders',
  InspectionTasks = 'inspection_tasks',
  InspectionReports = 'inspection_reports',
  InspectionComments = 'inspection_comments',
}

export interface RecentListsProps {
  areas: Areas;
  recentCustomers: RecentCustomers;
  recentSubscribedUsers: RecentSubscribedUsers;
  recentReceptionOrders: RecentReceptionOrders;
  recentInspectionTasks: RecentInspectionTasks;
  recentInspectionReports: RecentInspectionReports;
  recentInspectionComments: RecentInspectionComments;
}

export interface RecentListConfig {
  disabled?: boolean;
  statAspect: StatAspect;
  listType: RecentListType;
  fetchAction(): StandardAction<any> | ActionThunk<AppState>;
  invalidateAction(): StandardAction<any> | ActionThunk<AppState>;
  render(props: RecentListsProps): ReactNode;
}

export const RecentListsConfiguations: RecentListConfig[] = [
  {
    statAspect: StatAspect.Customer,
    listType: RecentListType.Customers,
    fetchAction: () => fetchDashboardRecentCustomers(),
    invalidateAction: () => invalidateDashboardRecentCustomers(),
    render: (props: RecentListsProps) => (
      <RecentCustomerList customers={props.recentCustomers} />
    ),
  },
  {
    statAspect: StatAspect.SubscribedUser,
    listType: RecentListType.SubscribedUsers,
    fetchAction: () => fetchDashboardRecentSubscribedUsers(),
    invalidateAction: () => invalidateDashboardRecentSubscribedUsers(),
    render: (props: RecentListsProps) => (
      <RecentSubscribedUserList users={props.recentSubscribedUsers} />
    ),
  },
  {
    statAspect: StatAspect.ReceptionOrder,
    listType: RecentListType.ReceptionOrders,
    fetchAction: () => fetchDashboardRecentReceptionOrders(),
    invalidateAction: () => invalidateDashboardRecentReceptionOrders(),
    render: (props: RecentListsProps) => (
      <RecentVehicleReceptionOrderList orders={props.recentReceptionOrders} />
    ),
  },
  {
    statAspect: StatAspect.InspectionTask,
    listType: RecentListType.InspectionTasks,
    fetchAction: () => fetchDashboardRecentInspectionTasks(),
    invalidateAction: () => invalidateDashboardRecentInspectionTasks(),
    render: (props: RecentListsProps) => (
      <RecentVehicleInspectionTaskList tasks={props.recentInspectionTasks} />
    ),
  },
  {
    statAspect: StatAspect.InspectionReport,
    listType: RecentListType.InspectionReports,
    fetchAction: () => fetchDashboardRecentInspectionReports(),
    invalidateAction: () => invalidateDashboardRecentInspectionReports(),
    render: (props: RecentListsProps) => (
      <RecentVehicleInspectionReportList
        reports={props.recentInspectionReports}
      />
    ),
  },
  {
    statAspect: StatAspect.InspectionComment,
    listType: RecentListType.InspectionComments,
    fetchAction: () => fetchDashboardRecentInspectionComments(),
    invalidateAction: () => invalidateDashboardRecentInspectionComments(),
    render: (props: RecentListsProps) => (
      <RecentVehicleInspectionCommentList
        comments={props.recentInspectionComments}
      />
    ),
  },
];
