/**
 * @file: report-list.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleReport } from 'model';
import { VehicleInspectionReports } from '../states';
import { ActionTypes } from '../types';

const initialState: VehicleInspectionReports = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 15,
  filter: {},
};

const defaultReducer = createAsyncListActionReducers<
  VehicleReport,
  VehicleInspectionReports,
  string
>('reports.report_list', initialState, {
  mapItemKey: x => x._id,
});

export default function vehicleInspectionReportsReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: VehicleInspectionReports = initialState,
  action: StandardAction<any>,
): VehicleInspectionReports {
  switch (action.type) {
    case ActionTypes.SetActiveReport: {
      return { ...state, activeReportId: action.payload };
    }
    default: {
      return defaultReducer(state, action);
    }
  }
}
