import React, { KeyboardEventHandler, PureComponent } from 'react';
import Select from 'react-select/creatable';

interface Props {
  disabled?: boolean;
  values: string[];
  options?: string[];
  placeholder?: string;
  clearable?: boolean;
  onChange: (values: string[]) => void;
}

interface Option {
  readonly label: string;
  readonly value: string;
}

interface State {
  inputValue: string;
}

const components = {
  DropdownIndicator: null,
};

export class MultiTextInput extends PureComponent<Props, State> {
  state: State = {
    inputValue: '',
  };

  onChange = (value: Option[]) => {
    this.props.onChange(value.map(x => x.value));
  };

  onInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  onKeyDown: KeyboardEventHandler<HTMLElement> = event => {
    const { inputValue } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState({
          inputValue: '',
        });
        this.props.onChange([...new Set([...this.props.values, inputValue])]);
        event.preventDefault();
    }
  };

  render() {
    return (
      <Select
        menuIsOpen={this.props.options == null ? false : undefined}
        components={this.props.options == null ? components : undefined}
        inputValue={this.state.inputValue}
        isClearable={this.props.clearable !== false}
        isMulti
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        onKeyDown={this.onKeyDown}
        placeholder={this.props.placeholder}
        options={
          this.props.options == null
            ? undefined
            : this.props.options.map(label => ({ label, value: label }))
        }
        value={this.props.values.map(label => ({ label, value: label }))}
      />
    );
  }
}
