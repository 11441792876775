import { useRef } from 'react';

export type AnyFunc<Args extends any[] = any, Ret = any> = (
  ...args: Args
) => Ret;

export function usePersistFn<F extends AnyFunc>(
  fn: F,
  options?: {
    decorator?: <DF extends AnyFunc>(fn: DF) => F;
  },
): F {
  const fnRef = useRef<F>(fn);
  fnRef.current = fn;

  const persistFn = useRef<F>();
  if (!persistFn.current) {
    const decorator = options?.decorator ?? (t => t);
    persistFn.current = (decorator as any)(function (this: any, ...args) {
      return fnRef.current!.apply(this, args);
    } as F);
  }

  return persistFn.current!;
}
