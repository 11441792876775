/**
 * @file: VehicleInspectionTaskDeliveryCheckService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionTaskDeliveryCheckListFilter, VehicleInspectionTaskDeliveryCheck, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions, VehicleInspectionTaskDeliveryCheckMedia } from 'model';

export class VehicleInspectionTaskDeliveryCheckServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskDeliveryCheckListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionTaskDeliveryCheck> : VehicleInspectionTaskDeliveryCheck[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-delivery-checks', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionTaskDeliveryCheck | null> {
    return await this.api.get()
      .url('/vehicle-inspection-task-delivery-checks/:id', { id })
      .future();
  }

  async create(vehicleInspectionTaskDeliveryCheck: Partial<VehicleInspectionTaskDeliveryCheck>): Promise<VehicleInspectionTaskDeliveryCheck> {
    return await this.api.post()
      .url('/vehicle-inspection-task-delivery-checks')
      .data(vehicleInspectionTaskDeliveryCheck)
      .future();
  }

  async update(vehicleInspectionTaskDeliveryCheck: Partial<VehicleInspectionTaskDeliveryCheck>): Promise<VehicleInspectionTaskDeliveryCheck> {
    return await this.api.put()
      .url('/vehicle-inspection-task-delivery-checks/:id', { id: vehicleInspectionTaskDeliveryCheck.id })
      .data(vehicleInspectionTaskDeliveryCheck)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/vehicle-inspection-task-delivery-checks/:id', { id })
      .future();
  }

  async getMediasByCheckId(id: number, recursive = false): Promise<VehicleInspectionTaskDeliveryCheckMedia[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-delivery-checks/:id/medias', { id, recursive })
      .future();
  }

  async createVehicleInspectionTaskDeliveryCheckMedia(media: Partial<VehicleInspectionTaskDeliveryCheckMedia>): Promise<VehicleInspectionTaskDeliveryCheckMedia> {
    const id = media.checkId;
    if (!id) {
      throw new Error('media.checkId is required. ');
    }
    return await this.api.post()
      .url('/vehicle-inspection-task-delivery-checks/:id/medias')
      .data(media)
      .future();
  }

  async updateVehicleInspectionTaskDeliveryCheckMedia(media: VehicleInspectionTaskDeliveryCheckMedia): Promise<VehicleInspectionTaskDeliveryCheckMedia> {
    const id = media.checkId;
    if (!id) {
      throw new Error('media.checkId is required. ');
    }
    return await this.api.put()
      .url('/vehicle-inspection-task-delivery-checks/:id/medias')
      .data(media)
      .future();
  }

  async deleteVehicleInspectionTaskDeliveryCheckMedia(
    checkId: number | VehicleInspectionTaskDeliveryCheckMedia, mediaId?: number
  ): Promise<void> {
    if (checkId && typeof checkId === 'object') {
      mediaId = checkId.id;
      if (!checkId.checkId) {
        throw new Error('checkId cannot be null or undefined');
      }
      checkId = checkId.checkId;
    }
    if (!checkId || !mediaId) {
      throw new Error('checkId and mediaId are both required');
    }
    return void await this.api.delete()
      .url('/vehicle-inspection-task-delivery-checks/:checkId/medias/:mediaId', { checkId, mediaId })
      .future();
  }
}