/**
 * @file: OfficialAccountCertificateTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, OfficialAccountCertificateType } from 'model';

export const OfficialAccountCertificateTypeOptions: Array<Option<OfficialAccountCertificateType>> = [
  { value: OfficialAccountCertificateType.Uncertified, label: 'official_account_certificate_type.uncertified' },
  { value: OfficialAccountCertificateType.WeixinCertified, label: 'official_account_certificate_type.weixin_certified' },
  { value: OfficialAccountCertificateType.SinaWeiboCertified, label: 'official_account_certificate_type.sina_weibo_certified' },
  { value: OfficialAccountCertificateType.TencentWeiboCertified, label: 'official_account_certificate_type.tencent_weibo_certified' },
  { value: OfficialAccountCertificateType.QualificationCertifiedWithoutNameCertified, label: 'official_account_certificate_type.qualification_certified_without_name_certified' },
  { value: OfficialAccountCertificateType.QualificationCertifiedWithoutNameCertifiedWithSinaWeiboCertified, label: 'official_account_certificate_type.qualification_certified_without_name_certified_with_sina_weibo_certified' },
  { value: OfficialAccountCertificateType.QualificationCertifiedWithoutNameCertifiedWithTencentWeiboCertified, label: 'official_account_certificate_type.qualification_certified_without_name_certified_with_tencent_weibo_certified' }
];

export const OfficialAccountCertificateTypeOptionsWithDefault: Array<Option<OfficialAccountCertificateType | null>> = [
  { value: null, label: 'official_account_certificate_type.__default__' },
  { value: OfficialAccountCertificateType.Uncertified, label: 'official_account_certificate_type.uncertified' },
  { value: OfficialAccountCertificateType.WeixinCertified, label: 'official_account_certificate_type.weixin_certified' },
  { value: OfficialAccountCertificateType.SinaWeiboCertified, label: 'official_account_certificate_type.sina_weibo_certified' },
  { value: OfficialAccountCertificateType.TencentWeiboCertified, label: 'official_account_certificate_type.tencent_weibo_certified' },
  { value: OfficialAccountCertificateType.QualificationCertifiedWithoutNameCertified, label: 'official_account_certificate_type.qualification_certified_without_name_certified' },
  { value: OfficialAccountCertificateType.QualificationCertifiedWithoutNameCertifiedWithSinaWeiboCertified, label: 'official_account_certificate_type.qualification_certified_without_name_certified_with_sina_weibo_certified' },
  { value: OfficialAccountCertificateType.QualificationCertifiedWithoutNameCertifiedWithTencentWeiboCertified, label: 'official_account_certificate_type.qualification_certified_without_name_certified_with_tencent_weibo_certified' }
];