/**
 * @file: modal.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ActionTypes } from '../types';
import { createStandardAction } from 'lib/duck/actions';
import { ReactNode } from 'react';

export function showAppModal(
  title: string,
  message: ReactNode,
  onClose?: () => void,
) {
  return createStandardAction(ActionTypes.ShowAppModal, {
    title,
    message,
    onClose,
  });
}

export function closeAppModal() {
  return createStandardAction(ActionTypes.CloseAppModal);
}
