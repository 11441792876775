import {
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
  TemplateProperty,
} from './types';

export class ServiceCommentRemindTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  get supportedDestTypes(): TemplateMsgDestType[] {
    return ['customer'];
  }

  getOverriddenProperties(): TemplateProperty[] {
    return ['serviceSubjects', 'storeName', 'serviceDate'].map(x => ({
      property: x,
      label: `integration.templates.props.service_comment_remind.${x}`,
    }));
  }

  getOverriddenExample() {
    return {
      title:
        '尊敬的京QXXXXX车主您好，非常感谢于2020年1月1日光临本店接受服务；为进一步提升整体服务体验，诚挚邀请您对于我们的本次服务进行评价!',
      remark: '点击进入服务点评页面',
    };
  }
}
