/**
 * @file: QuotationMaterialInventoryStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum QuotationMaterialInventoryStatus {
  InStock = 'in_stock',
  OutOfStock = 'out_of_stock',
  LowStock = 'low_stock',
  BackOrdered = 'back_ordered',
  Discontinued = 'discontinued',
  PreOrder = 'pre_order',
  Reserved = 'reserved',
  InTransit = 'in_transit',
  Pending = 'pending',
}

export const QuotationMaterialInventoryStatusValueSet = new Set([
  QuotationMaterialInventoryStatus.InStock,
  QuotationMaterialInventoryStatus.OutOfStock,
  QuotationMaterialInventoryStatus.LowStock,
  QuotationMaterialInventoryStatus.BackOrdered,
  QuotationMaterialInventoryStatus.Discontinued,
  QuotationMaterialInventoryStatus.PreOrder,
  QuotationMaterialInventoryStatus.Reserved,
  QuotationMaterialInventoryStatus.InTransit,
  QuotationMaterialInventoryStatus.Pending,
]);
