import { MpMenuItemMiniprogram } from 'model';
import { FocusEvent, PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { getString } from 'shared/components';

interface Props {
  editorId: number;
  parentIndex: number | undefined;
  index: number;
  url?: string;
  appid?: string;
  pagepath?: string;

  onMenuItemChange: <T = any>(
    editorId: number,
    path: number[],
    changes: T,
  ) => void;
}

export class MiniprogramItemEditor extends PureComponent<Props> {
  render() {
    const { url, appid, pagepath } = this.props;
    return (
      <div className="view-item-editor">
        <div className="form-group">
          <label>
            <Translate id="integration.menu.label.appid" />
          </label>
          <input
            type="text"
            className="form-control"
            key={appid || ''}
            placeholder={getString('integration.menu.placeholder.appid')}
            defaultValue={appid || ''}
            onBlur={this.onAppidBlur}
          />
        </div>
        <div className="form-group">
          <label>
            <Translate id="integration.menu.label.pagepath" />
          </label>
          <input
            type="text"
            className="form-control"
            key={pagepath || ''}
            placeholder={getString('integration.menu.placeholder.pagepath')}
            defaultValue={pagepath || ''}
            onBlur={this.onPagePathBlur}
          />
        </div>
        <div className="form-group">
          <label>
            <Translate id="integration.menu.label.url" />
          </label>
          <input
            type="url"
            className="form-control"
            key={url || ''}
            defaultValue={url || ''}
            onBlur={this.onUrlBlur}
          />
          <span className="form-text text-muted">
            <ReactMarkdown>
              {getString('integration.menu.help_text.fallback_url')}
            </ReactMarkdown>
          </span>
        </div>
      </div>
    );
  }

  onUrlBlur = (e: FocusEvent<HTMLInputElement>) => {
    const url = e.target.value.trim();
    if (url !== this.props.url) {
      this.props.onMenuItemChange<Partial<MpMenuItemMiniprogram>>(
        this.props.editorId,
        this.getMenuItemPath(),
        { url },
      );
    }
  };

  onAppidBlur = (e: FocusEvent<HTMLInputElement>) => {
    const appid = e.target.value.trim();
    if (appid !== this.props.appid) {
      this.props.onMenuItemChange<Partial<MpMenuItemMiniprogram>>(
        this.props.editorId,
        this.getMenuItemPath(),
        { appid },
      );
    }
  };

  onPagePathBlur = (e: FocusEvent<HTMLInputElement>) => {
    const pagepath = e.target.value.trim();
    if (pagepath !== this.props.pagepath) {
      this.props.onMenuItemChange<Partial<MpMenuItemMiniprogram>>(
        this.props.editorId,
        this.getMenuItemPath(),
        { pagepath },
      );
    }
  };

  private getMenuItemPath() {
    if (this.props.parentIndex !== undefined) {
      return [this.props.parentIndex, this.props.index];
    }
    return [this.props.index];
  }
}
