/**
 * @file: stats.service.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import { DatePartitionType, StatItem, StoreSummaryStats } from 'model';

export class StatsService {
  constructor(private readonly client: APIService) {}

  async getSummaryStats(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
  ): Promise<StoreSummaryStats> {
    return await this.client
      .get()
      .url('/stats/summary', { orgId, storeId, startDate, endDate })
      .future();
  }

  async getVehicleReceptionOrderStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/vehicle-reception-orders', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getVehicleInspectionTaskStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/vehicle-inspection-tasks', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getVehicleInspectionReportStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/vehicle-inspection-reports', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getCustomerStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/customers', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getUserStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/users', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getVehicleInspectionCommentStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/vehicle-inspection-comments', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }
}
