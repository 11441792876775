import { AclObjectList, OrgMember, OrgTeam, OrgTeamListFilter } from 'model';
import { Translate } from 'react-localize-redux';
import {
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
  StoreName,
} from 'shared/components';
import { formatTime } from 'utils';
import { teamActions } from '../duck/actions';

interface Props extends EntityListProps<OrgTeam, OrgTeamListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  OrgTeam,
  OrgTeamListFilter,
  number,
  Props
>();

export const OrgTeamList = componentClassBuilder
  .i18nPrefix('org_team')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/code/git4.svg')
      .default,
  )
  .accessRights({
    full: AclObjectList.OrgTeamFullAccess,
    readonly: AclObjectList.OrgTeamReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="org.breadcrumb.it" /> },
    { text: <Translate id="org.breadcrumb.teams" /> },
  ])
  .features({ addEntity: false })
  .entities(state => state.org.teams)
  .actions(teamActions)
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'name',
        placeholder: 'org_team.toolbar.placeholder.name',
        width: 150,
      })
      .button({
        buttonType: 'search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(teamActions.invalidate(true));
        },
      })
      .button({
        buttonType: 'add',
        onClick: (props: Props) => {
          const { dispatch, identity } = props;
          const team: Partial<OrgTeam> = {
            orgId: props.user.orgId,
            storeId:
              identity.storeId && identity.visibleStores.length === 1
                ? identity.storeId
                : undefined,
          };
          dispatch(teamActions.itemBeingCreated(team));
        },
      });
  })
  .editor(builder => {
    builder
      .store({
        prop: 'storeId',
        label: 'org_team.editor.label.store',
        disabled: (_: any, props: Props) => {
          const { identity } = props;
          return Boolean(
            identity.storeId && identity.visibleStores.length === 1,
          );
        },
      })
      .orgGroup({
        prop: 'groupId',
        label: 'org_team.editor.label.group',
      })
      .text({
        prop: 'name',
        label: 'org_team.editor.label.name',
        placeholder: 'org_team.editor.placeholder.name',
      })
      .orgMember({
        prop: 'managerId',
        label: 'org_team.editor.label.manager',
        placeholder: 'org_team.editor.placeholder.manager',
        onChange: (changes, _, member: OrgMember | null) => {
          if (member) {
            changes.managerName = member.name;
          }
          return undefined;
        },
      })
      .text({
        prop: 'sortOrder',
        label: 'org_team.editor.label.sort_order',
        placeholder: 'org_team.editor.placeholder.sort_order',
      });
  })
  .columns([
    {
      prop: 'storeId',
      width: 200,
      text: 'col.store_name',
      render: ({ storeId }) => <StoreName storeId={storeId} />,
    },
    {
      prop: 'name',
      width: 100,
      text: 'org_team.col.name',
    },
    {
      prop: 'managerName',
      width: 100,
      align: 'center',
      text: 'org_team.col.manager',
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .addActionButtons(['edit', 'remove'])
  .validate((entity: OrgTeam) => {
    const name = entity.name?.trim();

    let errCode = '';

    if (!entity.storeId) {
      errCode = 'store_required';
    } else if (!entity.groupId) {
      errCode = 'group_required';
    } else if (!name) {
      errCode = 'name_required';
    }

    if (errCode) {
      throw new Error(getString(`org_team.editor.error.${errCode}`));
    }
  })
  .onAdd((entity, props: Props) => {
    entity.orgId = props.user.orgId;
  })
  .componentDidUpdate((props, prevProps) => {
    if (props.activeStoreId !== prevProps.activeStoreId) {
      props.dispatch(teamActions.invalidate(true));
    }
  })
  .getClass();
