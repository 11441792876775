import React, { memo } from 'react';
import { OrderFieldVisibility } from 'model';
import { Translate } from 'react-localize-redux';

export const DisplayInOrderSummaryLabel = memo(
  ({ value }: { value: OrderFieldVisibility | undefined }) => {
    const color =
      value === 'always' ? 'green' : value === 'auto' ? 'blue' : undefined;
    return (
      <span style={{ color }}>
        {value == null ? (
          '-'
        ) : (
          <Translate
            id={`store.config.order_flow.values.field_display_in_summary_short.${value}`}
          />
        )}
      </span>
    );
  },
);
