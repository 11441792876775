import { TemplateProperty } from './types';

export const CommonTemplateProperties: TemplateProperty[] = [
  'title',
  'orderNo',
  'orderType',
  'vin',
  'vehicleModel',
  'brandName',
  'vehicleName',
  'vehicleMileage',
  'licensePlateNo',
  'customerName',
  'serviceAgentName',
  'technicianName',
  'statusText',
  'remark',
].map(x => ({
  property: x,
  label: `integration.templates.props.common.${x}`,
}));

export const CommonTemplatePropertyExamples: { [property: string]: string } = {
  orderNo: '20031823290102251569',
  reportNo: 'G27SA912',
  orderType: '整车全面保养',
  vin: 'LZWADAGA000000000',
  vehicleModel: 'S320',
  brandName: '奔驰',
  vehicleName: '奔驰 S200 2003款',
  vehicleMileage: '3万公里',
  licensePlateNo: '京QXXXXX',
  customerName: '刘大伟',
  technicianName: '张技师',
  serviceAgentName: '李顾问',
  statusText: '完成检测',
  remark: '点击此消息，查看检测报告，掌握车况详情！',
  estimatedFinishTime: '2020年1月3日',
  finishedAt: '2020年1月6日',
  startedAt: '2020年1月1日',
  inspectionResult: '1项紧急、5项异常',
  inspectedAt: '2020年1月2日',
  inspectionTechnicianName: '张大权',
  constructedAt: '2020年1月3日',
  constructionItems: '更换机油格、更换空气格',
  deliveryAt: '2020年1月5日',
  qualityInspector: '孟晓菲',
  serviceSubjects: '全车精检、机油保养',
  storeName: 'XX省XX市XX汽车XX店',
  score: '综合评分: 10分',
  comment: '体验非常棒',
};
