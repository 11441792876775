import { Button, Checkbox } from 'lib/metronic/components';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { getString } from 'shared/components';
import { randstr } from 'utils';
import { isEmail } from 'utils/validators';

interface Props {
  isLoading: boolean;
  onValidationError: (message: string) => void;
  onLogin: (userName: string, password: string, remember: boolean) => void;
}

export function LoginForm(props: Props) {
  const { onLogin, onValidationError, isLoading } = props;

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);

  const onSubmit = useCallback(
    (e: React.FormEvent | React.MouseEvent) => {
      e.preventDefault();

      const loginName = userName.trim();
      const loginPassword = password.trim();

      let errorMsg: string = '';

      if (!loginName) {
        errorMsg = getString('login.error.username_required');
      } else if (!/^[a-z0-9_.]+$/.test(loginName) && !isEmail(loginName)) {
        errorMsg = getString('login.error.invalid_username');
      } else if (!loginPassword) {
        errorMsg = getString('login.error.password_required');
      }

      if (errorMsg) {
        onValidationError(errorMsg);
        return false;
      }

      onLogin(loginName, loginPassword, remember);

      return false;
    },
    [userName, password, onLogin, remember, onValidationError],
  );

  const onUserNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  }, []);

  const onPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const onRememberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRemember(e.target.checked);
  }, []);

  return (
    <form className="kt-form" action="" onSubmit={onSubmit}>
      <div className="input-group">
        <input
          className="form-control"
          type="text"
          placeholder={getString('login.placeholder.username')}
          autoComplete="new-password"
          name={randstr(16)}
          value={userName}
          onChange={onUserNameChange}
        />
      </div>
      <div className="input-group">
        <input
          className="form-control"
          type="password"
          placeholder={getString('login.placeholder.password')}
          name={randstr(16)}
          autoComplete="new-password"
          value={password}
          onChange={onPasswordChange}
        />
      </div>
      <div className="row kt-login__extra">
        <div className="col">
          <Checkbox
            name="remember"
            label={getString('login.label.remember_me')}
            checked={remember}
            onChange={onRememberChange}
          />
        </div>
        <div className="col kt-align-right">
          <a href="#" className="kt-login__link">
            <Translate id="login.label.forgot_password" />
          </a>
        </div>
      </div>
      <div className="kt-login__actions">
        <Button
          color="brand"
          pill
          block
          className="kt-login__btn-primary"
          spinner={isLoading}
          spinnerSize="lg"
          spinnerColor="light"
          disabled={isLoading}
          onClick={onSubmit}
        >
          {getString('login.btn.login')}
        </Button>
      </div>
    </form>
  );
}
