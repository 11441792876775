/**
 * @file: ContentTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, ContentType } from 'model';

export const ContentTypeOptions: Array<Option<ContentType>> = [
  { value: ContentType.Text, label: 'content_type.text' },
  { value: ContentType.Photo, label: 'content_type.photo' },
  { value: ContentType.Video, label: 'content_type.video' },
  { value: ContentType.Audio, label: 'content_type.audio' }
];

export const ContentTypeOptionsWithDefault: Array<Option<ContentType | null>> = [
  { value: null, label: 'content_type.__default__' },
  { value: ContentType.Text, label: 'content_type.text' },
  { value: ContentType.Photo, label: 'content_type.photo' },
  { value: ContentType.Video, label: 'content_type.video' },
  { value: ContentType.Audio, label: 'content_type.audio' }
];