/**
 * @file: CustomerType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum CustomerType {
  Default = 1,
  Official = 2,
}

export const CustomerTypeValueSet = new Set([
  CustomerType.Default,
  CustomerType.Official,
]);
