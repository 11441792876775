import { useAppContext } from 'app/AppContext';
import { DisplayInOrderSummaryLabel } from 'app/settings/DisplayInOrderSummaryLabel';
import {
  EnumValueOptionsMap,
  OrderFieldEditor,
} from 'app/settings/OrderFieldEditor';
import { OrderFieldInfo, OrderProperty } from 'app/settings/types';
import { Button, Column, DataTable } from 'lib/metronic/components';
import { AclObjectList, OrderFieldConfig, OrderFieldsConfig } from 'model';
import { memo, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Checkmark, getString, OrgUserRoleTypeLabel } from 'shared/components';
import { isNotNull } from 'utils';
import { usePersistFn } from 'utils/usePersistFn';

type ColumnType = Column<OrderFieldInfo>;

const kProperties: OrderProperty[] = [
  'licensePlateNo',
  'vin',
  'vehicleInfo',
  'serviceAgent',
  'tags',
  'mileage',
  'dashboardImage',
  'facadeInspection',
  'serviceSubjects',
  'carwashType',
  'returnType',
  'oldPartDisposalType',
  'estimatedFinishTime',
  'reservationType',
  'customerType',
  'customerName',
  'customerMobile',
  'customerSign',
  'insuranceExpiryDate',
  'remark',
  'extendedProperties',
];

export const OrderFields = memo(
  ({
    fields,
    orderTags,
    onSave,
  }: {
    fields: OrderFieldsConfig | undefined;
    orderTags?: string[];
    onSave?: (fields: OrderFieldsConfig) => void;
  }) => {
    const { identity } = useAppContext();
    const [activeField, setActiveField] = useState<OrderFieldInfo>();

    const data = useMemo<OrderFieldInfo[]>(() => {
      const res: OrderFieldInfo[] = [];
      const makeField = (
        property: OrderProperty,
        fieldConfig: OrderFieldConfig | undefined,
      ): OrderFieldInfo => {
        return {
          property,
          ...fieldConfig,
        };
      };
      for (const property of kProperties) {
        res.push(makeField(property, fields?.[property]));
      }
      return res;
    }, [fields]);

    const groupNames = useMemo(
      () => [
        ...new Set(
          data
            .map(x => x.group)
            .filter(x => x)
            .filter(isNotNull),
        ),
      ],
      [data],
    );

    const canEdit = identity.hasAccessRights(
      AclObjectList.StoreConfigurationFullAccess,
    );

    const onEdit = usePersistFn((field: OrderFieldInfo) => {
      console.log('edit field: ', field);
      setActiveField(field);
    });

    const onClose = usePersistFn(() => {
      setActiveField(undefined);
    });

    const handleSave = usePersistFn((field: OrderFieldInfo) => {
      const { property, ...attrs } = field;
      const updated = {
        ...fields,
        [property]: attrs,
      };
      console.log('save field: ', field);
      onSave?.(updated);
      setActiveField(undefined);
    });

    const columns = useMemo<ColumnType[]>(() => {
      const list: ColumnType[] = [
        {
          prop: 'property',
          text: <Translate id="store.config.order_flow.col.name" />,
          width: 100,
          render: ({ property }) => (
            <Translate id={`store.config.order_flow.props.${property}`} />
          ),
        },
        {
          prop: 'required',
          text: <Translate id="store.config.order_flow.col.required" />,
          width: 60,
          align: 'center',
          render: ({ required }) =>
            required == null ? (
              '-'
            ) : (
              <Checkmark value={required} showFalseIcon />
            ),
        },
        {
          prop: 'hidden',
          text: <Translate id="store.config.order_flow.col.hidden" />,
          width: 60,
          render: ({ hidden }) =>
            hidden == null ? '-' : <Checkmark value={hidden} showFalseIcon />,
        },
        {
          prop: 'autoFill',
          text: <Translate id="store.config.order_flow.col.auto_fill" />,
          width: 60,
          render: ({ autoFill }) =>
            autoFill == null ? (
              '-'
            ) : (
              <Checkmark value={autoFill} showFalseIcon />
            ),
        },
        {
          prop: 'includeInOrderSummary',
          width: 60,
          align: 'center',
          text: (
            <Translate id="store.config.order_flow.col.include_in_order_summary" />
          ),
          render: ({ includeInOrderSummary }) => (
            <DisplayInOrderSummaryLabel value={includeInOrderSummary} />
          ),
        },
        {
          prop: 'group',
          text: <Translate id="store.config.order_flow.col.group" />,
          width: 80,
          render: ({ group }) => group || '-',
        },
        {
          prop: 'updatableBy',
          text: <Translate id="store.config.order_flow.col.updatable_by" />,
          width: 80,
          align: 'right',
          render: ({ updatableBy }) => (
            <span style={{ lineHeight: '1rem' }}>
              {updatableBy?.length ? (
                updatableBy.map(role => (
                  <OrgUserRoleTypeLabel
                    key={role}
                    value={role}
                    style={{
                      padding: '0 0.15rem',
                      lineHeight: 1.3,
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      fontSize: '0.75rem',
                      borderRadius: '3px',
                      height: '16px',
                    }}
                  />
                ))
              ) : (
                <Translate id="store.config.order_flow.values.inherited" />
              )}
            </span>
          ),
        },
        {
          prop: 'defaultValue',
          text: <Translate id="store.config.order_flow.col.defaultValue" />,
          width: 80,
          render: ({ defaultValue, property }) => {
            const options = (EnumValueOptionsMap as any)[property];
            if (options != null) {
              const label = options.find(
                (x: any) => x.value === defaultValue,
              )?.label;
              if (label != null) {
                return (
                  <Translate id={`store.config.order_flow.values.${label}`} />
                );
              }
            }
            return defaultValue != null ? `${defaultValue}` : '-';
          },
        },
      ];
      if (canEdit) {
        list.push({
          prop: 'actions',
          text: <Translate id="col.actions" />,
          align: 'center',
          width: 80,
          render: field => {
            return (
              <>
                <Button
                  size="small"
                  clean
                  iconOnly
                  data-toggle="tooltip"
                  data-container=".settings-editor"
                  title={getString(
                    'store.config.extended_property.tooltip.edit',
                  )}
                  onClick={() => onEdit(field)}
                >
                  <i className="la la-edit" />
                </Button>
              </>
            );
          },
        });
      }
      return list;
    }, [canEdit, onEdit]);

    return (
      <>
        <DataTable<OrderFieldInfo, string>
          className="order-fields-table"
          columns={columns}
          idProp="property"
          selModel="none"
          data={data}
          minHeight={0}
        />
        <OrderFieldEditor
          show={activeField != null}
          field={activeField}
          groupNames={groupNames}
          orderTags={orderTags}
          onSave={handleSave}
          onClose={onClose}
        />
      </>
    );
  },
);
