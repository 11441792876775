import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { InspectionReportItem } from 'model';
import { Translate } from 'react-localize-redux';
import { formatReportDetailResultValue, formatReportDetailNormalValue, getReportDetailResultCls } from './helpers';

interface Props {
  item: InspectionReportItem;
}

export class ReportResultItem extends PureComponent<Props> {
  render() {
    const { item } = this.props;
    const cls = getReportDetailResultCls(item);
    return (
      <dl className="inspection-report-item">
        <dt>{item.siteItemName}</dt>
        <dd className={classNames('inspection-report-item__actual', {
          [`inspection-report-item__actual--${cls}`]: cls
        })}>
          <label><Translate id="reports.detail.result.label.actual" /></label>
          {formatReportDetailResultValue(item)}
        </dd>
        <dd>
          <label><Translate id="reports.detail.result.label.expected" /></label>
          {formatReportDetailNormalValue(item)}
        </dd>
        {cls !== 'normal' && item.abnormalDescription &&
        <dd>
          <label><Translate id="reports.detail.result.label.desc" /></label>
          {item.abnormalDescription}
        </dd>
        }
      </dl>
    );
  }
}