import { UserAuthQrcodeAside } from 'app/org/users/UserAuthQrcodeAside';
import { Block } from 'lib/metronic/components';
import {
  AclObjectList,
  GenderOptions,
  Option,
  OrgMember,
  OrgMemberListFilter,
  OrgUserRoleType,
  OrgUserRoleTypeOptions,
} from 'model';
import { Translate } from 'react-localize-redux';
import {
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
  OrgInfoView,
  OrgUserRoleTypeLabel,
  StoreName,
} from 'shared/components';
import { formatTime } from 'utils';
import { isEmail, isMobile } from 'utils/validators';
import {
  memberActions,
  setCurrentMemberQrcodeAuth,
  userActions,
} from '../duck/actions';

interface Props extends EntityListProps<OrgMember, OrgMemberListFilter> {
  currentQrcodeAuthUserId?: number | undefined;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  OrgMember,
  OrgMemberListFilter,
  number,
  Props
>();

function shouldElementBeHidden(_: any, props: Props): boolean {
  const { itemBeingCreated, itemBeingUpdated } = props.entities;
  return Boolean(
    itemBeingUpdated || (itemBeingCreated && !itemBeingCreated.role),
  );
}

export const OrgMemberList = componentClassBuilder
  .i18nPrefix('org_member')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/communication/contact1.svg')
      .default,
  )
  .accessRights({
    full: AclObjectList.OrgMemberFullAccess,
    readonly: AclObjectList.OrgMemberReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="org.breadcrumb.it" /> },
    { text: <Translate id="org.breadcrumb.members" /> },
  ])
  .features({ addEntity: false })
  .entities(state => state.org.members)
  .actions(memberActions)
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'userName',
        placeholder: 'org_member.toolbar.placeholder.user_name',
        width: 150,
      })
      .text({
        prop: 'name',
        placeholder: 'org_member.toolbar.placeholder.name',
        width: 150,
      })
      .button({
        buttonType: 'search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(memberActions.invalidate(true));
        },
      })
      .button({
        buttonType: 'add',
        onClick: (props: Props) => {
          const { dispatch, identity } = props;
          const member: Partial<OrgMember> = {
            orgId: props.user.orgId,
            storeId:
              identity.storeId && identity.visibleStores.length === 1
                ? identity.storeId
                : undefined,
            requireChangePassword: false,
          };
          dispatch(memberActions.itemBeingCreated(member));
        },
      });
  })
  .editorWidth(550)
  .editor(builder => {
    builder
      .store({
        prop: 'storeId',
        label: 'org_member.editor.label.store',
      })
      .orgGroup({
        prop: 'groupId',
        label: 'org_member.editor.label.group',
      })
      .orgTeam({
        prop: 'teamId',
        label: 'org_member.editor.label.team',
      })
      .text({
        prop: 'userName',
        label: 'org_user.editor.label.user_name',
        placeholder: 'org_user.editor.placeholder.user_name',
        disabled: (_: any, props: Props) =>
          Boolean(props.entities.itemBeingUpdated),
      })
      .reactSelect({
        prop: 'role',
        label: 'org_user.editor.label.role',
        placeholder: 'org_user.editor.placeholder.role',
        values: OrgUserRoleTypeOptions,
        valueProp: 'value',
        labelProp: 'label',
        multi: true,
        onFormatOptionLabel(option: Option<OrgUserRoleType>) {
          return getString(option.label);
        },
        onValue(value, type) {
          if (type === 'get') {
            return ((value as string) || '')
              .split(',')
              .map(x => x.trim())
              .filter(x => x);
          } else {
            return (value as string[]).join(',');
          }
        },
      })
      .checkbox({
        prop: 'dialCustomerDisabled',
        label: 'org_member.editor.label.dial_customer_disabled',
      })
      .image({
        prop: 'avatar',
        label: 'org_member.editor.label.avatar',
        placeholder: 'org_member.editor.placeholder.avatar',
      })
      .text({
        prop: 'name',
        label: 'org_member.editor.label.name',
        placeholder: 'org_member.editor.placeholder.name',
      })
      .text({
        prop: 'mobile',
        label: 'org_user.editor.label.mobile',
        placeholder: 'org_user.editor.placeholder.mobile',
      })
      .text({
        prop: 'age',
        type: 'number',
        label: 'org_member.editor.label.age',
        placeholder: 'org_member.editor.placeholder.age',
      })
      .text({
        prop: 'jobNo',
        label: 'org_member.editor.label.job_no',
        placeholder: 'org_member.editor.placeholder.job_no',
        helpText: 'org_member.editor.help_text.job_no',
      })
      .text({
        prop: 'jobTitle',
        label: 'org_member.editor.label.job_title',
        placeholder: 'org_member.editor.placeholder.job_title',
      })
      .text({
        prop: 'seniorityInYears',
        type: 'number',
        label: 'org_member.editor.label.seniority_in_years',
        placeholder: 'org_member.editor.placeholder.seniority_in_years',
      })
      .multiTextInput({
        prop: 'academicTitle',
        label: 'org_member.editor.label.academic_title',
        placeholder: 'org_member.editor.placeholder.academic_title',
        serialize: values => values.map(x => x.trim()).join(','),
        deserialize: (value: string) =>
          value
            .split(/[,，；\s]/g)
            .map(x => x.trim())
            .filter(x => x),
      })
      .multiTextInput({
        prop: 'honourTitle',
        label: 'org_member.editor.label.honour_title',
        placeholder: 'org_member.editor.placeholder.honour_title',
        serialize: values => values.map(x => x.trim()).join(','),
        deserialize: (value: string) =>
          value
            .split(/[,，；\s]/g)
            .map(x => x.trim())
            .filter(x => x),
      })
      .multiTextInput({
        prop: 'specialities',
        label: 'org_member.editor.label.specialities',
        placeholder: 'org_member.editor.placeholder.specialities',
        serialize: values => values.map(x => x.trim()).join(','),
        deserialize: (value: string) =>
          value
            .split(/[,，；\s]/g)
            .map(x => x.trim())
            .filter(x => x),
      })
      .datepicker({
        prop: 'birthDate',
        label: 'org_member.editor.label.birth_date',
        placeholder: 'org_member.editor.placeholder.birth_date',
      })
      .datepicker({
        prop: 'employedAt',
        label: 'org_member.editor.label.employed_at',
        placeholder: 'org_member.editor.placeholder.employed_at',
      })
      .orgMember({
        prop: 'managerId',
        label: 'org_member.editor.label.manager',
        placeholder: 'org_member.editor.placeholder.manager',
        onChange: (changes, _, member: OrgMember | null) => {
          if (member) {
            changes.managerName = member.name;
          }
          return undefined;
        },
      })
      .textArea({
        prop: 'remark',
        label: 'org_member.editor.label.remark',
        placeholder: 'org_member.editor.placeholder.remark',
      })
      .textArea({
        prop: 'description',
        label: 'org_member.editor.label.description',
        placeholder: 'org_member.editor.placeholder.description',
        markdown: true,
        height: 400,
      })
      .custom({
        label: '',
        render: (props: Props) => {
          if (shouldElementBeHidden(null, props)) return null;
          return (
            <div className="kt-section__content kt-section__content--solid kt-font-bold">
              <Translate id="org_member.editor.user_info_section_title" />
            </div>
          );
        },
      })
      .text({
        type: 'password',
        prop: 'password',
        label: 'org_user.editor.label.password',
        hidden: shouldElementBeHidden,
        placeholder: 'org_user.editor.placeholder.password',
      })
      .checkbox({
        label: 'org_user.editor.label.change_password_on_first_login',
        prop: 'requireChangePassword',
        hidden: shouldElementBeHidden,
      })
      // .text({
      //   prop: 'email',
      //   label: 'org_user.editor.label.email',
      //   placeholder: 'org_user.editor.placeholder.email',
      //   hidden: shouldElementBeHidden,
      // })
      .radioList({
        prop: 'gender',
        options: GenderOptions,
        inline: true,
        label: 'org_user.editor.label.gender',
        hidden: shouldElementBeHidden,
        convertValue: (value: string) => Number(value),
      });
  })
  .withEmptyCellPlaceholder('/')
  .columns([
    {
      prop: 'storeId',
      width: 150,
      text: 'col.store_name',
      render: ({ storeId }) => <StoreName storeId={storeId} />,
    },
    {
      prop: 'name',
      width: 100,
      text: 'col.name',
    },
    {
      prop: 'jobNo',
      width: 80,
      align: 'center',
      hidden: true,
      text: 'org_member.col.job_no',
    },
    {
      prop: 'jobTitle',
      width: 100,
      align: 'center',
      text: 'org_member.col.job_title',
    },
    {
      prop: 'role',
      width: 200,
      text: 'org_user.col.role',
      render: ({ role }) => {
        const roles = (role || '')
          .split(',')
          .map(x => x.trim())
          .filter(x => x) as OrgUserRoleType[];
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {roles.map(x => (
              <OrgUserRoleTypeLabel
                value={x}
                key={x}
                style={{ marginRight: '3px' }}
              />
            ))}
          </span>
        );
      },
    },
    {
      prop: 'groupName',
      width: 100,
      hidden: true,
      text: 'org_member.col.group',
    },
    {
      prop: 'teamName',
      width: 100,
      hidden: true,
      text: 'org_member.col.team',
    },
    {
      prop: 'managerName',
      width: 100,
      align: 'center',
      text: 'org_member.col.manager_name',
      hidden: true,
    },
    {
      prop: 'userName',
      width: 150,
      text: 'col.user_name',
      render: ({ userName }) => (
        <OrgInfoView>{org => `${org.cid}/${userName}`}</OrgInfoView>
      ),
    },
    {
      prop: 'mobile',
      width: 150,
      align: 'center',
      text: 'col.mobile',
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .addActionButtons([
    'edit',
    {
      key: 'auth-qrcode',
      icon: 'la la-qrcode',
      tooltip: 'org_user.qrcode_auth.tooltip',
      disabled: x => Boolean(!x.storeId),
      onClick: (item, props: Props) => {
        props.dispatch(setCurrentMemberQrcodeAuth(item.userId));
      },
    },
    'remove',
  ])
  .validate((entity: OrgMember, isCreating: boolean) => {
    const userName = entity.userName?.trim();
    const password = entity.password?.trim();
    const role = entity.role;
    const email = entity.email?.trim();
    const mobile = entity.mobile?.trim();
    const jobNo = entity.jobNo?.trim();
    const jobTitle = entity.jobTitle?.trim();

    let msg = '';

    if (isCreating) {
      if (!entity.storeId) {
        msg = 'store_required';
      } else if (!userName) {
        msg = 'user_name_required';
      } else if (!/^[a-z][a-z0-9_]{0,19}$/.test(userName)) {
        msg = 'invalid_user_name';
      } else if (!password) {
        msg = 'password_required';
      } else if (password!.length < 5 || password!.length > 30) {
        msg = 'password_length_error';
      } else if (!role) {
        msg = 'role_required';
      } else if (jobNo && !/^\d+$/.test(jobNo)) {
        msg = 'invalid_job_no_format';
      } else if (!jobTitle) {
        msg = 'job_title_required';
      } else if (email && !isEmail(email)) {
        msg = 'invalid_email';
      } else if (!mobile) {
        msg = 'mobile_required';
      } else if (mobile && !isMobile(mobile)) {
        msg = 'invalid_mobile';
      }
    } else {
      if (jobNo && !/^\d+$/.test(jobNo)) {
        msg = 'invalid_job_no_format';
      } else if (!jobTitle) {
        msg = 'job_title_required';
      } else if (email && !isEmail(email)) {
        msg = 'invalid_email';
      } else if (!mobile) {
        msg = 'mobile_required';
      } else if (mobile && !isMobile(mobile)) {
        msg = 'invalid_mobile';
      } else if (entity.managerId === entity.id) {
        msg = 'manager_cannot_be_self';
      }
    }

    if (msg) {
      throw new Error(getString(`org_user.editor.error.${msg}`));
    }
  })
  .onAdd((entity, props: Props) => {
    entity.orgId = props.user.orgId;
  })
  .onDeleted((error, props: Props) => {
    if (!error) {
      props.dispatch(userActions.invalidate(true));
    }
  })
  .mapStateToProps(state => ({
    currentQrcodeAuthUserId: state.org.members.currentQrcodeAuthUserId,
  }))
  .componentDidUpdate((props, prevProps) => {
    if (props.activeStoreId !== prevProps.activeStoreId) {
      props.dispatch(memberActions.invalidate(true));
      props.dispatch(userActions.invalidate(true));
    }
  })
  .onRender((props: Props) => {
    return (
      <Block>
        <UserAuthQrcodeAside
          userId={props.currentQrcodeAuthUserId}
          onClose={() => {
            props.dispatch(setCurrentMemberQrcodeAuth(undefined));
          }}
        />
      </Block>
    );
  })
  .getClass();
