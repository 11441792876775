import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleInspectionSiteCheckItem } from 'model';
import { InspectionSiteItems } from '../states';

const initialState: InspectionSiteItems = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {}
};

export default createAsyncListActionReducers<VehicleInspectionSiteCheckItem, InspectionSiteItems>(
  'inspection.items',
  initialState,
  {
    mapItemKey: x => x.id
  }
);