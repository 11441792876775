import { TransFunction } from 'app';
import classNames from 'classnames';
import { buildSimpleTreeModel, imgproxy, SimpleTreeNode } from 'lib/helpers';
import React, { ChangeEvent, Component, MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { Clear, getString } from 'shared/components';
import { getCategoryNodeDecendants } from '../common/helpers';
import { InspectionSiteCategories, InspectionSites } from '../duck/states';
import { EventEmitter } from 'events';
import { VehicleInspectionSite, VehicleInspectionSiteCategory } from 'model';

import './site-picker.scss';
import { InlineSvg } from 'lib/components';

interface Props {
  excludedSiteId?: number;
  trans: TransFunction;
  categories: InspectionSiteCategories;
  sites: InspectionSites;
  selectedSiteIds: number[];
  event: EventEmitter;
  onSiteSelectionChange: (siteIds: number[], selected: boolean) => void;
}

type CategoryNode = SimpleTreeNode<VehicleInspectionSiteCategory, number>;

interface SiteListGroup {
  node: CategoryNode | null;
  siteList: VehicleInspectionSite[];
}

// tslint:disable-next-line: variable-name
let __previousSelectedCategoryId: number | null = null;

interface State {
  selectedCategoryId: number | null;
  keyword?: string;
}

export class InspectionSitePicker extends Component<Props, State> {
  state: State = { selectedCategoryId: null };

  constructor(props: Props) {
    super(props);
    this.state = { selectedCategoryId: __previousSelectedCategoryId };
  }

  componentDidMount() {
    this.props.event.on('select-all-sites', this.onSelectAllSites);
    this.props.event.on('deselect-all-sites', this.onDeselectAllSites);
  }

  componentWillUnmount() {
    this.props.event.off('select-all-sites', this.onSelectAllSites);
    this.props.event.off('deselect-all-sites', this.onDeselectAllSites);
  }

  render() {
    const { selectedCategoryId, keyword } = this.state;

    const { nodes, siteList, selectedSiteSet } = this.buildSiteList();

    const groups: SiteListGroup[] = [];

    if (selectedCategoryId) {
      const node = nodes.find(x => x.id === selectedCategoryId)!;
      const map = new Map<number, SiteListGroup>();
      for (const site of siteList) {
        let targetNode: CategoryNode | null = null;
        if (node.children?.length) {
          for (const childNode of node.children) {
            if (childNode.id === site.categoryId) {
              targetNode = childNode;
              break;
            } else {
              const decendants = getCategoryNodeDecendants(childNode);
              const set = new Set(decendants.map(x => x.id));
              if (set.has(site.categoryId)) {
                targetNode = childNode;
                break;
              }
            }
          }
        }
        const categoryId = targetNode ? targetNode.id : 0;
        if (!map.has(categoryId)) {
          map.set(categoryId, { node: targetNode, siteList: [] });
          groups.push(map.get(categoryId)!);
        }
        const group = map.get(categoryId)!;
        group.siteList.push(site);
      }
    } else {
      groups.push({
        node: null,
        siteList,
      });
    }

    return (
      <div className="site-picker">
        <div className="site-picker__header">
          <ul className="site-picker__category-list">
            <li
              className={classNames('site-picker__category-item', {
                'site-picker__category-item--selected':
                  selectedCategoryId === null,
              })}
            >
              <a href="#" onClick={this.onCategoryClick(null)}>
                <Translate id="inspection_template.detail.modal.site_picker.all_sites" />
              </a>
            </li>
            <li
              className={classNames('site-picker__category-item', {
                'site-picker__category-item--selected':
                  selectedCategoryId === 0,
              })}
            >
              <a href="#" onClick={this.onCategoryClick(0)}>
                <Translate id="inspection_template.detail.modal.site_picker.hardware_capable" />
              </a>
            </li>
            {nodes.map(node => (
              <li
                key={node.id}
                className={classNames('site-picker__category-item', {
                  'site-picker__category-item--selected':
                    selectedCategoryId === node.id,
                })}
              >
                <a href="#" onClick={this.onCategoryClick(node.data.id)}>
                  {node.data.name}
                </a>
              </li>
            ))}
          </ul>
          <div className="site-picker__keyword-filter">
            <Clear onClear={this.onClearKeyword} visible={Boolean(keyword)}>
              <input
                type="text"
                className="form-control form-control-sm"
                value={keyword || ''}
                placeholder={getString(
                  'inspection_template.detail.modal.site_picker.keyword_placeholder',
                )}
                onChange={this.onKeywordChange}
              />
            </Clear>
          </div>
        </div>
        {groups.map(group => (
          <div
            key={group.node ? group.node.id : 0}
            className="site-picker__site-group"
          >
            {group.node && (
              <div className="site-picker__site-group-hd">
                <h3 className="site-picker__site-group-name">
                  <i className="la la-bars" />
                  {group.node.data.name}
                </h3>
                <div className="site-picker__site-group-actions">
                  <a href="#" onClick={this.onSelectAllSitesInGroup(group)}>
                    <Translate id="inspection_template.detail.modal.site_picker.select_all" />
                  </a>
                  <a href="#" onClick={this.onDeselectAllSitesInGroup(group)}>
                    <Translate id="inspection_template.detail.modal.site_picker.deselect_all" />
                  </a>
                </div>
              </div>
            )}
            <ul className="site-picker__site-list">
              {group.siteList.map(site => (
                <li
                  key={site.id}
                  className={classNames('site-picker__site', {
                    'site-picker__site--selected': selectedSiteSet.has(site.id),
                  })}
                >
                  <a
                    href="#"
                    className="site-picker__site-icon"
                    onClick={this.onSiteClick(site.id, selectedSiteSet)}
                  >
                    {(() => {
                      if (!site.iconUrl) {
                        return (
                          <InlineSvg src="public/img/default-site-icon.svg" />
                        );
                      }
                      const url = new URL(site.iconUrl);
                      if (/\.svg$/i.exec(url.pathname)) {
                        return <InlineSvg src={imgproxy(site.iconUrl)} />;
                      }
                      return <img src={site.iconUrl} />;
                    })()}
                  </a>
                  <div className="site-picker__site-name">{site.name}</div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  }

  onSiteClick(siteId: number, selectedSiteSet: Set<number>) {
    return (e: MouseEvent) => {
      e.preventDefault();
      const selected = !selectedSiteSet.has(siteId);
      this.props.onSiteSelectionChange([siteId], selected);
    };
  }

  onCategoryClick(categoryId: number | null) {
    return (e: MouseEvent) => {
      e.preventDefault();
      if (this.state.selectedCategoryId !== categoryId) {
        __previousSelectedCategoryId = categoryId;
        this.setState({
          selectedCategoryId: categoryId,
        });
      }
    };
  }

  onKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      keyword: e.target.value,
    });
  };

  onClearKeyword = () => {
    this.setState({ keyword: '' });
  };

  onSelectAllSites = () => {
    const { siteList, selectedSiteSet } = this.buildSiteList();
    const selectedSiteIds: number[] = [];
    for (const site of siteList) {
      if (!selectedSiteSet.has(site.id)) {
        selectedSiteIds.push(site.id);
      }
    }
    if (selectedSiteIds.length) {
      this.props.onSiteSelectionChange(selectedSiteIds, true);
    }
  };

  onDeselectAllSites = () => {
    const { siteList, selectedSiteSet } = this.buildSiteList();
    const deselectedSiteIds: number[] = [];
    for (const site of siteList) {
      if (selectedSiteSet.has(site.id)) {
        deselectedSiteIds.push(site.id);
      }
    }
    if (deselectedSiteIds.length) {
      this.props.onSiteSelectionChange(deselectedSiteIds, false);
    }
  };

  onSelectAllSitesInGroup(group: SiteListGroup) {
    return (e: MouseEvent) => {
      e.preventDefault();
      const selectedSiteSet = this.getSelectedSiteSet();
      const selectedSiteIds: number[] = [];
      for (const site of group.siteList) {
        if (!selectedSiteSet.has(site.id)) {
          selectedSiteIds.push(site.id);
        }
      }
      if (selectedSiteIds.length) {
        this.props.onSiteSelectionChange(selectedSiteIds, true);
      }
    };
  }

  onDeselectAllSitesInGroup(group: SiteListGroup) {
    return (e: MouseEvent) => {
      e.preventDefault();
      const selectedSiteSet = this.getSelectedSiteSet();
      const deselectedSiteIds: number[] = [];
      for (const site of group.siteList) {
        if (selectedSiteSet.has(site.id)) {
          deselectedSiteIds.push(site.id);
        }
      }
      if (deselectedSiteIds.length) {
        this.props.onSiteSelectionChange(deselectedSiteIds, false);
      }
    };
  }

  buildSiteList() {
    const { sites, categories, excludedSiteId } = this.props;
    const { selectedCategoryId, keyword } = this.state;
    const nodes = buildSimpleTreeModel(
      categories.result || [],
      x => x.id,
      x => x.parentCategoryId,
    );

    let siteList = sites.result || [];
    if (excludedSiteId) {
      siteList = siteList.filter(x => x.id !== excludedSiteId);
    }

    if (selectedCategoryId) {
      const selectedNode = nodes.find(x => x.id === selectedCategoryId)!;
      const decendents = getCategoryNodeDecendants(selectedNode);
      const set = new Set(decendents.map(x => x.id));
      set.add(selectedCategoryId);
      siteList = siteList.filter(x => set.has(x.categoryId));
    } else if (selectedCategoryId === 0) {
      siteList = siteList.filter(x => x.supportsIdevice);
    }

    if (keyword?.trim()) {
      const word = keyword.trim();
      siteList = siteList.filter(
        x => x.pyInitial?.includes(word) || x.name.includes(word),
      );
    }

    const selectedSiteSet = this.getSelectedSiteSet();

    return { nodes, siteList, selectedSiteSet };
  }

  getSelectedSiteSet() {
    return new Set(this.props.selectedSiteIds);
  }
}
