/**
 * @file: VehicleReceptionOrderService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { tokenService } from 'lib';
import { VehicleReceptionOrderServiceBase } from './base/VehicleReceptionOrderService';

export class VehicleReceptionOrderService extends VehicleReceptionOrderServiceBase {
  async deletePermanently(orderNo: string): Promise<void> {
    return void (await this.api
      .delete()
      .url('/vehicle-reception-orders/:orderNo/delete-permanently', { orderNo })
      .future());
  }

  getRenderAsPdfUrl(orderNo: string, format?: 'html' | 'pdf'): string {
    const token = tokenService.getToken();
    return this.api.url('/order-printable.html', {
      orderno: orderNo,
      format: format ?? 'pdf',
      token,
    });
  }

  async rollback(orderNo: string, reason: string): Promise<void> {
    return void (await this.api
      .post()
      .url('/vehicle-reception-orders/:orderNo/rollback-finished', { orderNo })
      .data({ reason })
      .future());
  }
}
