/**
 * @file: acl.service.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';

export class AclService {
  constructor(private readonly api: APIService) {}

  async listAcls(): Promise<string[]> {
    return await this.api.get().url('/acl').future();
  }
}
