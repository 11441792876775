/**
 * @file: QuotationTemplateConfigService.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */
import { APIService } from 'lib/restful-client/api.service';
import {
  QuotationTemplateConfig,
  QuotationTemplateInfo,
  QuotationTemplateInfoWithDetail,
} from 'model';

export class QuotationTemplateConfigService {
  constructor(private readonly api: APIService) {}

  async listQuotationTemplateConfigs(): Promise<QuotationTemplateInfo[]> {
    return await this.api.get().url('/quotation-template-config/list').future();
  }

  async getQuotationTemplateConfig(
    orgId: number,
    storeId: number,
  ): Promise<QuotationTemplateInfoWithDetail | undefined> {
    return await this.api
      .get()
      .url('/quotation-template-config', { orgId, storeId })
      .future();
  }

  async saveQuotationTemplateConfig(
    storeId: number,
    config: QuotationTemplateConfig,
  ): Promise<QuotationTemplateInfoWithDetail> {
    return await this.api
      .post()
      .url('/quotation-template-config')
      .data({ storeId, config })
      .future();
  }

  async duplicateQuotationTemplateConfig(
    storeId: number,
    from: {
      orgId: number;
      storeId: number;
    },
  ) {
    return await this.api
      .post()
      .url('/quotation-template-config/duplicate')
      .data({ storeId, from })
      .future();
  }

  async deleteQuotationTemplateConfig(storeId: number) {
    return await this.api
      .delete()
      .url('/quotation-template-config')
      .data({ storeId })
      .future();
  }

  async enableQuotationTemplateConfig(storeId: number) {
    return await this.api
      .post()
      .url('/quotation-template-config/enable')
      .data({ storeId })
      .future();
  }

  async disableQuotationTemplateConfig(storeId: number) {
    return await this.api
      .post()
      .url('/quotation-template-config/disable')
      .data({ storeId })
      .future();
  }
}
