import { Badge, Button, Callout } from 'lib/metronic/components';
import { CSSProperties, MouseEvent, PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { getString } from 'shared/components';
import {
  MpTemplateIndustries,
  TemplateIndustrySettingsState,
  WeixinTemplateLibraryConfState,
} from '../duck/states';
import { isTemplateIndustrySet } from './helpers';

interface Props {
  templateIndustrySettings: TemplateIndustrySettingsState;
  templateLibraryConf: WeixinTemplateLibraryConfState;
  templateIndustries: MpTemplateIndustries;
  onApplyTemplateSettingsAutomatically: () => void;
}

export class TemplateSettingsSummary extends PureComponent<Props> {
  render() {
    return (
      <Callout color="brand" diagonalBg>
        <Callout.Content>{this.renderContent()}</Callout.Content>
        <Callout.Action>
          <Button
            color="info"
            bold
            uppercased
            wide
            size="large"
            onClick={this.props.onApplyTemplateSettingsAutomatically}
          >
            <Translate id="integration.templates.button.apply_template_settings_automatically_short" />
          </Button>
        </Callout.Action>
      </Callout>
    );
  }

  renderContent() {
    const { templateIndustrySettings } = this.props;
    if (templateIndustrySettings.isLoading) {
      return (
        <div className="mp-template-settings-summary__loading">
          <Translate id="integration.templates.industry_settings.loading" />
        </div>
      );
    }

    if (templateIndustrySettings.error) {
      return (
        <div className="mp-template-settings-summary__error">
          <ReactMarkdown>
            {getString('integration.templates.industry_settings.load_error', {
              error: templateIndustrySettings.error.message,
            })}
          </ReactMarkdown>
        </div>
      );
    }

    const badgeStyle: CSSProperties = { padding: '0.3rem 0.6rem' };

    return (
      <div className="mp-template-settings-summary__industry-settings">
        <dl className="mp-template-settings-summary__industry-settings-industry-info">
          <dt>
            <Translate id="integration.templates.industry_settings.label.primary_industry" />
          </dt>
          {isTemplateIndustrySet(
            templateIndustrySettings.result?.primaryIndustry,
          ) && (
            <dd>
              <Badge color="success" size="small" outline style={badgeStyle}>
                {templateIndustrySettings.result!.primaryIndustry.firstClass}
              </Badge>
              <Badge color="success" size="small" outline style={badgeStyle}>
                {templateIndustrySettings.result!.primaryIndustry.secondClass}
              </Badge>
            </dd>
          )}
          {!isTemplateIndustrySet(
            templateIndustrySettings.result?.primaryIndustry,
          ) && (
            <dd>
              <Translate
                id="integration.templates.industry_settings.not_set"
                data={{
                  button: (
                    <a
                      href="#"
                      data-type="primary"
                      onClick={this.onSetIndustry}
                    >
                      <Translate id="integration.templates.button.set_industry" />
                    </a>
                  ),
                }}
              />
            </dd>
          )}
        </dl>
        <dl className="mp-template-settings-summary__industry-settings-industry-info">
          <dt>
            <Translate id="integration.templates.industry_settings.label.secondary_industry" />
          </dt>
          {isTemplateIndustrySet(
            templateIndustrySettings.result?.secondaryIndustry,
          ) && (
            <dd>
              <Badge color="info" size="small" outline style={badgeStyle}>
                {templateIndustrySettings.result!.secondaryIndustry.firstClass}
              </Badge>
              <Badge color="info" size="small" outline style={badgeStyle}>
                {templateIndustrySettings.result!.secondaryIndustry.secondClass}
              </Badge>
            </dd>
          )}
          {!isTemplateIndustrySet(
            templateIndustrySettings.result?.secondaryIndustry,
          ) && (
            <dd>
              <Translate
                id="integration.templates.industry_settings.not_set"
                data={{
                  button: (
                    <a
                      href="#"
                      data-type="primary"
                      onClick={this.onSetIndustry}
                    >
                      <Translate id="integration.templates.button.set_industry" />
                    </a>
                  ),
                }}
              />
            </dd>
          )}
        </dl>
      </div>
    );
  }

  onSetIndustry = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  };
}
