import { ActionTypes } from '../types';
import { createStandardAction, createAsyncActionCreators } from 'lib/duck/actions';
import { WeixinOpenAuthorizedInfo } from 'model';
import { weixinService } from 'services';

export const authorizationActions = createAsyncActionCreators('integration.authorization', {
  fetchHandler: (_, invalidate: boolean) => {
    if (invalidate) {
      return weixinService.invalidateAuthorizedInfo();
    }
    return weixinService.getAuthorizedInfo();
  }
}, undefined, (_, fetch) => {
  return fetch(true);
});

export function beginAuthorization() {
  return createStandardAction(ActionTypes.BeginAuthorization);
}

export function authorizationSuccess(authorizedInfo: WeixinOpenAuthorizedInfo) {
  return createStandardAction(ActionTypes.AuthoizationSuccess, authorizedInfo);
}

export function authorizationFailed(error: Error) {
  return createStandardAction(ActionTypes.AuthorizationFailed, error);
}