import classNames from 'classnames';
import { memo } from 'react';
import { useHasFullAccess } from '../../shared/components/WithFullAccess';
import { DetailView } from './Detail';
import { LeftSidebar } from './LeftSidebar';

export const Content = memo(() => {
  const hasFullAccess = useHasFullAccess();
  return (
    <div
      className={classNames('construction-tpl-man__content', {
        'construction-tpl-man__content--no-full-access': !hasFullAccess,
      })}
    >
      <LeftSidebar />
      <DetailView />
    </div>
  );
});
