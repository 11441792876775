/**
 * @file: VehicleInspectionSiteService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionSiteListFilter, VehicleInspectionSite, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions, VehicleInspectionSiteCheckItem } from 'model';

export class VehicleInspectionSiteServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionSiteListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionSite> : VehicleInspectionSite[]> {
    return await this.api.get()
      .url('/vehicle-inspection-sites', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionSite | null> {
    return await this.api.get()
      .url('/vehicle-inspection-sites/:id', { id })
      .future();
  }

  async create(vehicleInspectionSite: Partial<VehicleInspectionSite>): Promise<VehicleInspectionSite> {
    return await this.api.post()
      .url('/vehicle-inspection-sites')
      .data(vehicleInspectionSite)
      .future();
  }

  async update(vehicleInspectionSite: Partial<VehicleInspectionSite>): Promise<VehicleInspectionSite> {
    return await this.api.put()
      .url('/vehicle-inspection-sites/:id', { id: vehicleInspectionSite.id })
      .data(vehicleInspectionSite)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/vehicle-inspection-sites/:id', { id })
      .future();
  }

  async getCheckItemsBySiteId(id: number, recursive = false): Promise<VehicleInspectionSiteCheckItem[]> {
    return await this.api.get()
      .url('/vehicle-inspection-sites/:id/check-items', { id, recursive })
      .future();
  }

  async createVehicleInspectionSiteCheckItem(item: Partial<VehicleInspectionSiteCheckItem>): Promise<VehicleInspectionSiteCheckItem> {
    const id = item.siteId;
    if (!id) {
      throw new Error('item.siteId is required. ');
    }
    return await this.api.post()
      .url('/vehicle-inspection-sites/:id/check-items')
      .data(item)
      .future();
  }

  async updateVehicleInspectionSiteCheckItem(item: VehicleInspectionSiteCheckItem): Promise<VehicleInspectionSiteCheckItem> {
    const id = item.siteId;
    if (!id) {
      throw new Error('item.siteId is required. ');
    }
    return await this.api.put()
      .url('/vehicle-inspection-sites/:id/check-items')
      .data(item)
      .future();
  }

  async deleteVehicleInspectionSiteCheckItem(
    siteId: number | VehicleInspectionSiteCheckItem, itemId?: number
  ): Promise<void> {
    if (siteId && typeof siteId === 'object') {
      itemId = siteId.id;
      if (!siteId.siteId) {
        throw new Error('siteId cannot be null or undefined');
      }
      siteId = siteId.siteId;
    }
    if (!siteId || !itemId) {
      throw new Error('siteId and itemId are both required');
    }
    return void await this.api.delete()
      .url('/vehicle-inspection-sites/:siteId/check-items/:itemId', { siteId, itemId })
      .future();
  }
}