/**
 * @file: InspectionTaskTroubleCodeStateLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Badge, BadgeProps } from 'lib/metronic/components';
import { InspectionTaskTroubleCodeState } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { LabelColors } from '../LabelColors';

interface Props extends BadgeProps {
  value: InspectionTaskTroubleCodeState | null | undefined;
}

const Values = {
  [InspectionTaskTroubleCodeState.Unknown]: 'unknown',
  [InspectionTaskTroubleCodeState.Determinate]: 'determinate',
  [InspectionTaskTroubleCodeState.Incidental]: 'incidental',
  [InspectionTaskTroubleCodeState.Other]: 'other',
};

export const InspectionTaskTroubleCodeStateLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  let enumValue = '';
  if (value !== null && value !== undefined) {
    enumValue = Values[value];
  }

  const labelColor =
    value && LabelColors.InspectionTaskTroubleCodeState?.[enumValue]
      ? LabelColors.InspectionTaskTroubleCodeState[enumValue]
      : color;

  return (
    <Badge inline color={labelColor} {...otherProps}>
      <Translate id={`inspection_task_trouble_code_state.${enumValue}`} />
    </Badge>
  );
});
