/**
 * @file: DataExportTaskOriginOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { DataExportTaskOrigin, Option } from 'model';

export const DataExportTaskOriginOptions: Array<Option<DataExportTaskOrigin>> =
  [
    { value: DataExportTaskOrigin.Boss, label: 'data_export_task_origin.boss' },
    { value: DataExportTaskOrigin.Org, label: 'data_export_task_origin.org' },
    {
      value: DataExportTaskOrigin.Store,
      label: 'data_export_task_origin.store',
    },
  ];

export const DataExportTaskOriginOptionsWithDefault: Array<
  Option<DataExportTaskOrigin | null>
> = [
  { value: null, label: 'data_export_task_origin.__default__' },
  { value: DataExportTaskOrigin.Boss, label: 'data_export_task_origin.boss' },
  { value: DataExportTaskOrigin.Org, label: 'data_export_task_origin.org' },
  { value: DataExportTaskOrigin.Store, label: 'data_export_task_origin.store' },
];
