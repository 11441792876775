/**
 * @file: InspectionTemplateBarrierLevel.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum InspectionTemplateBarrierLevel {
  Global = 'global',
  Agent = 'agent',
  Org = 'org',
  Store = 'store',
}

export const InspectionTemplateBarrierLevelValueSet = new Set([
  InspectionTemplateBarrierLevel.Global,
  InspectionTemplateBarrierLevel.Agent,
  InspectionTemplateBarrierLevel.Org,
  InspectionTemplateBarrierLevel.Store,
]);
