/**
 * @file: VehicleInspectionSiteRelService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionSiteRelListFilter, VehicleInspectionSiteRel, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class VehicleInspectionSiteRelServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionSiteRelListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionSiteRel> : VehicleInspectionSiteRel[]> {
    return await this.api.get()
      .url('/vehicle-inspection-site-rels', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionSiteRel | null> {
    return await this.api.get()
      .url('/vehicle-inspection-site-rels/:id', { id })
      .future();
  }

  async create(vehicleInspectionSiteRel: Partial<VehicleInspectionSiteRel>): Promise<VehicleInspectionSiteRel> {
    return await this.api.post()
      .url('/vehicle-inspection-site-rels')
      .data(vehicleInspectionSiteRel)
      .future();
  }

  async update(vehicleInspectionSiteRel: Partial<VehicleInspectionSiteRel>): Promise<VehicleInspectionSiteRel> {
    return await this.api.put()
      .url('/vehicle-inspection-site-rels/:id', { id: vehicleInspectionSiteRel.id })
      .data(vehicleInspectionSiteRel)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/vehicle-inspection-site-rels/:id', { id })
      .future();
  }
}