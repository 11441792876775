/**
 * @file: MiniprogramCertificateTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, MiniprogramCertificateType } from 'model';

export const MiniprogramCertificateTypeOptions: Array<Option<MiniprogramCertificateType>> = [
  { value: MiniprogramCertificateType.Uncertified, label: 'miniprogram_certificate_type.uncertified' },
  { value: MiniprogramCertificateType.WeixinCertified, label: 'miniprogram_certificate_type.weixin_certified' }
];

export const MiniprogramCertificateTypeOptionsWithDefault: Array<Option<MiniprogramCertificateType | null>> = [
  { value: null, label: 'miniprogram_certificate_type.__default__' },
  { value: MiniprogramCertificateType.Uncertified, label: 'miniprogram_certificate_type.uncertified' },
  { value: MiniprogramCertificateType.WeixinCertified, label: 'miniprogram_certificate_type.weixin_certified' }
];