/**
 * @file: MaterialTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { MaterialType, Option } from 'model';

export const MaterialTypeOptions: Array<Option<MaterialType>> = [
  { value: MaterialType.Oem, label: '@string/material_type.oem' },
  { value: MaterialType.Brand, label: '@string/material_type.brand' },
  {
    value: MaterialType.Aftermarket,
    label: '@string/material_type.aftermarket',
  },
  { value: MaterialType.Salvaged, label: '@string/material_type.salvaged' },
  {
    value: MaterialType.Refurbished,
    label: '@string/material_type.refurbished',
  },
  {
    value: MaterialType.Counterfeit,
    label: '@string/material_type.counterfeit',
  },
  { value: MaterialType.Other, label: '@string/material_type.other' },
];

export const MaterialTypeOptionsWithDefault: Array<
  Option<MaterialType | null>
> = [
  { value: null, label: 'material_type.__default__' },
  { value: MaterialType.Oem, label: 'material_type.oem' },
  { value: MaterialType.Brand, label: 'material_type.brand' },
  { value: MaterialType.Aftermarket, label: 'material_type.aftermarket' },
  { value: MaterialType.Salvaged, label: 'material_type.salvaged' },
  { value: MaterialType.Refurbished, label: 'material_type.refurbished' },
  { value: MaterialType.Counterfeit, label: 'material_type.counterfeit' },
  { value: MaterialType.Other, label: 'material_type.other' },
];
