import classNames from 'classnames';
import {
  MpMenuItemClick,
  OfficialAccountMenuItemMediaType,
  OfficialAccountMenuItemMediaTypeOptions,
  WeixinImageMsg,
  WeixinMsg,
  WeixinMsgType,
  WeixinMusicInfo,
  WeixinMusicMsg,
  WeixinNewsMsg,
  WeixinTextMsg,
  WeixinVideoMsg,
  WeixinVoiceMsg,
} from 'model';
import { MouseEvent, PureComponent } from 'react';
import { getString } from 'shared/components';
import { ImageEditor } from './ImageEditor';
import { MusicEditor } from './MusicEditor';
import { NewsEditor } from './NewsEditor';
import { TextEditor } from './TextEditor';
import { VideoEditor } from './VideoEditor';
import { VoiceEditor } from './VoiceEditor';

const msgTypeToMediaTypeMap = {
  [WeixinMsgType.Text]: OfficialAccountMenuItemMediaType.Text,
  [WeixinMsgType.Image]: OfficialAccountMenuItemMediaType.Image,
  [WeixinMsgType.Video]: OfficialAccountMenuItemMediaType.Video,
  [WeixinMsgType.Voice]: OfficialAccountMenuItemMediaType.Voice,
  [WeixinMsgType.Music]: OfficialAccountMenuItemMediaType.Music,
  [WeixinMsgType.News]: OfficialAccountMenuItemMediaType.News,
};

const mediaTypeToMsgTypeMap = {
  [OfficialAccountMenuItemMediaType.Text]: WeixinMsgType.Text,
  [OfficialAccountMenuItemMediaType.Image]: WeixinMsgType.Image,
  [OfficialAccountMenuItemMediaType.Video]: WeixinMsgType.Video,
  [OfficialAccountMenuItemMediaType.Voice]: WeixinMsgType.Voice,
  [OfficialAccountMenuItemMediaType.Music]: WeixinMsgType.Music,
  [OfficialAccountMenuItemMediaType.News]: WeixinMsgType.News,
};

const mediaTypeIconMap = {
  [OfficialAccountMenuItemMediaType.Text]: 'fab fa-buysellads',
  [OfficialAccountMenuItemMediaType.Image]: 'fa fa-image',
  [OfficialAccountMenuItemMediaType.Video]: 'fa fa-video',
  [OfficialAccountMenuItemMediaType.Voice]: 'fa fa-voicemail',
  [OfficialAccountMenuItemMediaType.Music]: 'fa fa-music',
  [OfficialAccountMenuItemMediaType.News]: 'fab fa-elementor',
};

interface Props {
  editorId: number;
  parentIndex: number | undefined;
  item: MpMenuItemClick;
  index: number;
  data: WeixinMsg;
  onMenuItemChange: <T>(
    editorId: number,
    path: number[],
    changes: any,
    data: { [P in keyof T]?: Partial<T[P]> },
  ) => void;
  onClickMsgTypeChange: (
    editorId: number,
    path: number[],
    msgType: WeixinMsgType,
  ) => void;
  onLoadMenuItemData: (editorId: number, path: number[]) => void;
}

export class ClickItemEditor extends PureComponent<Props> {
  componentDidMount() {
    this.loadMenuItemData();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.data.msgType !== prevProps.data.msgType) {
      this.loadMenuItemData();
    }
  }

  loadMenuItemData() {
    const { data, editorId, onLoadMenuItemData } = this.props;
    if (data.msgType === WeixinMsgType.News) {
      const newsData = data as WeixinNewsMsg;
      if (newsData?.mediaId && !newsData.articleCount) {
        onLoadMenuItemData(editorId, this.getMenuItemPath());
      }
    }
  }

  render() {
    const { data, parentIndex, index } = this.props;
    const indexPath =
      parentIndex !== undefined ? [parentIndex, index] : [index];
    return (
      <div className="form-group click-item-editor">
        <ul className="click-item-editor__media-types">
          {OfficialAccountMenuItemMediaTypeOptions.map(option => (
            <li
              className={classNames({
                selected: msgTypeToMediaTypeMap[data.msgType] === option.value,
              })}
              key={option.value}
            >
              <a
                href="#"
                onClick={this.onMediaTypeClick}
                data-media-type={option.value}
              >
                <i className={mediaTypeIconMap[option.value]} />
                {getString(option.label)}
              </a>
            </li>
          ))}
        </ul>
        {data.msgType === WeixinMsgType.Text && (
          <TextEditor
            key={indexPath.join('/')}
            content={(data as WeixinTextMsg).content}
            onChange={this.onTextMsgContentChange}
          />
        )}
        {data.msgType === WeixinMsgType.Image && (
          <ImageEditor
            mediaId={(data as WeixinImageMsg).image.mediaId}
            data={data as WeixinImageMsg}
            isTempMedia={(data as WeixinImageMsg).image.__isTempMedia}
            onChange={this.onImageMsgMediaIdChange}
          />
        )}
        {data.msgType === WeixinMsgType.Video && (
          <VideoEditor
            mediaId={(data as WeixinVideoMsg).video.mediaId}
            data={data as WeixinVideoMsg}
            isTempMedia={(data as WeixinVideoMsg).video.__isTempMedia}
            onChange={this.onVideoMsgMediaIdChange}
          />
        )}
        {data.msgType === WeixinMsgType.Voice && (
          <VoiceEditor
            mediaId={(data as WeixinVoiceMsg).voice.mediaId}
            data={data as WeixinVoiceMsg}
            isTempMedia={(data as WeixinVoiceMsg).voice.__isTempMedia}
            onChange={this.onVoiceMsgMediaIdChange}
          />
        )}
        {data.msgType === WeixinMsgType.Music && (
          <MusicEditor
            musicInfo={(data as WeixinMusicMsg).music}
            onChange={this.onMusicMsgMediaIdChange}
          />
        )}
        {data.msgType === WeixinMsgType.News && (
          <NewsEditor
            data={data as WeixinNewsMsg}
            onChange={this.onNewsMsgInfoChange}
          />
        )}
      </div>
    );
  }

  onTextMsgContentChange = (content: string) => {
    this.props.onMenuItemChange<WeixinTextMsg>(
      this.props.editorId,
      this.getMenuItemPath(),
      {},
      { content },
    );
  };

  onImageMsgMediaIdChange = (mediaId: string, extra: any) => {
    this.props.onMenuItemChange<WeixinImageMsg>(
      this.props.editorId,
      this.getMenuItemPath(),
      {},
      {
        image: { mediaId, __isTempMedia: false, ...(extra || {}) },
      },
    );
  };

  onVideoMsgMediaIdChange = (mediaId: string, extra: any) => {
    this.props.onMenuItemChange<WeixinVideoMsg>(
      this.props.editorId,
      this.getMenuItemPath(),
      {},
      {
        video: { mediaId, __isTempMedia: false, ...(extra || {}) },
      },
    );
  };

  onVoiceMsgMediaIdChange = (mediaId: string, extra: any) => {
    this.props.onMenuItemChange<WeixinVoiceMsg>(
      this.props.editorId,
      this.getMenuItemPath(),
      {},
      {
        voice: { mediaId, __isTempMedia: false, ...(extra || {}) },
      },
    );
  };

  onMusicMsgMediaIdChange = (musicInfo: Partial<WeixinMusicInfo>) => {
    this.props.onMenuItemChange<WeixinMusicMsg>(
      this.props.editorId,
      this.getMenuItemPath(),
      {},
      { music: musicInfo },
    );
  };

  onNewsMsgInfoChange = (mediaId: string, extra: any) => {
    this.props.onMenuItemChange<WeixinNewsMsg>(
      this.props.editorId,
      this.getMenuItemPath(),
      {},
      { mediaId, ...(extra || {}) },
    );
  };

  onMediaTypeClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const type: OfficialAccountMenuItemMediaType = e.currentTarget.getAttribute(
      'data-media-type',
    )! as any;
    const msgType = mediaTypeToMsgTypeMap[type] as WeixinMsgType;
    if (msgType !== this.props.data.msgType) {
      this.props.onClickMsgTypeChange(
        this.props.editorId,
        this.getMenuItemPath(),
        msgType,
      );
    }
  };

  private getMenuItemPath() {
    if (this.props.parentIndex !== undefined) {
      return [this.props.parentIndex, this.props.index];
    }
    return [this.props.index];
  }
}
