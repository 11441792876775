/**
 * @file: DeliveryCheckTemplateTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { DeliveryCheckTemplateType, Option } from 'model';

export const DeliveryCheckTemplateTypeOptions: Array<
  Option<DeliveryCheckTemplateType>
> = [
  {
    value: DeliveryCheckTemplateType.WithInspectionResults,
    label: 'delivery_check_template_type.with_inspection_results',
  },
  {
    value: DeliveryCheckTemplateType.WithoutInspectionResults,
    label: 'delivery_check_template_type.without_inspection_results',
  },
];

export const DeliveryCheckTemplateTypeOptionsWithDefault: Array<
  Option<DeliveryCheckTemplateType | null>
> = [
  { value: null, label: 'delivery_check_template_type.__default__' },
  {
    value: DeliveryCheckTemplateType.WithInspectionResults,
    label: 'delivery_check_template_type.with_inspection_results',
  },
  {
    value: DeliveryCheckTemplateType.WithoutInspectionResults,
    label: 'delivery_check_template_type.without_inspection_results',
  },
];
