import { useAppContext, useTranslateToString } from 'app/AppContext';
import { AppState } from 'app/duck/states';
import {
  beginConfigureConstructionTemplate,
  endConfigureConstructionTemplate,
  makeDefaultConstructionTemplateConfig,
} from 'app/inspection/duck/actions';
import {
  ConstructionTemplateConfigDetail,
  ConstructionTemplateConfigTarget,
} from 'app/inspection/duck/states';
import { BreadcrumbItem, Page, Portlet } from 'lib/metronic/components';
import { AclObjectList, ConstructionTemplateConfig } from 'model';
import { useEffect, useMemo, useRef } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Prompt } from 'react-router-dom';
import { ItemSourceLabel, getString } from 'shared/components';
import { StoreNameLabel } from 'shared/components/StoreNameLabel';
import { WithFullAccessContext } from 'shared/components/WithFullAccess';
import { usePersistFn } from 'utils/usePersistFn';
import { Actions } from './Actions';
import { Content } from './Content';
import { Context } from './Context';

const kFullAccessRights = [AclObjectList.VehicleConstructionTemplateFullAccess];

const pageIcon =
  require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/selected-file.svg').default;

export function ConstructionTemplateConfigManager() {
  const trans = useTranslateToString();

  const dispatch = useDispatch();

  const defaultTemplateConfig = useRef<ConstructionTemplateConfig>(
    makeDefaultConstructionTemplateConfig(),
  ).current;

  const history = useHistory();
  const queryString = history.location.search.substring(1);

  const target = useMemo<ConstructionTemplateConfigTarget>(() => {
    const params = new URLSearchParams(queryString);
    const type = params.get(
      'target',
    ) as ConstructionTemplateConfigTarget['type'];
    const storeIdStr = params.get('storeId');
    const storeId = storeIdStr ? Number(storeIdStr) : undefined;
    return type === 'store' ? { type, storeId: storeId! } : { type };
  }, [queryString]);

  const { user, identity } = useAppContext();
  const hasRights =
    target.type !== 'system' &&
    ((target.type === 'org' && user.isOrgRootUser) ||
      (target.type === 'store' &&
        identity.visibleStoreSet.has(target.storeId))) &&
    identity.hasAccessRights(...kFullAccessRights);

  const breadcrumbs = useMemo<BreadcrumbItem[]>(() => {
    return [
      { text: <Translate id="inspection.breadcrumb.it" /> },
      {
        text: <Translate id="inspection.breadcrumb.construction_templates" />,
        href: '/inspection/construction-template-config/list',
      },
      {
        text: (
          <span>
            <ItemSourceLabel value={target.type} />
            {target.type === 'store' && (
              <StoreNameLabel
                storeId={target.storeId}
                style={{
                  marginLeft: '0.3rem',
                }}
              />
            )}
          </span>
        ),
      },
    ];
  }, [target]);

  useEffect(() => {
    dispatch(beginConfigureConstructionTemplate(target));
  }, [dispatch, target]);

  useEffect(
    () => () => {
      setTimeout(() => {
        dispatch(endConfigureConstructionTemplate());
      }, 1000);
    },
    [dispatch],
  );

  const onRefresh = usePersistFn(() => {
    dispatch(beginConfigureConstructionTemplate(target));
  });

  const detail = useSelector<
    AppState,
    ConstructionTemplateConfigDetail | undefined
  >(state => state.inspection.constructionTemplateConfigs.detail);

  const hasChanges = detail?.state?.templateConfigBeingEdited != null;

  useEffect(() => {
    if (hasChanges) {
      window.onbeforeunload = () => {
        return getString('construction_tpl.changes.navigate_away_warn');
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [hasChanges]);

  if (!detail) {
    return null;
  }

  const state = detail.state;

  return (
    <WithFullAccessContext.Provider value={{ hasRights }}>
      <Context.Provider
        value={{
          target: detail.target!,
          templateConfig:
            state.templateConfigBeingEdited ??
            state.result ??
            defaultTemplateConfig,
          templateConfigBeingEdited: state.templateConfigBeingEdited,
          selectedSidebarItem: state.selectedSidebarItem,
          configInfo: detail.configInfo,
        }}
      >
        <Prompt
          when={hasChanges}
          message={getString('construction_tpl.changes.navigate_away_warn')}
        />
        <Page
          title={trans('construction_tpl.title')}
          fullAccessRight={AclObjectList.VehicleConstructionTemplateFullAccess}
          readonlyAccessRight={
            AclObjectList.VehicleConstructionTemplateReadonlyAccess
          }
          className="construction-tpl-man"
        >
          <Page.Header>
            <Page.Header.Main>
              <Page.Breadcrumb items={breadcrumbs} />
            </Page.Header.Main>
          </Page.Header>
          <Page.Content>
            <Portlet mobile cellPadding={0}>
              <Portlet.Header
                size="large"
                title={trans('construction_tpl.title')}
                icon={pageIcon}
                iconColor="brand"
                onRefresh={onRefresh}
              >
                <Actions />
              </Portlet.Header>
              <Portlet.Body noPadding>
                <Content />
              </Portlet.Body>
            </Portlet>
          </Page.Content>
        </Page>
      </Context.Provider>
    </WithFullAccessContext.Provider>
  );
}
