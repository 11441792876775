import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { DeliveryCheckTemplates } from '../states';
import { VehicleDeliveryCheckTemplate } from 'model';

const initialState: DeliveryCheckTemplates = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {},
};

export default createAsyncListActionReducers<
  VehicleDeliveryCheckTemplate,
  DeliveryCheckTemplates
>('inspection.delivery_check_templates', initialState, {
  mapItemKey: x => x.id,
});
