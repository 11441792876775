import { ConstructionTemplateConfigTarget } from 'app/inspection/duck/states';
import {
  ConstructionTemplateCategory,
  ConstructionTemplateConfig,
  ConstructionTemplateInfo,
} from 'model';

export function isCategoryConfigValid(category: ConstructionTemplateCategory) {
  if (
    category.name == null ||
    category.name.trim().length === 0 ||
    category.groups.length === 0 ||
    !category.templateId
  ) {
    return false;
  }
  for (const group of category.groups) {
    if (!group.name || group.name.trim().length === 0) {
      return false;
    }
    for (const item of group.items) {
      if (!item.name || item.name.trim().length === 0) {
        return false;
      }
    }
  }
  return true;
}

export function isTemplateConfigValid(template: ConstructionTemplateInfo) {
  return (
    template.name != null &&
    template.name.trim().length > 0 &&
    template.procedures.length > 0 &&
    template.procedures.every(
      x => x.name != null && x.name.trim().length > 0 && x.type.length > 0,
    )
  );
}

export function isConstructionTemplateConfigValid(
  templateConfig: ConstructionTemplateConfig,
) {
  return (
    templateConfig.templates.every(isTemplateConfigValid) &&
    templateConfig.categories.every(isCategoryConfigValid)
  );
}

export function storeIdFromConstructionTemplateConfigTarget(
  target: ConstructionTemplateConfigTarget,
) {
  return target.type === 'store' ? target.storeId : 0;
}
