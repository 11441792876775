/**
 * @file: subscribers.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { OrgSubscribedUser } from 'model';
import { OrgSubscribedUsers } from '../states';

const initialState: OrgSubscribedUsers = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
};

export default createAsyncListActionReducers<
  OrgSubscribedUser,
  OrgSubscribedUsers
>('org.subscribers', initialState, {
  mapItemKey: x => x.id,
});
