import { produce } from 'immer';
import { ExtendedPropertyDefAttachment } from 'model';
import { MouseEvent, ReactNode, memo } from 'react';
import { Translate } from 'react-localize-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { array_move } from 'utils';
import { makeUniqueIdAlphabetic } from 'utils/makeUniqueId';
import { usePersistFn } from 'utils/usePersistFn';
import { ExtendedPropertyAttachmentEditor } from './ExtendedPropertyAttachmentEditor';

const AttachmentList = memo(({ children }: { children?: ReactNode }) => {
  return (
    <div className="property-editor__attachment-list-sortable noselect">
      {children}
    </div>
  );
});

const AttachmentListItem = memo(({ children }: { children?: ReactNode }) => {
  return (
    <div className="property-editor__attachment-list-item">{children}</div>
  );
});

const SortableAttachmentList = SortableContainer(AttachmentList);
const SortableAttachmentListItem = SortableElement(AttachmentListItem);

export const ExtendedPropertyAttachments = memo(
  ({
    attachments,
    onChange,
  }: {
    attachments: ExtendedPropertyDefAttachment[];
    onChange?: (attachments: ExtendedPropertyDefAttachment[]) => void;
  }) => {
    const onAttachmentChange = usePersistFn(
      (attachment: ExtendedPropertyDefAttachment) => {
        const updated = produce(attachments, draft => {
          const index = draft.findIndex(x => x.id === attachment.id);
          if (index < 0) return;
          Object.assign(draft[index], attachment);
        });
        onChange?.(updated);
      },
    );

    const onAddClick = usePersistFn((e: MouseEvent) => {
      e.preventDefault();
      const updated = produce(attachments, draft => {
        draft.push({
          id: makeUniqueIdAlphabetic(8),
          type: 'image',
          content: '',
          visibility: 'private',
        });
      });
      onChange?.(updated);
    });

    const onRemove = usePersistFn(
      (attachment: ExtendedPropertyDefAttachment) => {
        const updated = produce(attachments, draft => {
          const index = draft.findIndex(x => x.id === attachment.id);
          if (index >= 0) {
            draft.splice(index, 1);
          }
        });
        onChange?.(updated);
      },
    );

    const onAttachmentSorted = usePersistFn(
      (e: { oldIndex: number; newIndex: number }) => {
        const { newIndex, oldIndex } = e;
        if (newIndex === oldIndex) return;
        const updated = produce(attachments, draft => {
          array_move(draft, oldIndex, newIndex);
        });
        onChange?.(updated);
      },
    );

    return (
      <div className="property-editor__attachment-item-list">
        <SortableAttachmentList
          helperClass="property-editor__attachment-list-item--being-dragged"
          lockAxis="y"
          distance={3}
          onSortEnd={onAttachmentSorted}
        >
          {attachments.map((attachment, index) => (
            <SortableAttachmentListItem key={attachment.id} index={index}>
              <ExtendedPropertyAttachmentEditor
                attachment={attachment}
                onChange={onAttachmentChange}
                onRemove={onRemove}
              />
            </SortableAttachmentListItem>
          ))}
        </SortableAttachmentList>
        <div
          style={{
            fontStyle: 'italic',
            fontSize: '0.9rem',
            color: '#999',
          }}
        >
          <a href="#" onClick={onAddClick}>
            <i className="fa fa-plus" style={{ marginRight: '0.35rem' }} />
            <Translate id="store.config.label.extended_property.attachment.add" />
          </a>
        </div>
      </div>
    );
  },
);
