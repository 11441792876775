/**
 * @file: task-list.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators, createStandardAction } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { VehicleInspectionTask, VehicleInspectionTaskListFilter } from 'model';
import { vehicleInspectionTaskService } from 'services';
import { ActionTypes } from '../types';

export const taskListActions =
  createListAsyncActionCreators<AppState, VehicleInspectionTask, VehicleInspectionTaskListFilter, number>(
  'tasks.task_list',
  {
    shouldInvalidateForFilter: true,
    shouldFetchOnInvalidate: true,
    getItemBeingCreated: (state: AppState) => state.tasks.taskList.itemBeingCreated,
    getItemBeingUpdated: (state: AppState) => state.tasks.taskList.itemBeingUpdated,
    getItemsBeingDeleted: (state: AppState) => state.tasks.taskList.itemsBeingDeleted,
    async fetchHandler(state: AppState) {
      const orgId = state.identity!.orgId;
      const storeId = state.activeStoreId || undefined;
      return await vehicleInspectionTaskService.list(
        Object.assign({}, state.tasks.taskList.filter, { orgId, storeId }),
        state.tasks.taskList.sorts?.[0],
        state.tasks.taskList.offset,
        state.tasks.taskList.limit,
        { count: true, recursive: true }
        );
    }
  }
);

export function setTaskListActiveTaskId(taskId: number | undefined) {
  return createStandardAction(ActionTypes.SetActiveTask, taskId);
}