import { APIService } from 'lib/restful-client/api.service';
import {
  IdType,
  ListResult,
  Maybe,
  ProductAuthorizationContract,
  ProductAuthorizationContractClientConfig,
  ProductAuthorizationRequest,
  ProductAuthorizationRequestEvent,
} from 'model';
import {
  AuthorizedProductSearchParams,
  CreateProductAuthorizationRequest,
  ProductAgentInfo,
  ProductAuthorizationContractSearchParams,
  ProductAuthorizationRequestSearchParams,
  ProductWithContractId,
  UpdateProductAuthorizationRequest,
} from 'model/app/product';

export class ProductAuthorizationService {
  constructor(private readonly api: APIService) {}

  async searchAuthorizationRequests(
    params: ProductAuthorizationRequestSearchParams | undefined,
    offset: number,
    limit: number,
  ): Promise<ListResult<ProductAuthorizationRequest>> {
    return await this.api
      .get()
      .url('/product-authorization/requests/search', {
        storeId: params?.storeId,
        supplierId: params?.supplierId,
        status: params?.status?.length ? params.status.join(',') : undefined,
        offset,
        limit,
      })
      .future();
  }

  async searchAuthorizationContracts(
    params: ProductAuthorizationContractSearchParams | undefined,
    offset: number,
    limit: number,
  ): Promise<ListResult<ProductAuthorizationContract>> {
    return await this.api
      .get()
      .url('/product-authorization/contracts/search', {
        storeId: params?.storeId,
        supplierId: params?.supplierId,
        status: params?.status?.length ? params.status.join(',') : undefined,
        offset,
        limit,
      })
      .future();
  }

  async createAuthorizationRequest(
    request: CreateProductAuthorizationRequest,
  ): Promise<ProductAuthorizationRequest> {
    return await this.api
      .post()
      .url('/product-authorization/requests')
      .data(request)
      .future();
  }

  async updateAuthorizationRequest(
    request: UpdateProductAuthorizationRequest,
  ): Promise<ProductAuthorizationRequest> {
    return await this.api
      .patch()
      .url('/product-authorization/requests/:requestId', {
        requestId: request.requestId,
      })
      .data(request)
      .future();
  }

  async cancelAuthorizationRequest(
    requestId: IdType,
    remark?: string,
  ): Promise<ProductAuthorizationRequest> {
    return await this.api
      .post()
      .url('/product-authorization/requests/:requestId/cancel', { requestId })
      .data({ remark })
      .future();
  }

  async getAuthorizedProductAgentsOfClient(
    storeId?: number,
  ): Promise<ProductAgentInfo[]> {
    return await this.api
      .get()
      .url('/product-authorization/authorized-product-agents', { storeId })
      .future();
  }

  async getAuthorizationRequestEvents(
    requestId: IdType,
  ): Promise<ProductAuthorizationRequestEvent[]> {
    return await this.api
      .get()
      .url('/product-authorization/requests/:requestId/events', { requestId })
      .future();
  }

  async searchProducts(
    params: AuthorizedProductSearchParams | undefined,
    offset: number,
    limit: number,
  ): Promise<ListResult<ProductWithContractId>> {
    return await this.api
      .get()
      .url('/product-authorization/products/search', {
        ...params,
        offset,
        limit,
      })
      .future();
  }

  async getClientConfig(
    contractId: IdType,
    productId: IdType,
  ): Promise<ProductAuthorizationContractClientConfig> {
    return await this.api
      .get()
      .url(
        '/product-authorization-contract-client-configs/query/contractid-productid',
        { contractId, productId },
      )
      .future();
  }

  async saveClientConfig(
    contractId: IdType,
    productId: IdType,
    config: {
      productReferralServiceAgentDistributionRatio?: Maybe<number>;
      productAdoptionServiceAgentDistributionRatio?: Maybe<number>;
    },
  ): Promise<ProductAuthorizationContractClientConfig> {
    return await this.api
      .post()
      .url('/product-authorization-contract-client-configs/save-config')
      .data({ contractId, productId, config })
      .future();
  }
}
