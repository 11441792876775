import React, { useRef , memo, useCallback, useEffect, useState } from 'react';
import { TabNav } from 'lib/metronic/components';
import { AsideRight } from 'lib/metronic/layout';
import { Translate } from 'react-localize-redux';
import { orgUserService } from 'services';

export const UserAuthQrcodeAside = memo(
  ({ userId, onClose }: { userId?: number; onClose?: () => void }) => {
    return (
      <AsideRight
        open={userId != null}
        onClose={onClose}
        // width={350}
      >
        <AsideRight.Nav>
          <TabNav line="brand" bolder>
            <TabNav.Item active>
              <Translate id="org_user.qrcode_auth.title" />
            </TabNav.Item>
          </TabNav>
        </AsideRight.Nav>
        <AsideRight.Content>
          {userId && <Content userId={userId} />}
        </AsideRight.Content>
      </AsideRight>
    );
  },
);

type Data = { dataURI: string; ttl: number };

const Content = memo(({ userId }: { userId: number }) => {
  const [data, setData] = useState<Data>();
  const [expired, setExpired] = useState(false);
  const timer = useRef<any>();

  const refresh = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    orgUserService
      .renderUserAuthQrcode(userId)
      .then(res => {
        setData(res);
        setExpired(false);
        timer.current = setTimeout(() => {
          timer.current = null;
          setExpired(true);
        }, (res.ttl - 3) * 1000);
      })
      .catch(err => {
        alert('Error get qrcode: ' + err.message);
      });
  }, [userId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (data == null) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
      }}
    >
      <div style={{ width: 200, height: 200, position: 'relative' }}>
        <img src={data.dataURI} style={{ width: 200, height: 200 }} />
        {expired ? (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.85)',
              cursor: 'pointer',
            }}
            onClick={e => {
              e.preventDefault();
              refresh();
            }}
          >
            <span style={{ color: 'red' }}>
              <Translate id="org_user.qrcode_auth.expired" />
            </span>
          </div>
        ) : null}
      </div>
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          refresh();
        }}
        style={{ marginTop: '1rem' }}
      >
        <Translate id="org_user.qrcode_auth.refresh_btn" />
      </a>
    </div>
  );
});
