/**
 * @file: InspectionTaskTroubleCodeStateOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionTaskTroubleCodeState } from 'model';

export const InspectionTaskTroubleCodeStateOptions: Array<Option<InspectionTaskTroubleCodeState>> = [
  { value: InspectionTaskTroubleCodeState.Unknown, label: 'inspection_task_trouble_code_state.unknown' },
  { value: InspectionTaskTroubleCodeState.Determinate, label: 'inspection_task_trouble_code_state.determinate' },
  { value: InspectionTaskTroubleCodeState.Incidental, label: 'inspection_task_trouble_code_state.incidental' },
  { value: InspectionTaskTroubleCodeState.Other, label: 'inspection_task_trouble_code_state.other' }
];

export const InspectionTaskTroubleCodeStateOptionsWithDefault: Array<Option<InspectionTaskTroubleCodeState | null>> = [
  { value: null, label: 'inspection_task_trouble_code_state.__default__' },
  { value: InspectionTaskTroubleCodeState.Unknown, label: 'inspection_task_trouble_code_state.unknown' },
  { value: InspectionTaskTroubleCodeState.Determinate, label: 'inspection_task_trouble_code_state.determinate' },
  { value: InspectionTaskTroubleCodeState.Incidental, label: 'inspection_task_trouble_code_state.incidental' },
  { value: InspectionTaskTroubleCodeState.Other, label: 'inspection_task_trouble_code_state.other' }
];