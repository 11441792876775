/**
 * @file: DeliveryCheckTemplateBarrierLevel.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum DeliveryCheckTemplateBarrierLevel {
  Global = 'global',
  Agent = 'agent',
  Org = 'org',
  Store = 'store',
}

export const DeliveryCheckTemplateBarrierLevelValueSet = new Set([
  DeliveryCheckTemplateBarrierLevel.Global,
  DeliveryCheckTemplateBarrierLevel.Agent,
  DeliveryCheckTemplateBarrierLevel.Org,
  DeliveryCheckTemplateBarrierLevel.Store,
]);
