/**
 * @file: ReportService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import { ListResult, VehicleReport, VehicleReportFilter, VehicleReportProjection, VehicleReportSort } from 'model';

export class ReportService {
  constructor(protected api: APIService) {}

  async getReport(
    reportNo: string,
    projection?: VehicleReportProjection | null,
    ): Promise<VehicleReport | null> {
    return await this.api.get()
      .url('/vehicle-reports/:reportNo/detail', {
        reportNo,
        projection: projection && JSON.stringify(projection)
      })
      .future();
  }

  async searchReports(
    filter: Partial<VehicleReportFilter> | undefined | null,
    sorter: VehicleReportSort | null | undefined,
    projection: VehicleReportProjection | null | undefined,
    offset: number,
    limit: number
  ): Promise<VehicleReport[]> {
    return await this.api.post()
      .url('/vehicle-reports/search')
      .data({ filter, sorter, projection, offset, limit })
      .future();
  }

  async searchReportsWithTotal(
    filter: Partial<VehicleReportFilter> | undefined | null,
    sorter: VehicleReportSort | null | undefined,
    projection: VehicleReportProjection | null | undefined,
    offset: number,
    limit: number
  ): Promise<ListResult<VehicleReport>> {
    return await this.api.post()
      .url('/vehicle-reports/search')
      .data({ filter, sorter, projection, offset, limit, count: true })
      .future();
  }

  async getReportCount(
    filter?: Partial<VehicleReportFilter> | null
  ): Promise<number> {
    return await this.api.post()
      .url('/vehicle-reports/count')
      .data({ filter })
      .future();
  }
}