/**
 * @file: VehicleServiceSubjectService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleServiceSubject,
  VehicleServiceSubjectListFilter,
} from 'model';

export class VehicleServiceSubjectServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleServiceSubjectListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleServiceSubject>
      : VehicleServiceSubject[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-service-subjects',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleServiceSubject | null> {
    return await this.api
      .get()
      .url('/vehicle-service-subjects/:id', { id })
      .future();
  }

  async create(
    vehicleServiceSubject: Partial<VehicleServiceSubject>,
  ): Promise<VehicleServiceSubject> {
    return await this.api
      .post()
      .url('/vehicle-service-subjects')
      .data(vehicleServiceSubject)
      .future();
  }

  async update(
    vehicleServiceSubject: Partial<VehicleServiceSubject>,
  ): Promise<VehicleServiceSubject> {
    return await this.api
      .put()
      .url('/vehicle-service-subjects/:id', { id: vehicleServiceSubject.id })
      .data(vehicleServiceSubject)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-service-subjects/:id', { id })
      .future();
  }
}
