/**
 * @file: vehicle-bind-verify.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
  TemplateProperty,
} from './types';

export class VehicleBindVerifyTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  get supportedDestTypes(): TemplateMsgDestType[] {
    return ['customer'];
  }

  getOverriddenProperties(): TemplateProperty[] {
    return ['name'].map(x => ({
      property: x,
      label: `integration.templates.props.vehicle_bind_verify.${x}`,
    }));
  }

  getOverriddenExample() {
    return {
      title: '正在申请绑定车辆',
      name: '故乡的云',
      remark: '点击进行验证，非本人操作请忽略',
    };
  }
}
