/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

const common = require('./strings/common.yaml');
const header = require('./strings/header.yaml');
const enums = require('./strings/enums.yaml');
const menu = require('./strings/menu.yaml');
const dashboard = require('./strings/dashboard.yaml');
const login = require('./strings/login.yaml');
const settings = require('./strings/settings.yaml');
const user = require('./strings/user.yaml');
const device = require('./strings/device.yaml');
const org = require('./strings/organization.yaml');
const integration = require('./strings/integration.yaml');
const store = require('./strings/store.yaml');
const receptionOrder = require('./strings/reception-order.yaml');
const inspectionTask = require('./strings/inspection-task.yaml');
const inspectionReport = require('./strings/inspection-report.yaml');
const inspectionComment = require('./strings/inspection-comment.yaml');
const manufacturer = require('./strings/manufacturer.yaml');
const customer = require('./strings/customer.yaml');
const orgUser = require('./strings/org-user.yaml');
const orgMember = require('./strings/org-member.yaml');
const orgCustomer = require('./strings/org-customer.yaml');
const orgGroup = require('./strings/org-group.yaml');
const orgTeam = require('./strings/org-team.yaml');
const subscriber = require('./strings/subscriber.yaml');
const inspection = require('./strings/inspection.yaml');
const inspectionSite = require('./strings/inspection-site.yaml');
const inspectionSiteCategory = require('./strings/inspection-site-category.yaml');
const inspectionSiteItem = require('./strings/inspection-site-item.yaml');
const inspectionSiteItemOption = require('./strings/inspection-site-item-option.yaml');
const inspectionTool = require('./strings/inspection-tool.yaml');
const inspectionTemplates = require('./strings/inspection-template.yaml');
const orders = require('./strings/orders.yaml');
const tasks = require('./strings/tasks.yaml');
const reports = require('./strings/reports.yaml');
const vehicle = require('./strings/vehicle.yaml');
const serviceSubject = require('./strings/service-subject.yaml');
const inspectionSubject = require('./strings/inspection-subject.yaml');
const checkDeliveryTemplate = require('./strings/delivery-check-template.yaml');
const dataExport = require('./strings/data-export.yaml');
const constructionTpl = require('./strings/construction-template-config.yaml');
const product = require('./strings/product.yaml');
const authorizedProduct = require('./strings/authorized-product.yaml');
const productAuthorizationRequest = require('./strings/product-authorization-request.yaml');
const productAuthorizationContract = require('./strings/product-authorization-contract.yaml');
const quotationTpl = require('./strings/quotation-template-config.yaml');

export default {
  ...common,
  ...enums,
  header,
  menu,
  dashboard,
  login,
  settings,
  user,
  device,
  org,
  integration,
  store,
  inspection_order: receptionOrder,
  inspection_task: inspectionTask,
  inspection_report: inspectionReport,
  inspection_comment: inspectionComment,
  manufacturer,
  customer,
  org_user: orgUser,
  org_member: orgMember,
  org_customer: orgCustomer,
  org_group: orgGroup,
  org_team: orgTeam,
  subscriber,
  inspection,
  inspection_site: inspectionSite,
  inspection_site_category: inspectionSiteCategory,
  inspection_site_item: inspectionSiteItem,
  inspection_site_item_option: inspectionSiteItemOption,
  inspection_tool: inspectionTool,
  inspection_template: inspectionTemplates,
  delivery_check_template: checkDeliveryTemplate,
  orders,
  tasks,
  reports,
  vehicle,
  service_subject: serviceSubject,
  inspection_subject: inspectionSubject,
  data_export: dataExport,
  construction_tpl: constructionTpl,
  product,
  product_authorization_request: productAuthorizationRequest,
  product_authorization_contract: productAuthorizationContract,
  authorized_product: authorizedProduct,
  quotation_tpl: quotationTpl,
};
