import { AppState } from 'app';
import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import {
  ConstructionTemplateConfigDetail,
  ConstructionTemplateConfigState,
  ConstructionTemplateConfigTarget,
} from 'app/inspection/duck/states';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { ActionThunk } from 'lib/duck/interfaces';
import {
  ConstructionTemplateConfig,
  ConstructionTemplateConfigInfo,
  ConstructionTemplateConfigInfoWithDetail,
} from 'model';
import { constructionTemplateConfigService } from 'services';
import { ActionTypes } from '../types';
import { Draft, produce } from 'immer';

export const constructionTemplateConfigListActions =
  createListAsyncActionCreators<
    AppState,
    ConstructionTemplateConfigInfo,
    any,
    number
  >('inspection.construction-template-config', {
    shouldFetchOnInvalidate: true,
    getItemBeingCreated: (state: AppState) =>
      state.inspection.constructionTemplateConfigs.itemBeingCreated,
    getItemBeingUpdated: (state: AppState) =>
      state.inspection.constructionTemplateConfigs.itemBeingUpdated,
    getItemsBeingDeleted: (state: AppState) =>
      state.inspection.constructionTemplateConfigs.itemsBeingDeleted,
    fetchHandler: () =>
      constructionTemplateConfigService.listConstructionTemplateConfigs(),
    delete: item => {
      return constructionTemplateConfigService.deleteConstructionTemplateConfig(
        item.storeId,
      );
    },
    deleteMulti: items => {
      if (items.length === 1) {
        return constructionTemplateConfigService.deleteConstructionTemplateConfig(
          items[0].storeId,
        );
      }
      throw new Error('Delete multiple items are not supported');
    },
  });

export function endConfigureConstructionTemplate() {
  return createStandardAction(
    ActionTypes.EndConfigureConstructionTemplateConfig,
  );
}

export function beginConfigureConstructionTemplate(
  target: ConstructionTemplateConfigTarget,
): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();
    if (state.identity == null) return;

    dispatch(
      showAppLoading({
        message: 'Loading... ',
        status: 'loading',
      }),
    );

    let orgId = 0;
    let storeId = 0;

    if (target.type === 'store') {
      orgId = state.identity.orgId;
      storeId = target.storeId;
    } else if (target.type === 'org') {
      orgId = state.identity.orgId;
    }

    constructionTemplateConfigService
      .getConstructionTemplateConfig(orgId, storeId)
      .then(result => {
        const detail: ConstructionTemplateConfigDetail = {
          configInfo: result,
          target,
          state: {
            result: result?.conf ?? makeDefaultConstructionTemplateConfig(),
          },
        };
        dispatch(hideAppLoading());
        dispatch(
          createStandardAction(
            ActionTypes.BeginConfigureConstructionTemplateConfig,
            detail,
          ),
        );
      })
      .catch(err => {
        dispatch(
          showAppLoading({
            message: err.message,
            status: 'error',
            timeout: 10000,
          }),
        );
      });
  };
}

export function makeDefaultConstructionTemplateConfig(): ConstructionTemplateConfig {
  return {
    version: '1.0',
    categories: [],
    templates: [],
  };
}

function selectSidebarItem(
  item:
    | { type: 'category'; categoryId: string }
    | { type: 'template'; templateId: string }
    | undefined,
) {
  return createStandardAction(
    ActionTypes.ConstructionTemplateConfigSelectSidebarItem,
    item,
  );
}

function applyChangesToTemplateConfig(
  recipe: (
    draft: ConstructionTemplateConfig,
    state: Draft<ConstructionTemplateConfigState>,
  ) => void,
): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();
    const detail = state.inspection.constructionTemplateConfigs.detail;
    if (!detail) return;
    let templateConfigState = produce(detail.state, draft => {
      if (!draft.templateConfigBeingEdited) {
        draft.templateConfigBeingEdited =
          draft.result ?? makeDefaultConstructionTemplateConfig();
      }
    });
    templateConfigState = produce(templateConfigState, draft => {
      recipe(draft.templateConfigBeingEdited!, draft);
    });
    dispatch(
      createStandardAction(
        ActionTypes.ConstructionTemplateConfigChanged,
        templateConfigState,
      ),
    );
  };
}

function resetChanges(withConfig?: ConstructionTemplateConfig) {
  return createStandardAction(
    ActionTypes.ResetConstructionTemplateConfigChanges,
    withConfig,
  );
}

function saveConstructionConfigSuccess(
  configInfo: ConstructionTemplateConfigInfoWithDetail,
) {
  return createStandardAction(
    ActionTypes.SaveConstructionTemplateConfigSuccess,
    configInfo,
  );
}

function toggleIsConstructionConfigEnabled(): ActionThunk<AppState> {
  return async (dispatch, getState) => {
    const detail = getState().inspection.constructionTemplateConfigs.detail;
    if (!detail?.configInfo || detail.target.type === 'system') return;
    const storeId = detail.target.type === 'store' ? detail.target.storeId : 0;
    let enabled = detail.configInfo.enabled;
    if (enabled) {
      await constructionTemplateConfigService.disableConstructionTemplateConfig(
        storeId,
      );
    } else {
      await constructionTemplateConfigService.enableConstructionTemplateConfig(
        storeId,
      );
    }
    enabled = !enabled;
    dispatch(
      createStandardAction(
        ActionTypes.ToggleConstructionTemplateConfigEnabled,
        enabled,
      ),
    );
  };
}

export const constructionTplActions = {
  selectSidebarItem,
  applyChangesToTemplateConfig,
  resetChanges,
  saveConstructionConfigSuccess,
  toggleIsConstructionConfigEnabled,
};
