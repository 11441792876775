import { Checkbox } from 'lib/metronic/components';
import { ChangeEvent, PureComponent } from 'react';
import { FormElementCheckbox, FormElementCheckboxStyle } from '../types';

interface Props<T> {
  element: FormElementCheckbox<T>;
  value: boolean;
  disabled?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormCheckbox<T> extends PureComponent<Props<T>> {
  render() {
    const { element, value, disabled } = this.props;
    const checked = element.inverse ? !value : value;
    const style: FormElementCheckboxStyle = {
      solid: checked
        ? element.checkedStyle &&
          typeof element.checkedStyle.solid === 'boolean'
          ? element.checkedStyle.solid
          : true
        : typeof element.solid === 'boolean'
          ? element.solid
          : false,
      tick: checked
        ? element.checkedStyle && typeof element.checkedStyle.tick === 'boolean'
          ? element.checkedStyle.tick
          : false
        : typeof element.tick === 'boolean'
          ? element.tick
          : true,
      color: checked
        ? element.checkedStyle && typeof element.checkedStyle.color === 'string'
          ? element.checkedStyle.color
          : 'brand'
        : typeof element.color === 'string'
          ? element.color
          : 'brand',
    };

    return (
      <Checkbox
        label={element.label}
        solid={style.solid}
        tick={style.tick}
        color={style.color}
        checked={checked}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    const checked = e.target.checked;
    changes[element.prop] = (element.inverse ? !checked : checked) as any;
    void element.onChange?.(changes, extra);
    this.props.onChange(changes);
  };
}
