/**
 * @file: UserWeixinBindingType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum UserWeixinBindingType {
  Mp = 'mp',
  Open = 'open',
  App = 'app'
}

export const UserWeixinBindingTypeValueSet = new Set([
  UserWeixinBindingType.Mp,
  UserWeixinBindingType.Open,
  UserWeixinBindingType.App
]);