import classNames from 'classnames';
import { Organization, OrgGroup, OrgTeam } from 'model';
import { Component } from 'react';
import { Options } from 'react-select';
import { orgGroupService, orgTeamService } from 'services';
import { Select } from '../Select';
import { getString } from '../StringLabel';

interface Props {
  orgId: number;
  storeId?: number | null;
  groupId?: number | null;
  teamId?: number | null;
  className?: string;
  showGroupOnly?: boolean;
  disabled?: boolean;
  onChange: (
    groupId: number | undefined,
    teamId: number | undefined,
    group: OrgGroup | null,
    team: OrgTeam | null,
  ) => void;
}

interface State {
  groups: OrgGroup[] | null;
  isLoadingGroups: boolean;
  teams: OrgTeam[] | null;
  isLoadingTeams: boolean;
}

export class GroupTeamPicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      groups: null,
      isLoadingGroups: false,
      teams: null,
      isLoadingTeams: false,
    };
  }

  render() {
    const { groups, isLoadingGroups, teams, isLoadingTeams } = this.state;
    const { storeId, groupId, teamId, className, showGroupOnly, disabled } =
      this.props;

    const selectedGroup =
      groups && groupId ? groups.find(x => x.id === groupId) : null;

    const selectedTeam =
      teams && teamId ? teams.find(x => x.id === teamId) : null;

    return (
      <div className={classNames('group-team-picker', className)}>
        <Select<OrgGroup>
          key={`stores/${storeId || 0}/groups`}
          values={[]}
          valueProp="id"
          labelProp="name"
          disabled={disabled}
          className="group-team-picker__component group-team-picker__group"
          selectedValue={selectedGroup}
          placeholder={getString('group_team_picker.placeholder.select_group')}
          isLoading={isLoadingGroups}
          isClearable
          noOptionsMessage={getString('group_team_picker.no_values_msg.group')}
          async
          defaultValues
          onLoadValues={this.onLoadGroups}
          onChange={this.onGroupChange}
        />
        {!showGroupOnly && (
          <Select<OrgTeam>
            key={`stores/${storeId || 0}/groups/${groupId || 0}/teams`}
            values={teams || []}
            valueProp="id"
            labelProp="name"
            disabled={disabled}
            className="group-team-picker__component group-team-picker__team"
            selectedValue={selectedTeam}
            placeholder={getString('group_team_picker.placeholder.select_team')}
            isLoading={isLoadingTeams}
            isClearable
            noOptionsMessage={getString('group_team_picker.no_values_msg.team')}
            onChange={this.onTeamChange}
          />
        )}
      </div>
    );
  }

  onLoadGroups = async (_inputValue: string): Promise<Organization[]> => {
    const { orgId, storeId } = this.props;
    if (!storeId) return [];

    // clear stores.
    if (this.state.teams !== null) {
      this.setState({ teams: null, isLoadingTeams: false });
    }

    this.setState({ isLoadingGroups: true });
    try {
      const groups = (await orgGroupService.list(
        { orgId, storeId },
        null,
        0,
        0,
      )) as any;
      this.setState({ isLoadingGroups: false, groups });
      return groups;
    } catch (e) {
      console.error(e);
      this.setState({ isLoadingGroups: false, groups: null });
    }

    return [];
  };

  onGroupChange = async (value: Options<OrgGroup>) => {
    if (Array.isArray(value)) {
      return;
    }
    const { orgId, storeId } = this.props;

    if (!value) {
      this.props.onChange(undefined, undefined, null, null);
      this.setState({ teams: null });
      return;
    }

    const group = value as unknown as OrgGroup;

    this.props.onChange(group.id, undefined, group, null);

    // load teams
    this.setState({ teams: null, isLoadingTeams: true });

    try {
      const teams = (await orgTeamService.list(
        {
          orgId,
          storeId,
          groupId: group.id,
        },
        null,
        0,
        0,
      )) as any;
      this.setState({ teams, isLoadingTeams: false });
    } catch (e) {
      console.error(e);
      this.setState({ teams: null, isLoadingTeams: false });
    }
  };

  onTeamChange = (value: Options<OrgTeam>) => {
    if (Array.isArray(value)) {
      return;
    }
    const group = this.state.groups!.find(x => x.id === this.props.groupId)!;

    if (!value) {
      this.props.onChange(
        this.props.groupId || undefined,
        undefined,
        group,
        null,
      );
      return;
    }

    const team = value as unknown as OrgTeam;

    this.props.onChange(this.props.groupId!, team.id, group, team);
  };
}
