import classNames from 'classnames';
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  PureComponent,
  WheelEvent,
} from 'react';
import InputMask from 'react-input-mask';
import { getString } from 'shared/components/StringLabel';
import { randstr } from 'utils';
import { FormElementText } from '../types';

interface Props<T> {
  element: FormElementText<T>;
  value: any;
  disabled?: boolean;
  readOnly?: boolean;
  autocomplete?: boolean;
  useUncontrolled?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  error?: Error | null;
  onValidate?: (value: any) => Error | undefined;
  onChange: (values: Partial<T>) => void;
}

const numberInputOnWheelPreventChange = (e: WheelEvent) => {
  // Prevent the input value change
  const target = e.target as any;
  target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();
};

export class FormTextInput<T> extends PureComponent<Props<T>> {
  render() {
    const {
      disabled,
      readOnly,
      autocomplete,
      useUncontrolled,
      element,
      value,
      error,
    } = this.props;

    const { mask, maskChar, formatChars, alwaysShowMask } = element;

    if (mask) {
      if (useUncontrolled) {
        throw new Error('masked input does not support uncontrolled mode');
      }
      return (
        <>
          <InputMask
            mask={typeof mask === 'string' ? getString(mask) : mask}
            maskChar={maskChar}
            formatChars={formatChars}
            alwaysShowMask={alwaysShowMask}
            value={value === null || value === undefined ? '' : value}
            className={classNames('form-control m-input', {
              'is-invalid': error ?? element.error,
            })}
            name={randstr(16)}
            placeholder={getString(element.placeholder)}
            disabled={disabled}
            readOnly={readOnly}
            onChange={this.onChange}
            onKeyDown={element.onKeyDown ? this.onKeyDown : undefined}
            onKeyUp={element.onKeyUp ? this.onKeyUp : undefined}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onWheel={numberInputOnWheelPreventChange}
            style={{ width: element.width }}
          />
        </>
      );
    }

    if (
      (useUncontrolled === true && element.controlled !== true) ||
      (!useUncontrolled && element.controlled === false)
    ) {
      return (
        <input
          autoComplete={
            autocomplete === false || element.autocomplete === false
              ? 'new-password'
              : undefined
          }
          type={element.type}
          name={randstr(16)}
          className={classNames('form-control', {
            'is-invalid': error ?? element.error,
          })}
          placeholder={getString(element.placeholder)}
          defaultValue={value === null || value === undefined ? '' : value}
          disabled={disabled}
          readOnly={readOnly}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyDown={element.onKeyDown ? this.onKeyDown : undefined}
          onKeyUp={element.onKeyUp ? this.onKeyUp : undefined}
          onWheel={numberInputOnWheelPreventChange}
          style={{ width: element.width }}
        />
      );
    }

    return (
      <input
        autoComplete={
          autocomplete === false || element.autocomplete === false
            ? 'new-password'
            : undefined
        }
        type={element.type}
        name={randstr(16)}
        className={classNames('form-control', {
          'is-invalid': error ?? element.error,
        })}
        placeholder={getString(element.placeholder)}
        value={value === null || value === undefined ? '' : value}
        disabled={disabled}
        readOnly={readOnly}
        onChange={this.onChange}
        onKeyDown={element.onKeyDown ? this.onKeyDown : undefined}
        onKeyUp={element.onKeyUp ? this.onKeyUp : undefined}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onWheel={numberInputOnWheelPreventChange}
        style={{ width: element.width }}
      />
    );
  }

  handleValueChange(value: any) {
    const { element, onGetExtraInfo, onValidate } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    if (element.type === 'number') {
      value = value.trim() === '' ? element.defaultValue : Number(value);
      if (isNaN(value)) value = undefined;
    }
    if (onValidate?.(value) != null) return;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = value;
    const res = element.onChange && element.onChange(changes, extra);
    if (res === false) return;
    if (this.props.value === changes[element.prop]) return;
    this.props.onChange(changes);
  }

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.handleValueChange(e.target.value as any);
  };

  onFocus = (e: FocusEvent<HTMLInputElement>) => {
    const { element, onGetExtraInfo } = this.props;
    if (element.onFocus) {
      const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
      element.onFocus(e, extra);
    }
  };

  onBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { useUncontrolled, element, onGetExtraInfo } = this.props;
    if (element.onBlur) {
      const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
      element.onBlur(e, extra);
    }
    if (
      (useUncontrolled === true && element.controlled !== true) ||
      (!useUncontrolled && element.controlled === false)
    ) {
      this.handleValueChange(e.target.value as any);
    }
  };

  onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { element } = this.props;
    element.onKeyDown && element.onKeyDown(e);
  };

  onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const { element } = this.props;
    element.onKeyUp && element.onKeyUp(e);
  };
}
