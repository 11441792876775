import classNames from 'classnames';
import { Organization, OrgGroup } from 'model';
import { Component } from 'react';
import { Options } from 'react-select';
import { orgGroupService } from 'services';
import { Select } from '../Select';
import { getString } from '../StringLabel';

interface Props {
  orgId: number;
  storeId?: number | null;
  groupId?: number | null;
  className?: string;
  disabled?: boolean;
  onChange: (groupId: number | undefined, group: OrgGroup | null) => void;
}

interface State {
  groups: OrgGroup[] | null;
  isLoadingGroups: boolean;
}

export class GroupPicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      groups: null,
      isLoadingGroups: false,
    };
  }

  render() {
    const { groups, isLoadingGroups } = this.state;
    const { storeId, groupId, className, disabled } = this.props;

    const selectedGroup =
      groups && groupId
        ? groups.find(x => x.id === groupId && x.storeId === storeId)
        : null;

    return (
      <div className={classNames('group-picker', className)}>
        <Select<OrgGroup>
          key={`stores/${storeId || 0}/groups`}
          values={[]}
          valueProp="id"
          labelProp="name"
          disabled={disabled}
          className="group-picker__component group-picker__group"
          selectedValue={selectedGroup}
          placeholder={getString('group_picker.placeholder.select_group')}
          isLoading={isLoadingGroups}
          isClearable
          noOptionsMessage={getString('group_picker.no_values_msg.group')}
          async
          defaultValues
          onLoadValues={this.onLoadGroups}
          onChange={this.onGroupChange}
        />
      </div>
    );
  }

  onLoadGroups = async (_inputValue: string): Promise<Organization[]> => {
    const { orgId, storeId } = this.props;
    if (!storeId) return [];

    this.setState({ isLoadingGroups: true });
    try {
      const groups = (await orgGroupService.list(
        { orgId, storeId },
        null,
        0,
        0,
      )) as any;
      this.setState({ isLoadingGroups: false, groups });
      return groups;
    } catch (e) {
      console.error(e);
      this.setState({ isLoadingGroups: false, groups: null });
    }

    return [];
  };

  onGroupChange = async (value: Options<OrgGroup>) => {
    if (Array.isArray(value)) {
      return;
    }

    if (!value) {
      this.props.onChange(undefined, null);
      return;
    }

    const group = value as unknown as OrgGroup;

    this.props.onChange(group.id, group);
  };
}
