import classNames from 'classnames';
import extend from 'extend';
import { DatePartitionType, DateRange, StatItem } from 'model';
import moment from 'moment';
import { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { arr2map, comparer, computeChainRelativeDateRange } from 'utils';
import { StatAspect, StatsStateByTime } from './duck/states';

const AXES_ID_DEFAULT = 'default';
// const AXES_ID_ONE = 'one';
// const MIXED_CHART = true;

const CHART_COLORS: Record<string, string> = {
  purple: 'rgb(113, 106, 202)',
  red: 'rgb(255, 99, 132)',
  blue: 'rgb(54, 162, 235)',
  green: 'rgb(75, 192, 192)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  grey: 'rgb(201, 203, 207)',
};

interface WeekKeyType {
  year: number;
  week: number;
}

interface MonthKeyType {
  year: number;
  month: number;
}

interface QuarterKeyType {
  year: number;
  quarter: number;
}

interface Props extends LocalizeContextProps {
  aspectType: StatAspect;
  dateRange: DateRange;
  stats: StatsStateByTime;
  companionStats?: StatsStateByTime | null;
  onPartitionChange?: (partition: DatePartitionType) => void;
}

interface PartitionOption {
  id: DatePartitionType;
}

const PARTITIONS: PartitionOption[] = [
  { id: DatePartitionType.Hour },
  { id: DatePartitionType.Day },
  { id: DatePartitionType.Week },
  { id: DatePartitionType.Month },
  { id: DatePartitionType.Quarter },
  { id: DatePartitionType.Year },
];

function valueToKey<T>(s: string, ...props: Array<keyof T>): T {
  const key: T = {} as any;
  const parts = s.split(/[/\-Q:]/g);
  for (let i = 0; i < props.length; i++) {
    key[props[i]] = Number(parts[i]) as any;
  }
  return key;
}

class StatsByTimeChartComponent extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return (
      this.props.aspectType !== nextProps.aspectType ||
      this.props.dateRange !== nextProps.dateRange ||
      this.props.stats !== nextProps.stats ||
      this.props.companionStats !== nextProps.companionStats
    );
  }

  render() {
    const data = this.getData();
    const options = this.getOptions();
    const { stats, dateRange } = this.props;
    const isHourPartition = dateRange.startDate === dateRange.endDate;
    const currentPartition = isHourPartition ? 'hour' : stats.partition;
    const partitions = isHourPartition ? PARTITIONS : PARTITIONS.slice(1);
    console.log(JSON.stringify({ data, options }, null, 2));
    return (
      <div className="stats-by-time">
        <div className="stats-by-time__header">
          <div>
            <div className="stats-by-time__label">
              <Translate id="dashboard.stats_by_time.label" />
            </div>
            <div className="stats-by-time__partitions btn-group btn-group-sm">
              {partitions.map(partition => (
                <button
                  type="button"
                  key={partition.id}
                  disabled={isHourPartition && partition.id !== 'hour'}
                  className={classNames('btn btn-outline-secondary', {
                    active: currentPartition === partition.id,
                  })}
                  onClick={this.onPartitionClick(partition.id)}
                >
                  <Translate id={`time_partition.${partition.id}`} />
                </button>
              ))}
            </div>
          </div>
        </div>
        <div style={{ height: 300 }}>
          <Bar data={data} options={options} redraw />
        </div>
      </div>
    );
  }

  calcChartData<TKey>(
    stats: StatItem[] | null | undefined,
    companionStats: StatItem[] | null | undefined,
    keyFn: (item: StatItem) => TKey,
    incFn: (key: TKey) => TKey,
    labelFn: (key: TKey, years: number[]) => string,
    compareFn: (x: TKey, y: TKey) => number,
    yearFn?: (key: TKey) => number,
  ): [TKey[], string[], any[]] {
    const { dateRange } = this.props;
    const keys: TKey[] = [];
    const labels: string[] = [];
    const dataSets: any[] = [];

    if (!stats) {
      return [keys, labels, dataSets];
    }

    const getMapKey = (key: TKey): string | number => {
      return typeof key === 'string'
        ? key
        : typeof key === 'number'
          ? key
          : Object.prototype.toString.call(key) === '[object Date]'
            ? (key as any).toString()
            : JSON.stringify(key);
    };

    const keySet = new Set<any>();
    const years: number[] = [];

    for (const item of stats) {
      const key = keyFn(item);
      if (key === undefined) {
        continue;
      }
      const year = yearFn ? yearFn(key) : null;
      if (year && !years.includes(year)) years.push(year);
      const lookupKey = getMapKey(key);
      if (!keySet.has(lookupKey)) {
        keySet.add(lookupKey);
        keys.push(key);
      }
    }

    keys.sort(compareFn);

    if (keys.length > 1) {
      const min = keys[0];
      const max = keys[keys.length - 1];
      keys.splice(0);
      let value = min;
      while (compareFn ? compareFn(value, max) <= 0 : value <= max) {
        keys.push(value);
        value = incFn(value);
      }
    }

    for (const key of keys) {
      labels.push(labelFn(key, years));
    }

    // tslint:disable-next-line: variable-name
    const stats_list: StatItem[][] = [];
    const dateRanges: DateRange[] = [];

    const colors = Object.keys(CHART_COLORS).map(x => CHART_COLORS[x]);

    if (stats) {
      stats_list.push(stats);
      dateRanges.push(dateRange);
    }

    if (companionStats) {
      stats_list.push(companionStats);
      const compationDateRange = computeChainRelativeDateRange(dateRange);
      dateRanges.push(compationDateRange);
    }

    if (keys.length) {
      const statIndex = 0;
      for (const result of stats_list) {
        const resultMap = arr2map(result, x => getMapKey(keyFn(x)));

        // data set color
        const color = colors[statIndex % colors.length];

        // data set data
        const data: number[] = [];
        for (const key of keys) {
          const lookupKey = getMapKey(key);
          const item = resultMap[lookupKey];
          data.push(item?.y || 0);
        }

        let label = '';
        const { startDate, endDate } = dateRanges[statIndex];
        if (startDate === endDate) {
          label = moment(startDate).format('YYYY/MM/DD');
        } else {
          label = [
            moment(startDate).format('YYYY/MM/DD'),
            moment(endDate).format('YYYY/MM/DD'),
          ].join('-');
        }

        const dataSet = {
          label,
          data,
          backgroundColor: color,
          borderColor: color,
          fill: false,
          type: 'line',
          yAxisID: AXES_ID_DEFAULT,
        };

        dataSets.push(dataSet);
      }
    }

    if (dataSets.length === 1) {
      dataSets[0].type = 'line';
    } else {
      const idx = dataSets.findIndex(x => x.type === 'bar');
      if (idx >= 0) {
        const dataSet = dataSets[idx];
        dataSets.splice(idx, 1);
        dataSets.push(dataSet);
      }
    }

    return [keys, labels, dataSets];
  }

  getData(): any {
    const { stats, dateRange, companionStats } = this.props;
    const { startDate: start, endDate: end } = dateRange;
    const partition = start === end ? DatePartitionType.Hour : stats.partition;
    const statTtems = stats?.result?.[partition];
    const companionStatItems = companionStats?.result?.[partition];

    // populates the labels and dataSets.
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let keys: any[];
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let labels: string[];
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let dataSets: any[];

    if (partition === DatePartitionType.Hour) {
      [keys, labels, dataSets] = this.calcChartData<number>(
        statTtems,
        companionStatItems,
        item => item.x as number,
        key => key + 1,
        key => `${key}`,
        (x, y) => comparer(x, y),
      );
    } else if (partition === DatePartitionType.Day) {
      [keys, labels, dataSets] = this.calcChartData<Date>(
        statTtems,
        companionStatItems,
        item => new Date(item.x),
        key => moment(key).add(1, 'd').toDate(),
        key => moment(key).format('M/D'),
        (x, y) => comparer(x.getTime(), y.getTime()),
      );
    } else if (partition === DatePartitionType.Week) {
      [keys, labels, dataSets] = this.calcChartData<WeekKeyType>(
        statTtems,
        companionStatItems,
        item => valueToKey<WeekKeyType>(item.x as string, 'year', 'week'),
        key => {
          const date = moment([key.year])
            .startOf('isoWeek')
            .add(key.week, 'week');
          console.log(date.format('YYYY-MM-DD'));
          return { year: date.weekYear(), week: date.week() };
        },
        (key, years) =>
          years.length > 1 ? `${key.year}W${key.week}` : `${key.week}`,
        (x, y) => {
          const res = comparer(x.year, y.year);
          if (0 === res) {
            return comparer(x.week, y.week);
          }
          return res;
        },
        key => key.year,
      );
    } else if (partition === DatePartitionType.Month) {
      [keys, labels, dataSets] = this.calcChartData<MonthKeyType>(
        statTtems,
        companionStatItems,
        item => valueToKey<MonthKeyType>(item.x as string, 'year', 'month'),
        key => {
          const date = moment([key.year, key.month - 1]).add(1, 'month');
          return { year: date.year(), month: date.month() + 1 };
        },
        (key, years) =>
          moment([key.year, key.month - 1]).format(
            years.length > 1 ? 'YY/MM' : 'MM',
          ),
        (x, y) => {
          const result = comparer(x.year, y.year);
          if (0 === result) {
            return comparer(x.month, y.month);
          }
          return result;
        },
        key => key.year,
      );
    } else if (partition === DatePartitionType.Quarter) {
      [keys, labels, dataSets] = this.calcChartData<QuarterKeyType>(
        statTtems,
        companionStatItems,
        item => valueToKey<QuarterKeyType>(item.x as string, 'year', 'quarter'),
        key => {
          const date = moment([key.year]).add(key.quarter - 1, 'quarter');
          date.add(1, 'quarter');
          return { year: date.year(), quarter: date.quarter() };
        },
        (key, years) =>
          moment([key.year])
            .add(key.quarter - 1, 'quarter')
            .format(years.length > 1 ? 'YYYY/[Q]Q' : '[Q]Q'),
        (x, y) => {
          const result = comparer(x.year, y.year);
          if (0 === result) {
            return comparer(x.quarter, y.quarter);
          }
          return result;
        },
        key => key.year,
      );
    } else {
      // year
      [keys, labels, dataSets] = this.calcChartData<number>(
        statTtems,
        companionStatItems,
        item => item.x as number,
        key => key + 1,
        key => `${key}`,
        (x, y) => comparer(x, y),
      );
    }

    return {
      keys,
      labels,
      datasets: dataSets,
    };
  }

  getOptions(): any {
    const yAxes = [];
    const yAxesConfig = {
      display: true,
      gridLines: { display: true },
      scaleLabel: { display: false },
      ticks: {
        beginAtZero: false,
      },
    };

    yAxes.push(
      extend(true, {}, yAxesConfig, {
        id: AXES_ID_DEFAULT,
        position: 'left',
      }),
    );

    const options = {
      title: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        position: 'nearest',
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: { display: false },
            lineWidth: 1,
            barPercentage: 0.3,
            color: 'rgb(0, 0, 0)',
            scaleLabel: { display: false },
          },
        ],
        yAxes,
      },
      elements: {
        point: {
          radius: 1,
          borderWidth: 0,
        },
        line: {
          borderWidth: 1,
        },
        bar: {
          borderWidth: 0,
        },
      },
      layout: {
        padding: {
          left: 10,
          right: 16,
          top: 8,
          bottom: 10,
        },
      },
    };
    return options;
  }

  onPartitionClick = (partition: DatePartitionType) => {
    return () => {
      if (partition === DatePartitionType.Hour) return;
      if (this.props.stats.partition !== partition) {
        this.props.onPartitionChange && this.props.onPartitionChange(partition);
      }
    };
  };
}

export const StatsByTimeChart = withLocalize<Props>(StatsByTimeChartComponent);
