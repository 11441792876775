import { useQuotationTplPanelHeight } from 'app/inspection/quotation-template-config/hooks/useQuotationTplPanelHeight';
import { ConfirmRemoveSubjectModal } from 'app/inspection/quotation-template-config/subject/ConfirmRemoveModal';
import { SubjectEditor } from 'app/inspection/quotation-template-config/subject/SubjectEditor';
import { memo } from 'react';
import { Detail } from './Detail';
import { Sidebar } from './Sidebar';
import { ConfirmRemoveCategoryModal } from './category/ConfirmRemoveModal';
import { ConfirmRemoveGroupModal } from './group/ConfirmRemoveModal';

export const Content = memo(() => {
  const { containerRef } = useQuotationTplPanelHeight();

  return (
    <div className="quotation-tpl__content" ref={containerRef}>
      <Sidebar />
      <Detail />
      <ConfirmRemoveCategoryModal />
      <ConfirmRemoveGroupModal />
      <ConfirmRemoveSubjectModal />
      <SubjectEditor />
    </div>
  );
});
