import { AppContext } from 'app/AppContext';
import { hideAppLoading, showAppLoading, showAppModal } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import {
  authorizationActions,
  weixinMenuActions,
} from 'app/integration/duck/actions';
import { AuthorizationState, WeixinMenus } from 'app/integration/duck/states';
import { DispatchFn } from 'lib/duck/interfaces';
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Callout,
  Dropdown,
  Nav,
  Page,
  Portlet,
  Spinner,
} from 'lib/metronic/components';
import {
  AclObjectList,
  MpConditionalMenuMatchRule,
  MpMenuItem,
  MpMenuItemType,
  WeixinMsgType,
} from 'model';
import { Component, MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { ThunkDispatch } from 'redux-thunk';
import { getString } from 'shared/components';
import { loadAsyncState } from 'utils';
import { getItemIndexFromPath, getMenuItemKeyFromIndexPath } from './helpers';
import { CustomMenuContext } from './helpers/context';
import { validateMenuEditor } from './helpers/validate';
import { MenuItemDetail } from './menu/MenuItemDetail';
import { MenuList } from './menu/MenuList';
import { PersonalizedMenuConfig } from './menu/PersonalizedMenuConfig';
import { Preview } from './menu/Preview';

import './menu.scss';

const EmptyErrors = {} as any;

interface Props {
  menus: WeixinMenus;
  authorization: AuthorizationState;
  dispatch: DispatchFn<AppState>;
}

function mapStateToProps(state: AppState): Partial<Props> {
  return {
    menus: state.integration.menus,
    authorization: state.integration.authorization,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

class CustomMenuComponent extends Component<Props> {
  private readonly breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="integration.breadcrumb.it" /> },
    { text: <Translate id="integration.breadcrumb.menu" /> },
  ];

  componentDidMount() {
    this.props.dispatch((dispatch, getState) => {
      const state = getState();
      loadAsyncState(
        state.integration.authorization,
        () => dispatch(authorizationActions.fetch()),
        true,
      );
      if (
        state.integration.authorization.result &&
        !state.integration.menus.editors.length &&
        !state.integration.menus.isLoading
      ) {
        dispatch(weixinMenuActions.loadMenus());
      }
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.authorization.result && !prevProps.authorization.result) {
      this.props.dispatch(weixinMenuActions.loadMenus());
    }
    if (this.props.menus.isSaving && !prevProps.menus.isSaving) {
      this.props.dispatch(
        showAppLoading({
          message: getString('integration.menu.saving_menu'),
        }),
      );
    }
    if (!this.props.menus.isSaving && prevProps.menus.isSaving) {
      this.props.dispatch(hideAppLoading());
    }
    if (this.props.menus.error && !prevProps.menus.error) {
      this.props.dispatch(
        showAppModal(
          getString('integration.menu.modal.save_error.title'),
          getString('integration.menu.modal.save_error.msg', {
            error: this.props.menus.error.message,
          }),
        ),
      );
    }
  }

  renderTitle() {
    const { menus } = this.props;
    return (
      <span>
        <span style={{ marginRight: 16 }}>
          <Translate id="integration.menu.title" />
        </span>
        {menus.dirty && (
          <Badge
            pill
            color="info"
            size="extra-small"
            style={{ lineHeight: '18px', fontSize: '0.8rem' }}
            className="mp-custom-menu-page__note"
          >
            <ReactMarkdown>
              {getString('integration.menu.edit_note')}
            </ReactMarkdown>
          </Badge>
        )}
        {menus.dirty === false && (
          <Badge
            pill
            color="success"
            size="extra-small"
            style={{ lineHeight: '18px', fontSize: '0.8rem' }}
            className="mp-custom-menu-page__note"
          >
            <i className="fa fa-check-circle" style={{ marginRight: 5 }} />
            <ReactMarkdown>
              {getString('integration.menu.save_menu_success')}
            </ReactMarkdown>
          </Badge>
        )}
      </span>
    );
  }

  render() {
    const { menus } = this.props;
    return (
      <Page
        title={getString('integration.authorization.title')}
        fullAccessRight={AclObjectList.WeixinOpenIntegrationFullAccess}
        readonlyAccessRight={AclObjectList.WeixinOpenIntegrationReadonlyAccess}
        className="mp-custom-menu-page"
      >
        <Page.Header>
          <Page.Header.Main>
            <Breadcrumb items={this.breadcrumbs} />
          </Page.Header.Main>
          <Page.Header.Toolbar>
            {menus && menus.editors.length > 0 && (
              <Button
                color="brand"
                label
                pill
                onClick={this.onAddMenu}
                style={{ marginRight: 8 }}
                disabled={menus.isSaving}
              >
                <i className="fa fa-plus" />
                <Translate id="integration.menu.button.add_conditional_menu" />
              </Button>
            )}
            {menus && menus.editors.length > 0 && (
              <Button
                color="success"
                label
                pill
                onClick={this.onSave}
                spinner={menus.isSaving}
                spinnerColor="success"
                disabled={menus.isSaving}
              >
                {!menus.isSaving && <i className="fa fa-cloud-upload-alt" />}
                <Translate id="integration.menu.button.save_menu" />
              </Button>
            )}
          </Page.Header.Toolbar>
        </Page.Header>
        <Page.Content>
          <Portlet>
            <Portlet.Header
              icon={
                require('!@svgr/webpack!lib/metronic/assets/icons/svg/text/menu.svg')
                  .default
              }
              title={this.renderTitle()}
              size="large"
              iconColor="brand"
            >
              {this.renderRemoveButton()}
              <Dropdown
                iconOnly
                inline
                clean
                size="small"
                buttonContents={<i className="flaticon-more-1" />}
                showToggleButton={false}
                dropdownProps={{ placement: 'bottom-end' }}
              >
                <Nav>
                  <Nav.Item
                    icon="flaticon-refresh"
                    text={getString('integration.menu.button.reload')}
                    onClick={this.onRefresh}
                  />
                </Nav>
              </Dropdown>
            </Portlet.Header>
            <Portlet.Body className="weixin-authorization">
              {this.renderPageBody()}
            </Portlet.Body>
          </Portlet>
          {this.renderConfirmRemoveMenuItemModal()}
        </Page.Content>
      </Page>
    );
  }

  hasActiveMenuItem() {
    const { menus } = this.props;
    const { activeEditorId, editors } = menus;
    if (activeEditorId) {
      const editor = editors.find(x => x.id === activeEditorId)!;
      if (editor.activeMenuItemIndexPath) {
        return true;
      }
    }
    return false;
  }

  renderRemoveButton() {
    const { menus } = this.props;
    const { activeEditorId, editors } = menus;
    if (activeEditorId) {
      const editor = editors.find(x => x.id === activeEditorId)!;
      if (editor.activeMenuItemIndexPath) {
        return (
          <Button
            color="danger"
            label
            style={{ marginRight: 8 }}
            pill
            size="small"
            onClick={this.onRemoveMenuItemClick}
          >
            <Translate id="integration.menu.button.remove_menu_item" />
          </Button>
        );
      }
    }
    return null;
  }

  renderConfirmRemoveMenuItemModal() {
    const { menus } = this.props;
    const { activeEditorId, editors, menuItemBeingRemoved } = menus;
    let show = false;
    let button: MpMenuItem | null = null;
    if (activeEditorId && menuItemBeingRemoved) {
      const { parentIndex, index, editorId } = menuItemBeingRemoved;
      if (editorId === activeEditorId) {
        show = true;
        const editor = editors.find(x => x.id === activeEditorId)!;
        button = parentIndex
          ? editor.menu.button[parentIndex].subButton![index]
          : editor.menu.button[index];
      }
    }
    console.log(button);
    return (
      <Modal
        isOpen={show}
        onClosed={this.onCancelRemoveItem}
        toggle={this.onCancelRemoveItem}
      >
        <ModalHeader toggle={this.onCancelRemoveItem}>
          <Translate id="integration.menu.modal.remove_item.title" />
        </ModalHeader>
        <ModalBody>
          <ReactMarkdown>
            {getString('integration.menu.modal.remove_item.msg')}
          </ReactMarkdown>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'flex-end' }}>
          <Button color="secondary" wide onClick={this.onCancelRemoveItem}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="danger" wide onClick={this.onConfirmRemoveItem}>
            <Translate id="yes_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderPageBody() {
    const { menus } = this.props;
    const { isLoading, activeEditorId, editors } = menus;
    const {
      isLoading: isLoadingAuthorization,
      result,
      error,
    } = this.props.authorization;

    if (isLoadingAuthorization) {
      return (
        <div className="weixin-authorization__loading">
          <Spinner color="brand" />
          <Translate id="integration.authorization.loading" />
        </div>
      );
    }

    if (null === result) {
      if (error) {
        return (
          <Callout color="danger" diagonalBg>
            <Callout.Content>
              <Translate id="integration.authorization.fetch_error" />
            </Callout.Content>
            <Callout.Action>
              <Button
                color="info"
                bold
                uppercased
                wide
                size="large"
                onClick={this.onLoadAuthorization}
              >
                <Translate id="integration.authorization.button.retry_fetch" />
              </Button>
            </Callout.Action>
          </Callout>
        );
      }

      return (
        <div className="weixin-authorization__unauthorized-msg">
          <AppContext.Consumer>
            {({ identity }) => {
              if (
                identity.hasAccessRights(
                  AclObjectList.WeixinOpenIntegrationFullAccess,
                )
              ) {
                return (
                  <Callout
                    color="danger"
                    diagonalBg
                    title={getString(
                      'integration.authorization.unauthorized_title',
                    )}
                  >
                    <Callout.Content>
                      <Translate id="integration.authorization.unauthorized_message" />
                    </Callout.Content>
                    <Callout.Action>
                      <Button
                        color="success"
                        bold
                        uppercased
                        wide
                        tall="taller"
                        size="large"
                        href={'/settings/weixin-open/authorization'}
                        link
                      >
                        <Translate id="integration.authorization.button.authorize" />
                      </Button>
                    </Callout.Action>
                  </Callout>
                );
              }
              if (
                identity.hasAccessRights(
                  AclObjectList.WeixinOpenIntegrationReadonlyAccess,
                )
              ) {
                return (
                  <Callout
                    color="danger"
                    diagonalBg
                    title={getString(
                      'integration.authorization.unauthorized_title',
                    )}
                  >
                    <Callout.Content>
                      <Translate id="integration.authorization.unauthorized_message_readonly" />
                    </Callout.Content>
                    <Callout.Action>
                      <Button
                        color="success"
                        bold
                        uppercased
                        fontSize="small"
                        disabled
                      >
                        <Translate id="integration.authorization.button.authorize" />
                      </Button>
                    </Callout.Action>
                  </Callout>
                );
              }
              return null;
            }}
          </AppContext.Consumer>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="mp-custom-menu-editor__loading">
          <Translate id="integration.menu.loading" />
        </div>
      );
    }

    if (!editors.length) {
      return (
        <div className="mp-custom-menu-editor__no-menus">
          <img src="/public/img/pic_menu_preview49d02c.png" />
          <div>
            <p>
              <Translate id="integration.menu.empty" />
            </p>
            <Button
              color="brand"
              size="large"
              onClick={this.onAddMenu}
              wide
              bold
              label
              fontSize="large"
              className="d-flex"
            >
              <i className="fa fa-plus" />
              <Translate id="integration.menu.button.add_menu" />
            </Button>
          </div>
        </div>
      );
    }

    const activeEditor = editors.find(x => x.id === activeEditorId)!;
    const indexPath = activeEditor.activeMenuItemIndexPath;
    const item = indexPath
      ? indexPath.length === 1
        ? activeEditor.menu.button[indexPath[0]]
        : activeEditor.menu.button[indexPath[0]].subButton![indexPath[1]]
      : null;
    const itemKey = indexPath
      ? getMenuItemKeyFromIndexPath(activeEditorId!, indexPath)
      : null;
    const itemErrors =
      (itemKey && activeEditor.menuItemErrors[itemKey]) || EmptyErrors;

    return (
      <CustomMenuContext.Provider
        value={{ authorization: this.props.authorization }}
      >
        <div className="mp-custom-menu-editor__wrap">
          {activeEditorId && (
            <MenuList
              editors={editors}
              activeEditorId={activeEditorId}
              onMenuChange={this.onMenuChange}
              onAddMenu={this.onAddMenu}
            />
          )}
          <div className="mp-custom-menu-editor container-fluid">
            <div className="row" style={{ width: '100%' }}>
              <div className="mp-custom-menu-editor__left">
                <Preview
                  editor={activeEditor}
                  authorizedInfo={result}
                  menuBeingRemoved={menus.menuBeingRemoved}
                  onAddMenuItem={this.onAddMenuItem}
                  onMenuItemMoved={this.onMenuItemMoved}
                  onActiveMenuItemChanged={this.onActiveMenuItemChanged}
                />
              </div>
              <div className="mp-custom-menu-editor__right">
                {indexPath && (
                  <MenuItemDetail
                    editorId={activeEditor.id}
                    parentIndex={
                      indexPath.length > 1 ? indexPath[0] : undefined
                    }
                    index={indexPath.length > 1 ? indexPath[1] : indexPath[0]}
                    item={item!}
                    keyData={menus.keyData}
                    authorizedInfo={result}
                    errors={itemErrors}
                    onRemoveMenuItem={this.onRemoveMenuItem}
                    onMenuItemTypeChange={this.onMenuItemTypeChange}
                    onMenuItemChange={this.onMenuItemChange}
                    onClickMsgTypeChange={this.onClickMsgTypeChange}
                    onLoadClickMenuItemData={this.onLoadClickMenuItemData}
                    onMenuItemError={this.onMenuItemError}
                  />
                )}
                {!activeEditor.isDefaultMenu && (
                  <PersonalizedMenuConfig
                    editorId={activeEditorId!}
                    rule={activeEditor.menu.matchrule!}
                    onChange={this.onConditionalMenuMatchRuleChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </CustomMenuContext.Provider>
    );
  }

  onLoadAuthorization = () => {
    this.props.dispatch(authorizationActions.fetch());
  };

  onAddMenu = () => {
    this.props.dispatch(weixinMenuActions.addMenu());
  };

  onMenuChange = (id: number) => {
    this.props.dispatch(weixinMenuActions.activeMenuChanged(id));
  };

  onRefresh = () => {
    this.props.dispatch(weixinMenuActions.loadMenus());
  };

  onMenuItemTypeChange = (
    editorId: number,
    path: number[],
    type: MpMenuItemType,
  ) => {
    this.props.dispatch(
      weixinMenuActions.menuItemTypeChanged(editorId, path, type),
    );
  };

  onMenuItemChange = (
    editorId: number,
    path: number[],
    changes: any,
    data: any,
  ) => {
    this.props.dispatch(
      weixinMenuActions.menuItemChanged(editorId, path, changes, data),
    );
  };

  onClickMsgTypeChange = (
    editorId: number,
    path: number[],
    msgType: WeixinMsgType,
  ) => {
    this.props.dispatch(
      weixinMenuActions.clickMenuItemMsgTypeChanged(editorId, path, msgType),
    );
  };

  onAddMenuItem = (editorId: number, name: string, parentIndex?: number) => {
    this.props.dispatch(
      weixinMenuActions.addMenuItem(editorId, name, parentIndex),
    );
  };

  onMenuItemMoved = (
    editorId: number,
    parentIndex: number | undefined,
    fromIndex: number,
    toIndex: number,
  ) => {
    this.props.dispatch(
      weixinMenuActions.menuItemMoved(
        editorId,
        parentIndex,
        fromIndex,
        toIndex,
      ),
    );
  };

  onRemoveMenuItemClick = (e: MouseEvent) => {
    e.preventDefault();
    const id = this.props.menus.activeEditorId!;
    const editor = this.props.menus.editors.find(x => x.id === id)!;
    const path = editor.activeMenuItemIndexPath!;
    const { parentIndex, index } = getItemIndexFromPath(path);
    this.onRemoveMenuItem(id, index, parentIndex);
  };

  onRemoveMenuItem = (
    editorId: number,
    index: number,
    parentIndex?: number,
  ) => {
    this.props.dispatch(
      weixinMenuActions.removeMenuItem(editorId, index, parentIndex),
    );
  };

  onCancelRemoveItem = () => {
    this.props.dispatch(weixinMenuActions.removeMenuItemCancelled());
  };

  onConfirmRemoveItem = () => {
    this.props.dispatch(weixinMenuActions.removeMenuItemCommitted());
  };

  onActiveMenuItemChanged = (editorId: number, path: number[]) => {
    this.props.dispatch(
      weixinMenuActions.activeMenuItemChanged(editorId, path),
    );
  };

  onLoadClickMenuItemData = (editorId: number, path: number[]) => {
    this.props.dispatch(weixinMenuActions.loadMenuItemData(editorId, path));
  };

  onMenuItemError = (
    editorId: number,
    path: number[],
    name: string,
    error: string | null,
  ) => {
    this.props.dispatch(
      weixinMenuActions.onMenuItemError(editorId, path, name, error),
    );
  };

  onConditionalMenuMatchRuleChange = (
    editorId: number,
    changes: Partial<MpConditionalMenuMatchRule>,
  ) => {
    this.props.dispatch(
      weixinMenuActions.conditionalMenuMatchRuleChanged(editorId, changes),
    );
  };

  onSave = () => {
    let i = 0;
    for (const editor of this.props.menus.editors) {
      const res = validateMenuEditor(editor, i, this.props.menus.keyData);
      if (res.message) {
        this.props.dispatch(
          showAppModal(
            getString('integration.menu.modal.validation.title'),
            res.message,
          ),
        );

        if (this.props.menus.activeEditorId !== editor.id) {
          this.props.dispatch(weixinMenuActions.activeMenuChanged(editor.id));
        }

        if (res.path) {
          this.props.dispatch(
            weixinMenuActions.activeMenuItemChanged(editor.id, res.path),
          );
        }

        return;
      }
      if (!editor.isDefaultMenu) i++;
    }
    this.props.dispatch(weixinMenuActions.saveMenu());
  };
}

export const CustomMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomMenuComponent);
