import { LinkButton } from 'app/inspection/quotation-template-config/LinkButton';
import classNames from 'classnames';
import { Checkbox } from 'lib/metronic/components';
import { QuotationTemplateItemStaged } from 'model';
import { ChangeEvent, memo, useCallback } from 'react';
import { Translate } from 'react-localize-redux';
import { SortableHandle } from 'react-sortable-hoc';
import {
  useHasFullAccess,
  WithFullAccess,
} from 'shared/components/WithFullAccess';
import { MaterialListView } from './MaterialListView';

import './ItemView.scss';

export const QuotationTemplateItemView = memo(
  ({
    item,
    onAddMaterial,
    onEdit,
    onRemove,
    onCheckChange,
  }: {
    item: QuotationTemplateItemStaged;
    onAddMaterial: (item: QuotationTemplateItemStaged) => void;
    onEdit: (item: QuotationTemplateItemStaged) => void;
    onRemove: (item: QuotationTemplateItemStaged) => void;
    onCheckChange: (
      item: QuotationTemplateItemStaged,
      checked: boolean,
    ) => void;
  }) => {
    const editable = useHasFullAccess();

    const onAddMaterialClick = useCallback(() => {
      onAddMaterial(item);
    }, [item, onAddMaterial]);

    const onEditClick = useCallback(() => {
      onEdit(item);
    }, [item, onEdit]);

    const onRemoveClick = useCallback(() => {
      onRemove(item);
    }, [item, onRemove]);

    const handleCheckChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onCheckChange(item, e.target.checked);
      },
      [item, onCheckChange],
    );

    return (
      <div
        className={classNames('quotation-tpl-item', {
          'quotation-tpl-item--editable': editable,
        })}
      >
        <div className="quotation-tpl-item__header noselect">
          <div className="quotation-tpl-item__header-left">
            {editable && (
              <Checkbox
                label=""
                color="brand"
                checked={item.included ?? false}
                onChange={handleCheckChange}
                className="quotation-tpl-item__check"
              />
            )}
            <DragHandle item={item} />
          </div>
          {
            <div className="quotation-tpl-item__actions">
              <dl className="quotation-tpl-item__man-hour-cost">
                <dt>
                  <Translate id="quotation_tpl.item.label.man_hour_cost" />:{' '}
                </dt>
                <dd>
                  <span
                    className="quotation-tpl-item__man-hour-cost-value"
                    style={{ marginLeft: '4px' }}
                  >
                    {item.discountManHourCost != null ? (
                      <>
                        <del>{item.manHourCost?.toFixed(2) ?? '--'}</del>{' '}
                        {item.discountManHourCost.toFixed(2)}
                      </>
                    ) : item.manHourCost ? (
                      item.manHourCost.toFixed(2)
                    ) : (
                      '--'
                    )}
                  </span>
                </dd>
              </dl>
              <WithFullAccess>
                <LinkButton onClick={onEditClick}>
                  <i className={`la la-edit`} />
                </LinkButton>
                <LinkButton onClick={onRemoveClick}>
                  <i className={`la la-trash`} />
                </LinkButton>
              </WithFullAccess>
            </div>
          }
        </div>
        <div className="quotation-tpl-item__body">
          <div className="quotation-tpl-item__material-list-hd">
            <h5>
              <Translate id="quotation_tpl.item.label.materials" />(
              {item.materials?.length ?? 0})
            </h5>
            {editable && (
              <LinkButton onClick={onAddMaterialClick}>
                <i className={`la la-plus`} />
              </LinkButton>
            )}
          </div>
          {!item.materials?.length ? (
            <div className="quotation-tpl-item__no-materials">
              <Translate id="quotation_tpl.item.editor.no_materials" />
            </div>
          ) : (
            <MaterialListView item={item} editable={editable} />
          )}
        </div>
      </div>
    );
  },
);

const DragHandle = SortableHandle(
  ({ item }: { item: QuotationTemplateItemStaged }) => (
    <h4 className="quotation-tpl-item__title quotation-tpl-item__sort-handle">
      <span className="quotation-tpl-item__name">{item.name}</span>
      {item.code ? (
        <span className="quotation-tpl-item__code">{item.code}</span>
      ) : null}
    </h4>
  ),
);
