import { quotationTplActions } from 'app/inspection/duck/actions';
import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import {
  QuotationItemEditor,
  QuotationItemEditorRef,
} from 'app/inspection/quotation-template-config/items/editor/ItemEditor';
import {
  findQuotationItemByRef,
  findQuotationSubjectByRef,
} from 'app/inspection/quotation-template-config/util';
import { Button, TabNav } from 'lib/metronic/components';
import { AsideRight } from 'lib/metronic/layout';
import { QuotationSubjectRef, QuotationTemplateItemStaged } from 'model';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { Fill } from 'react-slot-fill';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap/lib';
import { AsyncConfirmModal } from 'shared/components/ConfirmModal/async';
import invariant from 'tiny-invariant';
import './editor.scss';

const kDefaultItem: QuotationTemplateItemStaged = {
  id: '',
  name: '',
  subjectRef: {} as any,
};

export const QuotationItemEditorModal = memo(
  ({
    presentationStyle = 'aside',
  }: {
    presentationStyle?: 'modal' | 'aside';
  }) => {
    const { state } = useQuotationTemplateConfigContext();
    const dispatch = useDispatch();
    const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);

    const ref = useRef<QuotationItemEditorRef>(null);

    const itemBeingEdited = useMemo<
      | {
          item: QuotationTemplateItemStaged;
          mode: 'add' | 'edit';
        }
      | undefined
    >(() => {
      if (state.itemRefBeingEdited == null) {
        return undefined;
      }
      const subjectRef: QuotationSubjectRef = {
        categoryId: state.itemRefBeingEdited.categoryId,
        groupId: state.itemRefBeingEdited.groupId,
        subjectId: state.itemRefBeingEdited.subjectId,
      };
      const item = findQuotationItemByRef(
        state.staged,
        state.itemRefBeingEdited,
      );
      return {
        item: item ?? {
          id: state.itemRefBeingEdited.itemId,
          name: '',
          subjectRef,
        },
        mode: item == null ? 'add' : 'edit',
      };
    }, [state.itemRefBeingEdited, state.staged]);

    const onConfirm = useCallback(() => {
      invariant(state.itemRefBeingEdited != null);
      invariant(itemBeingEdited != null);

      if (!ref.current?.validate()) {
        return;
      }

      const subject = findQuotationSubjectByRef(
        state.staged,
        state.itemRefBeingEdited,
      );
      invariant(subject != null);

      const staged = ref.current.getStaged();
      if (
        subject.items.some(
          x =>
            x.name === staged.name &&
            (itemBeingEdited.mode === 'add' || x.id !== staged.id),
        )
      ) {
        ref.current.setError(
          'name',
          <Translate
            id="quotation_tpl.item.error.duplicate_name"
            data={{
              name: staged.name,
            }}
          />,
        );
        return;
      }

      dispatch(quotationTplActions.commitItemBeingEdited(staged));
    }, [dispatch, itemBeingEdited, state.itemRefBeingEdited, state.staged]);

    const onCancel = useCallback(() => {
      if (!ref.current?.isDirty) {
        dispatch(quotationTplActions.cancelItemBeingEdited());
        return;
      }
      setShowConfirmCloseModal(true);
    }, [dispatch]);

    const onClose = useCallback(() => {
      setShowConfirmCloseModal(false);
      setTimeout(() => {
        dispatch(quotationTplActions.cancelItemBeingEdited());
      }, 150);
    }, [dispatch]);

    if (presentationStyle === 'aside') {
      return (
        <>
          <AsideRight
            open={itemBeingEdited != null}
            footerSlot="quotation-tpl-item-editor-actions"
            onClose={onCancel}
          >
            <AsideRight.Nav>
              {itemBeingEdited ? (
                <TabNav line="brand" bolder>
                  <TabNav.Item active>
                    <Translate
                      id={`quotation_tpl.item.modal.editor.title.${itemBeingEdited.mode}`}
                    />
                  </TabNav.Item>
                </TabNav>
              ) : (
                ''
              )}
            </AsideRight.Nav>
            <AsideRight.Content style={{ paddingBottom: 100 }}>
              <QuotationItemEditor
                item={itemBeingEdited?.item ?? kDefaultItem}
                key={itemBeingEdited?.item.id ?? ''}
                ref={ref}
              />
              <Fill name="quotation-tpl-item-editor-actions">
                <Button color="secondary" onClick={onCancel}>
                  <Translate id="cancel_btn_text" />
                </Button>
                <Button
                  color="brand"
                  onClick={onConfirm}
                  style={{ marginTop: '0.5rem' }}
                >
                  <Translate id="ok_btn_text" />
                </Button>
              </Fill>
            </AsideRight.Content>
          </AsideRight>
          <AsyncConfirmModal
            open={showConfirmCloseModal}
            title="quotation_tpl.item.modal.confirm_close.title"
            content="quotation_tpl.item.modal.confirm_close.msg"
            onCancel={() => setShowConfirmCloseModal(false)}
            onConfirm={onClose}
          />
        </>
      );
    }

    return (
      <>
        <Modal isOpen={itemBeingEdited != null}>
          <ModalHeader>
            {itemBeingEdited ? (
              <Translate
                id={`quotation_tpl.item.modal.editor.title.${itemBeingEdited.mode}`}
              />
            ) : (
              ''
            )}
          </ModalHeader>
          <ModalBody>
            <QuotationItemEditor
              item={itemBeingEdited?.item ?? kDefaultItem}
              key={itemBeingEdited?.item.id ?? ''}
              ref={ref}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onCancel}>
              <Translate id="cancel_btn_text" />
            </Button>
            <Button color="primary" onClick={onConfirm}>
              <Translate id="ok_btn_text" />
            </Button>
          </ModalFooter>
        </Modal>
        <AsyncConfirmModal
          open={showConfirmCloseModal}
          title="quotation_tpl.item.modal.confirm_close.title"
          content="quotation_tpl.item.modal.confirm_close.msg"
          onCancel={() => setShowConfirmCloseModal(false)}
          onConfirm={onClose}
        />
      </>
    );
  },
);
