import { AppState } from 'app/duck/states';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { ActionThunk } from 'lib/duck/interfaces';
import { MpMessageTemplate } from 'model';
import { weixinService } from 'services';
import { ActionTypes } from '../types';
import { weixinTemplateConfActions } from './template-confs';

export const weixinTemplateActions = createListAsyncActionCreators<
  AppState,
  MpMessageTemplate,
  any,
  string
>('integration.templates', {
  getItemsBeingDeleted(state) {
    return state.integration.templates.itemsBeingDeleted;
  },
  mapItemKey(template: MpMessageTemplate) {
    return template.templateId;
  },
  async fetchHandler() {
    return await weixinService.listTemplates();
  },
  async delete(template: MpMessageTemplate) {
    await weixinService.deleteTemplate(template.templateId);
  },
});

export function templateBeingViewed(template: MpMessageTemplate) {
  return createStandardAction(ActionTypes.ViewTemplateDetail, template);
}

export function cancelTemplateBeingViewed() {
  return createStandardAction(ActionTypes.ViewTemplateDetailCancelled);
}

export function templateBeingConfigured(template: MpMessageTemplate) {
  return createStandardAction(ActionTypes.ConfigureTemplate, template);
}

export function cancelTemplateBeingConfigured() {
  return createStandardAction(ActionTypes.ConfigureTemplateCancelled);
}

export function confirmCloseTemplateBeingConfigured() {
  return createStandardAction(ActionTypes.ConfirmConfigureTemplateClose);
}

export function commitCloseTemplateBeingConfigured() {
  return createStandardAction(
    ActionTypes.ConfirmConfigureTemplateCloseCommited,
  );
}

export function cancelCloseTemplateBeingConfigured() {
  return createStandardAction(
    ActionTypes.ConfirmConfigureTemplateCloseCancelled,
  );
}

export function applyTemplateSettingsAutomatically() {
  return createStandardAction(ActionTypes.ApplyTemplateSettingsAutomatically);
}

export function cancelApplyTemplateSettingsAutomatically() {
  return createStandardAction(
    ActionTypes.ApplyTemplateSettingsAutomaticallyCancelled,
  );
}

export function commitApplyTemplateSettingsAutomatically(): ActionThunk<AppState> {
  return async dispatch => {
    dispatch(
      createStandardAction(
        ActionTypes.ApplyTemplateSettingsAutomaticallyCommitted,
      ),
    );

    // apply settings
    try {
      const result = await weixinService.applyTemplateSettingsAutomatically();
      dispatch(weixinTemplateConfActions.invalidate(true));
      dispatch(weixinTemplateActions.invalidate(true));
      dispatch(
        createStandardAction(
          ActionTypes.ApplyTemplateSettingsAutomaticallySuccess,
          result,
        ),
      );
    } catch (e) {
      dispatch(
        createStandardAction(
          ActionTypes.ApplyTemplateSettingsAutomaticallyFailed,
          e,
        ),
      );
    }
  };
}

export function addTemplateByLibraryTemplateId() {
  return createStandardAction(ActionTypes.AddTemplateFromLibrary);
}

export function addTemplateLibraryTemplateIdChanged(libraryTemplateId: string) {
  return createStandardAction(
    ActionTypes.AddTemplateFromLibraryTemplateIdChanged,
    libraryTemplateId,
  );
}

export function commitAddTemplateByLibraryTemplateId(): ActionThunk<AppState> {
  return async (dispatch, getState) => {
    const libraryTemplateId =
      getState().integration.templates.libraryTemplateId!.trim();
    dispatch(createStandardAction(ActionTypes.CommitAddTemplateFromLibrary));
    try {
      const templateId = await weixinService.addTemplate(libraryTemplateId);
      dispatch(
        createStandardAction(
          ActionTypes.AddTemplateFromLibrarySuccess,
          templateId,
        ),
      );
      dispatch(weixinTemplateActions.invalidate(true));
    } catch (e) {
      dispatch(
        createStandardAction(ActionTypes.AddTemplateFromLibraryFailed, e),
      );
    }
  };
}

export function cancelAddTemplateByLibraryTemplateId() {
  return createStandardAction(ActionTypes.CancelAddTemplateFromLibrary);
}
