/**
 * @file: ProductAuthorizationRequestEventTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ProductAuthorizationRequestEventType } from 'model';

export const ProductAuthorizationRequestEventTypeOptions: Array<
  Option<ProductAuthorizationRequestEventType>
> = [
  {
    value: ProductAuthorizationRequestEventType.Submitted,
    label: 'product_authorization_request_event_type.submitted',
  },
  {
    value: ProductAuthorizationRequestEventType.Updated,
    label: 'product_authorization_request_event_type.updated',
  },
  {
    value: ProductAuthorizationRequestEventType.Accepted,
    label: 'product_authorization_request_event_type.accepted',
  },
  {
    value: ProductAuthorizationRequestEventType.Rejected,
    label: 'product_authorization_request_event_type.rejected',
  },
  {
    value: ProductAuthorizationRequestEventType.Canceled,
    label: 'product_authorization_request_event_type.canceled',
  },
];

export const ProductAuthorizationRequestEventTypeOptionsWithDefault: Array<
  Option<ProductAuthorizationRequestEventType | null>
> = [
  {
    value: null,
    label: 'product_authorization_request_event_type.__default__',
  },
  {
    value: ProductAuthorizationRequestEventType.Submitted,
    label: 'product_authorization_request_event_type.submitted',
  },
  {
    value: ProductAuthorizationRequestEventType.Updated,
    label: 'product_authorization_request_event_type.updated',
  },
  {
    value: ProductAuthorizationRequestEventType.Accepted,
    label: 'product_authorization_request_event_type.accepted',
  },
  {
    value: ProductAuthorizationRequestEventType.Rejected,
    label: 'product_authorization_request_event_type.rejected',
  },
  {
    value: ProductAuthorizationRequestEventType.Canceled,
    label: 'product_authorization_request_event_type.canceled',
  },
];
