/**
 * @file: useSelectedGroup.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */

import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import { findQuotationSubjectByRef } from 'app/inspection/quotation-template-config/util';
import { useMemo } from 'react';

export const useSelectedSubject = () => {
  const { state } = useQuotationTemplateConfigContext();
  return useMemo(() => {
    if (state.selectedSubjectRef == null) {
      return undefined;
    }
    return findQuotationSubjectByRef(state.staged, state.selectedSubjectRef);
  }, [state.selectedSubjectRef, state.staged]);
};
