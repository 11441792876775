/**
 * @file: ReviewStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, ReviewStatus } from 'model';

export const ReviewStatusOptions: Array<Option<ReviewStatus>> = [
  { value: ReviewStatus.Pending, label: 'review_status.pending' },
  { value: ReviewStatus.Accepted, label: 'review_status.accepted' },
  { value: ReviewStatus.Rejected, label: 'review_status.rejected' }
];

export const ReviewStatusOptionsWithDefault: Array<Option<ReviewStatus | null>> = [
  { value: null, label: 'review_status.__default__' },
  { value: ReviewStatus.Pending, label: 'review_status.pending' },
  { value: ReviewStatus.Accepted, label: 'review_status.accepted' },
  { value: ReviewStatus.Rejected, label: 'review_status.rejected' }
];