import { AppContext } from 'app/AppContext';
import { AppState } from 'app/duck/states';
import { authorizationActions } from 'app/integration/duck/actions';
import {
  AuthorizationState,
  MpMessageTemplates,
  MpTemplateIndustries,
  OrgWeixinTemplateConfs,
  TemplateIndustrySettingsState,
  WeixinTemplateLibraryConfState,
} from 'app/integration/duck/states';
import { DispatchFn } from 'lib/duck/interfaces';
import {
  Alert,
  Block,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Callout,
  Dropdown,
  Nav,
  Page,
  Portlet,
  Spinner,
} from 'lib/metronic/components';
import { AclObjectList } from 'model';
import { Component, MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { Unsubscribe } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getString } from 'shared/components';
import { loadAsyncState, uniq } from 'utils';
import { weixinTemplateConfActions } from '../duck/actions/template-confs';
import { templateIndustryActions } from '../duck/actions/template-industries';
import { weixinTemplateIndustrySettingsActions } from '../duck/actions/template-industry-settings';
import { weixinTemplateLibraryConfActions } from '../duck/actions/template-library-conf';
import {
  addTemplateByLibraryTemplateId,
  applyTemplateSettingsAutomatically,
  cancelApplyTemplateSettingsAutomatically,
  commitApplyTemplateSettingsAutomatically,
  weixinTemplateActions,
} from '../duck/actions/templates';
import {
  formatTemplateIndustryDisplayName,
  getTemplateIndustryByCode,
  subscribeTemplateIndustryList,
} from './helpers';
import { TemplateSettingsSummary } from './Summary';
import { TemplateConfList } from './TemplateConfs';
import { TemplateList } from './TemplateList';

import ReactMarkdown from 'react-markdown';
import '../templates.scss';

const dummy = {
  get dummyvalue() {
    return Date.now();
  },
} as any;

interface Props {
  authorization: AuthorizationState;
  templateLibraryConf: WeixinTemplateLibraryConfState;
  templateIndustries: MpTemplateIndustries;
  templateIndustrySettings: TemplateIndustrySettingsState;
  templateConfs: OrgWeixinTemplateConfs;
  templates: MpMessageTemplates;
  dispatch: DispatchFn<AppState>;
}

function mapStateToProps(state: AppState): Partial<Props> {
  return {
    authorization: state.integration.authorization,
    templateLibraryConf: state.integration.templateLibraryConf,
    templateIndustries: state.integration.templateIndustries,
    templateIndustrySettings: state.integration.templateIndustrySettings,
    templates: state.integration.templates,
    templateConfs: state.integration.templateConfs,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

class TemplateSettingsComponent extends Component<Props> {
  private readonly breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="integration.breadcrumb.it" /> },
    { text: <Translate id="integration.breadcrumb.templates" /> },
  ];

  private unsubscribeTemplateIndustryList: Unsubscribe;

  componentDidMount() {
    this.props.dispatch((dispatch, getState) => {
      const state = getState();

      this.unsubscribeTemplateIndustryList = subscribeTemplateIndustryList();

      loadAsyncState(
        state.integration.authorization,
        () => dispatch(authorizationActions.fetch()),
        true,
      );
      loadAsyncState(state.integration.templateIndustries, () =>
        dispatch(templateIndustryActions.fetch()),
      );
      loadAsyncState(state.integration.templateLibraryConf, () =>
        dispatch(weixinTemplateLibraryConfActions.fetch()),
      );
      loadAsyncState(state.integration.templateIndustrySettings, () =>
        dispatch(weixinTemplateIndustrySettingsActions.fetch()),
      );
      if (
        state.integration.authorization.result &&
        !state.integration.templates.result &&
        !state.integration.templates.isLoading
      ) {
        // weixin template depends upon the authorization status
        // thus only load the templates if the authorization is ready
        dispatch(weixinTemplateActions.fetch());
      }
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.authorization.result && !prevProps.authorization.result) {
      this.props.dispatch(weixinTemplateActions.fetch());
    }
  }

  componentWillUnmount() {
    this.unsubscribeTemplateIndustryList();
  }

  render() {
    return (
      <Page
        title={getString('integration.templates.title')}
        fullAccessRight={AclObjectList.WeixinOpenIntegrationFullAccess}
        readonlyAccessRight={AclObjectList.WeixinOpenIntegrationReadonlyAccess}
        className="mp-template-settings-page"
      >
        <Page.Header>
          <Page.Header.Main>
            <Breadcrumb items={this.breadcrumbs} />
          </Page.Header.Main>
          <Page.Header.Toolbar>
            <Button
              color="brand"
              label
              pill
              onClick={this.onApplyTemplateSettingsAutomatically}
            >
              <i className="fa fa-plus" />
              <Translate id="integration.templates.button.apply_template_settings_automatically" />
            </Button>
          </Page.Header.Toolbar>
        </Page.Header>
        <Page.Content>
          <Portlet>
            <Portlet.Header
              icon={
                require('!@svgr/webpack!lib/metronic/assets/icons/svg/communication/chat1.svg')
                  .default
              }
              title={getString('integration.templates.title')}
              size="large"
              iconColor="brand"
            >
              <Dropdown
                iconOnly
                inline
                clean
                size="small"
                buttonContents={<i className="flaticon-more-1" />}
                showToggleButton={false}
                dropdownProps={{ placement: 'bottom-end' }}
              >
                <Nav>
                  <Nav.Item
                    icon="flaticon-refresh"
                    text={getString('integration.templates.button.refresh')}
                    onClick={this.onRefresh}
                  />
                </Nav>
              </Dropdown>
            </Portlet.Header>
            <Portlet.Body className="weixin-authorization">
              {this.renderPageBody()}
            </Portlet.Body>
          </Portlet>
          {this.renderApplyTemplateSettingsAutomaticallyModal()}
        </Page.Content>
      </Page>
    );
  }

  renderApplyTemplateSettingsAutomaticallyModal() {
    const templateLibraryConf = this.props.templateLibraryConf.result;
    const industryList = templateLibraryConf
      ? uniq(templateLibraryConf.conf, x => x.industryCode)
          .map(x => {
            const industry = getTemplateIndustryByCode(x.industryCode);
            if (!industry) return '';
            return (
              '`' +
              formatTemplateIndustryDisplayName(
                industry.firstClass,
                industry.secondClass,
              ) +
              '`'
            );
          })
          .filter(x => x)
          .join(', ')
      : '';

    let body = '';
    const strprefix =
      'integration.templates.modal.apply_template_settings_auto';
    if (this.props.templates.applyTemplateSettingsAutomaticallyResult) {
      const result =
        this.props.templates.applyTemplateSettingsAutomaticallyResult;
      const suffix = result.failedScenes.length
        ? 'with_failed_scenes'
        : 'no_failed_scenes';
      const getIndustryName = (x: string) => {
        const industry = getTemplateIndustryByCode(x);
        if (!industry) return '`' + x + '`';
        const name = formatTemplateIndustryDisplayName(
          industry.firstClass,
          industry.secondClass,
        );
        return '`' + name + '`';
      };
      const addedIndustryList = result.industryAdded
        .map(getIndustryName)
        .join(', ');
      const updatedIndustryList = Object.keys(result.industryReplaced)
        .map(x => {
          return [
            `\`${getIndustryName(x)}\``,
            `\`${getIndustryName((result.industryReplaced as any)[x])}\``,
          ].join(' -> ');
        })
        .join(', ');
      const industryChangesStrId =
        result.industryAdded.length &&
        Object.keys(result.industryReplaced).length
          ? 'add_update'
          : result.industryAdded.length
            ? 'add_only'
            : Object.keys(result.industryReplaced).length
              ? 'update_only'
              : 'no_change';
      body = getString(`${strprefix}.success_msg.${suffix}`, {
        industryChanges: getString(
          `${strprefix}.industry_changes.${industryChangesStrId}`,
          { addedIndustryList, updatedIndustryList },
        ),
        newTemplateCount: result.newTemplateCount,
        addedTemplateConfCount: result.addedTemplateConfCount,
        deletedTemplateConfCount: result.deletedTemplateConfCount,
        updatedTemplateConfCount: result.updatedTemplateConfCount,
      });
    } else {
      body = getString(`${strprefix}.msg`, {
        industry_list: industryList,
      });
    }

    return (
      <Modal
        isOpen={Boolean(
          this.props.templates.isConfirmApplyingTemplateSettingsAutomatically ||
            this.props.templates.applyTemplateSettingsAutomaticallyResult,
        )}
        onClosed={this.onCancelApplyTemplateSettingsAutomatically}
        toggle={this.onCancelApplyTemplateSettingsAutomatically}
      >
        <Block
          active={this.props.templates.isApplyingTemplateSettingsAutomatically}
        >
          <ModalHeader toggle={this.onCancelApplyTemplateSettingsAutomatically}>
            <Translate id="integration.templates.modal.apply_template_settings_auto.title" />
          </ModalHeader>
          <ModalBody>
            {this.props.templates.applyTemplateSettingsAutomaticallyError && (
              <Alert color="danger">
                {
                  this.props.templates.applyTemplateSettingsAutomaticallyError
                    .message
                }
              </Alert>
            )}
            <ReactMarkdown>{body}</ReactMarkdown>
          </ModalBody>
          <ModalFooter
            style={{
              justifyContent: this.props.templates
                .applyTemplateSettingsAutomaticallyResult
                ? 'center'
                : 'flex-end',
            }}
          >
            {this.props.templates.applyTemplateSettingsAutomaticallyResult ? (
              <Button
                color="secondary"
                wide
                onClick={this.onCancelApplyTemplateSettingsAutomatically}
              >
                <Translate id="ok_btn_text" />
              </Button>
            ) : (
              <>
                <Button
                  color="secondary"
                  wide
                  onClick={this.onCancelApplyTemplateSettingsAutomatically}
                >
                  <Translate id="cancel_btn_text" />
                </Button>
                <Button
                  color="danger"
                  wide
                  onClick={this.onConfirmApplyTemplateSettingsAutomatically}
                >
                  <Translate id="integration.templates.button.apply" />
                </Button>
              </>
            )}
          </ModalFooter>
        </Block>
      </Modal>
    );
  }

  renderPageBody() {
    const {
      isLoading: isLoadingAuthorization,
      result,
      error,
    } = this.props.authorization;

    if (isLoadingAuthorization) {
      return (
        <div className="weixin-authorization__loading">
          <Spinner color="brand" />
          <Translate id="integration.authorization.loading" />
        </div>
      );
    }

    if (null === result) {
      if (error) {
        return (
          <Callout color="danger" diagonalBg>
            <Callout.Content>
              <Translate id="integration.authorization.fetch_error" />
            </Callout.Content>
            <Callout.Action>
              <Button
                color="info"
                bold
                uppercased
                wide
                size="large"
                onClick={this.onLoadAuthorization}
              >
                <Translate id="integration.authorization.button.retry_fetch" />
              </Button>
            </Callout.Action>
          </Callout>
        );
      }

      return (
        <div className="weixin-authorization__unauthorized-msg">
          <AppContext.Consumer>
            {({ identity }) => {
              if (
                identity.hasAccessRights(
                  AclObjectList.WeixinOpenIntegrationFullAccess,
                )
              ) {
                return (
                  <Callout
                    color="danger"
                    diagonalBg
                    title={getString(
                      'integration.authorization.unauthorized_title',
                    )}
                  >
                    <Callout.Content>
                      <Translate id="integration.authorization.unauthorized_message" />
                    </Callout.Content>
                    <Callout.Action>
                      <Button
                        color="success"
                        bold
                        uppercased
                        wide
                        tall="taller"
                        size="large"
                        href={'/settings/weixin-open/authorization'}
                        link
                      >
                        <Translate id="integration.authorization.button.authorize" />
                      </Button>
                    </Callout.Action>
                  </Callout>
                );
              }
              if (
                identity.hasAccessRights(
                  AclObjectList.WeixinOpenIntegrationReadonlyAccess,
                )
              ) {
                return (
                  <Callout
                    color="danger"
                    diagonalBg
                    title={getString(
                      'integration.authorization.unauthorized_title',
                    )}
                  >
                    <Callout.Content>
                      <Translate id="integration.authorization.unauthorized_message_readonly" />
                    </Callout.Content>
                    <Callout.Action>
                      <Button
                        color="success"
                        bold
                        uppercased
                        fontSize="small"
                        disabled
                      >
                        <Translate id="integration.authorization.button.authorize" />
                      </Button>
                    </Callout.Action>
                  </Callout>
                );
              }
              return null;
            }}
          </AppContext.Consumer>
        </div>
      );
    }

    return (
      <div className="mp-template-settings-page__content">
        <TemplateSettingsSummary
          templateIndustrySettings={this.props.templateIndustrySettings}
          templateLibraryConf={this.props.templateLibraryConf}
          templateIndustries={this.props.templateIndustries}
          onApplyTemplateSettingsAutomatically={
            this.onApplyTemplateSettingsAutomatically
          }
        />
        <TemplateConfList templates={this.props.templates} {...dummy} />
        <h3 className="mp-template-settings-page__list-title">
          <span>
            <i
              className="fa fa-envelope-open-text"
              style={{ marginRight: 8 }}
            />
            <Translate id="integration.templates.list.title" />
          </span>
          <span style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>
            <Translate
              id="integration.templates.list.add_template_tip"
              data={{
                button: (
                  <a
                    href="#"
                    onClick={this.onAddTemplate}
                    data-toggle="tooltip"
                    title={getString(
                      'integration.templates.tooltip.add_template',
                    )}
                  >
                    <Translate id="integration.templates.button.add_template" />
                  </a>
                ),
              }}
            />
          </span>
        </h3>
        <TemplateList templateConfs={this.props.templateConfs} {...dummy} />
      </div>
    );
  }

  onLoadAuthorization = () => {
    this.props.dispatch(authorizationActions.fetch());
  };

  onRefresh = () => {
    this.props.dispatch(weixinTemplateActions.invalidate(true));
    this.props.dispatch(weixinTemplateConfActions.invalidate(true));
  };

  onApplyTemplateSettingsAutomatically = () => {
    this.props.dispatch(applyTemplateSettingsAutomatically());
  };

  onCancelApplyTemplateSettingsAutomatically = () => {
    this.props.dispatch(cancelApplyTemplateSettingsAutomatically());
  };

  onConfirmApplyTemplateSettingsAutomatically = () => {
    this.props.dispatch(commitApplyTemplateSettingsAutomatically());
  };

  onAddTemplate = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.dispatch(addTemplateByLibraryTemplateId());
  };
}

export const TemplateSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateSettingsComponent);
