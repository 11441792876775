import { VehicleInspectionSite } from 'model';
import { getString, withEntityEditorSidebarBuilder } from 'shared/components';

export const SiteEditor =
  withEntityEditorSidebarBuilder<VehicleInspectionSite>()
    .withI18nPrefix('inspection_site')
    .withForm(builder =>
      builder
        .uncontrolled()
        .text({
          prop: 'code',
          label: 'inspection_site.editor.label.code',
          placeholder: 'inspection_site.editor.placeholder.code',
          helpText: 'inspection_site.editor.help_text.code',
        })
        .text({
          prop: 'name',
          label: 'inspection_site.editor.label.name',
          placeholder: 'inspection_site.editor.placeholder.name',
        })
        .text({
          prop: 'pyInitial',
          label: 'inspection_site.editor.label.py_initial',
          placeholder: 'inspection_site.editor.placeholder.py_initial',
          onChange: changes => {
            changes.pyInitial = changes.pyInitial?.toLowerCase();
            return undefined;
          },
        })
        .text({
          type: 'number',
          prop: 'influenceFactor',
          label: 'inspection_site.editor.label.influence_factor',
          placeholder: 'inspection_site.editor.placeholder.influence_factor',
          helpText: 'inspection_site.editor.help_text.influence_factor',
        })
        .image({
          prop: 'iconUrl',
          label: 'inspection_site.editor.label.icon',
          filePicker: {
            cover: false,
            realm: 'site/icons',
            accept: {
              'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
            },
          },
        })
        .checkbox({
          prop: 'supportsIdevice',
          label: 'inspection_site.editor.label.supports_idevice',
        })
        .text({
          type: 'number',
          prop: 'ideviceSortOrder',
          label: 'inspection_site.editor.label.idevice_sort_order',
          placeholder: 'inspection_site.editor.placeholder.idevice_sort_order',
          helpText: 'inspection_site.editor.help_text.idevice_sort_order',
        })
        .textArea({
          prop: 'description',
          label: 'inspection_site.editor.label.description',
          placeholder: 'inspection_site.editor.placeholder.description',
        }),
    )
    .withValidator(site => {
      let errCode: string | undefined = undefined;
      const name = site.name?.trim();
      const code = site.code?.trim();
      const pyInitial = site.pyInitial?.trim();
      if (!site.categoryId) {
        errCode = 'category_required';
      } else if (!name) {
        errCode = 'name_required';
      } else if (!code) {
        errCode = 'code_required';
      } else if (!pyInitial) {
        errCode = 'py_initial_required';
      } else if (!site.influenceFactor) {
        errCode = 'influence_factor_required';
      } else if (site.influenceFactor < 1 || site.influenceFactor > 10) {
        errCode = 'invalid_influence_factor';
      }
      if (errCode) {
        const errMsg = getString('inspection_site.editor.error.' + errCode);
        throw new Error(errMsg);
      }
    })
    .getClass();
