import {
  getDateRangePickerLocale,
  getDefaultInputRanges,
  getDefaultStaticRanges,
} from 'app';
import { Button } from 'lib/metronic/components';
import {
  DataExportTask,
  DataExportTaskJobType,
  DataExportTaskJobTypeOptions,
  Organization,
  Store,
} from 'model';
import {
  DataExportTaskRequest,
  DataExportTaskRequestTarget,
} from 'model/DataExportTaskRequest';
import moment from 'moment';
import { memo, useCallback, useEffect, useState } from 'react';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { Translate } from 'react-localize-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap/lib';
import { organizationService, storeService } from 'services';
import { getString, Select, StorePicker } from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';

type Target =
  | {
      type: 'org';
      org?: Organization;
    }
  | {
      type: 'store';
      org?: Organization;
      store?: Store;
    };

export const AddJobModal = memo(
  ({
    orgId,
    fromExisting,
    isOpen,
    onCancel,
    onConfirm,
  }: {
    orgId: number;
    isOpen: boolean;
    fromExisting?: DataExportTask;
    onCancel: () => void;
    onConfirm: (
      jobType: DataExportTaskJobType,
      target: DataExportTaskRequestTarget,
      dateRange: { from: string; to: string },
    ) => void;
  }) => {
    const requestId = fromExisting?.taskId;
    const [jobType, setJobType] = useState<DataExportTaskJobType | undefined>(
      fromExisting?.jobType ?? undefined,
    );
    const [org, setOrg] = useState<Organization>();
    const [target, setTarget] = useState<Target | undefined>();
    const [startDate, setStartDate] = useState<string>(
      moment().format('YYYY-MM-DD'),
    );
    const [endDate, setEndDate] = useState<string>(
      moment().format('YYYY-MM-DD'),
    );

    useEffect(() => {
      organizationService
        .get(orgId)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .then(org => {
          org && setOrg(org);
        })
        .catch(err => {
          alert(err.message);
        });
    }, [orgId]);

    useEffect(() => {
      setJobType(fromExisting?.jobType);

      if (!org) return;

      if (!fromExisting) {
        setTarget({ type: 'org', org });
        return;
      }

      const request: DataExportTaskRequest = JSON.parse(fromExisting.request);
      if (request.target.type === 'store') {
        storeService
          .get(request.target.storeId)
          .then(store => {
            store &&
              setTarget({
                type: 'store',
                org,
                store,
              });
          })
          .catch(err => {
            alert(err.message);
          });
      }
    }, [fromExisting, org]);

    const onDateRangeChange = useCallback((ranges: RangeKeyDict) => {
      const key = Object.keys(ranges)[0];
      if (!key) return;
      const range = (ranges as any)[key];
      setStartDate(moment(range.startDate).format('YYYY-MM-DD'));
      setEndDate(moment(range.endDate).format('YYYY-MM-DD'));
    }, []);

    const disabled =
      jobType == null ||
      target == null ||
      org == null ||
      (target.type === 'store' && (target.org == null || target.store == null));

    const onConfirmPress = usePersistFn(() => {
      if (target == null || jobType == null) return;
      const targetInfo: DataExportTaskRequestTarget =
        target.type === 'org'
          ? {
              type: 'org',
              orgId: target.org!.id,
              orgName: target.org!.name,
            }
          : {
              type: 'store',
              orgId: target.org!.id,
              orgName: target.org!.name,
              storeId: target.store!.id,
              storeName: target.store!.name,
            };
      onConfirm(jobType, targetInfo, { from: startDate, to: endDate });
    });

    return (
      <Modal isOpen={isOpen} modalClassName="add-data-export-job-modal">
        <ModalHeader>
          <Translate id={`data_export.add_job.title`} />
        </ModalHeader>
        <ModalBody>
          <div style={{ width: '90%', marginBottom: '1rem' }}>
            <p style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
              <Translate id="data_export.add_job.label.job_type" />
            </p>
            <Select
              valueProp="value"
              labelProp="label"
              placeholder={getString(
                'data_export.add_job.placeholder.job_type',
              )}
              isClearable
              selectedValue={DataExportTaskJobTypeOptions.find(
                x => x.value === jobType,
              )}
              values={DataExportTaskJobTypeOptions}
              onChange={option => {
                setJobType(option.value);
              }}
              onFormatOptionLabel={option => getString(option.label)}
            />
          </div>

          <p style={{ fontWeight: 'bold' }}>
            <Translate id="data_export.add_job.label.target" />
          </p>
          <StorePicker
            key={requestId}
            storeId={target?.type === 'store' ? target.store?.id : undefined}
            className="add-data-export-job__store-picker"
            placeholder="data_export.placeholder.store_picker"
            onChange={(_storeId, store) => {
              setTarget(
                store
                  ? {
                      type: 'store',
                      org,
                      store,
                    }
                  : {
                      type: 'org',
                      org,
                    },
              );
            }}
          />
          <p style={{ marginTop: '1rem', fontWeight: 'bold' }}>
            <Translate id="data_export.add_job.label.date_range" />
          </p>
          <div>
            <Translate>
              {({ activeLanguage, translate }) => (
                <DateRangePicker
                  locale={getDateRangePickerLocale(activeLanguage.code)}
                  dateDisplayFormat={getString('date_range.date_format')}
                  ranges={[
                    {
                      startDate: moment(startDate).toDate(),
                      endDate: moment(endDate).toDate(),
                    },
                  ]}
                  onChange={onDateRangeChange as any}
                  staticRanges={getDefaultStaticRanges(translate as any)}
                  inputRanges={getDefaultInputRanges(translate as any)}
                />
              )}
            </Translate>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="primary" onClick={onConfirmPress} disabled={disabled}>
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
