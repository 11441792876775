import { showAppLoading } from 'app/duck/actions';
import classNames from 'classnames';
import { Store } from 'model';
import { memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { storeService } from 'services';

export const SettingsSidebar = memo(
  ({
    orgId,
    storeId,
    onActiveStoreChange,
  }: {
    orgId: number;
    storeId?: number;
    onActiveStoreChange?: (storeId: number | undefined) => void;
  }) => {
    const [stores, setStores] = useState<Store[]>();
    const dispatch = useDispatch();

    useEffect(() => {
      storeService
        .list({ orgId }, null, 0, 0, { recursive: false, count: false })
        .then(res => {
          setStores(res);
        })
        .catch(e => {
          dispatch(
            showAppLoading({
              message: e.message,
              status: 'error',
              timeout: 3000,
            }),
          );
        });
    }, [dispatch, orgId]);

    const onMenuItemClick = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const value = e.currentTarget.getAttribute('data-store-id');
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const storeId = value ? Number(value) : undefined;
        onActiveStoreChange?.(storeId);
      },
      [onActiveStoreChange],
    );

    return (
      <div className="settings-sidebar">
        <h3>
          <i className="fa 	fa-angle-right" />
          <a
            href="#"
            className={classNames('settings-sidebar__menu-item', {
              'settings-sidebar__menu-item--active': storeId == null,
            })}
            onClick={onMenuItemClick}
          >
            <Translate id="store.config.target.org" />
          </a>
        </h3>
        <p style={{ marginBottom: '3rem' }}>
          <Translate id="store.config.help_text.org_config" />
        </p>
        <h3>
          <i className="fa 	fa-angle-right" />
          <Translate id="store.config.target.store" />
        </h3>
        <p>
          <Translate id="store.config.help_text.store_config" />
        </p>
        {stores == null ? <i className="fa fa-spinner fa-pulse" /> : null}
        <ul>
          {stores?.map(store => (
            <li key={store.id}>
              <a
                href="#"
                data-store-id={store.id}
                className={classNames('settings-sidebar__menu-item', {
                  'settings-sidebar__menu-item--active': storeId === store.id,
                })}
                onClick={onMenuItemClick}
              >
                <i className="la la-cog" />
                {store.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  },
);
