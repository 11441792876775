/**
 * @file: VehicleInspectionTaskCheckSiteItemMediaService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionTaskCheckSiteItemMediaListFilter, VehicleInspectionTaskCheckSiteItemMedia, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class VehicleInspectionTaskCheckSiteItemMediaServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskCheckSiteItemMediaListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionTaskCheckSiteItemMedia> : VehicleInspectionTaskCheckSiteItemMedia[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-check-site-item-medias', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionTaskCheckSiteItemMedia | null> {
    return await this.api.get()
      .url('/vehicle-inspection-task-check-site-item-medias/:id', { id })
      .future();
  }

  async create(vehicleInspectionTaskCheckSiteItemMedia: Partial<VehicleInspectionTaskCheckSiteItemMedia>): Promise<VehicleInspectionTaskCheckSiteItemMedia> {
    return await this.api.post()
      .url('/vehicle-inspection-task-check-site-item-medias')
      .data(vehicleInspectionTaskCheckSiteItemMedia)
      .future();
  }

  async update(vehicleInspectionTaskCheckSiteItemMedia: Partial<VehicleInspectionTaskCheckSiteItemMedia>): Promise<VehicleInspectionTaskCheckSiteItemMedia> {
    return await this.api.put()
      .url('/vehicle-inspection-task-check-site-item-medias/:id', { id: vehicleInspectionTaskCheckSiteItemMedia.id })
      .data(vehicleInspectionTaskCheckSiteItemMedia)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/vehicle-inspection-task-check-site-item-medias/:id', { id })
      .future();
  }
}