import { defineActionTypes } from 'lib';

export const ActionTypes = defineActionTypes('app/products', {
  CancelProductAuthorizationRequest: 'cancel-product-authorization-request',
  CancelProductAuthorizationRequestCanceled:
    'cancel-product-authorization-request.canceled',
  CancelProductAuthorizationRequestRequested:
    'cancel-product-authorization-request.requested',
  CancelProductAuthorizationRequestSuccess:
    'cancel-product-authorization-request.success',
  CancelProductAuthorizationRequestError:
    'cancel-product-authorization-request.error',
});
