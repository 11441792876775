import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { OrgWeixinTemplateConf, OrgWeixinTemplateConfListFilter } from 'model';
import { orgWeixinTemplateConfService } from 'services';

export const weixinTemplateConfActions = createListAsyncActionCreators<
  AppState,
  OrgWeixinTemplateConf,
  OrgWeixinTemplateConfListFilter,
  number
>('integration.templates.confs', {
  shouldFetchOnInvalidate: true,

  getItemBeingCreated(state) {
    return state.integration.templateConfs.itemBeingCreated;
  },
  getItemBeingUpdated(state) {
    return state.integration.templateConfs.itemBeingUpdated;
  },
  getItemsBeingDeleted(state) {
    return state.integration.templateConfs.itemsBeingDeleted;
  },

  mapItemKey(conf) {
    return conf.id;
  },

  async fetchHandler() {
    return await orgWeixinTemplateConfService.list();
  },

  async create(conf: Partial<OrgWeixinTemplateConf>) {
    return await orgWeixinTemplateConfService.create({
      ...conf,
      primaryIndustry: conf.primaryIndustry ?? '',
      deputyIndustry: conf.deputyIndustry ?? '',
    });
  },

  async update(conf: OrgWeixinTemplateConf) {
    return await orgWeixinTemplateConfService.update({
      ...conf,
      primaryIndustry: conf.primaryIndustry ?? '',
      deputyIndustry: conf.deputyIndustry ?? '',
    });
  },

  async delete(conf: OrgWeixinTemplateConf) {
    await orgWeixinTemplateConfService.delete(conf.id);
  },
});
