/**
 * @file: InspectionJobProductAuthorTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionJobProductAuthorType, Option } from 'model';

export const InspectionJobProductAuthorTypeOptions: Array<
  Option<InspectionJobProductAuthorType>
> = [
  {
    value: InspectionJobProductAuthorType.ServiceAgent,
    label: 'inspection_job_product_author_type.sa',
  },
  {
    value: InspectionJobProductAuthorType.Technicians,
    label: 'inspection_job_product_author_type.tech',
  },
];

export const InspectionJobProductAuthorTypeOptionsWithDefault: Array<
  Option<InspectionJobProductAuthorType | null>
> = [
  { value: null, label: 'inspection_job_product_author_type.__default__' },
  {
    value: InspectionJobProductAuthorType.ServiceAgent,
    label: 'inspection_job_product_author_type.sa',
  },
  {
    value: InspectionJobProductAuthorType.Technicians,
    label: 'inspection_job_product_author_type.tech',
  },
];
