/**
 * @file: DeliveryCheckTemplateSceneTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { DeliveryCheckTemplateSceneType, Option } from 'model';

export const DeliveryCheckTemplateSceneTypeOptions: Array<
  Option<DeliveryCheckTemplateSceneType>
> = [
  {
    value: DeliveryCheckTemplateSceneType.CompletionCheck,
    label: '@string/delivery_check_template_scene_type.completion_check',
  },
  {
    value: DeliveryCheckTemplateSceneType.SamplingCheck,
    label: '@string/delivery_check_template_scene_type.sampling_check',
  },
];

export const DeliveryCheckTemplateSceneTypeOptionsWithDefault: Array<
  Option<DeliveryCheckTemplateSceneType | null>
> = [
  { value: null, label: 'delivery_check_template_scene_type.__default__' },
  {
    value: DeliveryCheckTemplateSceneType.CompletionCheck,
    label: 'delivery_check_template_scene_type.completion_check',
  },
  {
    value: DeliveryCheckTemplateSceneType.SamplingCheck,
    label: 'delivery_check_template_scene_type.sampling_check',
  },
];
