/**
 * @file: types.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { FC, ReactNode } from 'react';

export type AppStatus = 'loading' | 'info' | 'success' | 'warning' | 'error';

export type ItemSource = 'system' | 'agent' | 'org' | 'store';

export const ItemSourceList: ItemSource[] = ['system', 'agent', 'org', 'store'];

export type ItemSourceFilter = 'all' | ItemSource;

export const ItemSourceFilterList: ItemSourceFilter[] = [
  'all',
  ...ItemSourceList,
];

export interface AppLoadingOptions {
  message?: string | ReactNode;
  status?: AppStatus;
  timeout?: number;
}

export interface SvgrComponent extends FC<React.SVGAttributes<SVGElement>> {}
