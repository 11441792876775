import { useAppContext } from 'app/AppContext';
import { Menu } from 'lib/metronic/components';
import { Layout, LayoutProps } from 'lib/metronic/layout';
import { memo, useMemo } from 'react';
import { OrgInfoView, getString } from 'shared/components';

// @ts-ignore
import ShopIcon from '!@svgr/webpack!lib/metronic/assets/icons/svg/map/marker1.svg';
// @ts-ignore
import AllShopsIcon from '!@svgr/webpack!lib/metronic/assets/icons/svg/map/compass.svg';

interface MenuItemContext {
  type: 'store';
}

interface StoreMenuItemContext extends MenuItemContext {
  storeId: number | null;
}

export const WrappedLayout = memo((props: Omit<LayoutProps, 'topMenu'>) => {
  const topMenu = useTopMenu();
  return <Layout {...props} topMenu={topMenu} />;
});

const useTopMenu = () => {
  const { globalVisibleStores: stores, activeStoreId } = useAppContext();

  return useMemo(() => {
    const menu: Menu = { sections: [{ items: [] }] };
    const section = menu.sections[0];
    const prependOrgName = (s: string) => {
      return (
        <>
          <strong className="top-store-select__org-name">
            <OrgInfoView />
          </strong>
          <span className="top-store-select__sep" style={{ margin: '0 3px ' }}>
            →
          </span>
          <span className="top-store-select__store-name">{s}</span>
        </>
      );
    };
    if (stores.length === 1) {
      section.items.push({
        id: `global-visible-store-${stores[0].id}`,
        className: 'top-store-select',
        text: prependOrgName(stores[0].name),
        active: true,
        positionRelative: true,
        icon: AllShopsIcon,
        forceDropdownClass: true,
      });
    } else {
      const activeStore = activeStoreId
        ? stores.find(x => x.id === activeStoreId)
        : null;
      section.items.push({
        id: 'global-visible-stores',
        className: 'top-store-select',
        text: prependOrgName(
          activeStore
            ? activeStore.name
            : `${getString('@string/header_visible_stores_menu_text')} (${
                stores.length
              })`,
        ),
        items: [],
        active: true,
        icon: AllShopsIcon,
        positionRelative: true,
        noBullet: true,
        dropdown: true,
        dropdownProps: {
          classic: true,
          placement: 'left',
        },
      });
      const items = section.items[0].items!;
      if (activeStore) {
        items.push({
          id: 'global-visible-stores--all',
          icon: ShopIcon,
          noBullet: true,
          context: { type: 'store', storeId: null } as StoreMenuItemContext,
          text: getString('@string/header_visible_stores_menu_text'),
        });
      }
      for (const store of stores) {
        items.push({
          id: `global-visible-store-${store.id}`,
          text: store.name,
          active: activeStoreId === store.id,
          noBullet: true,
          icon: ShopIcon,
          context: { type: 'store', storeId: store.id } as StoreMenuItemContext,
        });
      }
    }

    return menu;
  }, [stores, activeStoreId]);
};
