import { addEventListenerOnce } from './once';

export function addTransitionEndEventListener(
  el: HTMLElement,
  listener: () => void,
) {
  const transitions: any = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'mozTransitionEnd',
    WebkitTransition: 'webkitTransitionEnd',
    msTransition: 'msTransitionEnd',
  };

  let called = false;

  const oncallback = () => {
    if (called) return;
    called = true;
    listener();
  };

  for (const p in transitions) {
    if (
      Object.prototype.hasOwnProperty.call(transitions, p) &&
      (el.style as any)[p] !== undefined
    ) {
      addEventListenerOnce(el, transitions[p] as any, oncallback);
      break;
    }
  }

  // book keeping by using timer fallback
  const durationStr = window.getComputedStyle(el).transitionDuration;
  const m = /^((?:(?:\d+)?\.)?\d+)(m?s)$/.exec(durationStr);
  let duration = 0;
  if (m) {
    if (m[1].startsWith('.')) {
      duration = Number('0' + m[1]);
    } else {
      duration = Number(m[1]);
    }
    if (m[2] === 's') {
      duration *= 1000;
    }
  }
  setTimeout(oncallback, duration + 1);
}
