/**
 * @file: InspectionTaskEventSubTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Badge, BadgeProps } from 'lib/metronic/components';
import { InspectionTaskEventSubType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { LabelColors } from '../LabelColors';

interface Props extends BadgeProps {
  value: InspectionTaskEventSubType | null | undefined;
}

const Values = {
  [InspectionTaskEventSubType.LicensePlateNoCaptured]:
    'license_plate_no_captured',
  [InspectionTaskEventSubType.VinCaptured]: 'vin_captured',
  [InspectionTaskEventSubType.OrderCreated]: 'order_created',
  [InspectionTaskEventSubType.TaskCreated]: 'task_created',
  [InspectionTaskEventSubType.TaskBasicInfoUpdated]: 'task_basic_info_updated',
  [InspectionTaskEventSubType.InspectionStarted]: 'inspection_started',
  [InspectionTaskEventSubType.ItemInspected]: 'item_inspected',
  [InspectionTaskEventSubType.SiteInspectionStarted]: 'site_inspection_started',
  [InspectionTaskEventSubType.SiteInspectionFinished]:
    'site_inspection_finished',
  [InspectionTaskEventSubType.SiteInspectionCancelled]:
    'site_inspection_cancelled',
  [InspectionTaskEventSubType.CustomIssueAdded]: 'custom_issue_added',
  [InspectionTaskEventSubType.CustomIssueUpdated]: 'custom_issue_updated',
  [InspectionTaskEventSubType.InspectionFinished]: 'inspection_finished',
  [InspectionTaskEventSubType.InspectionReportGenerated]:
    'inspection_report_generated',
  [InspectionTaskEventSubType.InspectionReportUpdated]:
    'inspection_report_updated',
  [InspectionTaskEventSubType.InspectionReportShared]:
    'inspection_report_shared',
  [InspectionTaskEventSubType.QuotationStarted]: 'quotation_started',
  [InspectionTaskEventSubType.QuotationInitiated]: 'quotation_initiated',
  [InspectionTaskEventSubType.QuotationSubmitted]: 'quotation_submitted',
  [InspectionTaskEventSubType.QuotationConfirmed]: 'quotation_confirmed',
  [InspectionTaskEventSubType.QuotationFinished]: 'quotation_finished',
  [InspectionTaskEventSubType.QuotationPrinted]: 'quotation_printed',
  [InspectionTaskEventSubType.QuotationUpdated]: 'quotation_updated',
  [InspectionTaskEventSubType.QuotationReportGenerated]:
    'quotation_report_generated',
  [InspectionTaskEventSubType.QuotationReportUpdated]:
    'quotation_report_updated',
  [InspectionTaskEventSubType.QuotationReportShared]: 'quotation_report_shared',
  [InspectionTaskEventSubType.ConstructionConfirmed]: 'construction_confirmed',
  [InspectionTaskEventSubType.ConstructionUpdated]: 'construction_updated',
  [InspectionTaskEventSubType.ConstructionJobBeginned]:
    'construction_job_beginned',
  [InspectionTaskEventSubType.ConstructionJobCommitted]:
    'construction_job_committed',
  [InspectionTaskEventSubType.ConstructionJobCommittedUpdated]:
    'construction_job_committed_updated',
  [InspectionTaskEventSubType.ConstructionFinished]: 'construction_finished',
  [InspectionTaskEventSubType.ConstructionReportGenerated]:
    'construction_report_generated',
  [InspectionTaskEventSubType.ConstructionReportUpdated]:
    'construction_report_updated',
  [InspectionTaskEventSubType.ConstructionReportShared]:
    'construction_report_shared',
  [InspectionTaskEventSubType.DiagnosticJobListUpdated]:
    'diagnostic_job_list_updated',
  [InspectionTaskEventSubType.DiagnosticJobBeginned]: 'diagnostic_job_beginned',
  [InspectionTaskEventSubType.DiagnosticJobCommitted]:
    'diagnostic_job_committed',
  [InspectionTaskEventSubType.DiagnosticJobCommittedUpdated]:
    'diagnostic_job_committed_updated',
  [InspectionTaskEventSubType.DiagnosticFinished]: 'diagnostic_finished',
  [InspectionTaskEventSubType.DiagnosticReportGenerated]:
    'diagnostic_report_generated',
  [InspectionTaskEventSubType.DiagnosticReportUpdated]:
    'diagnostic_report_updated',
  [InspectionTaskEventSubType.DiagnosticReportShared]:
    'diagnostic_report_shared',
  [InspectionTaskEventSubType.PendingIssuesConfirmed]:
    'pending_issues_confirmed',
  [InspectionTaskEventSubType.CheckListItemReviewed]:
    'check_list_item_reviewed',
  [InspectionTaskEventSubType.CheckListReviewed]: 'check_list_reviewed',
  [InspectionTaskEventSubType.DeliveryCheckReportGenreated]:
    'delivery_check_report_genreated',
  [InspectionTaskEventSubType.DeliveryCheckReportUpdated]:
    'delivery_check_report_updated',
  [InspectionTaskEventSubType.DeliveryCheckReportShared]:
    'delivery_check_report_shared',
  [InspectionTaskEventSubType.Done]: 'done',
  [InspectionTaskEventSubType.Closed]: 'closed',
  [InspectionTaskEventSubType.PreInspectionStarted]: 'pre_inspection_started',
  [InspectionTaskEventSubType.PreSiteInspectionStarted]:
    'pre_site_inspection_started',
  [InspectionTaskEventSubType.PreItemInspected]: 'pre_item_inspected',
  [InspectionTaskEventSubType.PreSiteInspected]: 'pre_site_inspected',
  [InspectionTaskEventSubType.PreInspectionFinished]: 'pre_inspection_finished',
  [InspectionTaskEventSubType.PreInspectionReportGenerated]:
    'pre_inspection_report_generated',
  [InspectionTaskEventSubType.PreInspectionReportUpdated]:
    'pre_inspection_report_updated',
  [InspectionTaskEventSubType.PreInspectionReportShared]:
    'pre_inspection_report_shared',
};

export const InspectionTaskEventSubTypeLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  let enumValue = '';
  if (value !== null && value !== undefined) {
    enumValue = Values[value];
  }

  const labelColor =
    value && LabelColors.InspectionTaskEventSubType?.[enumValue]
      ? LabelColors.InspectionTaskEventSubType[enumValue]
      : color;

  return (
    <Badge inline color={labelColor} {...otherProps}>
      <Translate id={`inspection_task_event_sub_type.${enumValue}`} />
    </Badge>
  );
});
