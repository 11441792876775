export enum WeixinMsgType {
  Text = 'text',
  Image = 'image',
  Voice = 'voice',
  Video = 'video',
  Music = 'music',
  News = 'news',
}

export interface WeixinMsg {
  msgType: WeixinMsgType;
}

export interface WeixinTextMsg extends WeixinMsg {
  content: string;
}

export interface WeixinMediaRef {
  mediaId: string;
  updateTime?: number;
  name?: string;
  url?: string;
  tags?: string[];
  coverUrl?: string;
  description?: string;
  vid?: string;
  __isTempMedia?: boolean;
}

export interface WeixinVideoRef extends WeixinMediaRef {
  title?: string;
  description?: string;
}

export interface WeixinImageMsg extends WeixinMsg {
  image: WeixinMediaRef;
}

export interface WeixinVoiceMsg extends WeixinMsg {
  voice: WeixinMediaRef;
}

export interface WeixinVideoMsg extends WeixinMsg {
  video: WeixinVideoRef;
}

export interface WeixinMusicInfo {
  title?: string;
  description?: string;
  musicUrl?: string;
  hqMusicUrl?: string;
  thumbMediaId: string;
}

export interface WeixinMusicMsg extends WeixinMsg {
  music: WeixinMusicInfo;
}

export interface WeixinArticleInfo {
  title: string;
  description: string;
  picUrl: string;
  url: string;
  picMediaId?: string;
}

export interface WeixinNewsMsg extends WeixinMsg {
  mediaId: string;
  articleCount: number;
  items: WeixinArticleInfo[];
}

export interface WeixinTextMsgLinkSegment {
  href: string;
  text: string;
  miniprogram?: {
    appid: string;
    path: string;
  };
}

export type WeixinTextMsgSegment = string | WeixinTextMsgLinkSegment;
