import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { MpMessageTemplate } from 'model';
import { MpMessageTemplates } from '../states';
import { ActionTypes } from '../types';

const initialState: MpMessageTemplates = {
  isLoading: false,
  error: null,
  result: null,
  isConfirmApplyingTemplateSettingsAutomatically: false,
  isApplyingTemplateSettingsAutomatically: false,
  isAddingTemplateFromLibrary: false,
  libraryTemplateId: null,
};

const defaultReducer = createAsyncListActionReducers<
  MpMessageTemplate,
  MpMessageTemplates,
  string
>('integration.templates', initialState, {
  mapItemKey(template: MpMessageTemplate) {
    return template.templateId;
  },
});

export default function templatesReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MpMessageTemplates = initialState,
  action: StandardAction<any>,
): MpMessageTemplates {
  switch (action.type) {
    case ActionTypes.ViewTemplateDetail: {
      return {
        ...state,
        templateBeingViewed: action.payload,
      };
    }
    case ActionTypes.ViewTemplateDetailCancelled: {
      return {
        ...state,
        templateBeingViewed: null,
      };
    }
    case ActionTypes.ConfigureTemplate: {
      return {
        ...state,
        templateBeingConfigured: action.payload,
      };
    }
    case ActionTypes.ConfigureTemplateCancelled: {
      return {
        ...state,
        templateBeingConfigured: null,
        isConfirmCloseTemplateBeingConfigured: false,
      };
    }
    case ActionTypes.ConfirmConfigureTemplateClose: {
      return {
        ...state,
        isConfirmCloseTemplateBeingConfigured: true,
      };
    }
    case ActionTypes.ConfirmConfigureTemplateCloseCommited: {
      return {
        ...state,
        templateBeingConfigured: null,
        isConfirmCloseTemplateBeingConfigured: false,
      };
    }
    case ActionTypes.ConfirmConfigureTemplateCloseCancelled: {
      return {
        ...state,
        isConfirmCloseTemplateBeingConfigured: false,
      };
    }
    case ActionTypes.ApplyTemplateSettingsAutomatically: {
      return {
        ...state,
        isConfirmApplyingTemplateSettingsAutomatically: true,
        applyTemplateSettingsAutomaticallyError: null,
        applyTemplateSettingsAutomaticallyResult: null,
      };
    }
    case ActionTypes.ApplyTemplateSettingsAutomaticallyCancelled: {
      return {
        ...state,
        isConfirmApplyingTemplateSettingsAutomatically: false,
        applyTemplateSettingsAutomaticallyError: null,
        applyTemplateSettingsAutomaticallyResult: null,
      };
    }
    case ActionTypes.ApplyTemplateSettingsAutomaticallyCommitted: {
      return {
        ...state,
        isApplyingTemplateSettingsAutomatically: true,
        applyTemplateSettingsAutomaticallyError: null,
        applyTemplateSettingsAutomaticallyResult: null,
      };
    }
    case ActionTypes.ApplyTemplateSettingsAutomaticallySuccess: {
      return {
        ...state,
        isConfirmApplyingTemplateSettingsAutomatically: false,
        isApplyingTemplateSettingsAutomatically: false,
        applyTemplateSettingsAutomaticallyError: null,
        applyTemplateSettingsAutomaticallyResult: action.payload,
      };
    }
    case ActionTypes.ApplyTemplateSettingsAutomaticallyFailed: {
      return {
        ...state,
        isConfirmApplyingTemplateSettingsAutomatically: true,
        isApplyingTemplateSettingsAutomatically: false,
        applyTemplateSettingsAutomaticallyError: action.payload,
        applyTemplateSettingsAutomaticallyResult: null,
      };
    }
    case ActionTypes.AddTemplateFromLibrary: {
      return {
        ...state,
        libraryTemplateId: '',
      };
    }
    case ActionTypes.AddTemplateFromLibraryTemplateIdChanged: {
      return {
        ...state,
        libraryTemplateId: action.payload,
      };
    }
    case ActionTypes.CommitAddTemplateFromLibrary: {
      return {
        ...state,
        isAddingTemplateFromLibrary: true,
      };
    }
    case ActionTypes.CancelAddTemplateFromLibrary: {
      return {
        ...state,
        libraryTemplateId: null,
      };
    }
    case ActionTypes.AddTemplateFromLibrarySuccess: {
      return {
        ...state,
        libraryTemplateId: null,
        isAddingTemplateFromLibrary: false,
      };
    }
    case ActionTypes.AddTemplateFromLibraryFailed: {
      return {
        ...state,
        isAddingTemplateFromLibrary: false,
        addTemplateFromLibraryError: action.payload,
      };
    }
    default: {
      return defaultReducer(state, action);
    }
  }
}
