/**
 * @file: refs.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */

import {
  makeGroupRef,
  makeItemRef,
  makeMaterialRef,
  makeSubjectRef,
} from 'app/inspection/quotation-template-config/util';
import {
  QuotationCategoryRef,
  QuotationGroupRef,
  QuotationItemRef,
  QuotationMaterialRef,
  QuotationSubjectRef,
  QuotationTemplateGroupStaged,
  QuotationTemplateItemStaged,
  QuotationTemplateMaterialStaged,
  QuotationTemplateSubjectStaged,
} from 'model';
import { useMemo } from 'react';

export const useGroupRef = (
  categoryRef: QuotationCategoryRef,
  groupId: string,
): QuotationGroupRef =>
  useMemo(() => makeGroupRef(categoryRef, groupId), [categoryRef, groupId]);

export const useSubjectRef = (
  groupRef: QuotationGroupRef,
  subjectId: string,
): QuotationSubjectRef => {
  return useMemo(
    () => makeSubjectRef(groupRef, subjectId),
    [groupRef, subjectId],
  );
};

export const useItemRef = (
  subjectRef: QuotationSubjectRef,
  itemId: string,
): QuotationItemRef => {
  return useMemo(() => makeItemRef(subjectRef, itemId), [subjectRef, itemId]);
};

export const useMaterialRef = (
  itemRef: QuotationItemRef,
  materialId: string,
): QuotationMaterialRef => {
  return useMemo(
    () => makeMaterialRef(itemRef, materialId),
    [itemRef, materialId],
  );
};

export const useGroupStagedRef = (
  staged: QuotationTemplateGroupStaged,
): QuotationGroupRef =>
  useMemo(
    () => makeGroupRef({ categoryId: staged.categoryId }, staged.id),
    [staged.categoryId, staged.id],
  );

export const useSubjectStagedRef = (
  staged: QuotationTemplateSubjectStaged,
): QuotationSubjectRef =>
  useMemo(
    () => makeSubjectRef(staged.groupRef, staged.id),
    [staged.groupRef, staged.id],
  );

export const useItemStagedRef = (
  staged: QuotationTemplateItemStaged,
): QuotationItemRef =>
  useMemo(
    () => makeItemRef(staged.subjectRef, staged.id),
    [staged.subjectRef, staged.id],
  );

export const useMaterialStagedRef = (
  staged: QuotationTemplateMaterialStaged,
): QuotationMaterialRef =>
  useMemo(
    () => makeMaterialRef(staged.itemRef, staged.id),
    [staged.itemRef, staged.id],
  );
