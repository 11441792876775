/**
 * @file: InspectionTaskEventSubTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionTaskEventSubType } from 'model';

export const InspectionTaskEventSubTypeOptions: Array<Option<InspectionTaskEventSubType>> = [
  { value: InspectionTaskEventSubType.LicensePlateNoCaptured, label: 'inspection_task_event_sub_type.license_plate_no_captured' },
  { value: InspectionTaskEventSubType.VinCaptured, label: 'inspection_task_event_sub_type.vin_captured' },
  { value: InspectionTaskEventSubType.OrderCreated, label: 'inspection_task_event_sub_type.order_created' },
  { value: InspectionTaskEventSubType.TaskCreated, label: 'inspection_task_event_sub_type.task_created' },
  { value: InspectionTaskEventSubType.TaskBasicInfoUpdated, label: 'inspection_task_event_sub_type.task_basic_info_updated' },
  { value: InspectionTaskEventSubType.InspectionStarted, label: 'inspection_task_event_sub_type.inspection_started' },
  { value: InspectionTaskEventSubType.ItemInspected, label: 'inspection_task_event_sub_type.item_inspected' },
  { value: InspectionTaskEventSubType.SiteInspectionStarted, label: 'inspection_task_event_sub_type.site_inspection_started' },
  { value: InspectionTaskEventSubType.SiteInspectionFinished, label: 'inspection_task_event_sub_type.site_inspection_finished' },
  { value: InspectionTaskEventSubType.SiteInspectionCancelled, label: 'inspection_task_event_sub_type.site_inspection_cancelled' },
  { value: InspectionTaskEventSubType.CustomIssueAdded, label: 'inspection_task_event_sub_type.custom_issue_added' },
  { value: InspectionTaskEventSubType.CustomIssueUpdated, label: 'inspection_task_event_sub_type.custom_issue_updated' },
  { value: InspectionTaskEventSubType.InspectionFinished, label: 'inspection_task_event_sub_type.inspection_finished' },
  { value: InspectionTaskEventSubType.InspectionReportGenerated, label: 'inspection_task_event_sub_type.inspection_report_generated' },
  { value: InspectionTaskEventSubType.InspectionReportUpdated, label: 'inspection_task_event_sub_type.inspection_report_updated' },
  { value: InspectionTaskEventSubType.InspectionReportShared, label: 'inspection_task_event_sub_type.inspection_report_shared' },
  { value: InspectionTaskEventSubType.QuotationStarted, label: 'inspection_task_event_sub_type.quotation_started' },
  { value: InspectionTaskEventSubType.QuotationInitiated, label: 'inspection_task_event_sub_type.quotation_initiated' },
  { value: InspectionTaskEventSubType.QuotationSubmitted, label: 'inspection_task_event_sub_type.quotation_submitted' },
  { value: InspectionTaskEventSubType.QuotationConfirmed, label: 'inspection_task_event_sub_type.quotation_confirmed' },
  { value: InspectionTaskEventSubType.QuotationFinished, label: 'inspection_task_event_sub_type.quotation_finished' },
  { value: InspectionTaskEventSubType.QuotationPrinted, label: 'inspection_task_event_sub_type.quotation_printed' },
  { value: InspectionTaskEventSubType.QuotationUpdated, label: 'inspection_task_event_sub_type.quotation_updated' },
  { value: InspectionTaskEventSubType.QuotationReportGenerated, label: 'inspection_task_event_sub_type.quotation_report_generated' },
  { value: InspectionTaskEventSubType.QuotationReportUpdated, label: 'inspection_task_event_sub_type.quotation_report_updated' },
  { value: InspectionTaskEventSubType.QuotationReportShared, label: 'inspection_task_event_sub_type.quotation_report_shared' },
  { value: InspectionTaskEventSubType.ConstructionConfirmed, label: 'inspection_task_event_sub_type.construction_confirmed' },
  { value: InspectionTaskEventSubType.ConstructionUpdated, label: 'inspection_task_event_sub_type.construction_updated' },
  { value: InspectionTaskEventSubType.ConstructionJobBeginned, label: 'inspection_task_event_sub_type.construction_job_beginned' },
  { value: InspectionTaskEventSubType.ConstructionJobCommitted, label: 'inspection_task_event_sub_type.construction_job_committed' },
  { value: InspectionTaskEventSubType.ConstructionJobCommittedUpdated, label: 'inspection_task_event_sub_type.construction_job_committed_updated' },
  { value: InspectionTaskEventSubType.ConstructionFinished, label: 'inspection_task_event_sub_type.construction_finished' },
  { value: InspectionTaskEventSubType.ConstructionReportGenerated, label: 'inspection_task_event_sub_type.construction_report_generated' },
  { value: InspectionTaskEventSubType.ConstructionReportUpdated, label: 'inspection_task_event_sub_type.construction_report_updated' },
  { value: InspectionTaskEventSubType.ConstructionReportShared, label: 'inspection_task_event_sub_type.construction_report_shared' },
  { value: InspectionTaskEventSubType.DiagnosticJobListUpdated, label: 'inspection_task_event_sub_type.diagnostic_job_list_updated' },
  { value: InspectionTaskEventSubType.DiagnosticJobBeginned, label: 'inspection_task_event_sub_type.diagnostic_job_beginned' },
  { value: InspectionTaskEventSubType.DiagnosticJobCommitted, label: 'inspection_task_event_sub_type.diagnostic_job_committed' },
  { value: InspectionTaskEventSubType.DiagnosticJobCommittedUpdated, label: 'inspection_task_event_sub_type.diagnostic_job_committed_updated' },
  { value: InspectionTaskEventSubType.DiagnosticFinished, label: 'inspection_task_event_sub_type.diagnostic_finished' },
  { value: InspectionTaskEventSubType.DiagnosticReportGenerated, label: 'inspection_task_event_sub_type.diagnostic_report_generated' },
  { value: InspectionTaskEventSubType.DiagnosticReportUpdated, label: 'inspection_task_event_sub_type.diagnostic_report_updated' },
  { value: InspectionTaskEventSubType.DiagnosticReportShared, label: 'inspection_task_event_sub_type.diagnostic_report_shared' },
  { value: InspectionTaskEventSubType.PendingIssuesConfirmed, label: 'inspection_task_event_sub_type.pending_issues_confirmed' },
  { value: InspectionTaskEventSubType.CheckListItemReviewed, label: 'inspection_task_event_sub_type.check_list_item_reviewed' },
  { value: InspectionTaskEventSubType.CheckListReviewed, label: 'inspection_task_event_sub_type.check_list_reviewed' },
  { value: InspectionTaskEventSubType.DeliveryCheckReportGenreated, label: 'inspection_task_event_sub_type.delivery_check_report_genreated' },
  { value: InspectionTaskEventSubType.DeliveryCheckReportUpdated, label: 'inspection_task_event_sub_type.delivery_check_report_updated' },
  { value: InspectionTaskEventSubType.DeliveryCheckReportShared, label: 'inspection_task_event_sub_type.delivery_check_report_shared' },
  { value: InspectionTaskEventSubType.Done, label: 'inspection_task_event_sub_type.done' },
  { value: InspectionTaskEventSubType.Closed, label: 'inspection_task_event_sub_type.closed' },
  { value: InspectionTaskEventSubType.PreInspectionStarted, label: 'inspection_task_event_sub_type.pre_inspection_started' },
  { value: InspectionTaskEventSubType.PreSiteInspectionStarted, label: 'inspection_task_event_sub_type.pre_site_inspection_started' },
  { value: InspectionTaskEventSubType.PreItemInspected, label: 'inspection_task_event_sub_type.pre_item_inspected' },
  { value: InspectionTaskEventSubType.PreSiteInspected, label: 'inspection_task_event_sub_type.pre_site_inspected' },
  { value: InspectionTaskEventSubType.PreInspectionFinished, label: 'inspection_task_event_sub_type.pre_inspection_finished' },
  { value: InspectionTaskEventSubType.PreInspectionReportGenerated, label: 'inspection_task_event_sub_type.pre_inspection_report_generated' },
  { value: InspectionTaskEventSubType.PreInspectionReportUpdated, label: 'inspection_task_event_sub_type.pre_inspection_report_updated' },
  { value: InspectionTaskEventSubType.PreInspectionReportShared, label: 'inspection_task_event_sub_type.pre_inspection_report_shared' }
];

export const InspectionTaskEventSubTypeOptionsWithDefault: Array<Option<InspectionTaskEventSubType | null>> = [
  { value: null, label: 'inspection_task_event_sub_type.__default__' },
  { value: InspectionTaskEventSubType.LicensePlateNoCaptured, label: 'inspection_task_event_sub_type.license_plate_no_captured' },
  { value: InspectionTaskEventSubType.VinCaptured, label: 'inspection_task_event_sub_type.vin_captured' },
  { value: InspectionTaskEventSubType.OrderCreated, label: 'inspection_task_event_sub_type.order_created' },
  { value: InspectionTaskEventSubType.TaskCreated, label: 'inspection_task_event_sub_type.task_created' },
  { value: InspectionTaskEventSubType.TaskBasicInfoUpdated, label: 'inspection_task_event_sub_type.task_basic_info_updated' },
  { value: InspectionTaskEventSubType.InspectionStarted, label: 'inspection_task_event_sub_type.inspection_started' },
  { value: InspectionTaskEventSubType.ItemInspected, label: 'inspection_task_event_sub_type.item_inspected' },
  { value: InspectionTaskEventSubType.SiteInspectionStarted, label: 'inspection_task_event_sub_type.site_inspection_started' },
  { value: InspectionTaskEventSubType.SiteInspectionFinished, label: 'inspection_task_event_sub_type.site_inspection_finished' },
  { value: InspectionTaskEventSubType.SiteInspectionCancelled, label: 'inspection_task_event_sub_type.site_inspection_cancelled' },
  { value: InspectionTaskEventSubType.CustomIssueAdded, label: 'inspection_task_event_sub_type.custom_issue_added' },
  { value: InspectionTaskEventSubType.CustomIssueUpdated, label: 'inspection_task_event_sub_type.custom_issue_updated' },
  { value: InspectionTaskEventSubType.InspectionFinished, label: 'inspection_task_event_sub_type.inspection_finished' },
  { value: InspectionTaskEventSubType.InspectionReportGenerated, label: 'inspection_task_event_sub_type.inspection_report_generated' },
  { value: InspectionTaskEventSubType.InspectionReportUpdated, label: 'inspection_task_event_sub_type.inspection_report_updated' },
  { value: InspectionTaskEventSubType.InspectionReportShared, label: 'inspection_task_event_sub_type.inspection_report_shared' },
  { value: InspectionTaskEventSubType.QuotationStarted, label: 'inspection_task_event_sub_type.quotation_started' },
  { value: InspectionTaskEventSubType.QuotationInitiated, label: 'inspection_task_event_sub_type.quotation_initiated' },
  { value: InspectionTaskEventSubType.QuotationSubmitted, label: 'inspection_task_event_sub_type.quotation_submitted' },
  { value: InspectionTaskEventSubType.QuotationConfirmed, label: 'inspection_task_event_sub_type.quotation_confirmed' },
  { value: InspectionTaskEventSubType.QuotationFinished, label: 'inspection_task_event_sub_type.quotation_finished' },
  { value: InspectionTaskEventSubType.QuotationPrinted, label: 'inspection_task_event_sub_type.quotation_printed' },
  { value: InspectionTaskEventSubType.QuotationUpdated, label: 'inspection_task_event_sub_type.quotation_updated' },
  { value: InspectionTaskEventSubType.QuotationReportGenerated, label: 'inspection_task_event_sub_type.quotation_report_generated' },
  { value: InspectionTaskEventSubType.QuotationReportUpdated, label: 'inspection_task_event_sub_type.quotation_report_updated' },
  { value: InspectionTaskEventSubType.QuotationReportShared, label: 'inspection_task_event_sub_type.quotation_report_shared' },
  { value: InspectionTaskEventSubType.ConstructionConfirmed, label: 'inspection_task_event_sub_type.construction_confirmed' },
  { value: InspectionTaskEventSubType.ConstructionUpdated, label: 'inspection_task_event_sub_type.construction_updated' },
  { value: InspectionTaskEventSubType.ConstructionJobBeginned, label: 'inspection_task_event_sub_type.construction_job_beginned' },
  { value: InspectionTaskEventSubType.ConstructionJobCommitted, label: 'inspection_task_event_sub_type.construction_job_committed' },
  { value: InspectionTaskEventSubType.ConstructionJobCommittedUpdated, label: 'inspection_task_event_sub_type.construction_job_committed_updated' },
  { value: InspectionTaskEventSubType.ConstructionFinished, label: 'inspection_task_event_sub_type.construction_finished' },
  { value: InspectionTaskEventSubType.ConstructionReportGenerated, label: 'inspection_task_event_sub_type.construction_report_generated' },
  { value: InspectionTaskEventSubType.ConstructionReportUpdated, label: 'inspection_task_event_sub_type.construction_report_updated' },
  { value: InspectionTaskEventSubType.ConstructionReportShared, label: 'inspection_task_event_sub_type.construction_report_shared' },
  { value: InspectionTaskEventSubType.DiagnosticJobListUpdated, label: 'inspection_task_event_sub_type.diagnostic_job_list_updated' },
  { value: InspectionTaskEventSubType.DiagnosticJobBeginned, label: 'inspection_task_event_sub_type.diagnostic_job_beginned' },
  { value: InspectionTaskEventSubType.DiagnosticJobCommitted, label: 'inspection_task_event_sub_type.diagnostic_job_committed' },
  { value: InspectionTaskEventSubType.DiagnosticJobCommittedUpdated, label: 'inspection_task_event_sub_type.diagnostic_job_committed_updated' },
  { value: InspectionTaskEventSubType.DiagnosticFinished, label: 'inspection_task_event_sub_type.diagnostic_finished' },
  { value: InspectionTaskEventSubType.DiagnosticReportGenerated, label: 'inspection_task_event_sub_type.diagnostic_report_generated' },
  { value: InspectionTaskEventSubType.DiagnosticReportUpdated, label: 'inspection_task_event_sub_type.diagnostic_report_updated' },
  { value: InspectionTaskEventSubType.DiagnosticReportShared, label: 'inspection_task_event_sub_type.diagnostic_report_shared' },
  { value: InspectionTaskEventSubType.PendingIssuesConfirmed, label: 'inspection_task_event_sub_type.pending_issues_confirmed' },
  { value: InspectionTaskEventSubType.CheckListItemReviewed, label: 'inspection_task_event_sub_type.check_list_item_reviewed' },
  { value: InspectionTaskEventSubType.CheckListReviewed, label: 'inspection_task_event_sub_type.check_list_reviewed' },
  { value: InspectionTaskEventSubType.DeliveryCheckReportGenreated, label: 'inspection_task_event_sub_type.delivery_check_report_genreated' },
  { value: InspectionTaskEventSubType.DeliveryCheckReportUpdated, label: 'inspection_task_event_sub_type.delivery_check_report_updated' },
  { value: InspectionTaskEventSubType.DeliveryCheckReportShared, label: 'inspection_task_event_sub_type.delivery_check_report_shared' },
  { value: InspectionTaskEventSubType.Done, label: 'inspection_task_event_sub_type.done' },
  { value: InspectionTaskEventSubType.Closed, label: 'inspection_task_event_sub_type.closed' },
  { value: InspectionTaskEventSubType.PreInspectionStarted, label: 'inspection_task_event_sub_type.pre_inspection_started' },
  { value: InspectionTaskEventSubType.PreSiteInspectionStarted, label: 'inspection_task_event_sub_type.pre_site_inspection_started' },
  { value: InspectionTaskEventSubType.PreItemInspected, label: 'inspection_task_event_sub_type.pre_item_inspected' },
  { value: InspectionTaskEventSubType.PreSiteInspected, label: 'inspection_task_event_sub_type.pre_site_inspected' },
  { value: InspectionTaskEventSubType.PreInspectionFinished, label: 'inspection_task_event_sub_type.pre_inspection_finished' },
  { value: InspectionTaskEventSubType.PreInspectionReportGenerated, label: 'inspection_task_event_sub_type.pre_inspection_report_generated' },
  { value: InspectionTaskEventSubType.PreInspectionReportUpdated, label: 'inspection_task_event_sub_type.pre_inspection_report_updated' },
  { value: InspectionTaskEventSubType.PreInspectionReportShared, label: 'inspection_task_event_sub_type.pre_inspection_report_shared' }
];