import { DeliveryCheckTemplateConf } from 'model';
import { guid } from 'utils';

export const defaultDeliveryCheckTemplateConf: DeliveryCheckTemplateConf = {
  items: [
    {
      id: guid('n'),
      subject: '机油液位中线偏上？',
      requiresPhoto: true,
      options: [
        {
          id: guid('n'),
          title: '是',
          remark: '机油油位已到达上线',
          isDefaultChecked: true,
        },
        {
          id: guid('n'),
          title: '否',
          remark: '机油液位下线建议处理',
          isDefaultChecked: false,
        },
      ],
    },
    {
      id: guid('n'),
      subject: '发动机舱整洁，所有拆卸部位已安装到位？',
      requiresPhoto: true,
      options: [
        {
          id: guid('n'),
          title: '是',
          remark: '机舱已清洁了，部件已安装到位',
          isDefaultChecked: true,
        },
        {
          id: guid('n'),
          title: '否',
          remark: '',
          isDefaultChecked: false,
        },
      ],
    },
    {
      id: guid('n'),
      subject: '冷却液液位中线偏上？',
      requiresPhoto: true,
      options: [
        {
          id: guid('n'),
          title: '是',
          remark: '冷却液位已达上线',
          isDefaultChecked: true,
        },
        {
          id: guid('n'),
          title: '否',
          remark: '',
          isDefaultChecked: false,
        },
      ],
    },
    {
      id: guid('n'),
      subject: '轮胎已按规定力矩紧固？',
      requiresPhoto: false,
      options: [
        {
          id: guid('n'),
          title: '是',
          remark: '已按规定紧固',
          isDefaultChecked: true,
        },
        {
          id: guid('n'),
          title: '否',
          remark: '未拆卸轮胎',
          isDefaultChecked: false,
        },
      ],
    },
    {
      id: guid('n'),
      subject: '内饰无油污、指纹？',
      requiresPhoto: false,
      options: [
        {
          id: guid('n'),
          title: '是',
          remark: '无油污指纹',
          isDefaultChecked: true,
        },
        {
          id: guid('n'),
          title: '否',
          remark: '',
          isDefaultChecked: false,
        },
      ],
    },
    {
      id: guid('n'),
      subject: '轮胎气压已存储？',
      requiresPhoto: false,
      options: [
        {
          id: guid('n'),
          title: '是',
          remark: '已按标准存储胎压',
          isDefaultChecked: true,
        },
        {
          id: guid('n'),
          title: '否',
          remark: '不带胎压监测功能',
          isDefaultChecked: false,
        },
      ],
    },
    {
      id: guid('n'),
      subject: '保养提示已复位？',
      requiresPhoto: false,
      options: [
        {
          id: guid('n'),
          title: '是',
          remark: '保养灯已复位',
          isDefaultChecked: true,
        },
        {
          id: guid('n'),
          title: '否',
          remark: '该车不带保养提供功能',
          isDefaultChecked: false,
        },
      ],
    },
    {
      id: guid('n'),
      subject: '机油格、放油螺丝干净，不漏油？',
      requiresPhoto: false,
      options: [
        {
          id: guid('n'),
          title: '是',
          remark: '已清洁并检查',
          isDefaultChecked: true,
        },
        {
          id: guid('n'),
          title: '否',
          remark: '',
          isDefaultChecked: false,
        },
      ],
    },
  ],
};
