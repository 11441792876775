/**
 * @file: VehicleInspectionTaskDeliveryCheckService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { VehicleInspectionTaskDeliveryCheckServiceBase } from './base/VehicleInspectionTaskDeliveryCheckService';

export class VehicleInspectionTaskDeliveryCheckService extends VehicleInspectionTaskDeliveryCheckServiceBase {
  // add custom service implementation here
}
