/**
 * @file: OptionValueTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import React from 'react';
import { OptionValueType } from 'model';
import { Translate } from 'react-localize-redux';
import { BadgeProps } from 'lib/metronic/components';

interface Props extends BadgeProps {
  value: OptionValueType | null | undefined;
}

export const OptionValueTypeLabel = React.memo<Props>(props => {
  const { value } = props;

  return (
    <span>
      <Translate id={`option_value_type.${value}`} />
    </span>
    );
});