/**
 * @file: authorized-products.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { IdType } from 'model';
import { productAuthorizationService } from 'services';
import {
  AuthorizedProductSearchParams,
  ProductWithContractId,
} from 'model/app/product';

export const authorizedProductActions = createListAsyncActionCreators<
  AppState,
  ProductWithContractId,
  AuthorizedProductSearchParams,
  IdType
>('authorized_products', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  mapItemKey(item) {
    return item.id;
  },
  async fetchHandler(state: AppState) {
    return await productAuthorizationService.searchProducts(
      {
        ...state.products.authorizedProducts.filter,
        storeId: state.activeStoreId ?? undefined,
      },
      state.products.authorizedProducts.offset ?? 0,
      state.products.authorizedProducts.limit ?? 10,
    );
  },
});
