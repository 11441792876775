import { OfficialAccountGrantedPermissionType } from 'model';

export const RequiredAuthorizedPermissionSet: Set<number> = new Set([
  OfficialAccountGrantedPermissionType.Message,
  OfficialAccountGrantedPermissionType.Menu,
  OfficialAccountGrantedPermissionType.WebPage,
  OfficialAccountGrantedPermissionType.MassMessageAndNotification,
  OfficialAccountGrantedPermissionType.User,
  OfficialAccountGrantedPermissionType.Account,
  OfficialAccountGrantedPermissionType.Media,
  OfficialAccountGrantedPermissionType.MultiServiceSupport,
  OfficialAccountGrantedPermissionType.OpenPlatformAccount,
  OfficialAccountGrantedPermissionType.MiniprogramAccount,
  OfficialAccountGrantedPermissionType.ServiceAccountConversation
]);