/**
 * @file: DeliveryCheckTemplateMatchTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { DeliveryCheckTemplateMatchType, Option } from 'model';

export const DeliveryCheckTemplateMatchTypeOptions: Array<
  Option<DeliveryCheckTemplateMatchType>
> = [
  {
    value: DeliveryCheckTemplateMatchType.None,
    label: 'delivery_check_template_match_type.none',
  },
  {
    value: DeliveryCheckTemplateMatchType.Yes,
    label: 'delivery_check_template_match_type.yes',
  },
  {
    value: DeliveryCheckTemplateMatchType.No,
    label: 'delivery_check_template_match_type.no',
  },
];

export const DeliveryCheckTemplateMatchTypeOptionsWithDefault: Array<
  Option<DeliveryCheckTemplateMatchType | null>
> = [
  { value: null, label: 'delivery_check_template_match_type.__default__' },
  {
    value: DeliveryCheckTemplateMatchType.None,
    label: 'delivery_check_template_match_type.none',
  },
  {
    value: DeliveryCheckTemplateMatchType.Yes,
    label: 'delivery_check_template_match_type.yes',
  },
  {
    value: DeliveryCheckTemplateMatchType.No,
    label: 'delivery_check_template_match_type.no',
  },
];
