import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { VehicleReceptionOrder, VehicleReceptionOrderListFilter } from 'model';
import { vehicleReceptionOrderService } from 'services';
import { dateRangeFromDashboardState } from './helpers';

const actionCreators = createListAsyncActionCreators<AppState, VehicleReceptionOrder>(
  'dashboard.recent_reception_orders',
  {
    shouldFetchOnInvalidate: true,
    shouldInvalidateForFilter: true,

    async fetchHandler(state: AppState) {
      const orgId = state.identity!.orgId;
      const storeId = state.activeStoreId || undefined;

      return await vehicleReceptionOrderService.list(
        Object.assign({}, state.dashboard.recentReceptionOrders.filter, {
          orgId, storeId, createdAt: dateRangeFromDashboardState(state)
        } as Partial<VehicleReceptionOrderListFilter>),
        state.dashboard.recentReceptionOrders.sorts?.[0],
        state.dashboard.recentReceptionOrders.offset,
        state.dashboard.recentReceptionOrders.limit,
        { count: false, recursive: true }
      );
    }
  }
);

export const {
  fetch: fetchDashboardRecentReceptionOrders,
  invalidate: invalidateDashboardRecentReceptionOrders
} = actionCreators;