import { Switch } from 'lib/metronic/components';
import { ChangeEvent, PureComponent } from 'react';
import { FormElementSwitch } from '../types';

interface Props<T> {
  element: FormElementSwitch<T>;
  value: boolean;
  disabled?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormSwitch<T> extends PureComponent<Props<T>> {
  render() {
    const { disabled, value } = this.props;
    return (
      <div>
        <Switch checked={value} disabled={disabled} onChange={this.onChange} />
      </div>
    );
  }

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const checked = e.target.checked;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = checked as any;
    void (element.onChange && element.onChange(changes, extra));
    this.props.onChange(changes);
  };
}
