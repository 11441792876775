import {
  TemplateProperty,
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
} from './types';

export class ServiceStartedTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  getOverriddenProperties(): TemplateProperty[] {
    return ['startedAt', 'estimatedFinishTime'].map(x => ({
      property: x,
      label: `integration.templates.props.service_started.${x}`,
    }));
  }

  getOverriddenExample(destType: TemplateMsgDestType) {
    if (destType === 'customer') {
      return {
        title: '尊敬的车主，欢迎光临成都宝和汽车西门店',
        status: '服务已开始',
        remark: '点击进入查看实时服务进度',
      };
    }
    return {
      title: '您有新的服务订单，请及时跟进服务进度',
      status: '服务已开始',
      remark: '点击进入查看接车单详情',
    };
  }
}
