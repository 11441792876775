/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { api, cacheService } from 'lib';

import { AclService } from './AclService';
import { AuthService } from './AuthService';
import { CommonService } from './CommonService';
import { LbsService } from './LbsService';
import { MediaService } from './MediaService';
import { ProductAuthorizationService } from './ProductAuthorizationService';
import { ProductService } from './ProductService';
import { ReportService } from './ReportService';
import { StatsService } from './StatsService';
import { SystemService } from './SystemService';
import { WeixinService } from './WeixinService';

/* service_import_begin */
import { AreaService } from './AreaService';
import { AutoPartNameService } from './AutoPartNameService';
import { ConstructionTemplateConfigService } from './ConstructionTemplateConfigService';
import { CustomerService } from './CustomerService';
import { DataExportTaskService } from './DataExportTaskService';
import { InspectionToolService } from './InspectionToolService';
import { MaintenanceServiceSubjectService } from './MaintenanceServiceSubjectService';
import { OrganizationService } from './OrganizationService';
import { OrgGroupService } from './OrgGroupService';
import { OrgMemberService } from './OrgMemberService';
import { OrgSubscribedUserService } from './OrgSubscribedUserService';
import { OrgTeamService } from './OrgTeamService';
import { OrgUserAclService } from './OrgUserAclService';
import { OrgUserService } from './OrgUserService';
import { OrgUserVisibleStoreAclService } from './OrgUserVisibleStoreAclService';
import { OrgUserVisibleStoreService } from './OrgUserVisibleStoreService';
import { OrgUserWeixinBindingService } from './OrgUserWeixinBindingService';
import { OrgWeixinTemplateConfService } from './OrgWeixinTemplateConfService';
import { QuotationPartCatalogService } from './QuotationPartCatalogService';
import { QuotationTemplateConfigService } from './QuotationTemplateConfigService';
import { StoreCustomerService } from './StoreCustomerService';
import { StoreMediaService } from './StoreMediaService';
import { StoreService } from './StoreService';
import { UserService } from './UserService';
import { UserWeixinBindingService } from './UserWeixinBindingService';
import { VehicleDeliveryCheckTemplateBarrierService } from './VehicleDeliveryCheckTemplateBarrierService';
import { VehicleDeliveryCheckTemplateService } from './VehicleDeliveryCheckTemplateService';
import { VehicleInspectionCommentService } from './VehicleInspectionCommentService';
import { VehicleInspectionSiteCategoryService } from './VehicleInspectionSiteCategoryService';
import { VehicleInspectionSiteCheckItemMediaService } from './VehicleInspectionSiteCheckItemMediaService';
import { VehicleInspectionSiteCheckItemOptionService } from './VehicleInspectionSiteCheckItemOptionService';
import { VehicleInspectionSiteCheckItemService } from './VehicleInspectionSiteCheckItemService';
import { VehicleInspectionSiteMaintenancePeriodService } from './VehicleInspectionSiteMaintenancePeriodService';
import { VehicleInspectionSiteMaintenancePeriodVarianceService } from './VehicleInspectionSiteMaintenancePeriodVarianceService';
import { VehicleInspectionSiteRelService } from './VehicleInspectionSiteRelService';
import { VehicleInspectionSiteService } from './VehicleInspectionSiteService';
import { VehicleInspectionSubjectService } from './VehicleInspectionSubjectService';
import { VehicleInspectionTaskAssignmentService } from './VehicleInspectionTaskAssignmentService';
import { VehicleInspectionTaskCheckSiteItemDataService } from './VehicleInspectionTaskCheckSiteItemDataService';
import { VehicleInspectionTaskCheckSiteItemMediaService } from './VehicleInspectionTaskCheckSiteItemMediaService';
import { VehicleInspectionTaskCheckSiteItemService } from './VehicleInspectionTaskCheckSiteItemService';
import { VehicleInspectionTaskCheckSiteService } from './VehicleInspectionTaskCheckSiteService';
import { VehicleInspectionTaskCustomIssueService } from './VehicleInspectionTaskCustomIssueService';
import { VehicleInspectionTaskDeliveryCheckMediaService } from './VehicleInspectionTaskDeliveryCheckMediaService';
import { VehicleInspectionTaskDeliveryCheckService } from './VehicleInspectionTaskDeliveryCheckService';
import { VehicleInspectionTaskDiagnosticJobMediaService } from './VehicleInspectionTaskDiagnosticJobMediaService';
import { VehicleInspectionTaskDiagnosticJobService } from './VehicleInspectionTaskDiagnosticJobService';
import { VehicleInspectionTaskDiagnosticJobTroubleCodeService } from './VehicleInspectionTaskDiagnosticJobTroubleCodeService';
import { VehicleInspectionTaskEventService } from './VehicleInspectionTaskEventService';
import { VehicleInspectionTaskJobDetailService } from './VehicleInspectionTaskJobDetailService';
import { VehicleInspectionTaskJobMediaService } from './VehicleInspectionTaskJobMediaService';
import { VehicleInspectionTaskJobService } from './VehicleInspectionTaskJobService';
import { VehicleInspectionTaskService } from './VehicleInspectionTaskService';
import { VehicleInspectionTaskSubjectService } from './VehicleInspectionTaskSubjectService';
import { VehicleInspectionTaskTroubleCodeService } from './VehicleInspectionTaskTroubleCodeService';
import { VehicleInspectionTemplateBarrierService } from './VehicleInspectionTemplateBarrierService';
import { VehicleInspectionTemplateService } from './VehicleInspectionTemplateService';
import { VehicleIssueService } from './VehicleIssueService';
import { VehicleReceptionOrderService } from './VehicleReceptionOrderService';
import { VehicleReceptionOrderTechnicianService } from './VehicleReceptionOrderTechnicianService';
import { VehicleServiceSubjectService } from './VehicleServiceSubjectService';
import { VehicleSystemModuleService } from './VehicleSystemModuleService';
/* service_import_end */

export const commonService = new CommonService(api, cacheService);
export const authService = new AuthService(api);
export const statsService = new StatsService(api);
export const aclService = new AclService(api);
export const weixinService = new WeixinService(api);
export const lbsService = new LbsService(api);
export const mediaService = new MediaService(api);
export const reportService = new ReportService(api);
export const systemService = new SystemService(api);
export const productService = new ProductService(api);
export const productAuthorizationService = new ProductAuthorizationService(api);

/* instance_export_begin */
export const areaService = new AreaService(api);
export const customerService = new CustomerService(api);
export const inspectionToolService = new InspectionToolService(api);
export const orgMemberService = new OrgMemberService(api);
export const orgUserService = new OrgUserService(api);
export const orgUserAclService = new OrgUserAclService(api);
export const orgUserWeixinBindingService = new OrgUserWeixinBindingService(api);
export const organizationService = new OrganizationService(api);
export const storeService = new StoreService(api);
export const storeCustomerService = new StoreCustomerService(api);
export const vehicleInspectionCommentService =
  new VehicleInspectionCommentService(api);
export const vehicleInspectionSiteService = new VehicleInspectionSiteService(
  api,
);
export const vehicleInspectionSiteCategoryService =
  new VehicleInspectionSiteCategoryService(api);
export const vehicleInspectionSiteCheckItemService =
  new VehicleInspectionSiteCheckItemService(api);
export const vehicleInspectionSiteCheckItemMediaService =
  new VehicleInspectionSiteCheckItemMediaService(api);
export const vehicleInspectionSiteCheckItemOptionService =
  new VehicleInspectionSiteCheckItemOptionService(api);
export const vehicleInspectionSiteMaintenancePeriodService =
  new VehicleInspectionSiteMaintenancePeriodService(api);
export const vehicleInspectionSiteMaintenancePeriodVarianceService =
  new VehicleInspectionSiteMaintenancePeriodVarianceService(api);
export const vehicleInspectionTaskService = new VehicleInspectionTaskService(
  api,
);
export const vehicleInspectionTaskAssignmentService =
  new VehicleInspectionTaskAssignmentService(api);
export const vehicleInspectionTaskCheckSiteService =
  new VehicleInspectionTaskCheckSiteService(api);
export const vehicleInspectionTaskCheckSiteItemService =
  new VehicleInspectionTaskCheckSiteItemService(api);
export const vehicleInspectionTaskCheckSiteItemDataService =
  new VehicleInspectionTaskCheckSiteItemDataService(api);
export const vehicleInspectionTaskCheckSiteItemMediaService =
  new VehicleInspectionTaskCheckSiteItemMediaService(api);
export const vehicleInspectionTemplateService =
  new VehicleInspectionTemplateService(api);
export const vehicleReceptionOrderService = new VehicleReceptionOrderService(
  api,
);
export const vehicleReceptionOrderTechnicianService =
  new VehicleReceptionOrderTechnicianService(api);
export const orgUserVisibleStoreService = new OrgUserVisibleStoreService(api);
export const orgUserVisibleStoreAclService = new OrgUserVisibleStoreAclService(
  api,
);
export const userService = new UserService(api);
export const userWeixinBindingService = new UserWeixinBindingService(api);
export const orgWeixinTemplateConfService = new OrgWeixinTemplateConfService(
  api,
);
export const vehicleIssueService = new VehicleIssueService(api);
export const vehicleInspectionSiteRelService =
  new VehicleInspectionSiteRelService(api);
export const orgGroupService = new OrgGroupService(api);
export const orgTeamService = new OrgTeamService(api);
export const vehicleInspectionTaskEventService =
  new VehicleInspectionTaskEventService(api);
export const vehicleServiceSubjectService = new VehicleServiceSubjectService(
  api,
);
export const vehicleInspectionSubjectService =
  new VehicleInspectionSubjectService(api);
export const vehicleInspectionTaskJobService =
  new VehicleInspectionTaskJobService(api);
export const vehicleInspectionTaskTroubleCodeService =
  new VehicleInspectionTaskTroubleCodeService(api);
export const vehicleInspectionTaskDiagnosticJobService =
  new VehicleInspectionTaskDiagnosticJobService(api);
export const vehicleInspectionTaskDiagnosticJobMediaService =
  new VehicleInspectionTaskDiagnosticJobMediaService(api);
export const vehicleInspectionTaskDiagnosticJobTroubleCodeService =
  new VehicleInspectionTaskDiagnosticJobTroubleCodeService(api);
export const vehicleInspectionTaskJobDetailService =
  new VehicleInspectionTaskJobDetailService(api);
export const vehicleInspectionTaskJobMediaService =
  new VehicleInspectionTaskJobMediaService(api);
export const vehicleInspectionTaskSubjectService =
  new VehicleInspectionTaskSubjectService(api);
export const vehicleInspectionTaskCustomIssueService =
  new VehicleInspectionTaskCustomIssueService(api);
export const storeMediaService = new StoreMediaService(api);
export const quotationPartCatalogService = new QuotationPartCatalogService(api);
export const autoPartNameService = new AutoPartNameService(api);
export const vehicleInspectionTaskDeliveryCheckService =
  new VehicleInspectionTaskDeliveryCheckService(api);
export const vehicleInspectionTaskDeliveryCheckMediaService =
  new VehicleInspectionTaskDeliveryCheckMediaService(api);
export const vehicleDeliveryCheckTemplateService =
  new VehicleDeliveryCheckTemplateService(api);
export const orgSubscribedUserService = new OrgSubscribedUserService(api);
export const maintenanceServiceSubjectService =
  new MaintenanceServiceSubjectService(api);
export const vehicleSystemModuleService = new VehicleSystemModuleService(api);
export const vehicleInspectionTemplateBarrierService =
  new VehicleInspectionTemplateBarrierService(api);
export const vehicleDeliveryCheckTemplateBarrierService =
  new VehicleDeliveryCheckTemplateBarrierService(api);
export const dataExportTaskService = new DataExportTaskService(api);
export const constructionTemplateConfigService =
  new ConstructionTemplateConfigService(api);
export const quotationTemplateConfigService =
  new QuotationTemplateConfigService(api);
/* instance_import_end */
