import { APIService } from 'lib/restful-client/api.service';
import { OrderTag } from 'model';

export class SystemService {
  constructor(private readonly api: APIService) {}

  async getPredefinedOrderTags(): Promise<OrderTag[]> {
    return await this.api.get().url('/system/predefined-order-tags').future();
  }
}
