import { ConstructionTemplateConfigTarget } from 'app/inspection/duck/states';
import {
  ConstructionTemplateConfig,
  ConstructionTemplateConfigInfoWithDetail,
} from 'model';
import { createContext, useContext } from 'react';

type ContextProps = {
  configInfo: ConstructionTemplateConfigInfoWithDetail | undefined;
  target: ConstructionTemplateConfigTarget;
  templateConfig: ConstructionTemplateConfig;
  templateConfigBeingEdited: ConstructionTemplateConfig | undefined;
  selectedSidebarItem:
    | { type: 'category'; categoryId: string }
    | { type: 'template'; templateId: string }
    | undefined;
};

export const Context = createContext<ContextProps>(null as any);

export const useConstructionTemplateConfigContext = () => useContext(Context);
