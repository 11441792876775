import { OrderFieldVisibility } from 'model';

type Option = { label: string; value: OrderFieldVisibility };

export const kOrderFieldVisibilityOptions: Option[] = [
  {
    label: 'store.config.order_flow.values.field_display_in_summary.default',
    value: 'default',
  },
  {
    label: 'store.config.order_flow.values.field_display_in_summary.always',
    value: 'always',
  },
  {
    label: 'store.config.order_flow.values.field_display_in_summary.auto',
    value: 'auto',
  },
  {
    label: 'store.config.order_flow.values.field_display_in_summary.none',
    value: 'none',
  },
];
