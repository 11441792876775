/**
 * @file: VehicleInspectionTaskJobMediaService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionTaskJobMediaListFilter, VehicleInspectionTaskJobMedia, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class VehicleInspectionTaskJobMediaServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskJobMediaListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionTaskJobMedia> : VehicleInspectionTaskJobMedia[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-job-medias', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionTaskJobMedia | null> {
    return await this.api.get()
      .url('/vehicle-inspection-task-job-medias/:id', { id })
      .future();
  }

  async create(vehicleInspectionTaskJobMedia: Partial<VehicleInspectionTaskJobMedia>): Promise<VehicleInspectionTaskJobMedia> {
    return await this.api.post()
      .url('/vehicle-inspection-task-job-medias')
      .data(vehicleInspectionTaskJobMedia)
      .future();
  }

  async update(vehicleInspectionTaskJobMedia: Partial<VehicleInspectionTaskJobMedia>): Promise<VehicleInspectionTaskJobMedia> {
    return await this.api.put()
      .url('/vehicle-inspection-task-job-medias/:id', { id: vehicleInspectionTaskJobMedia.id })
      .data(vehicleInspectionTaskJobMedia)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/vehicle-inspection-task-job-medias/:id', { id })
      .future();
  }
}