/**
 * @file: OrganizationService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Organization, Store, StoreConfiguration } from 'model';
import { OrganizationServiceBase } from './base/OrganizationService';

export class OrganizationService extends OrganizationServiceBase {
  async getCurrent(): Promise<Organization> {
    return await this.api.get().url('/organizations/current').future();
  }

  async getStores(): Promise<Store[]> {
    return await this.api.get().url('/organizations/stores').future();
  }

  async getInviteLink(storeId?: number): Promise<string> {
    return await this.api
      .get()
      .url('/organizations/invite-link', { storeId })
      .future();
  }

  async sendStoreStatsRemindNotificationToUser(userId: number): Promise<any> {
    return await this.api
      .post()
      .url('/organizations/store-stats-remind')
      .data({ userId })
      .future();
  }

  async getConfiguration(): Promise<StoreConfiguration> {
    return await this.api.get().url('/organizations/config').future();
  }

  async saveConfiguration(config: StoreConfiguration): Promise<void> {
    return void (await this.api
      .put()
      .url('/organizations/config')
      .data(config)
      .future());
  }

  async deleteConfiguration(): Promise<void> {
    await this.api.delete().url('/organizations/config').future();
  }
}
