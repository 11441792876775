import { combineReducers } from 'redux';

import authorization from './authorization';
import authorizationUrl from './authorization-url';
import menus from './menus';
import materials from './materials';
import tags from './tags';
import templateIndustries from './template-industries';
import templates from './templates';
import templateConfs from './template-confs';
import templateLibraryConf from './template-library-conf';
import templateIndustrySettings from './template-industry-settings';
import miniProgramLinkState from './mini-program-link-state';

export default combineReducers({
  authorization,
  miniProgramLinkState,
  authorizationUrl,
  menus,
  materials,
  tags,
  templateIndustries,
  templates,
  templateConfs,
  templateLibraryConf,
  templateIndustrySettings
});
