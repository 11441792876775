import { Badge, BadgeProps } from 'lib/metronic/components';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { ItemSource } from '../types';
import { LabelColors } from './LabelColors';

interface Props extends BadgeProps {
  value: ItemSource | null | undefined;
}

export const ItemSourceLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  const labelColor =
    value && LabelColors.ItemSource?.[value]
      ? LabelColors.ItemSource[value]
      : color;

  return (
    <Badge inline color={labelColor} {...otherProps}>
      <Translate id={`item_source.${value}`} />
    </Badge>
  );
});
