import { memo } from 'react';
import { AsyncConfirmModal } from 'shared/components/ConfirmModal/async';

export const CreateFromConstructionModal = memo(
  ({
    open,
    onConfirm,
    onCancel,
  }: {
    open?: boolean;
    onConfirm?: () => Promise<void>;
    onCancel?: () => void;
  }) => {
    return (
      <AsyncConfirmModal
        open={open}
        title="quotation_tpl.modal.create_from_construction.title"
        content="quotation_tpl.modal.create_from_construction.msg"
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    );
  },
);
