/**
 * @file: DeliveryCheckTemplateSceneType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum DeliveryCheckTemplateSceneType {
  CompletionCheck = 'completion_check',
  SamplingCheck = 'sampling_check',
}

export const DeliveryCheckTemplateSceneTypeValueSet = new Set([
  DeliveryCheckTemplateSceneType.CompletionCheck,
  DeliveryCheckTemplateSceneType.SamplingCheck,
]);
