/**
 * @file: InspectionTaskSubjectTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionTaskSubjectType } from 'model';

export const InspectionTaskSubjectTypeOptions: Array<Option<InspectionTaskSubjectType>> = [
  { value: InspectionTaskSubjectType.ServiceSubject, label: 'inspection_task_subject_type.service_subject' },
  { value: InspectionTaskSubjectType.InspectionSubject, label: 'inspection_task_subject_type.inspection_subject' }
];

export const InspectionTaskSubjectTypeOptionsWithDefault: Array<Option<InspectionTaskSubjectType | null>> = [
  { value: null, label: 'inspection_task_subject_type.__default__' },
  { value: InspectionTaskSubjectType.ServiceSubject, label: 'inspection_task_subject_type.service_subject' },
  { value: InspectionTaskSubjectType.InspectionSubject, label: 'inspection_task_subject_type.inspection_subject' }
];