import React, { Component } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Column, DataTable } from 'lib/metronic/components';
import { getScopedTransFunction } from 'app';
import { RecentInspectionComments } from './duck/states';
import { VehicleInspectionComment } from 'model';
import { formatTime } from 'utils';
import { InspectionOrderTypeLabel, StoreName } from 'shared/components';

interface Props extends LocalizeContextProps {
  comments: RecentInspectionComments;
}

export class RecentVehicleInspectionCommentListComponent extends Component<Props> {
  private columns: Array<Column<VehicleInspectionComment>>;

  constructor(props: Props, context: any) {
    super(props, context);
    this.init();
  }

  render() {
    const { comments } = this.props;
    return (
      <DataTable
        columns={this.columns}
        idProp="id"
        selModel="none"
        data={comments.result}
        isLoading={comments.isLoading}
        minHeight={0}
        error={comments.error}
      />
    );
  }

  private init() {
    const { translate } = this.props;
    const trans = getScopedTransFunction(translate, '');
    this.columns = [
      {
        prop: 'storeId',
        width: 150,
        text: trans('col.store_name'),
        render: ({ storeId }) => <StoreName storeId={storeId} />,
      },
      {
        prop: 'orderNo',
        width: 150,
        text: trans('col.order_no'),
      },
      {
        prop: 'orderType',
        width: 120,
        text: trans('col.order_type'),
        render: ({ orderType }) => (
          <InspectionOrderTypeLabel value={orderType} />
        ),
      },
      {
        prop: 'customerName',
        width: 150,
        text: trans('col.customer_name'),
        hidden: true,
      },
      {
        prop: 'vehicleName',
        width: 150,
        text: trans('col.vehicle_name'),
      },
      {
        prop: 'score',
        width: 80,
        text: trans(`inspection_comment.col.score`),
      },
      {
        prop: 'comment',
        width: 200,
        text: trans('inspection_comment.col.comment'),
      },
      {
        prop: 'createdAt',
        text: trans('col.created_at'),
        width: 150,
        align: 'center',
        render: ({ createdAt }) => formatTime(createdAt),
      },
    ];
  }
}

export const RecentVehicleInspectionCommentList = withLocalize(
  RecentVehicleInspectionCommentListComponent,
);
