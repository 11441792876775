/**
 * @file: MaintenanceServiceSubjectService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { MaintenanceServiceSubjectServiceBase } from './base/MaintenanceServiceSubjectService';

export class MaintenanceServiceSubjectService extends MaintenanceServiceSubjectServiceBase {
  // add custom service implementation here
}
