import { createAsyncActionCreators } from 'lib/duck/actions';
import { WeixinOpenAuthorizeUrlType, WeixinOpenAuthType } from 'model';
import { weixinService } from 'services';

export const authorizationUrlActions = createAsyncActionCreators('integration.authorization_url', {
  fetchHandler: () => {
    const url = [
      location.protocol,
      '//',
      location.host,
      '/weixin/open/authorization/callback'
    ].join('');
    return weixinService.getAuthorizationUrl(
      url, WeixinOpenAuthorizeUrlType.Qrcode, WeixinOpenAuthType.MpOnly
      );
  }
});