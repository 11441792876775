/**
 * @file: user.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { Identity } from 'model';
import { ActionTypes } from '../types';

export default function userIdentityReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Identity | null = null,
  action: StandardAction<any>,
): Identity | null {
  if (action.type == ActionTypes.UserAuthenticated) {
    return action.payload;
  }
  return state;
}
