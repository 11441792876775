/**
 * @file: InspectionOrderPriorityOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionOrderPriority } from 'model';

export const InspectionOrderPriorityOptions: Array<Option<InspectionOrderPriority>> = [
  { value: InspectionOrderPriority.Normal, label: 'inspection_order_priority.normal' },
  { value: InspectionOrderPriority.High, label: 'inspection_order_priority.high' },
  { value: InspectionOrderPriority.Urgent, label: 'inspection_order_priority.urgent' }
];

export const InspectionOrderPriorityOptionsWithDefault: Array<Option<InspectionOrderPriority | null>> = [
  { value: null, label: 'inspection_order_priority.__default__' },
  { value: InspectionOrderPriority.Normal, label: 'inspection_order_priority.normal' },
  { value: InspectionOrderPriority.High, label: 'inspection_order_priority.high' },
  { value: InspectionOrderPriority.Urgent, label: 'inspection_order_priority.urgent' }
];