import { CSSProperties, MouseEvent, ReactNode, memo, useCallback } from 'react';

export const LinkButton = memo(
  ({
    onClick,
    ...props
  }: {
    children?: ReactNode;
    onClick?: () => void;
    className?: string;
    style?: CSSProperties;
  }) => {
    const handleClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.();
      },
      [onClick],
    );

    return <a href="#" onClick={handleClick} {...props} />;
  },
);
