/**
 * @file: InspectionTaskDiagnosticJobMediaCategoryLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Badge, BadgeProps } from 'lib/metronic/components';
import { InspectionTaskDiagnosticJobMediaCategory } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { LabelColors } from '../LabelColors';

interface Props extends BadgeProps {
  value: InspectionTaskDiagnosticJobMediaCategory | null | undefined;
}

const Values = {
  [InspectionTaskDiagnosticJobMediaCategory.DiagnosticResult]:
    'diagnostic_result',
  [InspectionTaskDiagnosticJobMediaCategory.Procedure]: 'procedure',
};

export const InspectionTaskDiagnosticJobMediaCategoryLabel = React.memo<Props>(
  props => {
    const { value, color, ...otherProps } = props;

    let enumValue = '';
    if (value !== null && value !== undefined) {
      enumValue = Values[value];
    }

    const labelColor =
      value && LabelColors.InspectionTaskDiagnosticJobMediaCategory?.[enumValue]
        ? LabelColors.InspectionTaskDiagnosticJobMediaCategory[enumValue]
        : color;

    return (
      <Badge inline color={labelColor} {...otherProps}>
        <Translate
          id={`inspection_task_diagnostic_job_media_category.${enumValue}`}
        />
      </Badge>
    );
  },
);
