import React, { useState, useCallback, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Grid } from 'lib/metronic/components';
import { Translate } from 'react-localize-redux';
import { authService } from 'services';
import { sha256 } from 'utils';
import { tokenService } from 'lib';
import { getString } from 'shared/components';
import { LoginForm } from './Login';
import { ChangePasswordForm } from './ChangePassword';

import './login.scss';
import { AuthProviderType } from 'model/auth';

type Mode = 'login' | 'change_password';

export function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const oldPassword = useRef('');
  const [mode, setMode] = useState<Mode>('login');

  const [errorMsg, setErrorMsg] = useState(
    localStorage.getItem('__last_auth_error__'),
  );

  useEffect(() => {
    localStorage.removeItem('__last_auth_error__');
  }, []);

  const onValidationError = useCallback((message: string) => {
    setErrorMsg(message);
  }, []);

  const onLogin = useCallback(
    async (userName: string, password: string, remember: boolean) => {
      try {
        setIsLoading(true);
        setErrorMsg('');

        const loginResult = await authService.loginV2({
          type: AuthProviderType.Password,
          userName,
          password: sha256(password)!,
        });

        setIsLoading(false);

        tokenService.setToken(loginResult.token, !remember);

        if (loginResult.requiresChangePassword) {
          setMode('change_password');
          oldPassword.current = password;
          return;
        }

        location.href = '/';
      } catch (e) {
        setIsLoading(false);

        let msg = 'general_error';
        if (e.status === 500) {
          msg = 'internal_server_error';
        } else if (e.status === 404 || e.status === 403) {
          msg = 'login.error.user_or_password_error';
        }
        console.log('error login: ', JSON.stringify(e));
        setErrorMsg(getString(msg));
      }
    },
    [],
  );

  const onChangePassword = useCallback(async (password: string) => {
    try {
      setIsLoading(true);
      setErrorMsg('');

      const success = await authService.changePassword(
        sha256(oldPassword.current)!,
        sha256(password)!,
      );

      setIsLoading(false);

      if (!success) {
        setErrorMsg(getString('login.error.failed_to_change_password'));
      } else {
        location.href = '/';
      }
    } catch (e) {
      setIsLoading(false);

      let msg = 'general_error';

      if (
        e.code === 'missing_old_password' ||
        e.code === 'missing_new_password' ||
        e.code === 'incorrect_old_password' ||
        e.code === 'unexpected_new_password' ||
        e.code === 'new_password_not_changed'
      ) {
        msg = `login.error.${e.code}`;
      } else if (e.status === 500) {
        msg = 'internal_server_error';
      }

      console.log('error change password: ', JSON.stringify(e));
      setErrorMsg(getString(msg));
    }
  }, []);

  return (
    <Grid direction="vertical" root>
      <Grid
        direction="horizontal"
        root
        className={classNames('kt-login kt-login--v4 kt-login--signin')}
      >
        <Grid.Item
          fluid
          grid={{ direction: 'horizontal' }}
          style={{
            backgroundImage: `url('lib/metronic/assets/img/bg/bg-2.jpg')`,
          }}
        >
          <Grid.Item fluid className="kt-login__wrapper">
            <div className="kt-login__container">
              <div className="kt-login__logo">
                <a href="/">
                  <img src={require('assets/img/logo.png')} />
                </a>
              </div>
              <div className="kt-login__signin">
                <div className="kt-login__head">
                  <h3 className="kt-login__title" style={{ fontSize: '2rem' }}>
                    <Translate id={`login.title.${mode}`} />
                  </h3>
                  <div className="kt-login__desc">
                    <Translate id={`login.sub_title.${mode}`} />
                  </div>
                  {errorMsg && (
                    <div className="kt-login__desc kt-font-danger text-center">
                      {errorMsg}
                    </div>
                  )}
                </div>
                {mode === 'login' ? (
                  <LoginForm
                    isLoading={isLoading}
                    onValidationError={onValidationError}
                    onLogin={onLogin}
                  />
                ) : (
                  <ChangePasswordForm
                    isLoading={isLoading}
                    onValidationError={onValidationError}
                    onChangePassword={onChangePassword}
                  />
                )}
              </div>
            </div>
          </Grid.Item>
        </Grid.Item>
      </Grid>
    </Grid>
  );
}
