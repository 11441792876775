import { AppContext } from 'app/AppContext';
import { AppState } from 'app/duck/states';
import {
  authorizationActions,
  authorizationFailed,
  authorizationSuccess,
  authorizationUrlActions,
  beginAuthorization,
} from 'app/integration/duck/actions';
import {
  AuthorizationState,
  MiniProgramLinkState,
} from 'app/integration/duck/states';
import classNames from 'classnames';
import { DispatchFn } from 'lib/duck/interfaces';
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Callout,
  Dropdown,
  Nav,
  Page,
  Portlet,
} from 'lib/metronic/components';
import {
  AclObjectList,
  LinkedMiniProgramStatus,
  OfficialAccountGrantedPermissionType,
  WeixinOpenAuthorizeUrlType,
  WeixinOpenAuthType,
} from 'model';
import { Component, MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { weixinService } from 'services';
import { getString } from 'shared/components';
import { loadAsyncState } from 'utils';
import { AuthorizationDetail } from './AuthorizationDetail';

import { hideAppLoading, showAppLoading, showAppModal } from 'app/duck/actions';
import {
  miniProgramLinkStateActions,
  requestLinkMiniProgram,
  unlinkMiniProgram,
} from './duck/actions/mini-program-link-state';
import './index.scss';

interface Props {
  miniProgramLinkState: MiniProgramLinkState;
  authorizationState: AuthorizationState;
  authorizationUrl: string | null;
  dispatch: DispatchFn<AppState>;
}

function mapStateToProps(state: AppState): Partial<Props> {
  return {
    miniProgramLinkState: state.integration.miniProgramLinkState,
    authorizationState: state.integration.authorization,
    authorizationUrl: state.integration.authorizationUrl.result,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

class WeixinOpenAuthorizationComponent extends Component<Props> {
  autoLinkMiniprogramFlag = false;

  private readonly breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="integration.breadcrumb.it" /> },
    { text: <Translate id="integration.breadcrumb.authorization" /> },
  ];

  componentDidMount() {
    this.props.dispatch((dispatch, getState) => {
      const state = getState();
      loadAsyncState(
        state.integration.authorization,
        () => dispatch(authorizationActions.fetch()),
        true,
      );
      loadAsyncState(
        state.integration.authorizationUrl,
        () => dispatch(authorizationUrlActions.fetch()),
        true,
      );
    });
  }

  componentDidUpdate(prevProps: Props) {
    // if authorization statue is loaded
    if (
      this.props.authorizationState.result !=
      prevProps.authorizationState.result
    ) {
      this.props.dispatch((dispatch, getState) => {
        const state = getState();
        loadAsyncState(
          state.integration.miniProgramLinkState,
          () => dispatch(miniProgramLinkStateActions.fetch()),
          true,
        );
      });
    }

    // automatically request link to miniprogram
    if (
      this.autoLinkMiniprogramFlag &&
      !this.props.miniProgramLinkState.isLoading &&
      prevProps.miniProgramLinkState.isLoading &&
      !this.props.miniProgramLinkState.result
    ) {
      this.autoLinkMiniprogramFlag = false;
      this.props.dispatch(requestLinkMiniProgram());
    }
  }

  render() {
    return (
      <Page
        title={getString('integration.authorization.title')}
        fullAccessRight={AclObjectList.WeixinOpenIntegrationFullAccess}
        readonlyAccessRight={AclObjectList.WeixinOpenIntegrationReadonlyAccess}
      >
        <Page.Header>
          <Page.Header.Main>
            <Breadcrumb items={this.breadcrumbs} />
          </Page.Header.Main>
        </Page.Header>
        <Page.Content className="inventory-summary__page-body">
          <Portlet>
            <Portlet.Header
              icon={
                require('!@svgr/webpack!lib/metronic/assets/icons/svg/general/attachment1.svg')
                  .default
              }
              title={
                <span>
                  {getString('integration.authorization.title')}
                  <a
                    href="#"
                    onClick={this.onAuthorizationQrcodeClick}
                    style={{ marginLeft: 8 }}
                  >
                    <i className="fa fa-qrcode" />
                  </a>
                </span>
              }
              size="large"
              iconColor="brand"
            >
              <div className="weixin-authorization__header">
                {this.renderMiniProgramLinkState()}
                <Dropdown
                  iconOnly
                  inline
                  clean
                  size="small"
                  buttonContents={<i className="flaticon-more-1" />}
                  showToggleButton={false}
                  dropdownProps={{ placement: 'bottom-end' }}
                >
                  <Nav>
                    <Nav.Item
                      icon="flaticon-refresh"
                      text={getString(
                        'integration.authorization.button.refresh_account_info',
                      )}
                      onClick={this.onRefresh}
                    />
                  </Nav>
                </Dropdown>
              </div>
            </Portlet.Header>
            <Portlet.Body className="weixin-authorization">
              {this.renderPageBody()}
            </Portlet.Body>
          </Portlet>
        </Page.Content>
      </Page>
    );
  }

  onAuthorizationQrcodeClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const callbackUrl = [
      location.protocol,
      '//',
      location.host,
      '/weixin/open/authorization/callback?h5=1',
    ].join('');
    try {
      this.props.dispatch(
        showAppLoading({
          message: getString('integration.authorization.qrcode.loading'),
          status: 'loading',
        }),
      );
      const { dataURI, authorizationUrl } =
        await weixinService.getAuthorizationQrcode(
          callbackUrl,
          WeixinOpenAuthorizeUrlType.Link,
          WeixinOpenAuthType.MpOnly,
        );
      this.props.dispatch(hideAppLoading());
      this.props.dispatch(
        showAppModal(
          getString('integration.authorization.qrcode.title'),
          <div>
            <p style={{ textAlign: 'center' }}>
              {getString('integration.authorization.qrcode.description')}
            </p>
            <p style={{ textAlign: 'center' }}>
              <img src={dataURI} style={{ width: 350, height: 350 }} />
            </p>
            <p
              style={{
                textAlign: 'center',
                position: 'relative',
                backgroundColor: '#f9f9f9',
                border: '1px solid #ccc',
                padding: '20px 8px',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                fontSize: '0.8rem',
                color: '#888',
              }}
            >
              {authorizationUrl}
              <a
                href="#"
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  padding: '1px 3px',
                  backgroundColor: '#aaa',
                  fontSize: '0.85rem',
                  color: '#fff',
                }}
                // eslint-disable-next-line @typescript-eslint/no-shadow
                onClick={e => {
                  e.preventDefault();
                  if (!navigator.clipboard?.writeText) {
                    alert(
                      'navigator.clipboard.writeText is not supported in your browser. ' +
                        'But you can copy and paste the invite link manually. ',
                    );
                    return;
                  }
                  void navigator.clipboard.writeText(authorizationUrl);
                  e.currentTarget.innerText = getString(
                    'integration.authorization.qrcode.copied',
                  );
                }}
              >
                {getString('integration.authorization.qrcode.copy')}
              </a>
            </p>
          </div>,
        ),
      );
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (e) {
      this.props.dispatch(hideAppLoading());
      alert(e.message);
    }
  };

  renderPageBody() {
    const {
      isLoading,
      result,
      isAuthorizing,
      authorizationError,
      error,
      isAuthorizationSuccess,
    } = this.props.authorizationState;

    const { authorizationUrl } = this.props;

    if (isLoading) {
      return (
        <div className="weixin-authorization__loading">
          <Translate id="integration.authorization.loading" />
        </div>
      );
    }

    if (null === result) {
      if (error) {
        return (
          <Callout color="danger" diagonalBg>
            <Callout.Content>
              <Translate id="integration.authorization.fetch_error" />
            </Callout.Content>
            <Callout.Action>
              <Button
                color="info"
                bold
                uppercased
                wide
                size="large"
                onClick={this.onLoad}
              >
                <Translate id="integration.authorization.button.retry_fetch" />
              </Button>
            </Callout.Action>
          </Callout>
        );
      }

      if (authorizationError) {
        return (
          <Callout color="danger" diagonalBg>
            <Callout.Content>
              <span className="kt-font-danger">
                <ReactMarkdown>
                  {getString('integration.authorization.authorization_error', {
                    errorMsg: authorizationError.message,
                  })}
                </ReactMarkdown>
              </span>
            </Callout.Content>
            <Callout.Action>
              <Button
                color="info"
                bold
                uppercased
                wide
                size="large"
                onClick={this.onAuthorize}
              >
                <Translate id="integration.authorization.button.retry_authorize" />
              </Button>
            </Callout.Action>
          </Callout>
        );
      }

      return (
        <div className="weixin-authorization__unauthorized-msg">
          <AppContext.Consumer>
            {({ identity }) => {
              if (
                identity.hasAccessRights(
                  AclObjectList.WeixinOpenIntegrationFullAccess,
                )
              ) {
                return (
                  <Callout
                    color="danger"
                    diagonalBg
                    title={getString(
                      'integration.authorization.unauthorized_title',
                    )}
                  >
                    <Callout.Content>
                      <Translate id="integration.authorization.unauthorized_message" />
                      {isAuthorizing && (
                        <div
                          className="kt-font-success"
                          style={{ marginTop: '1rem' }}
                        >
                          <Translate id="integration.authorization.authorizing_message" />
                        </div>
                      )}
                    </Callout.Content>
                    <Callout.Action>
                      <Button
                        color="success"
                        bold
                        uppercased
                        wide
                        tall="taller"
                        size="large"
                        spinner={isAuthorizing}
                        spinnerColor="light"
                        disabled={isAuthorizing || !authorizationUrl}
                        onClick={this.onAuthorize}
                      >
                        <Translate
                          id={`integration.authorization.button.${
                            isAuthorizing ? 'authorizing' : 'authorize'
                          }`}
                        />
                      </Button>
                    </Callout.Action>
                  </Callout>
                );
              }
              if (
                identity.hasAccessRights(
                  AclObjectList.WeixinOpenIntegrationReadonlyAccess,
                )
              ) {
                return (
                  <Callout
                    color="danger"
                    diagonalBg
                    title={getString(
                      'integration.authorization.unauthorized_title',
                    )}
                  >
                    <Callout.Content>
                      <Translate id="integration.authorization.unauthorized_message_readonly" />
                    </Callout.Content>
                    <Callout.Action>
                      <Button
                        color="success"
                        bold
                        uppercased
                        fontSize="small"
                        disabled
                      >
                        <Translate id="integration.authorization.button.authorize" />
                      </Button>
                    </Callout.Action>
                  </Callout>
                );
              }
              return null;
            }}
          </AppContext.Consumer>
        </div>
      );
    }

    const hasMenuPermission = Boolean(
      result.authorizationInfo.funcInfo.find(
        x =>
          x.funcscopeCategory.id === OfficialAccountGrantedPermissionType.Menu,
      ),
    );

    return (
      <>
        {isAuthorizationSuccess && (
          <Alert color="success" icon="fa fa-check-circle" iconColor="light">
            <Translate id="integration.authorization.authorization_success_message" />
          </Alert>
        )}
        <AuthorizationDetail
          authorizedInfo={result}
          reauthorizable={Boolean(authorizationUrl)}
          menuConfiguable={hasMenuPermission}
          onReauthorize={this.onReauthorize}
        />
      </>
    );
  }

  renderMiniProgramLinkState() {
    return (
      <div className="mpln-state-ct">
        {this.renderMiniProgramLinkStateDetail()}
      </div>
    );
  }

  renderMiniProgramLinkStateDetail() {
    if (!this.props.authorizationState.result) return null;

    const $action = (s: string, handler: () => void) => {
      const onclick = (e: MouseEvent) => {
        e.preventDefault();
        handler();
      };
      return (
        <a href="#" onClick={onclick}>
          {getString(`integration.miniprogram.${s}`)}
        </a>
      );
    };

    // eslint-disable-next-line @typescript-eslint/init-declarations
    let linkStatus:
      | 'not_linked'
      | 'linked'
      | 'app_accept_pending'
      | 'mp_accept_pending'
      | 'rejected';

    let showAsError = false;
    let iconCls = '';

    const { miniProgramLinkState } = this.props;

    if (miniProgramLinkState.isLinking) {
      return (
        <div className="mpln-state">
          <i className="la la-spinner fa-pulse" />
          <Translate id="integration.miniprogram.linking" />
        </div>
      );
    }

    if (miniProgramLinkState.linkError) {
      return (
        <div className="mpln-state mpln-state--error">
          <i className="fa fa-exclamation-circle" />
          <Translate
            id="integration.miniprogram.link_error"
            data={{
              try_again: $action('try_again', this.onRequestLinkMiniProgram),
            }}
          />
        </div>
      );
    }

    if (miniProgramLinkState.isUnlinking) {
      return (
        <div className="mpln-state">
          <i className="la la-spinner fa-pulse" />
          <Translate id="integration.miniprogram.unlinking" />
        </div>
      );
    }

    if (miniProgramLinkState.unlinkError) {
      return (
        <div className="mpln-state mpln-state--error">
          <i className="fa fa-exclamation-circle" />
          <Translate
            id="integration.miniprogram.unlink_error"
            data={{ try_again: $action('try_again', this.onUnlinkMiniProgram) }}
          />
        </div>
      );
    }

    const app = miniProgramLinkState.result;

    if (!app) {
      if (miniProgramLinkState.error) {
        return (
          <div className="mpln-state mpln-state--error">
            <i className="fa fa-exclamation-circle" />
            <Translate
              id="integration.miniprogram.fetch_error"
              data={{
                try_again: $action(
                  'try_again',
                  this.onFetchMiniProgramLinkState,
                ),
              }}
            />
          </div>
        );
      }
      if (miniProgramLinkState.isLoading) {
        return (
          <div className="mpln-state">
            <i className="la la-spinner fa-pulse" />
            <Translate id="integration.miniprogram.loading" />
          </div>
        );
      }
      linkStatus = 'not_linked';
      iconCls = 'fa fa-exclamation-circle';
      showAsError = true;
    } else {
      if (app.status === LinkedMiniProgramStatus.Linked) {
        linkStatus = 'linked';
        iconCls = 'fa fa-check-circle';
      } else if (app.status === LinkedMiniProgramStatus.Rejected) {
        linkStatus = 'rejected';
        iconCls = 'fa fa-exclamation-circle';
        showAsError = true;
      } else if (
        app.status ===
        LinkedMiniProgramStatus.WaitingForAcceptByMiniProgramAdmin
      ) {
        linkStatus = 'app_accept_pending';
        iconCls = 'fa fa-exclamation-circle';
        showAsError = true;
      } else if (
        app.status === LinkedMiniProgramStatus.WaitingForAcceptByMpAdmin
      ) {
        linkStatus = 'mp_accept_pending';
        iconCls = 'fa fa-exclamation-circle';
        showAsError = true;
      } else {
        linkStatus = 'not_linked';
        iconCls = 'fa fa-exclamation-circle';
        showAsError = true;
      }
    }
    const args: any = {
      link_now: $action('link_now', this.onRequestLinkMiniProgram),
      unlink_now: $action('unlink_now', this.onUnlinkMiniProgram),
      relink_now: $action('relink_now', this.onRequestLinkMiniProgram),
    };
    return (
      <div
        className={classNames('mpln-state', {
          [`mpln-state--${linkStatus}`]: true,
          'mpln-state--error': showAsError,
        })}
      >
        <i className={iconCls} />
        <Translate
          id={`integration.miniprogram.link_state.${linkStatus}`}
          data={args}
        />
      </div>
    );
  }

  onLoad = () => {
    this.props.dispatch(authorizationActions.fetch());
  };

  onAuthorize = (e: MouseEvent) => {
    e.preventDefault();
    this.internalAuthorize(false);
  };

  onReauthorize = (e: MouseEvent) => {
    e.preventDefault();
    this.internalAuthorize(true);
  };

  onConfigureMenu = (e: MouseEvent) => {
    e.preventDefault();
  };

  onRefresh = (e: MouseEvent) => {
    e.preventDefault();
    this.props.dispatch(authorizationActions.invalidate());
  };

  onFetchMiniProgramLinkState = () => {
    this.props.dispatch(miniProgramLinkStateActions.fetch());
  };

  onRequestLinkMiniProgram = () => {
    this.props.dispatch(requestLinkMiniProgram());
  };

  onUnlinkMiniProgram = () => {
    this.props.dispatch(unlinkMiniProgram());
  };

  async onAuthorizationCallback(authorizationCode: string, isUpdated: boolean) {
    try {
      const result = await weixinService.handleAuthorizationSuccessResult(
        authorizationCode,
        isUpdated,
      );
      // automatically link to miniprogram
      this.autoLinkMiniprogramFlag = true;
      this.props.dispatch(authorizationSuccess(result));
    } catch (e) {
      this.props.dispatch(authorizationFailed(e));
    }
  }

  internalAuthorize(isUpdate: boolean) {
    this.props.dispatch(beginAuthorization());
    localStorage.removeItem('weixin_open_auth_code');
    const win = window.open(this.props.authorizationUrl!);
    if ('StorageEvent' in window) {
      window.addEventListener(
        'storage',
        e => {
          if (e.key === 'weixin_open_auth_code' && e.newValue) {
            try {
              win?.close();
            } catch {
              /* noop */
            }
            void this.onAuthorizationCallback(e.newValue, isUpdate);
          }
        },
        { once: true },
      );
    } else {
      let timer: any = setInterval(() => {
        const authorizationCode = localStorage.getItem('weixin_open_auth_code');
        if (authorizationCode) {
          clearInterval(timer);
          timer = null;
          try {
            win?.close();
          } catch (e) {
            /* noop */
          }
          void this.onAuthorizationCallback(authorizationCode, isUpdate);
        }
      }, 1000);
    }
  }
}

export const WeixinOpenAuthorization = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WeixinOpenAuthorizationComponent);
