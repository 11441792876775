import classNames from 'classnames';
import { AreaTree } from 'model';
import { Component, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import { FormProductAgentPicker } from 'shared/components/EntityEditorForm/elements/ProductAgentPicker';
import { getString, StringLabel } from 'shared/components/StringLabel';
import {
  EntityWithAreaProps,
  EntityWithGroupBounded,
  EntityWithGroupTeamBounded,
  EntityWithStoreBounded,
  FormElement,
  FormElementAreaPicker,
  FormElementCheckbox,
  FormElementCheckboxList,
  FormElementCustom,
  FormElementDatePicker,
  FormElementFile,
  FormElementGroup,
  FormElementGroupPicker,
  FormElementGroupTeamPicker,
  FormElementHtml,
  FormElementMemberPicker,
  FormElementMultiTextInput,
  FormElementProductAgentPicker,
  FormElementRadioList,
  FormElementReactSelect,
  FormElementSelect,
  FormElementStorePicker,
  FormElementSwitch,
  FormElementTeamPicker,
  FormElementText,
  FormElementTextArea,
} from '../types';
import {
  FormAreaPicker,
  FormCheckbox,
  FormCheckboxList,
  FormFilePicker,
  FormGroup,
  FormHtmlEditor,
  FormRadioList,
  FormReactSelect,
  FormSelect,
  FormSwitch,
  FormTextArea,
  FormTextInput,
  MultiTextInput,
} from './';
import { FormDatePicker } from './DatePicker';
import { FormGroupPicker } from './GroupPicker';
import { FormGroupTeamPicker } from './GroupTeamPicker';
import { FormMemberPicker } from './MemberPicker';
import { FormStorePicker } from './StorePicker';
import { FormTeamPicker } from './TeamPicker';

type State = { error?: Error | undefined };

interface Props<T> {
  element: FormElement<T>;
  disabled?: boolean;
  value: any;
  autocomplete?: boolean;
  useUncontrolled?: boolean;
  helpTextPlacement?: 'after' | 'before';
  onGetExtraInfo: (() => any) | undefined | null;
  onGetEntity: () => T | Partial<T>;
  onGetAreas: () => AreaTree | null | undefined;
  onChange: (values: Partial<T>) => void;
}

export class FormElementRenderer<T> extends Component<Props<T>, State> {
  state: State = {};

  render() {
    const { element } = this.props;
    const helpTextPlacement =
      element.type === 'checkbox'
        ? 'after'
        : (element.helpTextPlacement ??
          this.props.helpTextPlacement ??
          'after');

    const entity = this.props.onGetEntity();
    const extra = this.props.onGetExtraInfo
      ? this.props.onGetExtraInfo()
      : undefined;

    if (element.hidden) {
      if (typeof element.hidden === 'function') {
        if (element.hidden(entity, extra)) return null;
      } else {
        return null;
      }
    }

    const control = this.internalRenderControl(entity);
    if (!control) return null;

    return (
      <div
        className={classNames('form-group', element.className, {
          validated: this.state.error || element.error,
        })}
        style={element.style}
        key={element.key || (element.prop as string)}
      >
        {element.label &&
          element.type !== 'checkbox' &&
          (element.unwrapLabel ? (
            <>{element.label}</>
          ) : (
            <label
              className={classNames('form-group__label', {
                'form-group__label--required': element.required,
              })}
            >
              <StringLabel value={element.label} />
            </label>
          ))}
        {element.helpText && helpTextPlacement === 'before' && (
          <span className="form-text text-muted form-group__help-text">
            {typeof element.helpText === 'string' ? (
              <ReactMarkdown>{getString(element.helpText)}</ReactMarkdown>
            ) : (
              <>{element.helpText}</>
            )}
          </span>
        )}
        {element.wrapControl ? (
          <div className="form-control-wrapper">
            {control}
            {element.extraContents?.(entity, this.props.onChange, extra)}
          </div>
        ) : (
          <>
            {control}
            {element.extraContents?.(entity, this.props.onChange, extra)}
          </>
        )}
        {(element.error || this.state.error) && (
          <div className="invalid-feedback">
            {element.error ?? this.state.error?.message}
          </div>
        )}
        {element.helpText && helpTextPlacement === 'after' && (
          <span className="form-text text-muted form-group__help-text">
            {typeof element.helpText === 'string' ? (
              <ReactMarkdown>{getString(element.helpText)}</ReactMarkdown>
            ) : (
              <>{element.helpText}</>
            )}
          </span>
        )}
      </div>
    );
  }

  internalRenderControl(entity: T | Partial<T>) {
    const { element } = this.props;
    const { type } = element;

    const value = entity[element.prop] as any;

    if (/^(text|number|password|email|phone)$/.test(type)) {
      return this.renderTextControl(element as FormElementText<T>, value);
    }

    if (type === 'multi-text-input') {
      return this.renderMultiTextInput(
        element as FormElementMultiTextInput<T>,
        value,
      );
    }

    if (type === 'textarea') {
      return this.renderTextArea(element as FormElementTextArea<T>, value);
    }

    if (type === 'datepicker') {
      return this.renderDatePicker(element as FormElementDatePicker<T>, value);
    }

    if (type === 'html') {
      return this.renderHtmlEditor(element as FormElementHtml<T>, value);
    }

    if (type === 'image' || type === 'video' || type === 'file') {
      return this.renderFilePicker(element as FormElementFile<T>, value);
    }

    if (type === 'radiolist') {
      return this.renderRadioList(element as FormElementRadioList<T>, value);
    }

    if (type === 'checkboxlist') {
      return this.renderCheckboxList(
        element as FormElementCheckboxList<T>,
        value,
      );
    }

    if (type === 'select') {
      return this.renderSelect(element as FormElementSelect<T>, value);
    }

    if (type === 'reactselect') {
      return this.renderReactSelect(
        element as FormElementReactSelect<T>,
        value,
      );
    }

    if (type === 'checkbox') {
      return this.renderCheckbox(
        element as FormElementCheckbox<T>,
        value || false,
      );
    }

    if (type === 'switch') {
      return this.renderSwitch(element as FormElementSwitch<T>, value || false);
    }

    if (type === 'area') {
      return this.renderAreaPicker(
        element as FormElementAreaPicker<T & EntityWithAreaProps>,
        entity as T & EntityWithAreaProps,
      );
    }

    if (type === 'store') {
      return this.renderStorePicker(
        element as FormElementStorePicker<T>,
        entity as T,
      );
    }

    if (type === 'org-group') {
      return this.renderGroupPicker(
        element as FormElementGroupPicker<T & EntityWithStoreBounded>,
        entity as T & EntityWithStoreBounded,
      );
    }

    if (type === 'org-team') {
      return this.renderTeamPicker(
        element as FormElementTeamPicker<T & EntityWithGroupBounded>,
        entity as T & EntityWithGroupBounded,
      );
    }

    if (type === 'org-member') {
      return this.renderMemberPicker(
        element as FormElementMemberPicker<T & EntityWithStoreBounded>,
        entity as T & EntityWithStoreBounded,
      );
    }

    if (type === 'custom') {
      return this.renderCustom(element as FormElementCustom<T>, entity);
    }

    if (type === 'element-group') {
      return this.renderElementGroup(element as FormElementGroup<T>);
    }

    if (type === 'product-agent') {
      return this.renderProductAgentPicker(
        element as FormElementProductAgentPicker<T>,
        entity as T,
      );
    }

    return null;
  }

  renderTextControl(element: FormElementText<T>, value: any): ReactNode {
    const { autocomplete } = this.props;
    return (
      <FormTextInput
        element={element}
        {...this.getCommonElementAttrs(element)}
        autocomplete={autocomplete}
        useUncontrolled={this.props.useUncontrolled}
        value={value}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderMultiTextInput(
    element: FormElementMultiTextInput<T>,
    value: any,
  ): ReactNode {
    const { disabled } = this.props;
    const values = value ? element.deserialize(value) : [];
    return (
      <MultiTextInput
        values={values}
        disabled={disabled}
        placeholder={
          element.placeholder ? getString(element.placeholder) : undefined
        }
        options={element.options}
        onChange={updatedValues => {
          const changes: { [K in keyof T]?: T[K] } = {};
          changes[element.prop] = element.serialize(updatedValues) as any;
          this.props.onChange(changes);
        }}
      />
    );
  }

  renderTextArea(element: FormElementTextArea<T>, value: any): ReactNode {
    const { autocomplete } = this.props;
    return (
      <FormTextArea
        element={element}
        {...this.getCommonElementAttrs(element)}
        autocomplete={autocomplete}
        useUncontrolled={this.props.useUncontrolled}
        value={value}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderDatePicker(element: FormElementDatePicker<T>, value: any): ReactNode {
    return (
      <FormDatePicker
        value={value}
        format={element.format}
        placeholder={
          element.placeholder ? getString(element.placeholder) : undefined
        }
        onChange={updatedValue => {
          const changes: { [K in keyof T]?: T[K] } = {};
          changes[element.prop] = updatedValue as any;
          this.props.onChange(changes);
        }}
      />
    );
  }

  renderFilePicker(element: FormElementFile<T>, value: any): ReactNode {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormFilePicker
        element={element}
        value={value}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderHtmlEditor(element: FormElementHtml<T>, value: any): ReactNode {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormHtmlEditor
        element={element}
        value={value}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderCheckbox(element: FormElementCheckbox<T>, value: boolean): ReactNode {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormCheckbox
        element={element}
        value={value}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderSwitch(element: FormElementSwitch<T>, value: boolean): ReactNode {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormSwitch
        element={element}
        value={value}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderRadioList(element: FormElementRadioList<T>, value: any): ReactNode {
    const entity = this.props.onGetEntity();
    const extra = this.props.onGetExtraInfo?.();
    const options =
      typeof element.options === 'function'
        ? element.options({ entity, value, extra })
        : element.options;
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormRadioList
        element={element}
        disabled={disabled}
        value={value}
        options={options}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderCheckboxList(
    element: FormElementCheckboxList<T>,
    value: any,
  ): ReactNode {
    const entity = this.props.onGetEntity();
    const extra = this.props.onGetExtraInfo?.();
    const options =
      typeof element.options === 'function'
        ? element.options({ entity, value, extra })
        : element.options;
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormCheckboxList
        element={element}
        disabled={disabled}
        value={value}
        options={options}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderSelect(element: FormElementSelect<T>, value: any): ReactNode {
    const entity = this.props.onGetEntity();
    const extra = this.props.onGetExtraInfo?.();
    const options =
      (typeof element.options === 'function'
        ? element.options({ entity, value, extra })
        : element.options) || [];
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormSelect
        element={element}
        options={options}
        value={value}
        autocomplete={this.props.autocomplete}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderReactSelect(element: FormElementReactSelect<T>, value: any): ReactNode {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormReactSelect
        element={element}
        value={value}
        disabled={disabled}
        onGetEntity={this.props.onGetEntity}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderAreaPicker(
    element: FormElementAreaPicker<T & EntityWithAreaProps>,
    entity: (T & EntityWithAreaProps) | Partial<T & EntityWithAreaProps>,
  ) {
    const areas = this.props.onGetAreas();
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormAreaPicker
        element={element}
        disabled={disabled}
        areas={areas}
        provinceId={entity.provinceId}
        cityId={entity.cityId}
        districtId={entity.districtId}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderStorePicker(
    element: FormElementStorePicker<T>,
    entity: T | Partial<T>,
  ) {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormStorePicker
        element={element}
        disabled={disabled}
        storeId={entity[element.prop] as any}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderGroupTeamPicker(
    element: FormElementGroupTeamPicker<T & EntityWithGroupTeamBounded>,
    entity:
      | (T & EntityWithGroupTeamBounded)
      | Partial<T & EntityWithGroupTeamBounded>,
    showGroupOnly: boolean,
  ) {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormGroupTeamPicker
        element={element}
        disabled={disabled}
        orgId={entity.orgId!}
        storeId={entity.storeId}
        groupId={entity.groupId}
        showGroupOnly={showGroupOnly}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderGroupPicker(
    element: FormElementGroupPicker<T & EntityWithStoreBounded>,
    entity: (T & EntityWithStoreBounded) | Partial<T & EntityWithStoreBounded>,
  ) {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormGroupPicker
        element={element as any}
        disabled={disabled}
        orgId={entity.orgId!}
        storeId={entity.storeId}
        groupId={entity[element.prop] as any}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderTeamPicker(
    element: FormElementTeamPicker<T & EntityWithGroupBounded>,
    entity: (T & EntityWithGroupBounded) | Partial<T & EntityWithGroupBounded>,
  ) {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormTeamPicker
        element={element as any}
        disabled={disabled}
        orgId={entity.orgId!}
        storeId={entity.storeId}
        groupId={entity.groupId}
        teamId={entity[element.prop] as any}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderMemberPicker(
    element: FormElementMemberPicker<T & EntityWithStoreBounded>,
    entity: (T & EntityWithStoreBounded) | Partial<T & EntityWithStoreBounded>,
  ) {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormMemberPicker
        element={element as any}
        disabled={disabled}
        orgId={entity.orgId!}
        storeId={entity.storeId}
        memberId={entity[element.prop] as any}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderCustom(element: FormElementCustom<T>, entity: T | Partial<T>) {
    const extra = this.props.onGetExtraInfo?.();
    return (element.render && element.render(extra, entity)) || null;
  }

  renderElementGroup(group: FormElementGroup<T>) {
    const entity = this.props.onGetEntity();
    const { disabled } = this.props;
    return (
      <FormGroup
        element={group}
        value={entity[group.prop]}
        autocomplete={this.props.autocomplete}
        disabled={disabled}
        useUncontrolled={this.props.useUncontrolled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onGetEntity={this.props.onGetEntity}
        onGetAreas={this.props.onGetAreas}
        onChange={this.props.onChange}
      />
    );
  }

  renderProductAgentPicker(
    element: FormElementProductAgentPicker<T>,
    entity: T | Partial<T>,
  ) {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormProductAgentPicker
        element={element}
        disabled={disabled}
        agentId={entity[element.prop] as any}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  private readonly onValidate = (value: any) => {
    if (this.props.element.validate == null) return undefined;
    const res = this.props.element.validate?.(value);
    if (res != null && res !== true) {
      const error =
        res === false
          ? new Error('Field is invalid')
          : typeof res === 'string'
            ? new Error(res)
            : res;
      this.setState({
        error,
      });
      return error;
    }
    if (this.state.error) {
      this.setState({ error: undefined });
    }
    return undefined;
  };

  private getCommonElementAttrs(
    element: Pick<FormElement<T>, 'disabled' | 'readonly' | 'validate'>,
  ) {
    const entity = this.props.onGetEntity();

    const extra = this.props.onGetExtraInfo?.();

    let disabled: boolean | undefined = undefined;
    if (this.props.disabled === true) {
      disabled = true;
    } else {
      if (typeof element.disabled === 'function') {
        disabled = element.disabled(entity, extra);
      } else {
        disabled = element.disabled;
      }
    }

    let readOnly: boolean | undefined = undefined;
    if (typeof element.readonly === 'function') {
      readOnly = element.readonly(entity, extra);
    } else {
      readOnly = element.readonly;
    }

    return {
      disabled,
      readOnly,
      error: this.state.error,
      onValidate: element.validate ? this.onValidate : undefined,
    };
  }
}
