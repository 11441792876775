/**
 * @file: OrderReturnType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum OrderReturnType {
  None = 0,
  OnSite,
  Deliver,
  Pick,
}

export const OrderReturnTypeValueSet = new Set([
  OrderReturnType.None,
  OrderReturnType.OnSite,
  OrderReturnType.Deliver,
  OrderReturnType.Pick,
]);
