/**
 * @file: product-authorization-contracts.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { ProductAuthorizationContract } from 'model';
import { ProductAuthorizationContracts } from '../states';

const initialState: ProductAuthorizationContracts = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {
    status: [],
  },
  sorts: [
    {
      property: 'createdAt',
      dir: 'desc',
    },
  ],
};

const defaultReducer = createAsyncListActionReducers<
  ProductAuthorizationContract,
  ProductAuthorizationContracts
>('product_authorization_contracts', initialState, {
  mapItemKey: x => x.id,
});

export default function productAuthorizationContractReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ProductAuthorizationContracts = initialState,
  action: StandardAction<any>,
): ProductAuthorizationContracts {
  switch (action.type) {
    default: {
      return defaultReducer(state, action);
    }
  }
}
