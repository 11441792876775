import classNames from 'classnames';
import {
  MpMenuItem,
  MpMenuItemClick,
  MpMenuItemMiniprogram,
  MpMenuItemType,
  MpMenuItemView,
  OfficialAccountMenuItemType,
  OfficialAccountMenuItemTypeOptions,
  WeixinMsgType,
  WeixinOpenAuthorizedInfo,
} from 'model';
import React, { ChangeEvent, FocusEvent, PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { getString } from 'shared/components';
import { validateMenuItemName } from '../helpers';
import { ClickItemEditor } from './click/Editor';
import { MiniprogramItemEditor } from './miniprogram/Editor';
import { ViewItemEditor } from './view/Editor';

interface Props {
  editorId: number;
  parentIndex: number | undefined;
  index: number;
  item: MpMenuItem;
  keyData: { [key: string]: any };
  authorizedInfo: WeixinOpenAuthorizedInfo;
  errors: { [name: string]: string | null };
  onRemoveMenuItem: (
    editorId: number,
    index: number,
    parentIndex?: number,
  ) => void;
  onMenuItemChange: (
    editorId: number,
    path: number[],
    changes: any,
    data?: any,
  ) => void;
  onMenuItemTypeChange: (
    editorId: number,
    path: number[],
    type: MpMenuItemType,
  ) => void;
  onClickMsgTypeChange: (
    editorId: number,
    path: number[],
    msgType: WeixinMsgType,
  ) => void;
  onLoadClickMenuItemData: (editorId: number, path: number[]) => void;
  onMenuItemError: (
    editorId: number,
    path: number[],
    name: string,
    error: string | null,
  ) => void;
}

const SupportedMenuItemTypeSet = new Set([
  OfficialAccountMenuItemType.Click,
  OfficialAccountMenuItemType.View,
  OfficialAccountMenuItemType.Miniprogram,
]);

export class MenuItemDetail extends PureComponent<Props> {
  private readonly nameRef = React.createRef<HTMLInputElement>();

  render() {
    const { item, editorId, parentIndex, index, keyData, errors } = this.props;
    const types = OfficialAccountMenuItemTypeOptions.filter(x =>
      SupportedMenuItemTypeSet.has(x.value),
    );

    return (
      <div className="menu-item-detail kt-section kt-section--first">
        <div className="form-group">
          <label>
            <Translate id="integration.menu.label.name" />
          </label>
          <input
            ref={this.nameRef}
            type="text"
            className={classNames('form-control', {
              'is-invalid': errors.name,
            })}
            key={item.name || ''}
            defaultValue={item.name || ''}
            onBlur={this.onButtonNameBlur}
          />
          {errors.name && (
            <div className="invalid-feedback">
              <Translate id={errors.name} />
            </div>
          )}
          <span className="form-text text-muted">
            <ReactMarkdown>
              {getString('integration.menu.help_text.name')}
            </ReactMarkdown>
          </span>
        </div>
        {!item.subButton && (
          <div className="form-group">
            <label>
              <Translate id="integration.menu.label.type" />
            </label>
            <select
              className="form-control"
              value={item.type}
              onChange={this.onButtonTypeChange}
            >
              {types.map(option => (
                <option value={option.value} key={option.value}>
                  {getString(option.label)}
                </option>
              ))}
            </select>
            <div className="form-text text-muted">
              <ReactMarkdown>
                {getString(`integration.menu.help_text.item_type.${item.type}`)}
              </ReactMarkdown>
            </div>
          </div>
        )}
        {!item.subButton && item.type === MpMenuItemType.Click && (
          <ClickItemEditor
            editorId={editorId}
            item={item as MpMenuItemClick}
            parentIndex={parentIndex}
            index={index}
            data={keyData[(item as MpMenuItemClick).key]}
            onMenuItemChange={this.props.onMenuItemChange}
            onClickMsgTypeChange={this.props.onClickMsgTypeChange}
            onLoadMenuItemData={this.props.onLoadClickMenuItemData}
          />
        )}
        {!item.subButton && item.type === MpMenuItemType.View && (
          <ViewItemEditor
            editorId={editorId}
            parentIndex={parentIndex}
            index={index}
            url={(item as MpMenuItemView).url}
            onMenuItemChange={this.props.onMenuItemChange}
          />
        )}
        {!item.subButton && item.type === MpMenuItemType.Miniprogram && (
          <MiniprogramItemEditor
            editorId={editorId}
            parentIndex={parentIndex}
            index={index}
            url={(item as MpMenuItemMiniprogram).url}
            appid={(item as MpMenuItemMiniprogram).appid}
            pagepath={(item as MpMenuItemMiniprogram).pagepath}
            onMenuItemChange={this.props.onMenuItemChange}
          />
        )}
      </div>
    );
  }

  onButtonNameBlur = (e: FocusEvent<HTMLInputElement>) => {
    let name = e.target.value;
    const errorMsg = validateMenuItemName(name);
    if (errorMsg) {
      this.props.onMenuItemError(
        this.props.editorId,
        this.getIndexPath(),
        'name',
        errorMsg,
      );
      return;
    }
    if (name !== this.props.item.name) {
      name = name.trim();
      this.props.onMenuItemError(
        this.props.editorId,
        this.getIndexPath(),
        'name',
        null,
      );
      this.nameRef.current!.value = name;
      this.onChange({ name });
    }
  };

  onButtonTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { editorId, parentIndex, index } = this.props;
    const path = parentIndex !== undefined ? [parentIndex, index] : [index];
    const type = e.target.value as MpMenuItemType;
    this.props.onMenuItemTypeChange(editorId, path, type);
  };

  onChange = (changes: any) => {
    const { editorId, parentIndex, index } = this.props;
    const path = parentIndex !== undefined ? [parentIndex, index] : [index];
    this.props.onMenuItemChange(editorId, path, changes);
  };

  getIndexPath() {
    const { parentIndex, index } = this.props;
    return parentIndex !== undefined ? [parentIndex, index] : [index];
  }
}
