/**
 * @file: VehicleInspectionSiteCheckItemMediaService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionSiteCheckItemMediaListFilter, VehicleInspectionSiteCheckItemMedia, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class VehicleInspectionSiteCheckItemMediaServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionSiteCheckItemMediaListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionSiteCheckItemMedia> : VehicleInspectionSiteCheckItemMedia[]> {
    return await this.api.get()
      .url('/vehicle-inspection-site-check-item-medias', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionSiteCheckItemMedia | null> {
    return await this.api.get()
      .url('/vehicle-inspection-site-check-item-medias/:id', { id })
      .future();
  }

  async create(vehicleInspectionSiteCheckItemMedia: Partial<VehicleInspectionSiteCheckItemMedia>): Promise<VehicleInspectionSiteCheckItemMedia> {
    return await this.api.post()
      .url('/vehicle-inspection-site-check-item-medias')
      .data(vehicleInspectionSiteCheckItemMedia)
      .future();
  }

  async update(vehicleInspectionSiteCheckItemMedia: Partial<VehicleInspectionSiteCheckItemMedia>): Promise<VehicleInspectionSiteCheckItemMedia> {
    return await this.api.put()
      .url('/vehicle-inspection-site-check-item-medias/:id', { id: vehicleInspectionSiteCheckItemMedia.id })
      .data(vehicleInspectionSiteCheckItemMedia)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/vehicle-inspection-site-check-item-medias/:id', { id })
      .future();
  }
}