/**
 * @file: ProductStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ProductStatus } from 'model';

export const ProductStatusOptions: Array<Option<ProductStatus>> = [
  { value: ProductStatus.New, label: 'product_status.new' },
  { value: ProductStatus.Unavailable, label: 'product_status.unavailable' },
  { value: ProductStatus.Listed, label: 'product_status.listed' },
];

export const ProductStatusOptionsWithDefault: Array<
  Option<ProductStatus | null>
> = [
  { value: null, label: 'product_status.__default__' },
  { value: ProductStatus.New, label: 'product_status.new' },
  { value: ProductStatus.Unavailable, label: 'product_status.unavailable' },
  { value: ProductStatus.Listed, label: 'product_status.listed' },
];
