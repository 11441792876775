/**
 * @file: index.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */

import { AuthenticatedUserInfo } from 'model/auth/AuthenticatedUserInfo';

export enum AuthProviderType {
  Password = 'password',
  Phone = 'phone',
  Otc = 'otc',
}

export const AuthProviderTypeSet = new Set([
  AuthProviderType.Password,
  AuthProviderType.Phone,
  AuthProviderType.Otc,
]);

export type PasswordAuthProvider = {
  type: AuthProviderType.Password;
  userName: string;
  password: string;
};

export type PhoneAuthProvider = {
  type: AuthProviderType.Phone;
  phone: string;
  ticket: string;
  verifyCode: string;
};

export type OtcAuthProvider = {
  type: AuthProviderType.Otc;
  code: string;
};

export type AuthProvider =
  | PasswordAuthProvider
  | PhoneAuthProvider
  | OtcAuthProvider;

export type AuthenticatorResult<T extends AuthProvider> =
  AuthenticatedUserInfo & {
    token: string;
    extra?: any;
  } & (T extends { type: AuthProviderType.Password }
      ? { requiresChangePassword: boolean }
      : // eslint-disable-next-line @typescript-eslint/ban-types
        {});
