import { createObjectMapAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { DatePartitionType, StatItem } from 'model';
import { statsService } from 'services';

const actionCreators = createObjectMapAsyncActionCreators<AppState, DatePartitionType, StatItem[]>(
  "dashboard.reception_order_stats_by_time",
  {
    shouldFetchObjectByKeyOnInvalidate: true,
    shouldFetchOnInvalidate: true,
    mapValueToKey: (value: any) => value.partition,
    fetchHandler: () => Promise.reject('Not supported here'),
    async fetchObjectHandler(appState: AppState, partition: DatePartitionType) {
      const { startDate, endDate } = appState.dashboard.dateRange;
      const orgId = appState.identity!.orgId;
      const storeId = appState.activeStoreId;
      const statItems = await statsService.getVehicleReceptionOrderStatsByTime(
        orgId, storeId, startDate, endDate, partition
        );
      (statItems as any).partition = partition;
      return statItems;
    }
  }
);

export const {
  fetchObjectByKey: fetchReceptionOrderStatsByTime,
  invalidateObjectByKey: invalidateReceptionOrderStatsByTime
} = actionCreators;