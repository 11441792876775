import React, { PureComponent } from 'react';
import { MpMediaPicker } from '../shared/MediaPicker';
import { WeixinPermanentMaterialListItem, WeixinPermanentNonNewsMaterialListItem, WeixinImageMsg } from 'model';

interface Props {
  mediaId?: string;
  data?: WeixinImageMsg;
  isTempMedia?: boolean;
  onChange: (mediaId: string, extra: any) => void;
}

export class ImageEditor extends PureComponent<Props> {
  render() {
    const { mediaId, isTempMedia, data } = this.props;
    return (
      <MpMediaPicker
        type='image'
        mediaId={mediaId}
        data={data}
        isTempMedia={isTempMedia}
        onChange={this.onChange}
        onMediaSelected={this.onMediaSelected}
        />
    );
  }

  onChange = (mediaId: string, extra: any) => {
    this.props.onChange(mediaId, extra);
  }

  onMediaSelected = (item: WeixinPermanentMaterialListItem) => {
    const imageItem = item as WeixinPermanentNonNewsMaterialListItem;
    this.props.onChange(item.mediaId, {
      name: imageItem.name,
      updateTime: imageItem.updateTime
    });
  }
}