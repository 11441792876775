import type { TransFunction } from 'app';
import { AreaTree, Identity, OrgUser, Organization, Store } from 'model';
import React, {
  ReactNode,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TranslateFunction } from 'react-localize-redux';
import { organizationService } from 'services';

export interface AppContextProps {
  org: Organization;
  stores: Store[];
  identity: Identity;
  user: OrgUser;
  areas: AreaTree | null;
  globalVisibleStores: Store[];
  activeStoreId: number | null;
  trans: TransFunction;
  translate: TranslateFunction;
}

export const AppContext = React.createContext<AppContextProps>({
  identity: null,
  user: null,
  areas: null,
  globalVisibleStores: [],
  activeStoreId: null,
} as any);

export const useAppContext = () => useContext(AppContext);

export const useTranslateToString = () => useAppContext().trans;

export const useTranslate = () => useAppContext().translate;

export const AppContextProvider = memo(
  ({
    children,
    ...props
  }: {
    identity: Identity;
    areas: AreaTree | null;
    activeStoreId: number | null;
    trans: TransFunction;
    translate: TranslateFunction;
    children?: ReactNode;
  }) => {
    const [org, setOrg] = useState<Organization>();
    const [stores, setStores] = useState<Store[]>([]);

    useEffect(() => {
      void Promise.all([
        organizationService.getCurrent(),
        organizationService.getStores(),
      ]).then(([currentOrg, storesOfOrg]) => {
        setOrg(currentOrg);
        setStores(storesOfOrg);
      });
    }, []);

    const globalVisibleStores = useMemo(
      () => stores.filter(x => props.identity.visibleStoreSet.has(x.id)),
      [stores, props.identity],
    );

    if (!org) return null;

    return (
      <AppContext.Provider
        value={{
          ...props,
          user: props.identity.userInfo,
          org,
          stores,
          globalVisibleStores,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  },
);
