/**
 * @file: VehicleInspectionTaskSubjectService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { VehicleInspectionTaskSubjectServiceBase } from './base/VehicleInspectionTaskSubjectService';

export class VehicleInspectionTaskSubjectService extends VehicleInspectionTaskSubjectServiceBase {
  // add custom service implementation here
}
