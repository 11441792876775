import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import {
  VehicleInspectionComment,
  VehicleInspectionCommentListFilter,
} from 'model';
import { vehicleInspectionCommentService } from 'services';
import { dateRangeFromDashboardState } from './helpers';

const actionCreators = createListAsyncActionCreators<
  AppState,
  VehicleInspectionComment
>('dashboard.recent_inspection_comments', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  async fetchHandler(state: AppState) {
    const orgId = state.identity!.orgId;
    const storeId = state.activeStoreId || undefined;
    return await vehicleInspectionCommentService.list(
      Object.assign({}, state.dashboard.recentInspectionComments.filter, {
        orgId,
        storeId,
        createdAt: dateRangeFromDashboardState(state),
      } as VehicleInspectionCommentListFilter),
      state.dashboard.recentInspectionComments.sorts?.[0],
      state.dashboard.recentInspectionComments.offset,
      state.dashboard.recentInspectionComments.limit,
      { count: true, recursive: true },
    );
  },
});

export const {
  fetch: fetchDashboardRecentInspectionComments,
  invalidate: invalidateDashboardRecentInspectionComments,
} = actionCreators;
