import React, { Component, ReactNode } from 'react';
import { Translate, TranslateChildFunction, TranslateProps, LocalizeContextProps, TranslateFunction } from 'react-localize-redux';
import { getScopedTransFunction, TransFunction } from 'app';

interface ScopedLocalizeContextProps extends LocalizeContextProps {
  trans: TransFunction;
}

type TranslatePropsWithoutChildren
  = Pick<TranslateProps, Exclude<keyof TranslateProps, 'children'>>;

export type ScopedTranslateChildFunction = (context: ScopedLocalizeContextProps) => any;

interface Props extends TranslatePropsWithoutChildren {
  scope: string;
  children?: ScopedTranslateChildFunction | ReactNode;
}

export class ScopedTranslate extends Component<Props> {
  render() {
    const { scope, children, data, options } = this.props;
    if (typeof children === 'function') {
      return (
        <Translate>
          {(context: ScopedLocalizeContextProps) => {
            context = Object.assign({}, context);
            context.trans = getScopedTransFunction(context.translate, scope);
            context.translate = context.trans as TranslateFunction;
            return (children as TranslateChildFunction)(context);
          }}
        </Translate>
      );
    }
    let { id } = this.props;
    if (!id) return null;
    if (!id.startsWith("app.")) {
      id = scope + '.' + id;
    }
    return <Translate id={id} data={data} options={options} />;
  }
}