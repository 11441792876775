/**
 * @file: VehicleIssueCloseTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, VehicleIssueCloseType } from 'model';

export const VehicleIssueCloseTypeOptions: Array<Option<VehicleIssueCloseType>> = [
  { value: VehicleIssueCloseType.Fixed, label: 'vehicle_issue_close_type.fixed' },
  { value: VehicleIssueCloseType.WontFixByCustomer, label: 'vehicle_issue_close_type.wont_fix_by_customer' },
  { value: VehicleIssueCloseType.WontFix, label: 'vehicle_issue_close_type.wont_fix' },
  { value: VehicleIssueCloseType.Silence, label: 'vehicle_issue_close_type.silence' },
  { value: VehicleIssueCloseType.Inreproducible, label: 'vehicle_issue_close_type.inreproducible' }
];

export const VehicleIssueCloseTypeOptionsWithDefault: Array<Option<VehicleIssueCloseType | null>> = [
  { value: null, label: 'vehicle_issue_close_type.__default__' },
  { value: VehicleIssueCloseType.Fixed, label: 'vehicle_issue_close_type.fixed' },
  { value: VehicleIssueCloseType.WontFixByCustomer, label: 'vehicle_issue_close_type.wont_fix_by_customer' },
  { value: VehicleIssueCloseType.WontFix, label: 'vehicle_issue_close_type.wont_fix' },
  { value: VehicleIssueCloseType.Silence, label: 'vehicle_issue_close_type.silence' },
  { value: VehicleIssueCloseType.Inreproducible, label: 'vehicle_issue_close_type.inreproducible' }
];