import React, { Component } from 'react';

export class NotFound extends Component {
  render() {
    return (
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v2"
          style={{
            backgroundImage: 'url(lib/metronic/assets/img/bg/error/bg2.jpg)'
          }}
          >
          <div className="kt-error_container">
            <span className="kt-error_title2 kt-font-light">
              <h1>OOPS!</h1>
            </span>
            <span className="kt-error_desc kt-font-light">
              Something went wrong here
            </span>
          </div>
        </div>
      </div>
    );
  }
}