/**
 * @file: MiniprogramCertificateType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum MiniprogramCertificateType {
  Uncertified = -1,
  WeixinCertified = 0
}

export const MiniprogramCertificateTypeValueSet = new Set([
  MiniprogramCertificateType.Uncertified,
  MiniprogramCertificateType.WeixinCertified
]);