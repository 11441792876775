/**
 * @file: OrgMemberService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { OrgMemberServiceBase } from './base/OrgMemberService';

export class OrgMemberService extends OrgMemberServiceBase {
  // add custom service implementation here
}
