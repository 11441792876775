/**
 * @file: TemplateMessageSceneTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Badge, BadgeProps } from 'lib/metronic/components';
import { TemplateMessageSceneType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { LabelColors } from '../LabelColors';

interface Props extends BadgeProps {
  value: TemplateMessageSceneType | null | undefined;
}

export const TemplateMessageSceneTypeLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  const labelColor =
    value && LabelColors.TemplateMessageSceneType?.[value]
      ? LabelColors.TemplateMessageSceneType[value]
      : color;

  return (
    <Badge inline color={labelColor} {...otherProps}>
      <Translate id={`template_message_scene_type.${value}`} />
    </Badge>
  );
});
