/**
 * @file: SiteInspectionTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, SiteInspectionType } from 'model';

export const SiteInspectionTypeOptions: Array<Option<SiteInspectionType>> = [
  { value: SiteInspectionType.Default, label: 'site_inspection_type.default' },
  { value: SiteInspectionType.Dashboard, label: 'site_inspection_type.dashboard' },
  { value: SiteInspectionType.Facade, label: 'site_inspection_type.facade' },
  { value: SiteInspectionType.AirConditioner, label: 'site_inspection_type.air_conditioner' }
];

export const SiteInspectionTypeOptionsWithDefault: Array<Option<SiteInspectionType | null>> = [
  { value: null, label: 'site_inspection_type.__default__' },
  { value: SiteInspectionType.Default, label: 'site_inspection_type.default' },
  { value: SiteInspectionType.Dashboard, label: 'site_inspection_type.dashboard' },
  { value: SiteInspectionType.Facade, label: 'site_inspection_type.facade' },
  { value: SiteInspectionType.AirConditioner, label: 'site_inspection_type.air_conditioner' }
];