import { StatsStateByTime, StatAspect } from './duck/states';
import { AppState } from 'app/duck/states';
import { ActionThunk, StandardAction } from 'lib/duck/interfaces';
import { DatePartitionType, StoreSummaryStats } from 'model';
import React, { ReactNode } from 'react';

import {
  fetchUserStatsByTime,
  invalidateUserStatsByTime,
  fetchReceptionOrderStatsByTime,
  invalidateReceptionOrderStatsByTime,
  fetchInspectionTaskStatsByTime,
  invalidateInspectionTaskStatsByTime,
  fetchInspectionReportStatsByTime,
  invalidateInspectionReportStatsByTime,
  fetchInspectionCommentStatsByTime,
  invalidateInspectionCommentStatsByTime,
  fetchCustomerStatsByTime,
  invalidateCustomerStatsByTime,
} from './duck/actions';

export interface StatsByTimeProps {
  customerStatsByTime: StatsStateByTime;
  userStatsByTime: StatsStateByTime;
  receptionOrderStatsByTime: StatsStateByTime;
  inspectionTaskStatsByTime: StatsStateByTime;
  inspectionReportStatsByTime: StatsStateByTime;
  inspectionCommentStatsByTime: StatsStateByTime;
}

export interface StatAspectConfig {
  disabled?: boolean;
  statAspect: StatAspect;
  getStatsByTime(props: StatsByTimeProps): StatsStateByTime;
  fetchAction(partition: DatePartitionType): ActionThunk<AppState>;
  invalidateAction(
    partition: DatePartitionType,
  ): ActionThunk<AppState> | StandardAction<any>;
  value(stats: StoreSummaryStats): number;
  format(value: number): ReactNode | string;
}

export const StatAspectsConfigurations: StatAspectConfig[] = [
  {
    statAspect: StatAspect.Customer,
    getStatsByTime: props => props.customerStatsByTime,
    fetchAction: (partition: DatePartitionType) =>
      fetchCustomerStatsByTime(partition),
    invalidateAction: (partition: DatePartitionType) =>
      invalidateCustomerStatsByTime(partition),
    value: (stats: StoreSummaryStats) => stats.totalCustomerCount,
    format: (value: number) => <span className="kt-font-brand">{value}</span>,
  },
  {
    statAspect: StatAspect.SubscribedUser,
    getStatsByTime: props => props.userStatsByTime,
    fetchAction: (partition: DatePartitionType) =>
      fetchUserStatsByTime(partition),
    invalidateAction: (partition: DatePartitionType) =>
      invalidateUserStatsByTime(partition),
    value: (stats: StoreSummaryStats) => stats.totalUserCount,
    format: (value: number) => <span className="kt-font-brand">{value}</span>,
  },
  {
    statAspect: StatAspect.ReceptionOrder,
    getStatsByTime: props => props.receptionOrderStatsByTime,
    fetchAction: (partition: DatePartitionType) =>
      fetchReceptionOrderStatsByTime(partition),
    invalidateAction: (partition: DatePartitionType) =>
      invalidateReceptionOrderStatsByTime(partition),
    value: (stats: StoreSummaryStats) => stats.totalReceptionOrderCount,
    format: (value: number) => <span className="kt-font-brand">{value}</span>,
  },
  {
    statAspect: StatAspect.InspectionTask,
    getStatsByTime: props => props.inspectionTaskStatsByTime,
    fetchAction: (partition: DatePartitionType) =>
      fetchInspectionTaskStatsByTime(partition),
    invalidateAction: (partition: DatePartitionType) =>
      invalidateInspectionTaskStatsByTime(partition),
    value: (stats: StoreSummaryStats) => stats.totalTaskCount,
    format: (value: number) => <span className="kt-font-brand">{value}</span>,
  },
  {
    statAspect: StatAspect.InspectionReport,
    getStatsByTime: props => props.inspectionReportStatsByTime,
    fetchAction: (partition: DatePartitionType) =>
      fetchInspectionReportStatsByTime(partition),
    invalidateAction: (partition: DatePartitionType) =>
      invalidateInspectionReportStatsByTime(partition),
    value: (stats: StoreSummaryStats) => stats.totalReportCount,
    format: (value: number) => <span className="kt-font-brand">{value}</span>,
  },
  {
    statAspect: StatAspect.InspectionComment,
    getStatsByTime: props => props.inspectionCommentStatsByTime,
    fetchAction: (partition: DatePartitionType) =>
      fetchInspectionCommentStatsByTime(partition),
    invalidateAction: (partition: DatePartitionType) =>
      invalidateInspectionCommentStatsByTime(partition),
    value: (stats: StoreSummaryStats) => stats.totalCommentCount,
    format: (value: number) => <span className="kt-font-brand">{value}</span>,
  },
];

const map = new Map<StatAspect, StatAspectConfig>();
for (const configuration of StatAspectsConfigurations) {
  map.set(configuration.statAspect, configuration);
}

export function getStatAspectConfig(statAspect: StatAspect): StatAspectConfig {
  return map.get(statAspect)!;
}

export function getStatsByTimeByStatAspect(
  props: StatsByTimeProps,
  statAspect: StatAspect,
): StatsStateByTime {
  const config = map.get(statAspect)!;
  return config.getStatsByTime(props);
}

export function fetchStatAspectStatsByTime(
  props: StatsByTimeProps,
  statAspect: StatAspect,
): ActionThunk<AppState> {
  const config = map.get(statAspect)!;
  const statsByTime = config.getStatsByTime(props);
  return config.fetchAction(statsByTime.partition);
}

export function invalidateStatAspectStatsByTime(
  props: StatsByTimeProps,
  statAspect: StatAspect,
): StandardAction<any> | ActionThunk<AppState> {
  const config = map.get(statAspect)!;
  const statsByTime = config.getStatsByTime(props);
  return config.invalidateAction(statsByTime.partition);
}
