import { showAppModal } from 'app/duck/actions';
import { Alert, Block, Button, TabNav } from 'lib/metronic/components';
import { AsideRight } from 'lib/metronic/layout';
import {
  AclObjectList,
  MpMessageTemplate,
  OrgWeixinTemplateConfDetail,
  TemplateMessageSceneType,
} from 'model';
import { ChangeEvent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import {
  Checkmark,
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
} from 'shared/components';
import { weixinTemplateConfActions } from '../duck/actions/template-confs';
import {
  addTemplateLibraryTemplateIdChanged,
  cancelAddTemplateByLibraryTemplateId,
  cancelCloseTemplateBeingConfigured,
  cancelTemplateBeingConfigured,
  cancelTemplateBeingViewed,
  commitAddTemplateByLibraryTemplateId,
  confirmCloseTemplateBeingConfigured,
  templateBeingConfigured,
  templateBeingViewed,
  weixinTemplateActions,
} from '../duck/actions/templates';
import { MpMessageTemplates, OrgWeixinTemplateConfs } from '../duck/states';
import { parseTemplateParams } from './helpers';
import { TemplateConfig } from './TemplateConfig';
import { TemplateDetail } from './TemplateDetail';

interface Props extends EntityListProps<MpMessageTemplate, any, string> {
  templateConfs: OrgWeixinTemplateConfs;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  MpMessageTemplate,
  any,
  string,
  Props
>();

export const TemplateList = componentClassBuilder
  .i18nPrefix('integration.templates.list')
  .listOnly()
  // .rowClickable()
  .selModel('none')
  .loadStateExternally()
  .idProp('templateId')
  .entities(state => state.integration.templates)
  .actions(weixinTemplateActions)
  .emptyPlaceholder((props: Props) => {
    if (props.entities.error) {
      return (
        <ReactMarkdown>
          {getString('integration.templates.list.error.error_load', {
            error: props.entities.error.message,
          })}
        </ReactMarkdown>
      );
    }
    return <Translate id="integration.templates.list.data_data" />;
  })
  .columns([
    {
      prop: 'templateId',
      width: 400,
      text: 'integration.templates.list.col.template_id',
    },
    {
      prop: 'title',
      width: 120,
      text: 'integration.templates.list.col.title',
    },
    {
      prop: 'industry',
      width: 120,
      text: 'integration.templates.list.col.industry',
      render: ({ primaryIndustry, deputyIndustry }) => (
        <div>
          {[primaryIndustry, deputyIndustry?.replace(/\//, '|')]
            .filter(x => x)
            .join('/') || '-'}
        </div>
      ),
    },
    {
      prop: 'selected',
      width: 80,
      text: 'integration.templates.list.col.applied',
      align: 'center',
      render: ({ templateId }, props: Props) => {
        return (
          <Checkmark
            value={Boolean(
              props.templateConfs.result &&
                props.templateConfs.result.findIndex(
                  x => x.templateId === templateId,
                ) >= 0,
            )}
          />
        );
      },
    },
  ])
  .addActionButtons([
    {
      rights: [AclObjectList.WeixinOpenIntegrationFullAccess],
      key: 'detail',
      icon: 'fa fa-info-circle',
      tooltip: 'integration.templates.tooltip.view_template_detail',
      onClick: (item, props: Props) => {
        props.dispatch(templateBeingViewed(item));
      },
    },
    {
      rights: [AclObjectList.WeixinOpenIntegrationFullAccess],
      key: 'apply',
      icon: 'fa fa-cog',
      tooltip: 'integration.templates.tooltip.apply_template',
      onClick: (item, props: Props) => {
        if (!props.templateConfs.result) return;
        const templateConf = props.templateConfs.result.find(
          x => x.templateId === item.templateId,
        );
        props.dispatch(templateBeingConfigured(item));
        if (templateConf) {
          props.dispatch(
            weixinTemplateConfActions.itemBeingUpdated(templateConf),
          );
        } else {
          props.dispatch(
            weixinTemplateConfActions.itemBeingCreated({
              templateId: item.templateId,
              title: item.title,
              primaryIndustry: item.primaryIndustry,
              deputyIndustry: item.deputyIndustry,
              content: item.content,
              example: item.example,
              conf: JSON.stringify({
                mapping: {},
              } as OrgWeixinTemplateConfDetail),
            }),
          );
        }
      },
    },
    {
      rights: [AclObjectList.WeixinOpenIntegrationFullAccess],
      key: 'remove',
      icon: 'fa fa-trash',
      tooltip: 'integration.templates.tooltip.remove_template',
      onClick: (item, props: Props) => {
        props.dispatch(weixinTemplateActions.itemsBeingDeleted([item]));
      },
    },
  ])
  .onRender(props => {
    const templateConf =
      props.templateConfs.itemBeingCreated ||
      props.templateConfs.itemBeingUpdated;

    const templates = props.entities as MpMessageTemplates;

    function onSceneChange(scene: TemplateMessageSceneType | undefined) {
      if (props.templateConfs.itemBeingCreated) {
        props.dispatch(
          weixinTemplateConfActions.itemBeingCreatedChanged({ scene }),
        );
      } else {
        props.dispatch(
          weixinTemplateConfActions.itemBeingUpdatedChanged({ scene }),
        );
      }
    }

    function onConfDetailChange(detail: OrgWeixinTemplateConfDetail) {
      const conf = JSON.stringify(detail);
      if (props.templateConfs.itemBeingCreated) {
        props.dispatch(
          weixinTemplateConfActions.itemBeingCreatedChanged({ conf }),
        );
      } else {
        props.dispatch(
          weixinTemplateConfActions.itemBeingUpdatedChanged({ conf }),
        );
      }
    }

    function closeTemplateConfiguration() {
      if (
        props.templateConfs.isCommitingItemBeingCreated ||
        props.templateConfs.isCommitingItemBeingUpdated
      ) {
        return;
      }

      if (templates.templateBeingConfigured) {
        props.dispatch(cancelTemplateBeingConfigured());
      }
      if (props.templateConfs.itemBeingCreated) {
        props.dispatch(weixinTemplateConfActions.cancelItemBeingCreated());
      } else if (props.templateConfs.itemBeingUpdated) {
        props.dispatch(weixinTemplateConfActions.cancelItemBeingUpdated());
      }
    }

    function onTemplateDetailClosed() {
      props.dispatch(cancelTemplateBeingViewed());
    }

    function onTemplateConfigurationClose() {
      closeTemplateConfiguration();
    }

    function onConfirmTemplateConfigurationClose() {
      if (
        props.templateConfs.isCommitingItemBeingCreated ||
        props.templateConfs.isCommitingItemBeingUpdated
      ) {
        return false;
      }

      if (
        (props.templateConfs.itemBeingCreated &&
          props.templateConfs.isItemBeingCreatedDirty) ||
        (props.templateConfs.itemBeingUpdated &&
          props.templateConfs.isItemBeingUpdatedDirty)
      ) {
        props.dispatch(confirmCloseTemplateBeingConfigured());
        return false;
      }
      return true;
    }

    function onTemplateConfigurationConfirmModalClosed() {
      props.dispatch(cancelCloseTemplateBeingConfigured());
    }

    function onCloseTemplateConfigurationConfirmed() {
      closeTemplateConfiguration();
    }

    function onSave() {
      // check the configuration
      if (!templateConf!.scene) {
        props.dispatch(
          showAppModal(
            getString('integration.templates.config.title'),
            getString('integration.templates.config.error.scene_required'),
          ),
        );
        return;
      }
      const confDetail = JSON.parse(
        templateConf!.conf!,
      ) as OrgWeixinTemplateConfDetail;
      const params = parseTemplateParams(
        templates.templateBeingConfigured!.content,
      );
      for (const param of params) {
        if (!confDetail.mapping[param]) {
          props.dispatch(
            showAppModal(
              getString('integration.templates.config.title'),
              getString(
                'integration.templates.config.error.param_mapping_required',
                { param },
              ),
            ),
          );
          return;
        }
      }
      if (props.templateConfs.itemBeingCreated) {
        props.dispatch(weixinTemplateConfActions.commitItemBeingCreated());
      } else {
        props.dispatch(weixinTemplateConfActions.commitItemBeingUpdated());
      }
    }

    function onAddTemplateClose() {
      props.dispatch(cancelAddTemplateByLibraryTemplateId());
    }

    function onCommitAddTemplate() {
      props.dispatch(commitAddTemplateByLibraryTemplateId());
    }

    function onTemplateLibraryIdChanged(e: ChangeEvent<HTMLInputElement>) {
      props.dispatch(addTemplateLibraryTemplateIdChanged(e.target.value));
    }

    return (
      <>
        <AsideRight
          open={Boolean(templates.templateBeingViewed)}
          onClose={onTemplateDetailClosed}
        >
          <AsideRight.Nav>
            <TabNav line="brand" bolder>
              <TabNav.Item active>
                <Translate id="integration.templates.detail.title" />
              </TabNav.Item>
            </TabNav>
          </AsideRight.Nav>
          <AsideRight.Content>
            {templates.templateBeingViewed && (
              <TemplateDetail template={templates.templateBeingViewed!} />
            )}
          </AsideRight.Content>
        </AsideRight>
        <AsideRight
          spin={Boolean(
            props.templateConfs.isCommitingItemBeingCreated ||
              props.templateConfs.isCommitingItemBeingUpdated,
          )}
          open={Boolean(templateConf)}
          onClose={onTemplateConfigurationClose}
          onConfirmClose={onConfirmTemplateConfigurationClose}
        >
          <AsideRight.Nav>
            <TabNav line="brand" bolder>
              <TabNav.Item active>
                <Translate id="integration.templates.config.title" />
              </TabNav.Item>
            </TabNav>
          </AsideRight.Nav>
          <AsideRight.Content>
            {templateConf && templates.templateBeingConfigured && (
              <TemplateConfig
                template={templates.templateBeingConfigured!}
                conf={templateConf}
                error={
                  props.templateConfs.createError ||
                  props.templateConfs.lastUpdateError
                }
                onSceneChange={onSceneChange}
                onConfDetailChange={onConfDetailChange}
                onSave={onSave}
              />
            )}
          </AsideRight.Content>
        </AsideRight>
        <Modal
          isOpen={Boolean(templates.isConfirmCloseTemplateBeingConfigured)}
          onClosed={onTemplateConfigurationConfirmModalClosed}
          toggle={onTemplateConfigurationConfirmModalClosed}
        >
          <ModalHeader toggle={onTemplateConfigurationConfirmModalClosed}>
            <Translate id="integration.templates.modal.confirm_close_template_config.title" />
          </ModalHeader>
          <ModalBody>
            <Translate id="integration.templates.modal.confirm_close_template_config.msg" />
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              color="secondary"
              wide
              onClick={onTemplateConfigurationConfirmModalClosed}
            >
              <Translate id="cancel_btn_text" />
            </Button>
            <Button
              color="brand"
              wide
              onClick={onCloseTemplateConfigurationConfirmed}
            >
              <Translate id="yes_btn_text" />
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={templates.libraryTemplateId !== null}
          onClosed={onAddTemplateClose}
          toggle={onAddTemplateClose}
        >
          <Block active={Boolean(templates.isAddingTemplateFromLibrary)}>
            <ModalHeader toggle={onAddTemplateClose}>
              <Translate id="integration.templates.modal.add_template.title" />
            </ModalHeader>
            <ModalBody>
              {templates.addTemplateFromLibraryError && (
                <Alert color="danger">
                  {templates.addTemplateFromLibraryError.message}
                </Alert>
              )}
              <div className="form-group">
                <label>
                  <Translate id="integration.templates.modal.add_template.label.library_template_id" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={templates.libraryTemplateId || ''}
                  onChange={onTemplateLibraryIdChanged}
                  placeholder={getString(
                    'integration.templates.modal.add_template.placeholder.library_template_id',
                  )}
                />
              </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent: 'center' }}>
              <Button color="secondary" wide onClick={onAddTemplateClose}>
                <Translate id="cancel_btn_text" />
              </Button>
              <Button
                color="brand"
                wide
                disabled={!templates.libraryTemplateId?.trim()}
                onClick={onCommitAddTemplate}
              >
                <Translate id="add_btn_text" />
              </Button>
            </ModalFooter>
          </Block>
        </Modal>
      </>
    );
  })
  .onRowClick((item, props: Props) => {
    props.dispatch(templateBeingViewed(item));
  })
  .onDeleted((error: Error | undefined, props: Props) => {
    if (!error) {
      props.dispatch(weixinTemplateConfActions.invalidate(true));
    }
  })
  .getClass();
