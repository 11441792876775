import { tokenService, api } from 'lib';
import { WeixinPermanentMaterialType } from 'model';

export type MediaType = Omit<
  WeixinPermanentMaterialType,
  WeixinPermanentMaterialType.Thumbnail
>;

export function formatMediaUrl(
  mediaId: string,
  type: MediaType,
  isTempMedia: boolean
) {
  const token = tokenService.getToken();
  if (isTempMedia) {
    return api.url('/weixin/temporary-medias/:mediaId', {
      mediaId,
      type,
      unwrap: true,
      token,
    });
  }
  return api.url('/weixin/permanent-materials/:mediaId', {
    mediaId,
    type,
    unwrap: true,
    token,
  });
}

export function isValidMenuItemName(name: string) {
  return /^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(name.trim());
}

export function getMenuItemNameLengthInChars(name: string) {
  return name.trim().replace(/[\u4e00-\u9fa5]/g, 'xx').length;
}

export function validateMenuItemName(name: string, checkEmpty = false) {
  if (!name.trim()) {
    if (checkEmpty) {
      return 'integration.menu.error.menu_item_name.blank';
    }
    return null;
  }
  if (!isValidMenuItemName(name)) {
    return 'integration.menu.error.menu_item_name.invalid_chars';
  }
  if (getMenuItemNameLengthInChars(name) > 8) {
    return 'integration.menu.error.menu_item_name.too_long';
  }
  return null;
}

export function getMenuItemKeyFromIndexPath(
  editorId: number,
  indexPath: number[]
) {
  return `${editorId}/${indexPath.join('/')}`;
}

export function getMenuItemKeyFromIndex(
  editorId: number,
  index: number,
  parentIndex?: number
) {
  if (parentIndex !== undefined) {
    return `${editorId}/${parentIndex}/${index}`;
  }
  return `${editorId}/${index}`;
}

export function getItemIndexFromPath(
  path: number[]
): { index: number; parentIndex?: number } {
  if (path.length === 1) {
    return { index: path[0] };
  }
  return { index: path[1], parentIndex: path[1] };
}

export function getProxiedImgUrl(url: string) {
  if (!url) return '';
  if (!url.startsWith('http')) return url;
  const token = tokenService.getToken();
  return api.url('/weixin/img.png', { url, token });
}
