/**
 * @file: importImage.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */
import $ from 'jquery';

const win: any = window;

/**
 * {Boolean} if HTML5 File API is supported.
 */
const supportsFileAPI = Boolean(
  win.File && win.FileList && win.FileReader
);

/**
 * Import image using HTML5 File API.
 * @param {Object} options
 *   @param {Boolean} options.multiple if multiple file allowed.
 * @param {Function} callback
 */
export function importImage(options: any, callback: any) {
  if (typeof options === 'function') {
    callback = options;
    options = null;
  }
  if (!options) options = { multiple: false };
  if (!supportsFileAPI) {
    const error = new Error('HTML5 File API is not supported. ');
    callback(error);
    return;
  }
  // http://stackoverflow.com/questions/39187857/inputfile-accept-image-open-dialog-so-slow-with-chrome
  // note(eric): set the accept attributes to 'image/*' will trigger a bug in chrome
  // Thus, we use specific MIME types here instead.
  const fileEl = $(
    '<input' +
    '  type="file"' +
    '  accept="image/png, image/jpeg, image/jpg, image/gif"' +
    '  style="display:none" />'
  ).appendTo(
    document.body
  ).attr(
    'multiple', Boolean(options.multiple) as any
  ).change((e: any) => {
    e = e.originalEvent;
    const target = e.dataTransfer || e.target;
    const files: File[] = options.multiple ?
      target.files : [target.files[0]];
    fileEl.remove();
    callback(null, files);
  }).click();
}
