/**
 * @file: teams.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { OrgTeam, OrgTeamListFilter } from 'model';
import { orgTeamService } from 'services';

export const teamActions = createListAsyncActionCreators<
  AppState,
  OrgTeam,
  OrgTeamListFilter,
  number
>('org.teams', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,

  getItemBeingCreated: (state: AppState) => state.org.teams.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) => state.org.teams.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) => state.org.teams.itemsBeingDeleted,

  async fetchHandler(state: AppState) {
    const orgId = state.identity!.orgId;
    const storeId = state.activeStoreId || undefined;
    return await orgTeamService.list(
      Object.assign({}, state.org.teams.filter, { orgId, storeId }),
      state.org.teams.sorts?.[0],
      state.org.teams.offset,
      state.org.teams.limit,
      { count: true, recursive: true },
    );
  },

  async create(team: Partial<OrgTeam>) {
    return await orgTeamService.create(team);
  },
  async update(team: OrgTeam) {
    return await orgTeamService.update(team);
  },
  async delete(team: OrgTeam) {
    await orgTeamService.delete(team.id);
  },
  async deleteMulti(teams: OrgTeam[]) {
    if (teams.length === 1) {
      await orgTeamService.delete(teams[0].id);
      return;
    }
    throw new Error('delete multi teams is not supported. ');
  },
});
