/**
 * @file: OldPartDisposalType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum OldPartDisposalType {
  None = 0,
  Keep,
  KeepForLook,
}

export const OldPartDisposalTypeValueSet = new Set([
  OldPartDisposalType.None,
  OldPartDisposalType.Keep,
  OldPartDisposalType.KeepForLook,
]);
