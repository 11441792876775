import {
  TemplateProperty,
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
} from './types';

export class ServiceFinishedTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  getOverriddenProperties(): TemplateProperty[] {
    return ['finishedAt'].map(x => ({
      property: x,
      label: `integration.templates.props.service_finished.${x}`,
    }));
  }

  getOverriddenExample(destType: TemplateMsgDestType) {
    if (destType === 'customer') {
      return {
        title: '车主您好，您的本次服务已完成，点击查看完整服务详情',
        status: '服务已完成',
        remark: '若对本次服务等有任何疑问，请联系门店服务顾问，感谢您的光临',
      };
    }
    return {
      title: '你所服务的车辆已完成全部服务，请及时跟进剩余工作事项',
      status: '服务已完成',
      remark: '点击查看完整服务详情',
    };
  }
}
