/**
 * @file: StoreService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { StoreConfiguration } from 'model';
import { StoreServiceBase } from './base/StoreService';

export class StoreService extends StoreServiceBase {
  async getConfiguration(
    storeId: number,
    selfOnly?: boolean,
  ): Promise<StoreConfiguration> {
    return await this.api
      .get()
      .url('/stores/:storeId/config', { storeId, selfOnly })
      .future();
  }

  async saveConfiguration(
    storeId: number,
    config: StoreConfiguration,
  ): Promise<void> {
    return void (await this.api
      .put()
      .url('/stores/:storeId/config', { storeId })
      .data(config)
      .future());
  }

  async deleteConfiguration(storeId: number): Promise<void> {
    await this.api
      .delete()
      .url('/stores/:storeId/config', { storeId })
      .future();
  }
}
