/**
 * @file: ProductAuthorizationContractStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ProductAuthorizationContractStatus } from 'model';

export const ProductAuthorizationContractStatusOptions: Array<
  Option<ProductAuthorizationContractStatus>
> = [
  {
    value: ProductAuthorizationContractStatus.Effective,
    label: 'product_authorization_contract_status.effective',
  },
  {
    value: ProductAuthorizationContractStatus.Ended,
    label: 'product_authorization_contract_status.ended',
  },
  {
    value: ProductAuthorizationContractStatus.Canceled,
    label: 'product_authorization_contract_status.canceled',
  },
  {
    value: ProductAuthorizationContractStatus.Closed,
    label: 'product_authorization_contract_status.closed',
  },
];

export const ProductAuthorizationContractStatusOptionsWithDefault: Array<
  Option<ProductAuthorizationContractStatus | null>
> = [
  { value: null, label: 'product_authorization_contract_status.__default__' },
  {
    value: ProductAuthorizationContractStatus.Effective,
    label: 'product_authorization_contract_status.effective',
  },
  {
    value: ProductAuthorizationContractStatus.Ended,
    label: 'product_authorization_contract_status.ended',
  },
  {
    value: ProductAuthorizationContractStatus.Canceled,
    label: 'product_authorization_contract_status.canceled',
  },
  {
    value: ProductAuthorizationContractStatus.Closed,
    label: 'product_authorization_contract_status.closed',
  },
];
