import { LinkButton } from 'app/inspection/quotation-template-config/LinkButton';
import { InlineSvg } from 'lib/components/InlineSvg';
import { memo } from 'react';
import { Translate } from 'react-localize-redux';

export const EmptySubjects = memo(
  ({ editable, onAdd }: { editable: boolean; onAdd?: () => void }) => {
    return (
      <div className={`quotation-tpl__subject-panel-empty`}>
        <InlineSvg src="img/empty-results.svg" />
        <span style={{ textAlign: 'center' }}>
          <Translate
            id={`quotation_tpl.subject.empty${editable ? '' : '_readonly'}`}
            data={{
              add_subject_btn: (
                <LinkButton onClick={onAdd}>
                  <Translate id="quotation_tpl.subject.btn.click_to_add_subject" />
                </LinkButton>
              ),
            }}
          />
        </span>
      </div>
    );
  },
);
