/**
 * @file: VehicleInspectionTaskJobMediaService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { VehicleInspectionTaskJobMediaServiceBase } from './base/VehicleInspectionTaskJobMediaService';

export class VehicleInspectionTaskJobMediaService extends VehicleInspectionTaskJobMediaServiceBase {
  // add custom service implementation here
}
