import React, { memo } from 'react';
import { Translate } from 'react-localize-redux';
import { useConstructionTemplateConfigContext } from './Context';
import { CategoryDetail } from './CategoryDetail';
import { TemplateDetail } from './TemplateDetail';

export const DetailView = memo(() => {
  const { templateConfig, selectedSidebarItem } =
    useConstructionTemplateConfigContext();

  if (selectedSidebarItem == null) {
    return (
      <div className="construction-tpl-man__detail construction-tpl-man__detail--no-content">
        <p>
          <Translate id="construction_tpl.select_category_tip" />
        </p>
      </div>
    );
  }

  if (selectedSidebarItem.type === 'category') {
    const category = templateConfig.categories.find(
      x => x.id === selectedSidebarItem.categoryId,
    );

    if (!category) {
      return null;
    }

    return <CategoryDetail category={category} />;
  }

  const template = templateConfig.templates.find(
    x => x.id === selectedSidebarItem.templateId,
  );

  if (template) {
    return <TemplateDetail template={template} />;
  }

  return null;
});
