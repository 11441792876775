/**
 * @file: OrgUserAclService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { OrgUserAclListFilter, OrgUserAcl, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class OrgUserAclServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<OrgUserAclListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<OrgUserAcl> : OrgUserAcl[]> {
    return await this.api.get()
      .url('/org-user-acls', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<OrgUserAcl | null> {
    return await this.api.get()
      .url('/org-user-acls/:id', { id })
      .future();
  }

  async create(orgUserAcl: Partial<OrgUserAcl>): Promise<OrgUserAcl> {
    return await this.api.post()
      .url('/org-user-acls')
      .data(orgUserAcl)
      .future();
  }

  async update(orgUserAcl: Partial<OrgUserAcl>): Promise<OrgUserAcl> {
    return await this.api.put()
      .url('/org-user-acls/:id', { id: orgUserAcl.id })
      .data(orgUserAcl)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/org-user-acls/:id', { id })
      .future();
  }
}