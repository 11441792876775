/**
 * @file: InspectionTaskEventDataTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Badge, BadgeProps } from 'lib/metronic/components';
import { InspectionTaskEventDataType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { LabelColors } from '../LabelColors';

interface Props extends BadgeProps {
  value: InspectionTaskEventDataType | null | undefined;
}

const Values = {
  [InspectionTaskEventDataType.None]: 'none',
  [InspectionTaskEventDataType.Text]: 'text',
  [InspectionTaskEventDataType.Json]: 'json',
  [InspectionTaskEventDataType.Xml]: 'xml',
  [InspectionTaskEventDataType.Plist]: 'plist',
  [InspectionTaskEventDataType.Csv]: 'csv',
  [InspectionTaskEventDataType.StringList]: 'string_list',
  [InspectionTaskEventDataType.MediaList]: 'media_list',
  [InspectionTaskEventDataType.QueryString]: 'query_string',
  [InspectionTaskEventDataType.Custom]: 'custom',
};

export const InspectionTaskEventDataTypeLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  let enumValue = '';
  if (value !== null && value !== undefined) {
    enumValue = Values[value];
  }

  const labelColor =
    value && LabelColors.InspectionTaskEventDataType?.[enumValue]
      ? LabelColors.InspectionTaskEventDataType[enumValue]
      : color;

  return (
    <Badge inline color={labelColor} {...otherProps}>
      <Translate id={`inspection_task_event_data_type.${enumValue}`} />
    </Badge>
  );
});
