import React from 'react';
import { Translate } from 'react-localize-redux';
import { CSSProperties } from 'styled-components';
import { formatTime } from 'utils';
import { storeActions } from '../duck/actions';
import { Store, StoreListFilter, AclObjectList, StoreMedia } from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  getString,
  StringLabel,
  OrgInfoView,
} from 'shared/components';

import './index.scss';
import { lbsService } from 'services';
import { StoreMediaEditor } from './MediaEditor';

interface Props extends EntityListProps<Store, StoreListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  Store,
  StoreListFilter,
  number,
  Props
>();

export const StoreList = componentClassBuilder
  .i18nPrefix('store')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/map/marker1.svg')
      .default,
  )
  .accessRights({
    readonly: AclObjectList.StoreReadonlyAccess,
    full: AclObjectList.StoreFullAccess,
  })
  .breadcrumbs([
    { text: <Translate id="org.breadcrumb.it" /> },
    { text: <Translate id="org.breadcrumb.stores" /> },
  ])
  .entities(state => state.org.stores)
  .actions(storeActions)
  .requireAreas()
  .editor(builder =>
    builder
      .text({
        prop: 'name',
        label: 'store.editor.label.name',
        placeholder: 'store.editor.placeholder.name',
      })
      .area({
        label: 'store.editor.label.city',
      })
      .text({
        prop: 'address',
        label: 'store.editor.label.address',
        placeholder: 'store.editor.placeholder.address',
        onBlur: async (_, props: Props) => {
          const entity =
            props.entities.itemBeingCreated || props.entities.itemBeingUpdated;
          if (
            !entity?.address ||
            (entity.latitude && entity.longitude)
          )
            return;
          try {
            const location = await lbsService.geocoding(entity.address);
            if (props.entities.itemBeingCreated) {
              props.dispatch(
                props.actions.itemBeingCreatedChanged!({
                  latitude: location.latitude,
                  longitude: location.longitude,
                }),
              );
            } else if (props.entities.itemBeingUpdated) {
              props.dispatch(
                props.actions.itemBeingUpdatedChanged!({
                  latitude: location.latitude,
                  longitude: location.longitude,
                }),
              );
            }
          } catch (e) {
            console.error('geocoding failed: ', e);
          }
        },
      })
      .text({
        type: 'number',
        prop: 'latitude',
        label: 'store.editor.label.latitude',
        placeholder: 'store.editor.placeholder.latitude',
      })
      .text({
        type: 'number',
        prop: 'longitude',
        label: 'store.editor.label.longitude',
        placeholder: 'store.editor.placeholder.longitude',
      })
      .custom({
        label: 'store.editor.label.medias',
        render: (props: Props) => {
          const entity =
            props.entities.itemBeingCreated || props.entities.itemBeingUpdated;
          const onChange = (medias: StoreMedia[]) => {
            if (props.entities.itemBeingCreated) {
              props.dispatch(
                props.actions.itemBeingCreatedChanged!({ medias }),
              );
            } else {
              props.dispatch(
                props.actions.itemBeingUpdatedChanged!({ medias }),
              );
            }
          };
          return (
            <StoreMediaEditor medias={entity?.medias} onChange={onChange} />
          );
        },
      })
      .text({
        prop: 'businessHours',
        label: 'store.editor.label.business_hours',
        placeholder: 'store.editor.placeholder.business_hours',
      })
      .text({
        prop: 'contactName',
        label: 'store.editor.label.contact.name',
        placeholder: 'store.editor.placeholder.contact.name',
      })
      .text({
        prop: 'contactPhone',
        label: 'store.editor.label.contact.phone',
        placeholder: 'store.editor.placeholder.contact.phone',
      })
      .text({
        prop: 'contactMobile',
        label: 'store.editor.label.contact.mobile',
        placeholder: 'store.editor.placeholder.contact.mobile',
      })
      .text({
        prop: 'contactFax',
        label: 'store.editor.label.contact.fax',
        placeholder: 'store.editor.placeholder.contact.fax',
      })
      .text({
        prop: 'contactEmail',
        label: 'store.editor.label.contact.email',
        placeholder: 'store.editor.placeholder.contact.email',
      })
      .text({
        prop: 'wifiSsid',
        label: 'store.editor.label.wifi_ssid',
        placeholder: 'store.editor.placeholder.wifi_ssid',
      })
      .text({
        prop: 'wifiPassword',
        label: 'store.editor.label.wifi_password',
        placeholder: 'store.editor.placeholder.wifi_password',
      })
      .textArea({
        prop: 'introduction',
        label: 'store.editor.label.introduction',
        placeholder: 'store.editor.placeholder.introduction',
        rows: 6,
      })
      .textArea({
        prop: 'remark',
        label: 'store.editor.label.remark',
        placeholder: 'store.editor.placeholder.remark',
      }),
  )
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'name',
        placeholder: 'store.toolbar.placeholder.name',
        width: 250,
      })
      .button({
        buttonType: 'search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(storeActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'logoImgUrl',
      width: 200,
      text: 'store.col.store',
      render: () => (
        <OrgInfoView>
          {org => (
            <img
              className="org-info__logo"
              style={{ width: 200 }}
              src={org.logoImgUrl || 'assets/img/org-placeholder.png'}
            />
          )}
        </OrgInfoView>
      ),
    },
    {
      prop: 'name',
      width: 200,
      text: 'col.name',
      render: store => {
        return (
          <div className="org-info__detail">
            <p>{store.name}</p>
            <OrgInfoView>
              {org => (
                <>
                  <dl>
                    <dt>
                      <Translate id="store.org_info.label.org_name" />
                    </dt>
                    <dd>
                      {org.name} ({org.shortName})
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <Translate id="store.org_info.label.brand_name" />
                    </dt>
                    <dd>{org.brandName}</dd>
                  </dl>
                </>
              )}
            </OrgInfoView>
          </div>
        );
      },
    },
    {
      prop: 'address',
      width: 200,
      text: 'col.address',
      render: (entity, props: Props) => {
        if (!entity.provinceId) return '-';
        if (!props.areas?.result || props.areas.isLoading) {
          return '...';
        }
        const province = props.areas.result.getNodeById(entity.provinceId);
        const city = props.areas.result.getNodeById(entity.cityId!);
        const district = props.areas.result.getNodeById(entity.districtId!);
        const parts: string[] = [];
        if (province.children.length > 1) {
          parts.push(province.area.name);
        }
        parts.push(city.area.name, district.area.name);
        return (
          <div>
            <p style={{ marginBottom: 4 }}>{parts.join('/')}</p>
            <p>{entity.address}</p>
          </div>
        );
      },
    },
    {
      prop: 'contact',
      width: 200,
      text: 'col.contact_name',
      render: entity => {
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            {entity.contactName && (
              <div style={style}>
                <StringLabel value="contact_label.name" />
                {entity.contactName}
              </div>
            )}
            {entity.contactPhone && (
              <div style={style}>
                <StringLabel value="contact_label.phone" />
                {entity.contactPhone}
              </div>
            )}
            {entity.contactMobile && (
              <div style={style}>
                <StringLabel value="contact_label.mobile" />
                {entity.contactMobile}
              </div>
            )}
            {entity.contactFax && (
              <div style={style}>
                <StringLabel value="contact_label.fax" />
                {entity.contactFax}
              </div>
            )}
            {entity.contactEmail && (
              <div style={style}>
                <StringLabel value="contact_label.email" />
                {entity.contactEmail}
              </div>
            )}
          </div>
        );
      },
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .addActionButtons(['edit'])
  .validate(entity => {
    const name = entity.name?.trim();

    if (!name) {
      throw new Error(getString('store.editor.error.name_required'));
    }

    if (!entity.provinceId || !entity.cityId || !entity.districtId) {
      throw new Error(getString('store.editor.error.city_required'));
    }

    if (!entity.address) {
      throw new Error(getString('store.editor.error.addr_required'));
    }

    if (!entity.latitude || !entity.longitude) {
      throw new Error(getString('store.editor.error.coords_required'));
    }
  })
  .getClass();
