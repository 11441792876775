/**
 * @file: OfficialAccountMenuItemMediaType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum OfficialAccountMenuItemMediaType {
  Text = 'text',
  Image = 'image',
  Voice = 'voice',
  Video = 'video',
  Music = 'music',
  News = 'news'
}

export const OfficialAccountMenuItemMediaTypeValueSet = new Set([
  OfficialAccountMenuItemMediaType.Text,
  OfficialAccountMenuItemMediaType.Image,
  OfficialAccountMenuItemMediaType.Voice,
  OfficialAccountMenuItemMediaType.Video,
  OfficialAccountMenuItemMediaType.Music,
  OfficialAccountMenuItemMediaType.News
]);