/**
 * @file: quotation-template-config copy.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */
import { AppState } from 'app';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { QuotationTemplateInfo } from 'model';
import { quotationTemplateConfigService } from 'services';

export const quotationTemplateConfigListActions = createListAsyncActionCreators<
  AppState,
  QuotationTemplateInfo,
  any,
  number
>('inspection.quotation-templates', {
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.quotationTemplates.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.quotationTemplates.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.quotationTemplates.itemsBeingDeleted,
  fetchHandler: () =>
    quotationTemplateConfigService.listQuotationTemplateConfigs(),
  delete: item => {
    return quotationTemplateConfigService.deleteQuotationTemplateConfig(
      item.storeId,
    );
  },
  deleteMulti: items => {
    if (items.length === 1) {
      return quotationTemplateConfigService.deleteQuotationTemplateConfig(
        items[0].storeId,
      );
    }
    throw new Error('Delete multiple items are not supported');
  },
});
