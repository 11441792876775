/**
 * @file: AreaTree.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AreaNode } from './AreaNode';

export class AreaTree {
  constructor(public nodes: AreaNode[], private readonly map: Map<number, AreaNode>) {}

  getNodeById(id: number): AreaNode {
    return this.map.get(id) as AreaNode;
  }
}
