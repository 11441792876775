/**
 * @file: StoreMediaService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { StoreMediaServiceBase } from './base/StoreMediaService';

export class StoreMediaService extends StoreMediaServiceBase {
  // add custom service implementation here
}
