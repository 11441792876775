import { TransFunction } from 'app';
import { AppContext } from 'app/AppContext';
import { AppState } from 'app/duck/states';
import classNames from 'classnames';
import { History } from 'history';
import { RouteViewProps } from 'lib';
import { InlineSvg } from 'lib/components';
import { withAcl } from 'lib/decorators/acl';
import { getItemSource } from 'lib/helpers';
import {
  Badge,
  BreadcrumbItem,
  Button,
  Column,
  DataTable,
  Page,
  Portlet,
} from 'lib/metronic/components';
import {
  AclObjectList,
  Identity,
  InspectionTemplatePredefinedType,
  InspectionTemplateSceneType,
  VehicleInspectionTemplate,
  VehicleInspectionTemplateListFilter,
} from 'model';
import { InspectionTemplateConf } from 'model/viewmodel/InspectionTemplateConf';
import React, { ChangeEvent, Component, MouseEvent } from 'react';
import {
  getTranslate,
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  authenticate,
  CommonEntityListProps,
  ConfirmDeleteModal,
  getString,
  InspectionTemplatePredefinedTypeLabel,
  InspectionTemplateSceneTypeLabel,
  ListToolbar,
  Restricted,
  ToolbarItemsBuilder,
} from 'shared/components';
import { arr2group, arr2map, loadAsyncList } from 'utils';
import {
  duplicateTemplate,
  inspectionTemplateActions,
  templateSourceChanged,
} from '../duck/actions';
import { InspectionTemplates } from '../duck/states';
import { TemplateEditor } from './editor';
import {
  InspectionTemplatePredefinedTypeList,
  PredefinedTemplateIcons,
} from './helpers';

type TemplateListColumn = Column<VehicleInspectionTemplate>;

import { hideAppLoading, showAppLoading, showAppModal } from 'app/duck/actions';
import { BarriersSettings } from 'app/inspection/templates/BarriersSettings';
import { memberActions } from 'app/org/duck/actions';
import { OrgMembers } from 'app/org/duck/states';
import moment from 'moment';
import { vehicleInspectionTemplateService } from 'services';
import { ItemSource, ItemSourceFilterList } from 'shared/types';
import './manager.scss';

export interface TemplatesManagerProps
  extends CommonEntityListProps,
    LocalizeContextProps {
  templates: InspectionTemplates;
  history: History;
  activeStorId?: number | null;
  members: OrgMembers;
}

function mapStateToProps(
  state: AppState,
  ownProps: RouteViewProps,
): Partial<TemplatesManagerProps> {
  return {
    history: ownProps.history,
    templates: state.inspection.templates,
    members: state.org.members,
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    activeStorId: state.activeStoreId,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

type State = {
  activeTemplateForBarriersSettings: VehicleInspectionTemplate | undefined;
};

@withAcl()
class InspectionTemplateManagerImpl extends Component<
  TemplatesManagerProps,
  State
> {
  state = {
    activeTemplateForBarriersSettings: undefined,
  };

  breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.templates_manager" /> },
  ];

  private columns: TemplateListColumn[] | undefined;

  componentDidMount() {
    this.loadTemplates();
  }

  componentDidUpdate(prevProps: TemplatesManagerProps) {
    if (this.props.activeStorId !== prevProps.activeStorId) {
      const { dispatch } = this.props;
      dispatch(inspectionTemplateActions.clearSelection());
    }
  }

  render() {
    const { trans, templates } = this.props;
    return (
      <Page
        title={trans('inspection_template.manager.title')}
        fullAccessRight={AclObjectList.VehicleInspectionTemplateFullAccess}
        readonlyAccessRight={
          AclObjectList.VehicleInspectionTemplateReadonlyAccess
        }
        error={templates.error}
        className="template-manager"
      >
        <Page.Header>
          <Page.Header.Main>
            <Page.Breadcrumb items={this.breadcrumbs} />
          </Page.Header.Main>
          <Page.Header.Toolbar>{this.renderToolbar()}</Page.Header.Toolbar>
        </Page.Header>
        <Page.Content>
          <Portlet mobile>
            <Portlet.Body>
              {this.renderPredefinedTemplatesPreview()}
              {this.renderDataList()}
              {this.renderSidebar()}
              {this.renderConfirmDeleteModal()}
              {this.renderBarriersSettingsSidebar()}
            </Portlet.Body>
          </Portlet>
        </Page.Content>
      </Page>
    );
  }

  renderToolbar() {
    const { dispatch, templates } = this.props;
    const builder =
      new ToolbarItemsBuilder<VehicleInspectionTemplateListFilter>();
    const activeSource = templates.source;
    builder
      .buttonGroup(group => {
        group.withPlacement('right').small();
        for (const source of ItemSourceFilterList) {
          group.button({
            key: source,
            text: `item_source.${source}`,
            // color: activeSource === source ? 'brand' : 'secondary',
            outline: activeSource !== source,
            color: 'primary',
            context: source,
            onClick: this.onSourceClick,
          });
        }
      })
      .button({
        placement: 'right',
        buttonType: 'refresh',
        onClick: this.onRefresh,
      })
      .button({
        placement: 'right',
        buttonType: 'add',
        onClick: () => {
          dispatch(inspectionTemplateActions.itemBeingCreated({}));
        },
      })
      .button({
        placement: 'right',
        iconOnly: true,
        size: 'small',
        cls: 'btn-import-template',
        file: true,
        accepts: ['application/json'],
        onFileChange: this.onImportTemplates,
        text: (
          <i
            className="la la-upload"
            data-toggle="tooltip"
            title={getString(
              'inspection_template.toolbar.button.import_template',
            )}
          />
        ),
      });
    return (
      <ListToolbar<VehicleInspectionTemplateListFilter>
        filter={templates.filter || {}}
        items={builder.build()}
        onFilterChange={this.onListFilterChange}
      />
    );
  }

  onSourceClick = (_: any, source: ItemSource) => {
    const { dispatch } = this.props;
    dispatch(templateSourceChanged(source));
  };

  onListFilterChange = () => {
    /* noop */
  };

  renderSidebar() {
    const { dispatch, templates } = this.props;
    return (
      <TemplateEditor
        entities={templates}
        actions={inspectionTemplateActions}
        dispatch={dispatch}
        extra={{ props: this.props }}
      />
    );
  }

  renderConfirmDeleteModal() {
    const { templates } = this.props;
    return (
      <ConfirmDeleteModal
        localeSegment={'inspection_template'}
        isOpen={Boolean(templates.itemsBeingDeleted?.[0])}
        isDeleting={templates.isDeleting}
        error={templates.lastDeleteError}
        onConfirm={this.onConfirmDelete}
        onCancel={this.onCancelDelete}
      />
    );
  }

  renderBarriersSettingsSidebar() {
    return (
      <BarriersSettings
        template={this.state.activeTemplateForBarriersSettings}
        onClose={this.onBarriersSettingsClose}
      />
    );
  }

  renderPredefinedTemplatesPreview() {
    const { templates } = this.props;
    const groups = arr2group(
      templates.result || [],
      x => x.predefinedType || InspectionTemplatePredefinedType.Other,
    );
    const map: { [type: string]: VehicleInspectionTemplate[] } = arr2map(
      groups,
      x => x.key,
      x => x.items,
    );
    const currentType = templates.filter?.predefinedType?.[0];

    return (
      <div className="predefined-templates-preview">
        {InspectionTemplatePredefinedTypeList.map(type => (
          <div
            className={classNames('predefined-templates-preview__item', {
              'predefined-templates-preview__item--active':
                currentType === type,
            })}
            key={type}
          >
            <div
              className="predefined-templates-preview__item-top"
              onClick={this.onPredefinedTypeClick(type)}
            >
              <div className="predefined-templates-preview__icon">
                <InlineSvg src={PredefinedTemplateIcons[type]} />
              </div>
              <div className="predefined-templates-preview__label">
                <Translate id={`inspection_template_predefined_type.${type}`} />
              </div>
            </div>
            <div className="predefined-templates-preview__info">
              <span className="predefined-templates-preview__count">
                <Translate
                  id="inspection_template.list.preview.count_label"
                  data={{ count: (map[type] || []).length }}
                />
              </span>
              <Restricted
                rights={AclObjectList.VehicleInspectionTemplateFullAccess}
                silent
              >
                <a
                  href="#"
                  onClick={this.onAddForPredefinedType(type)}
                  className="predefined-templates-preview__action-link"
                >
                  <i className="la la-plus" />
                  <Translate id="inspection_template.list.preview.add_button" />
                </a>
              </Restricted>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderDataList() {
    return (
      <AppContext.Consumer>
        {({ identity }) => {
          const { templates, activeStorId } = this.props;
          const filter = templates.filter || {};
          let data = templates.result;
          if (data && activeStorId) {
            data = data.filter(x => x.storeId === activeStorId);
          }
          if (data && filter.predefinedType?.length) {
            const predefinedType = filter.predefinedType[0];
            data = data.filter(x => x.predefinedType === predefinedType);
          }
          if (data && templates.source !== 'all') {
            data = data.filter(x => getItemSource(x) === templates.source);
          }

          const columns = this.buildColumns(identity);
          return (
            <DataTable<VehicleInspectionTemplate, number>
              columns={columns}
              idProp="id"
              selModel="none"
              data={data}
              isLoading={templates.isLoading}
              minHeight={400}
              selection={templates.selection}
            />
          );
        }}
      </AppContext.Consumer>
    );
  }

  onRefresh = () => {
    this.loadTemplates(true);
  };

  onBarriersSettingsClose = () => {
    this.setState({ activeTemplateForBarriersSettings: undefined });
  };

  onAdd = (predefinedType?: InspectionTemplatePredefinedType) => {
    const { dispatch, templates } = this.props;
    if (!predefinedType && templates.result) {
      const fullInspectionTemplate = templates.result.find(
        x =>
          x.predefinedType ===
            InspectionTemplatePredefinedType.FullInspection && !x.disabled,
      );
      if (!fullInspectionTemplate) {
        predefinedType = InspectionTemplatePredefinedType.FullInspection;
      }
    }

    let storeId: number | undefined = undefined;
    const { identity } = this.context as React.ContextType<typeof AppContext>;
    if (identity.storeId && identity.visibleStores.length === 1) {
      storeId = identity.storeId;
    }

    dispatch(
      inspectionTemplateActions.itemBeingCreated({
        storeId,
        predefinedType:
          predefinedType || InspectionTemplatePredefinedType.Other,
        sceneType:
          predefinedType === InspectionTemplatePredefinedType.FullInspection
            ? InspectionTemplateSceneType.Full
            : InspectionTemplateSceneType.Custom,
      }),
    );
  };

  onAddForPredefinedType(predefinedType: InspectionTemplatePredefinedType) {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      this.onAdd(predefinedType);
    };
  }

  onPredefinedTypeClick(predefinedType: InspectionTemplatePredefinedType) {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const { dispatch } = this.props;
      dispatch(
        inspectionTemplateActions.updateFilter({
          predefinedType: [predefinedType],
        }),
      );
    };
  }

  onConfirmDelete = () => {
    const { dispatch } = this.props;
    dispatch(inspectionTemplateActions.commitItemsBeingDeleted());
  };

  onCancelDelete = () => {
    const { dispatch } = this.props;
    dispatch(inspectionTemplateActions.cancelItemsBeingDeleted());
  };

  onImportTemplates = async (e: ChangeEvent<HTMLInputElement>) => {
    const accepts = ['application/json'];
    const file = this.acceptFile(e, accepts, 'invalid_file');
    if (!file) return;
    const { dispatch } = this.props;
    dispatch(
      showAppLoading({
        message: getString('inspection_template.manager.importing'),
        status: 'loading',
      }),
    );
    try {
      await vehicleInspectionTemplateService.importTemplates(
        this.props.activeStorId,
        file,
      );
      dispatch(inspectionTemplateActions.invalidate(true));
      dispatch(
        showAppLoading({
          message: getString('inspection_template.manager.import_success'),
          status: 'success',
          timeout: 2000,
        }),
      );
    } catch (error) {
      console.error(error);
      dispatch(hideAppLoading());
      dispatch(
        showAppModal(
          getString('inspection_template.modal.import_error.title'),
          getString('inspection_template.modal.import_error.msg'),
        ),
      );
    }
  };

  acceptFile(
    e: ChangeEvent<HTMLInputElement>,
    accepts: string[],
    error: string,
  ): File | false {
    if (!e.target.files?.length) return false;
    const file = e.target.files[0];
    if (!accepts.includes(file.type)) {
      alert(getString(`inspection_template.manager.${error}`));
      return false;
    }
    return file;
  }

  loadTemplates(force?: boolean) {
    const { dispatch, templates, members } = this.props;
    loadAsyncList(
      templates,
      () => dispatch(inspectionTemplateActions.fetch()),
      force,
    );
    loadAsyncList(members, () => dispatch(memberActions.fetch()), force);
  }

  async exportTemplates(templates: VehicleInspectionTemplate[]) {
    const ids = templates.map(x => x.id);
    const url = vehicleInspectionTemplateService.getExportTemplatesUrl(ids);
    console.log('download url is: %s', url);
    const link = document.createElement('a');
    const time = moment().format('YYYYMMDD');
    link.download =
      templates.length === 1
        ? `${templates[0].name}-exported-${time}.json`
        : `exported-templates-${time}.json`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  buildColumns(identity: Identity): TemplateListColumn[] {
    if (this.columns) return this.columns;
    const { trans } = this.props;
    const columns: TemplateListColumn[] = [
      {
        prop: 'name',
        text: trans('inspection_template.col.name'),
        width: 100,
      },
      {
        prop: 'source',
        text: trans('inspection_template.col.source'),
        width: 50,
        align: 'center',
        render: site => {
          const source = getItemSource(site);
          return (
            <Badge
              inline
              color={
                source === 'system'
                  ? 'danger'
                  : source === 'agent'
                    ? 'warning'
                    : source === 'org'
                      ? 'info'
                      : 'dark'
              }
              style={{ whiteSpace: 'nowrap' }}
            >
              <Translate id={`inspection_site.source.${source}`} />
            </Badge>
          );
        },
      },
      {
        prop: 'sceneType',
        text: trans('inspection_template.col.scene_type'),
        width: 100,
        render: ({ sceneType }) => {
          return <InspectionTemplateSceneTypeLabel value={sceneType} />;
        },
      },
      {
        prop: 'predefinedType',
        text: trans('inspection_template.col.predefined_type'),
        width: 80,
        render: ({ predefinedType }) => {
          return (
            <InspectionTemplatePredefinedTypeLabel value={predefinedType} />
          );
        },
      },
      {
        prop: 'isSystemDefault',
        text: trans('inspection_template.col.is_system_default'),
        width: 60,
        align: 'center',
        render: ({ isSystemDefault }) => {
          return isSystemDefault ? (
            <Badge color="success">
              <Translate id="inspection_template.label.default" />
            </Badge>
          ) : (
            '/'
          );
        },
      },
      {
        prop: 'description',
        text: trans('inspection_template.col.description'),
        width: 100,
        render: ({ description }) => description || '/',
      },
      {
        prop: 'enabled',
        text: trans('inspection_template.col.enabled'),
        width: 40,
        align: 'center',
        render: ({ disabled }) => {
          return (
            <i
              className={classNames('la la-check-circle', {
                'kt-font-success': !disabled,
                'kt-font-metal': disabled,
              })}
              style={{ fontSize: '1.4rem' }}
            />
          );
        },
      },
      {
        prop: 'conf',
        text: trans('inspection_template.col.conf'),
        width: 100,
        render: site => {
          const conf = site.conf
            ? (JSON.parse(site.conf) as InspectionTemplateConf)
            : null;
          if (!conf?.categories.length) {
            return (
              <span className="kt-font-warning">
                <Translate id="inspection_template.label.no_conf" />
              </span>
            );
          }
          return (
            <Translate
              id="inspection_template.label.conf"
              data={{
                categoryCount: conf.categories.length,
                siteCount: conf.categories.reduce((res, c) => {
                  res += c.groups.reduce((x, g) => {
                    x += g.siteIds.length;
                    return x;
                  }, 0);
                  return res;
                }, 0),
              }}
            />
          );
        },
      },
    ];
    this.addActionButtons(identity, columns);
    this.columns = columns;
    return columns;
  }

  addActionButtons(identity: Identity, columns: TemplateListColumn[]) {
    const fullAccessRight = AclObjectList.VehicleInspectionSiteFullAccess;
    if (!identity.hasAccessRights(fullAccessRight)) {
      columns.push({
        prop: 'actions',
        text: <Translate id="col.actions" />,
        align: 'center',
        width: 135,
        render: (template: VehicleInspectionTemplate) => {
          const onViewDetail = (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            const { history } = this.props;
            setTimeout(() => {
              history.push('/inspection/templates/detail?id=' + template.id);
            }, 0);
          };
          return (
            <>
              <Button
                size="small"
                clean
                iconOnly
                onClick={onViewDetail}
                data-toggle="tooltip"
                data-container=".template-manager"
                title={getString('inspection_template.tooltip.view_detail')}
              >
                <i className="la la-eye" />
              </Button>
            </>
          );
        },
      });
      return;
    }

    columns.push({
      prop: 'actions',
      text: <Translate id="col.actions" />,
      align: 'center',
      width: 215,
      render: (template: VehicleInspectionTemplate) => {
        const onEdit = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(inspectionTemplateActions.itemBeingUpdated(template));
        };
        const onDelete = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(inspectionTemplateActions.itemsBeingDeleted([template]));
        };
        const onConfig = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { history } = this.props;
          setTimeout(() => {
            history.push('/inspection/templates/detail?id=' + template.id);
          }, 0);
        };
        const onDuplicate = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(
            duplicateTemplate({
              ...template,
              name: `${template.name}-${getString(
                'inspection_template.label.clone_suffix',
              )}`,
            }),
          );
        };
        const onExport = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          void this.exportTemplates([template]);
        };
        const onBarriersSettings = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          this.setState({
            activeTemplateForBarriersSettings: template,
          });
        };

        const hasPermission = Boolean(
          template.orgId &&
            ((template.storeId &&
              identity.visibleStoreSet.has(template.storeId)) ||
              (!template.storeId && identity.userInfo.isOrgRootUser)),
        );

        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <Button
              size="small"
              clean
              iconOnly
              onClick={onEdit}
              data-toggle={hasPermission ? 'tooltip' : ''}
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.edit')}
              disabled={!hasPermission}
            >
              <i className="la la-edit" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              onClick={onConfig}
              data-toggle={hasPermission ? 'tooltip' : ''}
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.config')}
              disabled={!hasPermission}
            >
              <i className="la la-cog" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              onClick={onBarriersSettings}
              data-toggle={hasPermission ? 'tooltip' : ''}
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.barriers')}
              disabled={template.storeId != null}
            >
              <i className="flaticon-eye" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              onClick={onDuplicate}
              data-toggle={'tooltip'}
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.duplicate')}
            >
              <i className="la la-copy" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              onClick={onExport}
              data-toggle={hasPermission ? 'tooltip' : ''}
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.export')}
              disabled={!hasPermission}
            >
              <i className="la la-download" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              onClick={onDelete}
              data-toggle={hasPermission ? 'tooltip' : ''}
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.delete')}
              disabled={!hasPermission}
            >
              <i className="la la-trash" />
            </Button>
          </div>
        );
      },
    });
  }
}

export const InspectionTemplateManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<TemplatesManagerProps, InspectionTemplateManagerImpl>(
    withLocalize<TemplatesManagerProps>(InspectionTemplateManagerImpl) as any,
  ),
);
