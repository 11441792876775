/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  AsyncListState,
  AsyncObjectMapState,
  AsyncState,
} from 'lib/duck/interfaces';
import {
  Customer,
  CustomerListFilter,
  DatePartitionType,
  DateRange,
  OrgSubscribedUser,
  OrgSubscribedUserListFilter,
  StatItem,
  StoreSummaryStats,
  VehicleInspectionComment,
  VehicleInspectionCommentListFilter,
  VehicleInspectionTask,
  VehicleInspectionTaskListFilter,
  VehicleReceptionOrder,
  VehicleReceptionOrderListFilter,
  VehicleReport,
  VehicleReportFilter,
} from 'model';

export enum StatAspect {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Customer = 'customer',
  SubscribedUser = 'user',
  ReceptionOrder = 'reception-order',
  InspectionTask = 'inspection-task',
  InspectionReport = 'inspection-report',
  InspectionComment = 'inspection-comment',
}

export interface SummaryStatsState extends AsyncState<StoreSummaryStats> {}

export interface StatsStateByTime
  extends AsyncObjectMapState<DatePartitionType, StatItem[]> {
  partition: DatePartitionType;
}

export interface RecentSubscribedUsers
  extends AsyncListState<
    OrgSubscribedUser,
    OrgSubscribedUserListFilter,
    number
  > {}

export interface RecentCustomers
  extends AsyncListState<Customer, CustomerListFilter, number> {}

export interface RecentReceptionOrders
  extends AsyncListState<
    VehicleReceptionOrder,
    VehicleReceptionOrderListFilter,
    number
  > {}

export interface RecentInspectionTasks
  extends AsyncListState<
    VehicleInspectionTask,
    VehicleInspectionTaskListFilter,
    number
  > {}

export interface RecentInspectionReports
  extends AsyncListState<VehicleReport, VehicleReportFilter, number> {}

export interface RecentInspectionComments
  extends AsyncListState<
    VehicleInspectionComment,
    VehicleInspectionCommentListFilter,
    number
  > {}

export interface DashboardState {
  // date range and current stats item options state.
  dateRange: DateRange;
  activeStatAspect: StatAspect;

  // summary stats
  summaryStats: SummaryStatsState;
  companionSummaryStats: SummaryStatsState; // for chain relative (or year on year) ratio

  // detailed stats by time
  customerStatsByTime: StatsStateByTime;
  userStatsByTime: StatsStateByTime;
  receptionOrderStatsByTime: StatsStateByTime;
  inspectionTaskStatsByTime: StatsStateByTime;
  inspectionReportStatsByTime: StatsStateByTime;
  inspectionCommentStatsByTime: StatsStateByTime;

  // recent stuffs
  recentCustomers: RecentCustomers;
  recentSubscribedUsers: RecentSubscribedUsers;
  recentReceptionOrders: RecentReceptionOrders;
  recentInspectionTasks: RecentInspectionTasks;
  recentInspectionReports: RecentInspectionReports;
  recentInspectionComments: RecentInspectionComments;
}
