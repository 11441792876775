import {
  TemplateProperty,
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
} from './types';

export class PreInspectionFinishedTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  getOverriddenProperties(): TemplateProperty[] {
    return ['inspectionResult', 'inspectedAt', 'inspectionTechnicianName'].map(
      x => ({
        property: x,
        label: `integration.templates.props.inspection_finished.${x}`,
      }),
    );
  }

  getOverriddenExample(destType: TemplateMsgDestType) {
    if (destType === 'customer') {
      return {
        title: '尊敬的车主，您的爱车已完成进店预检，请点击查看预检报告详情',
        status: '检测已完成',
        remark: '若对预检报告等有任何疑问，请联系门店服务顾问',
      };
    }
    return {
      title: '您所服务的车辆已完成进店预检工作，请及时跟进服务进度',
      status: '检测已完成',
      remark: '点击进入查看预检报告详情',
    };
  }
}
