import {
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
  TemplateProperty,
} from './types';

export class CustomerPostedCommentTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  get supportedDestTypes(): TemplateMsgDestType[] {
    return ['service_agent'];
  }

  getOverriddenProperties(): TemplateProperty[] {
    return [
      'serviceSubjects',
      'storeName',
      'serviceDate',
      'score',
      'comment',
      'orderNo',
    ].map(x => ({
      property: x,
      label: `integration.templates.props.service_comment_remind.${x}`,
    }));
  }

  getOverriddenExample() {
    return {
      orderNo: '京QXXXXX【服务顾问: 张三】',
      title: '尊敬的京QXXXXX的车主对本次服务进行了评价!',
      remark:
        '服务态度10分/服务效率10分/专业程度10分/满意度10分 【体验非常棒】',
    };
  }
}
