import React, { PureComponent } from 'react';
import { HtmlEditor } from 'shared/components/HtmlEditor';
import { FormElementHtml } from '../types';

interface Props<T> {
  element: FormElementHtml<T>;
  disabled?: boolean;
  value: any;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormHtmlEditor<T> extends PureComponent<Props<T>> {
  render() {
    const { value, disabled } = this.props;
    return (
      <div>
        <HtmlEditor
          value={value || ''}
          disabled={disabled}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
      </div>
    );
  }

  onBlur = () => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    element.onBlur?.(extra);
  };

  onChange = (value: string) => {
    const { element, onChange, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = value as any;
    const res = element.onChange && element.onChange(changes, extra);
    if (!res) {
      onChange(changes);
    }
  };
}
