import { TransFunction } from 'app';
import { AppContext } from 'app/AppContext';
import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import { ObjectKeyType } from 'lib/duck/interfaces';
import { $has } from 'lib/helpers';
import {
  Button,
  Column,
  DataListGroup,
  DataTable,
  Dropdown,
  Nav,
  Page,
  Portlet,
} from 'lib/metronic/components';
import { Identity, ReactCtor } from 'model';
import { Component, MouseEvent, ReactNode } from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ConfirmDeleteModal } from '../ConfirmDeleteModal';
import { EntityEditorForm } from '../EntityEditorForm';
import {
  EntityEditorSidebar,
  EntityEditorSidebarProps,
} from '../EntityEditorSidebar';
import { ListToolbar } from '../ListToolbar';
import { Restricted } from '../Restricted';
import { getString } from '../StringLabel';
import {
  ActionButtonInfo,
  CommonEntityListProps,
  EntityListProps,
} from './types';

export function mapStateToPropsHelper<TProps extends CommonEntityListProps>(
  state: AppState,
  props: Omit<TProps, 'trans' | 'translate' | 'dispatch' | 'identity' | '$has'>,
): Partial<TProps> {
  return {
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    ...props,
  } as TProps;
}

export function mapDispatchToPropsHelper<TProps extends CommonEntityListProps>(
  dispatch: ThunkDispatch<AppState, any, any>,
): Partial<TProps> {
  return { dispatch } as any;
}

export class EntityList<
  T extends object,
  TFilter,
  TKey extends ObjectKeyType = number,
> extends Component<EntityListProps<T, TFilter, TKey>> {
  private readonly columns: Array<Column<T>> = [];

  constructor(props: EntityListProps<T, TFilter, TKey>) {
    super(props);
    this.columns = props.columns.map(column => ({
      ...column,
      text:
        typeof column.text === 'string' && column.text
          ? props.trans(column.text)
          : column.text,
    }));
    this.addActionButtons();
  }

  addActionButtons() {
    if (!this.props.actionButtons?.length) {
      return;
    }

    const { canEdit, canDelete, shouldDisableActionButton } = this.props;

    this.columns.push({
      prop: 'actions',
      text: <Translate id="col.actions" />,
      align: 'center',
      width: Math.max(48, this.props.actionButtons.length * 24),
      render: (item: T) => {
        const onEdit = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          e.stopPropagation();
          this.onEdit(item);
        };
        const onDelete = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          e.stopPropagation();
          this.props.actions.itemsBeingDeleted &&
            this.props.dispatch(this.props.actions.itemsBeingDeleted([item]));
        };
        const onClick = (actionButton: ActionButtonInfo<T>) => {
          return (e: MouseEvent<HTMLElement>) => {
            // tslint:disable-next-line: no-shadowed-variable
            const extra = this.onGetExtraInfo();
            e.preventDefault();
            e.stopPropagation();
            actionButton.onClick && actionButton.onClick(item, extra);
          };
        };

        const extra = this.onGetExtraInfo();

        return (
          <div className="kt-datatable__action-buttons">
            {this.props.actionButtons!.map(actionButton =>
              (() => {
                if (actionButton === 'edit') {
                  const disabled = canEdit
                    ? !canEdit(item, extra)
                    : shouldDisableActionButton
                      ? shouldDisableActionButton(actionButton, item, extra)
                      : undefined;
                  return (
                    this.props.fullAccessRight && (
                      <Restricted
                        rights={this.props.fullAccessRight}
                        key="__edit"
                      >
                        <Button
                          size="small"
                          clean
                          iconOnly
                          disabled={disabled}
                          onClick={onEdit}
                        >
                          <i className="la la-edit" />
                        </Button>
                      </Restricted>
                    )
                  );
                }
                if (actionButton === 'remove') {
                  const disabled = canDelete
                    ? !canDelete(item, extra)
                    : shouldDisableActionButton
                      ? shouldDisableActionButton(actionButton, item, extra)
                      : undefined;
                  return (
                    this.props.fullAccessRight && (
                      <Restricted
                        rights={this.props.fullAccessRight}
                        key="__remove"
                      >
                        <Button
                          size="small"
                          clean
                          iconOnly
                          disabled={disabled}
                          onClick={onDelete}
                        >
                          <i className="la la-trash" />
                        </Button>
                      </Restricted>
                    )
                  );
                }

                const shouldDisable =
                  typeof actionButton.disabled === 'boolean'
                    ? actionButton.disabled
                    : typeof actionButton.disabled === 'function'
                      ? actionButton.disabled(item, extra)
                      : shouldDisableActionButton
                        ? shouldDisableActionButton(actionButton, item, extra)
                        : undefined;

                return (
                  <Restricted
                    rights={actionButton.rights || []}
                    key={actionButton.key}
                  >
                    <Button
                      size="small"
                      clean
                      iconOnly
                      onClick={onClick(actionButton)}
                      data-toggle={actionButton.tooltip ? 'tooltip' : ''}
                      title={
                        actionButton.tooltip && getString(actionButton.tooltip)
                      }
                      disabled={shouldDisable}
                    >
                      {actionButton.icon ? (
                        <i className={actionButton.icon} />
                      ) : null}
                      {actionButton.text && getString(actionButton.text)}
                    </Button>
                  </Restricted>
                );
              })(),
            )}
          </div>
        );
      },
    });
  }

  render() {
    const {
      trans,
      pageTitle,
      subTitle,
      pageIcon,
      i18nPrefix,
      idProp,
      selModel,
      entities,
      noPaging,
      maxPagerItems,
      breadcrumbs,
      toolbarItems,
      toolbar,
      listOnly,
      emptyPlaceholder,
      rowClickable,
      fullAccessRight,
      readonlyAccessRight,
      onValidate,
      onRender,
      onRowClick,
      treeBuilder,
      onFilter,
    } = this.props;

    const extra = this.onGetExtraInfo();
    const items =
      entities.result && onFilter
        ? onFilter(entities.result, this.props)
        : entities.result;

    const EntityEditorSidebarType: ReactCtor<
      EntityEditorSidebarProps<T>,
      any
    > = EntityEditorSidebar;

    if (listOnly) {
      return (
        <AppContext.Consumer>
          {({ identity }) => (
            <div>
              <DataTable<T, TKey>
                columns={this.getColumns(identity)}
                idProp={idProp || ('id' as any)}
                selModel={selModel}
                emptyPlaceholder={emptyPlaceholder}
                rowClickable={rowClickable}
                data={items}
                treeBuilder={treeBuilder}
                isLoading={entities.isLoading}
                minHeight={400}
                offset={entities.offset}
                limit={entities.limit}
                total={noPaging !== true ? entities.total : 0}
                selection={entities.selection}
                maxPagerItems={maxPagerItems || 5}
                {...this.getListGroupProps()}
                onToggleAllSelection={this.onToggleAllSelection}
                onItemSelect={this.onSelectChange}
                onOffsetChange={this.onOffsetChange}
                onLimitChange={this.onLimitChange}
                onGetExtraInfo={this.onGetExtraInfo}
                onRowClick={onRowClick}
                onListItemNodeExpand={this.onListItemNodeExpand}
                onListItemNodeCollapse={this.onListItemNodeCollapse}
                onGetRowTag={this.props.onGetRowTag}
              />
              <EntityEditorSidebarType
                values={entities}
                extra={extra}
                title={`${i18nPrefix}.editor`}
                error={entities.createError || entities.lastUpdateError}
                onSave={this.onSave}
                onCancel={this.onCancel}
                onValidate={onValidate}
              >
                {(entity: T | Partial<T>) => this.renderEntityEditor(entity)}
              </EntityEditorSidebarType>
              {this.props.features?.deleteEntity !== false && (
                <ConfirmDeleteModal
                  localeSegment={i18nPrefix}
                  isOpen={Boolean(
                    entities.itemsBeingDeleted && entities.itemsBeingDeleted[0],
                  )}
                  isDeleting={entities.isDeleting}
                  error={
                    entities.lastDeleteError ||
                    (entities.itemsBeingDeleted &&
                      entities.deleteStatus?.[
                        entities.itemsBeingDeleted![0][idProp] as any
                      ]?.error)
                  }
                  onConfirm={this.onCommitItemBeingDeleted}
                  onCancel={this.onCancelBeingDeleted}
                />
              )}
              {onRender && onRender(this.props)}
            </div>
          )}
        </AppContext.Consumer>
      );
    }

    return (
      <AppContext.Consumer>
        {({ identity }) => (
          <Page
            title={trans(pageTitle)}
            fullAccessRight={fullAccessRight}
            readonlyAccessRight={readonlyAccessRight}
            error={
              entities.error || entities.lastUpdateError || entities.createError
            }
            // onAdd={!features || features.addEntity !== false ? this.onAdd : undefined}
          >
            <Page.Header>
              <Page.Header.Main>
                {breadcrumbs && <Page.Breadcrumb items={breadcrumbs} />}
              </Page.Header.Main>
              <Page.Header.Toolbar>
                {toolbar ? (
                  toolbar(extra)
                ) : (
                  <ListToolbar<TFilter, TKey>
                    items={
                      toolbarItems
                        ? typeof toolbarItems === 'function'
                          ? toolbarItems()
                          : toolbarItems
                        : null
                    }
                    filter={entities.filter || {}}
                    selection={entities.selection || []}
                    onGetExtraInfo={this.onGetExtraInfo}
                    onFilterChange={this.onFilterChange}
                    onAdd={this.onAdd}
                    canAdd={this.onCanAdd}
                  />
                )}
              </Page.Header.Toolbar>
            </Page.Header>
            <Page.Content>
              <Portlet mobile>
                <Portlet.Header
                  size="large"
                  title={trans(pageTitle)}
                  subTitle={
                    typeof subTitle === 'string'
                      ? getString(subTitle)
                      : subTitle
                  }
                  icon={pageIcon}
                  iconColor="brand"
                  onRefresh={this.onRefresh}
                >
                  <Dropdown
                    iconOnly
                    inline
                    clean
                    size="small"
                    buttonContents={<i className="flaticon-more-1" />}
                    showToggleButton={false}
                    dropdownProps={{ placement: 'bottom-end' }}
                  >
                    <Nav>
                      <Nav.Item
                        icon="flaticon-refresh"
                        text={getString('@string/nav_item_refresh_list')}
                        onClick={this.onRefresh}
                      />
                    </Nav>
                  </Dropdown>
                </Portlet.Header>
                <Portlet.Body>
                  <DataTable<T, TKey>
                    columns={this.getColumns(identity)}
                    idProp={idProp || ('id' as any)}
                    selModel={selModel}
                    emptyPlaceholder={emptyPlaceholder}
                    rowClickable={rowClickable}
                    data={items}
                    treeBuilder={treeBuilder}
                    isLoading={entities.isLoading}
                    minHeight={400}
                    offset={entities.offset}
                    limit={entities.limit}
                    total={noPaging !== true ? entities.total : 0}
                    selection={entities.selection}
                    maxPagerItems={maxPagerItems || 5}
                    {...this.getListGroupProps()}
                    onToggleAllSelection={this.onToggleAllSelection}
                    onItemSelect={this.onSelectChange}
                    onOffsetChange={this.onOffsetChange}
                    onLimitChange={this.onLimitChange}
                    onGetExtraInfo={this.onGetExtraInfo}
                    onRowClick={onRowClick}
                    onListItemNodeExpand={this.onListItemNodeExpand}
                    onListItemNodeCollapse={this.onListItemNodeCollapse}
                    onGetRowTag={this.props.onGetRowTag}
                  />
                </Portlet.Body>
              </Portlet>
              <EntityEditorSidebarType
                values={entities}
                extra={extra}
                title={`${i18nPrefix}.editor`}
                error={entities.createError || entities.lastUpdateError}
                width={this.props.editorWidth}
                onSave={this.onSave}
                onCancel={this.onCancel}
                onValidate={onValidate}
              >
                {(entity: T | Partial<T>) => this.renderEntityEditor(entity)}
              </EntityEditorSidebarType>
              {this.props.features?.deleteEntity !== false && (
                <ConfirmDeleteModal
                  localeSegment={i18nPrefix}
                  isOpen={Boolean(
                    entities.itemsBeingDeleted && entities.itemsBeingDeleted[0],
                  )}
                  isDeleting={entities.isDeleting}
                  error={entities.lastDeleteError}
                  onConfirm={this.onCommitItemBeingDeleted}
                  onCancel={this.onCancelBeingDeleted}
                />
              )}
              {onRender && onRender(this.props)}
            </Page.Content>
          </Page>
        )}
      </AppContext.Consumer>
    );
  }

  renderEntityEditor(entity: T | Partial<T>): ReactNode | null {
    const {
      editorFormElements,
      editorFormAutocompletion,
      editorFormUseUncontrolled,
      editorHelpTextPlacement,
    } = this.props;

    if (!editorFormElements) return null;

    return (
      <form className="entity-editor-form kt-form">
        <div className="kt-portlet__body">
          <div className="kt-form__section kt-form__section--first">
            <EntityEditorForm
              entity={entity}
              onChange={this.onChange}
              elements={editorFormElements}
              areas={this.props.areas?.result}
              autocomplete={editorFormAutocompletion}
              useUncontrolled={editorFormUseUncontrolled}
              helpTextPlacement={editorHelpTextPlacement}
              onGetExtraInfo={this.onGetExtraInfo}
            />
          </div>
        </div>
      </form>
    );
  }

  componentDidUpdate(prevProps: EntityListProps<T, TFilter, TKey>) {
    const { dispatch } = this.props;
    if (!prevProps.entities.isLoading && this.props.entities.isLoading) {
      dispatch(showAppLoading());
    } else if (!this.props.entities.isLoading && prevProps.entities.isLoading) {
      dispatch(hideAppLoading());
    }
  }

  onRefresh = (e?: MouseEvent<HTMLElement>) => {
    e && e.preventDefault();
    const { dispatch, actions } = this.props;
    try {
      dispatch(actions.invalidate());
    } catch (err) {
      console.error(err);
    }
  };

  onFilterChange = (filter: Partial<TFilter>) => {
    const { dispatch, actions } = this.props;
    actions.updateFilter && dispatch(actions.updateFilter(filter));
  };

  onAdd = () => {
    const extra = this.onGetExtraInfo();
    const { dispatch, actions, onAdd } = this.props;
    const entity: Partial<T> = {};
    const res = onAdd?.(entity, extra);
    if (res === true) {
      return;
    }
    actions.itemBeingCreated && dispatch(actions.itemBeingCreated(entity));
  };

  onCanAdd = () => {
    const { identity } = this.props;
    if (this.props.fullAccessRight) {
      return $has(identity, this.props.fullAccessRight);
    }
    return false;
  };

  onEdit(entity: T) {
    const extra = this.onGetExtraInfo();
    const { dispatch, actions, onEdit } = this.props;
    if (onEdit) {
      entity = onEdit(entity, extra);
    }
    actions.itemBeingUpdated && dispatch(actions.itemBeingUpdated(entity));
  }

  onCommitItemBeingDeleted = () => {
    const { dispatch, actions, onDeleted } = this.props;
    if (actions.commitItemsBeingDeleted) {
      if (onDeleted) {
        dispatch(
          actions.commitItemsBeingDeleted((err: Error | undefined) => {
            const extra = this.onGetExtraInfo();
            onDeleted(err, extra);
          }),
        );
      } else {
        dispatch(actions.commitItemsBeingDeleted());
      }
    }
  };

  onCancelBeingDeleted = () => {
    const { dispatch, actions } = this.props;
    actions.cancelItemsBeingDeleted &&
      dispatch(actions.cancelItemsBeingDeleted());
  };

  onSave = () => {
    const { entities, dispatch, actions } = this.props;
    if (entities.itemBeingCreated) {
      actions.commitItemBeingCreated &&
        dispatch(actions.commitItemBeingCreated());
    } else {
      actions.commitItemBeingUpdated &&
        dispatch(actions.commitItemBeingUpdated());
    }
  };

  onCancel = () => {
    const { entities, dispatch, actions } = this.props;
    if (entities.itemBeingCreated) {
      actions.cancelItemBeingCreated &&
        dispatch(actions.cancelItemBeingCreated());
    } else {
      actions.cancelItemBeingUpdated &&
        dispatch(actions.cancelItemBeingUpdated());
    }
  };

  onChange = (changes: Partial<T>) => {
    const { entities, dispatch, actions } = this.props;
    if (entities.itemBeingCreated) {
      actions.itemBeingCreatedChanged &&
        dispatch(actions.itemBeingCreatedChanged(changes));
    } else {
      actions.itemBeingUpdatedChanged &&
        dispatch(actions.itemBeingUpdatedChanged(changes));
    }
  };

  onToggleAllSelection = () => {
    const { dispatch, actions } = this.props;
    actions.toggleAllSelection && dispatch(actions.toggleAllSelection());
  };

  onSelectChange = (entity: T, selected: boolean) => {
    const { dispatch, actions } = this.props;
    if (selected) {
      actions.itemSelected && dispatch(actions.itemSelected(entity));
    } else {
      actions.itemDeselected && dispatch(actions.itemDeselected(entity));
    }
  };

  onOffsetChange = (offset: number) => {
    const { dispatch, actions } = this.props;
    actions.updateDataOffset && dispatch(actions.updateDataOffset(offset));
  };

  onLimitChange = (limit: number) => {
    const { dispatch, actions } = this.props;
    actions.updateDataLimit && dispatch(actions.updateDataLimit(limit));
  };

  onListItemNodeExpand = (entity: T) => {
    const { dispatch, actions } = this.props;
    dispatch(actions.expandListItemNode(entity));
  };

  onListItemNodeCollapse = (entity: T) => {
    const { dispatch, actions } = this.props;
    dispatch(actions.collapseListItemNode(entity));
  };

  onFormatGroupHeader = (group: DataListGroup<T>, key?: any) => {
    const extra = this.onGetExtraInfo();
    if (this.props.onFormatGroupHeader) {
      return this.props.onFormatGroupHeader(group, key, extra);
    }
    if (
      group.groupValue === null ||
      group.groupValue === undefined ||
      group.groupValue === ''
    ) {
      return getString('@string/unknown_list_group_label');
    }
    return group.groupValue;
  };

  onListGroupExpand = (value: any, key?: any) => {
    const { dispatch, actions } = this.props;
    const extra = this.onGetExtraInfo();
    this.props.onListGroupExpand &&
      this.props.onListGroupExpand(value, key, extra);
    dispatch(actions.expandListGroup(value));
  };

  onListGroupCollapse = (value: any, key?: any) => {
    const { dispatch, actions } = this.props;
    const extra = this.onGetExtraInfo();
    this.props.onListGroupCollapse &&
      this.props.onListGroupCollapse(value, key, extra);
    dispatch(actions.collapseListGroup(value));
  };

  onRenderListGroupActions = (group: DataListGroup<T>, key?: any) => {
    const extra = this.onGetExtraInfo();
    return this.props.onRenderListGroupActions
      ? this.props.onRenderListGroupActions(group, key, extra)
      : undefined;
  };

  getListGroupProps() {
    if (!this.props.groupBy) return {};
    const { entities } = this.props;
    const key = entities.activeGroupKey;
    return {
      groupBy: this.props.groupBy,
      groupListKey: this.props.groupListKey,
      collapseGroupByDefault: entities.collapseGroupByDefault,
      expandedGroups: entities.expandedGroups
        ? entities.expandedGroups.get(key)
        : undefined,
      collapsedGroups: entities.collapsedGroups
        ? entities.collapsedGroups.get(key)
        : undefined,
      onFormatGroupHeader: this.onFormatGroupHeader,
      onListGroupExpand: this.onListGroupExpand,
      onListGroupCollapse: this.onListGroupCollapse,
      onRenderListGroupActions: this.onRenderListGroupActions,
    };
  }

  onGetExtraInfo = () => this.props;

  getColumns = (identity: Identity) => {
    const { actionButtons } = this.props;
    const col = this.columns.find(x => x.prop === 'actions');
    if (!col) return this.columns;

    if (!actionButtons?.length) {
      col.hidden = true;
      return this.columns;
    }

    if (
      actionButtons.every(x => {
        if (x === 'edit' || x === 'remove') {
          if (
            this.props.fullAccessRight &&
            !identity.hasAccessRights(this.props.fullAccessRight)
          ) {
            return true;
          }
        } else if (x.rights?.length) {
          const rights = typeof x.rights === 'string' ? [x.rights] : x.rights;
          if (!identity.hasAccessRights(...rights)) {
            return true;
          }
        }
        return false;
      })
    ) {
      col.hidden = true;
    }

    return this.columns;
  };
}
