import { Component } from 'react';

export class Oops extends Component {
  render() {
    return (
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v6"
          style={{
            backgroundImage: 'url(lib/metronic/assets/img/bg/error/bg6.jpg)',
          }}
        >
          <div className="kt-error_container">
            <div className="kt-error_subtitle kt-font-light">
              <h1>Oops...</h1>
              <p className="kt-error_description kt-font-light">
                Looks like something went wrong.
                <br />
                {`We're working on it`}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
