import React, { Component, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { getTranslate, Translate } from 'react-localize-redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { loadAsyncList } from 'utils';
import { AppState, TransFunction } from 'app';
import { showAppLoading, hideAppLoading } from 'app/duck/actions';
import { AclObjectList, InspectionSiteNodeFilter, VehicleInspectionSiteCategory, VehicleInspectionSite, VehicleInspectionSiteCheckItem, VehicleInspectionSiteCheckItemOption, SeverityLevel } from 'model';
import { CommonEntityListProps, Tree, Node, getString, StringLabel, withConfirmDeleteModalBuilder } from 'shared/components';
import { Button, Alert, BreadcrumbItem, Page, Breadcrumb, Portlet, Dropdown, Nav, Badge } from 'lib/metronic/components';
import { InspectionSiteCategories, InspectionSites, InspectionSiteItems, InspectionSiteItemOptions, InspectionSiteInventoryUIState, InspectionTools } from '../duck/states';
import { mapStateToTree, isCategoryNode, getNodeDataAsCategory, isSiteNode, getNodeDataAsSite, isItemNode, getNodeDataAsItem, isOptionNode, getNodeDataAsOption, getAllNodeIds } from '../common/helpers';
import { inspectionSiteCategoryActions, inspectionSiteActions, inspectionSiteItemActions, inspectionSiteItemOptionActions, inspectionSiteInventoryNodeSelected, inspectionSiteInventoryNodeExpanded, inspectionSiteInventoryNodeCollapsed, inspectionSiteInventoryNodeMouseEnter, inspectionSiteInventoryNodeMouseLeave, inspectionSiteInventorySetExpandedCollapsedNodes, inspectionToolActions } from '../duck/actions';
import { inspectionSiteNodeFilterChanged } from '../duck/actions/filter';
import { InspectionSiteToolbar } from './Toolbar';
import { CategoryEditor } from './editors/CategoryEditor';
import { SiteEditor } from './editors/SiteEditor';
import { ItemEditor } from './editors/ItemEditor';
import { OptionEditor } from './editors/OptionEditor';

import './index.scss';

const EXPAND_ALL_BY_DEFAULT = true;

interface Props extends CommonEntityListProps {
  categories: InspectionSiteCategories;
  sites: InspectionSites;
  items: InspectionSiteItems;
  options: InspectionSiteItemOptions;
  tools: InspectionTools;
  nodes: Node[];
  uiState: InspectionSiteInventoryUIState;
  filter: Partial<InspectionSiteNodeFilter>;
}

function mapStateToProps(state: AppState): Partial<Props> {
  return {
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    categories: state.inspection.categories,
    sites: state.inspection.sites,
    items: state.inspection.items,
    options: state.inspection.options,
    tools: state.inspection.tools,
    uiState: state.inspection.uiState,
    nodes: mapStateToTree(state, state.inspection.filter.keyword || '', EXPAND_ALL_BY_DEFAULT),
    filter: state.inspection.filter
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

const ConfirmDeleteCategoryModal = withConfirmDeleteModalBuilder<VehicleInspectionSiteCategory>()
  .withI18nPrefix('inspection_site_category')
  .getClass();

const ConfirmDeleteSiteModal = withConfirmDeleteModalBuilder<VehicleInspectionSite>()
  .withI18nPrefix('inspection_site')
  .getClass();

const ConfirmDeleteItemModal = withConfirmDeleteModalBuilder<VehicleInspectionSiteCheckItem>()
  .withI18nPrefix('inspection_site_item')
  .getClass();

  const ConfirmDeleteOptionModal = withConfirmDeleteModalBuilder<VehicleInspectionSiteCheckItemOption>()
  .withI18nPrefix('inspection_site_item_option')
  .getClass();

export class InspectionSitesInventoryImpl extends Component<Props> {
  private readonly breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.summary" /> }
  ];

  componentDidMount() {
    this.props.dispatch((dispatch, getState) => {
      const state = getState();

      loadAsyncList(
        state.inspection.categories,
        () => dispatch(inspectionSiteCategoryActions.fetch())
        );
      loadAsyncList(
        state.inspection.sites,
        () => dispatch(inspectionSiteActions.fetch())
        );
      loadAsyncList(
        state.inspection.items,
        () => dispatch(inspectionSiteItemActions.fetch())
        );
      loadAsyncList(
        state.inspection.options,
        () => dispatch(inspectionSiteItemOptionActions.fetch())
        );
      loadAsyncList(
        state.inspection.tools,
        () => dispatch(inspectionToolActions.fetch())
      );
    });
  }

  render() {
    const { trans, dispatch, nodes, categories, sites, items, options, uiState } = this.props;
    const itemEditorExtra = { props: this.props };

    const optionEditorExtra = {
      nodes,
      selectedNodeId: uiState.selectedNodeId
    };
    return (
      <Page
        title={trans('inspection_site.page.title')}
        fullAccessRight={AclObjectList.VehicleInspectionSiteFullAccess}
        readonlyAccessRight={AclObjectList.VehicleInspectionSiteReadonlyAccess}
        >
        <Page.Header>
          <Page.Header.Main>
            <Breadcrumb items={this.breadcrumbs} />
          </Page.Header.Main>
          <Page.Header.Toolbar>
            <InspectionSiteToolbar
              filter={this.props.filter}
              onAddCategory={this.onAddCategory}
              onFilterChange={this.onFilterChange}
              onExpandAll={this.onExpandAll}
              onCollapseAll={this.onCollapseAll}
              />
          </Page.Header.Toolbar>
        </Page.Header>
        <Page.Content>
          <Portlet mobile>
            <Portlet.Header
              size="large"
              title={trans('inspection_site.page.title')}
              icon={require('!@svgr/webpack!lib/metronic/assets/icons/svg/layout/layout-4-blocks.svg').default}
              iconColor="brand"
              >
              <Dropdown
                iconOnly
                inline
                clean
                size="small"
                buttonContents={<i className="flaticon-more-1" />}
                showToggleButton={false}
                dropdownProps={{ placement: 'bottom-end' }}
                >
                <Nav>
                  <Nav.Item
                    icon="flaticon-refresh"
                    text={getString('@string/nav_item_refresh_list')}
                    onClick={this.onRefresh}
                    />
                </Nav>
              </Dropdown>
            </Portlet.Header>
            <Portlet.Body>
              <Alert color="secondary" icon="fa fa-info-circle" style={{ marginBottom: 0 }} >
                <Translate id="inspection_site.tip" />
              </Alert>

              <div className="inspection-site-inventory">
                <Tree
                  nodes={nodes}
                  onRenderNodeText={this.onRenderNodeText}
                  onNodeExpand={this.onNodeExpand}
                  onNodeCollapse={this.onNodeCollapse}
                  onNodeMouseEnter={this.onNodeMouseEnter}
                  onNodeMouseLeave={this.onNodeMouseLeave}
                  onRenderNodeDecoratedView={this.onRenderNodeDecoratedView}
                  onRenderEmptyPlaceholder={this.onRenderEmptyPlaceholder}
                  />
              </div>

              <CategoryEditor
                dispatch={dispatch}
                actions={inspectionSiteCategoryActions}
                entities={categories}
                />
              <SiteEditor
                dispatch={dispatch}
                actions={inspectionSiteActions}
                entities={sites}
                />
              <ItemEditor
                dispatch={dispatch}
                actions={inspectionSiteItemActions}
                entities={items}
                extra={itemEditorExtra}
                />
              <OptionEditor
                dispatch={dispatch}
                actions={inspectionSiteItemOptionActions}
                entities={options}
                extra={optionEditorExtra}
                />

              <ConfirmDeleteCategoryModal
                dispatch={dispatch}
                actions={inspectionSiteCategoryActions}
                entities={categories}
                />
              <ConfirmDeleteSiteModal
                dispatch={dispatch}
                actions={inspectionSiteActions}
                entities={sites}
                />
              <ConfirmDeleteItemModal
                dispatch={dispatch}
                actions={inspectionSiteItemActions}
                entities={items}
                />
              <ConfirmDeleteOptionModal
                dispatch={dispatch}
                actions={inspectionSiteItemOptionActions}
                entities={options}
                extra={optionEditorExtra}
                />
            </Portlet.Body>
          </Portlet>
        </Page.Content>
      </Page>
    );
  }

  componentDidUpdate(prevProps: Props) {
    const { dispatch } = this.props;
    if (this.isLoading(this.props) && !this.isLoading(prevProps)) {
      dispatch(showAppLoading());
    } else if (!this.isLoading(this.props) && this.isLoading(prevProps)) {
      dispatch(hideAppLoading());
    }
  }

  onAddCategory = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteCategoryActions.itemBeingCreated({
      sortOrder: 0
    }));
  }

  onNodeClick = (node: Node) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteInventoryNodeSelected(node.id as string));
  }

  onNodeExpand = (node: Node) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteInventoryNodeExpanded(node.id as string, EXPAND_ALL_BY_DEFAULT));
  }

  onNodeCollapse = (node: Node) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteInventoryNodeCollapsed(node.id as string, EXPAND_ALL_BY_DEFAULT));
  }

  onNodeMouseEnter = (node: Node) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteInventoryNodeMouseEnter(node.id as string));
  }

  onNodeMouseLeave = (node: Node) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteInventoryNodeMouseLeave(node.id as string));
  }

  onRenderNodeText = (node: Node) => {
    let nodeText: ReactNode = null;

    if (node.data?.orgId) {
      nodeText = (
        <span>
          {node.text}
          <Badge color="primary" size="small" pill inline style={{
            textDecoration: 'none',
            marginLeft: 4,
            fontWeight: 'normal',
            fontSize: '0.7rem',
            padding: '0 0.5rem',
            verticalAlign: 'middle'
            }}>
            {getString('@string/custom_label')}
          </Badge>
        </span>);
    } else {
      nodeText = node.text;
    }

    if (!isOptionNode(node)) return nodeText;
    const option = getNodeDataAsOption(node);
    return (
      <span className={classNames({
        'kt-font-success': option.severityLevel === SeverityLevel.None,
        'kt-font-info': option.severityLevel === SeverityLevel.Slight,
        'kt-font-brand': option.severityLevel === SeverityLevel.Notice,
        'kt-font-warning': option.severityLevel === SeverityLevel.Warning,
        'kt-font-danger': option.severityLevel === SeverityLevel.Danger
      })}>
        {nodeText}
      </span>
    );
  }

  onRenderNodeDecoratedView = (node: Node) => {
    if (this.props.uiState.mouseEnteredNodeId !== node.id ||
      isOptionNode(node) && !getNodeDataAsOption(node).orgId
    ) {
      if (isOptionNode(node)) {
        const option = getNodeDataAsOption(node);
        if (option.description) {
          return (
            <span style={{ color: '#999', fontSize: '0.8em' }}>
              ({option.description})
            </span>
          );
        }
      }
      return null;
    }

    const createAddButton = (text: string, onClick: () => void) => {
      const onButtonClick = (e: MouseEvent) => {
        e.preventDefault();
        onClick();
      };
      return (
        <Button
          outline
          color="brand"
          size="small"
          onClick={onButtonClick}
          >
          <i className="la la-plus" />
          <StringLabel value={text} />
        </Button>
      );
    };

    const createEditButton = (onClick: () => void) => {
      const onButtonClick = (e: MouseEvent) => {
        e.preventDefault();
        onClick();
      };
      return (
        <Button
          outline
          color="info"
          size="small"
          onClick={onButtonClick}
          >
          <i className="la la-pencil" />
          <Translate id="inspection_site.node_decoration.edit_btn" />
        </Button>
      );
    };

    const createDeleteButton = (onClick: () => void) => {
      const onButtonClick = (e: MouseEvent) => {
        e.preventDefault();
        onClick();
      };
      return (
        <Button
          outline
          color="warning"
          size="small"
          onClick={onButtonClick}
          >
          <i className="la la-remove" />
          <Translate id="inspection_site.node_decoration.remove_btn" />
        </Button>
      );
    };

    let addButton: ReactNode | null = null;
    let editButton: ReactNode | null = null;
    let removeButton: ReactNode | null = null;

    const { dispatch } = this.props;

    if (isCategoryNode(node)) {
      const category = getNodeDataAsCategory(node);
      addButton = createAddButton(
        'inspection_site.node_decoration.add_site',
        () => {
          dispatch(inspectionSiteActions.itemBeingCreated({
            categoryId: category.id
          }))
        });
      if (category.orgId) {
        editButton = createEditButton(() => {
          dispatch(inspectionSiteCategoryActions.itemBeingUpdated(category));
        });
        removeButton = createDeleteButton(() => {
          dispatch(inspectionSiteCategoryActions.itemsBeingDeleted([category]));
        });
      }
    } else if (isSiteNode(node)) {
      const site = getNodeDataAsSite(node);
      addButton = createAddButton(
        'inspection_site.node_decoration.add_item',
        () => {
          dispatch(inspectionSiteItemActions.itemBeingCreated({
            siteId: site.id
          }))
        });
      if (site.orgId) {
        editButton = createEditButton(() => {
          dispatch(inspectionSiteActions.itemBeingUpdated(site));
        });
        removeButton = createDeleteButton(() => {
          dispatch(inspectionSiteActions.itemsBeingDeleted([site]));
        });
      }
    } else if (isItemNode(node)) {
      const item = getNodeDataAsItem(node);
      addButton = createAddButton(
        'inspection_site.node_decoration.add_option',
        () => {
          dispatch(inspectionSiteItemOptionActions.itemBeingCreated({
            itemId: item.id,
            lowerInclusive: false,
            upperInclusive: false
          }))
        });
      if (item.orgId) {
        editButton = createEditButton(() => {
          dispatch(inspectionSiteItemActions.itemBeingUpdated(item));
        });
        removeButton = createDeleteButton(() => {
          dispatch(inspectionSiteItemActions.itemsBeingDeleted([item]));
        });
      }
    } else if (isOptionNode(node)) {
      const option = getNodeDataAsOption(node);
      if (option.orgId) {
        editButton = createEditButton(() => {
          dispatch(inspectionSiteItemOptionActions.itemBeingUpdated(option));
        });
        removeButton = createDeleteButton(() => {
          dispatch(inspectionSiteItemOptionActions.itemsBeingDeleted([option]));
        });
      }
    }

    return (
      <>
        {addButton}
        {editButton}
        {removeButton}
      </>
    );
  }

  onRenderEmptyPlaceholder = (node: Node | null) => {
    const { dispatch, filter } = this.props;
    if (!node) {
      const loading = this.isLoading(this.props, true);
      const stringId = 'inspection_site.empty_placeholder.' + (
        loading ? 'loading' : (filter.keyword ? 'search' : 'tree')
      );

      return (
        <div className="inspection-site-inventory__empty-tree kt-font-info">
          {loading ?
            <i className="fa fa-spinner" /> :
            <i className="la la-info-circle icon" />
            }
          <Translate id={stringId} />
        </div>
      );
    }

    if (isCategoryNode(node)) {
      const category = getNodeDataAsCategory(node);
      const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(inspectionSiteActions.itemBeingCreated({
          categoryId: category.id
        }));
      };
      const addButton = (
        <a href="#" onClick={onClick}>
          {getString('inspection_site.button.add_site')}
        </a>
      );
      return (
        <Translate
          id="inspection_site.empty_placeholder.category"
          data={{ addButton }}
          />
      );
    }
    if (isSiteNode(node)) {
      const site = getNodeDataAsSite(node);
      const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(inspectionSiteItemActions.itemBeingCreated({
          siteId: site.id
        }));
      };
      const addButton = (
        <a href="#" onClick={onClick}>
          {getString('inspection_site.button.add_item')}
        </a>
      );
      return (
        <Translate
          id="inspection_site.empty_placeholder.site"
          data={{ addButton }}
          />
      );
    }
    if (isItemNode(node)) {
      const item = getNodeDataAsItem(node);
      const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(inspectionSiteItemOptionActions.itemBeingCreated({
          itemId: item.id,
          lowerInclusive: false,
          upperInclusive: false
        }));
      };
      const addButton = (
        <a href="#" onClick={onClick}>
          {getString('inspection_site.button.add_option')}
        </a>
      );
      return (
        <Translate
          id="inspection_site.empty_placeholder.item"
          data={{ addButton }}
          />
      );
    }
    return '';
  }

  onRefresh = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteCategoryActions.invalidate(true));
    dispatch(inspectionSiteActions.invalidate(true));
    dispatch(inspectionSiteItemActions.invalidate(true));
    dispatch(inspectionSiteItemOptionActions.invalidate(true));
  }

  onFilterChange = (filter: Partial<InspectionSiteNodeFilter>) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteNodeFilterChanged(filter));
  }

  onExpandAll = () => {
    const { dispatch } = this.props;
    if (EXPAND_ALL_BY_DEFAULT) {
      dispatch(inspectionSiteInventorySetExpandedCollapsedNodes({
        expandedNodes: new Set(),
        collapsedNodes: new Set()
      }));
    } else {
      const ids = getAllNodeIds(this.props.nodes);
      dispatch(inspectionSiteInventorySetExpandedCollapsedNodes({
        expandedNodes: new Set(ids),
        collapsedNodes: new Set()
      }));
    }
  }

  onCollapseAll = () => {
    const { dispatch } = this.props;
    if (EXPAND_ALL_BY_DEFAULT) {
      const ids = getAllNodeIds(this.props.nodes);
      dispatch(inspectionSiteInventorySetExpandedCollapsedNodes({
        expandedNodes: new Set(),
        collapsedNodes: new Set(ids)
      }));
    } else {
      dispatch(inspectionSiteInventorySetExpandedCollapsedNodes({
        expandedNodes: new Set(),
        collapsedNodes: new Set()
      }));
    }
  }

  private isLoading(props: Props, firstTime = false) {
    const { categories, sites, items, options } = props;

    if (firstTime) {
      return Boolean(
        !categories.result && categories.isLoading ||
        !sites.result && sites.isLoading ||
        !items.result && items.isLoading ||
        !options.result && options.isLoading
      );
    }

    return (
      categories.isLoading ||
      sites.isLoading ||
      items.isLoading ||
      options.isLoading
    );
  }
}

export const InspectionSitesInventory = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InspectionSitesInventoryImpl);