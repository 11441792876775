/**
 * @file: ApprovalActionOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, ApprovalAction } from 'model';

export const ApprovalActionOptions: Array<Option<ApprovalAction>> = [
  { value: ApprovalAction.Accept, label: 'approval_action.accept' },
  { value: ApprovalAction.Reject, label: 'approval_action.reject' }
];

export const ApprovalActionOptionsWithDefault: Array<Option<ApprovalAction | null>> = [
  { value: null, label: 'approval_action.__default__' },
  { value: ApprovalAction.Accept, label: 'approval_action.accept' },
  { value: ApprovalAction.Reject, label: 'approval_action.reject' }
];