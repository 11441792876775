import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncActionReducers } from 'lib/duck/reducers';
import { WeixinOpenAuthorizedInfo } from 'model';
import { AuthorizationState } from '../states';
import { ActionTypes } from '../types';

const initialState: AuthorizationState = {
  isAuthorizing: false,
  error: null,
  isLoading: true,
  result: null,
  authorizationError: null,
  isAuthorizationSuccess: false,
};

const defaultReducer = createAsyncActionReducers<
  WeixinOpenAuthorizedInfo,
  AuthorizationState
>('integration.authorization', initialState);

export default function authorizationReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AuthorizationState = initialState,
  action: StandardAction<any>,
): AuthorizationState {
  switch (action.type) {
    case ActionTypes.BeginAuthorization: {
      return {
        ...state,
        isAuthorizing: true,
        authorizationError: null,
        error: null,
      };
    }
    case ActionTypes.AuthoizationSuccess: {
      return {
        ...state,
        isAuthorizing: false,
        isAuthorizationSuccess: true,
        authorizationError: null,
        error: null,
        result: action.payload,
      };
    }
    case ActionTypes.AuthorizationFailed: {
      return {
        ...state,
        isAuthorizing: false,
        authorizationError: action.payload,
        error: null,
        result: null,
      };
    }
    default: {
      return defaultReducer(state, action);
    }
  }
}
