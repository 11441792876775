import { Button } from 'lib/metronic/components';
import { useConstructionTemplateConfigContext } from './Context';
import { TemplatePicker } from './TemplatePicker';
import { ConstructionTemplateWorkItem } from 'model';
import React, { memo, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap/lib';
import { getString } from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';

export const ItemModal = memo(
  ({
    item,
    groupId,
    onCancel,
    onConfirm,
  }: {
    onCancel?: () => void;
    groupId?: string;
    item?: ConstructionTemplateWorkItem;
    onConfirm?: (info: {
      id: string;
      name: string;
      groupId: string;
      templateId?: string;
    }) => void;
  }) => {
    const itemId = item?.id;

    const {
      templateConfig: { templates },
    } = useConstructionTemplateConfigContext();

    const [name, setName] = useState('');
    const [templateId, setTemplateId] = useState<string>();

    useEffect(() => {
      if (item == null) return;
      setName(item.name);
      setTemplateId(item.templateId);
    }, [item]);

    const handleConfirm = usePersistFn(() => {
      if (!itemId || !groupId || !name?.trim()) return;
      onConfirm?.({
        id: itemId,
        groupId,
        name: name.trim(),
        templateId,
      });
    });

    const disabled = !name?.trim();

    return (
      <Modal
        isOpen={item != null}
        modalClassName="construction-tpl-man__item-modal"
      >
        <ModalHeader>
          <Translate id="construction_tpl.work_item.modal.title" />
        </ModalHeader>
        <ModalBody>
          <dl>
            <dt>
              <Translate id="construction_tpl.work_item.label.name" />
            </dt>
            <dd>
              <input
                type="text"
                name="name"
                className="form-control"
                value={name ?? ''}
                onChange={e => setName(e.target.value)}
                placeholder={getString(
                  'construction_tpl.work_item.placeholder.name',
                )}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <Translate id="construction_tpl.work_item.label.template" />
            </dt>
            <dd>
              <TemplatePicker
                templates={templates}
                value={templateId}
                onChange={setTemplateId}
                placeholder={getString(
                  'construction_tpl.work_item.placeholder.template',
                )}
              />
            </dd>
          </dl>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="primary" onClick={handleConfirm} disabled={disabled}>
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
