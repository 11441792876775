import { quotationTplActions } from 'app/inspection/duck/actions';
import { LinkButton } from 'app/inspection/quotation-template-config/LinkButton';
import { InlineSvg } from 'lib/components';
import { Checkbox } from 'lib/metronic/components';
import {
  QuotationItemRef,
  QuotationMaterialInventoryStatusOptions,
  QuotationTemplateItemStaged,
  QuotationTemplateMaterialStaged,
} from 'model';
import { ChangeEvent, FC, memo, ReactNode, useCallback, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortEndHandler,
} from 'react-sortable-hoc';
import { MaterialTypeLabel } from 'shared/components';
import invariant from 'tiny-invariant';

export const MaterialListView = memo(
  ({
    item,
    editable,
  }: {
    item: QuotationTemplateItemStaged;
    editable: boolean;
  }) => {
    const dispatch = useDispatch();

    const itemRef = useMemo<QuotationItemRef>(
      () => ({ ...item.subjectRef, itemId: item.id }),
      [item.subjectRef, item.id],
    );

    const onEditItem = useCallback(
      (material: QuotationTemplateMaterialStaged) => {
        dispatch(
          quotationTplActions.editMaterial({
            ...itemRef,
            materialId: material.id,
          }),
        );
      },
      [dispatch, itemRef],
    );

    const onRemoveItem = useCallback(
      (material: QuotationTemplateMaterialStaged) => {
        dispatch(
          quotationTplActions.removeMaterial({
            ...itemRef,
            materialId: material.id,
          }),
        );
      },
      [dispatch, itemRef],
    );

    const onCheckChange = useCallback(
      (material: QuotationTemplateMaterialStaged, checked: boolean) => {
        dispatch(
          quotationTplActions.materialCheckChanged(
            {
              ...itemRef,
              materialId: material.id,
            },
            checked,
          ),
        );
      },
      [dispatch, itemRef],
    );

    const onItemSortEnd: SortEndHandler = useCallback(
      e => {
        const { newIndex, oldIndex } = e;
        if (newIndex === oldIndex) return;
        const material = item.materials?.[oldIndex];
        invariant(material != null);
        dispatch(
          quotationTplActions.materialMoved(itemRef, oldIndex, newIndex),
        );
      },
      [dispatch, item.materials, itemRef],
    );

    return (
      <SortableMaterialList
        helperClass="quotation-tpl__item--being-dragged"
        onSortEnd={onItemSortEnd}
        lockAxis="y"
        useWindowAsScrollContainer={true}
        distance={4}
        useDragHandle
      >
        {item.materials?.map((material, index) => (
          <SortableMaterialListItem
            key={item.id}
            index={index}
            material={material}
            onEdit={onEditItem}
            onRemove={onRemoveItem}
            onCheckChange={onCheckChange}
            editable={editable}
          />
        ))}
      </SortableMaterialList>
    );
  },
);

const ListContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return <ul className="quotation-tpl-item__material-list">{children}</ul>;
};

const MaterialView = memo(
  ({
    material,
    editable,
    onEdit,
    onRemove,
    onCheckChange,
  }: {
    material: QuotationTemplateMaterialStaged;
    editable: boolean;
    onEdit: (material: QuotationTemplateMaterialStaged) => void;
    onRemove: (material: QuotationTemplateMaterialStaged) => void;
    onCheckChange: (
      material: QuotationTemplateMaterialStaged,
      checked: boolean,
    ) => void;
  }) => {
    const onEditClick = useCallback(() => {
      onEdit(material);
    }, [material, onEdit]);

    const onRemoveClick = useCallback(() => {
      onRemove(material);
    }, [material, onRemove]);

    const handleCheckChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onCheckChange(material, e.target.checked);
      },
      [material, onCheckChange],
    );

    return (
      <li className="quotation-tpl-item__material">
        <div className="quotation-tpl-item__material-content">
          <h6>
            <span
              style={{
                display: 'inline-flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {editable && (
                <Checkbox
                  label=""
                  color="brand"
                  checked={material.included ?? false}
                  onChange={handleCheckChange}
                  className="quotation-tpl-item__material-check"
                />
              )}
              {material.name}
              {material.type ? (
                <MaterialTypeLabel
                  value={material.type}
                  className="quotation-tpl-item__material-type"
                />
              ) : null}
            </span>
            <span className="quotation-tpl-item__material-price-qty">
              <span className="quotation-tpl-item__material-price">
                {material.discountPrice != null ? (
                  <>
                    <del style={{ color: '#ccc' }}>
                      {material.price?.toFixed(2) ?? '--'}
                    </del>{' '}
                    {material.discountPrice.toFixed(2)}
                  </>
                ) : material.price ? (
                  material.price.toFixed(2)
                ) : (
                  '--'
                )}
              </span>
              <span className="quotation-tpl-item__material-price-x">x</span>
              <span className="quotation-tpl-item__material-qty">
                {material.qty ?? 1}
              </span>
            </span>
          </h6>
          <div>
            <span className="quotation-tpl-item__material-info">
              <label>
                <Translate id="quotation_tpl.material.label.brand_name" />
              </label>
              <span>{material.brandName || '--'}</span>
            </span>
            <span className="quotation-tpl-item__material-info">
              <label>
                <Translate id="quotation_tpl.material.label.part_number" />
              </label>
              <span>{material.partNumber || '--'}</span>
            </span>
            <span className="quotation-tpl-item__material-info">
              <label>
                <Translate id="quotation_tpl.material.label.inventory_status" />
              </label>
              <span>
                {material.inventoryStatus ? (
                  <Translate
                    id={QuotationMaterialInventoryStatusOptions.find(
                      x => x.value === material.inventoryStatus,
                    )?.label.replace('@string/', '')}
                  />
                ) : (
                  '--'
                )}
              </span>
            </span>
            {material.optionGroup?.trim() ? (
              <span className="quotation-tpl-item__material-info">
                <label>
                  <Translate id="quotation_tpl.material.label.option_group" />
                </label>
                <span>{material.optionGroup}</span>
              </span>
            ) : null}
          </div>
        </div>
        {editable && (
          <div className="quotation-tpl-item__material-actions">
            <LinkButton onClick={onEditClick}>
              <i className={`la la-edit`} />
            </LinkButton>
            <LinkButton onClick={onRemoveClick}>
              <i className={`la la-close`} />
            </LinkButton>
            <DragHandle />
          </div>
        )}
      </li>
    );
  },
);

const SortableMaterialList = SortableContainer(ListContainer);
const SortableMaterialListItem = SortableElement(MaterialView);

const DragHandle = SortableHandle(() => (
  <InlineSvg src="public/img/icon-sort.svg" />
));
