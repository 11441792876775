/**
 * @file: InspectionOrderTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionOrderType } from 'model';

export const InspectionOrderTypeOptions: Array<Option<InspectionOrderType>> = [
  { value: InspectionOrderType.Basic, label: 'inspection_order_type.basic' },
  { value: InspectionOrderType.Repair, label: 'inspection_order_type.repair' },
  { value: InspectionOrderType.Full, label: 'inspection_order_type.full' }
];

export const InspectionOrderTypeOptionsWithDefault: Array<Option<InspectionOrderType | null>> = [
  { value: null, label: 'inspection_order_type.__default__' },
  { value: InspectionOrderType.Basic, label: 'inspection_order_type.basic' },
  { value: InspectionOrderType.Repair, label: 'inspection_order_type.repair' },
  { value: InspectionOrderType.Full, label: 'inspection_order_type.full' }
];