import classNames from 'classnames';
import { Alert, Button } from 'lib/metronic/components';
import {
  MpMessageTemplate,
  OrgWeixinTemplateConf,
  OrgWeixinTemplateConfDetail,
  TemplateMessageSceneType,
} from 'model';
import { ChangeEvent, MouseEvent, PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import { getString } from 'shared/components';
import { applyTemplateParamsExample, parseTemplateParams } from './helpers';
import {
  getTemplatePropertyProvider,
  getTemplateScenes,
  TemplateMsgDestType,
} from './properrty-providers';

interface Props {
  template: MpMessageTemplate;
  conf: OrgWeixinTemplateConf | Partial<OrgWeixinTemplateConf>;
  error?: Error | null;
  onSceneChange: (scene: TemplateMessageSceneType | undefined) => void;
  onConfDetailChange: (detail: OrgWeixinTemplateConfDetail) => void;
  onSave: () => void;
}

interface State {
  activeDestType?: TemplateMsgDestType;
}

export class TemplateConfig extends PureComponent<Props, State> {
  state: State = {};

  render() {
    const { template, conf, error } = this.props;
    const confDetail = JSON.parse(conf.conf!) as OrgWeixinTemplateConfDetail;
    const params = parseTemplateParams(template.content);
    const propertyProvider = conf.scene
      ? getTemplatePropertyProvider(conf.scene)
      : null;
    const scenes = getTemplateScenes();
    const activeDestType =
      this.state.activeDestType ?? propertyProvider?.supportedDestTypes[0];

    return (
      <>
        {error && <Alert color="danger">{error.message}</Alert>}
        <div className="mp-template-detail">
          <dl className="mp-template-detail__info">
            <dt>
              <Translate id="integration.templates.detail.label.title" />
            </dt>
            <dd>{template.title}</dd>
          </dl>
          <dl className="mp-template-detail__info">
            <dt>
              <Translate id="integration.templates.detail.label.first_class" />
            </dt>
            <dd>{template.primaryIndustry}</dd>
          </dl>
          <dl className="mp-template-detail__info">
            <dt>
              <Translate id="integration.templates.detail.label.second_class" />
            </dt>
            <dd>{template.deputyIndustry}</dd>
          </dl>
          <dl className="mp-template-detail__info">
            <dt>
              <Translate id="integration.templates.detail.label.content" />
            </dt>
            <dd>
              <pre>{template.content}</pre>
            </dd>
          </dl>
          <dl className="mp-template-detail__info">
            <dt>
              <Translate id="integration.templates.detail.label.scene" />
            </dt>
            <dd>
              <select
                value={conf.scene}
                onChange={this.onSceneChange}
                className="mp-template-detail__scenes"
              >
                <option>
                  {getString('integration.templates.detail.placeholder.scene')}
                </option>
                {scenes.map(scene => (
                  <option value={scene} key={scene}>
                    {getString(`template_message_scene_type.${scene}`)}
                  </option>
                ))}
              </select>
            </dd>
          </dl>
          <dl className="mp-template-detail__info">
            <dt>
              <Translate id="integration.templates.detail.label.params" />
            </dt>
            <dd>
              <ul className="mp-template-detail__params">
                {params.map(param => (
                  <li key={param}>
                    <span>{param}</span>
                    <span>
                      <select
                        data-param={param}
                        value={confDetail.mapping[param]}
                        onChange={this.onParamMappingChange}
                      >
                        <option>
                          {getString(
                            'integration.templates.detail.placeholder.property',
                          )}
                        </option>
                        {propertyProvider?.getProperties().map(property => (
                          <option
                            value={property.property}
                            key={property.property}
                          >
                            {getString(property.label)}
                          </option>
                        ))}
                      </select>
                    </span>
                  </li>
                ))}
              </ul>
            </dd>
          </dl>
          <dl className="mp-template-detail__info">
            <dt>
              <Translate id="integration.templates.detail.label.preview" />
            </dt>
            <dd>
              {propertyProvider &&
                propertyProvider.supportedDestTypes.length > 1 && (
                  <div
                    className="btn-group btn-group-sm"
                    style={{ marginBottom: '1rem' }}
                  >
                    {propertyProvider.supportedDestTypes.map(destType => (
                      <button
                        key={destType}
                        type="button"
                        className={classNames('btn btn-outline-secondary', {
                          active: activeDestType === destType,
                        })}
                        onClick={this.onDestTypeClick(destType)}
                      >
                        <Translate
                          id={`integration.templates.dest_type.${destType}`}
                        />
                      </button>
                    ))}
                  </div>
                )}
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {applyTemplateParamsExample(
                  template.content,
                  (activeDestType &&
                    propertyProvider?.getExample(activeDestType)) ||
                    {},
                  confDetail,
                )}
              </pre>
            </dd>
          </dl>
        </div>
        <div className="mp-template-detail__actions">
          <Button color="brand" block onClick={this.onSaveButtonClick}>
            <Translate id="save_btn_text" />
          </Button>
        </div>
      </>
    );
  }

  onSaveButtonClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.onSave();
  };

  onSceneChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const scene = e.target.value
      ? (e.target.value as TemplateMessageSceneType)
      : undefined;
    this.props.onSceneChange(scene);
  };

  onParamMappingChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { conf } = this.props;
    const param = e.target.getAttribute('data-param')!;
    const property = e.target.value || undefined;
    const confDetail = JSON.parse(conf.conf!) as OrgWeixinTemplateConfDetail;
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    if (!property) delete confDetail.mapping[param];
    else confDetail.mapping[param] = property;
    this.props.onConfDetailChange(confDetail);
  };

  onDestTypeClick(destType: TemplateMsgDestType) {
    return () => {
      if (this.state.activeDestType !== destType) {
        this.setState({ activeDestType: destType });
      }
    };
  }
}
