import React, { Component } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Column, DataTable } from 'lib/metronic/components';
import { getScopedTransFunction } from 'app';
import { RecentSubscribedUsers } from './duck/states';
import { OrgSubscribedUser } from 'model';
import { formatTime } from 'utils';
import { GenderLabel } from 'shared/components';

interface Props extends LocalizeContextProps {
  users: RecentSubscribedUsers;
}

export class RecentSubscribedUserListComponent extends Component<Props> {
  private columns: Array<Column<OrgSubscribedUser>>;

  constructor(props: Props, context: any) {
    super(props, context);
    this.init();
  }

  render() {
    const { users } = this.props;
    return (
      <DataTable
        columns={this.columns}
        idProp="id"
        selModel="none"
        data={users.result}
        isLoading={users.isLoading}
        minHeight={0}
        error={users.error}
      />
    );
  }

  private init() {
    const { translate } = this.props;
    const trans = getScopedTransFunction(translate, '');
    this.columns = [
      {
        prop: 'avatarUrl',
        width: 48,
        text: '',
        render: (user: OrgSubscribedUser) => {
          return (
            <span>
              <img
                src={user.avatarUrl || ''}
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '50%',
                  marginRight: 8,
                }}
              />
            </span>
          );
        },
      },
      {
        prop: 'nick',
        width: 200,
        text: trans('user.col.nick'),
        render: (user: OrgSubscribedUser) => {
          return (
            <span>
              <span style={{ fontWeight: 'bold' }}>{user.nick}</span>
            </span>
          );
        },
      },
      {
        prop: 'gender',
        width: 80,
        text: trans(`user.col.gender`),
        align: 'center',
        render: (user: OrgSubscribedUser) =>
          user.gender ? <GenderLabel value={user.gender} /> : '/',
      },
      {
        prop: 'area',
        width: 200,
        text: trans('col.area_name'),
        render: (user: OrgSubscribedUser) =>
          [user.province, user.city].join('/'),
      },
      {
        prop: 'createdAt',
        text: trans('col.created_at'),
        width: 150,
        align: 'center',
        render: ({ createdAt }) => formatTime(createdAt),
      },
    ];
  }
}

export const RecentSubscribedUserList = withLocalize(
  RecentSubscribedUserListComponent,
);
