import { createAsyncActionReducers } from 'lib/duck/reducers';
import { AuthorizationUrlState } from '../states';

const initialState: AuthorizationUrlState = {
  isLoading: false,
  error: null,
  result: null
};

export default createAsyncActionReducers<string, AuthorizationUrlState>(
  'integration.authorization_url', initialState
  );