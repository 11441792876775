import { PropertyOptionItem } from 'app/settings/OptionItemEditor';
import { produce } from 'immer';
import { ExtendedOrderPropertyOption } from 'model';
import React, { ReactNode, memo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { array_move, setAdded, setRemoved } from 'utils';
import { usePersistFn } from 'utils/usePersistFn';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const OptionList = memo(({ children }: { children?: ReactNode }) => {
  return (
    <div className="property-editor__option-list-sortable noselect">
      {children}
    </div>
  );
});

const OptionListItem = memo(({ children }: { children?: ReactNode }) => {
  return <div className="property-editor__option-list-item">{children}</div>;
});

const SortableOptionList = SortableContainer(OptionList);
const SortableOptionListItem = SortableElement(OptionListItem);

export const ExtendedPropertyOptions = memo(
  ({
    options,
    error,
    optionErrors,
    showErrorOnChange,
    onChange,
  }: {
    options: ExtendedOrderPropertyOption[];
    error?: any;
    optionErrors?: Record<string, Record<string, ReactNode>>;
    showErrorOnChange?: boolean;
    onChange?: (options: ExtendedOrderPropertyOption[]) => void;
  }) => {
    const [expandedOptionIds, setExpandedOptionIds] = useState(
      new Set<string>(),
    );

    const onOptionChange = usePersistFn(
      (option: ExtendedOrderPropertyOption) => {
        const updated = produce(options, draft => {
          const index = draft.findIndex(x => x.id === option.id);
          if (index < 0) return;
          Object.assign(draft[index], option);
        });
        onChange?.(updated);
      },
    );

    const onRemove = usePersistFn((option: ExtendedOrderPropertyOption) => {
      const updated = produce(options, draft => {
        const index = draft.findIndex(x => x.id === option.id);
        if (index >= 0) {
          draft.splice(index, 1);
        }
      });
      onChange?.(updated);
    });

    const onToggleExpand = usePersistFn(
      (option: ExtendedOrderPropertyOption) => {
        setExpandedOptionIds(set =>
          set.has(option.id)
            ? setRemoved(set, option.id)
            : setAdded(set, option.id),
        );
      },
    );

    const onOptionSorted = usePersistFn(
      (e: { oldIndex: number; newIndex: number }) => {
        const { newIndex, oldIndex } = e;
        if (newIndex === oldIndex) return;
        const updated = produce(options, draft => {
          array_move(draft, oldIndex, newIndex);
        });
        onChange?.(updated);
      },
    );

    return (
      <div className="property-editor__option-item-list">
        <SortableOptionList
          helperClass="property-editor__option-list-item--being-dragged"
          lockAxis="y"
          distance={3}
          onSortEnd={onOptionSorted}
        >
          {options.map((option, index) => (
            <SortableOptionListItem key={option.id} index={index}>
              <PropertyOptionItem
                option={option}
                showErrorOnChange={showErrorOnChange}
                errors={optionErrors?.[option.id]}
                expanded={expandedOptionIds.has(option.id)}
                onChange={onOptionChange}
                onRemove={onRemove}
                onToggleExpand={onToggleExpand}
              />
            </SortableOptionListItem>
          ))}
        </SortableOptionList>
        {options.length === 0 && (
          <div
            style={{
              padding: '1rem 0',
              fontStyle: 'italic',
              fontSize: '0.9rem',
              color: '#999',
            }}
          >
            <Translate id="store.config.extended_property.editor.no_options" />
          </div>
        )}
        {options.length === 0 && error ? (
          <div
            className="invalid-feedback"
            style={{
              display: 'block',
            }}
          >
            {error}
          </div>
        ) : null}
      </div>
    );
  },
);
