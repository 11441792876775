/**
 * @file: QuantitativeType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum QuantitativeType {
  Quantitative = 'quantitative',
  NonQuantitative = 'non_quantitative'
}

export const QuantitativeTypeValueSet = new Set([
  QuantitativeType.Quantitative,
  QuantitativeType.NonQuantitative
]);