/**
 * @file: acl.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { aclService } from 'services';

const actionCreators = createAsyncActionCreators<AppState, string[]>(
  'app.acl',
  {
    fetchHandler: () => {
      return aclService.listAcls();
    },
  },
);

export const { fetch: fetchAclList } = actionCreators;
