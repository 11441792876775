import {
  TemplateProperty,
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
} from './types';

export class DeliveryCheckFinishedTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  getOverriddenProperties(): TemplateProperty[] {
    return ['deliveryAt', 'qualityInspector'].map(x => ({
      property: x,
      label: `integration.templates.props.delivery_check_finished.${x}`,
    }));
  }

  getOverriddenExample(destType: TemplateMsgDestType) {
    if (destType === 'customer') {
      return {
        title: '尊敬的车主，您的爱车已完成施工并通过质检，点击查看质检报告详情',
        status: '交车检查已完成',
        remark:
          '请联系您的服务顾问陈美兰取车。若对施工报告等有任何疑问，请联系门店服务顾问',
      };
    }
    return {
      title: '您所服务的车辆已完成车辆施工并通过质检，请联系客户取车',
      status: '交车检查已完成',
      remark: '点击进入查看质检报告详情',
    };
  }
}
