/**
 * @file: ProductReferralStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ProductReferralStatus {
  Submitted = 'submitted',
  AwaitingApproval = 'awaiting_approval',
  ApprovalFinished = 'approval_finished',
  Resolved = 'resolved',
}

export const ProductReferralStatusValueSet = new Set([
  ProductReferralStatus.Submitted,
  ProductReferralStatus.AwaitingApproval,
  ProductReferralStatus.ApprovalFinished,
  ProductReferralStatus.Resolved,
]);
