/**
 * @file: SubscribeSceneType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum SubscribeSceneType {
  Search = 'ADD_SCENE_SEARCH',
  AccountMigration = 'ADD_SCENE_ACCOUNT_MIGRATION',
  ProfileCard = 'ADD_SCENE_PROFILE_CARD',
  QrCode = 'ADD_SCENE_QR_CODE',
  ProfileLink = 'ADD_SCENE_PROFILE_LINK',
  ProfileItem = 'ADD_SCENE_PROFILE_ITEM',
  Paid = 'ADD_SCENE_PAID',
  WechatAdvertisement = 'ADD_SCENE_WECHAT_ADVERTISEMENT',
  Others = 'ADD_SCENE_OTHERS'
}

export const SubscribeSceneTypeValueSet = new Set([
  SubscribeSceneType.Search,
  SubscribeSceneType.AccountMigration,
  SubscribeSceneType.ProfileCard,
  SubscribeSceneType.QrCode,
  SubscribeSceneType.ProfileLink,
  SubscribeSceneType.ProfileItem,
  SubscribeSceneType.Paid,
  SubscribeSceneType.WechatAdvertisement,
  SubscribeSceneType.Others
]);