/**
 * @file: LabelColors.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StateColorType } from 'lib/metronic';

export type ColorValue = StateColorType;

export type ColorStore = { [key: string]: { [value: string]: ColorValue } };

const conf = require('./LabelColors.yaml');

export const LabelColors: ColorStore = conf.colors;
