/**
 * @file: AutoPartNameService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { AutoPartNameServiceBase } from './base/AutoPartNameService';

export class AutoPartNameService extends AutoPartNameServiceBase {
  // add custom service implementation here
}
