/**
 * @file: OrgUserService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { OrgUserVisibleStore } from 'model';
import { OrgUserServiceBase } from './base/OrgUserService';

export class OrgUserService extends OrgUserServiceBase {
  async getOrgUserAcl(userId: number): Promise<string[]> {
    return await this.api
      .get()
      .url('/org-users/:userId/acl', { userId })
      .future();
  }

  async setOrgUserAcl(
    userId: number,
    grantedAccesses: string[],
  ): Promise<string[]> {
    return await this.api
      .put()
      .url('/org-users/:id/acl', { id: userId })
      .data({ grantedAccesses })
      .future();
  }

  async getUserVisibleStores(userId: number): Promise<OrgUserVisibleStore[]> {
    return await this.api
      .get()
      .url('/org-users/:id/visible-stores', { id: userId })
      .future();
  }

  async createVisibleStore(
    userId: number,
    storeId: number,
    grantedAccesses: string[],
  ): Promise<OrgUserVisibleStore> {
    return await this.api
      .post()
      .url('/org-users/:id/visible-stores', { id: userId })
      .data({ storeId, grantedAccesses })
      .future();
  }

  async updateVisibleStoreAcl(
    userId: number,
    storeId: number,
    grantedAccesses: string[],
  ): Promise<OrgUserVisibleStore> {
    return await this.api
      .put()
      .url('/org-users/:id/visible-stores/:storeId/acl', {
        id: userId,
        storeId,
      })
      .data({ grantedAccesses })
      .future();
  }

  async deleteVisibleStore(
    userId: number,
    storeId: number,
  ): Promise<OrgUserVisibleStore> {
    return await this.api
      .delete()
      .url('/org-users/:id/visible-stores/:storeId', { id: userId, storeId })
      .future();
  }

  async renderUserAuthQrcode(
    userId: number,
    options?: { size?: number; margin?: number },
  ): Promise<{
    dataURI: string;
    ttl: number;
  }> {
    return await this.api
      .get()
      .url('/org-users/auth/qrcode', { userid: userId, ...options })
      .future();
  }
}
