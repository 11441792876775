import {
  MpConditionalMenuMatchRule,
  MpKeyBasedMenuItem,
  MpMenuItem,
  MpMenuItemMiniprogram,
  MpMenuItemType,
  MpMenuItemView,
  WeixinImageMsg,
  WeixinMsgType,
  WeixinMusicMsg,
  WeixinNewsMsg,
  WeixinTextMsg,
  WeixinVideoMsg,
  WeixinVoiceMsg,
} from 'model';
import { getString } from 'shared/components';
import { WeixinMenuEditorInfo } from '../duck/states';

const BUTTON_NOS = ['first', 'second', 'third', 'fourth', 'fifth'];

export interface MenuValidateResult {
  message: string;
  path: number[] | null;
}

type MsgType = WeixinTextMsg &
  WeixinImageMsg &
  WeixinVideoMsg &
  WeixinVoiceMsg &
  WeixinMusicMsg &
  WeixinNewsMsg;

export function isConditonalMenuHasRule(rule: MpConditionalMenuMatchRule) {
  return Object.keys(rule).some(x => Boolean((rule as any)[x]));
}

function validateButton(
  menuName: string,
  keyData: { [key: string]: any },
  button: MpMenuItem,
  i: number,
  j?: number,
): MenuValidateResult {
  const result: MenuValidateResult = {
    message: '',
    path: j === undefined ? [i] : [i, j],
  };

  const error = (message: string) => {
    result.message = message;
    return result;
  };

  const buttonName =
    j === undefined
      ? getString(`integration.menu.button_no.${BUTTON_NOS[i]}`, {
          menu: menuName,
        })
      : getString(`integration.menu.sub_button_no.${BUTTON_NOS[j]}`, {
          button: getString(`integration.menu.button_no.${BUTTON_NOS[i]}`, {
            menu: menuName,
          }),
        });

  const buttonError = (type: string) => {
    return error(
      getString(`integration.menu.error.button.${type}`, {
        button: buttonName,
      }),
    );
  };

  if (button.subButton) {
    if (!button.subButton.length) {
      return error(
        getString('integration.menu.error.no_submenu_button', {
          button: buttonName,
        }),
      );
    }
    let k = 0;
    for (const subButton of button.subButton) {
      const res = validateButton(menuName, keyData, subButton, i, k);
      if (res.message) return res;
      k++;
    }
    return result;
  }

  if (button.type === MpMenuItemType.Click) {
    const { key } = button as MpKeyBasedMenuItem;
    const data = keyData[key] as MsgType;
    switch (data.msgType) {
      case WeixinMsgType.Text: {
        if (!data.content) {
          return buttonError('no_text_content');
        }
        break;
      }
      case WeixinMsgType.Image: {
        if (data.image && data.image.__isTempMedia) {
          return buttonError('temp_media');
        }
        if (!data.image?.mediaId) {
          return buttonError('no_image');
        }
        break;
      }
      case WeixinMsgType.Video: {
        if (data.video && data.video.__isTempMedia) {
          return buttonError('temp_media');
        }
        if (!data.video?.mediaId) {
          return buttonError('no_video');
        }
        break;
      }
      case WeixinMsgType.Voice: {
        if (data.voice && data.voice.__isTempMedia) {
          return buttonError('temp_media');
        }
        if (!data.voice?.mediaId) {
          return buttonError('no_voice');
        }
        break;
      }
      case WeixinMsgType.Music: {
        if (!data.music?.title) {
          return buttonError('no_music_title');
        }
        if (!data.music?.musicUrl) {
          return buttonError('no_music_url');
        }
        if (!data.music?.thumbMediaId) {
          return buttonError('no_music_thumb_pic');
        }
        break;
      }
      case WeixinMsgType.News: {
        if (!data.articleCount) {
          return buttonError('no_news');
        }
        break;
      }
    }
  } else if (button.type === MpMenuItemType.View) {
    const viewButton = button as MpMenuItemView;
    if (!viewButton.url) {
      return buttonError('no_view_url');
    }
  } else if (button.type === MpMenuItemType.Miniprogram) {
    const miniprogramButton = button as MpMenuItemMiniprogram;
    if (!miniprogramButton.appid) {
      return buttonError('no_appid');
    }
    if (!miniprogramButton.pagepath) {
      return buttonError('no_pagepath');
    }
    if (!miniprogramButton.url) {
      return buttonError('no_fallback_url');
    }
  }

  return result;
}

export function validateMenuEditor(
  editor: WeixinMenuEditorInfo,
  conditionalMenuDditorIndex: number,
  keyData: { [key: string]: any },
): MenuValidateResult {
  const result: MenuValidateResult = { message: '', path: null };

  const error = (message: string, path?: number[]) => {
    result.message = message;
    result.path = path || null;
    return result;
  };

  const menuName = editor.isDefaultMenu
    ? getString('integration.menu.default_menu_name')
    : getString('integration.menu.conditional_menu_name', {
        number: conditionalMenuDditorIndex + 1,
      });

  if (!editor.menu.button.length) {
    return error(
      getString('integration.menu.error.no_menu_button', { menu: menuName }),
    );
  }

  if (
    !editor.isDefaultMenu &&
    (!editor.menu.matchrule || !isConditonalMenuHasRule(editor.menu.matchrule))
  ) {
    return error(
      getString('integration.menu.error.no_rule_specified', { menu: menuName }),
    );
  }

  let i = 0;
  for (const button of editor.menu.button) {
    const res = validateButton(menuName, keyData, button, i);
    if (res.message) return res;
    i++;
  }

  return result;
}
