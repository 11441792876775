import {
  ConstructionTemplateConfig,
  MaterialType,
  QuotationCategoryRef,
  QuotationGroupRef,
  QuotationItemRef,
  QuotationMaterialRef,
  QuotationSubjectRef,
  QuotationTemplateCategory,
  QuotationTemplateCategoryStaged,
  QuotationTemplateConfig,
  QuotationTemplateConfigStaged,
  QuotationTemplateGroup,
  QuotationTemplateGroupStaged,
  QuotationTemplateItemStaged,
  QuotationTemplateMaterialStaged,
  QuotationTemplateSubject,
  QuotationTemplateSubjectStaged,
} from 'model';
import { makeUniqueIdAlphabetic } from 'utils/id';

export const keyOfMaterial = (x: {
  name: string;
  partNumber?: string | null;
  type?: MaterialType | null;
  brandName?: string | null;
}) =>
  (x.partNumber
    ? [x.name, x.partNumber]
    : [x.name, x.type ?? '', x.brandName ?? '']
  ).join(':');

export const makeGroupRef = (
  categoryRef: QuotationCategoryRef,
  groupId: string,
): QuotationGroupRef => ({
  ...categoryRef,
  groupId,
});

export const makeSubjectRef = (
  groupRef: QuotationGroupRef,
  subjectId: string,
): QuotationSubjectRef => ({
  ...groupRef,
  subjectId,
});

export const makeItemRef = (
  subjectRef: QuotationSubjectRef,
  itemId: string,
): QuotationItemRef => ({
  ...subjectRef,
  itemId,
});

export const makeMaterialRef = (
  itemRef: QuotationItemRef,
  materialId: string,
): QuotationMaterialRef => ({
  ...itemRef,
  materialId,
});

export function isCategoryConfigValid(category: QuotationTemplateCategory) {
  if (category.name == null || category.name.trim().length === 0) {
    return false;
  }
  for (const group of category.groups) {
    if (!group.name || group.name.trim().length === 0) {
      return false;
    }
    for (const subject of group.subjects) {
      if (!subject.name || subject.name.trim().length === 0) {
        return false;
      }
      for (const item of subject.items) {
        if (!item.name || item.name.trim().length === 0) {
          return false;
        }
      }
    }
  }
  return true;
}

export function isQuotationTemplateConfigValid(
  templateConfig: QuotationTemplateConfigStaged,
) {
  return templateConfig.categories.every(isCategoryConfigValid);
}

export function quotationTemplateConfigToStaged(
  quotationTemplateConfig: QuotationTemplateConfig,
): QuotationTemplateConfigStaged {
  return {
    categories:
      quotationTemplateConfig.categories.map<QuotationTemplateCategoryStaged>(
        category => ({
          id: category.id,
          name: category.name,
          icon: category.icon,
          description: category.description,
          groups: category.groups.map<QuotationTemplateGroupStaged>(group => ({
            id: group.id,
            categoryId: category.id,
            name: group.name,
            icon: group.icon,
            description: group.description,
            subjects: group.subjects.map<QuotationTemplateSubjectStaged>(
              subject => ({
                id: subject.id,
                groupRef: {
                  categoryId: category.id,
                  groupId: group.id,
                },
                name: subject.name,
                code: subject.code,
                pyInitial: subject.pyInitial,
                keywords: subject.keywords,
                items: subject.items.map<QuotationTemplateItemStaged>(item => ({
                  ...item,
                  subjectRef: {
                    categoryId: category.id,
                    groupId: group.id,
                    subjectId: subject.id,
                  },
                  materials:
                    item.materials?.map<QuotationTemplateMaterialStaged>(
                      material => ({
                        ...material,
                        itemRef: {
                          categoryId: category.id,
                          groupId: group.id,
                          subjectId: subject.id,
                          itemId: item.id,
                        },
                      }),
                    ),
                })),
              }),
            ),
          })),
        }),
      ),
  };
}

export function quotationTemplateConfigFromStaged(
  staged: QuotationTemplateConfigStaged,
): QuotationTemplateConfig {
  return {
    version: '1.0',
    categories: staged.categories.map<QuotationTemplateCategory>(category => ({
      id: category.id,
      name: category.name,
      icon: category.icon,
      description: category.description,
      groups: category.groups.map<QuotationTemplateGroup>(group => ({
        id: group.id,
        name: group.name,
        icon: group.icon,
        description: group.description,
        subjects: group.subjects.map<QuotationTemplateSubject>(subject => ({
          id: subject.id,
          name: subject.name,
          code: subject.code,
          pyInitial: subject.pyInitial,
          keywords: subject.keywords,
          items: subject.items,
        })),
      })),
    })),
  };
}

export function constructionTemplateConfigToStaged(
  constructionTemplateConfig: ConstructionTemplateConfig,
): QuotationTemplateConfigStaged {
  return {
    categories:
      constructionTemplateConfig.categories.map<QuotationTemplateCategoryStaged>(
        category => {
          const categoryId = makeUniqueIdAlphabetic();
          return {
            id: categoryId,
            name: category.name,
            icon: category.icon,
            description: category.description,
            groups: category.groups.map<QuotationTemplateGroupStaged>(
              group => ({
                id: makeUniqueIdAlphabetic(),
                categoryId,
                name: group.name,
                icon: group.icon,
                description: group.description,
                subjects: [],
              }),
            ),
          };
        },
      ),
  };
}

export function findQuotationGroupByRef(
  staged: QuotationTemplateConfigStaged,
  ref: QuotationGroupRef,
): QuotationTemplateGroupStaged | undefined {
  const category = staged.categories.find(c => c.id === ref.categoryId);
  if (!category) return;
  return category.groups.find(g => g.id === ref.groupId);
}

export function findQuotationSubjectByRef(
  staged: QuotationTemplateConfigStaged,
  ref: QuotationSubjectRef,
): QuotationTemplateSubjectStaged | undefined {
  const category = staged.categories.find(c => c.id === ref.categoryId);
  if (!category) return;
  const group = category.groups.find(g => g.id === ref.groupId);
  if (!group) return;
  return group.subjects.find(s => s.id === ref.subjectId);
}

export function findQuotationItemByRef(
  staged: QuotationTemplateConfigStaged,
  ref: QuotationItemRef,
): QuotationTemplateItemStaged | undefined {
  const category = staged.categories.find(c => c.id === ref.categoryId);
  if (!category) return;
  const group = category.groups.find(g => g.id === ref.groupId);
  if (!group) return;
  const subject = group.subjects.find(s => s.id === ref.subjectId);
  if (!subject) return;
  return subject.items.find(i => i.id === ref.itemId);
}
