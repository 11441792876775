import { Translate } from 'react-localize-redux';

interface Props {
  value: number | null | undefined;
}

export function Mileage(props: Props) {
  const mileage = props.value;
  if (!mileage) return <>{'-'}</>;
  return (
    <>
      {mileage}
      <Translate id="mileage_spacer" />
      <Translate id="value_unit.km" />
    </>
  );
}
