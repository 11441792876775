import { useAppContext } from 'app/AppContext';
import React, { ReactNode, memo } from 'react';
import { CSSProperties } from 'styled-components';

export const StoreName = memo(
  ({
    storeId,
    mode,
    defaultContent,
    style,
    className,
  }: {
    storeId?: number | null;
    mode?: 'org' | 'store' | 'default';
    style?: CSSProperties;
    className?: string;
    defaultContent?: ReactNode;
  }) => {
    const { org, stores } = useAppContext();

    const store = stores.find(x => x.id === storeId);

    if (mode === 'store') {
      return (
        <span style={style} className={className}>
          {store?.name || defaultContent}
        </span>
      );
    }

    if (mode === 'org') {
      return (
        <span style={style} className={className}>
          {org.name || defaultContent}
        </span>
      );
    }

    return (
      <span style={style} className={className}>
        {store?.name ?? defaultContent ?? org.name}
      </span>
    );
  },
);
