import {
  TemplateProperty,
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
} from './types';

export class ConstructionFinishedTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  getOverriddenProperties(): TemplateProperty[] {
    return ['constructedAt', 'constructionItems'].map(x => ({
      property: x,
      label: `integration.templates.props.construction_finished.${x}`,
    }));
  }

  getOverriddenExample(destType: TemplateMsgDestType) {
    if (destType === 'customer') {
      return {
        title: '尊敬的车主，您的爱车已完成施工，请点击查看施工报告详情',
        status: '施工已完成',
        remark: '若对施工报告等有任何疑问，请联系门店服务顾问',
      };
    }
    return {
      title: '您所服务的车辆已完成车辆施工工作，请及时跟进服务进度',
      status: '施工已完成',
      remark: '点击进入查看施工报告详情',
    };
  }
}
