import React, { PureComponent } from 'react';
import { ListToolbar, ToolbarItem, ToolbarItemsBuilder } from 'shared/components';
import { ItemSourceFilter, ItemSourceFilterList } from 'shared/types';

interface Props {
  source: ItemSourceFilter;
  selection: number[];
  onDelete: () => void;
  onSourceChange: (source: ItemSourceFilter) => void;
}

const noop = () => { /* noop */ };

export class InventoryManagerComplement extends PureComponent<Props> {
  render() {
    return (
      <ListToolbar
        filter={{}}
        items={this.buildItems()}
        onFilterChange={noop}
        onGetExtraInfo={noop}
        />
    );
  }

  buildItems(): Array<ToolbarItem<any>> {
    const { source: activeSource, selection, onDelete } = this.props;
    const builder = new ToolbarItemsBuilder<any>();
    return builder.button({
      key: 'delete',
      text: '@string/delete_btn_text',
      color: 'danger',
      onClick: onDelete,
      size: 'small',
      disabled: selection.length === 0,
      hidden: selection.length === 0
    }).buttonGroup(group => {
      group.withPlacement('right').small();
      for (const source of ItemSourceFilterList) {
        group.button({
          key: source,
          text: `item_source.${source}`,
          // color: activeSource === source ? 'brand' : 'secondary',
          outline: activeSource !== source,
          color: 'primary',
          context: source,
          onClick: this.onSourceClick
        });
      }
    }).build();
  }

  onSourceClick = (_: any, source: ItemSourceFilter) => {
    if (this.props.source !== source) {
      this.props.onSourceChange(source);
    }
  }
}