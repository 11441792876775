import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { MpTemplateIndustry } from 'model';
import { MpTemplateIndustries } from '../states';

const initialState: MpTemplateIndustries = {
  isLoading: false,
  error: null,
  result: null
};

export default createAsyncListActionReducers<MpTemplateIndustry, MpTemplateIndustries, string>(
  'integration.templates.industries',
  initialState,
  {
    mapItemKey(industry: MpTemplateIndustry) { return industry.industryNo; }
  }
);