import { applyMiddleware, createStore, Store } from 'redux';

import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { rootReducer } from './reducers';
import { AppState } from './states';

const middleware = applyMiddleware(thunk, logger as any);

export const store: Store<AppState> = createStore(rootReducer, middleware);
