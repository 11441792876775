import { OrgMember } from 'model';
import { PureComponent } from 'react';
import { MemberPicker } from 'shared/components/MemberPicker';
import { EntityWithStoreBounded, FormElementMemberPicker } from '../types';

interface Props<T extends EntityWithStoreBounded> {
  element: FormElementMemberPicker<T>;
  disabled?: boolean;
  orgId: number;
  storeId?: number | null;
  memberId?: number | null;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormMemberPicker<
  T extends EntityWithStoreBounded,
> extends PureComponent<Props<T>> {
  render() {
    const { orgId, storeId, memberId, disabled } = this.props;
    return (
      <MemberPicker
        orgId={orgId}
        storeId={storeId}
        memberId={memberId}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }

  onChange = (memberId: number | undefined, member: OrgMember | null) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = memberId as any;
    void element.onChange?.(changes as any, extra, member);
    this.props.onChange(changes as T);
  };
}
