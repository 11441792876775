import React, { Component, ReactNode, MouseEvent } from 'react';
import classNames from 'classnames';
import { StatAspect } from './duck/states';
import { TranslateFunction, LocalizeContextProps, withLocalize } from 'react-localize-redux';

interface Props extends LocalizeContextProps {
  aspectType: StatAspect;
  label: string | ReactNode;
  value: number | null | undefined;
  changeLabel?: string | ReactNode;
  prevValue?: number | undefined | null;
  showPrevValue?: boolean;
  formatter?: (value: number, translate: TranslateFunction) => string | ReactNode;
  active?: boolean;
  onClick?: (aspectType: StatAspect) => void;
}

class StatAspectItemComponent extends Component<Props> {
  render() {
    const {
      label,
      value,
      prevValue,
      formatter,
      changeLabel,
      active,
      showPrevValue
    } = this.props;
    const change = typeof value === 'number' &&
      typeof prevValue === 'number' ? value - prevValue : null;
    return (
      <div
        className={classNames('stat-aspect-item', {
          'stat-aspect-item--active': active
        })}
        onClick={this.onItemClick}
        >
        <div>
          <h4>{label}</h4>
          <div className="stat-aspect-item__value">
          {typeof value === 'number' ?
            (formatter ? formatter(value, this.props.translate) : value) :
            '--'
          }
          </div>
          <div className="stat-aspect-item__change">
          {changeLabel ? <>{changeLabel}: </> : ''}
          {change !== null ?
            <span className={change >= 0 ? 'text-danger' : 'text-success'}>
              {(prevValue === 0 ?
                (value === 0 ? '0' : '100') :
                (Math.abs(change) / prevValue! * 100).toFixed(1)
                ) + '%'}
            </span> : (showPrevValue ? '-' : '') }
          {change !== null ? (
            change >= 0 ?
              <i className="la la-arrow-up text-danger" /> :
              <i className="la la-arrow-down text-success" />
          ) : ''}
          </div>
        </div>
      </div>
    );
  }

  onItemClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.props.onClick && this.props.onClick(this.props.aspectType);
  }
}

export const StatAspectItem = withLocalize<Props>(StatAspectItemComponent);