import { Button } from 'lib/metronic/components';
import { PureComponent, ReactNode } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { getString, StringLabel } from '../StringLabel';

interface Props {
  title?: string;
  message: ReactNode;
  isOpen?: boolean;
  onClose: () => void;
}

export class AppModal extends PureComponent<Props> {
  render() {
    const { title, isOpen, message } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onClosed={this.props.onClose}
        toggle={this.props.onClose}
      >
        <ModalHeader toggle={this.props.onClose}>
          <StringLabel value={title} />
        </ModalHeader>
        <ModalBody>
          {typeof message === 'string' ? (
            <ReactMarkdown>{getString(message)}</ReactMarkdown>
          ) : (
            message
          )}
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          <Button wide color="brand" onClick={this.props.onClose}>
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
