import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleInspectionComment } from 'model';
import { RecentInspectionComments } from '../states';

const initialState: RecentInspectionComments = {
  isLoading: false,
  error: null,
  result: null,
  filter: {},
  sorts: [{ property: 'createdAt', dir: 'desc' }],
  offset: 0,
  limit: 15
};

export default createAsyncListActionReducers<VehicleInspectionComment, RecentInspectionComments>(
  'dashboard.recent_inspection_comments',
  initialState,
  {
    mapItemKey: x => x.id
  }
);