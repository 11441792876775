/**
 * @file: OrderReservationType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum OrderReservationType {
  None = 0,
  Reserved,
  NonReserved,
}

export const OrderReservationTypeValueSet = new Set([
  OrderReservationType.None,
  OrderReservationType.Reserved,
  OrderReservationType.NonReserved,
]);
