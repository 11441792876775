import { store } from 'app/duck/store';
import { ReactNode } from 'react';
import { Translate, getTranslate } from 'react-localize-redux';

interface Props {
  value: string | ReactNode | null | undefined;
}

export function StringLabel(props: Props) {
  const { value } = props;
  if (value === null || value === undefined) return null;
  if (typeof value === 'string') {
    if (/^[a-z0-9_-]+(\.[a-z0-9_-]+)+$/.test(value)) {
      return <Translate id={value} />;
    }
    const match = /^@string\/(.+)$/.exec(value);
    if (match) {
      return <Translate id={match[1]} />;
    }
  }
  return <>{value}</>;
}

export function getString(prop: string | null | undefined, data?: any): string {
  if (prop === null || prop === undefined) return '';
  if (typeof prop === 'string') {
    if (/^[a-z0-9_-]+(\.[a-z0-9_-]+)+$/i.test(prop)) {
      const trans = getTranslate(store.getState().localize);
      return trans(prop, data) as string;
    }
    const match = /^@string\/(.+)$/.exec(prop);
    if (match) {
      const trans = getTranslate(store.getState().localize);
      return trans(match[1], data) as string;
    }
  }
  return prop;
}

(window as any).getString = getString;
