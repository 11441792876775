/**
 * @file: DeliveryCheckTemplateType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum DeliveryCheckTemplateType {
  WithInspectionResults = 0,
  WithoutInspectionResults = 1,
}

export const DeliveryCheckTemplateTypeValueSet = new Set([
  DeliveryCheckTemplateType.WithInspectionResults,
  DeliveryCheckTemplateType.WithoutInspectionResults,
]);
