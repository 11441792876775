/**
 * @file: acl.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncActionReducers } from 'lib/duck/reducers';
import { AclList } from '../states';

const initialState: AclList = {
  isLoading: false,
  error: null,
  result: null,
};

export default createAsyncActionReducers<string[], AclList>(
  'app.acl',
  initialState,
);
