import { combineReducers, Reducer } from 'redux';
import { AppState } from 'app/duck/states';
import { localizeReducer } from 'react-localize-redux';
import identity from './user';
import ui from './ui';
import areas from './areas';
import acl from './acl';
import dashboard from 'app/dashboard/duck/reducers';
import org from 'app/org/duck/reducers';
import integration from 'app/integration/duck/reducers';
import inspection from 'app/inspection/duck/reducers';
import orders from 'app/orders/duck/reducers';
import tasks from 'app/tasks/duck/reducers';
import reports from 'app/reports/duck/reducers';
import modal from './modal';
import loading from './loading';
import activeStoreId from './activeStore';
import dataExportTasks from './data-export-task';
import products from 'app/products/duck/reducers';

export const rootReducer: Reducer<AppState> = combineReducers({
  localize: localizeReducer,
  activeStoreId,
  identity,
  ui,
  areas,
  acl,
  dashboard,
  org,
  integration,
  inspection,
  orders,
  tasks,
  reports,
  modal,
  loading,
  dataExportTasks,
  products,
});
