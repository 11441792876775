import React, { PureComponent, MouseEvent } from 'react';
import { WeixinMenuEditorInfo } from 'app/integration/duck/states';
import { TabNav } from 'lib/metronic/components';
import { Translate } from 'react-localize-redux';
import { getString } from 'shared/components';
import { isConditonalMenuHasRule } from '../helpers/validate';

interface Props {
  editors: WeixinMenuEditorInfo[];
  activeEditorId: number;
  onMenuChange: (id: number) => void;
  onAddMenu: () => void;
}

export class MenuList extends PureComponent<Props> {
  render() {
    const { editors, activeEditorId } = this.props;
    const defaultMenuEditor = editors.find(x => x.isDefaultMenu);
    const conditionalMenuEditors = editors.filter(x => !x.isDefaultMenu);
    return (
      <TabNav line="brand" bolder className="mp-menu-list">
        {defaultMenuEditor &&
        <TabNav.Item
          active={activeEditorId === defaultMenuEditor.id}
          onClick={this.onTabItemClick}
          data-editor-id={defaultMenuEditor.id}
          >
          <i className="flaticon2-list-1" />
          <Translate id="integration.menu.default_menu_name" />
        </TabNav.Item>}
        {conditionalMenuEditors.map((editor, i) => (
          <TabNav.Item
            key={editor.id}
            active={activeEditorId === editor.id}
            onClick={this.onTabItemClick}
            data-editor-id={editor.id}
            data-toggle={!isConditonalMenuHasRule(editor.menu.matchrule!) ? 'tooltip' : ''}
            title={getString('integration.menu.pmc.error.no_rule')}
            >
            <i className="flaticon2-avatar" />
            <Translate id="integration.menu.conditional_menu_name" data={{ number: i + 1 }} />
            {!isConditonalMenuHasRule(editor.menu.matchrule!) && (
              <i
                className="fa fa-exclamation-circle kt-font-warning"
                style={{ marginLeft: 8 }}
                />
            )}
          </TabNav.Item>
        ))}
      </TabNav>
    );
  }

  onTabItemClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const editorId = Number(e.currentTarget.getAttribute('data-editor-id'));
    if (this.props.activeEditorId !== editorId) {
      this.props.onMenuChange(editorId);
    }
  }

  onAddMenu = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.onAddMenu();
  }
}