/**
 * @file: service-subject.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleServiceSubject } from 'model';
import { VehicleServiceSubjects } from '../states';

const initialState: VehicleServiceSubjects = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {}
};

export default createAsyncListActionReducers<VehicleServiceSubject, VehicleServiceSubjects>(
  'inspection.service_subjects',
  initialState,
  {
    mapItemKey: x => x.id
  }
);