import { Button } from 'lib/metronic/components';
import { useConstructionTemplateConfigContext } from './Context';
import { TemplatePicker } from './TemplatePicker';
import { ConstructionTemplateGroup } from 'model';
import React, { memo, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap/lib';
import { getString } from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';

export const GroupModal = memo(
  ({
    group,
    onCancel,
    onConfirm,
  }: {
    onCancel?: () => void;
    group?: ConstructionTemplateGroup;
    onConfirm?: (info: {
      id: string;
      name: string;
      description?: string;
      templateId?: string;
    }) => void;
  }) => {
    const groupId = group?.id;

    const {
      templateConfig: { templates },
    } = useConstructionTemplateConfigContext();

    const [name, setName] = useState('');
    const [description, setDescription] = useState<string>();
    const [templateId, setTemplateId] = useState<string>();

    useEffect(() => {
      if (group == null) return;
      setName(group.name);
      setDescription(group.description);
      setTemplateId(group.templateId);
    }, [group]);

    const handleConfirm = usePersistFn(() => {
      if (groupId == null || !name?.trim()) return;
      onConfirm?.({
        id: groupId,
        name: name.trim(),
        description: description?.trim(),
        templateId,
      });
    });

    const disabled = !name?.trim();

    return (
      <Modal
        isOpen={group != null}
        modalClassName="construction-tpl-man__group-modal"
      >
        <ModalHeader>
          <Translate id="construction_tpl.group.modal.title" />
        </ModalHeader>
        <ModalBody>
          <dl>
            <dt>
              <Translate id="construction_tpl.group.label.name" />
            </dt>
            <dd>
              <input
                type="text"
                name="name"
                className="form-control"
                value={name ?? ''}
                onChange={e => setName(e.target.value)}
                placeholder={getString(
                  'construction_tpl.group.placeholder.name',
                )}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <Translate id="construction_tpl.group.label.description" />
            </dt>
            <dd>
              <input
                type="text"
                name="description"
                className="form-control"
                value={description ?? ''}
                onChange={e => setDescription(e.target.value)}
                placeholder={getString(
                  'construction_tpl.group.placeholder.description',
                )}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <Translate id="construction_tpl.group.label.template" />
            </dt>
            <dd>
              <TemplatePicker
                templates={templates}
                value={templateId}
                onChange={setTemplateId}
                placeholder={getString(
                  'construction_tpl.group.placeholder.template',
                )}
              />
            </dd>
          </dl>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="primary" onClick={handleConfirm} disabled={disabled}>
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
