/**
 * @file: mini-program-link-state.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { createAsyncActionCreators, createStandardAction } from 'lib/duck/actions';
import { ActionThunk } from 'lib/duck/interfaces';
import { weixinService } from 'services';
import { ActionTypes } from '../types';

export const miniProgramLinkStateActions = createAsyncActionCreators('integration.miniprogram', {
  fetchHandler: () => {
    return weixinService.getMiniProgramLinkState();
  }
});

export function requestLinkMiniProgram(): ActionThunk<AppState> {
  return async (dispatch) => {
    dispatch(createStandardAction(ActionTypes.RequestLinkMiniProgram));
    try {
      const result = await weixinService.requestLinkMiniProgram();
      dispatch(createStandardAction(ActionTypes.RequestLinkMiniProgramSuccess, result));
    } catch (e) {
      dispatch(createStandardAction(ActionTypes.RequestLinkMiniProgramFailed, e));
    }
  };
}

export function unlinkMiniProgram(): ActionThunk<AppState> {
  return async (dispatch) => {
    dispatch(createStandardAction(ActionTypes.UnlinkMiniProgram));
    try {
      await weixinService.unlinkMiniProgram();
      dispatch(createStandardAction(ActionTypes.UnlinkMiniProgramSuccess));
    } catch (e) {
      dispatch(createStandardAction(ActionTypes.UnlinkMiniProgramFailed, e));
    }
  };
}