import { createKeyedListActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { WeixinPermanentMaterialType, WeixinPermanentMaterialListItem } from 'model';
import { weixinService } from 'services';
import { WeixinPermanentMaterialList } from '../states';

export const permanentMaterialActions = createKeyedListActionCreators<
  AppState,
  WeixinPermanentMaterialType,
  WeixinPermanentMaterialListItem,
  any
>('integration.materials', {
  pagingMode: 'normal',
  getListKeyStringRepresentation: (key: any) => key,
  shouldFetchOnInvalidate: true,
  getState: (state: AppState, key: WeixinPermanentMaterialType) => state.integration.materials.keyedLists[key as any],
  async fetchHandler(materialType: WeixinPermanentMaterialType, listState: WeixinPermanentMaterialList) {
    return await weixinService.listPermanentMaterials(
      materialType as any,
      listState.offset!,
      listState.limit!
      );
  }
});