import { showAppLoading } from 'app/duck/actions';
import {
  AclObjectList,
  IdType,
  Maybe,
  ProductAuthorizationContractClientConfig,
} from 'model';
import {
  AuthorizedProductSearchParams,
  ProductWithContractId,
} from 'model/app/product';
import {
  MouseEvent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { productAuthorizationService } from 'services';
import {
  EntityListComponentClassBuilder,
  EntityListProps,
  ProductAuthorizationContractClientEntityTypeLabel,
  ProductStatusLabel,
  getString,
} from 'shared/components';
import { authorizedProductActions } from '../duck/actions';

interface Props
  extends EntityListProps<
    ProductWithContractId,
    AuthorizedProductSearchParams,
    IdType
  > {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  ProductWithContractId,
  AuthorizedProductSearchParams,
  IdType,
  Props
>();

export const AuthorizedProductList = componentClassBuilder
  .i18nPrefix('authorized_product')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/shopping/wallet.svg')
      .default,
  )
  .accessRights({
    readonly: AclObjectList.ProductReadonlyAccess,
    full: AclObjectList.ProductFullAccess,
  })
  .breadcrumbs([
    { text: <Translate id="product.breadcrumb.it" /> },
    { text: <Translate id="product.breadcrumb.authorized_products" /> },
  ])
  .features({ addEntity: false, deleteEntity: false })
  .entities(state => state.products.authorizedProducts)
  .actions(authorizedProductActions)
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'keyword',
        width: 175,
        placeholder: 'authorized_product.toolbar.placeholder.keyword',
      })
      .button({
        buttonType: 'search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(authorizedProductActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'id',
      width: 150,
      text: 'col.id',
      hidden: true,
    },
    {
      prop: 'coverUrl',
      width: 80,
      text: 'authorized_product.col.cover',
      render: ({ coverUrl, name }) => (
        <img
          src={coverUrl}
          alt={name}
          style={{
            width: 80,
            height: 80,
            borderRadius: 8,
            backgroundColor: '#ddd',
          }}
        />
      ),
    },
    {
      prop: 'name',
      width: 300,
      text: 'authorized_product.col.name',
      render: ({ id, name, title }) => {
        return (
          <dl>
            <dt>{name}</dt>
            <dd style={{ color: '#777' }}>{id}</dd>
            {title?.trim() ? <dd>{title}</dd> : null}
          </dl>
        );
      },
    },
    {
      prop: 'sourceContractId',
      width: 250,
      text: 'authorized_product.col.contract',
      render: ({
        sourceContractNumber,
        sourceContractClientType,
        sourceContractClientName,
      }) => {
        return (
          <dl>
            <dt>{sourceContractNumber}</dt>
            <dd>
              <ProductAuthorizationContractClientEntityTypeLabel
                value={sourceContractClientType}
              />
            </dd>
            <dd>{sourceContractClientName}</dd>
          </dl>
        );
      },
    },
    {
      prop: 'referringCommissionFee',
      width: 350,
      text: 'authorized_product.col.commission_fee',
      render: entity => {
        return <CommissionFeeInfo entity={entity} />;
      },
    },
    {
      prop: 'brandName',
      width: 100,
      text: 'authorized_product.col.brand_name',
    },
    {
      prop: 'agentName',
      width: 150,
      text: 'authorized_product.col.agent_name',
    },
    {
      prop: 'status',
      width: 100,
      text: 'col.status',
      align: 'center',
      render: ({ status }) => <ProductStatusLabel value={status} />,
    },
  ])
  .componentDidUpdate((props, prevProps) => {
    if (props.activeStoreId !== prevProps.activeStoreId) {
      props.dispatch(authorizedProductActions.invalidate(true));
    }
  })
  .getClass();

export const SaDistributionRatio = memo(
  ({
    entity,
    type,
    value,
    loading,
    error,
    onChange,
  }: {
    entity: ProductWithContractId;
    type: 'referring' | 'adoption';
    value: Maybe<number>;
    loading: boolean;
    error: Error | undefined;
    onChange?: (configValue: any) => void;
  }) => {
    const dispatch = useDispatch();

    const updateValue = useCallback(
      async (ratio: Maybe<number>) => {
        const configValue =
          type === 'referring'
            ? {
                productReferralServiceAgentDistributionRatio: ratio,
              }
            : {
                productAdoptionServiceAgentDistributionRatio: ratio,
              };

        try {
          dispatch(showAppLoading());
          await productAuthorizationService.saveClientConfig(
            entity.sourceContractId,
            entity.id,
            configValue,
          );
          dispatch(
            showAppLoading({
              status: 'success',
              message:
                'authorized_product.detail.sa_distribution_ratio.save_success',
            }),
          );
          onChange?.(configValue);
        } catch (e) {
          console.log(e);
          dispatch(
            showAppLoading({
              status: 'error',
              message: e.message,
              timeout: 3000,
            }),
          );
        }
      },
      [type, dispatch, entity.sourceContractId, entity.id, onChange],
    );

    const onEdit = useCallback(
      async (e: MouseEvent) => {
        e.preventDefault();
        const text = prompt(
          getString(
            'authorized_product.detail.sa_distribution_ratio.edit_msg.' + type,
          ),
          value != null ? (value * 100).toFixed(2) : '',
        )?.trim();
        if (!text) {
          return;
        }
        const ratio = parseFloat(text);
        if (ratio < 0 || ratio > 100) {
          alert(
            getString(
              'authorized_product.detail.sa_distribution_ratio.invalid_value',
            ),
          );
          return;
        }
        await updateValue(ratio / 100);
      },
      [type, value, updateValue],
    );

    const onRemove = useCallback(
      async (e: MouseEvent) => {
        e.preventDefault();
        if (
          confirm(
            getString(
              'authorized_product.detail.sa_distribution_ratio.confirm_remove',
            ),
          )
        ) {
          await updateValue(null);
        }
      },
      [updateValue],
    );

    // eslint-disable-next-line @typescript-eslint/init-declarations
    let content: ReactNode;
    if (error) {
      content = <span style={{ color: 'red' }}>error</span>;
    } else if (loading) {
      content = <span>--</span>;
    } else if (value) {
      content = <span>{(value * 100).toFixed(2)}%</span>;
    } else {
      content = <span>--</span>;
    }

    return (
      <span>
        {content}
        {error || loading ? null : (
          <>
            <a href="#" style={{ marginLeft: '0.1rem' }} onClick={onEdit}>
              <i className="la la-pencil" />
            </a>
            {value != null && (
              <a href="#" style={{ marginLeft: '0.1rem' }} onClick={onRemove}>
                <i className="la la-close" />
              </a>
            )}
          </>
        )}
      </span>
    );
  },
);

const CommissionFeeInfo = memo(
  ({ entity }: { entity: ProductWithContractId }) => {
    const [clientConfig, setClientConfig] =
      useState<ProductAuthorizationContractClientConfig | null>();
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      setLoading(true);
      setError(undefined);
      productAuthorizationService
        .getClientConfig(entity.sourceContractId, entity.id)
        .then(setClientConfig)
        .catch(err => {
          console.error(err);
          setError(err);
        })
        .finally(() => setLoading(false));
    }, [entity.id, entity.sourceContractId]);

    const onChange = useCallback((configValue: any) => {
      setClientConfig(c => ({ ...c, ...configValue }));
    }, []);

    return (
      <dl>
        <dd>
          <Translate id="authorized_product.detail.referring_commission_fee" />
          {`: ${(entity.referringCommissionFee / 100).toFixed(2)}元 [`}
          <span style={{ color: '#777' }}>
            <Translate id="authorized_product.detail.sa_distribution_ratio.label" />
          </span>
          {': '}
          <SaDistributionRatio
            entity={entity}
            type="referring"
            value={clientConfig?.productReferralServiceAgentDistributionRatio}
            loading={loading}
            error={error}
            onChange={onChange}
          />
          {']'}
        </dd>
        <dd>
          <Translate id="authorized_product.detail.adoption_commission_fee" />
          {`: ${(entity.adoptionCommissionFee / 100).toFixed(2)}元 [`}
          <span style={{ color: '#777' }}>
            <Translate id="authorized_product.detail.sa_distribution_ratio.label" />
          </span>
          {': '}
          <SaDistributionRatio
            entity={entity}
            type="adoption"
            value={clientConfig?.productAdoptionServiceAgentDistributionRatio}
            loading={loading}
            error={error}
            onChange={onChange}
          />
          {']'}
        </dd>
      </dl>
    );
  },
);
