import { Checkbox } from 'lib/metronic/components';
import { ChangeEvent, PureComponent } from 'react';
import { FormElementCheckboxList, FormElementCheckboxStyle } from '../types';

interface Props<T> {
  element: FormElementCheckboxList<T>;
  disabled?: boolean;
  options: any[] | null | undefined;
  value: any;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormCheckboxList<T> extends PureComponent<Props<T>> {
  render() {
    const { element, value, options, disabled } = this.props;
    const valueProp = element.valueProp || 'value';
    const labelProp = element.labelProp || 'label';

    return (
      <div
        className={element.inline ? 'kt-checkbox-inline' : 'kt-checkbox-list'}
      >
        {(options || []).map(option => {
          const checked = Boolean(
            element.isValueChecked
              ? element.isValueChecked(option[valueProp], value)
              : option[valueProp] === value,
          );
          const style: FormElementCheckboxStyle = {
            solid: checked
              ? element.checkedStyle &&
                typeof element.checkedStyle.solid === 'boolean'
                ? element.checkedStyle.solid
                : true
              : typeof element.solid === 'boolean'
                ? element.solid
                : false,
            tick: checked
              ? element.checkedStyle &&
                typeof element.checkedStyle.tick === 'boolean'
                ? element.checkedStyle.tick
                : false
              : typeof element.tick === 'boolean'
                ? element.tick
                : true,
            color: checked
              ? element.checkedStyle &&
                typeof element.checkedStyle.color === 'string'
                ? element.checkedStyle.color
                : 'brand'
              : typeof element.color === 'string'
                ? element.color
                : 'brand',
          };
          return (
            <Checkbox
              label={option[labelProp]}
              key={option[valueProp]}
              value={option[valueProp]}
              solid={style.solid}
              tick={style.tick}
              color={style.color}
              checked={checked}
              disabled={disabled}
              onChange={this.onChange}
            />
          );
        })}
      </div>
    );
  }

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { element, onGetExtraInfo } = this.props;
    const originalValue = this.props.value;
    if (!element.getCheckValue) return;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const checked = e.target.checked;
    let value: any = e.target.value;
    value = element.getCheckValue(originalValue, value, checked);
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = value;
    void element.onChange?.(changes, extra);
    this.props.onChange(changes);
  };
}
