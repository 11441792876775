/**
 * @file: quotation-template-config copy.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */
import { AppState } from 'app';
import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import {
  QuotationTemplateConfigState,
  QuotationTemplateConfigTarget,
} from 'app/inspection/duck/states';
import { Draft } from 'immer';
import { createStandardAction } from 'lib/duck/actions';
import { ActionThunk, StandardAction } from 'lib/duck/interfaces';
import {
  QuotationCategoryRef,
  QuotationGroupRef,
  QuotationItemRef,
  QuotationMaterialRef,
  QuotationSubjectRef,
  QuotationTemplateConfig,
  QuotationTemplateItemStaged,
  QuotationTemplateMaterialStaged,
  QuotationTemplateSubjectStaged,
} from 'model';
import { quotationTemplateConfigService } from 'services';
import { getString } from 'shared/components';
import { ActionTypes } from '../types';

export function makeDefaultQuotationTemplateConfig(): QuotationTemplateConfig {
  return {
    version: '1.0',
    categories: [],
  };
}

export class QuotationTemplateConfigActionCreators {
  endConfigureQuotationTemplate() {
    return createStandardAction(
      ActionTypes.EndConfigureQuotationTemplateConfig,
    );
  }

  beginConfigureQuotationTemplate(
    target: QuotationTemplateConfigTarget,
  ): ActionThunk<AppState> {
    return (dispatch, getState) => {
      const state = getState();
      if (state.identity == null) return;

      dispatch(
        showAppLoading({
          message: 'Loading... ',
          status: 'loading',
        }),
      );

      let orgId = 0;
      let storeId = 0;

      if (target.type === 'store') {
        orgId = state.identity.orgId;
        storeId = target.storeId;
      } else if (target.type === 'org') {
        orgId = state.identity.orgId;
      }

      quotationTemplateConfigService
        .getQuotationTemplateConfig(orgId, storeId)
        .then(template => {
          dispatch(hideAppLoading());
          dispatch(
            createStandardAction(
              ActionTypes.BeginConfigureQuotationTemplateConfig,
              { target, template },
            ),
          );
        })
        .catch(err => {
          dispatch(
            showAppLoading({
              message: getString('quotation_tpl.load_error', {
                msg: err.message,
              }),
              status: 'error',
              timeout: 10000,
            }),
          );
        });
    };
  }

  quotationTemplateConfigLoaded(
    quotationTemplateConfig: QuotationTemplateConfig,
  ) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigLoaded,
      quotationTemplateConfig,
    );
  }

  addCategory(): StandardAction<any> {
    return createStandardAction(ActionTypes.QuotationTemplateConfigAddCategory);
  }

  editCategory(id: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditCategory,
      id,
    );
  }

  editCategoryChanged(name: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditCategoryChanged,
      name,
    );
  }

  editCategoryCommitted(name?: string): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditCategoryCommitted,
      name,
    );
  }

  editCategoryCancelled(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditCategoryCancelled,
    );
  }

  removeCategory(id: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigRemoveCategory,
      id,
    );
  }

  commitRemoveCategory(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCommitRemoveCategory,
    );
  }

  cancelRemoveCategory(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCancelRemoveCategory,
    );
  }

  expandCategory(id: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigExpandCategory,
      id,
    );
  }

  collapseCategory(id: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCollapseCategory,
      id,
    );
  }

  categoryMoved(id: string, from: number, to: number): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCategoryMoved,
      {
        id,
        from,
        to,
      },
    );
  }

  addGroup(categoryId: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigAddGroup,
      categoryId,
    );
  }

  editGroup(groupRef: QuotationGroupRef, name: string): StandardAction<any> {
    return createStandardAction(ActionTypes.QuotationTemplateConfigEditGroup, {
      groupRef,
      name,
    });
  }

  editGroupChanged(name: string): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditGroupChanged,
      name,
    );
  }

  editGroupCommitted(name?: string): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditGroupCommitted,
      name,
    );
  }

  editGroupCancelled(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditGroupCancelled,
    );
  }

  removeGroup(groupRef: QuotationGroupRef) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigRemoveGroup,
      groupRef,
    );
  }

  commitRemoveGroup(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCommitRemoveGroup,
    );
  }

  cancelRemoveGroup(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCancelRemoveGroup,
    );
  }

  groupMoved(
    categoryRef: QuotationCategoryRef,
    from: number,
    to: number,
  ): StandardAction<any> {
    return createStandardAction(ActionTypes.QuotationTemplateConfigGroupMoved, {
      categoryRef,
      from,
      to,
    });
  }

  groupSelected(groupRef: QuotationGroupRef) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigGroupSelected,
      groupRef,
    );
  }

  allGroupsSelected(categoryId: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigAllGroupsSelected,
      categoryId,
    );
  }

  addSubject(groupRef: QuotationGroupRef): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigAddSubject,
      groupRef,
    );
  }

  editSubject(subjectRef: QuotationSubjectRef) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditSubject,
      subjectRef,
    );
  }

  commitSubjectBeingEdited(subject: QuotationTemplateSubjectStaged) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCommitSubjectBeingEdited,
      subject,
    );
  }

  cancelSubjectBeingEdited() {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCancelSubjectBeingEdited,
    );
  }

  subjectSelected(subjectRef: QuotationSubjectRef | undefined) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigSubjectSelected,
      subjectRef,
    );
  }

  removeSubject(subjectRef: QuotationSubjectRef) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigRemoveSubject,
      subjectRef,
    );
  }

  commitRemoveSubject(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCommitRemoveSubject,
    );
  }

  cancelRemoveSubject(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCancelRemoveSubject,
    );
  }

  subjectMoved(
    groupRef: QuotationGroupRef,
    from: number,
    to: number,
  ): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigSubjectMoved,
      {
        groupRef,
        from,
        to,
      },
    );
  }

  addItem(subjectRef: QuotationSubjectRef): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigAddItem,
      subjectRef,
    );
  }

  editItem(itemRef: QuotationItemRef): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditItem,
      itemRef,
    );
  }

  commitItemBeingEdited(item: QuotationTemplateItemStaged) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCommitItemBeingEdited,
      item,
    );
  }

  cancelItemBeingEdited() {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCancelItemBeingEdited,
    );
  }

  removeItem(itemRef: QuotationItemRef) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigRemoveItem,
      itemRef,
    );
  }

  commitRemoveItem(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCommitRemoveItem,
    );
  }

  cancelRemoveItem(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCancelRemoveItem,
    );
  }

  itemMoved(
    subjectRef: QuotationSubjectRef,
    from: number,
    to: number,
  ): StandardAction<any> {
    return createStandardAction(ActionTypes.QuotationTemplateConfigItemMoved, {
      subjectRef,
      from,
      to,
    });
  }

  itemCheckChanged(itemRef: QuotationItemRef, checked: boolean) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigItemCheckedChange,
      {
        itemRef,
        checked,
      },
    );
  }

  addMaterial(itemRef: QuotationItemRef) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigAddMaterial,
      itemRef,
    );
  }

  editMaterial(materialRef: QuotationMaterialRef) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditMaterial,
      materialRef,
    );
  }

  commitMaterialBeingEdited(material: QuotationTemplateMaterialStaged) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCommitMaterialBeingEdited,
      material,
    );
  }

  cancelMaterialBeingEdited() {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCancelMaterialBeingEdited,
    );
  }

  removeMaterial(materialRef: QuotationMaterialRef) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigRemoveMaterial,
      materialRef,
    );
  }

  commitRemoveMaterial(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCommitRemoveMaterial,
    );
  }

  cancelRemoveMaterial(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCancelRemoveMaterial,
    );
  }

  materialMoved(itemRef: QuotationItemRef, from: number, to: number) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigMaterialMoved,
      {
        itemRef,
        from,
        to,
      },
    );
  }

  materialCheckChanged(materialRef: QuotationMaterialRef, checked: boolean) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigMaterialCheckedChange,
      {
        materialRef,
        checked,
      },
    );
  }

  applyChangesToTemplateConfig(
    recipe: (state: Draft<QuotationTemplateConfigState>) => void,
    options?: { markAsDirty?: boolean },
  ) {
    return createStandardAction(ActionTypes.QuotationTemplateConfigChanged, {
      recipe,
      options,
    });
  }

  resetChanges(withConfig?: QuotationTemplateConfig) {
    return createStandardAction(
      ActionTypes.ResetQuotationTemplateConfigChanges,
      withConfig,
    );
  }

  saveSuccessful(res: QuotationTemplateConfig) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigSaveSuccess,
      res,
    );
  }
}

export const quotationTplActions = new QuotationTemplateConfigActionCreators();
