import classNames from 'classnames';
import { AreaTree } from 'model';
import React, { Component } from 'react';
import { FormElementGroup } from '../types';
import { FormElementRenderer } from './ElementRenderer';
import { getString } from 'shared/components/StringLabel';

interface Props<T> {
  element: FormElementGroup<T>;
  disabled?: boolean;
  value: any;
  autocomplete?: boolean;
  useUncontrolled?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  onGetEntity: () => T | Partial<T>;
  onGetAreas: () => AreaTree | null | undefined;
  onChange: (values: Partial<T>) => void;
}

export class FormGroup<T> extends Component<Props<T>> {
  render() {
    const { element: group, disabled } = this.props;
    const entity = this.props.onGetEntity();
    return (
      <div
        className={classNames(
          'entity-editor-form__element-group',
          {
            'entity-editor-form__element-group--vertical':
              group.orientation === 'vertical',
            'entity-editor-form__element-group--horizontal':
              group.orientation !== 'vertical',
          },
          group.className,
        )}
        style={{ width: group.width }}
      >
        {group.title ? (
          <h4
            style={{
              fontSize: '1rem',
              fontWeight: 600,
              borderBottom: '1px solid',
              paddingBottom: '0.35rem',
              marginBottom: '1rem',
            }}
          >
            {getString(group.title)}
          </h4>
        ) : null}
        {group.elements.map((elem, i) => (
          <FormElementRenderer
            key={i}
            element={elem}
            disabled={disabled}
            value={entity[elem.prop]}
            autocomplete={this.props.autocomplete}
            useUncontrolled={this.props.useUncontrolled}
            onGetExtraInfo={this.props.onGetExtraInfo}
            onGetEntity={this.props.onGetEntity}
            onGetAreas={this.props.onGetAreas}
            onChange={this.props.onChange}
          />
        ))}
      </div>
    );
  }
}
