import { createAsyncObjectMapActionReducers } from 'lib/duck/reducers';
import { StatItem, DatePartitionType } from 'model';
import { StatsStateByTime } from '../states';

const initialState: StatsStateByTime = {
  isLoading: false,
  result: {},
  error: null,
  statusMap: {},
  partition: DatePartitionType.Day
};

export default createAsyncObjectMapActionReducers<DatePartitionType, StatItem[], StatsStateByTime>(
  'dashboard.inspection_report_stats_by_time',
  initialState
);