/**
 * @file: InspectionJobProductStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum InspectionJobProductStatus {
  Submitted = 'submitted',
  AwaitingApproval = 'awaiting_approval',
  Resolved = 'resolved',
}

export const InspectionJobProductStatusValueSet = new Set([
  InspectionJobProductStatus.Submitted,
  InspectionJobProductStatus.AwaitingApproval,
  InspectionJobProductStatus.Resolved,
]);
