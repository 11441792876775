import { useAppContext, useTranslateToString } from 'app/AppContext';
import { AppState } from 'app/duck/states';
import { quotationTplActions } from 'app/inspection/duck/actions/quotation-template-config';
import {
  QuotationTemplateConfigState,
  QuotationTemplateConfigTarget,
} from 'app/inspection/duck/states';
import { Actions } from 'app/inspection/quotation-template-config/Actions';
import { Content } from 'app/inspection/quotation-template-config/Content';
import { QuotationTemplateConfigProvider } from 'app/inspection/quotation-template-config/Context';
import { CreateFromConstructionModal } from 'app/inspection/quotation-template-config/CreateFromConstructionModal';
import { constructionTemplateConfigToStaged } from 'app/inspection/quotation-template-config/util';
import { BreadcrumbItem, Page, Portlet } from 'lib/metronic/components';
import { AclObjectList } from 'model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import { constructionTemplateConfigService } from 'services';
import { getString, ItemSourceLabel } from 'shared/components';
import { StoreNameLabel } from 'shared/components/StoreNameLabel';
import {
  WithFullAccess,
  WithFullAccessContext,
} from 'shared/components/WithFullAccess';
import { usePersistFn } from 'utils/usePersistFn';

import './index.scss';

const pageIcon =
  require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/selected-file.svg').default;

export const QuotationTemplateConfigManager = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const trans = useTranslateToString();
  const queryString = history.location.search.substring(1);

  const target = useMemo<QuotationTemplateConfigTarget>(() => {
    const params = new URLSearchParams(queryString);
    const type = params.get('target') as QuotationTemplateConfigTarget['type'];
    const storeIdStr = params.get('storeId');
    const storeId = storeIdStr ? Number(storeIdStr) : undefined;
    return type === 'store' ? { type, storeId: storeId! } : { type };
  }, [queryString]);

  const { user, identity } = useAppContext();

  const hasRights =
    target.type !== 'system' &&
    ((target.type === 'org' && user.isOrgRootUser) ||
      (target.type === 'store' &&
        identity.visibleStoreSet.has(target.storeId))) &&
    identity.hasAccessRights(AclObjectList.VehicleQuotationTemplateFullAccess);

  const [breadcrumbs] = useState<BreadcrumbItem[]>(() => {
    return [
      { text: <Translate id="inspection.breadcrumb.it" /> },
      {
        text: <Translate id="inspection.breadcrumb.quotation_templates" />,
        href: '/inspection/quotation-templates',
      },
      {
        text: (
          <span>
            <ItemSourceLabel value={target.type} />
            {target.type === 'store' && (
              <StoreNameLabel
                storeId={target.storeId}
                style={{
                  marginLeft: '0.3rem',
                }}
              />
            )}
          </span>
        ),
      },
    ];
  });

  const state = useSelector<AppState, QuotationTemplateConfigState>(
    x => x.inspection.quotationTemplateConfig,
  );

  const hasChanges = state.dirty;

  const onRefresh = usePersistFn(() => {
    if (hasChanges) {
      return;
    }
    dispatch(quotationTplActions.beginConfigureQuotationTemplate(target));
  });

  useEffect(() => {
    dispatch(quotationTplActions.beginConfigureQuotationTemplate(target));
  }, [dispatch, target]);

  useEffect(
    () => () => {
      setTimeout(() => {
        dispatch(quotationTplActions.endConfigureQuotationTemplate());
      }, 1000);
    },
    [dispatch],
  );

  const [showCreateFromConstructionModal, setShowCreateFromConstructionModal] =
    useState(false);

  useEffect(() => {
    if (hasChanges) {
      window.onbeforeunload = () => {
        return getString('quotation_tpl.changes.navigate_away_warn');
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [hasChanges]);

  const onCreateFromConstructionTemplate = useCallback(() => {
    setShowCreateFromConstructionModal(true);
  }, []);

  const onConfirmCreateFromConstructionTemplate = useCallback(async () => {
    if (target.type === 'system') return;
    let constructionTemplate =
      await constructionTemplateConfigService.getConstructionTemplateConfig(
        identity.orgId,
        target.type === 'store' ? target.storeId : 0,
      );
    if (constructionTemplate == null) {
      if (target.type === 'store') {
        constructionTemplate =
          await constructionTemplateConfigService.getConstructionTemplateConfig(
            identity.orgId,
            0,
          );
        if (constructionTemplate == null) {
          constructionTemplate =
            await constructionTemplateConfigService.getConstructionTemplateConfig(
              0,
              0,
            );
        }
      }
    }
    if (constructionTemplate == null) {
      return;
    }
    dispatch(
      quotationTplActions.applyChangesToTemplateConfig(draft => {
        draft.staged = constructionTemplateConfigToStaged(
          constructionTemplate.conf,
        );
      }),
    );
    setShowCreateFromConstructionModal(false);
  }, [dispatch, identity.orgId, target]);

  const onCancelCreateFromConstructionTemplate = useCallback(() => {
    setShowCreateFromConstructionModal(false);
  }, []);

  return (
    <WithFullAccessContext.Provider value={{ hasRights }}>
      <QuotationTemplateConfigProvider
        target={target}
        state={state}
        key={JSON.stringify(target)}
      >
        <Prompt
          when={hasChanges}
          message={getString('quotation_tpl.changes.navigate_away_warn')}
        />
        <Page
          className="quotation-tpl-page"
          title={trans('quotation_tpl.title')}
          fullAccessRight={AclObjectList.VehicleQuotationTemplateFullAccess}
          readonlyAccessRight={
            AclObjectList.VehicleQuotationTemplateReadonlyAccess
          }
        >
          <Page.Header>
            <Page.Header.Main>
              <Page.Breadcrumb items={breadcrumbs} />
            </Page.Header.Main>
          </Page.Header>
          <Page.Content>
            <Portlet mobile cellPadding={0}>
              <Portlet.Header
                size="large"
                title={trans('quotation_tpl.title')}
                icon={pageIcon}
                iconColor="brand"
                onRefresh={onRefresh}
              >
                <WithFullAccess>
                  <Actions
                    onCreateFromConstructionTemplate={
                      onCreateFromConstructionTemplate
                    }
                  />
                </WithFullAccess>
              </Portlet.Header>
              <Portlet.Body noPadding className="quotation-tpl">
                <Content />
              </Portlet.Body>
            </Portlet>
          </Page.Content>
        </Page>
        <CreateFromConstructionModal
          open={showCreateFromConstructionModal}
          onConfirm={onConfirmCreateFromConstructionTemplate}
          onCancel={onCancelCreateFromConstructionTemplate}
        />
      </QuotationTemplateConfigProvider>
    </WithFullAccessContext.Provider>
  );
};
