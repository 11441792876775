import { AddJobModal } from 'app/data-export/AddJobModal';
import { showAppLoading } from 'app/duck/actions';
import {
  dataExportTaskActions,
  showAddDataExportJobTask,
} from 'app/duck/actions/data-export-task';
import { filesize } from 'filesize';
import {
  AclObjectList,
  DataExportTask,
  DataExportTaskJobType,
  DataExportTaskListFilter,
  DataExportTaskOrigin,
  DataExportTaskStatus,
} from 'model';
import {
  DataExportTaskRequest,
  DataExportTaskRequestTarget,
} from 'model/DataExportTaskRequest';
import { Fragment, memo } from 'react';
import { Translate } from 'react-localize-redux';
import { dataExportTaskService } from 'services';
import {
  Checkmark,
  DataExportTaskTargetTypeLabel,
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
} from 'shared/components';
import { formatTime } from 'utils';

import { MessageEventArgs } from 'lib/websocket';
import moment from 'moment';
import './data-export.scss';

interface Props
  extends EntityListProps<DataExportTask, DataExportTaskListFilter> {
  isAddJobModalOpen?: boolean;
  addJobFromExisting?: DataExportTask;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  DataExportTask,
  DataExportTaskListFilter,
  number,
  Props
>();

const DataExportTaskJobTypeLabelColors: {
  [p in DataExportTaskJobType]: {
    color: string;
    borderColor: string;
    bgColor: string;
  };
} = {
  [DataExportTaskJobType.OrderDetails]: {
    color: '#000',
    borderColor: '#777',
    bgColor: '#eee',
  },
  [DataExportTaskJobType.StatsSummaryReport]: {
    color: '#fff',
    borderColor: '#069478',
    bgColor: '#34bfa3',
  },
  [DataExportTaskJobType.ServiceAgentStats]: {
    color: '#fff',
    borderColor: '#881f73',
    bgColor: '#d06cb2',
  },
  [DataExportTaskJobType.PreInspectionDetails]: {
    color: '#fff',
    borderColor: '#1aad41',
    bgColor: '#57c063',
  },
  [DataExportTaskJobType.OrderRemarks]: {
    color: '#fff',
    borderColor: '#625132',
    bgColor: '#a1a130',
  },
  [DataExportTaskJobType.OrderComments]: {
    color: '#fff',
    borderColor: '#ad7a1a',
    bgColor: '#d8a95e',
  },
  [DataExportTaskJobType.UnfinishedOrderDetails]: {
    color: '#fff',
    borderColor: '#ed0b0b',
    bgColor: '#de6565',
  },
  [DataExportTaskJobType.ProductReferralDetails]: {
    color: '#fff',
    borderColor: '#1b83b0',
    bgColor: '#52bdeb',
  },
};

export const DataExportTaskList = componentClassBuilder
  .i18nPrefix('data_export')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/export.svg')
      .default,
  )
  .accessRights({
    full: AclObjectList.DataExportTaskFullAccess,
    readonly: AclObjectList.DataExportTaskReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="data_export.breadcrumb.it" /> },
    { text: <Translate id="data_export.breadcrumb.tasks" /> },
  ])
  .mapStateToProps(state => ({
    isAddJobModalOpen: state.dataExportTasks.isAddJobModalOpen,
    addJobFromExisting: state.dataExportTasks.addJobFromExisting,
  }))
  .entities(state => state.dataExportTasks)
  .actions(dataExportTaskActions)
  .columns([
    {
      prop: 'taskId',
      width: 200,
      hidden: true,
      text: 'data_export.col.id',
    },
    {
      prop: 'jobType',
      width: 100,
      text: 'data_export.col.job_type',
      render: ({ jobType }) => (
        <span
          style={{
            whiteSpace: 'nowrap',
            display: 'inline-block',
            color: DataExportTaskJobTypeLabelColors[jobType].color,
            backgroundColor: DataExportTaskJobTypeLabelColors[jobType].bgColor,
            border: `1px solid ${DataExportTaskJobTypeLabelColors[jobType].borderColor}`,
            fontSize: '0.85rem',
            padding: '0 0.25rem',
            borderRadius: '0.15rem',
          }}
        >
          <Translate id={`data_export_task_job_type.${jobType}`} />
        </span>
      ),
    },
    {
      prop: 'request',
      text: 'data_export.col.detail',
      width: 300,
      render: task => {
        return <TaskRequestInfo task={task} />;
      },
    },
    {
      prop: 'status',
      text: 'data_export.col.status',
      width: 300,
      render: task => {
        return <JobStatusCell task={task} />;
      },
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 120,
      render: task => {
        return formatTime(task.createdAt, 'YYYY-MM-DD HH:mm');
      },
    },
  ])
  .addActionButtons([
    'remove',
    {
      key: 'export',
      rights: [AclObjectList.DataExportTaskFullAccess],
      icon: 'la la-arrow-circle-o-right',
      onClick: (task, props: Props) => {
        props.dispatch(showAddDataExportJobTask(true, task));
      },
    },
  ])
  .componentDidMount((_state, { dispatch }: Props) => {
    window.addEventListener('message', async e => {
      const args = e.data as MessageEventArgs;
      if (args.source !== '@zhichetech/store') {
        return;
      }
      if (
        args.type === 'ws' &&
        args.event.type === 'data_export_task_status_update'
      ) {
        dispatch(async (_, getState) => {
          const state = getState();
          const entity = state.dataExportTasks.result?.find(
            x => x.taskId === args.event.data?.requestId,
          );
          if (entity != null) {
            const task = await dataExportTaskService.get(entity.id);
            if (task != null) {
              dispatch(dataExportTaskActions.updateSuccess(entity, task));
            }
          }
        });
      }
    });
  })
  .onRender(props => {
    const onConfirm = async (
      jobType: DataExportTaskJobType,
      target: DataExportTaskRequestTarget,
      dateRange: { from: string; to: string },
    ) => {
      const { dispatch } = props;
      const request: DataExportTaskRequest = { target, dateRange };
      const task: Partial<DataExportTask> = {
        request: JSON.stringify(request),
        jobType,
        origin: DataExportTaskOrigin.Org,
      };
      try {
        dispatch(showAddDataExportJobTask(false));
        dispatch(showAppLoading());
        const result = await dataExportTaskService.create(task);
        dispatch(dataExportTaskActions.createSuccess(task, result));
        dispatch(
          showAppLoading({
            message: getString('data_export.add_job_success'),
            status: 'success',
            timeout: 3000,
          }),
        );
      } catch (e) {
        dispatch(dataExportTaskActions.createFailed(task, e));
        dispatch(
          showAppLoading({
            message: e.message,
            status: 'error',
            timeout: 5000,
          }),
        );
      }
    };
    return (
      <AddJobModal
        orgId={props.user.orgId}
        fromExisting={props.addJobFromExisting}
        isOpen={props.isAddJobModalOpen ?? false}
        onCancel={() => props.dispatch(showAddDataExportJobTask(false))}
        onConfirm={onConfirm}
      />
    );
  })
  .toolbarItems(builder => {
    builder.button({
      buttonType: 'add',
    });
  })
  .onAdd((_, props: Props) => {
    props.dispatch(showAddDataExportJobTask(true));
    return true;
  })
  .getClass();

function TaskRequestInfo({ task }: { task: DataExportTask }) {
  const { target, dateRange } = JSON.parse(
    task.request,
  ) as DataExportTaskRequest;
  const times =
    dateRange.from === dateRange.to
      ? [dateRange.to]
      : [dateRange.from, dateRange.to];
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <DataExportTaskTargetTypeLabel
          value={target.type}
          style={{
            fontSize: '0.7rem',
            padding: '0 0.3rem',
            minHeight: 0,
            lineHeight: '1.25rem',
          }}
        />
        <span
          style={{ marginLeft: '0.25rem', fontWeight: 'bold', color: '#444' }}
        >
          {target.type === 'org' ? target.orgName : target.storeName}
        </span>
      </div>
      <div>
        {times.map((time, i) => (
          <Fragment key={time}>
            {i > 0 ? (
              <span
                style={{
                  padding: '0 0.5rem',
                }}
              >
                ~
              </span>
            ) : null}
            <span
              style={{
                color: '#888',
                borderBottom: '1px dotted #888',
              }}
            >
              {time}
            </span>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

const JobStatusCell = memo(({ task }: { task: DataExportTask }) => {
  let statusText: any = '';

  switch (task.status) {
    case DataExportTaskStatus.Queued: {
      statusText = (
        <span className="text-info">
          <i
            className="fa fa-hourglass-half"
            style={{ marginRight: '0.25rem', fontSize: '1rem' }}
          />
          <Translate id="data_export_task_status.queued" />
        </span>
      );
      break;
    }
    case DataExportTaskStatus.Running: {
      statusText = (
        <span className="text-info">
          <i
            className="fa fa-spinner fa-pulse"
            style={{ marginRight: '0.25rem', fontSize: '1rem' }}
          />
          <Translate id="data_export_task_status.running" />
          {' ...'}
        </span>
      );
      break;
    }
    case DataExportTaskStatus.Error: {
      statusText = (
        <span style={{ color: 'red' }}>
          <Translate id="data_export_task_status.error" />: {task.error}
        </span>
      );
      break;
    }
    case DataExportTaskStatus.Finished: {
      statusText = (
        <span style={{ color: 'green' }}>
          <Checkmark
            value={true}
            style={{ fontSize: '1rem', marginRight: '0.15rem' }}
          />
          <Translate id="data_export_task_status.finished" />,{' '}
          {moment
            .duration(moment(task.finishedAt).diff(task.runAt))
            .format(getString('data_export.time_elapsed_format'))}
          ,&nbsp;
          <a href={task.downloadUrl!} target="_blank" rel="noreferrer">
            <Translate id="data_export.download_link_text" />
          </a>
          <span
            style={{
              marginLeft: '0.25rem',
              color: '#aaa',
            }}
          >
            ({filesize(task.size ?? 0)})
          </span>
        </span>
      );
    }
  }
  return <div>{statusText}</div>;
});
