import { combineReducers } from 'redux';
import authorizationRequests from './product-authorization-requests';
import authorizationContracts from './product-authorization-contracts';
import authorizedProducts from './authorized-products';

export default combineReducers({
  authorizationRequests,
  authorizationContracts,
  authorizedProducts,
});
