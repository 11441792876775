import { Button } from 'lib/metronic/components';
import { Store } from 'model';
import {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap/lib';
import { StorePicker } from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';

export const SelectStoreModal = memo(
  ({
    isOpen,
    errorMsg,
    title,
    label,
    isValid,
    onCancel,
    onConfirm,
    onChange,
  }: {
    errorMsg?: string;
    isOpen?: boolean;
    title: ReactNode;
    label: ReactNode;
    isValid: boolean;
    onChange?: (store: Store | null) => void;
    onCancel?: () => void;
    onConfirm?: (info: { storeId: number }) => void;
  }) => {
    const [storeId, setStoreId] = useState<number>();

    const handleConfirm = usePersistFn(() => {
      onConfirm?.({
        storeId: storeId ?? 0,
      });
    });

    const handleChange = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      (storeId: number | undefined, store: Store | null) => {
        onChange?.(store);
        setStoreId(storeId);
      },
      [onChange],
    );

    const changeHandlerRef = useRef(onChange);
    changeHandlerRef.current = onChange;

    useEffect(() => {
      if (isOpen) {
        changeHandlerRef.current?.(null);
      }
    }, [isOpen]);

    return (
      <Modal
        isOpen={isOpen}
        modalClassName="construction-tpl-man__select-store-modal"
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <dl>
            <dt>{label}</dt>
            <dd>
              <StorePicker storeId={storeId} onChange={handleChange} />
            </dd>
            {errorMsg ? <dd style={{ color: 'red' }}>{errorMsg}</dd> : null}
          </dl>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="primary" onClick={handleConfirm} disabled={!isValid}>
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
