import {
  CommonTemplateProperties,
  CommonTemplatePropertyExamples,
} from './common';

export interface TemplateProperty {
  property: string;
  label: string;
}

export type TemplateMsgDestType = 'service_agent' | 'customer';

export type TemplateMsgDataExample = { [property: string]: string };

export interface TemplatePropertyProvider {
  readonly supportedDestTypes: TemplateMsgDestType[];
  getProperties(): TemplateProperty[];
  getExample(destType: TemplateMsgDestType): TemplateMsgDataExample;
}

export abstract class BaseTemplatePropertyProvider
  implements TemplatePropertyProvider
{
  get supportedDestTypes(): TemplateMsgDestType[] {
    return ['customer', 'service_agent'];
  }

  getProperties(): TemplateProperty[] {
    const properties = this.getOverriddenProperties();
    const propertySet = new Set(properties.map(x => x.property));
    const commonProperties = CommonTemplateProperties.filter(
      x => !propertySet.has(x.property),
    );
    return [...commonProperties, ...properties];
  }

  getExample(destType: TemplateMsgDestType): TemplateMsgDataExample {
    const example = this.getOverriddenExample(destType);
    return { ...CommonTemplatePropertyExamples, ...example };
  }

  abstract getOverriddenProperties(): TemplateProperty[];

  abstract getOverriddenExample(
    destType: TemplateMsgDestType,
  ): TemplateMsgDataExample;
}
