import React, { Component } from 'react';
import { AreaTree } from 'model';
import { FormElement } from './types';
import { FormElementRenderer } from './elements';

interface Props<T> {
  autocomplete?: boolean;
  useUncontrolled?: boolean;
  helpTextPlacement?: 'after' | 'before';
  entity: T | Partial<T>;
  areas?: AreaTree | null;
  elements: Array<FormElement<T>>;
  onGetExtraInfo?: () => any;
  onChange: (values: Partial<T>, extra: any) => void;
}

export class EntityEditorForm<T> extends Component<Props<T>> {
  render() {
    const { elements, entity } = this.props;
    return (
      <div className="kt-section kt-section--first">
        {elements.map(elem => (
          <FormElementRenderer
            key={elem.key || (elem.prop as string)}
            element={elem}
            value={entity[elem.prop]}
            autocomplete={this.props.autocomplete}
            useUncontrolled={this.props.useUncontrolled}
            helpTextPlacement={this.props.helpTextPlacement}
            onGetExtraInfo={this.props.onGetExtraInfo}
            onGetEntity={this.onGetEntity}
            onGetAreas={this.onGetAreas}
            onChange={this.onChange}
          />
        ))}
      </div>
    );
  }

  onGetEntity = () => {
    return this.props.entity;
  };

  onGetAreas = () => {
    return this.props.areas;
  };

  onChange = (changes: Partial<T>) => {
    const extra = this.props.onGetExtraInfo
      ? this.props.onGetExtraInfo()
      : undefined;
    this.props.onChange(changes, extra);
  };
}
