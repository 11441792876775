import classNames from 'classnames';
import { Badge } from 'lib/metronic/components';
import { QuotationTemplateGroupStaged } from 'model';
import {
  CSSProperties,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  memo,
  useCallback,
} from 'react';
import { Translate } from 'react-localize-redux';
import { getString } from 'shared/components';

export const GroupListItem = memo(
  ({
    group,
    itemCount,
    selected,
    className,
    editable,
    editMode,
    onClick,
    onRemove,
    onEndEditGroupName,
    onCancelEditGroupName,
    ...props
  }: {
    group?: QuotationTemplateGroupStaged;
    itemCount?: number;
    selected?: boolean;
    className?: string;
    style?: CSSProperties;
    editMode?: boolean;
    editable: boolean;
    onClick?: (group: QuotationTemplateGroupStaged | undefined) => void;
    onRemove?: (group: QuotationTemplateGroupStaged) => void;
    onEndEditGroupName?: (
      group: QuotationTemplateGroupStaged,
      name: string,
    ) => void;
    onCancelEditGroupName?: (group: QuotationTemplateGroupStaged) => void;
  }) => {
    const handleClick = useCallback(() => {
      onClick?.(group);
    }, [group, onClick]);

    const handleRemoveClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (group != null) {
          onRemove?.(group);
        }
      },
      [group, onRemove],
    );

    const onGroupNameMouseDown = useCallback((e: MouseEvent) => {
      e.stopPropagation();
    }, []);

    const onGroupNameMouseMove = useCallback((e: MouseEvent) => {
      e.stopPropagation();
    }, []);

    const onGroupNameBlur = useCallback(
      (e: FocusEvent) => {
        if (group == null) return;
        const el = e.target as HTMLInputElement;
        const name = el.value.trim();
        if (name.length) {
          onEndEditGroupName?.(group, name);
        } else {
          onCancelEditGroupName?.(group);
        }
      },
      [group, onCancelEditGroupName, onEndEditGroupName],
    );

    const onGroupNameKeyup = useCallback(
      (e: KeyboardEvent) => {
        if (group == null) return;
        if (e.key === 'Escape') {
          // cancel
          onCancelEditGroupName?.(group);
        } else if (e.key === 'Enter') {
          // enter
          const el = e.target as HTMLInputElement;
          const name = el.value.trim();
          if (name.length) {
            onEndEditGroupName?.(group, name);
          }
        }
      },
      [group, onCancelEditGroupName, onEndEditGroupName],
    );

    return (
      <div
        className={classNames(className, 'quotation-tpl__group', 'noselect', {
          'quotation-tpl__group--selected': selected,
        })}
        {...props}
      >
        <div onClick={handleClick}>
          <i
            className="la la-remove quotation-tpl__group-remove"
            onClick={handleRemoveClick}
            style={editable ? undefined : { visibility: 'hidden' }}
          />
          <span className="quotation-tpl__group-name">
            {editMode ? (
              <input
                type="text"
                className="form-control form-control-sm m-input"
                placeholder={getString('quotation_tpl.group.placeholder.name')}
                defaultValue={group?.name ?? ''}
                onBlur={onGroupNameBlur}
                onKeyUp={onGroupNameKeyup}
                onMouseDown={onGroupNameMouseDown}
                onMouseMove={onGroupNameMouseMove}
                ref={c => c?.focus()}
              />
            ) : (
              (group?.name ?? (
                <Translate id="quotation_tpl.group.label.all_groups" />
              ))
            )}
          </span>
          <Badge
            color="info"
            rounded={false}
            className="quotation-tpl__group-count"
          >
            {itemCount ?? group?.subjects.length ?? 0}
          </Badge>
        </div>
      </div>
    );
  },
);
