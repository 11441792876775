import React, { PureComponent } from 'react';
import { MpMediaPicker } from '../shared/MediaPicker';
import { WeixinNewsMsg, WeixinArticleInfo, WeixinPermanentMaterialListItem, WeixinPermanentNewsMaterialListItem } from 'model';

interface Props {
  data?: WeixinNewsMsg;
  onChange: (mediaId: string, extra: any) => void;
}

export class NewsEditor extends PureComponent<Props> {
  render() {
    const { data } = this.props;
    return (
      <MpMediaPicker
        type='news'
        mediaId={data?.mediaId}
        data={data}
        onChange={this.onChange}
        onMediaSelected={this.onMediaSelected}
        />
    );
  }

  onChange = (mediaId: string, extra: any) => {
    this.props.onChange(mediaId, extra);
  }

  onMediaSelected = (item: WeixinPermanentMaterialListItem) => {
    const newsListItem = item as WeixinPermanentNewsMaterialListItem;
    const articles: WeixinArticleInfo[] = newsListItem.content.newsItem.map(x => ({
      title: x.title,
      description: x.digest,
      picUrl: x.thumbUrl || '',
      picMediaId: x.thumbMediaId,
      url: x.url
    }));
    this.props.onChange(item.mediaId, {
      articleCount: articles.length, items: articles
    });
  }
}