import { createListAsyncActionCreators } from 'lib/duck/actions';
import { weixinService } from 'services';
import { MpTemplateIndustry } from 'model';
import { AppState } from 'app/duck/states';

export const templateIndustryActions = createListAsyncActionCreators<AppState, MpTemplateIndustry, any, string>(
  'integration.templates.industries',
  {
    mapItemKey(item: MpTemplateIndustry) { return item.industryNo; },
    async fetchHandler() {
      return await weixinService.getTemplateIndustryList();
    }
  }
);