/**
 * @file: OrgMemberService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  OrgMember,
  OrgMemberListFilter,
  PagedListQueryOptions,
  SortInfo,
} from 'model';

export class OrgMemberServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<OrgMemberListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions ? ListResult<OrgMember> : OrgMember[]
  > {
    return await this.api
      .get()
      .url(
        '/org-members',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<OrgMember | null> {
    return await this.api.get().url('/org-members/:id', { id }).future();
  }

  async getMemberByUserId(userId: number): Promise<OrgMember | null> {
    if (!userId) {
      throw new Error('missing userId parameter');
    }
    return await this.api
      .get()
      .url('/org-members/query/userid', { userId })
      .future();
  }

  async getMemberByMobile(mobile: string): Promise<OrgMember | null> {
    if (!mobile) {
      throw new Error('missing mobile parameter');
    }
    return await this.api
      .get()
      .url('/org-members/query/mobile', { mobile })
      .future();
  }

  async create(orgMember: Partial<OrgMember>): Promise<OrgMember> {
    return await this.api.post().url('/org-members').data(orgMember).future();
  }

  async update(orgMember: Partial<OrgMember>): Promise<OrgMember> {
    return await this.api
      .put()
      .url('/org-members/:id', { id: orgMember.id })
      .data(orgMember)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete().url('/org-members/:id', { id }).future();
  }
}
