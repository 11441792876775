import { AppState } from 'app';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Page,
  Portlet,
} from 'lib/metronic/components';
import { AclObjectList, Identity } from 'model';
import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { getString } from 'shared/components';
import { SettingsEditor } from './Editor';
import { SettingsSidebar } from './Sidebar';

import { organizationService, storeService } from 'services';
import './settings.scss';

export const Settings = memo(() => {
  const identity = useSelector<AppState, Identity | null>(x => x.identity);
  const [selectedStoreId, setSelectedStoreId] = useState<number>();
  const [key, setKey] = useState(0);

  const breadcrumbs = useMemo<BreadcrumbItem[]>(
    () => [
      { text: <Translate id="integration.breadcrumb.it" /> },
      { text: <Translate id="integration.breadcrumb.config" /> },
    ],
    [],
  );

  const onExport = useCallback(async () => {
    let data: string = '';
    if (selectedStoreId == null) {
      const config = await organizationService.getConfiguration();
      data = JSON.stringify(config, null, 2);
    } else {
      const config = await storeService.getConfiguration(selectedStoreId, true);
      data = JSON.stringify(config, null, 2);
    }
    const file = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(file);

    const link = document.createElement('a');
    link.download = `settings.json`;
    link.href = url;
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 0);
  }, [selectedStoreId]);

  const onImport = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files.length !== 1) {
        return;
      }
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.addEventListener('load', async () => {
        const config = JSON.parse(reader.result as string);

        console.log('config: ', config);

        if (confirm(getString('store.config.import_confirm'))) {
          try {
            if (selectedStoreId == null) {
              await organizationService.saveConfiguration(config);
            } else {
              await storeService.saveConfiguration(selectedStoreId, config);
            }
            setKey(x => x + 1);
          } catch (err) {
            alert(err.message);
          }
        }
      });
      reader.readAsText(file);
    },
    [selectedStoreId],
  );

  if (identity == null) {
    return null;
  }
  return (
    <Page
      title={getString('store.config.title')}
      fullAccessRight={AclObjectList.StoreFullAccess}
      readonlyAccessRight={AclObjectList.StoreReadonlyAccess}
    >
      <Page.Header>
        <Page.Header.Main>
          <Breadcrumb items={breadcrumbs} />
        </Page.Header.Main>
      </Page.Header>
      <Page.Content className="settings__page-content">
        <Portlet>
          <Portlet.Header
            icon={
              require('!@svgr/webpack!lib/metronic/assets/icons/svg/code/settings4.svg')
                .default
            }
            title={getString('store.config.title')}
            size="large"
            iconColor="brand"
          >
            <Button
              color="secondary"
              size="small"
              file
              accepts={['application/json']}
              style={{ marginRight: '0.5rem' }}
              onFileChange={onImport}
            >
              <Translate id="store.config.button.import_settings" />
            </Button>
            <Button color="primary" size="small" onClick={onExport}>
              <Translate id="store.config.button.export_settings" />
            </Button>
          </Portlet.Header>
          <Portlet.Body className="settings__page-body">
            <SettingsSidebar
              orgId={identity.orgId}
              storeId={selectedStoreId}
              onActiveStoreChange={setSelectedStoreId}
            />
            <SettingsEditor
              orgId={identity.orgId}
              storeId={selectedStoreId}
              key={`${key}-${selectedStoreId ?? ''}`}
            />
          </Portlet.Body>
        </Portlet>
      </Page.Content>
    </Page>
  );
});
