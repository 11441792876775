/**
 * @file uniq.ts
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

type KeyFn<T> = (x: T) => any;

export function uniq<T>(
  arr: T[],
  keyFunc: KeyFn<T> = x => (typeof (x) === 'object' ? JSON.stringify(x) : x)
): T[] {
  const result: T[] = [];
  const map: { [key: string]: boolean } = {};
  for (const item of arr) {
    const key = keyFunc(item);
    if (!map[key]) {
      map[key] = true;
      result.push(item);
    }
  }
  return result;
}
