interface ProtocolFieldId {
  id: number;
  name: string;
}

interface FieldIdGroup {
  name: string;
  fieldIds: ProtocolFieldId[];
}

export const ProtocolFieldIdGroups: FieldIdGroup[] = [
  {
    name: 'brake_oil',
    fieldIds: [{ id: 101, name: 'water_content_level' }],
  },
  {
    name: 'tire',
    fieldIds: [
      { id: 201, name: 'tread_depth' },
      { id: 202, name: 'brake_pad_thickness' },
      { id: 203, name: 'tire_pressure' },
    ],
  },
  {
    name: 'engine_oil',
    fieldIds: [{ id: 301, name: 'engine_oil_luminousness' }],
  },
  {
    name: 'battery',
    fieldIds: [
      { id: 401, name: 'battery_state_soh_percentage' },
      { id: 402, name: 'battery_state_soh_value' },
      { id: 403, name: 'battery_state_soc_percentage' },
      { id: 404, name: 'battery_state_soc_value' },
      { id: 405, name: 'battery_state_internal_r' },
      { id: 406, name: 'battery_state_rated' },
      // { id: 407, name: 'battery_state_select_input' },
      // { id: 408, name: 'battery_state_result' },
      // { id: 409, name: 'cranking_time' },
      // { id: 410, name: 'cranking_voltage' },
      // { id: 411, name: 'cranking_voltage_status' },
      // { id: 412, name: 'charging_state_loaded' },
      // { id: 413, name: 'charging_state_no_loaded' },
      // { id: 414, name: 'charging_state_ripple' },
      // { id: 415, name: 'charging_state_result' },
    ],
  },
  {
    name: 'noise_meter',
    fieldIds: [{ id: 500, name: 'noise_level' }],
  },
];
