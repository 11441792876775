/**
 * @file: ProductDetailContentType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ProductDetailContentType {
  ImageList = 'image_list',
  HtmlTemplate = 'html_tpl',
}

export const ProductDetailContentTypeValueSet = new Set([
  ProductDetailContentType.ImageList,
  ProductDetailContentType.HtmlTemplate,
]);
