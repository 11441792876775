/**
 * @file: DataExportTaskJobTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { DataExportTaskJobType, Option } from 'model';

export const DataExportTaskJobTypeOptions: Array<
  Option<DataExportTaskJobType>
> = [
  {
    value: DataExportTaskJobType.OrderDetails,
    label: 'data_export_task_job_type.order_details',
  },
  {
    value: DataExportTaskJobType.StatsSummaryReport,
    label: 'data_export_task_job_type.stats_summary_report',
  },
  {
    value: DataExportTaskJobType.OrderRemarks,
    label: 'data_export_task_job_type.order_remarks',
  },
  {
    value: DataExportTaskJobType.ServiceAgentStats,
    label: 'data_export_task_job_type.sa_stats',
  },
  {
    value: DataExportTaskJobType.OrderComments,
    label: 'data_export_task_job_type.order_comments',
  },
  {
    value: DataExportTaskJobType.PreInspectionDetails,
    label: 'data_export_task_job_type.pre_inspection_details',
  },
  {
    value: DataExportTaskJobType.UnfinishedOrderDetails,
    label: 'data_export_task_job_type.unfinished_order_details',
  },
  {
    value: DataExportTaskJobType.ProductReferralDetails,
    label: 'data_export_task_job_type.product_referral_details',
  },
];

export const DataExportTaskJobTypeOptionsWithDefault: Array<
  Option<DataExportTaskJobType | null>
> = [
  { value: null, label: 'data_export_task_job_type.__default__' },
  {
    value: DataExportTaskJobType.OrderDetails,
    label: 'data_export_task_job_type.order_details',
  },
  {
    value: DataExportTaskJobType.StatsSummaryReport,
    label: 'data_export_task_job_type.stats_summary_report',
  },
  {
    value: DataExportTaskJobType.OrderRemarks,
    label: 'data_export_task_job_type.order_remarks',
  },
  {
    value: DataExportTaskJobType.ServiceAgentStats,
    label: 'data_export_task_job_type.sa_stats',
  },
  {
    value: DataExportTaskJobType.OrderComments,
    label: 'data_export_task_job_type.order_comments',
  },
  {
    value: DataExportTaskJobType.PreInspectionDetails,
    label: 'data_export_task_job_type.pre_inspection_details',
  },
  {
    value: DataExportTaskJobType.UnfinishedOrderDetails,
    label: 'data_export_task_job_type.unfinished_order_details',
  },
  {
    value: DataExportTaskJobType.ProductReferralDetails,
    label: 'data_export_task_job_type.product_referral_details',
  },
];
