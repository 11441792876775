/**
 * @file: SubscribeSceneTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, SubscribeSceneType } from 'model';

export const SubscribeSceneTypeOptions: Array<Option<SubscribeSceneType>> = [
  { value: SubscribeSceneType.Search, label: 'subscribe_scene_type.ADD_SCENE_SEARCH' },
  { value: SubscribeSceneType.AccountMigration, label: 'subscribe_scene_type.ADD_SCENE_ACCOUNT_MIGRATION' },
  { value: SubscribeSceneType.ProfileCard, label: 'subscribe_scene_type.ADD_SCENE_PROFILE_CARD' },
  { value: SubscribeSceneType.QrCode, label: 'subscribe_scene_type.ADD_SCENE_QR_CODE' },
  { value: SubscribeSceneType.ProfileLink, label: 'subscribe_scene_type.ADD_SCENE_PROFILE_LINK' },
  { value: SubscribeSceneType.ProfileItem, label: 'subscribe_scene_type.ADD_SCENE_PROFILE_ITEM' },
  { value: SubscribeSceneType.Paid, label: 'subscribe_scene_type.ADD_SCENE_PAID' },
  { value: SubscribeSceneType.WechatAdvertisement, label: 'subscribe_scene_type.ADD_SCENE_WECHAT_ADVERTISEMENT' },
  { value: SubscribeSceneType.Others, label: 'subscribe_scene_type.ADD_SCENE_OTHERS' }
];

export const SubscribeSceneTypeOptionsWithDefault: Array<Option<SubscribeSceneType | null>> = [
  { value: null, label: 'subscribe_scene_type.__default__' },
  { value: SubscribeSceneType.Search, label: 'subscribe_scene_type.ADD_SCENE_SEARCH' },
  { value: SubscribeSceneType.AccountMigration, label: 'subscribe_scene_type.ADD_SCENE_ACCOUNT_MIGRATION' },
  { value: SubscribeSceneType.ProfileCard, label: 'subscribe_scene_type.ADD_SCENE_PROFILE_CARD' },
  { value: SubscribeSceneType.QrCode, label: 'subscribe_scene_type.ADD_SCENE_QR_CODE' },
  { value: SubscribeSceneType.ProfileLink, label: 'subscribe_scene_type.ADD_SCENE_PROFILE_LINK' },
  { value: SubscribeSceneType.ProfileItem, label: 'subscribe_scene_type.ADD_SCENE_PROFILE_ITEM' },
  { value: SubscribeSceneType.Paid, label: 'subscribe_scene_type.ADD_SCENE_PAID' },
  { value: SubscribeSceneType.WechatAdvertisement, label: 'subscribe_scene_type.ADD_SCENE_WECHAT_ADVERTISEMENT' },
  { value: SubscribeSceneType.Others, label: 'subscribe_scene_type.ADD_SCENE_OTHERS' }
];