/**
 * @file: UserWeixinBindingService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { UserWeixinBindingListFilter, UserWeixinBinding, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class UserWeixinBindingServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<UserWeixinBindingListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<UserWeixinBinding> : UserWeixinBinding[]> {
    return await this.api.get()
      .url('/user-weixin-bindings', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<UserWeixinBinding | null> {
    return await this.api.get()
      .url('/user-weixin-bindings/:id', { id })
      .future();
  }

  async create(userWeixinBinding: Partial<UserWeixinBinding>): Promise<UserWeixinBinding> {
    return await this.api.post()
      .url('/user-weixin-bindings')
      .data(userWeixinBinding)
      .future();
  }

  async update(userWeixinBinding: Partial<UserWeixinBinding>): Promise<UserWeixinBinding> {
    return await this.api.put()
      .url('/user-weixin-bindings/:id', { id: userWeixinBinding.id })
      .data(userWeixinBinding)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/user-weixin-bindings/:id', { id })
      .future();
  }
}