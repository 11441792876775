import React, {
  ChangeEvent,
  MouseEvent,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { AsideRight } from 'lib/metronic/layout';
import {
  cancelProductionAuthorizationRequest,
  cancelProductionAuthorizationRequestCanceled,
  confirmCancelProductionAuthorizationRequest,
  productAuthorizationRequestActions,
} from '../duck/actions';
import {
  ProductAuthorizationRequest,
  AclObjectList,
  IdType,
  ProductAuthorizationRequestStatus,
} from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  ProductAuthorizationContractClientEntityTypeLabel,
  ProductAuthorizationRequestStatusLabel,
  getString,
} from 'shared/components';
import { ProductAuthorizationRequestDetail } from './detail';
import { ProductAuthorizationRequests } from '../duck/states';
import { ProductAuthorizationRequestStatusOptionsWithDefault } from 'model/EnumOptions';
import { TabNav } from 'lib/metronic/components';
import { ProductAuthorizationRequestSearchParams } from 'model/app/product';
import { ConfirmModal } from 'shared/components/ConfirmModal';

import './index.scss';

const remarkInputRef = React.createRef<RemarkInputRef>();

interface Props
  extends EntityListProps<
    ProductAuthorizationRequest,
    ProductAuthorizationRequestSearchParams,
    IdType
  > {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  ProductAuthorizationRequest,
  ProductAuthorizationRequestSearchParams,
  IdType,
  Props
>();

export const ProductAuthorizationRequestList = componentClassBuilder
  .i18nPrefix('product_authorization_request')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/general/notification2.svg')
      .default,
  )
  .accessRights({
    readonly: AclObjectList.ProductAuthorizationRequestReadonlyAccess,
    full: AclObjectList.ProductAuthorizationRequestFullAccess,
  })
  .breadcrumbs([
    { text: <Translate id="product.breadcrumb.it" /> },
    { text: <Translate id="product.breadcrumb.authorization_requests" /> },
  ])
  .features({ addEntity: false, deleteEntity: false })
  .entities(state => state.products.authorizationRequests)
  .actions(productAuthorizationRequestActions)
  .toolbarItems(builder => {
    builder
      .select({
        prop: 'status',
        width: 150,
        array: true,
        clearable: true,
        label: 'product_authorization_request.toolbar.label.status',
        placeholder: 'product_authorization_request.toolbar.placeholder.status',
        values: ProductAuthorizationRequestStatusOptionsWithDefault.map(x => ({
          ...x,
          label: <Translate id={x.label} />,
        })),
      })
      .button({
        buttonType: 'search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(productAuthorizationRequestActions.invalidate(true));
        },
      })
      .button({
        buttonType: 'add',
        onClick: (props: Props) => {
          const { dispatch } = props;
          const user: Partial<ProductAuthorizationRequest> = {};
          dispatch(productAuthorizationRequestActions.itemBeingCreated(user));
        },
      });
  })
  .editor(builder => {
    return builder
      .store({
        prop: 'fromId',
        label: 'product_authorization_request.editor.label.store',
        helpText: 'product_authorization_request.editor.help_text.store',
        disabled: (entity: any, props: Props) => {
          const { identity } = props;
          return Boolean(
            entity.id ||
              (identity.storeId && identity.visibleStores.length === 1),
          );
        },
      })
      .productAgent({
        prop: 'targetAgentId',
        label: 'product_authorization_request.editor.label.supplier',
        disabled: x => Boolean(x.id),
      })
      .group(g => {
        g.withOrientation('vertical')
          .withKey('notification')
          .withTitle(
            'product_authorization_request.editor.label.notification.title',
          )
          .text({
            prop: 'notificationMobiles',
            label:
              'product_authorization_request.editor.label.notification.mobiles',
            placeholder:
              'product_authorization_request.editor.placeholder.notification.mobiles',
          })
          .text({
            prop: 'notificationEmails',
            label:
              'product_authorization_request.editor.label.notification.emails',
            placeholder:
              'product_authorization_request.editor.placeholder.notification.emails',
          });
      })
      .group(g =>
        g
          .withOrientation('vertical')
          .withKey('contact')
          .withTitle('product_authorization_request.editor.label.contact.title')
          .text({
            prop: 'contactName',
            label: 'product_authorization_request.editor.label.contact.name',
            placeholder:
              'product_authorization_request.editor.placeholder.contact.name',
          })
          .text({
            prop: 'contactEmail',
            label: 'product_authorization_request.editor.label.contact.email',
            placeholder:
              'product_authorization_request.editor.placeholder.contact.email',
          })
          .text({
            prop: 'contactAddr',
            label: 'product_authorization_request.editor.label.contact.addr',
            placeholder:
              'product_authorization_request.editor.placeholder.contact.addr',
          })
          .text({
            prop: 'contactPhone',
            label: 'product_authorization_request.editor.label.contact.phone',
            placeholder:
              'product_authorization_request.editor.placeholder.contact.phone',
          })
          .text({
            prop: 'contactFax',
            label: 'product_authorization_request.editor.label.contact.fax',
            placeholder:
              'product_authorization_request.editor.placeholder.contact.fax',
          }),
      )
      .textArea({
        prop: 'fromRemark',
        label: 'product_authorization_request.editor.label.remark',
        placeholder: 'product_authorization_request.editor.placeholder.remark',
      });
  })
  .columns([
    {
      prop: 'id',
      width: 150,
      align: 'center',
      text: 'col.id',
      render: ({ id }, props: Props) => {
        const onClick = (e: MouseEvent) => {
          e.preventDefault();
          const item = props.entities.result?.find(x => x.id === id);
          if (item) {
            props.dispatch(
              productAuthorizationRequestActions.setActiveItem(item),
            );
          }
        };
        return (
          <a href="#" onClick={onClick}>
            {id}
          </a>
        );
      },
    },
    {
      prop: 'fromType',
      width: 100,
      text: 'product_authorization_request.col.from_type',
      align: 'center',
      render: ({ fromType }) => (
        <ProductAuthorizationContractClientEntityTypeLabel value={fromType} />
      ),
    },
    {
      prop: 'fromTitle',
      width: 250,
      text: 'product_authorization_request.col.from_title',
    },
    {
      prop: 'targetAgentName',
      width: 250,
      text: 'product_authorization_request.col.target',
    },
    {
      prop: 'status',
      width: 100,
      text: 'col.status',
      render: ({ status }) => (
        <ProductAuthorizationRequestStatusLabel value={status} />
      ),
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      align: 'center',
      width: 200,
      render: ({ createdAt }) => formatTime(createdAt, 'YYYY-MM-DD HH:mm'),
    },
    {
      prop: 'updatedAt',
      text: 'col.updated_at',
      align: 'center',
      width: 200,
      render: ({ updatedAt }) => formatTime(updatedAt, 'YYYY-MM-DD HH:mm', '-'),
    },
  ])
  .addActionButtons(['edit'])
  .canEdit(
    (item: ProductAuthorizationRequest) =>
      item.status === ProductAuthorizationRequestStatus.Submitted,
  )
  .onRender((props: Props) => {
    const { dispatch } = props;
    const entities = props.entities as ProductAuthorizationRequests;

    const onClose = () => {
      dispatch(cancelProductionAuthorizationRequestCanceled());
      dispatch(productAuthorizationRequestActions.setActiveItem(undefined));
    };

    const onCancel = (request: ProductAuthorizationRequest) => {
      dispatch(cancelProductionAuthorizationRequest(request));
    };

    const onConfirm = async () => {
      const remark = remarkInputRef.current?.getRemarks();
      dispatch(
        confirmCancelProductionAuthorizationRequest(remark, () => {
          dispatch(productAuthorizationRequestActions.setActiveItem(undefined));
        }),
      );
    };

    return (
      <>
        <AsideRight
          open={entities.activeItem != null}
          onClose={onClose}
          width={600}
          spin={entities.requestBeingCanceledStatus === 'loading'}
        >
          <AsideRight.Nav>
            <TabNav line="brand" bolder>
              <TabNav.Item active>
                <Translate id="product_authorization_request.detail.title" />
              </TabNav.Item>
            </TabNav>
          </AsideRight.Nav>
          <AsideRight.Content>
            {entities.activeItem != null && (
              <ProductAuthorizationRequestDetail
                request={entities.activeItem}
                error={entities.requestBeingCanceledError}
                onCancel={onCancel}
              />
            )}
          </AsideRight.Content>
          <ConfirmModal
            i18nPrefix="product_authorization_request.cancel.confirm_modal"
            isOpen={entities.requestBeingCanceledStatus === 'confirm'}
            onConfirm={onConfirm}
            onCancel={() =>
              dispatch(cancelProductionAuthorizationRequestCanceled())
            }
          >
            <RemarkInput ref={remarkInputRef} />
          </ConfirmModal>
        </AsideRight>
      </>
    );
  })
  .componentDidUpdate((props, prevProps) => {
    if (props.activeStoreId !== prevProps.activeStoreId) {
      props.dispatch(productAuthorizationRequestActions.invalidate(true));
    }
  })
  .validate(entity => {
    let msg = '';
    if (!entity.targetAgentId) {
      msg = 'target_agent_required';
    }
    if (msg) {
      throw new Error(
        getString(`product_authorization_request.editor.error.${msg}`),
      );
    }
  })
  .getClass();

type RemarkInputRef = {
  getRemarks: () => string | undefined;
};

const RemarkInput = forwardRef<RemarkInputRef>((_props, ref) => {
  const [remarks, setRemarks] = useState<string>();

  useImperativeHandle(ref, () => ({
    getRemarks: () => remarks,
  }));

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRemarks(e.target.value);
  }, []);

  return (
    <div className="form-group">
      <label className="form-group__label">
        <Translate id="product_authorization_request.cancel.confirm_modal.remark.label" />
      </label>
      <input
        type="text"
        className="form-control"
        value={remarks ?? ''}
        onChange={onChange}
        placeholder={getString(
          'product_authorization_request.cancel.confirm_modal.remark.placeholder',
        )}
      />
    </div>
  );
});
