import React, { Component } from 'react';
import { DispatchFn, EntityListActions, AsyncListState } from 'lib/duck/interfaces';
import { AppState } from 'app';
import { ConfirmDeleteModal } from './modal';

export interface ConfirmDeleteModalProps<T> {
  dispatch: DispatchFn<AppState>;
  actions: EntityListActions<AppState, any, any>;
  entities: AsyncListState<T>;
  onConfirmDelete?: () => void;
  extra?: any | (() => any);
}

export class ConfirmDeleteModalBuilder<T> {
  private i18nPrefix: string;

  withI18nPrefix(i18nPrefix: string): this {
    this.i18nPrefix = i18nPrefix;
    return this;
  }

  getClass() {
    const i18nPrefix = this.i18nPrefix;
    class ConfirmDeleteModalHOC extends Component<ConfirmDeleteModalProps<T>> {
      render() {
        const { entities } = this.props;
        return (
          <ConfirmDeleteModal
            localeSegment={i18nPrefix}
            isOpen={entities.itemsBeingDeleted && entities.itemsBeingDeleted.length > 0 ? true : false}
            isDeleting={entities.isCommitingItemsBeingDeleted}
            error={entities.lastDeleteError}
            onConfirm={this.onConfirmDelete}
            onCancel={this.onCancelDelete}
            />
        );
      }

      onConfirmDelete = () => {
        const { dispatch, actions } = this.props;
        if (this.props.onConfirmDelete) {
          this.props.onConfirmDelete();
        } else {
          if (actions.commitItemsBeingDeleted) {
            dispatch(actions.commitItemsBeingDeleted());
          }
        }
      }

      onCancelDelete = () => {
        const { dispatch, actions } = this.props;
        if (actions.cancelItemsBeingDeleted) {
          dispatch(actions.cancelItemsBeingDeleted());
        }
      }
    }
    return ConfirmDeleteModalHOC;
  }
}

export function withConfirmDeleteModalBuilder<T>() {
  return new ConfirmDeleteModalBuilder<T>();
}