/**
 * @file: InspectionTemplateSceneTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionTemplateSceneType } from 'model';

export const InspectionTemplateSceneTypeOptions: Array<Option<InspectionTemplateSceneType>> = [
  { value: InspectionTemplateSceneType.Basic, label: 'inspection_template_scene_type.basic' },
  { value: InspectionTemplateSceneType.Full, label: 'inspection_template_scene_type.full' },
  { value: InspectionTemplateSceneType.Facade, label: 'inspection_template_scene_type.facade' },
  { value: InspectionTemplateSceneType.Dashboard, label: 'inspection_template_scene_type.dashboard' },
  { value: InspectionTemplateSceneType.Custom, label: 'inspection_template_scene_type.custom' }
];

export const InspectionTemplateSceneTypeOptionsWithDefault: Array<Option<InspectionTemplateSceneType | null>> = [
  { value: null, label: 'inspection_template_scene_type.__default__' },
  { value: InspectionTemplateSceneType.Basic, label: 'inspection_template_scene_type.basic' },
  { value: InspectionTemplateSceneType.Full, label: 'inspection_template_scene_type.full' },
  { value: InspectionTemplateSceneType.Facade, label: 'inspection_template_scene_type.facade' },
  { value: InspectionTemplateSceneType.Dashboard, label: 'inspection_template_scene_type.dashboard' },
  { value: InspectionTemplateSceneType.Custom, label: 'inspection_template_scene_type.custom' }
];