import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { VehicleReport, VehicleReportDetailDefaultProjection, VehicleReportFilter } from 'model';
import { reportService } from 'services';
import { dateRangeFromDashboardState } from './helpers';

const actionCreators = createListAsyncActionCreators<AppState, VehicleReport, string>(
  'dashboard.recent_inspection_reports',
  {
    shouldFetchOnInvalidate: true,
    shouldInvalidateForFilter: true,

    async fetchHandler(state: AppState) {
      const orgId = state.identity!.orgId;
      const storeId = state.activeStoreId || undefined;

      return await reportService.searchReports(
        Object.assign({}, state.dashboard.recentInspectionReports.filter, {
          orgId, storeId, createdAt: dateRangeFromDashboardState(state)
        } as Partial<VehicleReportFilter>),
        state.dashboard.recentInspectionReports.sorts && state.dashboard.recentInspectionReports.sorts[0] as any,
        VehicleReportDetailDefaultProjection,
        state.dashboard.recentInspectionReports.offset || 0,
        state.dashboard.recentInspectionReports.limit || 10
      );
    }
  }
);

export const {
  fetch: fetchDashboardRecentInspectionReports,
  invalidate: invalidateDashboardRecentInspectionReports
} = actionCreators;