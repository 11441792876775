import { defineActionTypes } from 'lib';

export const ActionTypes = defineActionTypes('app/integration', {
  BeginAuthorization: '',
  AuthoizationSuccess: '',
  AuthorizationFailed: '',
  GetAuthorizationUrl: '',
  GetAuthorizationUrlSuccess: '',
  GetAuthorizationUrlFailed: '',

  // miniprogram
  RequestLinkMiniProgram: '',
  RequestLinkMiniProgramSuccess: '',
  RequestLinkMiniProgramFailed: '',
  UnlinkMiniProgram: '',
  UnlinkMiniProgramSuccess: '',
  UnlinkMiniProgramFailed: '',

  // for menus
  HideConditionaMenuTip: '',
  LoadMenus: '',
  LoadMenusSuccess: '',
  LoadMenusFailed: '',
  ActiveMenuChanged: '',
  AddMenu: '',
  RemoveMenu: '',
  RemoveMenuCommitted: '',
  RemoveMenuCancelled: '',
  SaveMenu: '',
  SaveMenuSuccess: '',
  SaveMenuFailed: '',

  // for menu items
  AddMenuItem: '',
  RemoveMenuItem: '',
  RemoveMenuItemCommitted: '',
  RemoveMenuItemCancelled: '',
  MenuItemTypeChanged: '',
  MenuItemChanged: '',
  ClickMenuItemMsgTypeChanged: '',
  MenuItemMoved: '',
  ActiveMenuItemChanged: '',
  LoadMenuItemData: '',
  LoadMenuItemDataSuccess: '',
  LoadMenuItemDataFailed: '',
  OnMenuItemError: '',

  // conditional menu
  ConditionalMenuMatchRuleChanged: '',
  AddTemplateFromLibrary: '',
  AddTemplateFromLibraryTemplateIdChanged: '',
  CommitAddTemplateFromLibrary: '',
  CancelAddTemplateFromLibrary: '',
  AddTemplateFromLibrarySuccess: '',
  AddTemplateFromLibraryFailed: '',
  ApplyTemplateSettingsAutomatically: '',
  ApplyTemplateSettingsAutomaticallyCancelled: '',
  ApplyTemplateSettingsAutomaticallyCommitted: '',
  ApplyTemplateSettingsAutomaticallySuccess: '',
  ApplyTemplateSettingsAutomaticallyFailed: '',
  ViewTemplateDetail: '',
  ViewTemplateDetailCancelled: '',
  ConfigureTemplate: '',
  ConfigureTemplateCancelled: '',
  ConfirmConfigureTemplateClose: '',
  ConfirmConfigureTemplateCloseCommited: '',
  ConfirmConfigureTemplateCloseCancelled: ''
});
