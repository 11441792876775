import React from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { subscriberActions } from '../duck/actions';
import {
  AclObjectList,
  OrgSubscribedUserListFilter,
  OrgSubscribedUser,
} from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  GenderLabel,
} from 'shared/components';

interface Props
  extends EntityListProps<OrgSubscribedUser, OrgSubscribedUserListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  OrgSubscribedUser,
  OrgSubscribedUserListFilter,
  number,
  Props
>();

export const SubscriberList = componentClassBuilder
  .i18nPrefix('subscriber')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/user-folder.svg')
      .default,
  )
  .accessRights({
    full: AclObjectList.OrgSubscribedUserFullAccess,
    readonly: AclObjectList.OrgSubscribedUserReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="org.breadcrumb.it" /> },
    { text: <Translate id="org.breadcrumb.subscribers" /> },
  ])
  .features({ addEntity: false })
  .entities(state => state.org.subscribers)
  .actions(subscriberActions)
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'nick',
        // label: 'subscriber.toolbar.label.nick',
        placeholder: 'subscriber.toolbar.placeholder.nick',
        width: 250,
      })
      .datePicker({
        prop: 'subscribedAt',
        width: 150,
        dropdown: {
          placement: 'bottom-end',
        },
        placeholder: 'subscriber.toolbar.placeholder.subscribed_at',
      })
      .button({
        buttonType: 'search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(subscriberActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'avatarUrl',
      width: 48,
      text: '',
      render: user => (
        <span>
          <img
            src={user.avatarUrl || '/public/img/default-avatar-generic.png'}
            style={{
              width: 32,
              height: 32,
              borderRadius: '50%',
              marginRight: 8,
            }}
          />
        </span>
      ),
    },
    {
      prop: 'nick',
      width: 100,
      text: 'col.user_name',
      render: user => (
        <span>
          <span style={{ fontWeight: 'bold' }}>{user.nick}</span>
        </span>
      ),
    },
    {
      prop: 'gender',
      width: 80,
      text: 'col.gender',
      render: ({ gender }) => <GenderLabel value={gender} />,
    },
    {
      prop: 'province',
      width: 200,
      text: 'col.locality',
      render: user =>
        [user.province, user.city].filter(x => x).join('/') || '-',
    },
    {
      prop: 'subscribedAt',
      text: 'subscriber.col.subscribed_at',
      width: 150,
      align: 'center',
      render: ({ subscribedAt }) =>
        subscribedAt ? formatTime(subscribedAt) : '-',
    },
    {
      prop: 'tagList',
      text: 'subscriber.col.tag_list',
      width: 80,
      align: 'center',
      render: ({ tagList }) => tagList || '-',
    },
    {
      prop: 'subscribeScene',
      text: 'subscriber.col.subscribe_scene',
      width: 80,
    },
  ])
  .getClass();
