import { ColorDropdown } from 'app/settings/ColorDropdown';
import { usePropertyEditorContext } from 'app/settings/PropertyEditorContext';
import { ExtendedOrderPropertyOption } from 'model';
import { MouseEvent, ReactNode, memo, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import {
  EntityEditorForm,
  EntityEditorFormBuilder,
  getString,
} from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';

export const PropertyOptionItem = memo(
  ({
    option,
    expanded,
    errors,
    showErrorOnChange,
    onChange,
    onRemove,
    onToggleExpand,
  }: {
    option: ExtendedOrderPropertyOption;
    errors?: Record<string, ReactNode>;
    showErrorOnChange?: boolean;
    expanded?: boolean;
    onChange?: (option: ExtendedOrderPropertyOption) => void;
    onRemove?: (option: ExtendedOrderPropertyOption) => void;
    onToggleExpand?: (option: ExtendedOrderPropertyOption) => void;
  }) => {
    const optionId = option.id;

    const { onOptionErrorStateChange } = usePropertyEditorContext();

    const handleChange = usePersistFn((values: ExtendedOrderPropertyOption) => {
      onChange?.({ ...option, ...values });
    });

    const handleRemove = usePersistFn((e: MouseEvent) => {
      e.preventDefault();
      if (
        confirm(
          getString('store.config.extended_property.confirm_remove_option'),
        )
      ) {
        onRemove?.(option);
      }
    });

    const form = useMemo(() => {
      const builder =
        new EntityEditorFormBuilder<ExtendedOrderPropertyOption>();
      builder
        .text({
          prop: 'label',
          label: 'store.config.label.extended_property.option_label',
          placeholder:
            'store.config.placeholder.extended_property.option_label',
          //helpText: 'store.config.help_text.extended_property.option_label',
          required: true,
          error: errors?.['label'],
          wrapControl: true,
          extraContents: (entity, updateValues) => (
            <ColorDropdown
              value={entity.color}
              onChange={value =>
                updateValues({
                  color: value,
                })
              }
            />
          ),
          onChange: changes => {
            if (showErrorOnChange) {
              onOptionErrorStateChange(
                optionId,
                'label',
                changes.label?.trim() ? undefined : (
                  <Translate id="store.config.error.extended_property.option_label_required" />
                ),
              );
            }
            return undefined;
          },
        })
        .text({
          prop: 'value',
          label: 'store.config.label.extended_property.option_value',
          hidden: !expanded,
          placeholder:
            'store.config.placeholder.extended_property.option_value',
          helpText: 'store.config.help_text.extended_property.option_value',
        })
        .text({
          prop: 'description',
          hidden: !expanded,
          label: 'store.config.label.extended_property.option_desc',
          placeholder: 'store.config.placeholder.extended_property.option_desc',
          helpText: 'store.config.help_text.extended_property.option_desc',
        })
        .checkbox({
          prop: 'isDefaultSelected',
          hidden: !expanded,
          label: 'store.config.label.extended_property.is_default_selected',
        })
        .custom({
          label: '',
          render: () => {
            const onClick = (e: MouseEvent) => {
              e.preventDefault();
              onToggleExpand?.(option);
            };
            return (
              <a href="#" onClick={onClick}>
                <Translate
                  id={`store.config.prop.${expanded ? 'collapse' : 'expand'}`}
                />
                <i
                  className={expanded ? 'la 	la-angle-up' : 'la la-angle-down'}
                  style={{ marginLeft: '0.15rem' }}
                />
              </a>
            );
          },
        });
      return builder.build();
    }, [
      errors,
      expanded,
      showErrorOnChange,
      onOptionErrorStateChange,
      optionId,
      onToggleExpand,
      option,
    ]);

    return (
      <div className="m-form__section m-form__section--first property-editor__option-item">
        <EntityEditorForm
          entity={option}
          onChange={handleChange}
          elements={form.elements}
          autocomplete={form.autocomplete}
          useUncontrolled={form.useUncontrolled}
          helpTextPlacement="after"
        />
        <a
          href="#"
          className="property-editor__option-item-close-button"
          onClick={handleRemove}
          style={{
            position: 'absolute',
            right: '-0.35rem',
            top: '-0.35rem',
            backgroundColor: 'red',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            borderRadius: '50%',
            width: '1.25rem',
            height: '1.25rem',
          }}
        >
          <i className="la la-close" />
        </a>
      </div>
    );
  },
);
