/**
 * @file: UserVehicleCertificateType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum UserVehicleCertificateType {
  VehicleLicense = 1
}

export const UserVehicleCertificateTypeValueSet = new Set([
  UserVehicleCertificateType.VehicleLicense
]);