import { getItemSource, hasFullAccessToItemSourceable } from 'lib/helpers';
import {
  AclObjectList,
  VehicleInspectionSubject,
  VehicleInspectionSubjectListFilter,
} from 'model';
import { Translate } from 'react-localize-redux';
import { commonService } from 'services';
import {
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
  ItemSourceLabel,
  StoreName,
} from 'shared/components';
import { inspectionSubjectActions } from '../duck/actions';

interface Props
  extends EntityListProps<
    VehicleInspectionSubject,
    VehicleInspectionSubjectListFilter
  > {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  VehicleInspectionSubject,
  VehicleInspectionSubjectListFilter,
  number,
  Props
>();

export const InspectionSubjectList = componentClassBuilder
  .i18nPrefix('inspection_subject')
  .accessRights({
    full: AclObjectList.VehicleInspectionSubjectFullAccess,
    readonly: AclObjectList.VehicleInspectionSubjectReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.inspection_subjects" /> },
  ])
  .entities(state => state.inspection.inspectionSubjects)
  .actions(inspectionSubjectActions)
  .editor(builder =>
    builder
      .uncontrolled()
      .store({
        prop: 'storeId',
        label: 'editor.label.store',
        placeholder: 'editor.placeholder.store',
        disabled: (_, props: Props) => {
          if (props.entities.itemBeingUpdated) return true;
          const { identity } = props;
          if (identity.storeId && identity.visibleStores.length === 1) {
            return true;
          }
          return false;
        },
      })
      .text({
        prop: 'name',
        label: 'inspection_subject.editor.label.name',
        placeholder: 'inspection_subject.editor.placeholder.name',
        onChange: async (changes, props: Props) => {
          const { dispatch } = props;
          const updatePyInitial = (pyInitial: string) => {
            if (props.entities.itemBeingCreated) {
              dispatch(
                inspectionSubjectActions.itemBeingCreatedChanged({ pyInitial }),
              );
            } else {
              dispatch(
                inspectionSubjectActions.itemBeingUpdatedChanged({ pyInitial }),
              );
            }
          };
          if (changes.name) {
            try {
              const pyInitial = await commonService.getPyInitial(changes.name);
              updatePyInitial(pyInitial);
            } catch (e) {
              console.error(e);
              updatePyInitial('');
            }
          } else {
            updatePyInitial('');
          }
        },
      })
      .text({
        prop: 'pyInitial',
        label: 'inspection_subject.editor.label.py_initial',
        placeholder: 'inspection_subject.editor.placeholder.py_initial',
      }),
  )
  .toolbarItems(builder =>
    builder
      .text({
        prop: 'name',
        label: 'inspection_subject.toolbar.label.keyword',
        placeholder: 'inspection_subject.toolbar.placeholder.keyword',
      })
      .button({
        buttonType: 'add',
      }),
  )
  .columns([
    {
      prop: 'storeName',
      width: 200,
      text: 'col.store_name',
      render: ({ storeId }) => (
        <StoreName storeId={storeId} defaultContent="-" />
      ),
    },
    {
      prop: 'name',
      width: 200,
      text: 'inspection_subject.col.name',
    },
    {
      prop: 'pyInitial',
      width: 100,
      text: 'inspection_subject.col.py_initial',
    },
    {
      prop: 'source',
      width: 80,
      text: 'service_subject.col.source',
      align: 'center',
      render: item => <ItemSourceLabel value={getItemSource(item)} />,
    },
  ])
  .addActionButtons(['edit', 'remove'])
  .filter((items, props) => {
    const keyword = props.entities.filter?.name?.trim();
    if (keyword) {
      return items.filter(
        x => x.name.includes(keyword) || x.pyInitial?.includes(keyword),
      );
    }
    return items;
  })
  .shouldDisableActionButton((button, item, props: Props) => {
    if (button === 'edit' || button === 'remove') {
      const { identity } = props;
      return !hasFullAccessToItemSourceable(identity, item);
    }
    return false;
  })
  .validate(entity => {
    const name = entity.name?.trim();
    const pyInitial = entity.pyInitial?.trim();
    let errCode: string = '';
    if (!name) {
      errCode = 'name_required';
    } else if (!pyInitial) {
      errCode = 'py_initial_required';
    }
    if (errCode) {
      const errMsg = getString('inspection_subject.editor.error.' + errCode);
      throw new Error(errMsg);
    }
  })
  .onAdd((entity, props: Props) => {
    let storeId: number | undefined = undefined;
    const { identity } = props;
    if (identity.storeId && identity.visibleStores.length === 1) {
      storeId = identity.storeId;
    }
    entity.orgId = identity.orgId;
    entity.storeId = storeId;
  })
  .getClass();
