import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { ReportResultCls, ReportResultClsIcon } from './helpers';
import { InspectionReportItem } from 'model';
import { Translate } from 'react-localize-redux';
import { ReportResultItem } from './Item';

interface Props {
  cls: ReportResultCls;
  items: InspectionReportItem[];
}

export class ReportResultItemList extends PureComponent<Props> {
  render() {
    const { cls, items } = this.props;
    const iconCls = ReportResultClsIcon[cls];
    return (
      <div className="inspection-report-result-items">
        <div className={classNames('inspection-report-result-items__header', {
          [`inspection-report-result-items__header--${cls}`]: cls
        })}>
          <i className={classNames('inspection-report-result-items__header-icon', iconCls)} />
          <Translate id={`reports.detail.result.type.${cls}`} />
          <span>(<Translate id="reports.detail.result.item_count_label" data={{ count: items.length }} />)</span>
        </div>
        {items.length > 0 && <div className="inspection-report-result-items__list">
          {items.map((item, i) => (
            <ReportResultItem key={i} item={item} />
          ))}
        </div>}
      </div>
    );
  }
}