import { OrgTeam } from 'model';
import { PureComponent } from 'react';
import { TeamPicker } from 'shared/components/TeamPicker';
import { EntityWithStoreBounded, FormElementTeamPicker } from '../types';

interface Props<T extends EntityWithStoreBounded> {
  element: FormElementTeamPicker<T>;
  disabled?: boolean;
  orgId: number;
  storeId?: number | null;
  groupId?: number | null;
  teamId?: number | null;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormTeamPicker<
  T extends EntityWithStoreBounded,
> extends PureComponent<Props<T>> {
  render() {
    const { orgId, storeId, groupId, teamId, disabled } = this.props;
    return (
      <TeamPicker
        orgId={orgId}
        storeId={storeId}
        groupId={groupId}
        teamId={teamId}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }

  onChange = (teamId: number | undefined, team: OrgTeam | null) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = teamId as any;
    void (element.onChange && element.onChange(changes as any, extra, team));
    this.props.onChange(changes as T);
  };
}
