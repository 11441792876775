/**
 * @file: AbnormalLevelOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, AbnormalLevel } from 'model';

export const AbnormalLevelOptions: Array<Option<AbnormalLevel>> = [
  { value: AbnormalLevel.Fine, label: 'abnormal_level.fine' },
  { value: AbnormalLevel.Defective, label: 'abnormal_level.defective' },
  { value: AbnormalLevel.Urgent, label: 'abnormal_level.urgent' }
];

export const AbnormalLevelOptionsWithDefault: Array<Option<AbnormalLevel | null>> = [
  { value: null, label: 'abnormal_level.__default__' },
  { value: AbnormalLevel.Fine, label: 'abnormal_level.fine' },
  { value: AbnormalLevel.Defective, label: 'abnormal_level.defective' },
  { value: AbnormalLevel.Urgent, label: 'abnormal_level.urgent' }
];