import { APIService } from 'lib/restful-client/api.service';
import {
  WeixinOpenAuthorizeUrlType,
  WeixinOpenAuthType,
  WeixinOpenAuthorizedInfo,
  MpMenus,
  WeixinPermanentMaterialType,
  WeixinPermanentNewsMaterialListItem,
  WeixinPermanentNonNewsMaterialListItem,
  ListResult,
  WeixinPermanentVideoMaterialListItem,
  WeixinPermanentMaterialListItem,
  WeixinNonNewsPermanentMaterialType,
  WeixinPermanentNewsMaterialInfo,
  WeixinPermanentVideoMaterialInfo,
  WeixinUserTag,
  ListUserByTagResult,
  MpTemplateIndustry,
  TemplateIndustrySettings,
  MpMessageTemplate,
  WeixinTemplateLibraryConf,
  LinkedMiniProgramInfo,
  WeixinTemplateAutoUpdaterResult,

  MpMenuInfo,
  MpMenuSettings,
  MpCurrentMenuInfo,
  WeixinPermanentMaterialCreationResult,
  WeixinPermanentImageMaterialCreationResult} from 'model';

export class WeixinService {
  constructor(private readonly api: APIService) {}

  async getAuthorizationUrl(
    redirectUrl: string,
    urlType: WeixinOpenAuthorizeUrlType,
    authType: WeixinOpenAuthType,
  ): Promise<string> {
    return await this.api
      .get()
      .url('/weixin/authorization/url', { redirectUrl, urlType, authType })
      .future();
  }

  async getAuthorizationQrcode(
    redirectUrl: string,
    urlType: WeixinOpenAuthorizeUrlType,
    authType: WeixinOpenAuthType,
  ): Promise<{
    authorizationUrl: string;
    dataURI: string;
  }> {
    return await this.api
      .get()
      .url('/weixin/authorization/qrcode', { redirectUrl, urlType, authType })
      .future();
  }

  async handleAuthorizationSuccessResult(
    authorizationCode: string,
    isUpdated: boolean,
  ): Promise<WeixinOpenAuthorizedInfo> {
    return await this.api
      .post()
      .url('/weixin/authorization/callback')
      .data({ authorizationCode, isUpdated })
      .future();
  }

  async getAuthorizedInfo(): Promise<WeixinOpenAuthorizedInfo> {
    return await this.api
      .get()
      .url('/weixin/authorization/authorized-info')
      .future();
  }

  async invalidateAuthorizedInfo(): Promise<WeixinOpenAuthorizedInfo> {
    return await this.api
      .post()
      .url('/weixin/authorization/authorized-info/invalidate')
      .future();
  }

  async createPermanentMaterial(
    materialType: WeixinPermanentMaterialType.Image,
    file: FormData,
  ): Promise<WeixinPermanentImageMaterialCreationResult>;
  async createPermanentMaterial(
    materialType: Exclude<
      WeixinNonNewsPermanentMaterialType,
      WeixinPermanentMaterialType.Image
    >,
    file: FormData,
  ): Promise<WeixinPermanentMaterialCreationResult>;
  async createPermanentMaterial(
    materialType: WeixinNonNewsPermanentMaterialType,
    file: FormData,
  ): Promise<
    | WeixinPermanentMaterialCreationResult
    | WeixinPermanentImageMaterialCreationResult
  > {
    return await this.api
      .post()
      .url('/weixin/permanent-materials', { materialType })
      .data(file, false)
      .future();
  }

  async fetchPermanentMaterial(
    mediaId: string,
    type: Exclude<
      WeixinNonNewsPermanentMaterialType,
      WeixinPermanentMaterialType.Video
    >,
  ): Promise<Blob>;
  async fetchPermanentMaterial(
    mediaId: string,
    type: WeixinPermanentMaterialType.News,
  ): Promise<WeixinPermanentNewsMaterialInfo>;
  async fetchPermanentMaterial(
    mediaId: string,
    type: WeixinPermanentMaterialType.Video,
  ): Promise<WeixinPermanentVideoMaterialInfo>;
  async fetchPermanentMaterial(
    mediaId: string,
    type: WeixinPermanentMaterialType,
  ): Promise<
    WeixinPermanentNewsMaterialInfo | WeixinPermanentVideoMaterialInfo | Blob
  > {
    return await this.api
      .get()
      .url('/weixin/permanent-materials/get', { mediaId, type })
      .responseType(
        type === WeixinPermanentMaterialType.Video ||
          type === WeixinPermanentMaterialType.News
          ? 'json'
          : 'blob',
      )
      .future();
  }

  async listPermanentMaterials(
    type: WeixinPermanentMaterialType.News,
    offset: number,
    limit: number,
  ): Promise<ListResult<WeixinPermanentNewsMaterialListItem>>;
  async listPermanentMaterials(
    type: WeixinPermanentMaterialType.Video,
    offset: number,
    limit: number,
  ): Promise<ListResult<WeixinPermanentVideoMaterialListItem>>;
  async listPermanentMaterials(
    type: Exclude<
      WeixinPermanentMaterialType,
      WeixinPermanentMaterialType.News
    >,
    offset: number,
    limit: number,
  ): Promise<ListResult<WeixinPermanentNonNewsMaterialListItem>>;
  async listPermanentMaterials(
    type: WeixinPermanentMaterialType,
    offset: number,
    limit: number,
  ): Promise<ListResult<WeixinPermanentMaterialListItem>> {
    return await this.api
      .get()
      .url('/weixin/permanent-materials', { type, offset, limit })
      .future();
  }

  async cachePermanentMaterialInfo(
    materialInfo: WeixinPermanentMaterialListItem,
  ): Promise<void> {
    return void await this.api
      .post()
      .url('/weixin/permanent-materials/:mediaId/local-cache', {
        mediaId: materialInfo.mediaId,
      })
      .data({ materialInfo })
      .future();
  }

  async getCachedPermanentMaterialInfo(
    mediaId: string,
  ): Promise<WeixinPermanentMaterialListItem | null | undefined> {
    return await this.api
      .get()
      .url('/weixin/permanent-materials/:mediaId/local-cache', { mediaId })
      .future();
  }

  async getSystemTemplateLibraryConf(): Promise<WeixinTemplateLibraryConf> {
    return await this.api.get().url('/weixin/template-library-conf').future();
  }

  async getTemplateIndustryList(): Promise<MpTemplateIndustry[]> {
    return await this.api
      .get()
      .url('/weixin/template-messages/templates/industry-list')
      .future();
  }

  async getTemplateIndustrySettings(): Promise<TemplateIndustrySettings> {
    return await this.api
      .get()
      .url('/weixin/template-messages/templates/industry-settings')
      .future();
  }

  async updateTemplateIndustrySettings(
    primaryIndustryNo: string,
    secondaryIndustryNo: string,
  ): Promise<void> {
    return void await this.api
      .put()
      .url('/weixin/template-messages/templates/industry-settings')
      .data({ primaryIndustryNo, secondaryIndustryNo })
      .future();
  }

  async addTemplate(libraryTemplateId: string): Promise<string> {
    return await this.api
      .post()
      .url('/weixin/template-messages/templates')
      .data({ libraryTemplateId })
      .future();
  }

  async listTemplates(): Promise<MpMessageTemplate[]> {
    return await this.api
      .get()
      .url('/weixin/template-messages/templates')
      .future();
  }

  async deleteTemplate(templateId: string): Promise<void> {
    return void await this.api
      .delete()
      .url('/weixin/template-messages/templates/:templateId', { templateId })
      .future();
  }

  async applyTemplateSettingsAutomatically(): Promise<WeixinTemplateAutoUpdaterResult> {
    return await this.api
      .post()
      .url('/weixin/template-messages/templates/apply-settings-automatically')
      .future();
  }

  async getMenus(): Promise<MpMenus> {
    return await this.api.get().url('/weixin/menus').future();
  }

  async saveMenus(
    defaultMenu: MpMenuInfo,
    conditionalMenus: MpMenuInfo[] | undefined | null,
    keyData: { [key: string]: any },
  ): Promise<string[]> {
    return await this.api
      .post()
      .url('/weixin/menus')
      .data({ defaultMenu, conditionalMenus, keyData })
      .future();
  }

  async getMenuSettings(): Promise<MpMenuSettings> {
    return await this.api.get().url('/weixin/menu/settings').future();
  }

  async getCurrentCustomizedMenuInfo(): Promise<MpCurrentMenuInfo> {
    return await this.api.get().url('/weixin/menu/current').future();
  }

  async createCustomizedMenu(menu: MpMenuInfo): Promise<void> {
    return void await this.api
      .post()
      .url('/weixin/menu/default')
      .data({ menu })
      .future();
  }

  async deleteCurrentCustomizedMenu(): Promise<void> {
    return void await this.api.delete().url('/weixin/menu/default').future();
  }

  async createConditionalMenu(menu: MpMenuInfo): Promise<string> {
    return await this.api
      .post()
      .url('/weixin/menu/conditional-menus')
      .data({ menu })
      .future();
  }

  async deleteConditionalMenu(menuid: string): Promise<void> {
    return void await this.api
      .delete()
      .url('/weixin/menu/conditional-menus/:menuid', { menuid })
      .future();
  }

  async tryMatchConditionalMenu(userId: string): Promise<MpMenuInfo> {
    return await this.api
      .post()
      .url('/weixin/menu/conditional-menus/match-test')
      .data({ userId })
      .future();
  }

  async createUserTag(name: string): Promise<WeixinUserTag> {
    return await this.api
      .post()
      .url('/weixin/user-tags')
      .data({ name })
      .future();
  }

  async listUserTags(): Promise<WeixinUserTag[]> {
    return await this.api.get().url('/weixin/user-tags').future();
  }

  async updateUserTag(id: number, name: string): Promise<WeixinUserTag> {
    return await this.api
      .put()
      .url('/weixin/user-tags/:id', { id })
      .data({ id, name })
      .future();
  }

  async deleteUserTag(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/weixin/user-tags/:id', { id })
      .data({})
      .future();
  }

  async listUsersByTag(
    tagId: number,
    nextOpenId?: string,
  ): Promise<ListUserByTagResult> {
    return await this.api
      .get()
      .url('/weixin/users/list-by-tag', { tagId, nextOpenId })
      .future();
  }

  async tagUsers(tagid: number, openids: string[]): Promise<void> {
    return void await this.api
      .post()
      .url('/weixin/user-tags/tagging')
      .data({ tagid, openids })
      .future();
  }

  async untagUsers(tagid: number, openids: string[]): Promise<void> {
    return void await this.api
      .post()
      .url('/weixin/user-tags/untagging')
      .data({ tagid, openids })
      .future();
  }

  async getUserTags(openid: string): Promise<number[]> {
    return await this.api
      .get()
      .url('/weixin/users/:openid/tags', { openid })
      .future();
  }

  async getMiniProgramLinkState(): Promise<LinkedMiniProgramInfo | undefined> {
    return await this.api.get().url('/weixin/mini-program/link-state').future();
  }

  async requestLinkMiniProgram(): Promise<LinkedMiniProgramInfo | undefined> {
    return await this.api
      .post()
      .url('/weixin/mini-program/link-state/request')
      .future();
  }

  async unlinkMiniProgram(): Promise<LinkedMiniProgramInfo | undefined> {
    return await this.api
      .delete()
      .url('/weixin/mini-program/link-state')
      .future();
  }
}
