import { createAsyncActionCreators } from 'lib/duck/actions';
import { weixinService } from 'services';
import { AppState } from 'app/duck/states';
import { TemplateIndustrySettings } from 'model';

export const weixinTemplateIndustrySettingsActions = createAsyncActionCreators<AppState, TemplateIndustrySettings>(
  'integration.templates.industry-settings',
  {
    async fetchHandler() {
      return await weixinService.getTemplateIndustrySettings();
    }
  }
);