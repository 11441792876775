import { ClassNameType } from 'model';
import {
  EntityWithAreaProps,
  EntityWithGroupBounded,
  EntityWithOrgBounded,
  EntityWithStoreBounded,
  FormElement,
  FormElementAreaPicker,
  FormElementCheckbox,
  FormElementCheckboxList,
  FormElementCustom,
  FormElementDatePicker,
  FormElementFile,
  FormElementGroup,
  FormElementGroupPicker,
  FormElementHtml,
  FormElementMemberPicker,
  FormElementMultiTextInput,
  FormElementOptionList,
  FormElementProductAgentPicker,
  FormElementReactSelect,
  FormElementSelect,
  FormElementStorePicker,
  FormElementSwitch,
  FormElementTeamPicker,
  FormElementText,
  FormElementTextArea,
  FormElementType,
  TextInputType,
} from './types';

type ElemParamType<T extends { type: FormElementType }> = Omit<T, 'type'>;

export class EntityEditorFormBuilder<T extends object> {
  protected autocomplete?: boolean;
  protected useUncontrolled?: boolean;
  protected helpTextPlacement?: 'after' | 'before';
  protected elements: Array<FormElement<T>> = [];

  uncontrolled(): this {
    this.useUncontrolled = true;
    return this;
  }

  withHelpTextPlacement(value: 'after' | 'before') {
    this.helpTextPlacement = value;
    return this;
  }

  text(
    elem: ElemParamType<FormElementText<T>> & { type?: TextInputType },
  ): this {
    this.elements.push({ type: elem.type || 'text', ...elem });
    return this;
  }

  textArea(elem: ElemParamType<FormElementTextArea<T>>): this {
    this.elements.push({ type: 'textarea', ...elem });
    return this;
  }

  datepicker(elem: ElemParamType<FormElementDatePicker<T>>): this {
    this.elements.push({ type: 'datepicker', ...elem });
    return this;
  }

  multiTextInput(elem: ElemParamType<FormElementMultiTextInput<T>>): this {
    this.elements.push({ type: 'multi-text-input', ...elem });
    return this;
  }

  html(elem: ElemParamType<FormElementHtml<T>>): this {
    this.elements.push({ type: 'html', ...elem });
    return this;
  }

  image(elem: ElemParamType<FormElementFile<T>>): this {
    this.elements.push({ type: 'image', ...elem });
    return this;
  }

  video(elem: ElemParamType<FormElementFile<T>>): this {
    this.elements.push({ type: 'video', ...elem });
    return this;
  }

  file(elem: ElemParamType<FormElementFile<T>>): this {
    this.elements.push({ type: 'file', ...elem });
    return this;
  }

  checkbox(elem: ElemParamType<FormElementCheckbox<T>>): this {
    this.elements.push({ type: 'checkbox', ...elem });
    return this;
  }

  radioList<U>(elem: ElemParamType<FormElementOptionList<T, U>>): this {
    this.elements.push({ type: 'radiolist', ...elem });
    return this;
  }

  checkboxList<U>(elem: ElemParamType<FormElementCheckboxList<T, U>>): this {
    this.elements.push({ type: 'checkboxlist', ...elem });
    return this;
  }

  select<U>(elem: ElemParamType<FormElementSelect<T, U>>): this {
    this.elements.push({ type: 'select', ...elem });
    return this;
  }

  reactSelect<U>(elem: ElemParamType<FormElementReactSelect<T, U>>): this {
    this.elements.push({ type: 'reactselect', ...elem });
    return this;
  }

  switch(elem: ElemParamType<FormElementSwitch<T>>): this {
    this.elements.push({ type: 'switch', ...elem });
    return this;
  }

  area(
    elem: Omit<
      ElemParamType<FormElementAreaPicker<T & EntityWithAreaProps>>,
      'prop'
    >,
  ): this {
    this.elements.push({ type: 'area', ...elem, prop: '__area__' as any });
    return this;
  }

  productAgent(elem: ElemParamType<FormElementProductAgentPicker<T>>): this {
    this.elements.push({ type: 'product-agent', ...elem } as any);
    return this;
  }

  store(
    elem: ElemParamType<FormElementStorePicker<T & EntityWithOrgBounded>>,
  ): this {
    this.elements.push({ type: 'store', ...elem } as any);
    return this;
  }

  orgGroup(
    elem: ElemParamType<FormElementGroupPicker<T & EntityWithStoreBounded>>,
  ): this {
    this.elements.push({ type: 'org-group', ...elem } as any);
    return this;
  }

  orgTeam(
    elem: ElemParamType<FormElementTeamPicker<T & EntityWithGroupBounded>>,
  ): this {
    this.elements.push({ type: 'org-team', ...elem } as any);
    return this;
  }

  orgMember(
    elem: ElemParamType<FormElementMemberPicker<T & EntityWithStoreBounded>>,
  ): this {
    this.elements.push({ type: 'org-member', ...elem } as any);
    return this;
  }

  custom(elem: Omit<ElemParamType<FormElementCustom<T>>, 'prop'>): this {
    this.elements.push({ type: 'custom', ...elem, prop: '' as any });
    return this;
  }

  group(
    buildGroup: (builder: EntityEditorFormElementGroupBuilder<T>) => void,
  ): this {
    const builder = new EntityEditorFormElementGroupBuilder<T>();
    buildGroup(builder);
    const group = builder.buildGroup();
    this.elements.push(group);
    return this;
  }

  autocompleteOff(): this {
    this.autocomplete = false;
    return this;
  }

  build() {
    return {
      autocomplete: this.autocomplete,
      useUncontrolled: this.useUncontrolled,
      elements: this.elements,
      helpTextPlacement: this.helpTextPlacement,
    };
  }
}

export class EntityEditorFormElementGroupBuilder<
  T extends object,
> extends EntityEditorFormBuilder<T> {
  private label: string = '';
  private className?: ClassNameType;
  private width?: string | number;
  private key?: string;
  private title?: string;
  private orientation?: FormElementGroup<any>['orientation'];

  withLabel(label: string): this {
    this.label = label;
    return this;
  }

  withTitle(title: string): this {
    this.title = title;
    return this;
  }

  withKey(key: string): this {
    this.key = key;
    return this;
  }

  withClassName(className: ClassNameType): this {
    this.className = className;
    return this;
  }

  withWidth(width: number | string) {
    this.width = width;
    return this;
  }

  withOrientation(orientation: FormElementGroup<any>['orientation']) {
    this.orientation = orientation;
    return this;
  }

  buildGroup(): FormElementGroup<T> {
    return {
      type: 'element-group',
      label: this.label,
      title: this.title,
      prop: '' as any,
      key: this.key,
      width: this.width,
      className: this.className,
      elements: this.elements,
      orientation: this.orientation,
    };
  }
}
