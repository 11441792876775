/**
 * @file: product-authorization-contracts.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { ProductAuthorizationContract } from 'model';
import { productAuthorizationService } from 'services';
import { ProductAuthorizationContractSearchParams } from 'model/app/product';

export const productAuthorizationContractActions =
  createListAsyncActionCreators<
    AppState,
    ProductAuthorizationContract,
    ProductAuthorizationContractSearchParams,
    number
  >('product_authorization_contracts', {
    shouldInvalidateForFilter: true,
    shouldFetchOnInvalidate: true,
    getItemBeingCreated: (state: AppState) =>
      state.products.authorizationContracts.itemBeingCreated,
    getItemBeingUpdated: (state: AppState) =>
      state.products.authorizationContracts.itemBeingUpdated,
    getItemsBeingDeleted: (state: AppState) =>
      state.products.authorizationContracts.itemsBeingDeleted,
    async fetchHandler(state: AppState) {
      return await productAuthorizationService.searchAuthorizationContracts(
        {
          ...state.products.authorizationContracts.filter,
          storeId: state.activeStoreId ?? undefined,
        },
        state.products.authorizationContracts.offset ?? 0,
        state.products.authorizationContracts.limit ?? 10,
      );
    },
    delete(_request: ProductAuthorizationContract) {
      throw new Error('Not supported');
    },
    deleteMulti(_items: ProductAuthorizationContract[]) {
      throw new Error('Not supported');
    },
  });
