/**
 * @file: customers.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import {
  Customer,
  CustomerListFilter,
  ListResult,
  StoreCustomer,
  StoreCustomerListFilter,
} from 'model';
import { customerService, storeCustomerService } from 'services';

export const customerActions = createListAsyncActionCreators<
  AppState,
  Customer,
  CustomerListFilter,
  number
>('org.customers', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  async fetchHandler(state: AppState) {
    const orgId = state.identity!.orgId;
    const storeId = state.activeStoreId || undefined;

    if (storeId) {
      const customerFilter: Partial<CustomerListFilter> = {};
      if (state.org.customers.filter) {
        for (const prop in state.org.customers.filter) {
          if (prop === 'orgId' || prop === 'storeId') continue;
          const filter = state.org.customers.filter as any;
          if (filter[prop]) {
            (customerFilter as any)[prop] = filter[prop];
          }
        }
      }
      const filter: Partial<StoreCustomerListFilter> = {
        orgId,
        storeId: state.org.customers.filter?.storeId,
        customerFilter,
      };
      const storeCustomers = (await storeCustomerService.list(
        filter,
        null,
        state.org.customers.offset,
        state.org.customers.limit,
        { count: true, recursive: true },
      )) as ListResult<StoreCustomer>;
      const customers: ListResult<Customer> = {
        total: storeCustomers.total,
        items: storeCustomers.items.map(
          x =>
            ({
              id: x.customerId,
              orgId: x.orgId,
              userId: x.customer?.userId,
              fullName: x.customer?.fullName,
              gender: x.customer?.gender,
              mobile: x.customer?.mobile,
              idCardNo: x.customer?.idCardNo,
              email: x.customer?.email,
              qq: x.customer?.qq,
              wechatId: x.customer?.wechatId,
              isVip: x.customer?.isVip,
              createdAt: x.customer?.createdAt,
              updatedAt: x.customer?.updatedAt,
            }) as Customer,
        ),
      };
      return customers;
    }
    return await customerService.list(
      Object.assign({}, state.org.customers.filter, { orgId, storeId }),
      state.org.customers.sorts?.[0],
      state.org.customers.offset,
      state.org.customers.limit,
      { count: true, recursive: true },
    );
  },
});
