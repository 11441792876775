/**
 * @file: summary-states.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { StoreSummaryStats } from 'model';
import { statsService } from 'services';

const actionCreators = createAsyncActionCreators<AppState, StoreSummaryStats>(
  'dashboard.summary_stats',
  {
    shouldFetchOnInvalidate: true,
    fetchHandler: (state: AppState) => {
      const orgId = state.identity!.orgId;
      const storeId = state.activeStoreId;
      const { startDate, endDate } = state.dashboard.dateRange;
      return statsService.getSummaryStats(orgId, storeId, startDate, endDate);
    },
  },
);

export const {
  fetch: fetchDashboardSummaryStats,
  invalidate: invalidateDashboardSummaryStats,
} = actionCreators;
