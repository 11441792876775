/**
 * @file: StoreMediaService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { StoreMediaListFilter, StoreMedia, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class StoreMediaServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<StoreMediaListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<StoreMedia> : StoreMedia[]> {
    return await this.api.get()
      .url('/store-medias', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<StoreMedia | null> {
    return await this.api.get()
      .url('/store-medias/:id', { id })
      .future();
  }

  async create(storeMedia: Partial<StoreMedia>): Promise<StoreMedia> {
    return await this.api.post()
      .url('/store-medias')
      .data(storeMedia)
      .future();
  }

  async update(storeMedia: Partial<StoreMedia>): Promise<StoreMedia> {
    return await this.api.put()
      .url('/store-medias/:id', { id: storeMedia.id })
      .data(storeMedia)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/store-medias/:id', { id })
      .future();
  }
}