import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { OrgSubscribedUser, OrgSubscribedUserListFilter } from 'model';
import { orgSubscribedUserService } from 'services';
import { dateRangeFromDashboardState } from './helpers';

const actionCreators = createListAsyncActionCreators<
  AppState,
  OrgSubscribedUser
>('dashboard.recent_subscribed_users', {
  shouldFetchOnInvalidate: true,
  shouldInvalidateForFilter: true,

  async fetchHandler(state: AppState) {
    const orgId = state.identity!.orgId;
    // const storeId = state.activeStoreId || undefined;
    return await orgSubscribedUserService.list(
      Object.assign({}, state.dashboard.recentSubscribedUsers.filter, {
        orgId,
        createdAt: dateRangeFromDashboardState(state),
      } as Partial<OrgSubscribedUserListFilter>),
      state.dashboard.recentSubscribedUsers.sorts?.[0],
      state.dashboard.recentSubscribedUsers.offset,
      state.dashboard.recentSubscribedUsers.limit,
    );
  },
});

export const {
  fetch: fetchDashboardRecentSubscribedUsers,
  invalidate: invalidateDashboardRecentSubscribedUsers,
} = actionCreators;
