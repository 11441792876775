import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { WeixinUserTag } from 'model';
import { weixinService } from 'services';

export const tagActions = createListAsyncActionCreators<
  AppState,
  WeixinUserTag,
  any,
  number
>('integration.tags', {
  shouldFetchOnInvalidate: true,

  getItemBeingCreated(state) {
    return state.integration.tags.itemBeingCreated;
  },

  getItemBeingUpdated(state) {
    return state.integration.tags.itemBeingUpdated;
  },

  getItemsBeingDeleted(state) {
    return state.integration.tags.itemsBeingDeleted;
  },

  mapItemKey(tag: WeixinUserTag) {
    return tag.id;
  },

  async fetchHandler() {
    return await weixinService.listUserTags();
  },

  async create(item: Partial<WeixinUserTag>) {
    return await weixinService.createUserTag(item.name!.trim());
  },

  async update(item: Partial<WeixinUserTag>) {
    return await weixinService.updateUserTag(item.id!, item.name!.trim());
  },

  async delete(item: WeixinUserTag) {
    await weixinService.deleteUserTag(item.id);
  },
});
