import React, { MouseEvent, useCallback } from 'react';
import {
  ProductAuthorizationRequest,
  ProductAuthorizationRequestStatus,
} from 'model';
import {
  ProductAuthorizationContractClientEntityTypeLabel,
  ProductAuthorizationRequestStatusLabel,
  ScopedTranslate,
} from 'shared/components';
import { formatTime } from 'utils';
import { Alert, Button } from 'lib/metronic/components';
import { Translate } from 'react-localize-redux';

export const ProductAuthorizationRequestDetail = ({
  request,
  error,
  onCancel,
}: {
  request: ProductAuthorizationRequest;
  error?: Error;
  onCancel?: (request: ProductAuthorizationRequest) => void;
}) => {
  const onCancelClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onCancel?.(request);
    },
    [onCancel, request],
  );
  return (
    <ScopedTranslate scope="product_authorization_request.detail.label">
      {({ trans }) => (
        <div className="common-detail-view">
          {error ? <Alert color="danger">{error.message}</Alert> : null}
          <dl>
            <dt>{trans('id')}</dt>
            <dd>{request.id}</dd>
          </dl>
          <dl>
            <dt>{trans('from_type')}</dt>
            <dd>
              <ProductAuthorizationContractClientEntityTypeLabel
                value={request.fromType}
              />
            </dd>
          </dl>
          <dl>
            <dt>{trans('from_title')}</dt>
            <dd>{request.fromTitle}</dd>
          </dl>
          <dl>
            <dt>{trans('target_agent_id')}</dt>
            <dd>{request.targetAgentId}</dd>
          </dl>
          <dl>
            <dt>{trans('target_agent_name')}</dt>
            <dd>{request.targetAgentName}</dd>
          </dl>
          <dl>
            <dt>{trans('created_at')}</dt>
            <dd>{formatTime(request.createdAt)}</dd>
          </dl>
          <dl>
            <dt>{trans('status')}</dt>
            <dd>
              <ProductAuthorizationRequestStatusLabel value={request.status} />
            </dd>
          </dl>
          <dl>
            <dt>{trans('updated_at')}</dt>
            <dd>{formatTime(request.updatedAt ?? request.createdAt)}</dd>
          </dl>
          <dl>
            <dt>{trans('from_remark')}</dt>
            <dd>{request.fromRemark ?? '-'}</dd>
          </dl>
          <h4>{trans('notification.title')}</h4>
          <dl>
            <dt>{trans('notification.mobiles')}</dt>
            <dd>
              {request.notificationMobiles?.split(',').map((x, index) => (
                <div key={index} style={{ lineHeight: '1.66rem' }}>
                  {x}
                </div>
              )) ?? '-'}
            </dd>
          </dl>
          <dl>
            <dt>{trans('notification.emails')}</dt>
            <dd>
              {request.notificationEmails?.split(',').map((x, index) => (
                <div key={index} style={{ lineHeight: '1.66rem' }}>
                  {x}
                </div>
              )) ?? '-'}
            </dd>
          </dl>
          <h4>{trans('contact.title')}</h4>
          <dl>
            <dt>{trans('contact.name')}</dt>
            <dd>{request.contactName ?? '-'}</dd>
          </dl>
          <dl>
            <dt>{trans('contact.email')}</dt>
            <dd>{request.contactEmail ?? '-'}</dd>
          </dl>
          <dl>
            <dt>{trans('contact.addr')}</dt>
            <dd>{request.contactAddr ?? '-'}</dd>
          </dl>
          <dl>
            <dt>{trans('contact.phone')}</dt>
            <dd>{request.contactPhone ?? '-'}</dd>
          </dl>
          <dl>
            <dt>{trans('contact.fax')}</dt>
            <dd>{request.contactFax ?? '-'}</dd>
          </dl>
          {request.status === ProductAuthorizationRequestStatus.Submitted && (
            <Button
              block
              color="warning"
              onClick={onCancelClick}
              style={{ marginTop: '1rem' }}
            >
              <Translate id="product_authorization_request.cancel.button_text" />
            </Button>
          )}
        </div>
      )}
    </ScopedTranslate>
  );
};
