/**
 * @file: filter.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionSiteNodeFilter } from 'model';
import { createStandardAction } from 'lib/duck/actions';
import { ActionTypes } from '../types';

export function inspectionSiteNodeFilterChanged(filter: Partial<InspectionSiteNodeFilter>) {
  return createStandardAction(ActionTypes.FilterChanged, filter);
}