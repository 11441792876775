import React, { Component, ReactChildren, ReactNode } from 'react';
import { Translate } from 'react-localize-redux';
import { AsideRight } from 'lib/metronic/layout';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { Button, Alert, TabNav } from 'lib/metronic/components';
import { AsyncListState } from 'lib/duck/interfaces';
import { Render } from 'model';

import './EntityEditorSidebar.scss';

const SIMULATE_COMMITING = false;

interface Props<T> {
  values: AsyncListState<T>;
  title: string | ReactNode;
  children: ReactChildren | ((entity: T | Partial<T>) => any);
  confirmClose?: boolean;
  error?: Error | null;
  extra?: any;
  width?: number;
  onSave: (entity: T | Partial<T>) => void;
  onCancel: () => void;
  onValidate?: (
    entity: T | Partial<T>,
    isCreating: boolean,
    extra: any,
  ) => void;
}

export type EntityEditorSidebarProps<T> = Props<T>;

interface State {
  showConfirmClose: boolean;
  error: Error | null;
}

interface ConfirmCloseEntityEditorSidebarModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export const ConfirmCloseEntityEditorSidebarModal =
  React.memo<ConfirmCloseEntityEditorSidebarModalProps>(props => (
    <Modal isOpen={props.open}>
      <ModalHeader>
        <Translate id="confirm_entity_editor_modal_title" />
      </ModalHeader>
      <ModalBody>
        <Translate id="confirm_entity_editor_modal_msg" />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.onClose}>
          <Translate id="yes_btn_text" />
        </Button>
        <Button color="secondary" onClick={props.onCancel}>
          <Translate id="no_btn_text" />
        </Button>
      </ModalFooter>
    </Modal>
  ));

export class EntityEditorSidebar<T>
  extends Component<Props<T>, State>
  implements Render<Props<T>>
{
  private el: HTMLElement | null | undefined;

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      showConfirmClose: false,
      error: null,
    };
  }

  render() {
    const { values, title, onCancel, children } = this.props;
    const isAdd = Boolean(values.itemBeingCreated);
    const isEdit = Boolean(values.itemBeingUpdated);
    const entity = (values.itemBeingCreated || values.itemBeingUpdated) as
      | T
      | Partial<T>;
    const show = isAdd || isEdit;
    const error = this.props.error || this.state.error;
    const spinning = Boolean(
      values.isCommitingItemBeingCreated || values.isCommitingItemBeingUpdated,
    );
    return (
      <AsideRight
        open={isAdd || isEdit}
        spin={spinning || SIMULATE_COMMITING}
        onClose={this.onClose}
        onMounted={this.onMounted}
        width={this.props.width}
      >
        <AsideRight.Nav>
          <TabNav line="brand" bolder>
            <TabNav.Item active className="kt-quick-panel__nav-item">
              {typeof title === 'string' ? (
                <Translate id={`${title}.title.${isAdd ? 'add' : 'edit'}`} />
              ) : (
                title
              )}
            </TabNav.Item>
          </TabNav>
        </AsideRight.Nav>
        <AsideRight.Content className="entity-editor-sidebar">
          {error && (
            <Alert color="danger" icon="fa fa-exclamation-triangle">
              {error.message}
            </Alert>
          )}
          {show &&
            (typeof children === 'function' ? children(entity) : children)}
          <div className="kt-form__actions text-center">
            <Button color="brand" onClick={this.onSave}>
              <Translate id="save_btn_text" />
            </Button>
            &nbsp;
            <Button color="secondary" onClick={onCancel}>
              <Translate id="cancel_btn_text" />
            </Button>
          </div>
        </AsideRight.Content>
        {this.renderConfirmDeleteModal()}
      </AsideRight>
    );
  }

  onMounted = (el: HTMLElement | null | undefined) => {
    this.el = el;
  };

  onSave = () => {
    let entity: T | Partial<T> | null = null;
    let isCreating = false;
    if (this.props.values.itemBeingCreated) {
      entity = this.props.values.itemBeingCreated;
      isCreating = true;
    }
    if (this.props.values.itemBeingUpdated) {
      entity = this.props.values.itemBeingUpdated;
    }
    if (entity) {
      try {
        this.props.onValidate &&
          this.props.onValidate(entity, isCreating, this.props.extra);
      } catch (e) {
        this.setState({ error: e });
        this.el && this.el.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
      this.setState({ error: null }, () => {
        this.props.onSave(entity!);
      });
    }
  };

  onClose = () => {
    // see if has change.
    const { confirmClose, values, onCancel } = this.props;

    const spinning =
      Boolean(
        values.isCommitingItemBeingCreated ||
          values.isCommitingItemBeingUpdated,
      ) || SIMULATE_COMMITING;

    if (spinning) {
      return;
    }

    const dirty =
      (values.itemBeingCreated && values.isItemBeingCreatedDirty) ||
      (values.itemBeingUpdated && values.isItemBeingUpdatedDirty);

    if (confirmClose === false || !dirty) {
      if (this.state.error) {
        this.setState({ error: null });
      }
      onCancel();
      return;
    }

    this.setState({
      showConfirmClose: true,
    });
  };

  onConfirmClose = () => {
    this.setState({ showConfirmClose: false, error: null }, () => {
      this.props.onCancel();
    });
  };

  onCancelClose = () => {
    this.setState({
      showConfirmClose: false,
    });
  };

  private renderConfirmDeleteModal() {
    return (
      <ConfirmCloseEntityEditorSidebarModal
        open={this.state.showConfirmClose}
        onClose={this.onConfirmClose}
        onCancel={this.onCancelClose}
      />
    );
  }
}
