/**
 * @file: VehicleInspectionTemplateService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { tokenService } from 'lib';
import { UploadProgressListener } from 'lib/restful-client/RequestBuilder';
import { InspectionTemplateBarrierLevel } from 'model';
import { TargetInspectionTemplateBarriers } from 'model/viewmodel/TargetInspectionTemplateBarriers';
import { VehicleInspectionTemplateServiceBase } from './base/VehicleInspectionTemplateService';

export class VehicleInspectionTemplateService extends VehicleInspectionTemplateServiceBase {
  // add custom service implementation here
  getExportTemplatesUrl(templateIds: number[]): string {
    const token = tokenService.getToken();
    return this.api.url('/vehicle-inspection-templates/export', {
      token,
      templateIds: templateIds.join(','),
    });
  }

  async importTemplates(
    storeId: number | null | undefined,
    file: File,
    progressListener?: UploadProgressListener,
  ): Promise<void> {
    const form = new FormData();
    form.append('realm', 'tmp');
    form.append('filename', file.name);
    form.append('size', String(file.size));
    form.append('type', file.type);
    form.append('blob', file);
    if (typeof storeId === 'number' && storeId > 0) {
      form.append('storeId', String(storeId));
    }

    return void (await this.api
      .post()
      .url('/vehicle-inspection-templates/import')
      .data(form)
      .listenUploadProgress(progressListener)
      .future());
  }

  async getTargetInspectionTemplateBarriers(
    targetLevel: InspectionTemplateBarrierLevel,
    targetId: number,
  ): Promise<TargetInspectionTemplateBarriers> {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-templates/barriers/levels/:targetLevel/targets/:targetId',
        { targetLevel, targetId },
      )
      .future();
  }

  async setInspectionTemplateBarriers(
    templateId: number,
    targetLevel: InspectionTemplateBarrierLevel,
    targetIds: number[],
  ) {
    return await this.api
      .put()
      .url('/vehicle-inspection-templates/:templateId/barriers/:targetLevel', {
        targetLevel,
        templateId,
      })
      .data({ targetIds })
      .future();
  }

  async addTargetInspectionTemplateBarrier(
    targetLevel: InspectionTemplateBarrierLevel,
    targetId: number,
    templateId: number,
  ) {
    return await this.api
      .post()
      .url('/vehicle-inspection-templates/:templateId/barriers', {
        templateId,
      })
      .data({ targetLevel, targetId })
      .future();
  }

  async removeTargetInspectionTemplateBarrier(
    targetLevel: InspectionTemplateBarrierLevel,
    targetId: number,
    templateId: number,
  ) {
    return await this.api
      .delete()
      .url('/vehicle-inspection-templates/:templateId/barriers', {
        templateId,
      })
      .data({ targetLevel, targetId })
      .future();
  }
}
