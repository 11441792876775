import moment, { unitOfTime } from 'moment';
import { Component } from 'react';
import {
  LocalizeContextProps,
  Translate,
  TranslateFunction,
  withLocalize,
} from 'react-localize-redux';
import { StatAspect, SummaryStatsState } from './duck/states';
import { StatAspectItem } from './StatAspectItem';
import { StatAspectsConfigurations } from './StatAspectsConfig';

interface Props extends LocalizeContextProps {
  stats: SummaryStatsState;
  prevStats: SummaryStatsState;
  activeAspect: StatAspect;
  onAspectChange?: (aspectType: StatAspect) => void;
}

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function formatDuration(
  value: number,
  unit: unitOfTime.DurationConstructor,
  translate: TranslateFunction,
): string {
  const duration = moment.duration(value, unit) as any;
  const template = translate('duration') as string;
  return duration.format(template, { trim: 'all' });
}

class StatAspectListComponent extends Component<Props> {
  render() {
    const { stats, prevStats, activeAspect } = this.props;
    return (
      <div className="stat-aspect-list">
        {StatAspectsConfigurations.filter(x => x.disabled !== true).map(
          item => (
            <StatAspectItem
              key={item.statAspect}
              aspectType={item.statAspect}
              label={
                <Translate id={`dashboard.stats.label.${item.statAspect}`} />
              }
              changeLabel={<Translate id="dashboard.stats.change_label" />}
              formatter={item.format}
              active={item.statAspect === activeAspect}
              value={stats.result && item.value(stats.result)}
              prevValue={prevStats.result && item.value(prevStats.result)}
              onClick={this.onAspectItemClick}
            />
          ),
        )}
      </div>
    );
  }

  onAspectItemClick = (aspectType: StatAspect) => {
    if (this.props.activeAspect !== aspectType) {
      this.props.onAspectChange && this.props.onAspectChange(aspectType);
    }
  };
}

export const StatAspectList = withLocalize<Props>(StatAspectListComponent);
