import { useAppContext } from 'app/AppContext';
import { Organization } from 'model';
import React, { memo } from 'react';
import { CSSProperties } from 'styled-components';

export const OrgName = () => <OrgInfoView />;

export const OrgInfoView = memo(
  ({
    style,
    className,
    children,
  }: {
    style?: CSSProperties;
    className?: string;
    children?: (org: Organization) => any;
  }) => {
    const { org } = useAppContext();

    if (org === undefined) {
      return null;
    }

    if (children != null) {
      return children(org);
    }

    return (
      <span style={style} className={className}>
        {org?.name ?? ''}
      </span>
    );
  },
);
