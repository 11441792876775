/**
 * @file: VehicleInspectionTaskCheckSiteService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionTaskCheckSiteListFilter, VehicleInspectionTaskCheckSite, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions, VehicleInspectionTaskCheckSiteItem } from 'model';

export class VehicleInspectionTaskCheckSiteServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskCheckSiteListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionTaskCheckSite> : VehicleInspectionTaskCheckSite[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-check-sites', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionTaskCheckSite | null> {
    return await this.api.get()
      .url('/vehicle-inspection-task-check-sites/:id', { id })
      .future();
  }

  async create(vehicleInspectionTaskCheckSite: Partial<VehicleInspectionTaskCheckSite>): Promise<VehicleInspectionTaskCheckSite> {
    return await this.api.post()
      .url('/vehicle-inspection-task-check-sites')
      .data(vehicleInspectionTaskCheckSite)
      .future();
  }

  async update(vehicleInspectionTaskCheckSite: Partial<VehicleInspectionTaskCheckSite>): Promise<VehicleInspectionTaskCheckSite> {
    return await this.api.put()
      .url('/vehicle-inspection-task-check-sites/:id', { id: vehicleInspectionTaskCheckSite.id })
      .data(vehicleInspectionTaskCheckSite)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/vehicle-inspection-task-check-sites/:id', { id })
      .future();
  }

  async getInspectedSiteItemsByTaskSiteId(id: number, recursive = false): Promise<VehicleInspectionTaskCheckSiteItem[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-check-sites/:id/inspected-site-items', { id, recursive })
      .future();
  }

  async createVehicleInspectionTaskCheckSiteItem(item: Partial<VehicleInspectionTaskCheckSiteItem>): Promise<VehicleInspectionTaskCheckSiteItem> {
    const id = item.taskSiteId;
    if (!id) {
      throw new Error('item.taskSiteId is required. ');
    }
    return await this.api.post()
      .url('/vehicle-inspection-task-check-sites/:id/inspected-site-items')
      .data(item)
      .future();
  }

  async updateVehicleInspectionTaskCheckSiteItem(item: VehicleInspectionTaskCheckSiteItem): Promise<VehicleInspectionTaskCheckSiteItem> {
    const id = item.taskSiteId;
    if (!id) {
      throw new Error('item.taskSiteId is required. ');
    }
    return await this.api.put()
      .url('/vehicle-inspection-task-check-sites/:id/inspected-site-items')
      .data(item)
      .future();
  }

  async deleteVehicleInspectionTaskCheckSiteItem(
    siteId: number | VehicleInspectionTaskCheckSiteItem, itemId?: number
  ): Promise<void> {
    if (siteId && typeof siteId === 'object') {
      itemId = siteId.id;
      if (!siteId.taskSiteId) {
        throw new Error('taskSiteId cannot be null or undefined');
      }
      siteId = siteId.taskSiteId;
    }
    if (!siteId || !itemId) {
      throw new Error('siteId and itemId are both required');
    }
    return void await this.api.delete()
      .url('/vehicle-inspection-task-check-sites/:siteId/inspected-site-items/:itemId', { siteId, itemId })
      .future();
  }
}