/**
 * @file: members.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { store } from 'app/duck/store';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { OrgMember, OrgMemberListFilter } from 'model';
import { orgMemberService } from 'services';
import { sha256 } from 'utils';
import { userActions } from '.';
import { ActionTypes } from '../types';

export const memberActions = createListAsyncActionCreators<
  AppState,
  OrgMember,
  OrgMemberListFilter,
  number
>('org.members', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,

  getItemBeingCreated: (state: AppState) => state.org.members.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) => state.org.members.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.org.members.itemsBeingDeleted,

  async fetchHandler(state: AppState) {
    const orgId = state.identity!.orgId;
    const storeId = state.activeStoreId || undefined;
    return await orgMemberService.list(
      Object.assign({}, state.org.members.filter, { orgId, storeId }),
      state.org.members.sorts?.[0],
      state.org.members.offset,
      state.org.members.limit,
      { count: true, recursive: true },
    );
  },

  async create(member: Partial<OrgMember>) {
    member.password = sha256(member.password!)!;
    return await orgMemberService.create(member);
  },
  async update(member: OrgMember) {
    if (member.password) {
      member.password = sha256(member.password)!;
    }
    return await orgMemberService.update(member).then(result => {
      setTimeout(() => {
        store.dispatch(userActions.invalidate(true) as any);
        store.dispatch(memberActions.invalidate(true) as any);
      }, 10);
      return result;
    });
  },
  async delete(member: OrgMember) {
    await orgMemberService.delete(member.id);
  },
  async deleteMulti(members: OrgMember[]) {
    if (members.length === 1) {
      await orgMemberService.delete(members[0].id);
      return;
    }
    throw new Error('delete multi members is not supported. ');
  },
});

export function setCurrentMemberQrcodeAuth(userId: number | undefined) {
  return createStandardAction(ActionTypes.OrgMemberQrcodeAuth, userId);
}
