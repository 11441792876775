import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import {
  VehicleInspectionSiteCheckItemOption,
  VehicleInspectionSiteCheckItemOptionListFilter,
} from 'model';
import { vehicleInspectionSiteCheckItemOptionService as checkItemOptionService } from 'services';

// tslint:disable-next-line: max-line-length
export const inspectionSiteItemOptionActions = createListAsyncActionCreators<
  AppState,
  VehicleInspectionSiteCheckItemOption,
  VehicleInspectionSiteCheckItemOptionListFilter,
  number
>('inspection.options', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.options.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.options.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.options.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await checkItemOptionService.list(
      Object.assign({}, state.inspection.options.filter, {
        storeId: state.activeStoreId || undefined,
      }),
      state.inspection.options.sorts?.[0],
      state.inspection.options.offset,
      state.inspection.options.limit,
      { count: true, recursive: true },
    );
  },
  async create(option: Partial<VehicleInspectionSiteCheckItemOption>) {
    return await checkItemOptionService.create({
      ...option,
      label: option.label?.toLowerCase(),
      labelFormat: option.labelFormat?.toLowerCase(),
    });
  },
  async update(option: VehicleInspectionSiteCheckItemOption) {
    return await checkItemOptionService.update({
      ...option,
      label: option.label?.toLowerCase(),
      labelFormat: option.labelFormat?.toLowerCase(),
    });
  },
  async delete(option: VehicleInspectionSiteCheckItemOption) {
    await checkItemOptionService.delete(option.id);
  },
  async deleteMulti(options: VehicleInspectionSiteCheckItemOption[]) {
    if (options.length === 1) {
      await checkItemOptionService.delete(options[0].id);
      return;
    }
    throw new Error('delete multi options is not supported. ');
  },
});
