/**
 * @file: InspectionTaskDiagnosticJobMediaCategory.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum InspectionTaskDiagnosticJobMediaCategory {
  DiagnosticResult = 1,
  Procedure
}

export const InspectionTaskDiagnosticJobMediaCategoryValueSet = new Set([
  InspectionTaskDiagnosticJobMediaCategory.DiagnosticResult,
  InspectionTaskDiagnosticJobMediaCategory.Procedure
]);