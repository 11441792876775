/**
 * @file: OfficialAccountBusinessInfoTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, OfficialAccountBusinessInfoType } from 'model';

export const OfficialAccountBusinessInfoTypeOptions: Array<Option<OfficialAccountBusinessInfoType>> = [
  { value: OfficialAccountBusinessInfoType.OpenStore, label: 'official_account_business_info_type.open_store' },
  { value: OfficialAccountBusinessInfoType.OpenScan, label: 'official_account_business_info_type.open_scan' },
  { value: OfficialAccountBusinessInfoType.OpenPay, label: 'official_account_business_info_type.open_pay' },
  { value: OfficialAccountBusinessInfoType.OpenCard, label: 'official_account_business_info_type.open_card' },
  { value: OfficialAccountBusinessInfoType.OpenShake, label: 'official_account_business_info_type.open_shake' }
];

export const OfficialAccountBusinessInfoTypeOptionsWithDefault: Array<Option<OfficialAccountBusinessInfoType | null>> = [
  { value: null, label: 'official_account_business_info_type.__default__' },
  { value: OfficialAccountBusinessInfoType.OpenStore, label: 'official_account_business_info_type.open_store' },
  { value: OfficialAccountBusinessInfoType.OpenScan, label: 'official_account_business_info_type.open_scan' },
  { value: OfficialAccountBusinessInfoType.OpenPay, label: 'official_account_business_info_type.open_pay' },
  { value: OfficialAccountBusinessInfoType.OpenCard, label: 'official_account_business_info_type.open_card' },
  { value: OfficialAccountBusinessInfoType.OpenShake, label: 'official_account_business_info_type.open_shake' }
];