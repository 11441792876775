import { getMenuItemKeyFromIndexPath } from 'app/integration/helpers';
import extend from 'extend';
import { storageService } from 'lib';
import { StandardAction } from 'lib/duck/interfaces';
import {
  MpConditionalMenuMatchRule,
  MpKeyBasedMenuItem,
  MpMenuInfo,
  MpMenuItem,
  MpMenuItemClick,
  MpMenuItemImage,
  MpMenuItemMiniprogram,
  MpMenuItemNews,
  MpMenuItemText,
  MpMenuItemType,
  MpMenuItemVideo,
  MpMenuItemView,
  MpMenuItemVoice,
  MpMenus,
  MpNonAPIMenuItemTypeSet,
  WeixinImageMsg,
  WeixinMsg,
  WeixinMsgType,
  WeixinNewsMsg,
  WeixinTextMsg,
  WeixinVideoMsg,
  WeixinVoiceMsg,
} from 'model';
import { randstr } from 'utils';
import { WeixinMenuEditorInfo, WeixinMenus } from '../states';
import { ActionTypes } from '../types';

const initialState: WeixinMenus = {
  isEnabled: null,
  isLoading: false,
  isSaving: false,
  error: null,
  editors: [],
  activeEditorId: null,
  menuBeingRemoved: null,
  menuItemBeingRemoved: null,
  keyData: {},
  showCondionalMenuTip: storageService.ls_get(
    'integration.menu.show_conditional_menu_tip',
    true,
  ),
};

let MENU_ID = 1;

function resetMenuIdSeed() {
  MENU_ID = 1;
}

function identifyExternalMenuItems(
  button: MpMenuItem,
  keyData: { [name: string]: any },
) {
  if (button.type === MpMenuItemType.Click) {
    const click = button as MpMenuItemClick;
    if (!keyData[click.key]) {
      // probably external menu item, convert it to our menu item with default info
      click.__isExternal = true;
      click.key = randstr(32);
      keyData[click.key] = { msgType: WeixinMsgType.Text, content: '' };
    }
  }
}

function createMenuEditorInfo(
  isDefaultMenu: boolean,
  menu: MpMenuInfo,
  keyData?: { [name: string]: any },
): WeixinMenuEditorInfo {
  if (!isDefaultMenu && keyData) {
    // check external menu items.
    for (const button of menu.button) {
      identifyExternalMenuItems(button, keyData);
    }
  }
  return {
    id: MENU_ID++,
    isDefaultMenu,
    activeMenuItemIndexPath: null,
    menu,
    menuItemErrors: {},
  };
}

function clickMenuItemUpdatedWithNewMsgType(
  item: MpMenuItemClick,
  msgType: WeixinMsgType,
  keyData: { [key: string]: any },
) {
  item = { ...item, __isExternal: undefined };
  if (!item.keys[msgType]) {
    const key = randstr(32);
    item.key = key;
    item.keys = { ...item.keys, [msgType]: key };
    const data: any = { msgType };
    switch (msgType) {
      case WeixinMsgType.Text: {
        data.content = '';
        break;
      }
      case WeixinMsgType.Image: {
        data.image = { mediaId: '' };
        break;
      }
      case WeixinMsgType.Video: {
        data.video = { mediaId: '', title: '', description: '' };
        break;
      }
      case WeixinMsgType.Voice: {
        data.voice = { mediaId: '' };
        break;
      }
      case WeixinMsgType.Music: {
        data.music = {};
        break;
      }
      case WeixinMsgType.News: {
        data.mediaId = '';
        data.articleCount = 0;
        data.items = [];
        break;
      }
    }
    keyData[key] = data;
  } else {
    item.key = item.keys[msgType];
  }
  return item;
}

function convertedDefaultMenuButton(
  button: MpMenuItem,
  keyData: { [key: string]: any },
): MpMenuItem {
  if (button.type && MpNonAPIMenuItemTypeSet.has(button.type)) {
    const item: MpMenuItemClick = {
      type: MpMenuItemType.Click,
      key: randstr(32),
      keys: {},
      name: button.name,
      subButton: button.subButton?.map(x =>
        convertedDefaultMenuButton(x, keyData),
      ),
    };
    switch (button.type) {
      case MpMenuItemType.Text: {
        const textButton = button as MpMenuItemText;
        item.keys[WeixinMsgType.Text] = item.key;
        keyData[item.key] = {
          msgType: WeixinMsgType.Text,
          content: textButton.value,
        } as WeixinTextMsg;
        break;
      }
      case MpMenuItemType.Image: {
        const imgButton = button as MpMenuItemImage;
        item.keys[WeixinMsgType.Image] = item.key;
        keyData[item.key] = {
          msgType: WeixinMsgType.Image,
          image: { mediaId: imgButton.value, __isTempMedia: true },
        } as WeixinImageMsg;
        break;
      }
      case MpMenuItemType.Video: {
        const videoButton = button as MpMenuItemVideo;
        item.keys[WeixinMsgType.Video] = item.key;
        keyData[item.key] = {
          msgType: WeixinMsgType.Video,
          video: { mediaId: videoButton.value, __isTempMedia: true },
        } as WeixinVideoMsg;
        break;
      }
      case MpMenuItemType.Voice: {
        const voiceButton = button as MpMenuItemVoice;
        item.keys[WeixinMsgType.Voice] = item.key;
        keyData[item.key] = {
          msgType: WeixinMsgType.Voice,
          voice: { mediaId: voiceButton.value, __isTempMedia: true },
        } as WeixinVoiceMsg;
        break;
      }
      case MpMenuItemType.News: {
        const newsButton = button as MpMenuItemNews;
        item.keys[WeixinMsgType.News] = item.key;
        keyData[item.key] = {
          msgType: WeixinMsgType.News,
          mediaId: newsButton.value,
          articleCount: newsButton.newsInfo.list.length,
          items: newsButton.newsInfo.list.map(x => ({
            title: x.title,
            description: x.digest,
            picUrl: x.coverUrl || '',
            url: x.contentUrl,
          })),
        } as WeixinNewsMsg;
        break;
      }
      default: {
        break;
      }
    }
    return item;
  } else if (button.subButton) {
    return {
      ...button,
      keys: button.keys || {},
      subButton: button.subButton.map(x =>
        convertedDefaultMenuButton(x, keyData),
      ),
    };
  }

  if (!button.keys) button.keys = {};

  if (button.type == MpMenuItemType.Click) {
    const click = button as MpMenuItemClick;
    if (!click.key) click.key = randstr(32);
    const data = keyData[click.key] as WeixinMsg;
    if (!data) {
      keyData[click.key] = { msgType: WeixinMsgType.Text, content: '' };
      button.keys[WeixinMsgType.Text] = click.key;
    } else {
      button.keys[data.msgType] = click.key;
    }
  }

  return button;
}

function initMenu(menu: MpMenuInfo, keyData: { [name: string]: any }) {
  menu.button = menu.button.map(x => convertedDefaultMenuButton(x, keyData));
  return menu;
}

export default function menusReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: WeixinMenus = initialState,
  action: StandardAction<any>,
): WeixinMenus {
  switch (action.type) {
    case ActionTypes.HideConditionaMenuTip: {
      storageService.ls_set(
        'integration.menu.show_conditional_menu_tip',
        false,
      );
      return { ...state, showCondionalMenuTip: false };
    }
    case ActionTypes.LoadMenus: {
      return { ...state, isLoading: true, error: null };
    }
    case ActionTypes.LoadMenusSuccess: {
      const { current, settings, keyData } = action.payload as MpMenus;
      if (!current) {
        return {
          ...state,
          isEnabled: false,
          isLoading: false,
          editors: [],
          activeEditorId: null,
        };
      }
      // create the editors from the menus
      resetMenuIdSeed();

      const editors: WeixinMenuEditorInfo[] = [];

      const defaultMenu = settings?.menu
        ? initMenu(settings.menu, keyData)
        : {
            button: current.selfmenuInfo.button.map(x =>
              convertedDefaultMenuButton(x, keyData),
            ),
          };
      const defaultMenuEditor = createMenuEditorInfo(
        true,
        defaultMenu,
        keyData,
      );
      editors.push(defaultMenuEditor);

      if (settings?.conditionalmenu?.length) {
        for (const menu of settings.conditionalmenu) {
          const conditionalMenuEditor = createMenuEditorInfo(
            false,
            initMenu(menu, keyData),
            keyData,
          );
          editors.push(conditionalMenuEditor);
        }
      }

      return {
        ...state,
        isEnabled: Boolean(current.isMenuOpen),
        isLoading: false,
        editors,
        activeEditorId: defaultMenuEditor.id,
        keyData,
      };
    }
    case ActionTypes.LoadMenusFailed: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case ActionTypes.ActiveMenuChanged: {
      return { ...state, activeEditorId: action.payload };
    }
    case ActionTypes.AddMenu: {
      const defaultEditor = state.editors.find(x => x.isDefaultMenu);
      if (defaultEditor) {
        const editor = createMenuEditorInfo(false, {
          button: [],
          matchrule: {},
        });
        const editors = [...state.editors, editor];
        return { ...state, dirty: true, editors, activeEditorId: editor.id };
      }

      const editor = createMenuEditorInfo(true, { button: [] });
      const editors = [editor];
      return { ...state, dirty: true, editors, activeEditorId: editor.id };
    }
    case ActionTypes.RemoveMenu: {
      return { ...state, menuBeingRemoved: action.payload };
    }
    case ActionTypes.RemoveMenuCommitted: {
      if (!state.menuBeingRemoved) return state;
      const target = state.editors.find(x => x.id === state.menuBeingRemoved)!;
      if (target.isDefaultMenu) {
        return {
          ...state,
          dirty: true,
          menuBeingRemoved: null,
          editors: [],
          activeEditorId: null,
        };
      }
      const editors = state.editors.filter(
        x => x.id !== state.menuBeingRemoved,
      );
      const first = editors[0];
      return {
        ...state,
        dirty: true,
        menuBeingRemoved: null,
        editors,
        activeEditorId: first ? first.id : null,
      };
    }
    case ActionTypes.RemoveMenuCancelled: {
      return { ...state, menuBeingRemoved: null };
    }
    case ActionTypes.SaveMenu: {
      return { ...state, error: null, isSaving: true };
    }
    case ActionTypes.SaveMenuSuccess: {
      const conditionalMenuIds: string[] = action.payload.conditionalMenuIds;
      const editors = state.editors.map((editor, i) =>
        editor.isDefaultMenu
          ? editor
          : {
              ...editor,
              menu: { ...editor.menu, menuid: conditionalMenuIds[i] },
            },
      );
      return { ...state, dirty: false, editors, isSaving: false };
    }
    case ActionTypes.SaveMenuFailed: {
      return { ...state, isSaving: false, error: action.payload };
    }
    case ActionTypes.AddMenuItem: {
      const {
        editorId: id,
        name,
        parentIndex,
      } = action.payload as {
        editorId: number;
        name: string;
        parentIndex?: number;
      };
      const editor = state.editors.find(x => x.id === id)!;
      const key = randstr(32);
      const item: MpKeyBasedMenuItem = {
        type: MpMenuItemType.Click,
        name,
        key,
        keys: {
          [WeixinMsgType.Text]: key,
        },
      };
      if (parentIndex !== undefined) {
        // add sub menu item
        const parentMenuItem = editor.menu.button[parentIndex];
        if (parentMenuItem.subButton && parentMenuItem.subButton.length === 5) {
          return state;
        }
        return {
          ...state,
          dirty: true,
          keyData: {
            ...state.keyData,
            [item.key]: {
              msgType: WeixinMsgType.Text,
              content: '',
            } as WeixinTextMsg,
          },
          editors: state.editors.map(x =>
            x.id === id
              ? {
                  ...x,
                  menu: {
                    ...x.menu,
                    button: x.menu.button.map((button, i) =>
                      i === parentIndex
                        ? {
                            ...button,
                            subButton: button.subButton
                              ? [...button.subButton, item]
                              : [item],
                          }
                        : button,
                    ),
                  },
                }
              : x,
          ),
        };
      } else {
        if (editor.menu.button.length === 3) return state;
        return {
          ...state,
          dirty: true,
          keyData: {
            ...state.keyData,
            [item.key]: {
              msgType: WeixinMsgType.Text,
              content: '',
            } as WeixinTextMsg,
          },
          editors: state.editors.map(x =>
            x.id === id
              ? {
                  ...x,
                  menu: {
                    ...x.menu,
                    button: [...x.menu.button, item],
                  },
                }
              : x,
          ),
        };
      }
    }
    case ActionTypes.RemoveMenuItem: {
      return {
        ...state,
        menuItemBeingRemoved: action.payload,
      };
    }
    case ActionTypes.RemoveMenuItemCommitted: {
      const { editorId: id, index, parentIndex } = state.menuItemBeingRemoved!;
      if (parentIndex !== undefined) {
        return {
          ...state,
          dirty: true,
          editors: state.editors.map(x =>
            x.id === id
              ? {
                  ...x,
                  activeMenuItemIndexPath: null,
                  menu: {
                    ...x.menu,
                    button: x.menu.button.map((button, i) =>
                      i === parentIndex
                        ? {
                            ...button,
                            subButton:
                              button.subButton!.length === 1
                                ? undefined
                                : button.subButton!.filter(
                                    (_, k) => k !== index,
                                  ),
                          }
                        : button,
                    ),
                  },
                }
              : x,
          ),
        };
      } else {
        return {
          ...state,
          dirty: true,
          menuItemBeingRemoved: null,
          editors: state.editors.map(x =>
            x.id === id
              ? {
                  ...x,
                  activeMenuItemIndexPath: null,
                  menu: {
                    ...x.menu,
                    button: x.menu.button.filter((_, k) => k !== index),
                  },
                }
              : x,
          ),
        };
      }
    }
    case ActionTypes.RemoveMenuItemCancelled: {
      return { ...state, menuItemBeingRemoved: null };
    }
    case ActionTypes.ClickMenuItemMsgTypeChanged: {
      const id: number = action.payload.editorId;
      const path: number[] = action.payload.path;
      const msgType: WeixinMsgType = action.payload.msgType;

      let editor = state.editors.find(x => x.id === id)!;
      const keyData = {};
      const [t1, t2] = path;

      if (t2 !== undefined) {
        const item = clickMenuItemUpdatedWithNewMsgType(
          editor.menu.button[t1].subButton![t2] as MpMenuItemClick,
          msgType,
          keyData,
        );
        editor = {
          ...editor,
          menu: {
            ...editor.menu,
            button: editor.menu.button.map((button, i) =>
              i === t1
                ? {
                    ...button,
                    subButton: button.subButton!.map((y, k) =>
                      k === t2 ? item : y,
                    ),
                  }
                : button,
            ),
          },
        };
      } else {
        const item = clickMenuItemUpdatedWithNewMsgType(
          editor.menu.button[t1] as MpMenuItemClick,
          msgType,
          keyData,
        );
        editor = {
          ...editor,
          menu: {
            ...editor.menu,
            button: editor.menu.button.map((x, i) => (i === t1 ? item : x)),
          },
        };
      }

      return {
        ...state,
        dirty: true,
        keyData: Object.keys(keyData)
          ? { ...state.keyData, ...keyData }
          : state.keyData,
        editors: state.editors.map(x => (x.id === id ? editor : x)),
      };
    }
    case ActionTypes.MenuItemTypeChanged: {
      const id: number = action.payload.editorId;
      const path: number[] = action.payload.path;
      const type: MpMenuItemType = action.payload.type;
      const [t1, t2] = path;
      const editor = state.editors.find(x => x.id === id)!;
      let keyData = state.keyData;
      let buttonUpdated =
        t2 !== undefined
          ? editor.menu.button[t1].subButton![t2]
          : editor.menu.button[t1];
      const prevType = buttonUpdated.type;
      buttonUpdated = { ...buttonUpdated, type };

      if ('__isExternal' in (buttonUpdated as any)) {
        delete (buttonUpdated as any).__isExternal;
      }

      const saveEditorState = () => {
        if (prevType === MpMenuItemType.Miniprogram) {
          const miniprogramButton = buttonUpdated as MpMenuItemMiniprogram;
          buttonUpdated.keys = {
            ...buttonUpdated.keys,
            [MpMenuItemType.Miniprogram as string]: JSON.stringify({
              url: miniprogramButton.url,
              appid: miniprogramButton.appid,
              pagepath: miniprogramButton.pagepath,
            }),
          };
          delete (buttonUpdated as any).url;
          delete (buttonUpdated as any).appid;
          delete (buttonUpdated as any).pagepath;
        } else if (prevType === MpMenuItemType.View) {
          const viewButton = buttonUpdated as MpMenuItemView;
          buttonUpdated.keys = {
            ...buttonUpdated.keys,
            [MpMenuItemType.View as string]: JSON.stringify({
              url: viewButton.url,
            }),
          };
          delete (buttonUpdated as any).url;
        } else if (prevType === MpMenuItemType.Click) {
          const clickButton = buttonUpdated as MpMenuItemClick;
          buttonUpdated.keys = {
            ...buttonUpdated.keys,
            [MpMenuItemType.Click as string]: clickButton.key,
          };
          delete (buttonUpdated as any).key;
        }
      };

      saveEditorState();

      if (type === MpMenuItemType.Click) {
        const keyedButton = buttonUpdated as MpKeyBasedMenuItem;
        if (buttonUpdated.keys[MpMenuItemType.Click as any]) {
          keyedButton.key = buttonUpdated.keys[MpMenuItemType.Click as any];
        } else if (!keyedButton.key) {
          keyedButton.key = randstr(32);
          keyData = {
            ...keyData,
            [keyedButton.key]: {
              msgType: WeixinMsgType.Text,
              content: '',
            } as WeixinTextMsg,
          };
          keyedButton.keys = { [WeixinMsgType.Text]: keyedButton.key };
        }
      } else if (type === MpMenuItemType.View) {
        // load view values from keys
        const viewButton = buttonUpdated as MpMenuItemView;
        if (buttonUpdated.keys[MpMenuItemType.View as string]) {
          const viewInfo = JSON.parse(
            buttonUpdated.keys[MpMenuItemType.View as string],
          );
          viewButton.url = viewInfo.url;
        } else {
          viewButton.url = '';
        }
      } else if (type === MpMenuItemType.Miniprogram) {
        // load miniprogram info from keys
        const miniprogramButton = buttonUpdated as MpMenuItemMiniprogram;
        if (buttonUpdated.keys[MpMenuItemType.Miniprogram as string]) {
          const miniprogramInfo = JSON.parse(
            buttonUpdated.keys[MpMenuItemType.Miniprogram as string],
          );
          miniprogramButton.url = miniprogramInfo.url;
          miniprogramButton.appid = miniprogramInfo.appid;
          miniprogramButton.pagepath = miniprogramInfo.pagepath;
        } else {
          miniprogramButton.url = '';
          miniprogramButton.appid = '';
          miniprogramButton.pagepath = '';
        }
      }
      if (t2 !== undefined) {
        return {
          ...state,
          dirty: true,
          keyData,
          editors: state.editors.map(x =>
            x.id === id
              ? {
                  ...x,
                  menu: {
                    ...x.menu,
                    button: x.menu.button.map((button, i) =>
                      i === t1
                        ? {
                            ...button,
                            subButton: button.subButton!.map((y, k) =>
                              k === t2 ? buttonUpdated : y,
                            ),
                          }
                        : button,
                    ),
                  },
                }
              : x,
          ),
        };
      } else {
        return {
          ...state,
          dirty: true,
          keyData,
          editors: state.editors.map(x =>
            x.id === id
              ? {
                  ...x,
                  menu: {
                    ...x.menu,
                    button: x.menu.button.map((y, k) =>
                      k === t1 ? buttonUpdated : y,
                    ),
                  },
                }
              : x,
          ),
        };
      }
    }
    case ActionTypes.MenuItemChanged: {
      const id: number = action.payload.editorId;
      const path: number[] = action.payload.path;
      const changes: any = action.payload.changes;
      const data: any = action.payload.data;

      const [t1, t2] = path;

      const buttonWithoutExternalKey = (button: any) => {
        if ('__isExternal' in button) {
          delete button.__isExternal;
        }
        return button;
      };

      const mergedKeyData = () => {
        const keyData = state.keyData;
        if (!data) return keyData;
        const editor = state.editors.find(x => x.id === id)!;
        const button =
          t2 !== undefined
            ? editor.menu.button[t1].subButton![t2]
            : editor.menu.button[t1];
        if (button.type === MpMenuItemType.Click) {
          const clickButton = button as MpMenuItemClick;
          const prevData = keyData[clickButton.key] as WeixinMsg;
          switch (prevData.msgType) {
            case WeixinMsgType.News: {
              const prevNewsData = prevData as WeixinNewsMsg;
              const newsData = data as WeixinNewsMsg;
              return {
                ...keyData,
                [clickButton.key]: {
                  ...prevNewsData,
                  ...newsData,
                },
              };
            }
            default: {
              return {
                ...keyData,
                [clickButton.key]: extend(true, {}, prevData, data),
              };
            }
          }
        }
        return keyData;
      };

      if (t2 !== undefined) {
        return {
          ...state,
          dirty: true,
          keyData: mergedKeyData(),
          editors: state.editors.map(x =>
            x.id === id
              ? {
                  ...x,
                  menu: {
                    ...x.menu,
                    button: x.menu.button.map((button, i) =>
                      i === t1
                        ? {
                            ...button,
                            subButton: button.subButton!.map((y, k) =>
                              k === t2
                                ? buttonWithoutExternalKey({ ...y, ...changes })
                                : y,
                            ),
                          }
                        : button,
                    ),
                  },
                }
              : x,
          ),
        };
      } else {
        return {
          ...state,
          dirty: true,
          keyData: mergedKeyData(),
          editors: state.editors.map(x =>
            x.id === id
              ? {
                  ...x,
                  menu: {
                    ...x.menu,
                    button: x.menu.button.map((y, k) =>
                      k === t1
                        ? buttonWithoutExternalKey({ ...y, ...changes })
                        : y,
                    ),
                  },
                }
              : x,
          ),
        };
      }
    }
    case ActionTypes.MenuItemMoved: {
      const id: number = action.payload.editorId;
      const parentIndex: number | undefined = action.payload.parentIndex;
      const fromIndex: number = action.payload.fromIndex;
      const toIndex: number = action.payload.toIndex;

      const buttonsReordered = (buttons: any[]) => {
        buttons = [...buttons];
        buttons.splice(toIndex, 0, buttons.splice(fromIndex, 1)[0]);
        return buttons;
      };

      if (parentIndex !== undefined) {
        return {
          ...state,
          dirty: true,
          editors: state.editors.map(x =>
            x.id === id
              ? {
                  ...x,
                  menu: {
                    ...x.menu,
                    button: x.menu.button.map((button, i) =>
                      i === parentIndex
                        ? {
                            ...button,
                            subButton: buttonsReordered(button.subButton!),
                          }
                        : button,
                    ),
                  },
                }
              : x,
          ),
        };
      } else {
        return {
          ...state,
          dirty: true,
          editors: state.editors.map(x =>
            x.id === id
              ? {
                  ...x,
                  menu: {
                    ...x.menu,
                    button: buttonsReordered(x.menu.button),
                  },
                }
              : x,
          ),
        };
      }
    }
    case ActionTypes.ActiveMenuItemChanged: {
      const editorId: number = action.payload.editorId;
      const indexPath: number[] | null = action.payload.indexPath;
      return {
        ...state,
        editors: state.editors.map(x =>
          x.id === editorId
            ? {
                ...x,
                activeMenuItemIndexPath: indexPath,
              }
            : x,
        ),
      };
    }
    case ActionTypes.LoadMenuItemData: {
      const id: number = action.payload.editorId;
      const indexPath: number[] = action.payload.indexPath;
      const msgType: WeixinMsgType = action.payload.msgType;
      if (msgType !== WeixinMsgType.News) return state;
      return {
        ...state,
        editors: state.editors.map(x =>
          x.id === id
            ? {
                ...x,
                menuItemDataBeingLoadedIndexPath: indexPath,
                menuItemDataLoadError: null,
              }
            : x,
        ),
      };
    }
    case ActionTypes.LoadMenuItemDataSuccess: {
      const id: number = action.payload.editorId;
      const indexPath: number[] = action.payload.indexPath;
      const msgType: WeixinMsgType = action.payload.msgType;
      const data: any = action.payload.data;
      if (msgType !== WeixinMsgType.News) return state;
      const editor = state.editors.find(x => x.id === id)!;
      const [t1, t2] = indexPath;
      const button = (
        t2 ? editor.menu.button[t1].subButton![t2] : editor.menu.button[t1]
      ) as MpMenuItemClick;
      const keyData = {
        ...state.keyData,
        [button.key]: { ...(state.keyData[button.key] || {}), ...data },
      };
      return {
        ...state,
        editors: state.editors.map(x =>
          x.id === id
            ? {
                ...x,
                menuItemDataBeingLoadedIndexPath: null,
                menuItemDataLoadError: null,
              }
            : x,
        ),
        keyData,
      };
    }
    case ActionTypes.LoadMenuItemDataFailed: {
      const id: number = action.payload.editorId;
      const indexPath: number[] = action.payload.indexPath;
      const msgType: WeixinMsgType = action.payload.msgType;
      const error: Error = action.payload.error;
      if (msgType !== WeixinMsgType.News) return state;
      return {
        ...state,
        editors: state.editors.map(x =>
          x.id === id
            ? {
                ...x,
                menuItemDataBeingLoadedIndexPath: indexPath,
                menuItemDataLoadError: error,
              }
            : x,
        ),
      };
    }
    case ActionTypes.OnMenuItemError: {
      const id: number = action.payload.editorId;
      const indexPath: number[] = action.payload.indexPath;
      const name: string = action.payload.name;
      const error: string | null = action.payload.error;
      const key = getMenuItemKeyFromIndexPath(id, indexPath);
      return {
        ...state,
        editors: state.editors.map(x =>
          x.id === id
            ? {
                ...x,
                menuItemErrors: {
                  ...x.menuItemErrors,
                  [key]: {
                    ...(x.menuItemErrors[key] || {}),
                    [name]: error,
                  },
                },
              }
            : x,
        ),
      };
    }
    case ActionTypes.ConditionalMenuMatchRuleChanged: {
      const id: number = action.payload.editorId;
      const changes: Partial<MpConditionalMenuMatchRule> =
        action.payload.changes;
      return {
        ...state,
        dirty: true,
        editors: state.editors.map(x =>
          x.id === id
            ? {
                ...x,
                menu: {
                  ...x.menu,
                  matchrule: { ...x.menu.matchrule, ...changes },
                },
              }
            : x,
        ),
      };
    }
    default: {
      return state;
    }
  }
}
