/**
 * @file: product-authorization-requests.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { IdType, ProductAuthorizationRequest } from 'model';
import { productAuthorizationService } from 'services';
import { ProductAuthorizationRequestSearchParams } from 'model/app/product';
import { ActionTypes } from '../types';
import { ActionThunk } from 'lib/duck/interfaces';

export const productAuthorizationRequestActions = createListAsyncActionCreators<
  AppState,
  ProductAuthorizationRequest,
  ProductAuthorizationRequestSearchParams,
  IdType
>('product_authorization_requests', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  mapItemKey(item) {
    return item.id;
  },
  getItemBeingCreated: (state: AppState) =>
    state.products.authorizationRequests.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.products.authorizationRequests.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.products.authorizationRequests.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await productAuthorizationService.searchAuthorizationRequests(
      {
        ...state.products.authorizationRequests.filter,
        storeId: state.activeStoreId ?? undefined,
      },
      state.products.authorizationRequests.offset ?? 0,
      state.products.authorizationRequests.limit ?? 10,
    );
  },
  async create(item) {
    return await productAuthorizationService.createAuthorizationRequest({
      targetAgentId: item.targetAgentId!,
      storeId: item.fromId,
      remark: item.fromRemark ?? undefined,
      notification: {
        emails: item.notificationEmails?.split(',').map(x => x.trim()),
        mobiles: item.notificationMobiles?.split(',').map(x => x.trim()),
      },
      contact: {
        name: item.contactName ?? undefined,
        email: item.contactEmail ?? undefined,
        addr: item.contactAddr ?? undefined,
        phone: item.contactPhone ?? undefined,
        fax: item.contactFax ?? undefined,
      },
    });
  },
  async update(item) {
    return await productAuthorizationService.updateAuthorizationRequest({
      requestId: item.id,
      remark: item.fromRemark ?? undefined,
      notification: {
        mobiles: item.notificationMobiles?.split(',').map(x => x.trim()),
        emails: item.notificationEmails?.split(',').map(x => x.trim()),
      },
      contact: {
        name: item.contactName ?? undefined,
        email: item.contactEmail ?? undefined,
        addr: item.contactAddr ?? undefined,
        phone: item.contactPhone ?? undefined,
        fax: item.contactFax ?? undefined,
      },
    });
  },
  delete(_request: ProductAuthorizationRequest) {
    throw new Error('Not supported');
  },
  deleteMulti(_items: ProductAuthorizationRequest[]) {
    throw new Error('Not supported');
  },
});

export function cancelProductionAuthorizationRequest(
  request: ProductAuthorizationRequest,
) {
  return createStandardAction(
    ActionTypes.CancelProductAuthorizationRequest,
    request,
  );
}

export function cancelProductionAuthorizationRequestCanceled() {
  return createStandardAction(
    ActionTypes.CancelProductAuthorizationRequestCanceled,
  );
}

export function confirmCancelProductionAuthorizationRequest(
  remark?: string,
  callback?: () => void,
): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();
    const request = state.products.authorizationRequests.requestBeingCanceled;
    if (!request) return;
    dispatch(
      createStandardAction(
        ActionTypes.CancelProductAuthorizationRequestRequested,
      ),
    );
    productAuthorizationService
      .cancelAuthorizationRequest(request.id, remark)
      .then(updated => {
        dispatch(
          productAuthorizationRequestActions.updateSuccess(request, updated),
        );
        dispatch(
          createStandardAction(
            ActionTypes.CancelProductAuthorizationRequestSuccess,
            updated,
          ),
        );
        callback?.();
      })
      .catch(err => {
        dispatch(
          createStandardAction(
            ActionTypes.CancelProductAuthorizationRequestError,
            err,
          ),
        );
      });
  };
}
