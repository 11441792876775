/**
 * @file: areas.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { commonService } from 'services';
import { AreaTree } from 'model';

const actionCreators = createAsyncActionCreators<AppState, AreaTree>(
  'app.areas',
  {
    fetchHandler: () => {
      return commonService.listAreas();
    },
  },
);

export const { fetch: fetchAreas } = actionCreators;
