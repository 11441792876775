export const Colors = {
  blue: '#007bff',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#e83e8c',
  red: '#dc3545',
  orange: '#fd7e14',
  yellow: '#ffc107',
  green: '#28a745',
  teal: '#20c997',
  cyan: '#17a2b8',
  white: '#fff',
  gray: '#6c757d',
  grayDark: '#343a40',
  primary: '#5867dd',
  secondary: '#ebedf2',
  success: '#34bfa3',
  info: '#36a3f7',
  warning: '#ffb822',
  danger: '#f4516c',
  light: '#f8f9fa',
  dark: '#343a40',
};
