import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleReceptionOrder } from 'model';
import { RecentReceptionOrders } from '../states';

const initialState: RecentReceptionOrders = {
  isLoading: false,
  error: null,
  result: null,
  filter: {},
  sorts: [{ property: 'createdAt', dir: 'desc' }],
  offset: 0,
  limit: 15
};

export default createAsyncListActionReducers<VehicleReceptionOrder, RecentReceptionOrders>(
  'dashboard.recent_reception_orders',
  initialState,
  {
    mapItemKey: x => x.id
  }
);