/**
 * @file: CarwashType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum CarwashType {
  None = 0,
  NoWash,
  PaidWash,
  FreeWash,
}

export const CarwashTypeValueSet = new Set([
  CarwashType.None,
  CarwashType.NoWash,
  CarwashType.PaidWash,
  CarwashType.FreeWash,
]);
