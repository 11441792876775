import { tokenService } from 'lib';
import { Identity } from 'model';
import { Component } from 'react';
import { Redirect } from 'react-router';
import { authService } from 'services';
import { Oops } from 'shared/components/Oops';

import { onUserAuthenticated } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import { DispatchFn } from 'lib/duck/interfaces';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AclContext } from 'lib/decorators/acl';
import './authenticate.scss';

interface Props {
  identity: Identity | null;
  dispatch: DispatchFn<AppState>;
}

interface State {
  redirectUrl: string | null;
  isLoading: boolean;
}

const mapStateToProps = (state: AppState) => {
  return {
    identity: state.identity,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};

/**
 * High order component that requires authentication.
 * @param Component
 */
export function authenticate<TProps, T extends Component<TProps>>(
  WrappedComponent: T,
) {
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class Authenticate extends Component<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = { redirectUrl: null, isLoading: false };
      }

      componentDidMount() {
        this.setState({ isLoading: true });
        authService
          .getIdentity({
            returnUrl: location.href,
          })
          .then(
            identity => {
              identity.visibleStoreSet = new Set(
                identity.visibleStores.map(x => x.storeId),
              );
              identity.hasAccessRights = (...rights: string[]) => {
                if (!rights.length) return true;
                return identity.acl.some(x => rights.includes(x));
              };
              this.props.dispatch(onUserAuthenticated(identity));
              this.setState({ isLoading: false });
            },
            err => {
              this.setState({ isLoading: false });

              if (err.code) {
                if (err.code !== 'authentication_failed') {
                  localStorage.setItem('__last_auth_error__', err.message);
                }
                this.setState({ redirectUrl: '/login' });
                return;
              }

              if (!err.response?.authenticator) {
                console.error(err);
                return;
              }

              const { authenticator } = err.response;

              tokenService.removeToken();

              if (authenticator === 'form') {
                this.setState({ redirectUrl: '/login' });
              } else {
                const { redirectUri } = err.response.args;
                location.href = redirectUri;
              }
            },
          );
      }

      $has = (...rights: string[]) => {
        if (!this.props.identity) return false;
        if (!rights.length) return true;
        const identity = this.props.identity;
        return identity.acl.some(x => rights.includes(x));
      };

      render() {
        const { redirectUrl, isLoading } = this.state;
        const { identity } = this.props;

        if (!identity) {
          if (redirectUrl) {
            return <Redirect to={redirectUrl} />;
          }
          return !isLoading ? <Oops /> : null;
        }

        const TargetComponent = WrappedComponent as any;

        return (
          <AclContext.Provider value={{ identity, $has: this.$has }}>
            <TargetComponent {...this.props} />
          </AclContext.Provider>
        );
      }
    },
  );
}
