import { AppState } from 'app/duck/states';
import { Range } from 'model';

export function dateRangeFromDashboardState(state: AppState): Range<string | Date> {
  const { startDate, endDate } = state.dashboard.dateRange;
  return {
    start: startDate,
    startInclusive: true,
    end: endDate,
    endInclusive: true
  };
}