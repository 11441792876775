import { MainNavStatus, TransFunction } from 'app';
import {
  AppLoadingState,
  AppModalState,
  AppState,
  Areas,
  UIState,
} from 'app/duck/states';
import { DispatchFn, MapStateToPropsFn } from 'lib/duck/interfaces';
import { MenuItem } from 'lib/metronic/components';
import { Footer } from 'lib/metronic/layout';
import { initWebsocket } from 'lib/websocket';
import { Identity, OrgUser } from 'model';
import React, { Component } from 'react';
import {
  LocalizeContextProps,
  LocalizeState,
  getTranslate,
  withLocalize,
} from 'react-localize-redux';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ThunkDispatch } from 'redux-thunk';
import { createStructuredSelector } from 'reselect';
import {
  AppModal,
  MediaQuery,
  MenuConfig,
  authenticate,
} from 'shared/components';
import { AppContextProvider } from './AppContext';

import {
  activeStoreChanged,
  closeAppModal,
  closeMainNavInSmallScreen,
  fetchAreas,
  revealAsideLeft,
  toggleMinimize,
  unrevealAsideLeft,
} from './duck/actions';

import { Routes } from 'app/Routes';
import { WrappedLayout } from 'app/WrappedLayout';

const enableWebSocket = true;

interface Props extends LocalizeContextProps {
  dispatch: DispatchFn<AppState>;
  identity: Identity;
  currentUser: OrgUser | null;
  localize: LocalizeState;
  areas: Areas;
  activeStoreId: number | null;
  uiState: UIState;
  appModal: AppModalState;
  appLoading: AppLoadingState;
  onLoad: () => void;
  onToggleMinimize: (status?: MainNavStatus) => void;
  onRevealAsideLeft: () => void;
  onUnrevealAsideLeft: () => void;
}

interface MenuItemContext {
  type: 'store';
}

interface StoreMenuItemContext extends MenuItemContext {
  storeId: number | null;
}

// type MenuItemHandler = (context: any) => void;

const propsSelector = createStructuredSelector<AppState, Partial<Props>>({
  currentUser: state => state.identity?.userInfo,
  localize: state => state.localize,
  uiState: state => state.ui,
  appModal: state => state.modal,
  appLoading: state => state.loading,
  areas: state => state.areas,
  activeStoreId: state => state.activeStoreId,
});

const mapStateToProps: MapStateToPropsFn<AppState, Partial<Props>> = (
  state: AppState,
) => {
  return propsSelector(state);
};

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return {
    dispatch,
    onLoad: () => {
      dispatch((_: any, getState: () => AppState) => {
        const state = getState() as AppState;
        if (!state.areas.result && !state.areas.isLoading) {
          dispatch(fetchAreas());
        }
      });
    },
    onToggleMinimize: (status?: MainNavStatus) => {
      dispatch(toggleMinimize(status));
    },
    onRevealAsideLeft: () => {
      dispatch(revealAsideLeft());
    },
    onUnrevealAsideLeft: () => {
      dispatch(unrevealAsideLeft());
    },
  };
}

export class MainComponent extends Component<Props, any, any> {
  private readonly mainNavElRef = React.createRef<HTMLDivElement>();
  private readonly asideLeftOverlayElRef = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.uiState.mainNavStatus !== this.props.uiState.mainNavStatus ||
      prevProps.uiState.asideLeftHover !== this.props.uiState.asideLeftHover ||
      prevProps.uiState.mainNavOpenInSmallScreen !==
        this.props.uiState.mainNavOpenInSmallScreen
    ) {
      this.updateBodyClass();
    }

    if (
      prevProps.uiState.mainNavOpenInSmallScreen !==
      this.props.uiState.mainNavOpenInSmallScreen
    ) {
      if (this.props.uiState.mainNavOpenInSmallScreen) {
        if (this.asideLeftOverlayElRef.current) {
          this.asideLeftOverlayElRef.current.addEventListener(
            'click',
            this.onAsideLeftOverlayClick,
          );
        }
      } else {
        if (this.asideLeftOverlayElRef.current) {
          this.asideLeftOverlayElRef.current.removeEventListener(
            'click',
            this.onAsideLeftOverlayClick,
          );
        }
      }
    }
  }

  componentDidMount() {
    this.props.onLoad && this.props.onLoad();
    this.updateBodyClass();
    enableWebSocket && initWebsocket();
  }

  updateBodyClass() {
    const { mainNavStatus, asideLeftHover, mainNavOpenInSmallScreen } =
      this.props.uiState;

    if (mainNavStatus === 'minimized') {
      document.body.classList.add('kt-aside--minimize');
    } else if (mainNavStatus === 'open') {
      document.body.classList.remove('kt-aside--minimize');
      document.body.classList.remove('kt-aside--minimize-hover');
    }

    if (mainNavStatus === 'minimized' && !mainNavOpenInSmallScreen) {
      if (asideLeftHover) {
        document.body.classList.remove('kt-aside--minimize');
        document.body.classList.add('kt-aside--minimize-hover');
      } else {
        document.body.classList.remove('kt-aside--minimize-hover');
        document.body.classList.add('kt-aside--minimize');
      }
    }

    if (mainNavOpenInSmallScreen) {
      if (this.mainNavElRef.current) {
        this.mainNavElRef.current.classList.add('kt-aside--on');
      }
    } else {
      if (this.mainNavElRef.current) {
        this.mainNavElRef.current.classList.remove('kt-aside--on');
      }
    }
  }

  render() {
    const { appModal, currentUser, identity, areas, translate } = this.props;

    if (!currentUser) return null;

    return (
      <AppContextProvider
        identity={identity}
        areas={areas.result}
        activeStoreId={this.props.activeStoreId}
        trans={getTranslate(this.props.localize) as TransFunction}
        translate={translate}
      >
        <ReactTooltip id="global" effect="solid" type="dark" place="top" />
        <WrappedLayout
          user={currentUser}
          activeStoreId={this.props.activeStoreId}
          logo={require('assets/img/logo.png')}
          brand={'ZHICHETECH'}
          menu={MenuConfig}
          isAsideOpen={this.props.uiState.mainNavStatus === 'open'}
          showLoading={this.props.appLoading.show}
          loadingMessage={this.props.appLoading.message}
          loadingStatus={this.props.appLoading.status}
          onToggleAside={this.onToggleMinimize}
          onAsideMouseEnter={this.onSideLeftMouseEnter}
          onAsideMouseLeave={this.onSideLeftMouseLeave}
          onTopMenuItemClick={this.onTopMenuItemClick}
        >
          <MediaQuery
            query="(min-width: 1024px)"
            onChange={this.onScreenWidthChange}
          />
          <Routes />
          <Footer />
        </WrappedLayout>
        <AppModal
          isOpen={appModal.isOpen}
          title={appModal.title || ''}
          message={appModal.message || ''}
          onClose={this.onCloseAppModal}
        />
      </AppContextProvider>
    );
  }

  private readonly onCloseAppModal = () => {
    const { dispatch } = this.props;
    dispatch(closeAppModal());
  };

  private readonly onToggleMinimize = (status?: MainNavStatus) => {
    this.props.onToggleMinimize(status);
  };

  private readonly onSideLeftMouseEnter = () => {
    if (this.props.uiState.mainNavStatus === 'minimized') {
      this.props.onRevealAsideLeft();
    }
  };

  private readonly onSideLeftMouseLeave = () => {
    if (this.props.uiState.mainNavStatus === 'minimized') {
      this.props.onUnrevealAsideLeft();
    }
  };

  // private onLanguageChange = (lang: string) => {
  //   storageService.ls_set('lang', lang);
  //   location.reload();
  // }

  // private onOpenMainNavInSmallScreen = () => {
  //   this.props.dispatch(openMainNavInSmallScreen());
  // }

  private readonly onAsideLeftOverlayClick = (e: MouseEvent) => {
    e.preventDefault();
    this.props.dispatch(closeMainNavInSmallScreen());
  };

  private readonly onScreenWidthChange = (matches: boolean) => {
    if (matches) {
      this.props.dispatch(closeMainNavInSmallScreen());
    }
  };

  private readonly onTopMenuItemClick = (item: MenuItem) => {
    if (!item.context) return;
    const context = item.context as MenuItemContext;
    if (context.type === 'store') {
      const storeMenuItemContext = context as StoreMenuItemContext;
      this.props.dispatch(activeStoreChanged(storeMenuItemContext.storeId));
    }
  };
}

export const Main = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<Props, MainComponent>(withLocalize<Props>(MainComponent) as any),
);
