/**
 * @file: user.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createStandardAction } from 'lib/duck/actions';
import { ActionTypes } from '../types';
import { Identity } from 'model';

export function onUserAuthenticated(identity: Identity) {
  return createStandardAction(ActionTypes.UserAuthenticated, identity);
}
