/**
 * @file: ui.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createStandardAction } from 'lib/duck/actions';
import { ActionTypes } from '../types';
import { MainNavStatus } from 'app';
import { StandardAction } from 'lib/duck/interfaces';

export function toggleMinimize(
  status?: MainNavStatus,
): StandardAction<MainNavStatus | undefined> {
  return createStandardAction(ActionTypes.ToggleMiminize, status);
}

export function openMainNavInSmallScreen() {
  return createStandardAction(ActionTypes.OpenMainNavInSmallScreen);
}

export function closeMainNavInSmallScreen() {
  return createStandardAction(ActionTypes.CloseMainNavInSmallScreen);
}

export function revealAsideLeft(): StandardAction<void> {
  return createStandardAction(ActionTypes.RevealAsideLeft);
}

export function unrevealAsideLeft(): StandardAction<void> {
  return createStandardAction(ActionTypes.UnrevealAsideLeft);
}

export function revealAsideRight(): StandardAction<void> {
  return createStandardAction(ActionTypes.RevealAsideRight);
}

export function unrevealAsideRight(): StandardAction<void> {
  return createStandardAction(ActionTypes.UnrevealAsideRight);
}
