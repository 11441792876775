import React, { Component } from 'react';
import { Identity } from 'model';
import { Translate } from 'react-localize-redux';
import { AppContext } from 'app/AppContext';

interface Props {
  rights: string | string[];
  accessDeniedView?: any;
  silent?: boolean;
  children?: ((identity: Identity, hasRights: boolean) => any) | any;
}

export class Restricted extends Component<Props> {
  render() {
    const {
      rights,
      children,
      accessDeniedView,
      silent
    } = this.props;

    return (
      <AppContext.Consumer>
        {({ identity }) => {
          const hasRights = Array.isArray(rights) ?
            identity.hasAccessRights(...rights) :
            identity.hasAccessRights(rights);

          if (typeof children === 'function') {
            return (children as any)(identity, hasRights);
          }

          if (!hasRights) {
            if (silent) return null;

            return (
              <div className="restricted-no-rights kt-grid__item kt-grid__item--fluid">
              {accessDeniedView ||
                <div className="restricted-no-rights--default-content">
                  <i className="la la-lightbulb-o icon" />
                  <Translate id="access_denied_placeholder" />
                </div>}
              </div>
            );
          }

          const count = React.Children.count(children);

          if (count === 1) return children;

          return (<>{children}</>);
        }}
      </AppContext.Consumer>
    );
  }
}