import classNames from 'classnames';
import { OrgMember } from 'model';
import { PureComponent } from 'react';
import { Options } from 'react-select';
import { orgMemberService } from 'services';
import { Select } from '../Select';
import { getString } from '../StringLabel';

interface Props {
  orgId: number;
  storeId?: number | null;
  memberId?: number | null;
  className?: string;
  disabled?: boolean;
  onChange: (memberId: number | undefined, member: OrgMember | null) => void;
}

interface State {
  members: OrgMember[] | null;
  isLoadingMembers: boolean;
}

export class MemberPicker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      members: null,
      isLoadingMembers: false,
    };
  }

  render() {
    const { members, isLoadingMembers } = this.state;
    const { memberId, className, disabled, storeId } = this.props;

    const selectedMember =
      members && memberId
        ? members.find(x => x.id === memberId && x.storeId === storeId)
        : null;

    return (
      <div className={classNames('member-picker', className)}>
        <Select<OrgMember>
          key={`store/${storeId || 0}/members`}
          values={[]}
          valueProp="id"
          labelProp="name"
          disabled={disabled}
          className="member-picker__component member-picker__member"
          selectedValue={selectedMember}
          placeholder={getString('member_picker.placeholder.select_member')}
          isLoading={isLoadingMembers}
          isClearable
          noOptionsMessage={this.onNoOptionsMessage}
          async
          defaultValues
          onLoadValues={this.onLoadMembers}
          onChange={this.onChange}
        />
      </div>
    );
  }

  onNoOptionsMessage = (obj: { inputValue: string }): string | null => {
    if (!obj.inputValue) {
      return getString('member_picker.no_values_msg.member_hint');
    }
    return getString('member_picker.no_values_msg.member');
  };

  onLoadMembers = async (inputValue: string): Promise<OrgMember[]> => {
    const { orgId, storeId, memberId } = this.props;
    if (!storeId) return [];

    if (!inputValue) {
      if (memberId) {
        try {
          this.setState({ isLoadingMembers: true });
          const member = await orgMemberService.get(memberId);
          const members = member ? [member] : [];
          this.setState({
            isLoadingMembers: false,
            members,
          });
          return members;
        } catch (e) {
          console.error(e);
          this.setState({ isLoadingMembers: false });
        }
      } else {
        this.setState({ isLoadingMembers: false, members: [] });
      }
    } else {
      this.setState({ isLoadingMembers: true });
      try {
        const members = await orgMemberService.list(
          {
            orgId,
            storeId,
            keyword: inputValue,
          },
          null,
          0,
          0,
          { recursive: false },
        );
        this.setState({ isLoadingMembers: false, members });
        return members;
      } catch (e) {
        console.error(e);
        this.setState({ isLoadingMembers: false, members: null });
      }
    }

    return [];
  };

  onChange = (value: Options<OrgMember>) => {
    if (Array.isArray(value)) {
      return;
    }

    if (!value) {
      this.props.onChange(undefined, null);
      return;
    }

    const member = value as unknown as OrgMember;
    this.props.onChange(member.id, member);
  };
}
