import React, { Component } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Column, DataTable } from 'lib/metronic/components';
import { getScopedTransFunction } from 'app';
import { RecentCustomers } from './duck/states';
import { Customer } from 'model';
import { formatTime } from 'utils';
import { GenderLabel } from 'shared/components';

interface Props extends LocalizeContextProps {
  customers: RecentCustomers;
}

export class RecentCustomerListComponent extends Component<Props> {
  private columns: Array<Column<Customer>>;

  constructor(props: Props, context: any) {
    super(props, context);
    this.init();
  }

  render() {
    const { customers } = this.props;
    return (
      <DataTable
        columns={this.columns}
        idProp="id"
        selModel="none"
        data={customers.result}
        isLoading={customers.isLoading}
        minHeight={0}
        error={customers.error}
        />
    );
  }

  private init() {
    const { translate } = this.props;
    const trans = getScopedTransFunction(translate, '');
    this.columns = [{
      prop: 'fullName',
      width: 150,
      text: trans('col.name')
    }, {
      prop: 'mobile',
      width: 150,
      align: 'center',
      text: trans('col.mobile')
    }, {
      prop: 'gender',
      width: 150,
      align: 'center',
      text: trans(`col.gender`),
      render: (customer: Customer) => customer.gender ? <GenderLabel value={customer.gender} /> : '-'
    }, {
      prop: 'email',
      width: 250,
      text: trans('col.email'),
      render: ({ email }) => email || '-'
    }, {
      prop: 'createdAt',
      text: trans('col.created_at'),
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt)
    }];
  }
}

export const RecentCustomerList = withLocalize(RecentCustomerListComponent);