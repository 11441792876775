import '@babel/polyfill';
import moment from 'moment';
import momentDurationSetup from 'moment-duration-format';

/* tslint:disable: max-line-length */
require('!file-loader?name=public/css/icons/style.css!./assets/icons/style.css');
require('assets/scss/index.scss');
require('lib/metronic/assets/scss/style.scss');
require('lib/vendor-icons.scss');
require('lib/webfonts.scss');

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function dummy(p) {
  require(
    '!file-loader?name=public/css/icons/fonts/[name].[ext]!./assets/icons/fonts/' +
      p,
  );
}

momentDurationSetup(moment as any);
