import { AppState } from 'app/duck/states';
import { orgUserService } from 'services';
import { createObjectMapAsyncActionCreators } from 'lib/duck/actions';
import { OrgUserAccessRights } from 'model';

const actionCreators = createObjectMapAsyncActionCreators<AppState, number, OrgUserAccessRights>(
  'org.users.acl',
  {
    fetchHandler: () => Promise.reject(),
    fetchObjectHandler: async (_, userId: number) => {
      const acl = await orgUserService.getOrgUserAcl(userId);
      return { userId, acl };
    },
    updateObjectHandler: async (_, obj: OrgUserAccessRights) => {
      const acl = await orgUserService.setOrgUserAcl(obj.userId, obj.acl);
      return { userId: obj.userId, acl };
    },
    mapValueToKey: (obj: OrgUserAccessRights) => obj.userId
  }
);

export const {
  fetchObjectByKey: fetchOrgUserAcl,
  requestUpdateObjectByKey: updateOrgUserAcl
} = actionCreators;