export interface KnownUrl {
  group: string;
  name: string;
  path: string;
}

export const KnownUrls: KnownUrl[] = [{
  group: 'sa',
  name: 'order',
  path: '/sa/order.html'
}, {
  group: 'sa',
  name: 'orders',
  path: '/sa/orders.html'
}, {
  group: 'sa',
  name: 'index',
  path: '/sa/index.html'
}, {
  group: 'sa',
  name: 'reports',
  path: '/sa/reports.html'
}, {
  group: 'customer',
  name: 'index',
  path: '/index.html'
}, {
  group: 'customer',
  name: 'orders',
  path: '/orders.html'
}, {
  group: 'customer',
  name: 'vehicles',
  path: '/vehicles.html'
}, {
  group: 'customer',
  name: 'reports',
  path: '/reports.html'
}];