import { getDateRangePickerLocale } from 'app';
import { format } from 'date-fns';
import { Dropdown } from 'lib/metronic/components';
import { createRef, PureComponent } from 'react';
import { Calendar } from 'react-date-range';
import { Translate } from 'react-localize-redux';

type Props = {
  value?: string | Date | null;
  placeholder?: string;
  format?: string;
  onChange?: (value: string | Date | null | undefined) => void;
};

export class FormDatePicker extends PureComponent<Props> {
  private readonly ref = createRef<Dropdown>();

  onChange = (date: Date) => {
    this.props.onChange?.(date);
  };

  render() {
    return (
      <Dropdown
        ref={this.ref}
        buttonContents={
          this.props.value
            ? format(
                new Date(this.props.value),
                this.props.format ?? 'yyyy-MM-dd',
              )
            : (this.props.placeholder ?? 'Select date')
        }
        color="brand"
        label
        data-placement="left"
        dropdownProps={{
          placement: 'bottom-start',
          dismissOnClick: false,
        }}
      >
        <Translate>
          {({ activeLanguage }) => (
            <Calendar
              locale={getDateRangePickerLocale(activeLanguage.code)}
              dateDisplayFormat={this.props.format ?? 'yyyy-MM-dd'}
              date={
                this.props.value != null
                  ? new Date(this.props.value)
                  : undefined
              }
              onChange={value => {
                this.ref.current?.hide();
                this.onChange(value);
              }}
            />
          )}
        </Translate>
      </Dropdown>
    );
  }
}
