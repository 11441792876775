/**
 * @file: useQuotationTplPanelHeight.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */

import { useCallback, useEffect, useRef } from 'react';

export const useQuotationTplPanelHeight = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const update = useCallback(() => {
    if (containerRef.current != null) {
      containerRef.current.style.minHeight = '0px';
    }
    const footerEl = document.querySelector('.kt-footer');
    const contentEl = document.querySelector('.kt-content');

    let height = 0;
    if (footerEl && contentEl) {
      const footerRect = footerEl.getBoundingClientRect();
      const contentRect = contentEl.getBoundingClientRect();
      height = footerRect.top - contentRect.top - 120 - 2.2 * 13;
    }

    if (containerRef.current != null) {
      containerRef.current.style.minHeight = `${height}px`;
    }
  }, []);

  useEffect(() => {
    const onResize = () => {
      update();
    };
    window.addEventListener('resize', onResize);
    update();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [update]);

  return {
    containerRef,
  };
};
