/**
 * @file: OrgUserRoleTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, OrgUserRoleType } from 'model';

export const OrgUserRoleTypeOptions: Array<Option<OrgUserRoleType>> = [
  { value: OrgUserRoleType.Administrator, label: 'org_user_role_type.admin' },
  { value: OrgUserRoleType.Manager, label: 'org_user_role_type.manager' },
  {
    value: OrgUserRoleType.ServiceAgents,
    label: 'org_user_role_type.service_agent',
  },
  { value: OrgUserRoleType.Technicians, label: 'org_user_role_type.tech' },
  {
    value: OrgUserRoleType.QualityInspector,
    label: 'org_user_role_type.quality_inspector',
  },
  {
    value: OrgUserRoleType.AgentManager,
    label: 'org_user_role_type.agent_manager',
  },
  { value: OrgUserRoleType.Quoter, label: 'org_user_role_type.quoter' },
  { value: OrgUserRoleType.Financial, label: 'org_user_role_type.financial' },
];

export const OrgUserRoleTypeOptionsWithDefault: Array<
  Option<OrgUserRoleType | null>
> = [
  { value: null, label: 'org_user_role_type.__default__' },
  { value: OrgUserRoleType.Administrator, label: 'org_user_role_type.admin' },
  { value: OrgUserRoleType.Manager, label: 'org_user_role_type.manager' },
  {
    value: OrgUserRoleType.ServiceAgents,
    label: 'org_user_role_type.service_agent',
  },
  { value: OrgUserRoleType.Technicians, label: 'org_user_role_type.tech' },
  {
    value: OrgUserRoleType.QualityInspector,
    label: 'org_user_role_type.quality_inspector',
  },
  {
    value: OrgUserRoleType.AgentManager,
    label: 'org_user_role_type.agent_manager',
  },
  { value: OrgUserRoleType.Quoter, label: 'org_user_role_type.quoter' },
  { value: OrgUserRoleType.Financial, label: 'org_user_role_type.financial' },
];
