/**
 * @file: users.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { store } from 'app/duck/store';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { StandardAction } from 'lib/duck/interfaces';
import { OrgUser, OrgUserAccessRights, OrgUserListFilter } from 'model';
import { orgUserService } from 'services';
import { sha256 } from 'utils';
import { memberActions } from '.';
import { ActionTypes } from '../types';

export const userActions = createListAsyncActionCreators<
  AppState,
  OrgUser,
  OrgUserListFilter,
  number
>('org.users', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,

  getItemBeingCreated: (state: AppState) => state.org.users.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) => state.org.users.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) => state.org.users.itemsBeingDeleted,

  async fetchHandler(state: AppState) {
    const orgId = state.identity!.orgId;
    const storeId = state.activeStoreId || undefined;
    return await orgUserService.list(
      Object.assign({}, state.org.users.filter, { orgId, storeId }),
      state.org.users.sorts?.[0],
      state.org.users.offset,
      state.org.users.limit,
      { count: true, recursive: true },
    );
  },

  async create(user: Partial<OrgUser>) {
    user.password = sha256(user.password!.trim())!;
    user.userName = user.userName!.trim();
    user.email = user.email?.trim();
    user.mobile = user.mobile?.trim();
    user.jobNo = user.jobNo?.trim();
    user.jobTitle = user.jobTitle?.trim();
    return await orgUserService.create(user).then(result => {
      result.password = undefined as any;
      return result;
    });
  },
  async update(user: OrgUser) {
    if (user.password) {
      user.password = sha256(user.password.trim())!;
    }
    user.email = user.email?.trim();
    user.mobile = user.mobile?.trim();
    user.jobNo = user.jobNo?.trim();
    user.jobTitle = user.jobTitle?.trim();

    // the following field cannot be updated
    // (user as any).storeId = undefined;
    // (user as any).role = undefined;
    (user as any).userName = undefined;

    return await orgUserService.update(user).then(result => {
      result.password = undefined as any;
      setTimeout(() => {
        store.dispatch(userActions.invalidate(true) as any);
        store.dispatch(memberActions.invalidate(true) as any);
      }, 10);
      return result;
    });
  },
  async delete(user: OrgUser) {
    await orgUserService.delete(user.id);
  },
  async deleteMulti(users: OrgUser[]) {
    if (users.length === 1) {
      await orgUserService.delete(users[0].id);
      return;
    }
    throw new Error('delete multi users is not supported. ');
  },
});

export function openUserAclSettings(user: OrgUser) {
  return createStandardAction(ActionTypes.OpenUserAclSettings, user);
}

export function closeUserAclSettings() {
  return createStandardAction(ActionTypes.CloseUserAclSettings);
}

export function setCurrentOrgUserAcl(
  userId: number | null,
  acl: string[],
): StandardAction<OrgUserAccessRights | null> {
  return createStandardAction(
    ActionTypes.SetCurrentOrgUserAcl,
    userId ? { userId, acl } : null,
  );
}

export function setCurrentOrgUserVisibleStores(userId: number | null) {
  return createStandardAction(
    ActionTypes.SetCurrentOrgUserVisibleStores,
    userId,
  );
}

export function setCurrentUserQrcodeAuth(userId: number | undefined) {
  return createStandardAction(ActionTypes.OrgUserQrcodeAuth, userId);
}
