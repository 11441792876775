import { AppContext } from 'app/AppContext';
import classNames from 'classnames';
import { TabNav } from 'lib/metronic/components';
import { AsideRight } from 'lib/metronic/layout';
import {
  InspectionTemplateBarrierLevel,
  Store,
  VehicleInspectionTemplate,
} from 'model';
import { CSSProperties, memo, useContext, useEffect, useState } from 'react';
import {
  vehicleInspectionTemplateBarrierService,
  vehicleInspectionTemplateService,
} from 'services';
import { ScopedTranslate } from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';

const S = memo(({ id }: { id: string }) => {
  return <ScopedTranslate scope="inspection_template.barriers" id={id} />;
});

const Item = memo(
  ({ templateId, store }: { templateId: number; store: Store }) => {
    const { id, name } = store;
    const [checked, setChecked] = useState<boolean>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      vehicleInspectionTemplateService
        .getTargetInspectionTemplateBarriers(
          InspectionTemplateBarrierLevel.Store,
          id,
        )
        .then(barriers => {
          setChecked(!barriers.store.includes(templateId));
        })
        .catch(err => {
          console.error(err);
        });
    }, [id, templateId]);

    const onClick = usePersistFn(async () => {
      if (loading) return;

      setLoading(true);

      try {
        if (checked) {
          await vehicleInspectionTemplateService.addTargetInspectionTemplateBarrier(
            InspectionTemplateBarrierLevel.Store,
            id,
            templateId,
          );
          setChecked(false);
        } else {
          await vehicleInspectionTemplateService.removeTargetInspectionTemplateBarrier(
            InspectionTemplateBarrierLevel.Store,
            id,
            templateId,
          );
          setChecked(true);
        }
      } catch (e) {
        alert(e.message);
      } finally {
        setLoading(false);
      }
    });

    return (
      <li
        key={id}
        style={{
          listStyleType: 'none',
          marginLeft: 0,
          paddingLeft: 0,
          marginBottom: '0.3rem',
        }}
      >
        <input
          id={`barrier-item-${id}`}
          type="checkbox"
          value={id}
          onClick={onClick}
          onChange={() => null}
          checked={checked}
          style={{
            verticalAlign: 'middle',
          }}
        />
        <label
          htmlFor={`barrier-item-${id}`}
          style={{
            marginLeft: '0.35rem',
            verticalAlign: 'middle',
            marginBottom: 0,
          }}
        >
          {name}
        </label>
      </li>
    );
  },
);

export const BarriersSettings = memo(
  ({
    template,
    onClose,
  }: {
    template?: VehicleInspectionTemplate;
    onClose?: () => void;
  }) => {
    return (
      <AsideRight open={template != null} onClose={onClose}>
        <AsideRight.Nav>
          <TabNav line="brand" bolder>
            <TabNav.Item active>
              <S id="title" />
            </TabNav.Item>
          </TabNav>
        </AsideRight.Nav>
        <AsideRight.Content>
          {template != null && <Content template={template} />}
        </AsideRight.Content>
      </AsideRight>
    );
  },
);

export const Content = memo(
  ({
    template,
    style,
  }: {
    template: VehicleInspectionTemplate;
    style?: CSSProperties;
  }) => {
    const templateId = template.id;
    const [isVisibleToAgent, setIsVisibleToAgent] = useState<boolean>();
    const [isVisibleToOrg, setIsVisibleToOrg] = useState<boolean>();
    const { identity, globalVisibleStores } = useContext(AppContext);

    useEffect(() => {
      void (async () => {
        const [agentBarriers, orgBarriers] = await Promise.all([
          identity.agentId
            ? vehicleInspectionTemplateBarrierService.list(
                {
                  targetLevel: [InspectionTemplateBarrierLevel.Agent],
                  targetId: identity.agentId,
                  templateId,
                },
                null,
                undefined,
                undefined,
                { recursive: false },
              )
            : Promise.resolve([]),
          vehicleInspectionTemplateBarrierService.list(
            {
              targetLevel: [InspectionTemplateBarrierLevel.Org],
              targetId: identity.orgId,
              templateId,
            },
            null,
            undefined,
            undefined,
            { recursive: false },
          ),
        ]);
        setIsVisibleToAgent(
          !agentBarriers.some(x => x.templateId === templateId),
        );
        setIsVisibleToOrg(!orgBarriers.some(x => x.templateId === templateId));
      })();
    }, [identity, templateId]);

    const onClick = usePersistFn(async () => {
      if (isVisibleToOrg == null || !identity.userInfo.isOrgRootUser) return;

      const orgId = identity.orgId;
      try {
        if (!isVisibleToOrg) {
          await vehicleInspectionTemplateService.removeTargetInspectionTemplateBarrier(
            InspectionTemplateBarrierLevel.Org,
            orgId,
            templateId,
          );
          setIsVisibleToOrg(true);
        } else {
          await vehicleInspectionTemplateService.addTargetInspectionTemplateBarrier(
            InspectionTemplateBarrierLevel.Org,
            orgId,
            templateId,
          );
          setIsVisibleToOrg(false);
        }
      } catch (e) {
        alert(e.message);
      }
    });

    return (
      <div
        style={{
          padding: '1rem 2rem',
          ...style,
        }}
      >
        <div
          style={{
            marginBottom: '2rem',
          }}
        >
          <S id="description" />
        </div>

        {template.agentId == null &&
          template.orgId == null &&
          template.storeId == null && (
            <div
              style={{
                marginBottom: '2rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>
                <S id="visible_to_agent_label" />
              </span>
              <span
                className={classNames({
                  'kt-font-success': isVisibleToAgent,
                  'kt-font-warning': !isVisibleToAgent,
                })}
                style={{
                  marginLeft: '0.35rem',
                }}
              >
                {isVisibleToAgent == null ? (
                  '-'
                ) : isVisibleToAgent ? (
                  <S id="visible" />
                ) : (
                  <S id="invisible" />
                )}
              </span>
            </div>
          )}

        {template.orgId == null && (
          <div
            style={{
              marginBottom: '2rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              <S id="visible_to_org_label" />
            </span>
            <span
              style={{
                marginLeft: '0.35rem',
              }}
            >
              {isVisibleToOrg == null ? (
                '-'
              ) : identity.userInfo.isOrgRootUser ? (
                <input
                  type="checkbox"
                  checked={isVisibleToOrg}
                  onChange={() => null}
                  onClick={onClick}
                />
              ) : isVisibleToOrg ? (
                <S id="visible" />
              ) : (
                <S id="invisible" />
              )}
            </span>
          </div>
        )}

        {template.storeId == null && isVisibleToOrg && (
          <>
            <div
              style={{
                marginBottom: '0.5rem',
                fontWeight: 'bold',
              }}
            >
              <S id="visible_to_stores_label" />
            </div>
            <ul
              style={{
                marginLeft: 0,
                paddingLeft: 0,
              }}
            >
              {globalVisibleStores.map(store => (
                <Item key={store.id} store={store} templateId={templateId} />
              ))}
            </ul>
          </>
        )}
      </div>
    );
  },
);
