/**
 * @file: VehicleInspectionTemplateBarrierService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionTemplateBarrierServiceBase } from './base/VehicleInspectionTemplateBarrierService';

export class VehicleInspectionTemplateBarrierService extends VehicleInspectionTemplateBarrierServiceBase {
  // add custom service implementation here
}
