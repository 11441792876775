/**
 * @file: VehicleInspectionTemplateService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTemplate,
  VehicleInspectionTemplateListFilter,
} from 'model';

export class VehicleInspectionTemplateServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTemplateListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTemplate>
      : VehicleInspectionTemplate[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-templates',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionTemplate | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-templates/:id', { id })
      .future();
  }

  async getTemplateByCid(
    cid: string,
  ): Promise<VehicleInspectionTemplate | null> {
    if (!cid) {
      throw new Error('missing cid parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-inspection-templates/query/cid', { cid })
      .future();
  }

  async create(
    vehicleInspectionTemplate: Partial<VehicleInspectionTemplate>,
  ): Promise<VehicleInspectionTemplate> {
    return await this.api
      .post()
      .url('/vehicle-inspection-templates')
      .data(vehicleInspectionTemplate)
      .future();
  }

  async update(
    vehicleInspectionTemplate: Partial<VehicleInspectionTemplate>,
  ): Promise<VehicleInspectionTemplate> {
    return await this.api
      .put()
      .url('/vehicle-inspection-templates/:id', {
        id: vehicleInspectionTemplate.id,
      })
      .data(vehicleInspectionTemplate)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-templates/:id', { id })
      .future();
  }
}
