/**
 * @file: OrgUserVisibleStoreAclService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { OrgUserVisibleStoreAclListFilter, OrgUserVisibleStoreAcl, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class OrgUserVisibleStoreAclServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<OrgUserVisibleStoreAclListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<OrgUserVisibleStoreAcl> : OrgUserVisibleStoreAcl[]> {
    return await this.api.get()
      .url('/org-user-visible-store-acls', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(userId: number): Promise<OrgUserVisibleStoreAcl | null> {
    return await this.api.get()
      .url('/org-user-visible-store-acls/:userId', { userId })
      .future();
  }

  async create(orgUserVisibleStoreAcl: Partial<OrgUserVisibleStoreAcl>): Promise<OrgUserVisibleStoreAcl> {
    return await this.api.post()
      .url('/org-user-visible-store-acls')
      .data(orgUserVisibleStoreAcl)
      .future();
  }

  async update(orgUserVisibleStoreAcl: Partial<OrgUserVisibleStoreAcl>): Promise<OrgUserVisibleStoreAcl> {
    return await this.api.put()
      .url('/org-user-visible-store-acls/:userId', { userId: orgUserVisibleStoreAcl.userId })
      .data(orgUserVisibleStoreAcl)
      .future();
  }

  async delete(userId: number): Promise<void> {
    return void await this.api.delete()
      .url('/org-user-visible-store-acls/:userId', { userId })
      .future();
  }
}