/**
 * @file: OfficialAccountTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, OfficialAccountType } from 'model';

export const OfficialAccountTypeOptions: Array<Option<OfficialAccountType>> = [
  { value: OfficialAccountType.SubscriptionAccount, label: 'official_account_type.subscription_account' },
  { value: OfficialAccountType.MigratedSubscriptionAccount, label: 'official_account_type.migrated_subscription_account' },
  { value: OfficialAccountType.ServiceAccount, label: 'official_account_type.service_account' }
];

export const OfficialAccountTypeOptionsWithDefault: Array<Option<OfficialAccountType | null>> = [
  { value: null, label: 'official_account_type.__default__' },
  { value: OfficialAccountType.SubscriptionAccount, label: 'official_account_type.subscription_account' },
  { value: OfficialAccountType.MigratedSubscriptionAccount, label: 'official_account_type.migrated_subscription_account' },
  { value: OfficialAccountType.ServiceAccount, label: 'official_account_type.service_account' }
];