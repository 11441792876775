/**
 * @file: toSimpleNick.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

const prefixes = [
  'cntaobao',
  'chnaigou',
  'cnkoubei',
  'cnaliyun',
  'chnyahoo',
  'wangwang',
  'htyahooo',
  'cnalimam',
  'enaliint',
  'cnalichn',
  'cnwujing',
  'tbtrader',
];

const pattern = new RegExp(`^(${prefixes.join('|')})`, 'i');

export function toSimpleNick(
  nick: string,
  removeSubaccountPrefix = true,
): string {
  nick = nick.replace(pattern, '');
  if (removeSubaccountPrefix) {
    const idx = nick.indexOf(':');
    if (idx > 0) {
      nick = nick.substr(idx + 1);
    }
  }
  return nick;
}
