/**
 * @file: DeliveryCheckTemplateMatchType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum DeliveryCheckTemplateMatchType {
  None = 'none',
  Yes = 'yes',
  No = 'no',
}

export const DeliveryCheckTemplateMatchTypeValueSet = new Set([
  DeliveryCheckTemplateMatchType.None,
  DeliveryCheckTemplateMatchType.Yes,
  DeliveryCheckTemplateMatchType.No,
]);
