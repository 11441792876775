/**
 * @file: useSelectedGroup.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */

import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import { findQuotationGroupByRef } from 'app/inspection/quotation-template-config/util';
import { useMemo } from 'react';

export const useSelectedGroup = () => {
  const { state } = useQuotationTemplateConfigContext();
  return useMemo(() => {
    if (state.selectedGroupRef?.type === 'group') {
      return findQuotationGroupByRef(state.staged, state.selectedGroupRef.ref);
    }
    return undefined;
  }, [state.selectedGroupRef, state.staged]);
};
