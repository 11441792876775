import { APIService } from 'lib/restful-client/api.service';
import { CacheService } from 'lib/services/cache.service';
import { Area, AreaNode, AreaTree, FileUploadResult } from 'model';
import { UploadProgressListener } from 'lib/restful-client/RequestBuilder';

export class CommonService {
  constructor(private readonly api: APIService, private readonly cache: CacheService) {
  }

  async listAreas(): Promise<AreaTree> {
    let areas = await this.cache.get<Area[]>('common_data/areas');
    if (!areas) {
      areas = await this.api.get()
        .url('/areas', { offset: 0, limit: 0 })
        .future<Area[]>();
      await this.cache.set(
        'common_data/areas',
        areas,
        365 * 24 * 3600 * 1000 * 1000
        );
    }

    // build area tree
    const map: Map<number, AreaNode> = new Map<number, AreaNode>();

    const allocNode = (area: Area, parent: AreaNode | null): AreaNode => {
      const node = { area, parent, children: [] };
      map.set(area.id, node);
      return node;
    };

    const rootNodes: AreaNode[] = [];
    let country: AreaNode | null = null;
    let province: AreaNode | null = null;
    let city: AreaNode | null = null;
    let distrit: AreaNode | null = null;
    for (const area of areas) {
      if (!area.parentId) {
        country = allocNode(area, null);
        rootNodes.push(country);
      } else {
        if (area.levelType === 1) {
          province = allocNode(area, null);
          country!.children.push(province);
        } else if (area.levelType === 2) {
          city = allocNode(area, province);
          province!.children.push(city);
        } else if (area.levelType === 3) {
          distrit = allocNode(area, city);
          city!.children.push(distrit);
        }
      }
    }

    return new AreaTree(rootNodes[0].children, map);
  }

  async uploadFile(
    data: FormData,
    progressListener?: UploadProgressListener
  ): Promise<FileUploadResult> {
    return await this.api.post()
      .url('/upload')
      .data(data)
      .listenUploadProgress(progressListener)
      .future<FileUploadResult>();
  }

  async getPyInitial(word: string) {
    return await this.api.get()
      .url('/py-initial', { word })
      .future();
  }
}