import React from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { inspectionSiteCategoryActions } from '../duck/actions';
import { VehicleInspectionSiteCategory, VehicleInspectionSiteCategoryListFilter, AclObjectList } from 'model';
import { EntityListProps, EntityListComponentClassBuilder, getString } from 'shared/components';
import { Badge, EntityNode } from 'lib/metronic/components';
import { buildTreeModel } from 'lib/helpers';

interface Props extends EntityListProps<VehicleInspectionSiteCategory, VehicleInspectionSiteCategoryListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  VehicleInspectionSiteCategory,
  VehicleInspectionSiteCategoryListFilter,
  number,
  Props
  >();

export const InspectionSiteCategoryList = componentClassBuilder
  .i18nPrefix('inspection_site_category')
  .pageIcon(require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/folder.svg').default)
  .accessRights({
    full: AclObjectList.VehicleInspectionSiteCategoryFullAccess,
    readonly: AclObjectList.VehicleInspectionSiteCategoryReadonlyAccess
  })
  .breadcrumbs([
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.categories" /> }
  ])
  .entities(state => state.inspection.categories)
  .actions(inspectionSiteCategoryActions)
  .treeBuilder((items:VehicleInspectionSiteCategory[], props: Props) => {
    return buildTreeModel(items, {
      itemKey: x => x.id,
      parentItemKey: x => x.parentCategoryId,
      createNode(category) {
        const expanded = Boolean(
          !props.entities.collapsedItemIds ||
          !props.entities.collapsedItemIds.has(category.id)
        );
        return {
          entity: category,
          selected: false,
          expanded
        } as EntityNode<VehicleInspectionSiteCategory>;
      },
      appendChildNode(node, childNode) {
        if (!node.children) node.children = [];
        node.children.push(childNode);
      }
    });
  })
  .editor(builder =>
    builder.text({
      prop: 'name',
      label: 'inspection_site_category.editor.label.name',
      placeholder: 'inspection_site_category.editor.placeholder.name'
    }).reactSelect({
      prop: 'parentCategoryId',
      labelProp: 'name',
      valueProp: 'id',
      label: 'inspection_site_category.editor.label.parent_category',
      placeholder: 'inspection_site_category.editor.placeholder.parent_category',
      isOptionDisabled: (option: VehicleInspectionSiteCategory, props: Props) => {
        if (props.entities.itemBeingUpdated) {
          return option.id === props.entities.itemBeingUpdated.id;
        }
        return false;
      },
      values: (({ extra }) => {
        const props = extra as Props;
        return props.entities.result;
      })
    }).text({
      type: 'number',
      prop: 'sortOrder',
      label: 'inspection_site_category.editor.label.sort_order',
      placeholder: 'inspection_site_category.editor.placeholder.sort_order'
    }).textArea({
      prop: 'description',
      label: 'inspection_site_category.editor.label.description',
      placeholder: 'inspection_site_category.editor.placeholder.description'
    })
  )
  .toolbarItems(builder => {
    builder.text({
      prop: 'name',
      label: 'inspection_site_category.toolbar.label.name',
      placeholder: 'inspection_site_category.toolbar.placeholder.name',
      width: 250
    }).button({
      buttonType: 'search',
      onClick: (props: Props) => {
        const { dispatch } = props;
        dispatch(inspectionSiteCategoryActions.invalidate(true));
      }
    }).button({
      buttonType: 'add',
      onClick: (props: Props) => {
        const { dispatch } = props;
        const category: Partial<VehicleInspectionSiteCategory> = {
          orgId: props.user.orgId,
          storeId: props.activeStoreId || props.user.storeId || null
        };
        dispatch(inspectionSiteCategoryActions.itemBeingCreated(category));
      }
    })
  })
  .columns([{
    prop: 'name',
    width: 200,
    text: 'col.name',
    render: (category) => (
      <Badge
        label
        color={category.orgId ? 'info' : 'danger'}
        pill
        size="small"
        >
        {category.name}
      </Badge>
    )
  }, {
    prop: 'orgId',
    width: 80,
    align: 'center',
    text: 'col.resource_type',
    render: (category) => category.orgId ?
      <Badge color="info" size="small" pill inline>{getString('@string/custom_label')}</Badge> :
      <Badge color="danger" size="small" pill inline>{getString('@string/system_label')}</Badge>
  }, {
    prop: 'sortOrder',
    width: 80,
    align: 'right',
    text: 'col.sort_order'
  }, {
    prop: 'siteCount',
    width: 100,
    align: 'right',
    text: 'inspection_site_category.col.site_count'
  }, {
    prop: 'itemCount',
    width: 100,
    align: 'right',
    text: 'inspection_site_category.col.item_count'
  }, {
    prop: 'createdAt',
    text: 'col.created_at',
    width: 150,
    align: 'center',
    render: ({ createdAt }) => formatTime(createdAt)
  }])
  .addActionButtons(['edit', 'remove'])
  .validate(entity => {
    const name = entity.name?.trim();

    if (!name) {
      throw new Error(
        getString('inspection_site_category.editor.error.name_required')
        );
    }
  })
  .canEdit(x => Boolean(x.orgId))
  .canDelete(x => Boolean(x.orgId))
  .componentDidUpdate((props: Props, prevProps: Props) => {
    if (props.activeStoreId !== prevProps.activeStoreId) {
      props.dispatch(inspectionSiteCategoryActions.fetch());
    }
  })
  .getClass();