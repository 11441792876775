import { AppContext } from 'app/AppContext';
import {
  fetchAclList,
  hideAppLoading,
  showAppLoading,
  showAppModal,
} from 'app/duck/actions';
import { AclList, AppState } from 'app/duck/states';
import { UserAuthQrcodeAside } from 'app/org/users/UserAuthQrcodeAside';
import { Block, TabNav } from 'lib/metronic/components';
import { AsideRight } from 'lib/metronic/layout';
import {
  AclObjectList,
  GenderOptions,
  Option,
  OrgMember,
  OrgUser,
  OrgUserAccessRights,
  OrgUserListFilter,
  OrgUserRoleType,
  OrgUserRoleTypeOptions,
} from 'model';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { organizationService, orgMemberService } from 'services';
import {
  Checkmark,
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
  OrgInfoView,
  OrgUserRoleTypeLabel,
  StoreName,
  StorePicker,
} from 'shared/components';
import { formatDate, formatTime, loadAsyncState } from 'utils';
import { isEmail, isMobile } from 'utils/validators';
import {
  memberActions,
  setCurrentOrgUserAcl,
  setCurrentOrgUserVisibleStores,
  setCurrentUserQrcodeAuth,
  userActions,
} from '../duck/actions';
import { fetchOrgUserAcl, updateOrgUserAcl } from '../duck/actions/users-acl';
import { OrgUsersAcl } from '../duck/states';
import { AclEditor } from './acl-settings';
import { UserVisibleStoreSettings } from './visible-stores';

import './index.scss';

interface Props extends EntityListProps<OrgUser, OrgUserListFilter> {
  acl: AclList;
  usersAcl: OrgUsersAcl;
  currentUserAcl: OrgUserAccessRights;
  currentUserIdForVisibleStoreSettings?: number | null;
  currentQrcodeAuthUserId?: number | undefined;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  OrgUser,
  OrgUserListFilter,
  number,
  Props
>();

function shouldElementBeHidden(_: any, props: Props): boolean {
  const { itemBeingCreated, itemBeingUpdated } = props.entities;
  const entity = (itemBeingCreated || itemBeingUpdated)!;
  return Boolean(!entity.role || entity.role === OrgUserRoleType.Administrator);
}

export const OrgUserList = componentClassBuilder
  .i18nPrefix('org_user')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/communication/group.svg')
      .default,
  )
  .accessRights({
    full: AclObjectList.OrgUserFullAccess,
    readonly: AclObjectList.OrgUserReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="org.breadcrumb.it" /> },
    { text: <Translate id="org.breadcrumb.users" /> },
  ])
  .features({ addEntity: false })
  .entities(state => state.org.users)
  .actions(userActions)
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'userName',
        placeholder: 'org_user.toolbar.placeholder.user_name',
        width: 250,
      })
      .button({
        buttonType: 'search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(userActions.invalidate(true));
        },
      })
      .button({
        buttonType: 'add',
        onClick: (props: Props) => {
          const { dispatch, identity } = props;
          const user: Partial<OrgUser> = {
            orgId: props.user.orgId,
            storeId:
              identity.storeId && identity.visibleStores.length === 1
                ? identity.storeId
                : undefined,
            requireChangePassword: false,
            isOrgRootUser: false,
          };
          dispatch(userActions.itemBeingCreated(user));
        },
      });
  })
  .editor(builder => {
    builder
      .custom({
        label: 'org_user.editor.label.store',
        helpText: 'org_user.editor.help_text.store',
        key: 'store',
        render: (props: Props) => {
          const { dispatch } = props;
          // tslint:disable-next-line: no-shadowed-variable
          const onChange = (storeId: number | undefined) => {
            if (props.entities.itemBeingCreated) {
              dispatch(
                userActions.itemBeingCreatedChanged({
                  storeId: storeId || null,
                }),
              );
            } else {
              dispatch(
                userActions.itemBeingUpdatedChanged({
                  storeId: storeId || null,
                }),
              );
            }
          };
          const storeId = props.entities.itemBeingCreated
            ? props.entities.itemBeingCreated.storeId
            : props.entities.itemBeingUpdated
              ? props.entities.itemBeingUpdated.storeId
              : undefined;
          const isOrgRootUser = props.entities.itemBeingUpdated
            ? props.entities.itemBeingUpdated.isOrgRootUser
            : false;
          return (
            <AppContext.Consumer>
              {({ identity }) => (
                <StorePicker
                  storeId={storeId}
                  disabled={!identity.userInfo.isOrgRootUser || isOrgRootUser}
                  onChange={onChange}
                />
              )}
            </AppContext.Consumer>
          );
        },
      })
      .text({
        prop: 'userName',
        label: 'org_user.editor.label.user_name',
        placeholder: 'org_user.editor.placeholder.user_name',
        disabled: (_: any, props: Props) =>
          Boolean(props.entities.itemBeingUpdated),
      })
      .text({
        type: 'password',
        prop: 'password',
        label: 'org_user.editor.label.password',
        placeholder: 'org_user.editor.placeholder.password',
      })
      .checkbox({
        label: 'org_user.editor.label.change_password_on_first_login',
        prop: 'requireChangePassword',
        hidden: (_: any, props: Props) =>
          Boolean(props.entities.itemBeingUpdated?.lastLoginAt),
      })
      .reactSelect({
        prop: 'role',
        label: 'org_user.editor.label.role',
        placeholder: 'org_user.editor.placeholder.role',
        values: OrgUserRoleTypeOptions,
        valueProp: 'value',
        labelProp: 'label',
        multi: true,
        disabled: (item: OrgUser) => item.isOrgRootUser,
        onFormatOptionLabel(option: Option<OrgUserRoleType>) {
          return getString(option.label);
        },
        onValue(value, type) {
          if (type === 'get') {
            return ((value as string) || '')
              .split(',')
              .map(x => x.trim())
              .filter(x => x);
          } else {
            return (value as string[]).join(',');
          }
        },
      })
      .text({
        prop: 'mobile',
        label: 'org_user.editor.label.mobile',
        placeholder: 'org_user.editor.placeholder.mobile',
      })
      .checkbox({
        prop: 'isServiceNotificationEnabled',
        label: 'org_user.editor.label.is_service_notification_enabled',
        helpText: 'org_user.editor.help_text.is_service_notification_enabled',
      })
      // .text({
      //   prop: 'email',
      //   label: 'org_user.editor.label.email',
      //   placeholder: 'org_user.editor.placeholder.email',
      // })
      .radioList({
        prop: 'gender',
        options: GenderOptions,
        inline: true,
        label: 'org_user.editor.label.gender',
        convertValue: (value: string) => Number(value),
      })
      .custom({
        label: '',
        render: (props: Props) => {
          if (shouldElementBeHidden(null, props)) return null;
          return (
            <div className="kt-section__content kt-section__content--solid kt-font-bold">
              <Translate id="org_user.editor.member_info_section_title" />
            </div>
          );
        },
      })
      .text({
        prop: 'name',
        label: 'org_member.editor.label.name',
        placeholder: 'org_member.editor.placeholder.name',
        hidden: shouldElementBeHidden,
      })
      .text({
        prop: 'jobNo',
        label: 'org_member.editor.label.job_no',
        placeholder: 'org_member.editor.placeholder.job_no',
        helpText: 'org_member.editor.help_text.job_no',
        hidden: shouldElementBeHidden,
      })
      .text({
        prop: 'jobTitle',
        label: 'org_member.editor.label.job_title',
        placeholder: 'org_member.editor.placeholder.job_title',
        hidden: shouldElementBeHidden,
      })
      .reactSelect({
        prop: 'managerId',
        label: 'org_member.editor.label.manager',
        placeholder: 'org_member.editor.placeholder.manager',
        async: true,
        defaultValues: true,
        valueProp: 'id',
        labelProp: 'name',
        values: [],
        stateId: 'managers',
        hidden: shouldElementBeHidden,
        helpText: 'org_member.editor.help_text.manager',
        onLoadValues: async (
          keyword: string,
          entity: OrgUser | Partial<OrgUser>,
        ) => {
          return (await orgMemberService.list({
            orgId: entity.orgId!,
            storeId: entity.storeId || undefined,
            keyword: keyword || undefined,
          })) as OrgMember[];
        },
      })
      .textArea({
        prop: 'remark',
        label: 'org_member.editor.label.remark',
        placeholder: 'org_member.editor.placeholder.remark',
        hidden: shouldElementBeHidden,
      });
  })
  .columns([
    {
      prop: 'storeId',
      width: 150,
      text: 'col.store_name',
      render: ({ storeId }) => <StoreName storeId={storeId} />,
    },
    {
      prop: 'userName',
      width: 150,
      text: 'col.user_name',
      render: ({ userName }) => (
        <OrgInfoView>{org => `${org.cid}/${userName}`}</OrgInfoView>
      ),
    },
    {
      prop: 'nick',
      width: 100,
      text: 'col.nick',
      render: ({ nick }) => nick || '-',
    },
    {
      prop: 'name',
      width: 100,
      text: 'col.name',
      render: ({ name }) => name || '-',
    },
    {
      prop: 'mobile',
      width: 100,
      text: 'col.mobile',
      render: ({ mobile }) => mobile || '-',
    },
    {
      prop: 'jobNo',
      width: 80,
      hidden: true,
      text: 'org_member.col.job_no',
      render: ({ jobNo }) => jobNo || '-',
    },
    {
      prop: 'jobTitle',
      width: 80,
      text: 'org_member.col.job_title',
      render: ({ jobTitle }) => jobTitle || '-',
    },
    {
      prop: 'role',
      width: 300,
      text: 'org_user.col.role',
      render: ({ role }) => {
        const roles = role
          .split(',')
          .map(x => x.trim())
          .filter(x => x) as OrgUserRoleType[];
        return (
          <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {roles.map(x => (
              <OrgUserRoleTypeLabel
                value={x}
                key={x}
                style={{ marginRight: '4px', marginTop: '2px' }}
              />
            ))}
          </span>
        );
      },
    },
    {
      prop: 'isServiceNotificationEnabled',
      width: 80,
      text: 'org_user.col.is_service_notification_enabled',
      align: 'center',
      render: ({ isServiceNotificationEnabled }) => (
        <Checkmark value={isServiceNotificationEnabled} showFalseIcon />
      ),
    },
    {
      prop: 'isWxBound',
      width: 80,
      text: 'org_user.col.is_wx_bound',
      align: 'center',
      render: ({ isWxBound }) => <Checkmark value={isWxBound} showFalseIcon />,
    },
    {
      prop: 'wxBoundAt',
      text: 'col.wx_bound_at',
      width: 150,
      hidden: true,
      align: 'center',
      render: ({ wxBoundAt }) => (wxBoundAt ? formatDate(wxBoundAt) : '-'),
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      hidden: true,
      align: 'center',
      render: ({ createdAt }) => formatDate(createdAt),
    },
    {
      prop: 'lastLoginAt',
      text: 'org_user.col.last_login_at',
      width: 150,
      align: 'center',
      render: ({ lastLoginAt }) =>
        lastLoginAt ? formatTime(lastLoginAt) : '-',
    },
  ])
  .addActionButtons([
    'edit',
    {
      key: 'acl-setting',
      icon: 'flaticon2-gear',
      tooltip: 'org_user.action_button.acl_setting',
      rights: [AclObjectList.OrgUserAclFullAccess],
      onClick: (item: OrgUser, props: Props) => {
        const { dispatch } = props;
        const { usersAcl } = props;
        const userId = item.id;
        if (!usersAcl.result![userId]) {
          dispatch(fetchOrgUserAcl(userId));
          dispatch(setCurrentOrgUserAcl(userId, []));
        } else {
          dispatch(setCurrentOrgUserAcl(userId, usersAcl.result![userId].acl));
        }
      },
    },
    {
      key: 'visible-store-settings',
      icon: 'fa fa-list-ul',
      tooltip: 'org_user.action_button.visible_store_settings',
      rights: [AclObjectList.OrgUserVisibleStoreAclFullAccess],
      onClick: (user: OrgUser, props: Props) => {
        const { dispatch, identity } = props;
        if (!identity.userInfo.isOrgRootUser) {
          alert(getString('org_user.msg.visible_store_settings_denied'));
          return;
        }
        dispatch(setCurrentOrgUserVisibleStores(user.id));
      },
    },
    {
      key: 'send-invite-msg',
      icon: 'fa fa-rss',
      tooltip: 'org_user.action_button.send_invite_msg',
      rights: [AclObjectList.OrgUserFullAccess],
      disabled: x => Boolean(x.isWxBound || !x.storeId),
      onClick: async (user: OrgUser, props: Props) => {
        const { dispatch } = props;
        try {
          dispatch(
            showAppLoading({ message: 'loading... ', status: 'loading' }),
          );
          const inviteLink = await organizationService.getInviteLink(
            user.storeId!,
          );
          dispatch(hideAppLoading());
          dispatch(
            showAppModal(
              getString('org_user.action_button.send_invite_msg'),
              <ReactMarkdown>
                {getString('org_user.msg.sending_invite_msg', {
                  link: inviteLink,
                })}
              </ReactMarkdown>,
            ),
          );
        } catch (e) {
          dispatch(
            showAppLoading({
              message: getString('org_user.msg.sending_invite_failed'),
              status: 'error',
              timeout: 3000,
            }),
          );
        }
      },
    },
    {
      key: 'stats-remind',
      icon: 'la la-pie-chart',
      tooltip: 'store.send_stats_remind.tooltip',
      disabled: x => Boolean(!x.isWxBound || !x.storeId),
      onClick: (item, props: Props) => {
        props.dispatch(
          showAppLoading({
            message: getString('store.send_stats_remind.loading'),
          }),
        );
        organizationService
          .sendStoreStatsRemindNotificationToUser(item.id)
          .then(() => {
            props.dispatch(
              showAppLoading({
                message: getString('store.send_stats_remind.success'),
                status: 'success',
                timeout: 5000,
              }),
            );
          })
          .catch(err => {
            console.error(err);
            props.dispatch(
              showAppLoading({
                message:
                  getString('store.send_stats_remind.failed') +
                  ` (${err.message})`,
                status: 'error',
                timeout: 5000,
              }),
            );
          });
      },
    },
    {
      key: 'auth-qrcode',
      icon: 'la la-qrcode',
      tooltip: 'org_user.qrcode_auth.tooltip',
      disabled: x => Boolean(!x.storeId),
      onClick: (item, props: Props) => {
        props.dispatch(setCurrentUserQrcodeAuth(item.id));
      },
    },
    'remove',
  ])
  .shouldDisableActionButton((btn, user, props: Props) => {
    if (typeof btn !== 'string' && btn.key === 'visible-store-settings') {
      return user.isOrgRootUser || !props.identity.userInfo.isOrgRootUser;
    }
    return false;
  })
  .mapStateToProps(state => ({
    acl: state.acl,
    usersAcl: state.org.usersAcl,
    currentUserAcl: state.org.users.currentAcl,
    currentUserIdForVisibleStoreSettings:
      state.org.users.currentUserIdForVisibleStoreSettings,
    currentQrcodeAuthUserId: state.org.users.currentQrcodeAuthUserId,
  }))
  .componentDidMount((state: AppState, props: Props) => {
    const { dispatch } = props;
    loadAsyncState(state.acl, () => dispatch(fetchAclList()));
  })
  .componentDidUpdate((props: Props, prevProps: Props) => {
    if (props.currentUserAcl) {
      const { userId } = props.currentUserAcl;
      if (
        !prevProps.usersAcl.result![userId] &&
        props.usersAcl.result![userId]
      ) {
        props.dispatch(
          setCurrentOrgUserAcl(userId, props.usersAcl.result![userId].acl),
        );
      }
    }
  })
  .onRender((props: Props) => {
    const { acl, currentUserAcl, currentUserIdForVisibleStoreSettings } = props;

    const onAclAsideRightClose = () => {
      const { dispatch } = props;
      dispatch(setCurrentOrgUserAcl(null, []));
    };

    const onVisibleStoreSettingsAsideRightClose = () => {
      const { dispatch } = props;
      dispatch(setCurrentOrgUserVisibleStores(null));
    };

    const onAclChange = (values: string[]) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { currentUserAcl, dispatch } = props;
      if (!currentUserAcl) return;
      const { userId } = currentUserAcl;
      dispatch(setCurrentOrgUserAcl(userId, values));
    };

    const onSaveAcl = async () => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { currentUserAcl, dispatch } = props;
      if (!currentUserAcl) return;
      dispatch(
        updateOrgUserAcl(
          currentUserAcl.userId,
          currentUserAcl,
          (err: Error) => {
            if (!err) {
              dispatch(setCurrentOrgUserAcl(null, []));
            }
          },
        ),
      );
    };

    const userAclSettingsError = props.usersAcl.lastUpdateError;
    const currentUserForAclSettings = currentUserAcl
      ? props.entities.result?.find(x => x.id === currentUserAcl.userId)
      : null;

    const currentUserForVisibleStoreSettings =
      currentUserIdForVisibleStoreSettings
        ? props.entities.result!.find(
            x => x.id === currentUserIdForVisibleStoreSettings,
          )!
        : null;

    return (
      <Block>
        <AsideRight
          open={Boolean(currentUserAcl)}
          onClose={onAclAsideRightClose}
          // width={350}
        >
          <AsideRight.Nav>
            <TabNav line="brand" bolder>
              <TabNav.Item active>
                <Translate id="org_user.acl.title" />
              </TabNav.Item>
            </TabNav>
          </AsideRight.Nav>
          <AsideRight.Content>
            <AclEditor
              isSuperUser={Boolean(
                currentUserForAclSettings &&
                  currentUserForAclSettings.isOrgRootUser,
              )}
              error={userAclSettingsError}
              acl={acl.result || []}
              selected={currentUserAcl?.acl || []}
              onChange={onAclChange}
              onSave={onSaveAcl}
            />
          </AsideRight.Content>
        </AsideRight>
        <AsideRight
          open={Boolean(currentUserForVisibleStoreSettings)}
          onClose={onVisibleStoreSettingsAsideRightClose}
          // width={350}
        >
          <AsideRight.Nav>
            <TabNav line="brand" bolder>
              <TabNav.Item active>
                <Translate id="org_user.visible_stores.title" />
              </TabNav.Item>
            </TabNav>
          </AsideRight.Nav>
          <AsideRight.Content>
            {currentUserForVisibleStoreSettings && (
              <AppContext.Consumer>
                {({ globalVisibleStores }) => (
                  <UserVisibleStoreSettings
                    user={currentUserForVisibleStoreSettings}
                    accessRights={acl.result || []}
                    stores={globalVisibleStores}
                  />
                )}
              </AppContext.Consumer>
            )}
          </AsideRight.Content>
        </AsideRight>
        <UserAuthQrcodeAside
          userId={props.currentQrcodeAuthUserId}
          onClose={() => {
            props.dispatch(setCurrentUserQrcodeAuth(undefined));
          }}
        />
      </Block>
    );
  })
  .canEdit(
    (item: OrgUser, props: Props) =>
      !item.isOrgRootUser || props.user.isOrgRootUser,
  )
  .canDelete(item => !item.isOrgRootUser)
  .validate((entity: OrgUser, isCreating: boolean, _props: Props) => {
    const userName = entity.userName?.trim();
    const password = entity.password?.trim();
    const name = entity.name?.trim();
    const role = entity.role;
    const email = entity.email?.trim();
    const mobile = entity.mobile?.trim();
    const jobNo = entity.jobNo?.trim();

    const roleSet = new Set<OrgUserRoleType>(
      role
        .split(',')
        .map(x => x.trim())
        .filter(x => x) as any,
    );
    const isMember =
      roleSet.has(OrgUserRoleType.ServiceAgents) ||
      roleSet.has(OrgUserRoleType.Technicians) ||
      roleSet.has(OrgUserRoleType.Manager);

    let msg = '';

    if (isCreating) {
      if (!entity.storeId) {
        msg = 'store_required';
      } else if (!userName) {
        msg = 'user_name_required';
      } else if (!/^[a-z][a-z0-9_]{0,19}$/.test(userName)) {
        msg = 'invalid_user_name';
      } else if (!password) {
        msg = 'password_required';
      } else if (password!.length < 5 || password!.length > 30) {
        msg = 'password_length_error';
      } else if (!roleSet.size) {
        msg = 'role_required';
      }
      if (isMember && !mobile) {
        msg = 'mobile_required';
      } else if (isMember && jobNo && !/^\d+$/.test(jobNo)) {
        msg = 'invalid_job_no_format';
      } else if (isMember && !name) {
        msg = 'name_required';
      } else if (email && !isEmail(email)) {
        msg = 'invalid_email';
      } else if (mobile && !isMobile(mobile)) {
        msg = 'invalid_mobile';
      }
    } else {
      if (!entity.isOrgRootUser && !entity.storeId) {
        msg = 'store_required';
      } else if (password && (password.length < 5 || password.length > 30)) {
        msg = 'password_length_error';
      } else if (isMember && !mobile) {
        msg = 'mobile_required';
      } else if (isMember && !name) {
        msg = 'name_required';
      } else if (isMember && jobNo && !/^\d+$/.test(jobNo)) {
        msg = 'invalid_job_no_format';
      } else if (email && !isEmail(email)) {
        msg = 'invalid_email';
      } else if (mobile && !isMobile(mobile)) {
        msg = 'invalid_mobile';
      }
    }

    if (msg) {
      throw new Error(getString(`org_user.editor.error.${msg}`));
    }
  })
  .onAdd((entity, props: Props) => {
    entity.orgId = props.user.orgId;
  })
  .onDeleted((error, props: Props) => {
    if (!error) {
      props.dispatch(memberActions.invalidate(true));
    }
  })
  .componentDidUpdate((props, prevProps) => {
    if (props.activeStoreId !== prevProps.activeStoreId) {
      props.dispatch(userActions.invalidate(true));
      props.dispatch(memberActions.invalidate(true));
    }
  })
  .getClass();
