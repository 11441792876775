/**
 * @file index.ts
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

export interface AppConfig {
  environment: 'dev' | 'production' | 'staging';
  apiEndPoint: string;
  apiTimeout?: number;
  wsEndPoint: string;
  h5EndPoint: string;
}

import _config from './config.json';
const config = _config as AppConfig;

export { config };
