import { OrgGroup } from 'model';
import { PureComponent } from 'react';
import { GroupPicker } from 'shared/components/GroupPicker';
import { EntityWithStoreBounded, FormElementGroupPicker } from '../types';

interface Props<T extends EntityWithStoreBounded> {
  element: FormElementGroupPicker<T>;
  disabled?: boolean;
  orgId: number;
  storeId?: number | null;
  groupId?: number | null;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormGroupPicker<
  T extends EntityWithStoreBounded,
> extends PureComponent<Props<T>> {
  render() {
    const { orgId, storeId, groupId, disabled } = this.props;
    return (
      <GroupPicker
        orgId={orgId}
        storeId={storeId}
        groupId={groupId}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }

  onChange = (groupId: number | undefined, group: OrgGroup | null) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = groupId as any;
    void element.onChange?.(changes as any, extra, group);
    this.props.onChange(changes as T);
  };
}
