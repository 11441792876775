/**
 * @file: OptionValueTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, OptionValueType } from 'model';

export const OptionValueTypeOptions: Array<Option<OptionValueType>> = [
  { value: OptionValueType.Celsius, label: 'option_value_type.celsius' },
  { value: OptionValueType.Fahrenheit, label: 'option_value_type.fahrenheit' },
  { value: OptionValueType.Kilometer, label: 'option_value_type.km' },
  { value: OptionValueType.TenThousandsKm, label: 'option_value_type.ten_thousands_km' },
  { value: OptionValueType.Year, label: 'option_value_type.year' },
  { value: OptionValueType.Millimeter, label: 'option_value_type.mm' },
  { value: OptionValueType.Centimeter, label: 'option_value_type.cm' },
  { value: OptionValueType.Percentage, label: 'option_value_type.percentage' },
  { value: OptionValueType.Number, label: 'option_value_type.number' },
  { value: OptionValueType.String, label: 'option_value_type.string' },
  { value: OptionValueType.Text, label: 'option_value_type.text' },
  { value: OptionValueType.Xml, label: 'option_value_type.xml' },
  { value: OptionValueType.Json, label: 'option_value_type.json' },
  { value: OptionValueType.Csv, label: 'option_value_type.csv' },
  { value: OptionValueType.Yaml, label: 'option_value_type.yaml' },
  { value: OptionValueType.Blob, label: 'option_value_type.blob' },
  { value: OptionValueType.Custom, label: 'option_value_type.custom' }
];

export const OptionValueTypeOptionsWithDefault: Array<Option<OptionValueType | null>> = [
  { value: null, label: 'option_value_type.__default__' },
  { value: OptionValueType.Celsius, label: 'option_value_type.celsius' },
  { value: OptionValueType.Fahrenheit, label: 'option_value_type.fahrenheit' },
  { value: OptionValueType.Kilometer, label: 'option_value_type.km' },
  { value: OptionValueType.TenThousandsKm, label: 'option_value_type.ten_thousands_km' },
  { value: OptionValueType.Year, label: 'option_value_type.year' },
  { value: OptionValueType.Millimeter, label: 'option_value_type.mm' },
  { value: OptionValueType.Centimeter, label: 'option_value_type.cm' },
  { value: OptionValueType.Percentage, label: 'option_value_type.percentage' },
  { value: OptionValueType.Number, label: 'option_value_type.number' },
  { value: OptionValueType.String, label: 'option_value_type.string' },
  { value: OptionValueType.Text, label: 'option_value_type.text' },
  { value: OptionValueType.Xml, label: 'option_value_type.xml' },
  { value: OptionValueType.Json, label: 'option_value_type.json' },
  { value: OptionValueType.Csv, label: 'option_value_type.csv' },
  { value: OptionValueType.Yaml, label: 'option_value_type.yaml' },
  { value: OptionValueType.Blob, label: 'option_value_type.blob' },
  { value: OptionValueType.Custom, label: 'option_value_type.custom' }
];