import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import {
  InspectionTemplatePredefinedType,
  VehicleInspectionTemplate,
} from 'model';
import { InspectionTemplates } from '../states';
import { ActionTypes } from '../types';

const initialState: InspectionTemplates = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {
    predefinedType: [InspectionTemplatePredefinedType.FullInspection],
  },
  source: 'all',
};

const defaultReducer = createAsyncListActionReducers<
  VehicleInspectionTemplate,
  InspectionTemplates
>('inspection.templates', initialState, {
  mapItemKey: x => x.id,
});

export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: InspectionTemplates = initialState,
  action: StandardAction<any>,
): InspectionTemplates {
  switch (action.type) {
    case ActionTypes.TemplateSourceChanged:
      return {
        ...state,
        source: action.payload,
      };
    default:
      return defaultReducer(state, action);
  }
}
