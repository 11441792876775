/**
 * @file: chartjs-defaults.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { defaults } from 'react-chartjs-2';

// https://github.com/jerairrest/react-chartjs-2/issues/131
const Chart = require('react-chartjs-2').Chart;

import merge from 'lodash.merge';

merge(defaults, {
  scale: {
    gridLines: {
      color: 'rgba(128, 128, 128, 0.07)',
    },
  },
  global: {
    tooltips: {},
    elements: {
      legend: {},
      point: {
        borderColor: '#03a9f4',
        backgroundColor: '#ffffff',
        hoverRadius: 5,
      },
      line: {
        tension: 0.4,
        fill: true,
        backgroundColor: 'rgba(3, 169, 244, 0.1)',
        borderColor: '#03a9f4',
        borderWidth: 2,
        pointBorderColor: '#03a9f4',
        pointBackgroundColor: '#ffffff',
      },
    },
  },
});

Chart.elements.Rectangle.prototype.draw = function () {
  const ctx = this._chart.ctx;
  const vm = this._view;
  // eslint-disable-next-line no-var, @typescript-eslint/init-declarations
  var left, right, top, bottom, signX, signY, borderSkipped;
  let borderWidth = vm.borderWidth;

  // Set Radius Here
  // If radius is large enough to cause drawing errors a max radius is imposed
  const cornerRadius = this._chart.options.barRadius
    ? this._chart.options.barRadius
    : 0;

  if (!vm.horizontal) {
    // bar
    left = vm.x - vm.width / 2;
    right = vm.x + vm.width / 2;

    if (vm.y > 2 * cornerRadius) {
      top = vm.y - cornerRadius;
    } else {
      top = vm.y;
    }

    bottom = vm.base;
    signX = 1;
    signY = bottom > top ? 1 : -1;
    borderSkipped = vm.borderSkipped || 'bottom';
    //console.log(vm.base + '-' + vm.y);
  } else {
    // horizontal bar
    left = vm.base;
    right = vm.x;
    top = vm.y - vm.height / 2;
    bottom = vm.y + vm.height / 2;
    signX = right > left ? 1 : -1;
    signY = 1;
    borderSkipped = vm.borderSkipped || 'left';
  }

  // Canvas doesn't allow us to stroke inside the width so we can
  // adjust the sizes to fit if we're setting a stroke on the line
  if (borderWidth) {
    // borderWidth shold be less than bar width and bar height.
    const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
    borderWidth = borderWidth > barSize ? barSize : borderWidth;
    const halfStroke = borderWidth / 2;
    // Adjust borderWidth when bar top position is near vm.base(zero).
    const borderLeft =
      left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
    const borderRight =
      right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
    const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
    const borderBottom =
      bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
    // not become a vertical line?
    if (borderLeft !== borderRight) {
      top = borderTop;
      bottom = borderBottom;
    }
    // not become a horizontal line?
    if (borderTop !== borderBottom) {
      left = borderLeft;
      right = borderRight;
    }
  }

  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = borderWidth;

  // Corner points, from bottom-left to bottom-right clockwise
  // | 1 2 |
  // | 0 3 |
  const corners = [
    [left, bottom],
    [left, top],
    [right, top],
    [right, bottom],
  ];

  // Find first (starting) corner with fallback to 'bottom'
  const borders = ['bottom', 'left', 'top', 'right'];
  let startCorner = borders.indexOf(borderSkipped, 0);
  if (startCorner === -1) {
    startCorner = 0;
  }

  function cornerAt(index: number) {
    return corners[(startCorner + index) % 4];
  }

  // Draw rectangle from 'startCorner'
  let corner = cornerAt(0);
  ctx.moveTo(corner[0], corner[1]);

  for (let i = 1; i < 4; i++) {
    corner = cornerAt(i);
    let nextCornerId = i + 1;
    if (nextCornerId == 4) {
      nextCornerId = 0;
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const nextCorner = cornerAt(nextCornerId);

    const width = corners[2][0] - corners[1][0];
    const height = corners[0][1] - corners[1][1];
    const x = corners[1][0];
    const y = corners[1][1];

    let r = cornerRadius;

    // Fix radius being too large
    if (r > height / 2) {
      r = height / 2;
    }
    if (r > width / 2) {
      r = width / 2;
    }

    ctx.moveTo(x + r, y);
    ctx.lineTo(x + width - r, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + r);
    ctx.lineTo(x + width, y + height - r);
    ctx.quadraticCurveTo(x + width, y + height, x + width - r, y + height);
    ctx.lineTo(x + r, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - r);
    ctx.lineTo(x, y + r);
    ctx.quadraticCurveTo(x, y, x + r, y);
  }

  ctx.fill();
  if (borderWidth) {
    ctx.stroke();
  }
};
