import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import { memo } from 'react';
import { getString } from 'shared/components';

export const NewCategory = memo(
  ({ defaultValue }: { defaultValue: string }) => {
    const { onCategoryNameBlur, onCategoryNameKeyup } =
      useQuotationTemplateConfigContext();
    return (
      <div className="quotation-tpl__category quotation-tpl__category--editor">
        <div className="quotation-tpl__category-hd">
          <i className="fa fa-folder" />
          <input
            type="text"
            className="form-control form-control-sm m-input"
            placeholder={getString(
              'inspection_template.detail.placeholder.category_name',
            )}
            defaultValue={defaultValue}
            onBlur={onCategoryNameBlur}
            onKeyUp={onCategoryNameKeyup}
            ref={c => c?.focus()}
          />
        </div>
      </div>
    );
  },
);
