/**
 * @file: VehicleInspectionTaskDiagnosticJobService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionTaskDiagnosticJobListFilter, VehicleInspectionTaskDiagnosticJob, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions, VehicleInspectionTaskDiagnosticJobTroubleCode, VehicleInspectionTaskDiagnosticJobMedia } from 'model';

export class VehicleInspectionTaskDiagnosticJobServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskDiagnosticJobListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionTaskDiagnosticJob> : VehicleInspectionTaskDiagnosticJob[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-diagnostic-jobs', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionTaskDiagnosticJob | null> {
    return await this.api.get()
      .url('/vehicle-inspection-task-diagnostic-jobs/:id', { id })
      .future();
  }

  async create(vehicleInspectionTaskDiagnosticJob: Partial<VehicleInspectionTaskDiagnosticJob>): Promise<VehicleInspectionTaskDiagnosticJob> {
    return await this.api.post()
      .url('/vehicle-inspection-task-diagnostic-jobs')
      .data(vehicleInspectionTaskDiagnosticJob)
      .future();
  }

  async update(vehicleInspectionTaskDiagnosticJob: Partial<VehicleInspectionTaskDiagnosticJob>): Promise<VehicleInspectionTaskDiagnosticJob> {
    return await this.api.put()
      .url('/vehicle-inspection-task-diagnostic-jobs/:id', { id: vehicleInspectionTaskDiagnosticJob.id })
      .data(vehicleInspectionTaskDiagnosticJob)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/vehicle-inspection-task-diagnostic-jobs/:id', { id })
      .future();
  }

  async getTroubleCodesByJobId(id: number, recursive = false): Promise<VehicleInspectionTaskDiagnosticJobTroubleCode[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-diagnostic-jobs/:id/trouble-codes', { id, recursive })
      .future();
  }

  async createVehicleInspectionTaskDiagnosticJobTroubleCode(code: Partial<VehicleInspectionTaskDiagnosticJobTroubleCode>): Promise<VehicleInspectionTaskDiagnosticJobTroubleCode> {
    const id = code.jobId;
    if (!id) {
      throw new Error('code.jobId is required. ');
    }
    return await this.api.post()
      .url('/vehicle-inspection-task-diagnostic-jobs/:id/trouble-codes')
      .data(code)
      .future();
  }

  async updateVehicleInspectionTaskDiagnosticJobTroubleCode(code: VehicleInspectionTaskDiagnosticJobTroubleCode): Promise<VehicleInspectionTaskDiagnosticJobTroubleCode> {
    const id = code.jobId;
    if (!id) {
      throw new Error('code.jobId is required. ');
    }
    return await this.api.put()
      .url('/vehicle-inspection-task-diagnostic-jobs/:id/trouble-codes')
      .data(code)
      .future();
  }

  async deleteVehicleInspectionTaskDiagnosticJobTroubleCode(
    jobId: number | VehicleInspectionTaskDiagnosticJobTroubleCode, codeId?: number
  ): Promise<void> {
    if (jobId && typeof jobId === 'object') {
      codeId = jobId.id;
      if (!jobId.jobId) {
        throw new Error('jobId cannot be null or undefined');
      }
      jobId = jobId.jobId;
    }
    if (!jobId || !codeId) {
      throw new Error('jobId and codeId are both required');
    }
    return void await this.api.delete()
      .url('/vehicle-inspection-task-diagnostic-jobs/:jobId/trouble-codes/:codeId', { jobId, codeId })
      .future();
  }

  async getMediasByJobId(id: number, recursive = false): Promise<VehicleInspectionTaskDiagnosticJobMedia[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-diagnostic-jobs/:id/medias', { id, recursive })
      .future();
  }

  async createVehicleInspectionTaskDiagnosticJobMedia(media: Partial<VehicleInspectionTaskDiagnosticJobMedia>): Promise<VehicleInspectionTaskDiagnosticJobMedia> {
    const id = media.jobId;
    if (!id) {
      throw new Error('media.jobId is required. ');
    }
    return await this.api.post()
      .url('/vehicle-inspection-task-diagnostic-jobs/:id/medias')
      .data(media)
      .future();
  }

  async updateVehicleInspectionTaskDiagnosticJobMedia(media: VehicleInspectionTaskDiagnosticJobMedia): Promise<VehicleInspectionTaskDiagnosticJobMedia> {
    const id = media.jobId;
    if (!id) {
      throw new Error('media.jobId is required. ');
    }
    return await this.api.put()
      .url('/vehicle-inspection-task-diagnostic-jobs/:id/medias')
      .data(media)
      .future();
  }

  async deleteVehicleInspectionTaskDiagnosticJobMedia(
    jobId: number | VehicleInspectionTaskDiagnosticJobMedia, mediaId?: number
  ): Promise<void> {
    if (jobId && typeof jobId === 'object') {
      mediaId = jobId.id;
      if (!jobId.jobId) {
        throw new Error('jobId cannot be null or undefined');
      }
      jobId = jobId.jobId;
    }
    if (!jobId || !mediaId) {
      throw new Error('jobId and mediaId are both required');
    }
    return void await this.api.delete()
      .url('/vehicle-inspection-task-diagnostic-jobs/:jobId/medias/:mediaId', { jobId, mediaId })
      .future();
  }
}