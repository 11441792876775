import { AppState } from 'app/duck/states';
import { DispatchFn } from 'lib/duck/interfaces';
import { Alert, Block, Button } from 'lib/metronic/components';
import {
  MpConditionalMenuMatchRule,
  MpConditionalMenuMatchRuleClientPlatformType,
  MpConditionalMenuMatchRuleGender,
  MpConditionalMenuMatchRuleLanguage,
} from 'model';
import { ChangeEvent, FocusEvent, PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { ThunkDispatch } from 'redux-thunk';
import { getString } from 'shared/components';
import { loadAsyncList } from 'utils';
import { weixinMenuActions } from '../duck/actions';
import { tagActions } from '../duck/actions/tags';
import { WeixinUserTags } from '../duck/states';

interface Props {
  tags: WeixinUserTags;
  showTip: boolean;
}

interface OwnProps {
  editorId: number;
  rule: MpConditionalMenuMatchRule;
  onChange: (
    editorId: number,
    changes: Partial<MpConditionalMenuMatchRule>,
  ) => void;
}

interface DispatchProps {
  dispatch: DispatchFn<AppState>;
}

interface GenderRuleOption {
  value?: number;
  label: string;
}

interface ClientPlatformRuleOption {
  value?: number;
  label: string;
}

const Genders: GenderRuleOption[] = [
  {
    label: 'none',
  },
  {
    value: MpConditionalMenuMatchRuleGender.Male,
    label: 'male',
  },
  {
    value: MpConditionalMenuMatchRuleGender.Female,
    label: 'female',
  },
];

const Platforms: ClientPlatformRuleOption[] = [
  {
    label: 'none',
  },
  {
    value: MpConditionalMenuMatchRuleClientPlatformType.iOS,
    label: 'ios',
  },
  {
    value: MpConditionalMenuMatchRuleClientPlatformType.Android,
    label: 'android',
  },
  {
    value: MpConditionalMenuMatchRuleClientPlatformType.Others,
    label: 'others',
  },
];

const Languages: string[] = [
  '',
  MpConditionalMenuMatchRuleLanguage.SimplifiedChinese,
  MpConditionalMenuMatchRuleLanguage.TraditionalChineseTW,
  MpConditionalMenuMatchRuleLanguage.TraditionalChineseHK,
  MpConditionalMenuMatchRuleLanguage.English,
  MpConditionalMenuMatchRuleLanguage.Indonesian,
  MpConditionalMenuMatchRuleLanguage.Malaysian,
  MpConditionalMenuMatchRuleLanguage.Spanish,
  MpConditionalMenuMatchRuleLanguage.Korean,
  MpConditionalMenuMatchRuleLanguage.Italian,
  MpConditionalMenuMatchRuleLanguage.Japanese,
  MpConditionalMenuMatchRuleLanguage.Polish,
  MpConditionalMenuMatchRuleLanguage.Portuguese,
  MpConditionalMenuMatchRuleLanguage.Russian,
  MpConditionalMenuMatchRuleLanguage.Thai,
  MpConditionalMenuMatchRuleLanguage.Vietnamese,
  MpConditionalMenuMatchRuleLanguage.Arabic,
  MpConditionalMenuMatchRuleLanguage.Hindi,
  MpConditionalMenuMatchRuleLanguage.Hebrew,
  MpConditionalMenuMatchRuleLanguage.Turkish,
  MpConditionalMenuMatchRuleLanguage.Germany,
  MpConditionalMenuMatchRuleLanguage.French,
];

function mapStateToProps(state: AppState): Props {
  return {
    tags: state.integration.tags,
    showTip: state.integration.menus.showCondionalMenuTip,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, any, any>,
): DispatchProps {
  return { dispatch };
}

interface State {
  tagError?: string | null;
}

class PersonalizedMenuConfigImpl extends PureComponent<
  Props & OwnProps & DispatchProps,
  State
> {
  constructor(props: Props & OwnProps & DispatchProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch((dispatch, getState) => {
      const state = getState();
      loadAsyncList(state.integration.tags, () => dispatch(tagActions.fetch()));
    });
  }

  render() {
    const { rule, tags, showTip } = this.props;
    return (
      <div className="personalized-menu-config">
        {showTip && (
          <Alert color="secondary" dismissible onDismiss={this.onDismissTip}>
            <ReactMarkdown>
              {getString('integration.menu.pmc.tip')}
            </ReactMarkdown>
          </Alert>
        )}

        {/* tag */}
        <div className="form-group">
          <label>
            <Translate id="integration.menu.pmc.label.tag" />
          </label>
          <div className="input-group">
            <select
              className="form-control"
              value={rule.tagId}
              onChange={this.onTagChange}
              style={{ appearance: 'none', WebkitAppearance: 'none' }}
            >
              {!tags.result && (
                <option>
                  {getString('integration.menu.pmc.loading_tags')}
                </option>
              )}
              {tags.result && (
                <option>
                  {getString('integration.menu.pmc.options.tag.none')}
                </option>
              )}
              {tags.result?.map(tag => (
                <option value={tag.id} key={tag.id}>
                  {`${tag.name} (${tag.count})`}
                </option>
              ))}
            </select>
            <div className="input-group-append">
              <Button color="secondary" iconOnly onClick={this.onAddTag}>
                <i className="fa fa-plus" />
              </Button>
            </div>
          </div>
          <span className="form-text text-muted">
            <ReactMarkdown>
              {getString('integration.menu.pmc.help_text.tag')}
            </ReactMarkdown>
          </span>
        </div>

        {/* gender */}
        <div className="form-group">
          <label>
            <Translate id="integration.menu.pmc.label.gender" />
          </label>
          <div className="input-group">
            <select
              className="form-control"
              value={rule.sex}
              onChange={this.onGenderChange}
            >
              {Genders.map(option => (
                <option value={option.value} key={option.value || '<none>'}>
                  {getString(
                    `integration.menu.pmc.options.gender.${option.label}`,
                  )}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* platform */}
        <div className="form-group">
          <label>
            <Translate id="integration.menu.pmc.label.platform" />
          </label>
          <div className="input-group">
            <select
              className="form-control"
              value={rule.clientPlatformType}
              onChange={this.onPlatformChange}
            >
              {Platforms.map(option => (
                <option value={option.value} key={option.value || '<none>'}>
                  {getString(
                    `integration.menu.pmc.options.platform.${option.label}`,
                  )}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* language */}
        <div className="form-group">
          <label>
            <Translate id="integration.menu.pmc.label.language" />
          </label>
          <div className="input-group">
            <select
              className="form-control"
              value={rule.language}
              onChange={this.onLanguageChange}
            >
              {Languages.map(value => (
                <option value={value} key={value || '<none>'}>
                  {getString(
                    `integration.menu.pmc.options.language.${value || 'none'}`,
                  )}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* country */}
        <div className="form-group">
          <label>
            <Translate id="integration.menu.pmc.label.country" />
          </label>
          <input
            type="text"
            className="form-control"
            defaultValue={rule.country || ''}
            key={rule.country || ''}
            onBlur={this.onCountryBlur}
            placeholder={getString('integration.menu.pmc.placeholder.country')}
          />
        </div>

        {/* province */}
        <div className="form-group">
          <label>
            <Translate id="integration.menu.pmc.label.province" />
          </label>
          <input
            type="text"
            className="form-control"
            defaultValue={rule.province || ''}
            key={rule.province || ''}
            onBlur={this.onProvinceBlur}
            placeholder={getString('integration.menu.pmc.placeholder.province')}
          />
        </div>

        {/* city */}
        <div className="form-group">
          <label>
            <Translate id="integration.menu.pmc.label.city" />
          </label>
          <input
            type="text"
            className="form-control"
            defaultValue={rule.city || ''}
            key={rule.city || ''}
            onBlur={this.onCityBlur}
            placeholder={getString('integration.menu.pmc.placeholder.city')}
          />
        </div>
        {this.renderTagModal()}
      </div>
    );
  }

  renderTagModal() {
    return (
      <Modal
        isOpen={Boolean(this.props.tags.itemBeingCreated)}
        onClosed={this.onTagDialogClose}
        toggle={this.onTagDialogClose}
        className="mp-media-picker-video-player-modal"
      >
        <Block active={Boolean(this.props.tags.isCommitingItemBeingCreated)}>
          <ModalHeader toggle={this.onTagDialogClose}>
            <Translate id="integration.menu.pmc.modal.create_tag.title" />
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label>
                <Translate id="integration.menu.pmc.label.tag_name" />
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={30}
                placeholder={getString(
                  'integration.menu.pmc.placeholder.tag_name',
                )}
                value={this.props.tags.itemBeingCreated?.name || ''}
                onChange={this.onTagNameChange}
              />
              {this.state.tagError && (
                <div className="invalid-feedback">
                  <Translate id={this.state.tagError} />
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              color="brand"
              wide
              disabled={
                !this.props.tags.itemBeingCreated?.name?.trim()
              }
              onClick={this.onCreateTag}
            >
              <Translate id="integration.menu.pmc.button.create_tag" />
            </Button>
          </ModalFooter>
        </Block>
      </Modal>
    );
  }

  onAddTag = () => {
    this.props.dispatch(tagActions.itemBeingCreated({ name: '' }));
  };

  onTagDialogClose = () => {
    if (this.props.tags.itemBeingCreated) {
      this.props.dispatch(tagActions.cancelItemBeingCreated());
    }
  };

  onTagNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.state.tagError && e.target.value.trim().length <= 30) {
      this.setState({ tagError: null });
    }
    this.props.dispatch(
      tagActions.itemBeingCreatedChanged({
        name: e.target.value,
      }),
    );
  };

  onCreateTag = () => {
    const tagName = this.props.tags.itemBeingCreated!.name!.trim();
    if (tagName.length > 30) {
      this.setState({
        tagError: 'integration.menu.pmc.error.tag_name_too_long',
      });
      return;
    }
    this.props.dispatch(tagActions.commitItemBeingCreated());
  };

  onTagChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const tagId = e.target.value ? Number(e.target.value) : undefined;
    this.onChange({ tagId });
  };

  onGenderChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const sex = e.target.value ? Number(e.target.value) : undefined;
    this.onChange({ sex });
  };

  onPlatformChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const clientPlatformType = e.target.value
      ? Number(e.target.value)
      : undefined;
    this.onChange({ clientPlatformType });
  };

  onLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const language = (e.target.value || undefined) as
      | MpConditionalMenuMatchRuleLanguage
      | undefined;
    this.onChange({ language });
  };

  onCountryBlur = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value.trim() || undefined;
    if (value !== this.props.rule.country) {
      this.onChange({ country: value });
    }
  };

  onProvinceBlur = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value.trim() || undefined;
    if (value !== this.props.rule.province) {
      this.onChange({ province: value });
    }
  };

  onCityBlur = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value.trim() || undefined;
    if (value !== this.props.rule.city) {
      this.onChange({ city: value });
    }
  };

  onChange = (changes: Partial<MpConditionalMenuMatchRule>) => {
    this.props.onChange(this.props.editorId, changes);
  };

  onDismissTip = () => {
    this.props.dispatch(weixinMenuActions.dismissConditionalMenuTip());
  };
}

export const PersonalizedMenuConfig = connect<Props, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalizedMenuConfigImpl);
