/**
 * @file: VehicleInspectionTaskJobDetailService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { VehicleInspectionTaskJobDetailServiceBase } from './base/VehicleInspectionTaskJobDetailService';

export class VehicleInspectionTaskJobDetailService extends VehicleInspectionTaskJobDetailServiceBase {
  // add custom service implementation here
}
