import React, { PureComponent } from 'react';
import { MpMessageTemplate } from 'model';
import { Translate } from 'react-localize-redux';

interface Props {
  template: MpMessageTemplate;
}

export class TemplateDetail extends PureComponent<Props> {
  render() {
    const { template } = this.props;
    if (!template) return null;
    return (
      <div className="mp-template-detail">
        <dl className="mp-template-detail__info">
          <dt><Translate id="integration.templates.detail.label.title" /></dt>
          <dd>{template.title}</dd>
        </dl>
        <dl className="mp-template-detail__info">
          <dt><Translate id="integration.templates.detail.label.first_class" /></dt>
          <dd>{template.primaryIndustry}</dd>
        </dl>
        <dl className="mp-template-detail__info">
          <dt><Translate id="integration.templates.detail.label.second_class" /></dt>
          <dd>{template.deputyIndustry}</dd>
        </dl>
        <dl className="mp-template-detail__info">
          <dt><Translate id="integration.templates.detail.label.content" /></dt>
          <dd>
            <pre>
              {template.content}
            </pre>
          </dd>
        </dl>
        <dl className="mp-template-detail__info">
          <dt><Translate id="integration.templates.detail.label.example" /></dt>
          <dd>
            <pre>
              {template.example}
            </pre>
          </dd>
        </dl>
      </div>
    );
  }
}