import classNames from 'classnames';
import { Component, MouseEvent, ReactNode } from 'react';
import { TreeNode } from './TreeNode';
import { Node } from './types';

import './index.scss';

interface TreeProps {
  nodes: Node[];
  className?: string;
  emptyText?: string | ReactNode;
  onRenderNodeText?: (node: Node) => string | ReactNode;
  onNodeClick?: (node: Node) => 'toggle' | undefined;
  onNodeExpand?: (node: Node) => void;
  onNodeCollapse?: (node: Node) => void;
  onNodeCheckChange?: (node: Node, checked: boolean) => void;
  onNodeMouseEnter?: (node: Node, e: MouseEvent) => void;
  onNodeMouseLeave?: (node: Node, e: MouseEvent) => void;
  onRenderNodeDecoratedView?: (
    node: Node,
  ) => ReactNode | string | null | undefined;
  onRenderEmptyPlaceholder?: (node: Node | null) => string | ReactNode;
  onRenderLoading?: (node: Node) => string | ReactNode;
}

export class Tree extends Component<TreeProps> {
  render() {
    const { nodes, className, onRenderEmptyPlaceholder } = this.props;
    return (
      <div
        className={classNames('tree', className, {
          'tree--empty': !nodes.length,
        })}
      >
        {!nodes.length &&
          onRenderEmptyPlaceholder &&
          onRenderEmptyPlaceholder(null)}
        {nodes.map(node => (
          <TreeNode
            key={node.id}
            node={node}
            onRenderNodeText={this.props.onRenderNodeText}
            onClick={this.onNodeClick}
            onExpand={this.onNodeExpand}
            onCollapse={this.onNodeCollapse}
            onCheckChange={this.props.onNodeCheckChange}
            onMouseEnter={this.props.onNodeMouseEnter}
            onMouseLeave={this.props.onNodeMouseLeave}
            onRenderNodeDecoratedView={this.props.onRenderNodeDecoratedView}
            onRenderEmptyPlaceholder={this.props.onRenderEmptyPlaceholder}
            onRenderLoading={this.props.onRenderLoading}
          />
        ))}
      </div>
    );
  }

  onNodeClick = (node: Node) => {
    return this.props.onNodeClick?.(node);
  };

  onNodeExpand = (node: Node) => {
    this.props.onNodeExpand && this.props.onNodeExpand(node);
  };

  onNodeCollapse = (node: Node) => {
    this.props.onNodeCollapse && this.props.onNodeCollapse(node);
  };
}
