/**
 * @file: filter.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { InspectionSiteNodeFilter } from 'model';
import { ActionTypes } from '../types';

const initialState: Partial<InspectionSiteNodeFilter> = {
  keyword: '',
};

export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Partial<InspectionSiteNodeFilter> = initialState,
  action: StandardAction<any>,
): Partial<InspectionSiteNodeFilter> {
  if (action.type === ActionTypes.FilterChanged) {
    const filter = action.payload as Partial<InspectionSiteNodeFilter>;
    return { ...state, ...filter };
  }
  return state;
}
