import React, { MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { AsideRight } from 'lib/metronic/layout';
import { productAuthorizationContractActions } from '../duck/actions';
import { ProductAuthorizationContract, AclObjectList, IdType } from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  ProductAuthorizationContractClientEntityTypeLabel,
  ProductAuthorizationContractStatusLabel,
} from 'shared/components';
import { ProductAuthorizationContractDetail } from './detail';
import { ProductAuthorizationContracts } from '../duck/states';
import { ProductAuthorizationContractStatusOptionsWithDefault } from 'model/EnumOptions';
import { TabNav } from 'lib/metronic/components';
import { ProductAuthorizationContractSearchParams } from 'model/app/product';

import './index.scss';

interface Props
  extends EntityListProps<
    ProductAuthorizationContract,
    ProductAuthorizationContractSearchParams,
    IdType
  > {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  ProductAuthorizationContract,
  ProductAuthorizationContractSearchParams,
  IdType,
  Props
>();

export const ProductAuthorizationContractList = componentClassBuilder
  .i18nPrefix('product_authorization_contract')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/home/book-open.svg')
      .default,
  )
  .accessRights({
    readonly: AclObjectList.ProductAuthorizationContractReadonlyAccess,
    full: AclObjectList.ProductAuthorizationContractFullAccess,
  })
  .breadcrumbs([
    { text: <Translate id="product.breadcrumb.it" /> },
    { text: <Translate id="product.breadcrumb.authorization_contracts" /> },
  ])
  .features({ addEntity: false, deleteEntity: false })
  .entities(state => state.products.authorizationContracts)
  .actions(productAuthorizationContractActions)
  .toolbarItems(builder => {
    builder
      .select({
        prop: 'status',
        width: 150,
        array: true,
        clearable: true,
        label: 'product_authorization_contract.toolbar.label.status',
        placeholder:
          'product_authorization_contract.toolbar.placeholder.status',
        values: ProductAuthorizationContractStatusOptionsWithDefault.map(x => ({
          ...x,
          label: <Translate id={x.label} />,
        })),
      })
      .button({
        buttonType: 'search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(productAuthorizationContractActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'contractNumber',
      width: 150,
      align: 'center',
      text: 'col.id',
      render: ({ id, contractNumber }, props: Props) => {
        const onClick = (e: MouseEvent) => {
          e.preventDefault();
          const item = props.entities.result?.find(x => x.id === id);
          if (item) {
            props.dispatch(
              productAuthorizationContractActions.setActiveItem(item),
            );
          }
        };
        return (
          <a href="#" onClick={onClick}>
            {contractNumber}
          </a>
        );
      },
    },
    {
      prop: 'clientEntityType',
      width: 100,
      text: 'product_authorization_contract.col.client_type',
      align: 'center',
      render: ({ clientEntityType }) => (
        <ProductAuthorizationContractClientEntityTypeLabel
          value={clientEntityType}
        />
      ),
    },
    {
      prop: 'clientEntityName',
      width: 250,
      text: 'product_authorization_contract.col.client_name',
    },
    {
      prop: 'supplierEntityId',
      width: 150,
      text: 'product_authorization_contract.col.supplier_id',
    },
    {
      prop: 'supplierEntityName',
      width: 250,
      text: 'product_authorization_contract.col.supplier_name',
    },
    {
      prop: 'status',
      width: 100,
      text: 'col.status',
      render: ({ status }) => (
        <ProductAuthorizationContractStatusLabel value={status} />
      ),
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      align: 'center',
      width: 200,
      render: ({ createdAt }) => formatTime(createdAt, 'YYYY-MM-DD HH:mm'),
    },
  ])
  .onRender((props: Props) => {
    const { dispatch } = props;
    const entities = props.entities as ProductAuthorizationContracts;

    const onClose = () => {
      dispatch(productAuthorizationContractActions.setActiveItem(undefined));
    };

    return (
      <>
        <AsideRight
          open={entities.activeItem != null}
          onClose={onClose}
          width={600}
        >
          <AsideRight.Nav>
            <TabNav line="brand" bolder>
              <TabNav.Item active>
                <Translate id="product_authorization_contract.detail.title" />
              </TabNav.Item>
            </TabNav>
          </AsideRight.Nav>
          <AsideRight.Content>
            {entities.activeItem != null && (
              <ProductAuthorizationContractDetail
                contract={entities.activeItem}
              />
            )}
          </AsideRight.Content>
        </AsideRight>
      </>
    );
  })
  .componentDidUpdate((props, prevProps) => {
    if (props.activeStoreId !== prevProps.activeStoreId) {
      props.dispatch(productAuthorizationContractActions.invalidate(true));
    }
  })
  .getClass();
