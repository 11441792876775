/**
 * @file: ProductAuthorizationContractClientEntityType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ProductAuthorizationContractClientEntityType {
  Organization = 'org',
  Store = 'store',
  Agent = 'agent',
}

export const ProductAuthorizationContractClientEntityTypeValueSet = new Set([
  ProductAuthorizationContractClientEntityType.Organization,
  ProductAuthorizationContractClientEntityType.Store,
  ProductAuthorizationContractClientEntityType.Agent,
]);
