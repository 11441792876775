/**
 * @file: DataExportTaskService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  DataExportTask,
  DataExportTaskListFilter,
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
} from 'model';

export class DataExportTaskServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<DataExportTaskListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<DataExportTask>
      : DataExportTask[]
  > {
    return await this.api
      .get()
      .url(
        '/data-export-tasks',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<DataExportTask | null> {
    return await this.api.get().url('/data-export-tasks/:id', { id }).future();
  }

  async getTaskByTaskId(taskId: string): Promise<DataExportTask | null> {
    if (!taskId) {
      throw new Error('missing taskId parameter');
    }
    return await this.api
      .get()
      .url('/data-export-tasks/query/taskid', { taskId })
      .future();
  }

  async create(
    dataExportTask: Partial<DataExportTask>,
  ): Promise<DataExportTask> {
    return await this.api
      .post()
      .url('/data-export-tasks')
      .data(dataExportTask)
      .future();
  }

  async update(
    dataExportTask: Partial<DataExportTask>,
  ): Promise<DataExportTask> {
    return await this.api
      .put()
      .url('/data-export-tasks/:id', { id: dataExportTask.id })
      .data(dataExportTask)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/data-export-tasks/:id', { id })
      .future();
  }
}
