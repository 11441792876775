/**
 * @file: areas.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AreaTree } from 'model';
import { createAsyncActionReducers } from 'lib/duck/reducers';
import { Areas } from '../states';

const initialState: Areas = {
  isLoading: false,
  error: null,
  result: null,
};

export default createAsyncActionReducers<AreaTree, Areas>(
  'app.areas',
  initialState,
);
