import { DeliveryCheckTemplateManagerProps } from 'app/inspection/delivery-check-templates/list';
import {
  DeliveryCheckTemplateMatchTypeOptions,
  DeliveryCheckTemplateSceneTypeOptions,
  VehicleDeliveryCheckTemplate,
} from 'model';
import { organizationService, storeService, systemService } from 'services';
import { getString, withEntityEditorSidebarBuilder } from 'shared/components';
import { Content as BarriersSettings } from './BarriersSettings';

export const DeliveryCheckTemplateEditor =
  withEntityEditorSidebarBuilder<VehicleDeliveryCheckTemplate>()
    .withI18nPrefix('delivery_check_template')
    .withForm(builder =>
      builder
        .store({
          prop: 'storeId',
          label: 'delivery_check_template.editor.label.store',
          helpText: 'delivery_check_template.editor.help_text.store',
        })
        .text({
          prop: 'name',
          label: 'delivery_check_template.editor.label.name',
          placeholder: 'delivery_check_template.editor.placeholder.name',
        })
        .select({
          prop: 'sceneType',
          label: 'delivery_check_template.editor.label.scene_type',
          placeholder: 'delivery_check_template.editor.placeholder.scene_type',
          helpText: 'delivery_check_template.editor.help_text.scene_type',
          options: DeliveryCheckTemplateSceneTypeOptions,
        })
        .select({
          prop: 'withInspectionResults',
          label: 'delivery_check_template.editor.label.with_inspection_results',
          placeholder:
            'delivery_check_template.editor.placeholder.with_inspection_results',
          helpText:
            'delivery_check_template.editor.help_text.with_inspection_results',
          options: DeliveryCheckTemplateMatchTypeOptions,
        })
        .select({
          prop: 'withConstructionJobs',
          label: 'delivery_check_template.editor.label.with_construction_jobs',
          placeholder:
            'delivery_check_template.editor.placeholder.with_construction_jobs',
          helpText:
            'delivery_check_template.editor.help_text.with_construction_jobs',
          options: DeliveryCheckTemplateMatchTypeOptions,
        })
        .reactSelect({
          valueProp: 'name',
          labelProp: 'name',
          prop: 'withOrderType',
          label: 'delivery_check_template.editor.label.with_order_type',
          placeholder:
            'delivery_check_template.editor.placeholder.with_order_type',
          helpText: 'delivery_check_template.editor.help_text.with_order_type',
          noOptionsMessage:
            'delivery_check_template.editor.label.no_order_tags_msg',
          async: true,
          stateId: entity =>
            entity.storeId
              ? `order-tags-store:${entity.storeId}`
              : `order-tags-org`,
          values: [],
          defaultValues: true,
          clearable: true,
          searchable: false,
          onLoadValues: async (_, entity) => {
            const storeId = entity.storeId;
            const [orderTags, predefinedOrderTypes] = await Promise.all([
              storeId
                ? storeService
                    .getConfiguration(storeId)
                    .then(x => x.orderTags ?? [])
                : organizationService
                    .getConfiguration()
                    .then(x => x.orderTags ?? []),
              systemService.getPredefinedOrderTags(),
            ]);
            if (orderTags.length === 0) {
              return predefinedOrderTypes;
            }
            return orderTags;
          },
        })
        .custom({
          label: '',
          key: 'barriers',
          hidden: entity => Boolean(!entity.id || entity.agentId),
          render: ({ props }: { props: DeliveryCheckTemplateManagerProps }) => {
            if (
              !props.templates.itemBeingUpdated ||
              props.templates.itemBeingUpdated.storeId
            ) {
              return null;
            }
            return (
              <BarriersSettings
                template={props.templates.itemBeingUpdated}
                style={{
                  padding: 0,
                }}
              />
            );
          },
        })
        .checkbox({
          prop: 'isSystemDefault',
          label: 'delivery_check_template.editor.label.is_system_default',
        })
        .checkbox({
          prop: 'disabled',
          inverse: true,
          label: 'delivery_check_template.editor.label.enabled',
        })
        .textArea({
          prop: 'description',
          label: 'delivery_check_template.editor.label.description',
          placeholder: 'delivery_check_template.editor.placeholder.description',
        }),
    )
    .withValidator(template => {
      let code: string | undefined = undefined;
      if (!template.name?.trim()) {
        code = 'template_name_required';
      }
      if (!template.sceneType) {
        code = 'scene_type_required';
      }
      if (code) {
        const msg = getString(`delivery_check_template.editor.error.${code}`);
        throw new Error(msg);
      }
    })
    .getClass();
