import { ColorDropdown } from 'app/settings/ColorDropdown';
import { kOrderFieldVisibilityOptions } from 'app/settings/constants';
import format from 'date-fns/format';
import { produce } from 'immer';
import { Button, TabNav } from 'lib/metronic/components';
import { AsideRight } from 'lib/metronic/layout';
import {
  ExtendedOrderPropertyOption,
  ExtendedPropertyDef,
  ExtendedPropertyDefAttachment,
  ExtendedPropertySelectRenderType,
  ExtendedPropertySupportedAssetType,
  ExtendedPropertyType,
  ExtendedPropertyVisibility,
  Option,
  OrderFieldVisibility,
  OrgUserRoleType,
  OrgUserRoleTypeOptions,
} from 'model';
import { nanoid } from 'nanoid/non-secure';
import {
  MouseEvent,
  ReactNode,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { Fill, Slot } from 'react-slot-fill';
import {
  EntityEditorForm,
  EntityEditorFormBuilder,
  ScopedTranslate,
  getString,
} from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';
import { ExtendedPropertyAttachments } from './ExtendedPropertyAttachments';
import { ExtendedPropertyOptions } from './ExtendedPropertyOptions';
import { PropertyEditorContextProvider } from './PropertyEditorContext';

const S = memo(({ id }: { id: string }) => {
  return <ScopedTranslate scope="store.config" id={id} />;
});

export type ExtendedOrderPropertyConfig = {
  id: string;
  name: string;
  group?: string;
  prop?: string;
  description?: string;
  type: ExtendedPropertyType;
  required?: boolean;
  options?: ExtendedOrderPropertyOption[];
  multi?: boolean;
  renderType?: ExtendedPropertySelectRenderType;
  placeholder?: string;
  multiline?: boolean;
  maxLength?: number;
  minLength?: number;
  remark?: boolean;
  visibility?: ExtendedPropertyVisibility;
  yesValue?: string;
  noValue?: string;
  yesLabel?: string;
  noLabel?: string;
  yesColor?: string;
  noColor?: string;
  color?: string;
  defaultOn?: boolean;
  dateOnly?: boolean;
  displayFormat?: string;
  autoFill?: boolean;
  includeInOrderSummary?: OrderFieldVisibility;
  limit?: number;
  supportedFormats?: ExtendedPropertySupportedAssetType[];
  attachments?: ExtendedPropertyDefAttachment[];
  updatableBy?: OrgUserRoleType[];
};

const kPropTypes: ExtendedPropertyDef['type'][] = [
  'select',
  'text',
  'switch',
  'datetime',
  'assets',
];

const kVisibilities: ExtendedPropertyVisibility[] = ['public', 'private'];

const kSupportedAssetTypes: Array<Option<ExtendedPropertySupportedAssetType>> =
  [
    {
      value: 'image',
      label: 'store.config.label.extended_property.asset_type.image',
    },
    {
      value: 'video',
      label: 'store.config.label.extended_property.asset_type.video',
    },
  ];

const kRenderTypes: ExtendedPropertySelectRenderType[] = [
  'auto',
  'checkbox',
  'select',
  'actionsheet',
];

export const PropertyEditor = memo(
  ({
    property,
    show,
    groupNames,
    onSave,
    onClose,
  }: {
    property?: ExtendedPropertyDef;
    show?: boolean;
    groupNames?: string[];
    onClose?: () => void;
    onSave?: (property: ExtendedPropertyDef) => any;
  }) => {
    const isDirty = useRef(false);

    const handleSave = usePersistFn(async (def: ExtendedPropertyDef) => {
      await onSave?.(def);
      isDirty.current = false;
    });

    const handleCancel = usePersistFn(() => {
      if (
        !isDirty.current ||
        confirm(getString('@string/confirm_entity_editor_modal_msg'))
      ) {
        isDirty.current = false;
        onClose?.();
      }
    });

    const onChange = usePersistFn(() => {
      isDirty.current = true;
    });

    return (
      <AsideRight
        open={show}
        onClose={handleCancel}
        className="settings__extended-properties-editor-aside"
      >
        <AsideRight.Nav>
          <TabNav line="brand" bolder>
            <TabNav.Item active>
              <S id="extended_property.editor.title" />
            </TabNav.Item>
          </TabNav>
        </AsideRight.Nav>
        <AsideRight.Content>
          {property && (
            <Content
              property={property}
              groupNames={groupNames}
              onSave={handleSave}
              onCancel={handleCancel}
              onChange={onChange}
            />
          )}
        </AsideRight.Content>
        <Slot name="property-editor-actions" />
      </AsideRight>
    );
  },
);

const Content = memo(
  ({
    onSave,
    onCancel,
    onChange,
    groupNames,
    ...props
  }: {
    property: ExtendedPropertyDef;
    onCancel?: () => void;
    onSave?: (property: ExtendedPropertyDef) => void;
    onChange?: () => void;
    groupNames?: string[];
  }) => {
    const showErrorOnChange = useRef(false);
    const [isAdvancedConfigVisible, setIsAdvancedConfigVisible] =
      useState(false);

    const [errors, setErrors] = useState<Record<string, ReactNode>>({});
    const [optionErrors, setOptionErrors] = useState<
      Record<string, Record<string, ReactNode>>
    >({});

    const [property, setProperty] = useState(props.property);

    const handleChange = usePersistFn((values: ExtendedOrderPropertyConfig) => {
      setProperty((x: any) => ({ ...x, ...values }) as any);
      onChange?.();
    });

    const onToggleAdvancedConfigVisibility = useCallback(() => {
      setIsAdvancedConfigVisible(x => !x);
    }, []);

    const handleSave = usePersistFn(() => {
      // validate property attributes
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const errors: Record<string, ReactNode> = {};
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const optionErrors: Record<string, Record<string, ReactNode>> = {};

      if (!property.name?.trim()) {
        errors['name'] = (
          <Translate id="store.config.error.extended_property.label_required" />
        );
      }
      if (property.type === 'select') {
        if (!property.options?.length) {
          errors['options'] = (
            <Translate id="store.config.error.extended_property.options_required" />
          );
        } else {
          for (const option of property.options) {
            if (!option.label.trim()) {
              optionErrors[option.id] = {
                ...optionErrors[option.id],
                label: (
                  <Translate id="store.config.error.extended_property.option_label_required" />
                ),
              };
            }
          }
        }
      }

      const hasErrors = Boolean(
        Object.keys(errors).length || Object.keys(optionErrors).length,
      );

      showErrorOnChange.current = hasErrors;

      setErrors(errors);
      setOptionErrors(optionErrors);

      if (hasErrors) {
        return;
      }

      onSave?.(property);

      showErrorOnChange.current = false;
    });

    const handleCancel = usePersistFn(() => {
      showErrorOnChange.current = false;
      onCancel?.();
    });

    const onAddOption = usePersistFn((e: MouseEvent) => {
      e.preventDefault();
      const updated = produce(property, draft => {
        if (
          draft.type === 'text' ||
          draft.type === 'switch' ||
          draft.type === 'datetime'
        ) {
          return;
        }
        if ('options' in draft) {
          draft.options.push({
            id: nanoid(6),
            label: '',
            defaultValue: nanoid(6),
          });
        }
      });
      setProperty(updated);
    });

    const onOptionErrorStateChange = usePersistFn(
      (optionId: string, field: string, error: ReactNode | undefined) => {
        const updated = produce(optionErrors, draft => {
          draft[optionId] = {
            ...draft[optionId],
            [field]: error,
          };
        });
        setOptionErrors(updated);
      },
    );

    const form = useMemo(() => {
      const builder =
        new EntityEditorFormBuilder<ExtendedOrderPropertyConfig>();
      builder
        .withHelpTextPlacement('after')
        .select({
          prop: 'type',
          label: 'store.config.label.extended_property.type',
          required: true,
          options: kPropTypes.map(type => ({
            value: type,
            label: getString(`store.config.prop.type.${type}`),
          })),
        })
        .text({
          prop: 'name',
          label: 'store.config.label.extended_property.label',
          placeholder: 'store.config.placeholder.extended_property.label',
          helpText: 'store.config.help_text.extended_property.label',
          error: errors['name'],
          required: true,
          onChange: changes => {
            if (!showErrorOnChange.current) return undefined;
            // eslint-disable-next-line @typescript-eslint/no-shadow
            setErrors(errors => ({
              ...errors,
              name: changes.name?.trim() ? undefined : (
                <Translate id="store.config.error.extended_property.label_required" />
              ),
            }));
            return undefined;
          },
        })
        .reactSelect({
          prop: 'group',
          label: 'store.config.label.extended_property.group',
          placeholder: 'store.config.placeholder.extended_property.group',
          helpText: 'store.config.help_text.extended_property.group',
          valueProp: 'name',
          labelProp: 'name',
          clearable: true,
          creatable: true,
          createOnBlur: true,
          isValidNewOption(inputValue) {
            return inputValue.trim().length > 0;
          },
          onGetNewOptionData: ((inputValue: string) => {
            return { name: inputValue };
          }) as any,
          values: groupNames?.map(name => ({ name })) ?? [],
        })
        .checkbox({
          prop: 'autoFill',
          label: 'store.config.label.extended_property.auto_fill',
          helpText: 'store.config.help_text.extended_property.auto_fill',
        })
        .checkbox({
          prop: 'required',
          label: 'store.config.label.extended_property.required',
        })
        .radioList<OrderFieldVisibility>({
          prop: 'includeInOrderSummary',
          label: 'store.config.order_flow.label.include_in_order_summary',
          helpText:
            'store.config.order_flow.help_text.include_in_order_summary',
          options: kOrderFieldVisibilityOptions as any,
          color: 'brand',
          solid: true,
          checkedStyle: { solid: true, color: 'brand' },
          isValueChecked: (value, v) =>
            value === v || (value === 'default' && !v),
        });

      if (property.type === 'select') {
        builder.checkbox({
          prop: 'multi',
          label: 'store.config.label.extended_property.multi',
        });
      } else if (property.type === 'switch') {
        builder.checkbox({
          prop: 'defaultOn',
          label: 'store.config.label.extended_property.default_on',
          helpText: 'store.config.help_text.extended_property.default_on',
        });
      } else if (property.type === 'datetime') {
        builder.text({
          prop: 'displayFormat',
          label: 'store.config.label.extended_property.display_format',
          placeholder: property.dateOnly ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss',
          helpText: (
            <div>
              <ReactMarkdown linkTarget={'_blank'}>
                {getString(
                  'store.config.help_text.extended_property.display_format',
                  {
                    example:
                      property.type === 'datetime' && property.displayFormat
                        ? (() => {
                            try {
                              return `${format(
                                new Date(),
                                property.displayFormat,
                              )}`;
                            } catch (e) {
                              return (
                                <span>
                                  <Translate id="store.error.extended_property.invalid_display_format" />
                                  : {e.message}
                                </span>
                              );
                            }
                          })()
                        : '',
                  },
                )}
              </ReactMarkdown>
            </div>
          ),
        });
        builder
          .checkbox({
            prop: 'dateOnly',
            label: 'store.config.label.extended_property.date_only',
            helpText: 'store.config.help_text.extended_property.date_only',
          })
          .checkbox({
            prop: 'remark',
            label: 'store.config.label.extended_property.with_remark',
          });
      }
      if (property.type === 'assets') {
        builder
          .text({
            type: 'number',
            prop: 'limit',
            label: 'store.config.label.extended_property.asset_limit',
            placeholder:
              'store.config.placeholder.extended_property.asset_limit',
          })
          .checkboxList({
            prop: 'supportedFormats',
            options: kSupportedAssetTypes,
            label:
              'store.config.label.extended_property.supported_asset_formats',
            isValueChecked(optionValue, currentValue) {
              const value =
                optionValue as unknown as ExtendedPropertySupportedAssetType;
              const values = new Set(
                (currentValue as unknown as
                  | ExtendedPropertySupportedAssetType[]
                  | undefined) ?? [],
              );
              return values.has(value);
            },
            getCheckValue(originalValue, value, checked) {
              const values = new Set(
                (originalValue as unknown as
                  | ExtendedPropertySupportedAssetType[]
                  | undefined) ?? [],
              );
              const v = value as unknown as ExtendedPropertySupportedAssetType;
              if (checked) {
                values.add(v);
              } else {
                values.delete(v);
              }
              return [...values];
            },
          });
      }

      builder
        .custom({
          label: '',
          render: () => {
            const onClick = (e: MouseEvent) => {
              e.preventDefault();
              onToggleAdvancedConfigVisibility();
            };
            return (
              <a href="#" onClick={onClick}>
                <Translate
                  id={`store.config.prop.${
                    isAdvancedConfigVisible
                      ? 'collapse_advanced'
                      : 'expand_advanced'
                  }`}
                />
                <i
                  className={
                    isAdvancedConfigVisible
                      ? 'la 	la-angle-up'
                      : 'la la-angle-down'
                  }
                  style={{ marginLeft: '0.15rem' }}
                />
              </a>
            );
          },
        })
        .text({
          prop: 'prop',
          label: 'store.config.label.extended_property.prop',
          hidden: !isAdvancedConfigVisible,
          placeholder: 'store.config.placeholder.extended_property.prop',
          helpText: 'store.config.help_text.extended_property.prop',
        })
        .text({
          prop: 'placeholder',
          label: 'store.config.label.extended_property.placeholder',
          hidden: !isAdvancedConfigVisible,
          placeholder: 'store.config.placeholder.extended_property.placeholder',
          helpText: 'store.config.help_text.extended_property.placeholder',
        })
        .text({
          prop: 'color',
          label: 'store.config.label.extended_property.color',
          hidden: !isAdvancedConfigVisible,
          placeholder: 'store.config.placeholder.extended_property.color',
          helpText: 'store.config.help_text.extended_property.color',
          wrapControl: true,
          extraContents: (entity, updateValues) => (
            <ColorDropdown
              value={entity.color}
              onChange={value =>
                updateValues({
                  color: value,
                })
              }
            />
          ),
        })
        .text({
          prop: 'description',
          label: 'store.config.label.extended_property.description',
          hidden: !isAdvancedConfigVisible,
          placeholder: 'store.config.placeholder.extended_property.description',
          helpText: 'store.config.help_text.extended_property.description',
        })
        .select({
          prop: 'visibility',
          label: 'store.config.label.extended_property.visibility',
          hidden: !isAdvancedConfigVisible,
          helpText: 'store.config.help_text.extended_property.visibility',
          options: kVisibilities.map(type => ({
            value: type,
            label: getString(`store.config.prop.visibility.${type}`),
          })),
        })
        .reactSelect({
          prop: 'updatableBy',
          multi: true,
          hidden: !isAdvancedConfigVisible,
          label: 'store.config.order_flow.label.updatable_by',
          placeholder: 'store.config.order_flow.placeholder.updatable_by',
          helpText: 'store.config.order_flow.help_text.updatable_by',
          values: OrgUserRoleTypeOptions,
          valueProp: 'value',
          labelProp: 'label',
          onFormatOptionLabel(option: Option<OrgUserRoleType>) {
            return getString(option.label);
          },
        });

      if (property.type === 'select') {
        builder
          .checkbox({
            prop: 'remark',
            hidden: !isAdvancedConfigVisible,
            label: 'store.config.label.extended_property.with_remark',
          })
          .select({
            prop: 'renderType',
            label: 'store.config.label.extended_property.render_type',
            hidden: !isAdvancedConfigVisible,
            options: kRenderTypes.map(type => ({
              value: type,
              label: getString(`store.config.prop.render_type.${type}`),
            })),
          })
          .custom({
            key: 'options',
            className: 'property-editor__options-form-group',
            label: (
              <>
                <Translate id="store.config.label.extended_property.options" />
                <a
                  href="#"
                  onClick={onAddOption}
                  style={{ marginLeft: '0.5rem' }}
                >
                  <i
                    className="fa fa-plus-square"
                    style={{
                      fontSize: '1.3rem',
                    }}
                  />
                </a>
              </>
            ),
            render: (_extra, entity) => {
              const onOptionsChange = (
                options: ExtendedOrderPropertyOption[],
              ) => {
                const updated = produce(entity, draft => {
                  draft.options = options;
                });
                handleChange(updated as any);
              };
              return (
                <>
                  <ExtendedPropertyOptions
                    options={entity.options ?? []}
                    onChange={onOptionsChange}
                    error={errors['options']}
                    optionErrors={optionErrors}
                    showErrorOnChange={showErrorOnChange.current}
                  />
                </>
              );
            },
          });
      } else if (property.type === 'text') {
        builder
          .text({
            prop: 'minLength',
            type: 'number',
            label: 'store.config.label.extended_property.min_len',
            hidden: !isAdvancedConfigVisible,
            placeholder: 'store.config.placeholder.extended_property.min_len',
            helpText: 'store.config.help_text.extended_property.min_len',
          })
          .text({
            prop: 'maxLength',
            type: 'number',
            hidden: !isAdvancedConfigVisible,
            label: 'store.config.label.extended_property.max_len',
            placeholder: 'store.config.placeholder.extended_property.max_len',
            helpText: 'store.config.help_text.extended_property.max_len',
          })
          .checkbox({
            prop: 'multiline',
            hidden: !isAdvancedConfigVisible,
            label: 'store.config.label.extended_property.multiline',
          });
      } else if (property.type === 'switch') {
        builder
          .text({
            prop: 'yesValue',
            label: 'store.config.label.extended_property.yes_value',
            hidden: !isAdvancedConfigVisible,
            placeholder: 'store.config.placeholder.extended_property.yes_value',
            helpText: 'store.config.help_text.extended_property.yes_value',
          })
          .text({
            prop: 'noValue',
            label: 'store.config.label.extended_property.no_value',
            hidden: !isAdvancedConfigVisible,
            placeholder: 'store.config.placeholder.extended_property.no_value',
            helpText: 'store.config.help_text.extended_property.no_value',
          })
          .text({
            prop: 'yesLabel',
            label: 'store.config.label.extended_property.yes_label',
            hidden: !isAdvancedConfigVisible,
            placeholder: 'store.config.placeholder.extended_property.yes_label',
            wrapControl: true,
            extraContents: (entity, updateValues) => (
              <ColorDropdown
                value={entity.yesColor}
                onChange={value =>
                  updateValues({
                    yesColor: value,
                  })
                }
              />
            ),
          })
          .text({
            prop: 'noLabel',
            label: 'store.config.label.extended_property.no_label',
            hidden: !isAdvancedConfigVisible,
            placeholder: 'store.config.placeholder.extended_property.no_label',
            wrapControl: true,
            extraContents: (entity, updateValues) => (
              <ColorDropdown
                value={entity.noColor}
                onChange={value =>
                  updateValues({
                    noColor: value,
                  })
                }
              />
            ),
          })
          .checkbox({
            prop: 'remark',
            hidden: !isAdvancedConfigVisible,
            label: 'store.config.label.extended_property.with_remark',
          });
      }
      builder.custom({
        key: 'attachments',
        hidden: !isAdvancedConfigVisible,
        className: 'property-editor__attachments-form-group',
        label: 'store.config.label.extended_property.attachments',
        helpText: 'store.config.help_text.extended_property.attachments',
        render: (_extra, entity) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const onChange = (attachments: ExtendedPropertyDefAttachment[]) => {
            const updated = produce(entity, draft => {
              draft.attachments = attachments;
            });
            handleChange(updated as any);
          };
          return (
            <>
              <ExtendedPropertyAttachments
                attachments={entity.attachments ?? []}
                onChange={onChange}
              />
            </>
          );
        },
      });
      return builder.build();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      errors,
      groupNames,
      property,
      isAdvancedConfigVisible,
      onToggleAdvancedConfigVisibility,
      onAddOption,
      optionErrors,
      handleChange,
    ]);

    return (
      <PropertyEditorContextProvider
        onOptionErrorStateChange={onOptionErrorStateChange}
      >
        <div className="settings__property-editor">
          <form className="entity-editor-form m-form">
            <div className="m-portlet__body">
              <div className="m-form__section m-form__section--first">
                {property && (
                  <EntityEditorForm
                    entity={property}
                    onChange={handleChange}
                    elements={form.elements as any}
                    autocomplete={form.autocomplete}
                    useUncontrolled={form.useUncontrolled}
                    helpTextPlacement="before"
                  />
                )}
              </div>
            </div>
          </form>
          <Fill name="aside-actions">
            <div
              style={{
                position: 'fixed',
                left: 0,
                right: 0,
                bottom: 0,
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'stretch',
                padding: '1rem',
                backgroundColor: '#fff',
              }}
            >
              <Button block color="brand" onClick={handleSave}>
                <Translate id="save_btn_text" />
              </Button>
              <Button
                block
                color="secondary"
                onClick={handleCancel}
                style={{ marginTop: '0.35rem' }}
              >
                <Translate id="cancel_btn_text" />
              </Button>
            </div>
          </Fill>
        </div>
      </PropertyEditorContextProvider>
    );
  },
);
