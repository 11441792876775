import { buildSimpleTreeModel } from 'lib/helpers';
import { ColorType } from 'lib/metronic';
import {
  InspectionSiteNodeFilter,
  VehicleInspectionSiteListFilter,
} from 'model';
import { Component } from 'react';
import {
  ListToolbar,
  ToolbarItem,
  ToolbarItemsBuilder,
} from 'shared/components';
import { InspectionSiteCategories } from '../duck/states';

interface Props {
  filter: Partial<VehicleInspectionSiteListFilter>;
  categories: InspectionSiteCategories;
  isImporting: boolean;
  activeGroupKey: string;
  activeCategoryId?: number | null;
  onFilterChange: (filter: Partial<VehicleInspectionSiteListFilter>) => void;
  onKeywordChange?: (keyword: string) => void;
  onActiveGroupChange?: (key: string, categoryId?: number | null) => void;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}

export class InspectionSiteToolbarV2 extends Component<Props> {
  render() {
    return (
      <ListToolbar
        filter={this.props.filter}
        items={this.buildToolbarItems()}
        onFilterChange={this.onFilterChange}
        onGetExtraInfo={this.onGetExtraInfo}
      />
    );
  }

  onFilterChange = (filter: Partial<VehicleInspectionSiteListFilter>) => {
    this.props.onFilterChange(filter);
  };

  onGetExtraInfo = () => this.props;

  buildToolbarItems(): Array<ToolbarItem<any>> {
    const { categories, activeGroupKey, activeCategoryId } = this.props;

    const builder = new ToolbarItemsBuilder<InspectionSiteNodeFilter>();
    const activeColor = 'brand';
    const inactiveColor = 'secondary';
    const color = (active: boolean): ColorType => {
      return active ? activeColor : inactiveColor;
    };
    const nodes = buildSimpleTreeModel(
      categories.result || [],
      x => x.id,
      x => x.parentCategoryId,
    );
    return builder
      .buttonGroup(group => {
        group.withPlacement('left');
        group.button({
          key: 'all',
          text: 'inspection_site.toolbar.button.all_categories',
          color: color(activeGroupKey === 'all'),
          onClick: () => {
            if (activeGroupKey === 'all') return;
            this.props.onActiveGroupChange &&
              this.props.onActiveGroupChange('all');
          },
        });
        group.button({
          key: 'hardware_capable',
          text: 'inspection_site.toolbar.button.hardware_capable',
          color: color(activeGroupKey === 'hardware'),
          onClick: () => {
            if (activeGroupKey === 'hardware') return;
            this.props.onActiveGroupChange &&
              this.props.onActiveGroupChange('hardware');
          },
        });
        for (const node of nodes) {
          const active =
            activeGroupKey === 'category' && activeCategoryId === node.data.id;
          group.button({
            key: String(node.data.id),
            text: node.data.name,
            color: color(active),
            onClick: () => {
              if (active) return;
              this.props.onActiveGroupChange &&
                this.props.onActiveGroupChange('category', node.data.id);
            },
          });
        }
      })
      .text({
        prop: 'keyword',
        placement: 'right',
        // label: 'inspection_site.toolbar.label.keyword',
        placeholder: 'inspection_site.toolbar.placeholder.keyword',
        immediate: true,
        onChange: this.props.onKeywordChange,
      })
      .buttonGroup(group =>
        group
          .withPlacement('right')
          .button({
            key: 'expand_all',
            text: 'inspection_site.toolbar.button.expand_all',
            color: 'secondary',
            onClick: () => {
              this.props.onExpandAll();
            },
          })
          .button({
            key: 'collapse_all',
            text: 'inspection_site.toolbar.button.collapse_all',
            color: 'secondary',
            onClick: () => {
              this.props.onCollapseAll();
            },
          }),
      )
      .build();
  }
}
