/**
 * @file: auth.service.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import { Identity, OrgUser, LoginResult, OrgUserVisibleStore } from 'model';
import { AuthProvider, AuthenticatorResult } from 'model/auth';

export class AuthService {
  constructor(private readonly api: APIService) {}

  async getIdentity(args?: { returnUrl?: string }): Promise<Identity> {
    return await this.api
      .get()
      .url('/auth/identity', Object.assign({}, args))
      .future<Identity>()
      .then(identity => {
        identity.visibleStoreSet = new Set(
          identity.visibleStores.map(x => x.storeId),
        );
        return identity;
      });
  }

  async getUserInfo(): Promise<
    OrgUser & {
      visibleStores: OrgUserVisibleStore[];
      memberId: number | undefined;
      scope: 'org' | 'store';
      acl: string[];
    }
  > {
    return await this.api.get().url('/auth/me').future();
  }

  /**
   * @deprecated use `loginV2` instead.
   */
  async login(userName: string, password: string): Promise<LoginResult> {
    return await this.api.post().url('/auth').data({ userName, password }).future();
  }

  async loginV2<T extends AuthProvider>(
    authProviderData: T,
  ): Promise<AuthenticatorResult<T>> {
    return await this.api
      .post()
      .url('/auth/login')
      .data({ data: authProviderData })
      .future();
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
    return await this.api
      .put()
      .url('/auth/password')
      .data({ oldPassword, newPassword })
      .future();
  }
}
