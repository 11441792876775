import {
  TransFunction,
  getDateRangePickerLocale,
  getDefaultInputRanges,
  getDefaultStaticRanges,
} from 'app';
import { store } from 'app/duck/store';
import classNames from 'classnames';
import { ObjectKeyType } from 'lib/duck/interfaces';
import {
  AddButton,
  Button,
  Dropdown,
  SearchButton,
} from 'lib/metronic/components';
import { Range } from 'model';
import moment from 'moment';
import {
  ChangeEvent,
  Component,
  FocusEvent,
  Fragment,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
} from 'react';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { Translate, getTranslate } from 'react-localize-redux';
import { Clear } from '../Clear';
import { Select } from '../Select';
import { StringLabel, getString } from '../StringLabel';
import {
  ToolbarItem,
  ToolbarItemButton,
  ToolbarItemButtonGroup,
  ToolbarItemCustom,
  ToolbarItemDatePicker,
  ToolbarItemDropdown,
  ToolbarItemSelect,
  ToolbarItemSeparator,
  ToolbarItemText,
  ToolbarItemType,
} from './types';

import './toolbar.scss';

export interface Props<T, TKey extends ObjectKeyType = number> {
  filter: Partial<T>;
  items?: Array<ToolbarItem<T>> | null;
  selection?: TKey[];
  onGetExtraInfo?: () => any;
  onAdd?: (extra: any) => any;
  canAdd?: (extra: any) => boolean;
  onFilterChange?: (filter: Partial<T>) => void;
}

interface AsyncState<T = any> {
  isLoading?: boolean;
  values?: T[];
}

interface State {
  asyncResults: { [stateId: string]: AsyncState };
}

export class ListToolbar<
  T,
  TKey extends ObjectKeyType = number,
> extends Component<Props<T, TKey>, State> {
  constructor(props: Props<T, TKey>) {
    super(props);
    this.state = { asyncResults: {} };
  }

  render() {
    const { items } = this.props;
    if (!items?.length) return null;

    const trans = getTranslate(store.getState().localize) as TransFunction;
    const leftItems = items.filter(x => x.placement === 'left');
    const rightItems = items.filter(x => x.placement !== 'left');
    return (
      <div className="list-toolbar d-flex align-items-center justify-content-between">
        <div className="list-toolbar__left d-flex align-items-center">
          {this.renderItems(leftItems, trans)}
        </div>
        <div className="list-toolbar__right d-flex align-items-center justify-content-end">
          {this.renderItems(rightItems, trans)}
        </div>
      </div>
    );
  }

  renderItems(items: Array<ToolbarItem<T>>, trans: TransFunction): ReactNode[] {
    return items.map((item, i) => (
      <Fragment key={i}>{this.renderItem(item, trans)}</Fragment>
    ));
  }

  renderItem(item: ToolbarItem<T>, trans: TransFunction): ReactNode | null {
    if (typeof item.hidden === 'function') {
      const extra = this.props.onGetExtraInfo?.();
      if (item.hidden(extra)) {
        return null;
      }
    } else if (item.hidden) {
      return null;
    }
    const res = (() => {
      switch (item.type) {
        case ToolbarItemType.Text:
          return this.renderTextItem(item as ToolbarItemText<T>, trans);
        case ToolbarItemType.Select:
          return this.renderSelectItem(item as ToolbarItemSelect<T>, trans);
        case ToolbarItemType.DatePicker:
          return this.renderDatePickerItem(
            item as ToolbarItemDatePicker<T>,
            trans,
          );
        case ToolbarItemType.Dropdown:
          return this.renderDropdownItem(item as ToolbarItemDropdown<T>, trans);
        case ToolbarItemType.Button:
          return this.renderButtonItem(
            item as any as ToolbarItemButton<T>,
            trans,
          );
        case ToolbarItemType.Separator:
          return this.renderSeparatorItem(
            item as ToolbarItemSeparator<T>,
            trans,
          );
        case ToolbarItemType.Custom:
          return this.renderCustomItem(item as ToolbarItemCustom<T>, trans);
        case ToolbarItemType.ButtonGroup:
          return this.renderButtonGroup(
            item as ToolbarItemButtonGroup<T>,
            trans,
          );
      }
    })();
    if (!res) return null;
    return (
      <div
        className={classNames('list-toolbar__filter', {
          'list-toolbar__filter--right': item.placement === 'right',
        })}
      >
        <label className="list-toolbar__label">
          <StringLabel value={item.label} />
        </label>
        {res}
      </div>
    );
  }

  renderTextItem(item: ToolbarItemText<T>, _trans: TransFunction): ReactNode {
    const { filter } = this.props;
    const value = filter[item.prop] as any;
    const isEmpty = !value || !String(value).trim().length;
    if (item.immediate) {
      return (
        <Clear
          visible={item.clearable !== false && !isEmpty}
          onClear={this.onClearTextField(item)}
        >
          <input
            type="text"
            className="form-control toolbar__text-field"
            placeholder={getString(item.placeholder)}
            value={value === null || value === undefined ? '' : value}
            onChange={this.onTextFieldChange(item)}
            style={{ width: item.width }}
          />
        </Clear>
      );
    }
    return (
      <Clear
        visible={item.clearable !== false && !isEmpty}
        onClear={this.onClearTextField(item)}
      >
        <input
          type="text"
          className="form-control toolbar__text-field"
          placeholder={getString(item.placeholder)}
          defaultValue={value === null || value === undefined ? '' : value}
          key={value === null || value === undefined ? '' : value}
          onBlur={this.onTextFieldBlur(item)}
          onKeyUp={this.onTextFieldKeyUp(item)}
          style={{ width: item.width }}
        />
      </Clear>
    );
  }

  renderSelectItem(
    item: ToolbarItemSelect<T>,
    _trans: TransFunction,
  ): ReactNode {
    const { filter } = this.props;
    const valueProp = item.valueProp || 'value';
    const extra = this.props.onGetExtraInfo?.();
    const value = filter[item.prop] as any;
    let values =
      typeof item.values === 'function' ? item.values(extra) : item.values;

    let isLoading = false;

    if (item.async) {
      if (!item.stateId) {
        throw new Error('toolbar async select requires stateId property');
      }
      const asyncResult = this.state.asyncResults[item.stateId];
      isLoading = (asyncResult && asyncResult.isLoading) || false;
      values = asyncResult?.values || [];
    }

    const selected = item.multi
      ? values.filter(x => value.includes(x))
      : values.find(x => x[valueProp] === value);

    return (
      <Select
        values={values}
        valueProp={item.valueProp || 'value'}
        labelProp={item.labelProp || 'label'}
        multi={item.multi}
        selectedValue={selected}
        placeholder={getString(item.placeholder)}
        width={item.width}
        isLoading={isLoading}
        isClearable={item.clearable}
        className="toolbar-select"
        onGetOptionValue={item.onGetOptionValue}
        onGetOptionLabel={item.onGetOptionLabel}
        onFormatOptionLabel={item.onFormatOptionLabel}
        onChange={this.onSelectChange(item)}
        noOptionsMessage={item.noOptionsMessage}
        async={item.async}
        defaultValues={item.defaultValues}
        onLoadValues={this.onLoadValues(item)}
        cacheValues={item.cacheValues}
      />
    );
  }

  renderDatePickerItem(
    item: ToolbarItemDatePicker<T>,
    trans: TransFunction,
  ): ReactNode {
    const value = this.props.filter[item.prop] as
      | Range<string | Date>
      | null
      | undefined;
    return (
      <Translate>
        {({ activeLanguage, translate }) => (
          <Dropdown
            buttonContents={this.renderDateRangeText(
              value,
              getString(item.placeholder),
            )}
            color="secondary"
            className="mr-3"
            dropdownProps={item.dropdown}
          >
            <DateRangePicker
              locale={getDateRangePickerLocale(activeLanguage.code)}
              dateDisplayFormat={translate('date_range.date_format') as any}
              ranges={
                value && (value.start || value.end)
                  ? [
                      {
                        startDate: value.start
                          ? moment(value.start).toDate()
                          : moment(value.end).toDate(),
                        endDate: value.end
                          ? moment(value.end).toDate()
                          : moment(value.start).toDate(),
                      },
                    ]
                  : [
                      {
                        startDate: undefined,
                        endDate: undefined,
                      },
                    ]
              }
              onChange={this.onDatePickerChange(item)}
              staticRanges={getDefaultStaticRanges(trans)}
              inputRanges={getDefaultInputRanges(trans)}
            />
          </Dropdown>
        )}
      </Translate>
    );
  }

  renderDropdownItem(
    item: ToolbarItemDropdown<T>,
    trans: TransFunction,
  ): ReactNode {
    return (
      <Dropdown
        color="secondary"
        buttonContents={trans(item.text)}
        dropdownProps={item.dropdown}
      >
        {item.render()}
      </Dropdown>
    );
  }

  renderButtonItem(
    item: ToolbarItemButton<T>,
    _trans: TransFunction,
    key?: string,
    inButtonGroup?: boolean,
  ): ReactNode {
    const extra = this.props.onGetExtraInfo?.();
    const onClick = (e: MouseEvent<any>) => {
      if (item.file) return;
      e.preventDefault();
      item.onClick && item.onClick(extra, item.context);
      if (item.buttonType === 'add' && this.props.onAdd) {
        this.props.onAdd(extra);
      }
    };
    let text: string | ReactNode = '';
    if (typeof item.text === 'function') {
      text = item.text(extra);
    } else {
      text = item.text;
    }
    let isLoading: boolean | undefined = false;
    if (typeof item.loading === 'function') {
      isLoading = item.loading(extra);
    } else {
      isLoading = item.loading;
    }
    if (item.buttonType === 'search') {
      return (
        <SearchButton
          className={classNames('toolbar__button')}
          key={key}
          label
          size="small"
          color={item.color || 'primary'}
          onClick={onClick}
          style={item.style}
          spinner={isLoading}
          disabled={
            item.shouldDisable
              ? item.shouldDisable(this.props.selection || [], extra)
              : item.disabled
          }
        >
          {typeof text === 'string' && text ? getString(text) : text}
        </SearchButton>
      );
    } else if (item.buttonType === 'add') {
      if (this.props.canAdd && !this.props.canAdd(extra)) return null;
      return (
        <AddButton
          className={classNames('toolbar__button')}
          key={key}
          size={item.size}
          onClick={onClick}
          style={item.style}
          spinner={isLoading}
          disabled={
            item.shouldDisable
              ? item.shouldDisable(this.props.selection || [], extra)
              : item.disabled
          }
        >
          {typeof text === 'string' && text ? getString(text) : text}
        </AddButton>
      );
    } else if (item.buttonType === 'refresh') {
      return (
        <Button
          className={classNames(item.cls, {
            toolbar__button: !inButtonGroup,
          })}
          iconOnly
          key={key}
          size={'small'}
          color={item.color || 'light'}
          onClick={onClick}
          style={item.style}
          disabled={
            item.shouldDisable
              ? item.shouldDisable(this.props.selection || [], extra)
              : item.disabled
          }
        >
          <i className="la la-refresh" />
        </Button>
      );
    }
    return (
      <Button
        className={classNames(item.cls, {
          'toolbar_button--file': item.file,
          toolbar__button: !inButtonGroup,
        })}
        iconOnly={item.iconOnly}
        key={key}
        size={item.size}
        color={item.color || 'brand'}
        outline={item.outline}
        onClick={onClick}
        style={item.style}
        file={item.file}
        accepts={item.accepts}
        spinner={isLoading}
        disabled={
          item.shouldDisable
            ? item.shouldDisable(this.props.selection || [], extra)
            : item.disabled
        }
        onFileChange={item.onFileChange}
      >
        {typeof text === 'string' ? <StringLabel value={text} /> : text}
      </Button>
    );
  }

  renderButtonGroup(
    item: ToolbarItemButtonGroup<T>,
    trans: TransFunction,
  ): ReactNode {
    if (!item.buttons.length) return null;
    return (
      <div
        className={classNames('btn-group', {
          'btn-group-sm': item.size === 'small',
          'btn-group-lg': item.size === 'large',
        })}
      >
        {item.buttons.map((button, i) =>
          this.renderButtonItem(button, trans, button.key || String(i), true),
        )}
      </div>
    );
  }

  renderSeparatorItem(
    item: ToolbarItemSeparator<T>,
    _trans: TransFunction,
  ): ReactNode {
    return <div className="toolbar__separator" style={item.style} />;
  }

  renderCustomItem(
    item: ToolbarItemCustom<T>,
    _trans: TransFunction,
  ): ReactNode | string | null {
    const extra = this.props.onGetExtraInfo?.();
    return item.render(this.props.filter, this.applyChanges, extra);
  }

  onClearTextField(item: ToolbarItemText<T>) {
    return () => {
      this.applyChanges(x => (x[item.prop] = undefined));
    };
  }

  onTextFieldBlur(item: ToolbarItemText<T>) {
    return (e: FocusEvent<HTMLInputElement>) => {
      const value = e.target.value.trim() as any;
      if (value !== (this.props.filter[item.prop] || '')) {
        this.applyChanges(x => (x[item.prop] = value || undefined));
      }
    };
  }

  onTextFieldChange(item: ToolbarItemText<T>) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim() as any;
      if (value !== (this.props.filter[item.prop] || '')) {
        if (item.onChange) {
          item.onChange(value);
        }
        this.applyChanges(x => (x[item.prop] = value || undefined));
      }
    };
  }

  onTextFieldKeyUp(item: ToolbarItemText<T>) {
    return (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const value = e.currentTarget.value.trim() as any;
        if (value !== (this.props.filter[item.prop] || '')) {
          this.applyChanges(x => (x[item.prop] = value || undefined));
        }
      }
    };
  }

  onSelectChange<U = { [key: string]: any }>(item: ToolbarItemSelect<T, U>) {
    return (values: U | U[]) => {
      const valueProp = item.valueProp || 'value';
      this.applyChanges(x => {
        if (item.multi) {
          x[item.prop] = (values as U[]).map(t => (t as any)[valueProp]) as any;
        } else {
          const value = values as U;
          x[item.prop] =
            !value || (value as any)[valueProp] === null
              ? undefined
              : item.array
                ? [(value as any)[valueProp]]
                : (value as any)[valueProp];
        }
      });
    };
  }

  onDatePickerChange(item: ToolbarItemDatePicker<T>) {
    return (rangesByKey: RangeKeyDict) => {
      const key = Object.keys(rangesByKey)[0];
      if (!key) {
        this.applyChanges(x => (x[item.prop] = undefined));
        return;
      }
      const range = rangesByKey[key];
      const start = moment(range.startDate).format('YYYY-MM-DD');
      const end = moment(range.endDate).format('YYYY-MM-DD');
      this.applyChanges(x => {
        x[item.prop] = {
          start,
          end,
          startInclusive: true,
          endInclusive: true,
        } as any;
      });
    };
  }

  renderDateRangeText(
    value: Range<string | Date> | null | undefined,
    placeholder?: string,
  ) {
    if (!value?.start || !value.end) {
      return placeholder || '';
    }

    const text = (
      <>
        <i
          className="flaticon-event-calendar-symbol"
          style={{ margin: '0 6px 0 0', fontSize: '0.95em' }}
        />
        <span style={{ marginRight: 6 }}>
          {moment(value.start).format('YYYY/MM/DD')}
          &nbsp;-&nbsp;
          {moment(value.end).format('YYYY/MM/DD')}
        </span>
      </>
    );

    return text;
  }

  onLoadValues(item: ToolbarItemSelect<T>) {
    return async (inputValue: string) => {
      if (!item.stateId) {
        throw new Error('toolbar async select requires stateId property');
      }
      if (!item.onLoadValues) {
        throw new Error('toolbar async select requires onLoadValues property');
      }

      const asyncResult = this.state.asyncResults[item.stateId] || {
        isLoading: false,
        selected: null,
        values: [],
      };

      const updateAsyncState = (props: Partial<AsyncState>) => {
        this.setState({
          asyncResults: {
            ...this.state.asyncResults,
            [item.stateId!]: { ...asyncResult, ...props },
          },
        });
      };

      updateAsyncState({ isLoading: true });

      try {
        const values = await item.onLoadValues(inputValue);
        updateAsyncState({ isLoading: false, values });
        return values;
      } catch (e) {
        console.error(e);
        updateAsyncState({ isLoading: false, values: [] });
      }

      return [];
    };
  }

  private readonly applyChanges = (setter: (props: Partial<T>) => void) => {
    const props: Partial<T> = {};
    setter(props);
    this.props.onFilterChange?.(props);
  };
}
