import { VehicleInspectionSiteCategory } from 'model';
import { getString, withEntityEditorSidebarBuilder } from 'shared/components';

export const CategoryEditor = withEntityEditorSidebarBuilder<VehicleInspectionSiteCategory>()
  .withI18nPrefix('inspection_site_category')
  .withForm(builder => builder.uncontrolled().text({
      prop: 'name',
      label: 'inspection_site_category.editor.label.name',
      placeholder: 'inspection_site_category.editor.placeholder.name'
    }).text({
      type: 'number',
      prop: 'sortOrder',
      label: 'inspection_site_category.editor.label.sort_order',
      placeholder: 'inspection_site_category.editor.placeholder.sort_order'
    }).textArea({
      prop: 'description',
      label: 'inspection_site_category.editor.label.description',
      placeholder: 'inspection_site_category.editor.placeholder.description'
    })
  )
  .withValidator(category => {
    if (!category.name) {
      throw new Error(
        getString('inspection_site_category.editor.error.name_required')
        );
    }
  })
  .getClass();