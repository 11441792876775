import { createAsyncActionReducers } from 'lib/duck/reducers';
import { WeixinTemplateLibraryConf } from 'model';
import { WeixinTemplateLibraryConfState } from '../states';

const initialState: WeixinTemplateLibraryConfState = {
  isLoading: false,
  error: null,
  result: null
};

export default createAsyncActionReducers<WeixinTemplateLibraryConf, WeixinTemplateLibraryConfState>(
  'integration.template_library_conf',
  initialState
);