/**
 * @file: inspection-subject.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleInspectionSubject } from 'model';
import { VehicleInspectionSubjects } from '../states';

const initialState: VehicleInspectionSubjects = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {}
};

export default createAsyncListActionReducers<VehicleInspectionSubject, VehicleInspectionSubjects>(
  'inspection.inspection_subjects',
  initialState,
  {
    mapItemKey: x => x.id
  }
);