/**
 * @file: StoreService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  Store,
  StoreListFilter,
  StoreMedia,
} from 'model';

export class StoreServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<StoreListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions ? ListResult<Store> : Store[]
  > {
    return await this.api
      .get()
      .url(
        '/stores',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<Store | null> {
    return await this.api.get().url('/stores/:id', { id }).future();
  }

  async create(store: Partial<Store>): Promise<Store> {
    return await this.api.post().url('/stores').data(store).future();
  }

  async update(store: Partial<Store>): Promise<Store> {
    return await this.api
      .put()
      .url('/stores/:id', { id: store.id })
      .data(store)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete().url('/stores/:id', { id }).future();
  }

  async getMediasByStoreId(
    id: number,
    recursive = false,
  ): Promise<StoreMedia[]> {
    return await this.api
      .get()
      .url('/stores/:id/medias', { id, recursive })
      .future();
  }

  async createStoreMedia(media: Partial<StoreMedia>): Promise<StoreMedia> {
    const id = media.storeId;
    if (!id) {
      throw new Error('media.storeId is required. ');
    }
    return await this.api.post().url('/stores/:id/medias').data(media).future();
  }

  async updateStoreMedia(media: StoreMedia): Promise<StoreMedia> {
    const id = media.storeId;
    if (!id) {
      throw new Error('media.storeId is required. ');
    }
    return await this.api.put().url('/stores/:id/medias').data(media).future();
  }

  async deleteStoreMedia(
    storeId: number | StoreMedia,
    mediaId?: number,
  ): Promise<void> {
    if (storeId && typeof storeId === 'object') {
      mediaId = storeId.id;
      if (!storeId.storeId) {
        throw new Error('storeId cannot be null or undefined');
      }
      storeId = storeId.storeId;
    }
    if (!storeId || !mediaId) {
      throw new Error('storeId and mediaId are both required');
    }
    return void await this.api
      .delete()
      .url('/stores/:storeId/medias/:mediaId', { storeId, mediaId })
      .future();
  }
}
