/**
 * @file: ProductStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ProductStatus {
  New = 'new',
  Unavailable = 'unavailable',
  Listed = 'listed',
}

export const ProductStatusValueSet = new Set([
  ProductStatus.New,
  ProductStatus.Unavailable,
  ProductStatus.Listed,
]);
