import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { InspectionTool } from 'model';
import { InspectionTools } from '../states';

const initialState: InspectionTools = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {}
};

export default createAsyncListActionReducers<InspectionTool, InspectionTools>(
  'inspection.tools',
  initialState,
  {
    mapItemKey: x => x.id
  }
);