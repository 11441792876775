const initialState: number | null | undefined = null;
import { StandardAction } from 'lib/duck/interfaces';
import { ActionTypes } from '../types';

export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: StandardAction<any>,
) {
  switch (action.type) {
    case ActionTypes.ActiveStoreChanged:
      return action.payload;
    default:
      return state;
  }
}
