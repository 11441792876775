/**
 * @file: InspectionJobProductStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionJobProductStatus, Option } from 'model';

export const InspectionJobProductStatusOptions: Array<
  Option<InspectionJobProductStatus>
> = [
  {
    value: InspectionJobProductStatus.Submitted,
    label: 'inspection_job_product_status.submitted',
  },
  {
    value: InspectionJobProductStatus.AwaitingApproval,
    label: 'inspection_job_product_status.awaiting_approval',
  },
  {
    value: InspectionJobProductStatus.Resolved,
    label: 'inspection_job_product_status.resolved',
  },
];

export const InspectionJobProductStatusOptionsWithDefault: Array<
  Option<InspectionJobProductStatus | null>
> = [
  { value: null, label: 'inspection_job_product_status.__default__' },
  {
    value: InspectionJobProductStatus.Submitted,
    label: 'inspection_job_product_status.submitted',
  },
  {
    value: InspectionJobProductStatus.AwaitingApproval,
    label: 'inspection_job_product_status.awaiting_approval',
  },
  {
    value: InspectionJobProductStatus.Resolved,
    label: 'inspection_job_product_status.resolved',
  },
];
