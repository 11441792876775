/**
 * @file: InspectionTaskEventDataTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionTaskEventDataType } from 'model';

export const InspectionTaskEventDataTypeOptions: Array<Option<InspectionTaskEventDataType>> = [
  { value: InspectionTaskEventDataType.None, label: 'inspection_task_event_data_type.none' },
  { value: InspectionTaskEventDataType.Text, label: 'inspection_task_event_data_type.text' },
  { value: InspectionTaskEventDataType.Json, label: 'inspection_task_event_data_type.json' },
  { value: InspectionTaskEventDataType.Xml, label: 'inspection_task_event_data_type.xml' },
  { value: InspectionTaskEventDataType.Plist, label: 'inspection_task_event_data_type.plist' },
  { value: InspectionTaskEventDataType.Csv, label: 'inspection_task_event_data_type.csv' },
  { value: InspectionTaskEventDataType.StringList, label: 'inspection_task_event_data_type.string_list' },
  { value: InspectionTaskEventDataType.MediaList, label: 'inspection_task_event_data_type.media_list' },
  { value: InspectionTaskEventDataType.QueryString, label: 'inspection_task_event_data_type.query_string' },
  { value: InspectionTaskEventDataType.Custom, label: 'inspection_task_event_data_type.custom' }
];

export const InspectionTaskEventDataTypeOptionsWithDefault: Array<Option<InspectionTaskEventDataType | null>> = [
  { value: null, label: 'inspection_task_event_data_type.__default__' },
  { value: InspectionTaskEventDataType.None, label: 'inspection_task_event_data_type.none' },
  { value: InspectionTaskEventDataType.Text, label: 'inspection_task_event_data_type.text' },
  { value: InspectionTaskEventDataType.Json, label: 'inspection_task_event_data_type.json' },
  { value: InspectionTaskEventDataType.Xml, label: 'inspection_task_event_data_type.xml' },
  { value: InspectionTaskEventDataType.Plist, label: 'inspection_task_event_data_type.plist' },
  { value: InspectionTaskEventDataType.Csv, label: 'inspection_task_event_data_type.csv' },
  { value: InspectionTaskEventDataType.StringList, label: 'inspection_task_event_data_type.string_list' },
  { value: InspectionTaskEventDataType.MediaList, label: 'inspection_task_event_data_type.media_list' },
  { value: InspectionTaskEventDataType.QueryString, label: 'inspection_task_event_data_type.query_string' },
  { value: InspectionTaskEventDataType.Custom, label: 'inspection_task_event_data_type.custom' }
];