/**
 * @file: subscribers.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { OrgSubscribedUser, OrgSubscribedUserListFilter } from 'model';
import { orgSubscribedUserService } from 'services';

export const subscriberActions = createListAsyncActionCreators<
  AppState,
  OrgSubscribedUser,
  OrgSubscribedUserListFilter,
  number
>('org.subscribers', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  async fetchHandler(state: AppState) {
    const orgId = state.identity!.orgId;
    // const storeId = state.activeStoreId || undefined;
    return await orgSubscribedUserService.list(
      Object.assign({}, state.org.subscribers.filter, { orgId }),
      state.org.subscribers.sorts?.[0],
      state.org.subscribers.offset,
      state.org.subscribers.limit,
      { count: true, recursive: true },
    );
  },
});
