/**
 * @file: InspectionSiteType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum InspectionSiteType {
  Default = 1,
  Facade = 2,
  Dashboard = 3,
}

export const InspectionSiteTypeValueSet = new Set([
  InspectionSiteType.Default,
  InspectionSiteType.Facade,
  InspectionSiteType.Dashboard,
]);
