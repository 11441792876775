import { Dropdown } from 'lib/metronic/components';
import React, { MouseEvent, PureComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { HtmlEditor, getString } from 'shared/components';

interface Emoji {
  name: string;
  title: string;
  pos: string;
}

const Emojis: Emoji[] = require('./emojis.yaml');
const EmojiTitleMap: Map<string, Emoji> = new Map(
  Emojis.map(x => [x.title, x]),
);
const EmojiNameMap: Map<string, Emoji> = new Map(Emojis.map(x => [x.name, x]));
const MAX_CHARS = 300;

interface Props {
  content: string;
  onChange: (content: string) => void;
}

interface State {
  defaultValue: string;
}

function text2html(content: string) {
  if (!content) return '';
  return content
    .replace(/\[.+?\]/g, value => {
      if (!EmojiTitleMap.has(value)) return value;
      const emoji = EmojiTitleMap.get(value)!;
      return `<img src="/c.gif" class="html-editor__emoji ${emoji.name}" style="background-position: ${emoji.pos}">`;
    })
    .replace(/\n/g, '<br>');
}

function html2text(html: string) {
  if (!html) return '';
  return html
    .replace(/<img.+?class="html-editor__emoji\s+(.+?)".+?>/g, (_, name) => {
      const emoji = EmojiNameMap.get(name);
      if (!emoji) return '';
      return emoji.title;
    })
    .replace(/<br.*?>/g, '\n');
}

export class TextEditor extends PureComponent<Props, State> {
  private readonly htmlEditor = React.createRef<HtmlEditor>();

  constructor(props: Props) {
    super(props);
    this.state = { defaultValue: text2html(props.content || '') };
  }
  render() {
    const { content } = this.props;
    const charsLeft = Math.max(0, MAX_CHARS - content.length);
    return (
      <div className="click-item-text-editor">
        <HtmlEditor
          ref={this.htmlEditor}
          value={this.state.defaultValue}
          onChange={this.onChange}
          onBlur={this.onHtmlEditorBlur}
          disabled={charsLeft <= 0}
          options={{
            toolbar: [],
          }}
        />
        <div className="click-item-text-editor__toolbar">
          <Dropdown
            buttonContents={<i className="la la-smile-o" />}
            showToggleButton={false}
            iconOnly
          >
            <ul className="click-item-text-editor__emojis">
              {Emojis.map(x => (
                <li key={x.name} data-name={x.name} onClick={this.onEmojiClick}>
                  <i style={{ backgroundPosition: x.pos }} />
                </li>
              ))}
            </ul>
          </Dropdown>
          <div>
            <ReactMarkdown>
              {getString('integration.menu.char_left', { charsLeft })}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    );
  }

  onChange = (content: string) => {
    content = html2text(content);
    if (content !== this.props.content) {
      this.props.onChange(content);
    }
  };

  onHtmlEditorBlur = () => {
    this.htmlEditor.current!.saveRange();
  };

  onEmojiClick = (e: MouseEvent<HTMLElement>) => {
    const name = e.currentTarget.getAttribute('data-name')!;
    const emoji = EmojiNameMap.get(name)!;
    this.htmlEditor.current!.restoreRange();
    setTimeout(() => {
      this.htmlEditor.current!.insertImage('/c.gif', (img: JQuery.Node) => {
        $(img).attr('class', `html-editor__emoji ${emoji.name}`).css({
          'background-position': emoji.pos,
        });
      });
    }, 0);
  };
}
