import { Button } from 'lib/metronic/components';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { getString } from 'shared/components';
import { randstr } from 'utils';

interface Props {
  isLoading: boolean;
  onValidationError: (message: string) => void;
  onChangePassword: (newPassword: string) => void;
}

export function ChangePasswordForm(props: Props) {
  const { onValidationError, onChangePassword, isLoading } = props;

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const onSubmit = useCallback(
    (e: React.FormEvent | React.MouseEvent) => {
      e.preventDefault();
      const password = newPassword.trim();
      const passwordConfirm = newPasswordConfirm.trim();

      let errorMsg: string = '';

      if (!password) {
        errorMsg = getString('login.error.new_password_required');
      } else if (!passwordConfirm) {
        errorMsg = getString('login.error.new_password_confirm_required');
      } else if (password !== passwordConfirm) {
        errorMsg = getString('login.error.new_password_not_match');
      }

      if (errorMsg) {
        onValidationError(errorMsg);
        return false;
      }

      onChangePassword(password);

      return false;
    },
    [newPassword, newPasswordConfirm, onChangePassword, onValidationError],
  );

  const onNewPasswordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNewPassword(e.target.value);
    },
    [],
  );

  const onNewPasswordConfirmChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNewPasswordConfirm(e.target.value);
    },
    [],
  );

  return (
    <form className="kt-form" action="" onSubmit={onSubmit}>
      <div className="input-group">
        <input
          className="form-control"
          type="password"
          placeholder={getString('login.placeholder.new_password')}
          name={randstr(16)}
          autoComplete="new-password"
          value={newPassword}
          onChange={onNewPasswordChange}
        />
      </div>
      <div className="input-group">
        <input
          className="form-control"
          type="password"
          placeholder={getString('login.placeholder.new_password_confirm')}
          name={randstr(16)}
          autoComplete="new-password"
          value={newPasswordConfirm}
          onChange={onNewPasswordConfirmChange}
        />
      </div>
      <div className="kt-login__actions">
        <Button
          color="brand"
          pill
          block
          spinner={isLoading}
          spinnerSize="lg"
          spinnerColor="light"
          disabled={isLoading}
          className="kt-login__btn-primary"
          onClick={onSubmit}
        >
          {getString('login.btn.change_password')}
        </Button>
      </div>
    </form>
  );
}
