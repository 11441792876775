// https://developers.weixin.qq.com/doc/offiaccount/Custom_Menus/Creating_Custom-Defined_Menu.html
export enum MpMenuItemType {
  Click = 'click',
  View = 'view',
  ScanCodePush = 'scancode_push',
  ScanCodeWaitMsg = 'scancode_waitmsg',
  PicSysPhoto = 'pic_sysphoto',
  PicPhotoOrAlbum = 'pic_photo_or_album',
  PicWeixin = 'pic_weixin',
  LocationSelect = 'location_select',
  MediaId = 'media_id',
  ViewLimited = 'view_limited',
  Miniprogram = 'miniprogram',
  // the following types are used by official account web only
  Text = 'text',
  Image = 'img',
  Video = 'video',
  Voice = 'voice',
  News = 'news',
}

export const MpNonAPIMenuItemTypeSet = new Set<MpMenuItemType>([
  MpMenuItemType.Text,
  MpMenuItemType.Image,
  MpMenuItemType.Video,
  MpMenuItemType.Voice,
  MpMenuItemType.News,
]);

export enum MpConditionalMenuMatchRuleGender {
  Male = 1,
  Female = 2,
}

export enum MpConditionalMenuMatchRuleClientPlatformType {
  iOS = 1,
  Android = 2,
  Others = 3,
}

export enum MpConditionalMenuMatchRuleLanguage {
  SimplifiedChinese = 'zh_CN',
  TraditionalChineseTW = 'zh_TW',
  TraditionalChineseHK = 'zh_HK',
  English = 'en',
  Indonesian = 'id',
  Malaysian = 'ms',
  Spanish = 'es',
  Korean = 'ko',
  Italian = 'it',
  Japanese = 'jp',
  Polish = 'pl',
  Portuguese = 'pt',
  Russian = 'ru',
  Thai = 'th',
  Vietnamese = 'vi',
  Arabic = 'ar',
  Hindi = 'hi',
  Hebrew = 'he',
  Turkish = 'tr',
  Germany = 'de',
  French = 'fr',
}

export interface MpConditionalMenuMatchRule {
  tagId?: number;
  sex?: MpConditionalMenuMatchRuleGender;
  clientPlatformType?: MpConditionalMenuMatchRuleClientPlatformType;
  country?: string;
  province?: string;
  city?: string;
  language?: MpConditionalMenuMatchRuleLanguage;
}

export interface MpMenuItem {
  type: MpMenuItemType;
  name: string;
  subButton?: MpMenuItem[];
  keys: { [key: string]: string };
}

export const MpKeyBasedMenuItemTypeSet: Set<MpMenuItemType> = new Set([
  MpMenuItemType.Click,
  MpMenuItemType.ScanCodePush,
  MpMenuItemType.ScanCodeWaitMsg,
  MpMenuItemType.PicSysPhoto,
  MpMenuItemType.PicPhotoOrAlbum,
  MpMenuItemType.PicWeixin,
  MpMenuItemType.LocationSelect,
]);

export interface MpKeyBasedMenuItem extends MpMenuItem {
  key: string;
}

export interface MpMenuItemClick extends MpKeyBasedMenuItem {
  __isExternal?: boolean;
}

export interface MpMenuItemView extends MpMenuItem {
  url: string;
}

export interface MpMenuItemScanCodePush extends MpKeyBasedMenuItem {}

export interface MpMenuItemScanCodeWaitMsg extends MpKeyBasedMenuItem {}

export interface MpMenuItemPicSysPhoto extends MpKeyBasedMenuItem {}

export interface MpMenuItemPicPhotoOrAlbum extends MpKeyBasedMenuItem {}

export interface MpMenuItemPicWeixin extends MpKeyBasedMenuItem {}

export interface MpMenuItemLocationSelect extends MpKeyBasedMenuItem {}

export interface MpMenuItemMediaId extends MpMenuItem {
  mediaId: string;
}

export interface MpMenuItemViewLimited extends MpMenuItem {
  mediaId: string;
}

export interface MpMenuItemMiniprogram extends MpMenuItem {
  url: string;
  appid: string;
  pagepath: string;
}

export interface MpMenuItemText extends MpMenuItem {
  value: string;
}

export interface MpMenuItemImage extends MpMenuItem {
  value: string;
}

export interface MpMenuItemVideo extends MpMenuItem {
  value: string;
}

export interface MpMenuItemVoice extends MpMenuItem {
  value: string;
}

export interface MpMenuItemNewsInfo {
  title: string;
  author: string;
  digest: string;
  showCover: number;
  coverUrl?: string;
  contentUrl: string;
  sourceUrl?: string;
}

export interface MpMenuItemNews extends MpMenuItem {
  value: string;
  newsInfo: { list: MpMenuItemNewsInfo[] };
}

export interface MpMenuInfo {
  button: MpMenuItem[];
  matchrule?: MpConditionalMenuMatchRule;
  menuid?: string;
}

export interface MpCurrentMenuInfo {
  isMenuOpen: number;
  selfmenuInfo: MpMenuInfo;
}

export interface MpMenuSettings {
  menu?: MpMenuInfo;
  conditionalmenu?: MpMenuInfo[];
}

export interface MpMenus {
  settings: MpMenuSettings;
  current?: MpCurrentMenuInfo;
  keyData: { [key: string]: any };
}
