import { APIService } from 'lib/restful-client/api.service';
import {
  ConstructionTemplateConfig,
  ConstructionTemplateConfigInfo,
  ConstructionTemplateConfigInfoWithDetail,
} from 'model';

export class ConstructionTemplateConfigService {
  constructor(private readonly api: APIService) {}

  async listConstructionTemplateConfigs(): Promise<
    ConstructionTemplateConfigInfo[]
  > {
    return await this.api
      .get()
      .url('/construction-template-config/list')
      .future();
  }

  async getConstructionTemplateConfig(
    orgId: number,
    storeId: number,
  ): Promise<ConstructionTemplateConfigInfoWithDetail | undefined> {
    return await this.api
      .get()
      .url('/construction-template-config', { orgId, storeId })
      .future();
  }

  async saveConstructionTemplateConfig(
    storeId: number,
    config: ConstructionTemplateConfig,
  ): Promise<ConstructionTemplateConfigInfoWithDetail> {
    return await this.api
      .post()
      .url('/construction-template-config')
      .data({ storeId, config })
      .future();
  }

  async duplicateConstructionTemplateConfig(
    storeId: number,
    from: {
      orgId: number;
      storeId: number;
    },
  ) {
    return await this.api
      .post()
      .url('/construction-template-config/duplicate')
      .data({ storeId, from })
      .future();
  }

  async deleteConstructionTemplateConfig(storeId: number) {
    return await this.api
      .delete()
      .url('/construction-template-config')
      .data({ storeId })
      .future();
  }

  async enableConstructionTemplateConfig(storeId: number) {
    return await this.api
      .post()
      .url('/construction-template-config/enable')
      .data({ storeId })
      .future();
  }

  async disableConstructionTemplateConfig(storeId: number) {
    return await this.api
      .post()
      .url('/construction-template-config/disable')
      .data({ storeId })
      .future();
  }
}
