import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { WeixinUserTag } from 'model';
import { WeixinUserTags } from '../states';

const initialState: WeixinUserTags = {
  isLoading: false,
  error: null,
  result: null
};

export default createAsyncListActionReducers<WeixinUserTag, WeixinUserTags, number>(
  'integration.tags',
  initialState,
  {
    mapItemKey: (tag: WeixinUserTag) => tag.id,
    pagingMode: 'normal'
  }
);