/**
 * @file: InspectionTaskJobMediaCategoryOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionTaskJobMediaCategory } from 'model';

export const InspectionTaskJobMediaCategoryOptions: Array<Option<InspectionTaskJobMediaCategory>> = [
  { value: InspectionTaskJobMediaCategory.Comparison, label: 'inspection_task_job_media_category.comparison' },
  { value: InspectionTaskJobMediaCategory.Procedure, label: 'inspection_task_job_media_category.procedure' },
  { value: InspectionTaskJobMediaCategory.BeforeConstruction, label: 'inspection_task_job_media_category.before_construction' },
  { value: InspectionTaskJobMediaCategory.AfterConstruction, label: 'inspection_task_job_media_category.after_construction' },
  { value: InspectionTaskJobMediaCategory.ProductConfirm, label: 'inspection_task_job_media_category.product_confirm' },
  { value: InspectionTaskJobMediaCategory.Custom, label: 'inspection_task_job_media_category.custom' }
];

export const InspectionTaskJobMediaCategoryOptionsWithDefault: Array<Option<InspectionTaskJobMediaCategory | null>> = [
  { value: null, label: 'inspection_task_job_media_category.__default__' },
  { value: InspectionTaskJobMediaCategory.Comparison, label: 'inspection_task_job_media_category.comparison' },
  { value: InspectionTaskJobMediaCategory.Procedure, label: 'inspection_task_job_media_category.procedure' },
  { value: InspectionTaskJobMediaCategory.BeforeConstruction, label: 'inspection_task_job_media_category.before_construction' },
  { value: InspectionTaskJobMediaCategory.AfterConstruction, label: 'inspection_task_job_media_category.after_construction' },
  { value: InspectionTaskJobMediaCategory.ProductConfirm, label: 'inspection_task_job_media_category.product_confirm' },
  { value: InspectionTaskJobMediaCategory.Custom, label: 'inspection_task_job_media_category.custom' }
];