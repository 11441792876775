/**
 * @file: groups.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { OrgGroup, OrgGroupListFilter } from 'model';
import { orgGroupService } from 'services';

export const groupActions = createListAsyncActionCreators<
  AppState,
  OrgGroup,
  OrgGroupListFilter,
  number
>('org.groups', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,

  getItemBeingCreated: (state: AppState) => state.org.groups.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) => state.org.groups.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) => state.org.groups.itemsBeingDeleted,

  async fetchHandler(state: AppState) {
    const orgId = state.identity!.orgId;
    const storeId = state.activeStoreId || undefined;
    return await orgGroupService.list(
      Object.assign({}, state.org.groups.filter, { orgId, storeId }),
      state.org.groups.sorts?.[0],
      state.org.groups.offset,
      state.org.groups.limit,
      { count: true, recursive: true },
    );
  },

  async create(group: Partial<OrgGroup>) {
    return await orgGroupService.create(group);
  },
  async update(group: OrgGroup) {
    return await orgGroupService.update(group);
  },
  async delete(group: OrgGroup) {
    await orgGroupService.delete(group.id);
  },
  async deleteMulti(groups: OrgGroup[]) {
    if (groups.length === 1) {
      await orgGroupService.delete(groups[0].id);
      return;
    }
    throw new Error('delete multi groups is not supported. ');
  },
});
