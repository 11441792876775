import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleInspectionSiteCheckItemOption } from 'model';
import { InspectionSiteItemOptions } from '../states';

const initialState: InspectionSiteItemOptions = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {}
};

export default createAsyncListActionReducers<VehicleInspectionSiteCheckItemOption, InspectionSiteItemOptions>(
  'inspection.options',
  initialState,
  {
    mapItemKey: x => x.id
  }
);