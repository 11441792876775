import {
  MaterialTypeOptions,
  QuotationMaterialInventoryStatusOptions,
  QuotationTemplateMaterialStaged,
} from 'model';
import { MouseEvent, ReactNode, memo, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import {
  EntityEditorForm,
  EntityEditorFormBuilder,
  getString,
} from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';

export const ItemMaterialEditor = memo(
  ({
    material,
    expanded,
    errors,
    showErrorOnChange,
    standalone,
    onChange,
    onRemove,
    onToggleExpand,
    onErrorStateChange,
  }: {
    material: QuotationTemplateMaterialStaged;
    errors?: Record<string, ReactNode>;
    showErrorOnChange?: boolean;
    expanded?: boolean;
    standalone?: boolean;
    onChange?: (material: QuotationTemplateMaterialStaged) => void;
    onRemove?: (material: QuotationTemplateMaterialStaged) => void;
    onToggleExpand?: (material: QuotationTemplateMaterialStaged) => void;
    onErrorStateChange?: (
      materialId: string,
      field: string,
      error: ReactNode | undefined,
    ) => void;
  }) => {
    const materialId = material.id;

    const handleChange = usePersistFn(
      (values: QuotationTemplateMaterialStaged) => {
        onChange?.({ ...material, ...values });
      },
    );

    const handleRemove = usePersistFn((e: MouseEvent) => {
      e.preventDefault();
      if (confirm(getString('quotation_tpl.material.confirm_remove'))) {
        onRemove?.(material);
      }
    });

    const form = useMemo(() => {
      const builder =
        new EntityEditorFormBuilder<QuotationTemplateMaterialStaged>();
      builder
        .text({
          prop: 'name',
          label: 'quotation_tpl.material.label.name',
          placeholder: 'quotation_tpl.material.placeholder.name',
          required: true,
          error: errors?.['name'],
          onChange: changes => {
            if (showErrorOnChange) {
              onErrorStateChange?.(
                materialId,
                'name',
                changes.name?.trim() ? undefined : (
                  <Translate id="quotation_tpl.material.error.name_required" />
                ),
              );
            }
            return undefined;
          },
        })
        .select({
          prop: 'type',
          label: 'quotation_tpl.material.label.type',
          placeholder: 'quotation_tpl.material.placeholder.type',
          options: MaterialTypeOptions,
        })
        .text({
          prop: 'brandName',
          label: 'quotation_tpl.material.label.brand_name',
          placeholder: 'quotation_tpl.material.placeholder.brand_name',
        })
        .text({
          prop: 'price',
          type: 'number',
          label: 'quotation_tpl.material.label.unit_price',
          placeholder: 'quotation_tpl.material.placeholder.unit_price',
          error: errors?.['price'],
        })
        .text({
          prop: 'discountPrice',
          type: 'number',
          hidden: !expanded,
          label: 'quotation_tpl.material.label.discount_price',
          placeholder: 'quotation_tpl.material.placeholder.discount_price',
          error: errors?.['discountPrice'],
        })
        .text({
          prop: 'discountRemark',
          hidden: !expanded,
          label: 'quotation_tpl.material.label.discount_remark',
          placeholder: 'quotation_tpl.material.placeholder.discount_remark',
        })
        .text({
          prop: 'qty',
          type: 'number',
          hidden: !expanded,
          label: 'quotation_tpl.material.label.qty',
          placeholder: 'quotation_tpl.material.placeholder.qty',
        })
        .select({
          prop: 'inventoryStatus',
          hidden: !expanded,
          label: 'quotation_tpl.material.label.inventory_status',
          placeholder: 'quotation_tpl.material.placeholder.inventory_status',
          options: QuotationMaterialInventoryStatusOptions,
        })
        .text({
          prop: 'partNumber',
          hidden: !expanded,
          label: 'quotation_tpl.material.label.part_number',
          placeholder: 'quotation_tpl.material.placeholder.part_number',
        })
        .text({
          prop: 'optionGroup',
          hidden: !expanded,
          label: 'quotation_tpl.material.label.option_group',
          placeholder: 'quotation_tpl.material.placeholder.option_group',
          helpText: 'quotation_tpl.material.help_text.option_group',
        })
        .text({
          prop: 'remark',
          hidden: !expanded,
          label: 'quotation_tpl.material.label.remark',
          placeholder: 'quotation_tpl.material.placeholder.remark',
        })
        .checkbox({
          prop: 'included',
          label: 'quotation_tpl.material.label.included',
        })
        .custom({
          label: '',
          render: () => {
            const onClick = (e: MouseEvent) => {
              e.preventDefault();
              onToggleExpand?.(material);
            };
            return (
              <a href="#" onClick={onClick}>
                <Translate
                  id={`quotation_tpl.material.more.${expanded ? 'collapse' : 'expand'}`}
                />
                <i
                  className={expanded ? 'la la-angle-up' : 'la la-angle-down'}
                  style={{ marginLeft: '0.15rem' }}
                />
              </a>
            );
          },
        });
      return builder.build();
    }, [
      errors,
      expanded,
      material,
      onErrorStateChange,
      onToggleExpand,
      materialId,
      showErrorOnChange,
    ]);

    return (
      <div className="m-form__section m-form__section--first quotation-tpl-item-editor__material-item">
        <EntityEditorForm
          entity={material}
          onChange={handleChange}
          elements={form.elements}
          autocomplete={form.autocomplete}
          useUncontrolled={form.useUncontrolled}
          helpTextPlacement="after"
        />
        {!standalone && (
          <a
            href="#"
            className="quotation-tpl-item-editor__material-item-close-button"
            onClick={handleRemove}
            style={{
              position: 'absolute',
              right: '-0.35rem',
              top: '-0.35rem',
              backgroundColor: 'red',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
              borderRadius: '50%',
              width: '1.25rem',
              height: '1.25rem',
            }}
          >
            <i className="la la-close" />
          </a>
        )}
      </div>
    );
  },
);
