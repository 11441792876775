/**
 * @file: OrgUserService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  OrgUser,
  OrgUserListFilter,
  PagedListQueryOptions,
  SortInfo,
} from 'model';

export class OrgUserServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<OrgUserListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions ? ListResult<OrgUser> : OrgUser[]
  > {
    return await this.api
      .get()
      .url(
        '/org-users',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<OrgUser | null> {
    return await this.api.get().url('/org-users/:id', { id }).future();
  }

  async getUserByUid(uid: string): Promise<OrgUser | null> {
    if (!uid) {
      throw new Error('missing uid parameter');
    }
    return await this.api.get().url('/org-users/query/uid', { uid }).future();
  }

  async getUserByMobile(mobile: string): Promise<OrgUser | null> {
    if (!mobile) {
      throw new Error('missing mobile parameter');
    }
    return await this.api
      .get()
      .url('/org-users/query/mobile', { mobile })
      .future();
  }

  async getUserByEmail(email: string): Promise<OrgUser | null> {
    if (!email) {
      throw new Error('missing email parameter');
    }
    return await this.api
      .get()
      .url('/org-users/query/email', { email })
      .future();
  }

  async create(orgUser: Partial<OrgUser>): Promise<OrgUser> {
    return await this.api.post().url('/org-users').data(orgUser).future();
  }

  async update(orgUser: Partial<OrgUser>): Promise<OrgUser> {
    return await this.api
      .put()
      .url('/org-users/:id', { id: orgUser.id })
      .data(orgUser)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete().url('/org-users/:id', { id }).future();
  }
}
