import React from 'react';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  TemplateMessageSceneTypeLabel,
} from 'shared/components';
import { OrgWeixinTemplateConf, AclObjectList } from 'model';
import { weixinTemplateConfActions } from '../duck/actions/template-confs';
import { formatDate } from 'utils';
import { MpMessageTemplates } from '../duck/states';
import { templateBeingConfigured } from '../duck/actions/templates';

interface Props extends EntityListProps<OrgWeixinTemplateConf, any, number> {
  templates: MpMessageTemplates;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  OrgWeixinTemplateConf,
  any,
  number,
  Props
>();

export const TemplateConfList = componentClassBuilder
  .i18nPrefix('integration.templates.confs')
  .listOnly()
  .selModel('none')
  .idProp('id')
  .entities(state => state.integration.templateConfs)
  .accessRights({
    full: AclObjectList.WeixinOpenIntegrationFullAccess,
    readonly: AclObjectList.WeixinOpenIntegrationReadonlyAccess,
  })
  .actions(weixinTemplateConfActions)
  .addActionButtons([
    {
      rights: [AclObjectList.WeixinOpenIntegrationFullAccess],
      key: 'edit',
      icon: 'la la-cog',
      tooltip: 'integration.templates.tooltip.edit_template_conf',
      onClick: (item, props: Props) => {
        const template =
          props.templates.result?.find(x => x.templateId === item.templateId);
        if (!template) return;
        props.dispatch(templateBeingConfigured(template));
        props.dispatch(weixinTemplateConfActions.itemBeingUpdated(item));
      },
    },
    'remove',
  ])
  .rowTag((item, props: Props) => {
    if (
      !props.templates.result ||
      props.templates.result.some(x => x.templateId === item.templateId)
    ) {
      return 'valid';
    }
    return 'invalid';
  })
  .columns([
    {
      prop: 'scene',
      width: 120,
      text: 'integration.templates.confs.col.scene',
      render: ({ scene, templateId }, props: Props) => {
        const label = <TemplateMessageSceneTypeLabel value={scene} />;
        if (
          !props.templates.result ||
          props.templates.result.some(x => x.templateId === templateId)
        ) {
          return label;
        }
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {label}&nbsp;
            <i className="fa fa-exclamation-triangle kt-font-danger" />
          </span>
        );
      },
    },
    {
      prop: 'templateId',
      width: 200,
      text: 'integration.templates.confs.col.template_id',
    },
    {
      prop: 'title',
      width: 150,
      text: 'integration.templates.confs.col.title',
      render: ({ title, templateId }, props: Props) => {
        if (
          !props.templates.result ||
          props.templates.result.some(x => x.templateId === templateId)
        ) {
          return title;
        }
        return (
          <span className="kt-font-danger">
            {title}&nbsp;
            <i className="fa fa-exclamation-triangle" />
          </span>
        );
      },
    },
    {
      prop: 'industry',
      width: 150,
      text: 'integration.templates.confs.col.industry',
      render: ({ primaryIndustry, deputyIndustry }) => (
        <div>
          {[primaryIndustry, deputyIndustry?.replace(/\//, '|')]
            .filter(x => x)
            .join('/') || '-'}
        </div>
      ),
    },
    {
      prop: 'updatedAt',
      width: 80,
      text: 'col.updated_at',
      align: 'center',
      render: ({ createdAt, updatedAt }) => formatDate(updatedAt || createdAt),
    },
  ])
  .getClass();
