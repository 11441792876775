/**
 * @file: OrgGroupService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { OrgGroupServiceBase } from './base/OrgGroupService';

export class OrgGroupService extends OrgGroupServiceBase {
  // add custom service implementation here
}
