import { quotationTplActions } from 'app/inspection/duck/actions';
import {
  QuotationTemplateConfigState,
  QuotationTemplateConfigTarget,
} from 'app/inspection/duck/states';
import { QuotationTemplateCategoryStaged } from 'model';
import {
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { getString } from 'shared/components';
import { useHasFullAccess } from 'shared/components/WithFullAccess';

type ContextProps = {
  target: QuotationTemplateConfigTarget;
  state: QuotationTemplateConfigState;
  editable: boolean;
  onAddCategory: () => void;
  onEditCategory: (category: QuotationTemplateCategoryStaged) => void;
  onCategoryNameKeyup: (e: KeyboardEvent) => void;
  onCategoryNameBlur: (e: FocusEvent) => void;
  onToggleCategory: (category: QuotationTemplateCategoryStaged) => void;
  onMoveCategory: (
    category: QuotationTemplateCategoryStaged,
    from: number,
    to: number,
  ) => void;
  onRemoveCategory: (category: QuotationTemplateCategoryStaged) => void;
  onConfirmRemoveCategory: () => void;
  onCancelRemoveCategory: () => void;
};

export const QuotationTemplateConfigContext = createContext<ContextProps>(
  null as any,
);

export const useQuotationTemplateConfigContext = () =>
  useContext(QuotationTemplateConfigContext);

export const QuotationTemplateConfigProvider = memo(
  ({
    state,
    target,
    children,
  }: {
    state: QuotationTemplateConfigState;
    target: QuotationTemplateConfigTarget;
    children?: ReactNode;
  }) => {
    const dispatch = useDispatch();
    const editable = useHasFullAccess();

    const onAddCategory = useCallback(() => {
      dispatch(quotationTplActions.addCategory());
    }, [dispatch]);

    const onEditCategory = useCallback(
      (category: QuotationTemplateCategoryStaged) => {
        if (category == null) return;
        dispatch(quotationTplActions.editCategory(category.id));
      },
      [dispatch],
    );

    const checkIsDuplicate = useCallback(
      (name: string) => {
        if (
          state.staged.categories.some(
            x =>
              x.name === name &&
              (state.categoryIdWhoseNameIsBeingEdited == null ||
                x.id !== state.categoryIdWhoseNameIsBeingEdited),
          )
        ) {
          alert(
            getString('quotation_tpl.category.error.duplicate_name', {
              name,
            }),
          );
          return true;
        }
        return false;
      },
      [state.categoryIdWhoseNameIsBeingEdited, state.staged.categories],
    );

    const onCategoryNameKeyup = useCallback(
      (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          // cancel
          dispatch(quotationTplActions.editCategoryCancelled());
        } else if (e.key === 'Enter') {
          // enter
          const el = e.target as HTMLInputElement;
          const name = el.value.trim();
          if (name.length) {
            if (checkIsDuplicate(name)) {
              return;
            }
            dispatch(quotationTplActions.editCategoryCommitted(name));
          }
        }
      },
      [checkIsDuplicate, dispatch],
    );

    const onCategoryNameBlur = useCallback(
      (e: FocusEvent) => {
        const el = e.target as HTMLInputElement;
        const name = el.value.trim();
        if (name.length) {
          if (checkIsDuplicate(name)) {
            return;
          }
          dispatch(quotationTplActions.editCategoryCommitted(name));
        } else {
          dispatch(quotationTplActions.editCategoryCancelled());
        }
      },
      [checkIsDuplicate, dispatch],
    );

    const onToggleCategory = useCallback(
      (category: QuotationTemplateCategoryStaged) => {
        dispatch(
          category.expanded
            ? quotationTplActions.collapseCategory(category.id)
            : quotationTplActions.expandCategory(category.id),
        );
      },
      [dispatch],
    );

    const onMoveCategory = useCallback(
      (category: QuotationTemplateCategoryStaged, from: number, to: number) => {
        dispatch(quotationTplActions.categoryMoved(category.id, from, to));
      },
      [dispatch],
    );

    const onRemoveCategory = useCallback(
      (category: QuotationTemplateCategoryStaged) => {
        dispatch(quotationTplActions.removeCategory(category.id));
      },
      [dispatch],
    );

    const onConfirmRemoveCategory = useCallback(() => {
      dispatch(quotationTplActions.commitRemoveCategory());
    }, [dispatch]);

    const onCancelRemoveCategory = useCallback(() => {
      dispatch(quotationTplActions.cancelRemoveCategory());
    }, [dispatch]);

    return (
      <QuotationTemplateConfigContext.Provider
        value={{
          state,
          target,
          editable,
          onAddCategory,
          onEditCategory,
          onCategoryNameKeyup,
          onCategoryNameBlur,
          onToggleCategory,
          onMoveCategory,
          onRemoveCategory,
          onConfirmRemoveCategory,
          onCancelRemoveCategory,
        }}
      >
        {children}
      </QuotationTemplateConfigContext.Provider>
    );
  },
);
