import { AreaNode, AreaTree } from 'model';
import { ChangeEvent, PureComponent, ReactNode } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';

import './AreaPicker.scss';

interface Props extends LocalizeContextProps {
  areas: AreaTree | null | undefined;
  disabled?: boolean;
  provinceId?: number;
  cityId?: number;
  districtId?: number;
  onChange: (
    provinceId: number | undefined,
    cityId: number | undefined,
    districtId: number | undefined,
  ) => void;
}

class AreaPickerComponent extends PureComponent<Props> {
  render() {
    const { provinceId, cityId, districtId, areas, translate } = this.props;

    let provinceNodes: AreaNode[] = [];
    let cityNodes: AreaNode[] = [];
    let districtNodes: AreaNode[] = [];

    if (areas) {
      provinceNodes = areas.nodes;
      if (provinceId) {
        const provinceNode = areas.getNodeById(provinceId);
        cityNodes = provinceNode.children;
        if (cityId) {
          const cityNode = areas.getNodeById(cityId);
          districtNodes = cityNode.children;
        }
      }
    }

    return (
      <div className="area-picker">
        {this.renderSelect(
          translate('areas.province_label') as any,
          provinceNodes,
          provinceId,
          this.onProvinceSelectChange,
        )}
        {this.renderSelect(
          translate('areas.city_label') as any,
          cityNodes,
          cityId,
          this.onCitySelectChange,
        )}
        {this.renderSelect(
          translate('areas.district_label') as any,
          districtNodes,
          districtId,
          this.onDistrictSelectChange,
        )}
      </div>
    );
  }

  renderSelect(
    label: string | ReactNode,
    nodes: AreaNode[] | null,
    value: number | undefined,
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void,
  ): ReactNode {
    const { disabled } = this.props;
    return (
      <select
        className="form-control"
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <option>{label}</option>
        {nodes?.map(node => (
          <option key={node.area.id} value={node.area.id}>
            {node.area.name}
          </option>
        ))}
      </select>
    );
  }

  onProvinceSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!this.props.areas) return;
    const value = Number(e.target.value) || undefined;
    this.props.onChange(value, undefined, undefined);
  };

  onCitySelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!this.props.areas) return;
    const value = Number(e.target.value) || undefined;
    const node = value ? this.props.areas.getNodeById(value) : undefined;
    this.props.onChange(node?.parent!.area.id, value, undefined);
  };

  onDistrictSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!this.props.areas) return;
    const value = Number(e.target.value) || undefined;
    const node = value ? this.props.areas.getNodeById(value) : undefined;
    this.props.onChange(
      node?.parent!.parent!.area.id,
      node?.parent!.area.id,
      value,
    );
  };
}

export const AreaPicker = withLocalize<Props>(AreaPickerComponent);
