/**
 * @file: InspectionTaskStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { Option, InspectionTaskStatus } from 'model';

export const InspectionTaskStatusOptions: Array<Option<InspectionTaskStatus>> = [
  { value: InspectionTaskStatus.Pending, label: 'inspection_task_status.pending' },
  { value: InspectionTaskStatus.InProgress, label: 'inspection_task_status.inprogress' },
  { value: InspectionTaskStatus.Finished, label: 'inspection_task_status.finished' },
  { value: InspectionTaskStatus.Cancelled, label: 'inspection_task_status.cancelled' }
];

export const InspectionTaskStatusOptionsWithDefault: Array<Option<InspectionTaskStatus | null>> = [
  { value: null, label: 'inspection_task_status.__default__' },
  { value: InspectionTaskStatus.Pending, label: 'inspection_task_status.pending' },
  { value: InspectionTaskStatus.InProgress, label: 'inspection_task_status.inprogress' },
  { value: InspectionTaskStatus.Finished, label: 'inspection_task_status.finished' },
  { value: InspectionTaskStatus.Cancelled, label: 'inspection_task_status.cancelled' }
];