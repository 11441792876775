/**
 * @file: VehicleInspectionTaskService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTask,
  VehicleInspectionTaskCheckSite,
  VehicleInspectionTaskCustomIssue,
  VehicleInspectionTaskListFilter,
  VehicleInspectionTaskTroubleCode,
} from 'model';

export class VehicleInspectionTaskServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTask>
      : VehicleInspectionTask[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-tasks',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionTask | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-tasks/:id', { id })
      .future();
  }

  async getTaskByOrderId(
    orderId: number,
  ): Promise<VehicleInspectionTask | null> {
    if (!orderId) {
      throw new Error('missing orderId parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-inspection-tasks/query/orderid', { orderId })
      .future();
  }

  async getTaskByOrderNo(
    orderNo: string,
  ): Promise<VehicleInspectionTask | null> {
    if (!orderNo) {
      throw new Error('missing orderNo parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-inspection-tasks/query/orderno', { orderNo })
      .future();
  }

  async getTaskByTaskNo(taskNo: string): Promise<VehicleInspectionTask | null> {
    if (!taskNo) {
      throw new Error('missing taskNo parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-inspection-tasks/query/taskno', { taskNo })
      .future();
  }

  async getTaskByReportNo(
    reportNo: string,
  ): Promise<VehicleInspectionTask | null> {
    if (!reportNo) {
      throw new Error('missing reportNo parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-inspection-tasks/query/reportno', { reportNo })
      .future();
  }

  async getInspectedSitesByTaskId(
    id: number,
    recursive = false,
  ): Promise<VehicleInspectionTaskCheckSite[]> {
    return await this.api
      .get()
      .url('/vehicle-inspection-tasks/:id/inspected-sites', { id, recursive })
      .future();
  }

  async getCustomIssuesByTaskId(
    id: number,
    recursive = false,
  ): Promise<VehicleInspectionTaskCustomIssue[]> {
    return await this.api
      .get()
      .url('/vehicle-inspection-tasks/:id/custom-issues', { id, recursive })
      .future();
  }

  async getTroubleCodesByTaskId(
    id: number,
    recursive = false,
  ): Promise<VehicleInspectionTaskTroubleCode[]> {
    return await this.api
      .get()
      .url('/vehicle-inspection-tasks/:id/trouble-codes', { id, recursive })
      .future();
  }
}
