/**
 * @file: DataExportTaskJobType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum DataExportTaskJobType {
  OrderDetails = 'order_details',
  StatsSummaryReport = 'stats_summary_report',
  OrderRemarks = 'order_remarks',
  ServiceAgentStats = 'sa_stats',
  OrderComments = 'order_comments',
  PreInspectionDetails = 'pre_inspection_details',
  UnfinishedOrderDetails = 'unfinished_order_details',
  ProductReferralDetails = 'product_referral_details',
}

export const DataExportTaskJobTypeValueSet = new Set([
  DataExportTaskJobType.OrderDetails,
  DataExportTaskJobType.StatsSummaryReport,
  DataExportTaskJobType.OrderRemarks,
  DataExportTaskJobType.ServiceAgentStats,
  DataExportTaskJobType.OrderComments,
  DataExportTaskJobType.PreInspectionDetails,
  DataExportTaskJobType.UnfinishedOrderDetails,
  DataExportTaskJobType.ProductReferralDetails,
]);
