/**
 * @file: ProductAuthorizationRequestEventType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ProductAuthorizationRequestEventType {
  Submitted = 'submitted',
  Updated = 'updated',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Canceled = 'canceled',
}

export const ProductAuthorizationRequestEventTypeValueSet = new Set([
  ProductAuthorizationRequestEventType.Submitted,
  ProductAuthorizationRequestEventType.Updated,
  ProductAuthorizationRequestEventType.Accepted,
  ProductAuthorizationRequestEventType.Rejected,
  ProductAuthorizationRequestEventType.Canceled,
]);
