import { Alert, Button } from 'lib/metronic/components';
import { PureComponent, ReactNode } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { getString } from '../StringLabel';

interface Props {
  i18nPrefix: string;
  isOpen?: boolean;
  loading?: boolean;
  error?: Error | null;
  yesBtnText?: string;
  cancelBtnText?: string;
  children?: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export class ConfirmModal extends PureComponent<Props> {
  render() {
    const {
      error,
      isOpen,
      i18nPrefix,
      loading,
      yesBtnText,
      cancelBtnText,
      children,
      onConfirm,
      onCancel,
    } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          <Translate id={`${i18nPrefix}.title`} />
        </ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error.message}</Alert>}
          <ReactMarkdown>{getString(`${i18nPrefix}.msg`)}</ReactMarkdown>
          {children}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel} disabled={loading}>
            <Translate id={cancelBtnText ?? 'cancel_btn_text'} />
          </Button>
          <Button
            color="primary"
            onClick={onConfirm}
            disabled={loading}
            spinner={loading}
            spinnerColor="light"
          >
            <Translate id={yesBtnText ?? 'yes_btn_text'} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
