import React, { MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { CSSProperties } from 'styled-components';
import { formatTime } from 'utils';
import { AsideRight } from 'lib/metronic/layout';
import { reportListActions, setReportListActiveReportId } from './duck/actions';
import { VehicleReport, VehicleReportFilter, AclObjectList } from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  StringLabel,
  InspectionOrderTypeLabel,
} from 'shared/components';
import { ReportDetailView } from './Detail';
import { VehicleInspectionReports } from './duck/states';
import { InspectionOrderTypeOptionsWithDefault } from 'model/EnumOptions';
import { TabNav } from 'lib/metronic/components';

import './index.scss';

interface Props
  extends EntityListProps<VehicleReport, VehicleReportFilter, string> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  VehicleReport,
  VehicleReportFilter,
  string,
  Props
>();

export const VehicleInspectionReportList = componentClassBuilder
  .i18nPrefix('reports')
  .pageIcon(
    require('!@svgr/webpack!lib/metronic/assets/icons/svg/design/pen-and-ruller.svg')
      .default,
  )
  .accessRights({
    readonly: AclObjectList.ReportReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="reports.breadcrumb.it" /> },
    { text: <Translate id="reports.breadcrumb.report_list" /> },
  ])
  .features({ addEntity: false })
  .entities(state => state.reports.reportList)
  .actions(reportListActions)
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'reportNo',
        // label: 'reports.report_list.toolbar.label.report_no',
        placeholder: 'reports.report_list.toolbar.placeholder.report_no',
        width: 210,
      })
      .select({
        prop: 'orderType',
        width: 150,
        array: true,
        clearable: true,
        // label: 'reports.report_list.toolbar.label.report_type',
        placeholder: 'reports.report_list.toolbar.placeholder.report_type',
        values: InspectionOrderTypeOptionsWithDefault.map(x => ({
          ...x,
          label: <Translate id={x.label} />,
        })),
      })
      .button({
        buttonType: 'search',
        // text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(reportListActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'reportId',
      width: 100,
      align: 'center',
      text: '',
      render: (report: VehicleReport) => {
        return (
          <img
            src={report.vehicleBrandLogo!}
            alt={report.vehicleBrandName!}
            style={{ width: 100 }}
          />
        );
      },
    },
    {
      prop: '_id',
      width: 200,
      text: 'col.report_no',
      render: (report: VehicleReport, props: Props) => {
        const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          const { dispatch } = props;
          dispatch(setReportListActiveReportId(report._id));
        };
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>{report.orgName}</div>
            <div style={style}>{report.storeName}</div>
            <div style={{ margin: '5px 0' }}>
              <a
                href="#"
                style={{ textDecoration: 'underline', color: '#222' }}
                onClick={onClick}
              >
                {report._id}
              </a>
            </div>
            <div>
              <InspectionOrderTypeLabel value={report.orderType} />
            </div>
          </div>
        );
      },
    },
    {
      prop: 'vehicleName',
      width: 250,
      text: 'col.vehicle_info',
      render: entity => {
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>
              <StringLabel value="vehicle_label.name" />
              {entity.vehicleName}
            </div>
            <div style={style}>
              <StringLabel value="vehicle_label.brand" />
              {entity.vehicleBrandName}
            </div>
            <div style={style}>
              <StringLabel value="vehicle_label.mileage" />
              {entity.vehicleMileage! < 1
                ? entity.vehicleMileage! * 10000
                : entity.vehicleMileage}
              <StringLabel value="value_unit.km" />
            </div>
          </div>
        );
      },
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 200,
      render: report => {
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>
              <StringLabel value="reports.detail.label.ordered_at" />
              {report.orderedAt ? formatTime(report.orderedAt) : '-'}
            </div>
            <div style={style}>
              <StringLabel value="reports.detail.label.created_at" />
              {formatTime(report.createdAt)}
            </div>
          </div>
        );
      },
    },
  ])
  .onRender((props: Props) => {
    const { dispatch } = props;
    const entities = props.entities as VehicleInspectionReports;
    const onClose = () => {
      dispatch(setReportListActiveReportId(undefined));
    };
    return (
      <AsideRight open={Boolean(entities.activeReportId)} onClose={onClose}>
        <AsideRight.Nav>
          <TabNav line="brand" bolder>
            <TabNav.Item active>
              <Translate id="reports.detail.title" />
            </TabNav.Item>
          </TabNav>
        </AsideRight.Nav>
        <AsideRight.Content>
          {entities.result && entities.activeReportId && (
            <ReportDetailView
              report={entities.result.find(
                x => x._id === entities.activeReportId,
              )}
            />
          )}
        </AsideRight.Content>
      </AsideRight>
    );
  })
  .componentDidUpdate((props, prevProps) => {
    if (props.activeStoreId !== prevProps.activeStoreId) {
      props.dispatch(reportListActions.invalidate(true));
    }
  })
  .getClass();
