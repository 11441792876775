import { OptionValueType, InspectionReportItem, AbnormalLevel } from 'model';
import { sprintf } from 'sprintf-js';

export type ReportResultCls = 'normal' | 'abnormal' | 'urgent';

export const ReportResultClsIcon = {
  urgent: 'fa fa-exclamation-triangle',
  abnormal: 'fa fa-exclamation-triangle',
  normal: 'fa fa-check-circle'
};

const SimpleNumericResultDataTypeSet = new Set<OptionValueType>([
  OptionValueType.Celsius,
  OptionValueType.Fahrenheit,
  OptionValueType.Kilometer,
  OptionValueType.TenThousandsKm,
  OptionValueType.Year,
  OptionValueType.Millimeter,
  OptionValueType.Centimeter,
  OptionValueType.Percentage,
  OptionValueType.Number
]);

const SimpleStringResultDataTypeSet = new Set<OptionValueType>([
  OptionValueType.String
]);

const ComplexResultDataTypeSet = new Set<OptionValueType>([
  OptionValueType.Text,
  OptionValueType.Xml,
  OptionValueType.Json,
  OptionValueType.Csv,
  OptionValueType.Yaml,
  OptionValueType.Blob,
  OptionValueType.Custom
]);

export function isComplexInspectionResultDataType(type: OptionValueType): boolean {
  return ComplexResultDataTypeSet.has(type);
}

export function isSimpleNumericInspectionResultDataType(type: OptionValueType): boolean {
  return SimpleNumericResultDataTypeSet.has(type);
}

export function isSimpleStringInspectionResultDataType(type: OptionValueType): boolean {
  return SimpleStringResultDataTypeSet.has(type);
}

export function isValidResultDataType(type: OptionValueType): boolean {
  return isComplexInspectionResultDataType(type) ||
    isSimpleNumericInspectionResultDataType(type) ||
    isSimpleStringInspectionResultDataType(type);
}

export function formatReportDetailResultValue(detail: InspectionReportItem): string {
  if (isSimpleNumericInspectionResultDataType(detail.resultDataType)) {
    return formatValue(detail.resultDataNumberValue!, `%g${valueUnitFormat(detail.resultDataUnit)}`);
  } else if (isSimpleStringInspectionResultDataType(detail.resultDataType)) {
    return detail.resultDataStringValue!;
  } else {
    return '点击查看该项目检测结果';
  }
}

function formatValue(value: number, format?: string | null) {
  if (!format) return String(value);
  return sprintf(format, value);
}

function valueUnitFormat(unit: string | undefined | null) {
  if (!unit) return '';
  if (unit === '%') return '%%';
  return unit;
}

export function formatReportDetailNormalValue(detail: InspectionReportItem): string {
  const {
    normalResultLabelFormat: labelFormat,
    normalResultLabel: label,
    normalResultLower: lower,
    normalResultLowerInclusive: lowerInclusive,
    normalResultUpper: upper,
    normalResultUpperInclusive: upperInclusive,
    normalValueUnit: valueUnit
  } = detail;

  if (!labelFormat) {
    return label || '';
  }

  const values: any[] = [];
  if (typeof lower === 'number' && typeof upper === 'number') {
    values.push(lower, upper);
    const lowerLabel = formatValue(lower, `%g${valueUnitFormat(valueUnit)}`);
    const upperLabel = formatValue(upper, `%g${valueUnitFormat(valueUnit)}`);
    return [
      lowerLabel,
      lowerInclusive ? '≤' : '<',
      '检测值',
      upperInclusive ? '≥' : '>',
      upperLabel
    ].join('');
  } else if (typeof lower === 'number') {
    values.push(lower);
    const op = lowerInclusive ? '≥' : '>';
    return `${op} ${formatValue(lower, `%g${valueUnitFormat(valueUnit)}`)}`;
  } else if (typeof upper === 'number') {
    values.push(upper);
    const op = upper ? '≤' : '<';
    return `${op} ${formatValue(upper, `%g${valueUnitFormat(valueUnit)}`)}`;
  } else {
    return sprintf(labelFormat, ...values);
  }
}

export function getReportDetailResultCls(detail: InspectionReportItem): ReportResultCls {
  if (detail.abnormalLevel === AbnormalLevel.Fine) {
    return 'normal';
  }
  if (detail.abnormalLevel !== AbnormalLevel.Urgent) {
    return 'urgent';
  }
  return 'abnormal';
}