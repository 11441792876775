export enum WeixinPermanentMaterialType {
  Image = 'image',
  Voice = 'voice',
  Video = 'video',
  Thumbnail = 'thumb',
  News = 'news',
}

export type WeixinNonNewsPermanentMaterialType = Exclude<
  WeixinPermanentMaterialType,
  WeixinPermanentMaterialType.News
>;

export interface WeixinPermanentNewsMaterialArticleInfo {
  title: string;
  thumbMediaId: string;
  thumbUrl?: string;
  author: string;
  digest: string;
  showCoverPic: boolean;
  content: string;
  url: string;
  contentSourceUrl: string;
}

export interface WeixinPermanentNewsMaterialInfo {
  newsItem: WeixinPermanentNewsMaterialArticleInfo[];
}

export interface WeixinPermanentVideoMaterialInfo {
  title: string;
  description: string;
  downUrl: string;
}

export interface WeixinPermanentMaterialListItem {
  mediaId: string;
  updateTime: number;
}

export interface WeixinPermanentNewsMaterialListItem
  extends WeixinPermanentMaterialListItem {
  content: WeixinPermanentNewsMaterialInfo;
}

export interface WeixinPermanentNonNewsMaterialListItem
  extends WeixinPermanentMaterialListItem {
  name: string;
  url?: string;
  tags?: string[];
}

export interface WeixinPermanentVideoMaterialListItem
  extends WeixinPermanentNonNewsMaterialListItem {
  coverUrl?: string;
  description?: string;
  newcat?: string;
  newsubcat?: string;

  // https://mp.weixin.qq.com/mp/readtemplate?t=pages/video_player_tmpl&vid=wxv_1318090575291252738
  vid?: string; // e.g., wxv_1318090575291252738
}

export interface WeixinPermanentMaterialListResult<
  T extends WeixinPermanentMaterialListItem,
> {
  totalCount: number;
  itemCount: number;
  items: T[];
}

export interface WeixinPermanentMaterialCreationResult {
  mediaId: string;
}

export interface WeixinPermanentImageMaterialCreationResult
  extends WeixinPermanentMaterialCreationResult {
  url: string;
}

export interface WeixinNonNewsPermanentMaterialCreationOptions {
  filename?: string;
  contentType?: string;
  title?: string;
  description?: string;
}
