/* eslint-disable @typescript-eslint/init-declarations */
import { TransFunction } from 'app';
import { AppContext } from 'app/AppContext';
import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import { InlineSvg } from 'lib/components';
import {
  $has,
  buildSimpleTreeModel,
  getItemSource,
  imgproxy,
} from 'lib/helpers';
import {
  BreadcrumbItem,
  Button,
  Column,
  DataListGroup,
  DataTable,
  Page,
  Portlet,
} from 'lib/metronic/components';
import {
  AclObjectList,
  Identity,
  VehicleInspectionSite,
  VehicleInspectionSiteCategory,
  VehicleInspectionSiteCheckItem,
  VehicleInspectionSiteCheckItemOption,
  VehicleInspectionSiteListFilter,
} from 'model';
import React, { Component, MouseEvent } from 'react';
import {
  getTranslate,
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  authenticate,
  CommonEntityListProps,
  ConfirmDeleteModal,
} from 'shared/components';
import { ItemSourceFilter } from 'shared/types';
import { arr2map, loadAsyncList } from 'utils';
import { getCategoryNodeDecendants } from '../common/helpers';
import {
  inspectionSiteActions,
  inspectionSiteCategoryActions,
  inspectionSiteItemActions,
  inspectionSiteItemOptionActions,
  inspectionToolActions,
  inventoryManagerSetNewSiteTargetCategoryId,
  inventoryManagerSourceChanged,
} from '../duck/actions';
import {
  InspectionSiteCategories,
  InspectionSiteInventoryUIState,
  InspectionSiteItemOptions,
  InspectionSiteItems,
  InspectionSites,
  InspectionTools,
} from '../duck/states';
import { ItemEditorV2, OptionEditorV2, SiteEditorV2 } from './editors-v2';
import { InventoryManagerComplement } from './ManagerComplement';
import { SiteDetail } from './SiteDetail';
import { InspectionSiteToolbarV2 } from './Toolbar-v2';

import './index.scss';

type SiteListColumn = Column<VehicleInspectionSite>;
const pageIcon =
  require('!@svgr/webpack!lib/metronic/assets/icons/svg/layout/layout-4-blocks.svg').default;

export interface InventoryManagerProps
  extends CommonEntityListProps,
    LocalizeContextProps {
  categories: InspectionSiteCategories;
  sites: InspectionSites;
  items: InspectionSiteItems;
  options: InspectionSiteItemOptions;
  uiState: InspectionSiteInventoryUIState;
  filter: VehicleInspectionSiteListFilter;
  tools: InspectionTools;
  activeGroupKey: string;
  activeCategoryId: number | null | undefined;
  activeStoreId: number | null | undefined;
}

class InventoryManagerImpl extends Component<InventoryManagerProps> {
  breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.inventory_manager" /> },
  ];

  private columns: SiteListColumn[] | undefined;

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: InventoryManagerProps) {
    const { dispatch } = this.props;
    if (this.props.activeStoreId !== prevProps.activeStoreId) {
      dispatch(inspectionSiteActions.clearSelection());
      // this.loadData(true);
    }
    if (!this.isLoading(prevProps) && this.isLoading(this.props)) {
      dispatch(showAppLoading());
    } else if (this.isLoading(prevProps) && !this.isLoading(this.props)) {
      dispatch(hideAppLoading());
    }
  }

  render() {
    const { trans, sites } = this.props;
    return (
      <Page
        title={trans('inspection_site.manager.title')}
        fullAccessRight={AclObjectList.VehicleInspectionSiteFullAccess}
        readonlyAccessRight={AclObjectList.VehicleInspectionSiteReadonlyAccess}
        error={sites.error}
        className="inventory-manager"
      >
        <Page.Header>
          <Page.Header.Main>
            <Page.Breadcrumb items={this.breadcrumbs} />
          </Page.Header.Main>
          <Page.Header.Toolbar>{this.renderToolbar()}</Page.Header.Toolbar>
        </Page.Header>
        <Page.Content>
          <Portlet mobile>
            <Portlet.Header
              size="large"
              title={trans('inspection_site.manager.title')}
              icon={pageIcon}
              iconColor="brand"
              onRefresh={this.onRefresh}
            >
              {this.renderComplement()}
            </Portlet.Header>
            <Portlet.Body>
              {this.renderDataList()}
              {this.renderSidebar()}
              {this.renderConfirmDeleteModal()}
            </Portlet.Body>
          </Portlet>
        </Page.Content>
      </Page>
    );
  }

  renderToolbar() {
    const { sites, categories, uiState, activeGroupKey, activeCategoryId } =
      this.props;
    return (
      <InspectionSiteToolbarV2
        filter={sites.filter || {}}
        activeGroupKey={activeGroupKey}
        activeCategoryId={activeCategoryId}
        categories={categories}
        isImporting={uiState.isImporting || false}
        onExpandAll={this.onExpandAll}
        onCollapseAll={this.onCollapseAll}
        onFilterChange={this.onSiteFilterChange}
        onActiveGroupChange={this.onActiveGroupChange}
      />
    );
  }

  renderComplement() {
    return (
      <InventoryManagerComplement
        source={this.props.sites.source}
        selection={this.props.sites.selection || []}
        onSourceChange={this.onSourceChange}
        onDelete={this.onDeleteSites}
      />
    );
  }

  renderDataList() {
    const { filter, sites, categories } = this.props;
    const data = this.filteredInspectionSites();
    const useGroup = Boolean(categories.result && !filter.categoryIds?.length);
    const groupListKey = this.getListGroupKey();
    const columns = this.buildColumns();
    return (
      <DataTable<VehicleInspectionSite, number>
        columns={columns}
        idProp="id"
        selModel="check"
        data={data}
        isLoading={sites.isLoading}
        minHeight={400}
        selection={sites.selection}
        enableItemDetails
        groupBy={useGroup ? this.getSiteGroupValue : undefined}
        groupListKey={groupListKey}
        expandedGroups={sites.expandedGroups?.get(groupListKey)}
        collapsedGroups={sites.collapsedGroups?.get(groupListKey)}
        onFormatGroupHeader={this.formatSiteGroupHeader}
        onRenderListGroupActions={this.renderSiteGroupActions}
        onListGroupExpand={this.onListGroupExpand}
        onListGroupCollapse={this.onListGroupCollapse}
        expandedItemDetailIds={sites.expandedDetailItemIds?.get(groupListKey)}
        collapsedItemDetailIds={sites.collpasedDetailItemIds?.get(groupListKey)}
        onItemDetailExpand={this.onSiteDetailExpand}
        onItemDetailCollapse={this.onSiteDetailCollapse}
        onRenderItemDetail={this.onRenderSiteDetail}
        onToggleAllSelection={this.onToggleAllSelection}
        onItemSelect={this.onSelectChange}
      />
    );
  }

  renderSidebar() {
    const { dispatch, sites, items, options } = this.props;
    const extra = { props: this.props };
    return (
      <>
        <SiteEditorV2
          entities={sites}
          actions={inspectionSiteActions}
          dispatch={dispatch}
          extra={extra}
        />
        <ItemEditorV2
          entities={items}
          actions={inspectionSiteItemActions}
          dispatch={dispatch}
          extra={extra}
        />
        <OptionEditorV2
          entities={options}
          actions={inspectionSiteItemOptionActions}
          dispatch={dispatch}
          extra={extra}
        />
      </>
    );
  }

  renderConfirmDeleteModal() {
    const { sites, items, options } = this.props;
    let i18nPrefix: string = '';
    let itemBeingDeleted: any;
    let isDeleting: boolean | undefined;
    let lastDeleteError: Error | undefined | null;
    let onConfirm: (() => void) | undefined;
    let onCancel: (() => void) | undefined;
    if (sites.itemsBeingDeleted?.length) {
      i18nPrefix = 'inspection_site';
      itemBeingDeleted = sites.itemsBeingDeleted[0];
      isDeleting = sites.isDeleting;
      lastDeleteError = sites.lastDeleteError;
      onConfirm = this.onConfirmDeleteSite;
      onCancel = this.onCancelDeleteSite;
    } else if (items.itemsBeingDeleted?.length) {
      i18nPrefix = 'inspection_site_item';
      itemBeingDeleted = items.itemsBeingDeleted[0];
      isDeleting = items.isDeleting;
      lastDeleteError = items.lastDeleteError;
      onConfirm = this.onConfirmDeleteItem;
      onCancel = this.onCancelDeleteItem;
    } else if (options.itemsBeingDeleted?.length) {
      i18nPrefix = 'inspection_site_item_option';
      itemBeingDeleted = options.itemsBeingDeleted[0];
      isDeleting = options.isDeleting;
      lastDeleteError = options.lastDeleteError;
      onConfirm = this.onConfirmDeleteOption;
      onCancel = this.onCancelDeleteOption;
    }
    return (
      <ConfirmDeleteModal
        localeSegment={i18nPrefix}
        isOpen={Boolean(itemBeingDeleted)}
        isDeleting={isDeleting}
        error={lastDeleteError}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    );
  }

  onSiteFilterChange = (filter: Partial<VehicleInspectionSiteListFilter>) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.updateFilter(filter));
  };

  onSourceChange = (source: ItemSourceFilter) => {
    const { dispatch } = this.props;
    dispatch(inventoryManagerSourceChanged(source));
  };

  onConfirmDeleteSite = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.commitItemsBeingDeleted());
  };

  onCancelDeleteSite = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.cancelItemsBeingDeleted());
  };

  onConfirmDeleteItem = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemActions.commitItemsBeingDeleted());
  };

  onCancelDeleteItem = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemActions.cancelItemsBeingDeleted());
  };

  onConfirmDeleteOption = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemOptionActions.commitItemsBeingDeleted());
  };

  onCancelDeleteOption = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemOptionActions.cancelItemsBeingDeleted());
  };

  onAdd = () => {
    const { dispatch } = this.props;
    let storeId: number | undefined;
    const { identity } = this.context as React.ContextType<typeof AppContext>;
    if (identity.storeId && identity.visibleStores.length === 1) {
      storeId = identity.storeId;
    }
    dispatch(inspectionSiteActions.itemBeingCreated({ storeId }));
  };

  onAddUnderGroup = (group: DataListGroup<VehicleInspectionSite>) => {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const { dispatch, categories } = this.props;

      const { identity } = this.context as React.ContextType<typeof AppContext>;
      let storeId: number | undefined;
      const orgId = identity.orgId;
      if (identity.storeId && identity.visibleStores.length === 1) {
        storeId = identity.storeId;
      }

      const categoryId = group.groupValue as number;
      const category = categories.result!.find(x => x.id === categoryId)!;
      if (!category.parentCategoryId) {
        dispatch(inventoryManagerSetNewSiteTargetCategoryId(categoryId));
        dispatch(inspectionSiteActions.itemBeingCreated({ orgId, storeId }));
      } else {
        dispatch(inventoryManagerSetNewSiteTargetCategoryId(null));
        dispatch(
          inspectionSiteActions.itemBeingCreated({
            categoryId,
            orgId,
            storeId,
          }),
        );
      }
    };
  };

  onAddItem = (site: VehicleInspectionSite) => {
    const { dispatch } = this.props;
    dispatch(
      inspectionSiteItemActions.itemBeingCreated({
        orgId: site.orgId,
        storeId: site.storeId,
        siteId: site.id,
      }),
    );
  };

  onAddOption = (
    site: VehicleInspectionSite,
    item: VehicleInspectionSiteCheckItem,
  ) => {
    const { dispatch } = this.props;
    dispatch(
      inspectionSiteItemOptionActions.itemBeingCreated({
        orgId: site.orgId,
        storeId: site.storeId,
        itemId: item.id,
      }),
    );
  };

  onRefresh = () => {
    const { dispatch, categories, sites, items, options, tools } = this.props;
    loadAsyncList(
      categories,
      () => dispatch(inspectionSiteCategoryActions.fetch()),
      true,
    );
    loadAsyncList(sites, () => dispatch(inspectionSiteActions.fetch()), true);
    loadAsyncList(
      items,
      () => dispatch(inspectionSiteItemActions.fetch()),
      true,
    );
    loadAsyncList(
      options,
      () => dispatch(inspectionSiteItemOptionActions.fetch()),
      true,
    );
    loadAsyncList(tools, () => dispatch(inspectionToolActions.fetch()), true);
  };

  onExpandAll = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.expandAllListGroup());
  };

  onCollapseAll = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.collapseAllListGroup());
  };

  onActiveGroupChange = (key: string, categoryId?: number | null) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.activeListGroupKeyChanged(key, categoryId));
  };

  onListGroupExpand = (value: any) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.expandListGroup(value));
  };

  onListGroupCollapse = (value: any) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.collapseListGroup(value));
  };

  onSiteDetailExpand = (site: VehicleInspectionSite) => {
    const { dispatch } = this.props;
    const key = this.getListGroupKey();
    dispatch(inspectionSiteActions.expandItemDetail(site, key));
  };

  onSiteDetailCollapse = (site: VehicleInspectionSite) => {
    const { dispatch } = this.props;
    const key = this.getListGroupKey();
    dispatch(inspectionSiteActions.collapseItemDetail(site, key));
  };

  onRenderSiteDetail = (site: VehicleInspectionSite) => {
    const { items, options, tools, translate } = this.props;
    return (
      <SiteDetail
        site={site}
        items={items}
        options={options}
        tools={tools}
        translate={translate}
        onAddItem={this.onAddItem}
        onAddOption={this.onAddOption}
        onEditItem={this.onEditInspectionSiteItem}
        onDeleteItem={this.onDeleteInspectionSiteItem}
        onEditOption={this.onEditInspectionSiteItemOption}
        onDeleteOption={this.onDeleteInspectionSiteItemOption}
      />
    );
  };

  onToggleAllSelection = () => {
    const { dispatch } = this.props;
    dispatch(
      inspectionSiteActions.toggleAllSelection(() => {
        return this.filteredInspectionSites() || [];
      }),
    );
  };

  onSelectChange = (site: VehicleInspectionSite, selected: boolean) => {
    const { dispatch } = this.props;
    if (selected) {
      dispatch(inspectionSiteActions.itemSelected(site));
    } else {
      dispatch(inspectionSiteActions.itemDeselected(site));
    }
  };

  onEditInspectionSite = (site: VehicleInspectionSite) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.itemBeingUpdated(site));
  };

  onDeleteInspectionSite = (site: VehicleInspectionSite) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.itemsBeingDeleted([site]));
  };

  onDeleteSites = () => {
    const { dispatch } = this.props;
    const set = new Set(this.props.sites.selection!);
    const sites = this.props.sites.result!.filter(x => set.has(x.id));
    dispatch(inspectionSiteActions.itemsBeingDeleted(sites));
  };

  onEditInspectionSiteItem = (item: VehicleInspectionSiteCheckItem) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemActions.itemBeingUpdated(item));
  };

  onDeleteInspectionSiteItem = (item: VehicleInspectionSiteCheckItem) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemActions.itemsBeingDeleted([item]));
  };

  onEditInspectionSiteItemOption = (
    option: VehicleInspectionSiteCheckItemOption,
  ) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemOptionActions.itemBeingUpdated(option));
  };

  onDeleteInspectionSiteItemOption = (
    option: VehicleInspectionSiteCheckItemOption,
  ) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemOptionActions.itemsBeingDeleted([option]));
  };

  loadData(force?: boolean) {
    const { dispatch, categories, sites, items, options, tools } = this.props;
    loadAsyncList(
      categories,
      () => dispatch(inspectionSiteCategoryActions.fetch()),
      force,
    );
    loadAsyncList(sites, () => dispatch(inspectionSiteActions.fetch()), force);
    loadAsyncList(
      items,
      () => dispatch(inspectionSiteItemActions.fetch()),
      force,
    );
    loadAsyncList(
      options,
      () => dispatch(inspectionSiteItemOptionActions.fetch()),
      force,
    );
    loadAsyncList(tools, () => dispatch(inspectionToolActions.fetch()), force);
  }

  isLoading(props: InventoryManagerProps) {
    const { categories, sites, items, options, tools } = props;
    return (
      categories.isLoading ||
      sites.isLoading ||
      items.isLoading ||
      options.isLoading ||
      tools.isLoading
    );
  }

  filteredInspectionSites() {
    const {
      sites,
      filter,
      activeGroupKey,
      activeStoreId,
      activeCategoryId,
      categories,
    } = this.props;
    const list = sites.result;
    if (!list) return list;
    const filters: Array<(site: VehicleInspectionSite) => boolean> = [];
    if (activeGroupKey === 'category') {
      const tree = buildSimpleTreeModel(
        categories.result!,
        x => x.id,
        x => x.parentCategoryId,
      );
      const node = tree.find(x => x.data.id === activeCategoryId!)!;
      const decendants = getCategoryNodeDecendants(node);
      const set = new Set(decendants.map(x => x.data.id));
      filters.push(site => {
        return set.has(site.categoryId);
      });
    } else if (activeGroupKey === 'hardware') {
      filters.push(site => site.supportsIdevice);
    }

    const keyword = filter.keyword?.trim();
    if (keyword) {
      filters.push(site => {
        return (
          site.name.includes(keyword) ||
          Boolean(site.pyInitial && site.pyInitial.includes(keyword))
        );
      });
    }

    const source = sites.source;
    if (source !== 'all') {
      filters.push(x => getItemSource(x) === source);
    }

    if (activeStoreId) {
      filters.push(x => x.storeId === activeStoreId);
    }

    if (!filters.length) return list;

    return list.filter(site => filters.every(x => x(site)));
  }

  getSiteGroupValue = (site: VehicleInspectionSite) => {
    const { categories, activeGroupKey, activeCategoryId } = this.props;
    // const list = activeGroupKey === 'category' ?
    //   categories.result!.filter(x => x.parentCategoryId === activeCategoryId!) :
    //   categories.result!.filter(x => !x.parentCategoryId);
    const map = arr2map(categories.result!, x => x.id);
    let category: VehicleInspectionSiteCategory | null | undefined =
      map[site.categoryId];
    const targetCategoryId =
      activeGroupKey === 'category' ? activeCategoryId : null;
    while (
      category &&
      (category.parentCategoryId || null) !== targetCategoryId
    ) {
      category =
        (category.parentCategoryId && map[category.parentCategoryId]) || null;
    }
    return category ? category.id : 0;
  };

  formatSiteGroupHeader = (group: DataListGroup<VehicleInspectionSite>) => {
    const { categories, activeGroupKey, activeCategoryId, trans } = this.props;
    let label: string = '';
    if (group.groupValue === 0) {
      label = trans('inspection_site.unknown_group_header_text');
    } else {
      if (activeGroupKey === 'category') {
        const children = this.props.categories.result!.filter(
          x => x.parentCategoryId === activeCategoryId!,
        );
        label = children.find(x => x.id === group.groupValue)!.name;
      } else {
        label = categories
          .result!.filter(x => !x.parentCategoryId)
          .find(x => x.id === group.groupValue)!.name;
      }
    }

    return `${label} (${group.items.length})`;
  };

  renderSiteGroupActions = (group: DataListGroup<VehicleInspectionSite>) => {
    return (
      <a href="#" onClick={this.onAddUnderGroup(group)}>
        <i className="la la-plus" />
        <Translate id="inspection_site.node_decoration.add_site" />
      </a>
    );
  };

  getListGroupKey() {
    const { sites } = this.props;
    const { activeGroupKey, activeGroupContext } = sites;
    return activeGroupKey === 'category'
      ? `${activeGroupKey}:${activeGroupContext}`
      : activeGroupKey;
  }

  buildColumns(): SiteListColumn[] {
    if (this.columns) return this.columns;
    const { trans } = this.props;
    const { identity } = this.context as React.ContextType<typeof AppContext>;
    const columns: SiteListColumn[] = [
      {
        prop: 'iconUrl',
        text: trans('inspection_site.col.icon'),
        width: 40,
        align: 'center',
        render: site => {
          if (!site.iconUrl) {
            return (
              <InlineSvg
                className="inspection-site-icon"
                src="public/img/default-site-icon.svg"
              />
            );
          }
          const url = new URL(site.iconUrl);
          if (/\.svg$/i.exec(url.pathname)) {
            return (
              <InlineSvg
                className="inspection-site-icon"
                src={imgproxy(site.iconUrl)}
              />
            );
          }
          return (
            <span className="inspection-site-icon">
              <img src={site.iconUrl} />
            </span>
          );
        },
      },
      {
        prop: 'source',
        text: trans('inspection_site.col.source'),
        width: 50,
        align: 'center',
        render: site => {
          const source = getItemSource(site);
          return <Translate id={`inspection_site.source.${source}`} />;
        },
      },
      {
        prop: 'name',
        text: trans('inspection_site.col.name'),
        width: 80,
      },
      {
        prop: 'code',
        text: trans('inspection_site.col.code'),
        width: 80,
      },
      {
        prop: 'pyInitial',
        text: trans('inspection_site.col.py_initial'),
        width: 50,
      },
      {
        prop: 'influenceFactor',
        text: trans('inspection_site.col.influence_factor'),
        width: 50,
        align: 'center',
      },
      {
        prop: 'positionCode',
        text: trans('inspection_site.col.position_code'),
        width: 60,
        align: 'center',
        render: ({ positionCode }) => positionCode || '/',
      },
      {
        prop: 'description',
        text: trans('inspection_site.col.description'),
        width: 300,
      },
      {
        prop: 'nextCheckMileageInterval',
        text: trans('inspection_site.col.next_check_mileage_interval'),
        width: 80,
        align: 'center',
        render: site => {
          return site.nextCheckMileageInterval || '/';
        },
      },
      {
        prop: 'supportsIdevice',
        text: trans('inspection_site.col.supports_idevice'),
        width: 50,
        align: 'center',
        render: site => {
          return site.supportsIdevice ? (
            <i
              className="la la-check-circle kt-font-success"
              style={{ fontWeight: 'normal', fontSize: '2rem' }}
            />
          ) : (
            '/'
          );
        },
      },
      {
        prop: 'ideviceSortOrder',
        text: trans('inspection_site.col.idevice_sort_order'),
        width: 80,
        align: 'center',
      },
    ];
    this.addActionButtons(identity, columns);
    this.columns = columns;
    return columns;
  }

  addActionButtons(identity: Identity, columns: SiteListColumn[]) {
    const fullAccessRight = AclObjectList.VehicleInspectionSiteFullAccess;
    if (!$has(identity, fullAccessRight)) {
      return;
    }
    columns.push({
      prop: 'actions',
      isActionCol: true,
      text: <Translate id="col.actions" />,
      align: 'center',
      width: 80,
      render: (site: VehicleInspectionSite) => {
        const onEdit = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          this.onEditInspectionSite(site);
        };
        const onDelete = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          this.onDeleteInspectionSite(site);
        };
        const hasPermission = Boolean(
          site.orgId &&
            ((site.storeId && identity.visibleStoreSet.has(site.storeId)) ||
              (!site.storeId && identity.userInfo.isOrgRootUser)),
        );

        return (
          <>
            <Button
              size="small"
              clean
              iconOnly
              disabled={!hasPermission}
              onClick={onEdit}
            >
              <i className="la la-edit" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              disabled={!hasPermission}
              onClick={onDelete}
            >
              <i className="la la-trash" />
            </Button>
          </>
        );
      },
    });
  }
}

function mapStateToProps(state: AppState) {
  return {
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    categories: state.inspection.categories,
    sites: state.inspection.sites,
    items: state.inspection.items,
    options: state.inspection.options,
    uiState: state.inspection.uiState,
    filter: state.inspection.sites.filter,
    tools: state.inspection.tools,
    activeGroupKey: state.inspection.sites.activeGroupKey,
    activeCategoryId: state.inspection.sites.activeGroupContext,
    activeStoreId: state.activeStoreId,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

export const InventoryManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<InventoryManagerProps, InventoryManagerImpl>(
    withLocalize<InventoryManagerProps>(InventoryManagerImpl) as any,
  ),
);
