/**
 * @file: mini-program-link-state.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncActionReducers } from 'lib/duck/reducers';
import { LinkedMiniProgramInfo } from 'model';
import { MiniProgramLinkState } from '../states';
import { ActionTypes } from '../types';

const initialState: MiniProgramLinkState = {
  result: null,
  isLoading: false,
  error: null,
};

const defaultReducer = createAsyncActionReducers<
  LinkedMiniProgramInfo | undefined,
  MiniProgramLinkState
>('integration.miniprogram', initialState);

export default function miniProgramLinkStateReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MiniProgramLinkState = initialState,
  action: StandardAction<any>,
): MiniProgramLinkState {
  switch (action.type) {
    case ActionTypes.RequestLinkMiniProgram: {
      return { ...state, isLinking: true, linkError: null };
    }
    case ActionTypes.RequestLinkMiniProgramSuccess: {
      return { ...state, isLinking: false, result: action.payload };
    }
    case ActionTypes.RequestLinkMiniProgramFailed: {
      return { ...state, isLinking: false, linkError: action.payload };
    }
    case ActionTypes.UnlinkMiniProgram: {
      return { ...state, isUnlinking: true, unlinkError: null };
    }
    case ActionTypes.UnlinkMiniProgramSuccess: {
      return { ...state, isUnlinking: false, result: undefined };
    }
    case ActionTypes.UnlinkMiniProgramFailed: {
      return { ...state, isUnlinking: false, unlinkError: action.payload };
    }
    default:
      return defaultReducer(state, action);
  }
}
