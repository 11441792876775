import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleInspectionTask } from 'model';
import { RecentInspectionTasks } from '../states';

const initialState: RecentInspectionTasks = {
  isLoading: false,
  error: null,
  result: null,
  filter: {},
  sorts: [{ property: 'startedAt', dir: 'desc' }],
  offset: 0,
  limit: 15
};

export default createAsyncListActionReducers<VehicleInspectionTask, RecentInspectionTasks>(
  'dashboard.recent_inspection_tasks',
  initialState,
  {
    mapItemKey: x => x.id
  }
);