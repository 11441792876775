import classNames from 'classnames';
import { ChangeEvent, PureComponent } from 'react';
import { getString } from 'shared/components/StringLabel';
import { randstr } from 'utils';
import { FormElementSelect } from '../types';

interface Props<T> {
  element: FormElementSelect<T>;
  options: any[] | null | undefined;
  value: any;
  autocomplete?: boolean;
  disabled?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormSelect<T> extends PureComponent<Props<T>> {
  render() {
    const { element, value, options, disabled, autocomplete } = this.props;
    const valueProp = element.valueProp || 'value';
    const labelProp = element.labelProp || 'label';

    return (
      <select
        autoComplete={
          autocomplete === false || element.autocomplete === false
            ? 'off'
            : undefined
        }
        name={
          autocomplete === false || element.autocomplete === false
            ? randstr(16)
            : undefined
        }
        className={classNames('form-control', {
          'is-invalid': element.error,
        })}
        value={value === undefined || value === null ? '' : value}
        onChange={this.onChange}
        style={{ width: element.width || '100%', ...(element.style || {}) }}
        disabled={disabled}
      >
        {element.placeholder && (
          <option value={undefined} key="___default___">
            {getString(element.placeholder)}
          </option>
        )}
        {(options || []).map(option => (
          <option value={option[valueProp]} key={option[valueProp]}>
            {getString(option[labelProp])}
          </option>
        ))}
      </select>
    );
  }

  onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { element, options, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const valueProp = element.valueProp || 'value';
    const changes: { [K in keyof T]?: T[K] } = {};
    const value = element.convertValue
      ? element.convertValue(e.target.value)
      : e.target.value;
    // tslint:disable-next-line: triple-equals
    const option = options!.find(x => x[valueProp] == value);
    changes[element.prop] = option ? (option[valueProp] as any) : undefined;
    void element.onChange?.(changes, extra);
    this.props.onChange(changes);
  };
}
