import { LinkButton } from 'app/inspection/quotation-template-config/LinkButton';
import classNames from 'classnames';
import { QuotationTemplateSubjectStaged } from 'model';
import { memo, useCallback } from 'react';

export const SubjectListItem = memo(
  ({
    subject,
    selected,
    editable,
    onRemove,
    onEdit,
    onClick,
  }: {
    subject: QuotationTemplateSubjectStaged;
    selected?: boolean;
    editable?: boolean;
    onRemove?: (subjectId: string) => void;
    onEdit?: (subjectId: string) => void;
    onClick?: (subjectId: string) => void;
  }) => {
    const onItemClick = useCallback(() => {
      onClick?.(subject.id);
    }, [onClick, subject.id]);

    const onEditClick = useCallback(() => {
      onEdit?.(subject.id);
    }, [onEdit, subject.id]);

    const onRemoveClick = useCallback(() => {
      onRemove?.(subject.id);
    }, [onRemove, subject.id]);

    return (
      <div
        className={classNames('quotation-tpl__subject-list-item', {
          'quotation-tpl__subject-list-item--selected': selected,
        })}
        onClick={onItemClick}
      >
        <i className="fa fa-tag quotation-tpl__subject-list-item-icon" />
        <div className="quotation-tpl__subject-list-item-name noselect">
          {subject.name}
        </div>
        {editable && (
          <div className="quotation-tpl__subject-list-item-actions">
            <LinkButton onClick={onEditClick}>
              <i className={`la la-edit`} />
            </LinkButton>
            <LinkButton onClick={onRemoveClick}>
              <i className={`la la-trash`} />
            </LinkButton>
          </div>
        )}
      </div>
    );
  },
);
