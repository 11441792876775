import { OrgGroup, OrgTeam } from 'model';
import { PureComponent } from 'react';
import { GroupTeamPicker } from 'shared/components/GroupTeamPicker';
import {
  EntityWithGroupTeamBounded,
  FormElementGroupTeamPicker,
} from '../types';

interface Props<T> {
  element: FormElementGroupTeamPicker<T & EntityWithGroupTeamBounded>;
  disabled?: boolean;
  orgId: number;
  storeId?: number | null;
  groupId?: number | null;
  teamId?: number | null;
  showGroupOnly?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormGroupTeamPicker<T> extends PureComponent<Props<T>> {
  render() {
    const { orgId, storeId, groupId, teamId, showGroupOnly, disabled } =
      this.props;
    return (
      <GroupTeamPicker
        orgId={orgId}
        storeId={storeId}
        groupId={groupId}
        teamId={teamId}
        showGroupOnly={showGroupOnly}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }

  onChange = (
    groupId: number | undefined,
    teamId: number | undefined,
    group: OrgGroup | null,
    team: OrgTeam | null,
  ) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: {
      [K in keyof EntityWithGroupTeamBounded]?: EntityWithGroupTeamBounded[K];
    } = {};
    changes.groupId = groupId;
    changes.teamId = teamId;
    void element.onChange?.(changes as any, extra, group, team);
    this.props.onChange(changes as T);
  };
}
