/**
 * @file: factory.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import React, { Component } from 'react';
import { ObjectKeyType } from 'lib/duck/interfaces';
import { TransFunction } from 'app';
import { AppState } from 'app/duck/states';
import { getTranslate } from 'react-localize-redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { loadAsyncState } from 'utils';
import { EntityListProps } from './types';
import { EntityList } from './list';
import { fetchAreas } from 'app/duck/actions';

export type MapStateToPropsReturnType<
  T extends object,
  TFilter,
  TKey extends ObjectKeyType,
  TProps extends EntityListProps<T, TFilter, TKey>
  > = Omit<TProps, 'trans' | 'translate' | 'dispatch' | 'identity' | '$has'>;

export function entityListComponentClassFactory<
  T extends object,
  TFilter,
  TKey extends ObjectKeyType,
  TProps extends EntityListProps<T, TFilter, TKey>
  >(
  options: {
    shouldFetchAreas?: boolean;
    mapStateToProps: (state: AppState) => MapStateToPropsReturnType<T, TFilter, TKey, TProps>;
    componentDidMount?: (state: AppState, props: TProps) => void;
    componentDidUpdate?: (props: TProps, prevProps: TProps) => void;
  }
): React.ComponentType<TProps> {
  function mapStateToProps(state: AppState, ownProps: any): Partial<TProps> {
    return {
      trans: getTranslate(state.localize) as TransFunction,
      translate: getTranslate(state.localize),
      ...options.mapStateToProps(state),
      ...ownProps,
      user: state.identity!.userInfo,
      identity: state.identity!,
      areas: state.areas,
    };
  }

  function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>): Partial<TProps> {
    return { dispatch } as any;
  }

  class EntityListComponent extends Component<TProps> {
    componentDidMount() {
      const { dispatch, entities, actions, loadStateExternally } = this.props;

      if (!loadStateExternally) {
        loadAsyncState(entities, () => dispatch(actions.fetch()));
      }

      dispatch((_, getState) => {
        const state = getState();
        options.componentDidMount && options.componentDidMount(state, this.props);

        if (options.shouldFetchAreas) {
          loadAsyncState(state.areas, () => dispatch(fetchAreas()));
        }
      });
    }

    componentDidUpdate(prevProps: TProps) {
      options.componentDidUpdate &&
        options.componentDidUpdate(this.props, prevProps);
    }

    render() {
      return (
        <EntityList<T, TFilter, TKey> {...this.props}/>
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(EntityListComponent as any);
}