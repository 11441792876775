/**
 * @file: ProductAuthorizationRequestStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ProductAuthorizationRequestStatus {
  Submitted = 'submitted',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Canceled = 'canceled',
}

export const ProductAuthorizationRequestStatusValueSet = new Set([
  ProductAuthorizationRequestStatus.Submitted,
  ProductAuthorizationRequestStatus.Accepted,
  ProductAuthorizationRequestStatus.Rejected,
  ProductAuthorizationRequestStatus.Canceled,
]);
