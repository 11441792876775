import classNames from 'classnames';
import { Alert, Button, Checkbox, CheckboxList } from 'lib/metronic/components';
import { OrgUser, OrgUserVisibleStore, Store } from 'model';
import { ChangeEvent, Component, MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { orgUserService } from 'services';
import { getString } from 'shared/components';

interface Props {
  stores: Store[];
  user: OrgUser;
  accessRights: string[];
}

interface State {
  selectedStoreId?: number | null;
  visibleStores: OrgUserVisibleStore[];
  error?: Error;
}

export class UserVisibleStoreSettings extends Component<Props, State> {
  state: State = { visibleStores: [] };

  async componentDidMount() {
    const { id } = this.props.user;
    const visibleStores = await orgUserService.getUserVisibleStores(id);
    this.setState({ visibleStores });
  }

  render() {
    const { user } = this.props;
    const { error } = this.state;

    return (
      <div className="visible-store-settings">
        <div className="visible-store-settings__tip">
          <Translate
            id="org_user.visible_stores.tip"
            data={{ user: user.userName }}
          />
        </div>
        {error && <Alert color="danger">{error.message}</Alert>}
        {this.renderStoreList()}
        {this.renderAclEditor()}
      </div>
    );
  }

  renderStoreList() {
    const { stores, user } = this.props;
    const { visibleStores, selectedStoreId } = this.state;
    return (
      <div className="visible-store-settings__store-list">
        {stores.map(store => {
          const visibleStore = visibleStores.find(x => x.storeId === store.id);
          const isOwnStore = user.storeId === store.id;
          return (
            <div
              key={store.id}
              className={classNames('visible-store-settings__store', {
                'visible-store-settings__store--selected':
                  selectedStoreId && selectedStoreId === store.id,
              })}
              onClick={this.onStoreClick(store.id)}
            >
              <div>{store.name}</div>
              <div>
                <Button
                  iconOnly
                  color={visibleStore ? 'danger' : 'success'}
                  label
                  size="small"
                  disabled={isOwnStore}
                  onClick={
                    visibleStore
                      ? this.onRemoveVisibleStore(visibleStore.storeId)
                      : this.onCreateVisibleStore(store.id)
                  }
                >
                  <i
                    className={
                      isOwnStore
                        ? 'la la-check'
                        : visibleStore
                          ? 'la la-remove'
                          : 'la la-plus'
                    }
                  />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderAclEditor() {
    const { accessRights } = this.props;
    const { selectedStoreId, visibleStores } = this.state;
    const current = visibleStores.find(x => x.storeId === selectedStoreId);
    if (!current) return null;
    const acl = current.acl || [];
    return (
      <div className="acl-editor">
        <CheckboxList
          style={{
            overflowY: 'auto',
            height: 400,
            padding: '0.5rem 0',
          }}
        >
          {accessRights.map(x => (
            <Checkbox
              key={x}
              value={x}
              solid
              color={acl.includes(x) ? 'success' : undefined}
              checked={acl.includes(x)}
              onChange={this.onAclChange(x)}
              label={x}
              style={{ fontSize: '0.9rem' }}
              controlStyle={{ top: '0px' }}
            />
          ))}
        </CheckboxList>
        <div
          className="acl-settings__actions"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: '3px solid rgba(93, 120, 255, .1)',
            padding: '1rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 8px',
              }}
            >
              <ReactMarkdown>
                {getString('org_user.acl.label.selected', {
                  selected: acl.length,
                })}
              </ReactMarkdown>
            </div>
            <Button color="info" onClick={this.onSelectAll} label>
              <Translate id="org_user.acl.label.select_all" />
            </Button>
            <Button
              color="info"
              onClick={this.onDeselectAll}
              label
              style={{ marginLeft: 4 }}
            >
              <Translate id="org_user.acl.label.deselect_all" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  onAclChange(value: string) {
    return async (e: ChangeEvent<HTMLInputElement>) => {
      const { selectedStoreId, visibleStores } = this.state;
      const current = visibleStores.find(x => x.storeId === selectedStoreId);
      if (!current) return null;
      let acl = current.acl || [];
      if (e.target.checked) {
        acl = [...acl, value];
      } else {
        acl = acl.filter(x => x !== value);
      }
      await this.updateVisibleStoreAcl(current, acl);
      return true;
    };
  }

  onSelectAll = () => {
    const current = this.getCurrentVisibleStore();
    if (!current) return;
    void this.updateVisibleStoreAcl(current, this.props.accessRights);
  };

  onDeselectAll = () => {
    const current = this.getCurrentVisibleStore();
    if (!current) return;
    void this.updateVisibleStoreAcl(current, []);
  };

  onStoreClick = (storeId: number) => {
    return (e: MouseEvent) => {
      e.preventDefault();
      if (this.state.selectedStoreId !== storeId) {
        this.setState({ selectedStoreId: storeId });
      }
    };
  };

  onCreateVisibleStore = (storeId: number) => {
    return async (e: MouseEvent) => {
      e.preventDefault();
      try {
        this.setState({ error: undefined });
        const visibleStore = await orgUserService.createVisibleStore(
          this.props.user.id,
          storeId,
          this.props.accessRights,
        );
        this.setState({
          visibleStores: [...this.state.visibleStores, visibleStore],
        });
      } catch (error) {
        this.setState({ error });
      }
    };
  };

  onRemoveVisibleStore = (storeId: number) => {
    return async (e: MouseEvent) => {
      e.preventDefault();

      try {
        this.setState({ error: undefined });
        await orgUserService.deleteVisibleStore(this.props.user.id, storeId);
        this.setState({
          visibleStores: this.state.visibleStores.filter(
            x => x.storeId !== storeId,
          ),
        });
      } catch (error) {
        this.setState({ error });
      }
    };
  };

  async updateVisibleStoreAcl(current: OrgUserVisibleStore, acl: string[]) {
    try {
      this.setState({ error: undefined });
      await orgUserService.updateVisibleStoreAcl(
        current.userId,
        current.storeId,
        acl,
      );
      const visibleStores = this.state.visibleStores.map(x =>
        x.storeId === current.storeId
          ? {
              ...x,
              acl,
            }
          : x,
      );
      this.setState({ visibleStores });
    } catch (e) {
      this.setState({ error: e });
    }
  }

  private getCurrentVisibleStore() {
    const { selectedStoreId, visibleStores } = this.state;
    const current = visibleStores.find(x => x.storeId === selectedStoreId);
    if (!current) return null;
    return current;
  }
}
