import { ReactNode, CSSProperties, ChangeEvent } from 'react';
import { FormatOptionLabelMeta } from 'react-select';
import { ObjectKeyType } from 'lib/duck/interfaces';
import { ColorType } from 'lib/metronic/types';
import { DropdownMenuProps } from 'lib/metronic/components';

export type ToolbarItemPlacement = 'left' | 'right';

export enum ToolbarItemType {
  Text,
  Select,
  DatePicker,
  Dropdown,
  Button,
  ButtonGroup,
  Separator,
  Custom,
}

export type ToolbarItemParamType<T, U extends ToolbarItem<T>> = Omit<U, 'type'>;

export interface ToolbarItem<T> {
  label?: string | ReactNode | null;
  type: ToolbarItemType;
  width?: number;
  placement?: ToolbarItemPlacement;
  prop?: keyof T;
  context?: any;
  hidden?: boolean | ((extra: any) => boolean);
}

export type ToolbarItemWithProp<T> = ToolbarItem<T> & { prop: keyof T };

export interface ToolbarItemText<T> extends ToolbarItemWithProp<T> {
  prop: keyof T;
  clearable?: boolean;
  placeholder?: string | null;
  immediate?: boolean;
  onChange?: (value: string) => void;
}

export interface ToolbarItemSelect<T, U = { [key: string]: any }>
  extends ToolbarItemWithProp<T> {
  array?: boolean;
  stateId?: string;
  values: U[] | ((extra: any) => U[]);
  valueProp?: string;
  labelProp?: string;
  multi?: boolean;
  placeholder?: string;
  clearable?: boolean;
  noOptionsMessage?: ((obj: { inputValue: string }) => string | null) | string;
  onGetOptionValue?: (option: U | any) => any;
  onGetOptionLabel?: (option: U | any) => any;
  onFormatOptionLabel?: (
    option: U | any,
    meta: FormatOptionLabelMeta<U | any>,
  ) => ReactNode;

  async?: boolean;
  defaultValues?: U[] | boolean;
  onLoadValues?: (inputValue: string) => Promise<U[]>;
  cacheValues?: any;
}

export interface ToolbarItemDatePicker<T> extends ToolbarItemWithProp<T> {
  placeholder?: string;
  icon?: string;
  dropdown?: DropdownMenuProps;
}

export interface ToolbarItemDropdown<T> extends ToolbarItem<T> {
  text: string;
  dropdown?: DropdownMenuProps;
  render: () => ReactNode;
}

export interface ToolbarItemButton<T> extends ToolbarItem<T> {
  buttonType?: 'search' | 'add' | 'refresh';
  key?: string;
  text?: ReactNode | ((extra: any) => ReactNode);
  loading?: boolean | ((extra: any) => boolean);
  file?: boolean;
  color?: ColorType;
  size?: 'small' | 'large';
  iconOnly?: boolean;
  cls?: string;
  outline?: boolean;
  style?: CSSProperties;
  accepts?: string[];
  active?: boolean | ((extra: any) => boolean);
  disabled?: boolean;
  shouldDisable?: (selection: ObjectKeyType[], extra: any) => boolean;
  onClick?: (extra: any, context: any) => void;
  onFileChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export interface ToolbarItemSeparator<T> extends ToolbarItem<T> {
  style?: CSSProperties;
}

export interface ToolbarItemCustom<T> extends ToolbarItem<T> {
  render: (
    filter: Partial<T>,
    onChange: (setter: (props: Partial<T>) => void) => void,
    extra: any,
  ) => ReactNode | string | null;
}

export interface ToolbarItemButtonGroup<T> extends ToolbarItem<T> {
  size?: 'small' | 'large';
  buttons: Array<ToolbarItemButton<T>>;
}
