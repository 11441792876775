import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { ConstructionTemplateConfigs } from '../states';
import { ConstructionTemplateConfigInfo } from 'model';
import { StandardAction } from 'lib/duck/interfaces';
import { ActionTypes } from '../types';
import { produce } from 'immer';

const initialState: ConstructionTemplateConfigs = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {},
};

const defaultReducer = createAsyncListActionReducers<
  ConstructionTemplateConfigInfo,
  ConstructionTemplateConfigs
>('inspection.construction-template-config', initialState, {
  mapItemKey: x => x.id,
});

export default function (
  state: ConstructionTemplateConfigs,
  action: StandardAction<any>,
): ConstructionTemplateConfigs {
  switch (action.type) {
    case ActionTypes.BeginConfigureConstructionTemplateConfig: {
      return {
        ...state,
        detail: action.payload,
      };
    }
    case ActionTypes.EndConfigureConstructionTemplateConfig: {
      return {
        ...state,
        detail: undefined,
      };
    }
    case ActionTypes.ConstructionTemplateConfigSelectSidebarItem: {
      return produce(state, draft => {
        if (!draft.detail) return;
        draft.detail.state.selectedSidebarItem = action.payload;
      });
    }
    case ActionTypes.ConstructionTemplateConfigChanged: {
      return produce(state, draft => {
        if (!draft.detail) return;
        draft.detail.state = action.payload;
      });
    }
    case ActionTypes.ResetConstructionTemplateConfigChanges: {
      return produce(state, draft => {
        if (!draft.detail) return;
        draft.detail.state.templateConfigBeingEdited = undefined;
        draft.detail.state.result = action.payload ?? draft.detail.state.result;
      });
    }
    case ActionTypes.SaveConstructionTemplateConfigSuccess: {
      return produce(state, draft => {
        if (!draft.detail) return;
        draft.detail.configInfo = action.payload;
      });
    }
    case ActionTypes.ToggleConstructionTemplateConfigEnabled: {
      return produce(state, draft => {
        if (!draft.detail?.configInfo) return;
        draft.detail.configInfo.enabled = action.payload;
      });
    }
    default:
      return defaultReducer(state, action);
  }
}
