import { showAppModal } from 'app/duck/actions';
import { store } from 'app/duck/store';
import { permanentMaterialActions } from 'app/integration/duck/actions';
import {
  MediaType,
  formatMediaUrl,
  getProxiedImgUrl,
} from 'app/integration/helpers';
import classNames from 'classnames';
import { Alert, Block, Button, Image } from 'lib/metronic/components';
import {
  WeixinArticleInfo,
  WeixinNewsMsg,
  WeixinPermanentMaterialListItem,
  WeixinPermanentMaterialType,
  WeixinVideoMsg,
} from 'model';
import React, { ChangeEvent, MouseEvent, PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { weixinService } from 'services';
import { getString } from 'shared/components';
import { MediaLibrary } from './MediaLibrary';

interface Props {
  mediaId?: string | null;
  data?: any;
  isLoadingData?: boolean;
  loadDataError?: Error | null;
  isTempMedia?: boolean;
  type: MediaType;
  onChange: (mediaId: string, extra: any) => void;
  onMediaSelected?: (item: WeixinPermanentMaterialListItem) => void;
}

interface State {
  showMediaLibrary: boolean;
  isUploading: boolean;
  isPlayingVideo: boolean;
}

export class MpMediaPicker extends PureComponent<Props, State> {
  private readonly audioRef = React.createRef<HTMLAudioElement>();
  private readonly playButtonRef = React.createRef<HTMLAnchorElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      showMediaLibrary: false,
      isUploading: false,
      isPlayingVideo: false,
    };
  }

  render() {
    const { mediaId, isTempMedia, type } = this.props;
    return (
      <Block active={this.state.isUploading}>
        {isTempMedia && (
          <Alert
            color="danger"
            className="mp-media-picker__temp-media-note"
            solid
          >
            <i
              className="fa fa-exclamation-triangle"
              style={{ marginRight: 8 }}
            />
            <ReactMarkdown>
              {getString(`integration.menu.replace_temp_${type}_note`)}
            </ReactMarkdown>
          </Alert>
        )}
        <div className="mp-media-picker">
          {mediaId && this.renderMedia(mediaId, type, Boolean(isTempMedia))}
          <div
            className={classNames('mp-media-picker__source-list', {
              'mp-media-picker__source-list--vertical': mediaId,
            })}
          >
            <Button
              className="mp-media-picker__source"
              color="light"
              onClick={this.onShowMediaLibrary}
            >
              <i className="fa fa-folder" />
              <Translate id="integration.menu.button.select_from_media_library" />
            </Button>
            {type === 'image' && (
              <Button
                className="mp-media-picker__source"
                file
                onFileChange={this.onFileChange}
                color="light"
              >
                <i className="fa fa-image" />
                <Translate id="integration.menu.button.upload_image" />
              </Button>
            )}
            {/* {type === 'voice' &&
            <Button className="mp-media-picker__source" file onFileChange={this.onFileChange}>
              <i className="fa fa-voicemail" />
              <Translate id="integration.menu.button.upload_voice" />
            </Button>} */}
          </div>
          <MediaLibrary
            show={this.state.showMediaLibrary}
            type={this.props.type as any}
            onCloseMediaLibrary={this.onCloseMediaLibrary}
            onMediaSelected={this.onMediaSelected}
          />
        </div>
      </Block>
    );
  }

  renderMedia(mediaId: string, type: MediaType, isTempMedia: boolean) {
    const { data, isLoadingData, loadDataError } = this.props;
    if (type === 'image') {
      return (
        <Image
          placeholderStyle={{ width: 250, height: 250 }}
          src={formatMediaUrl(mediaId, type, isTempMedia)}
          className="mp-media-picker__image"
          errorPlaceholder={
            <p className="mp-media-picker__load-error">
              <Translate id="integration.menu.error.image_load_error" />
            </p>
          }
        />
      );
    }
    if (type === 'video') {
      const coverUrl =
        data?.video?.coverUrl && getProxiedImgUrl(data.video.coverUrl);

      return (
        <div className="mp-media-picker__video">
          <div
            className="mp-media-picker__video-cover"
            style={{
              backgroundImage: coverUrl ? `url("${coverUrl}")` : undefined,
            }}
            onClick={this.onVideoCoverClick}
          >
            <i className="mp-media-picker__video-duration">00:00</i>
          </div>
          <div className="mp-media-picker__video-info">
            <div className="mp-media-picker__video-title">
              {data?.video?.name}
            </div>
            <div className="mp-media-picker__video-desc">
              {data?.video?.description}
            </div>
          </div>
          {this.renderVideoPlayer(mediaId)}
        </div>
      );
    }

    if (type === 'voice') {
      return (
        <div className="mp-media-picker__voice">
          <a
            href="#"
            className="mp-media-picker__voice-play-button"
            ref={this.playButtonRef}
            onClick={this.onPlayButtonClick}
          >
            <i />
          </a>
          <div className="mp-media-picker__voice-info">
            <div>{data?.voice?.name || mediaId}</div>
            <div>00:00</div>
          </div>
          {mediaId && (
            <audio className="mp-media-picker__audio" ref={this.audioRef} />
          )}
        </div>
      );
    }
    if (type === 'news') {
      const newsData = data as WeixinNewsMsg;
      return (
        <ReactPlaceholder
          type="media"
          ready={!isLoadingData}
          showLoadingAnimation
          rows={4}
        >
          {loadDataError || !newsData ? (
            <div>
              <Translate id="integration.menu.error.news_load_error" />
            </div>
          ) : (
            this.renderNews(newsData)
          )}
        </ReactPlaceholder>
      );
    }
    return null;
  }

  getNewsPicUrl(data: WeixinArticleInfo) {
    if (data.picUrl) return getProxiedImgUrl(data.picUrl);
    if (data.picMediaId) {
      return formatMediaUrl(
        data.picMediaId,
        WeixinPermanentMaterialType.Image,
        false,
      );
    }
    return '';
  }

  renderNews(data: WeixinNewsMsg) {
    return (
      <div className="mp-media-picker__news">
        {data.items.map((item, i) => (
          <div
            className={classNames({
              'mp-news-cover-item': i === 0,
              'mp-news-item': i > 0,
            })}
            key={item.url}
            onClick={this.onNewsClick}
            data-url={item.url}
          >
            <div className="mp-news-item__title">{item.title}</div>
            <div
              className="mp-news-item__pic"
              style={{ backgroundImage: `url("${this.getNewsPicUrl(item)}")` }}
            />
          </div>
        ))}
      </div>
    );
  }

  renderVideoPlayer(mediaId: string) {
    const data: WeixinVideoMsg | undefined = this.props.data;
    const videoUrl = formatMediaUrl(
      mediaId,
      WeixinPermanentMaterialType.Video,
      false,
    );
    const coverUrl =
      data?.video?.coverUrl && getProxiedImgUrl(data.video.coverUrl);

    return (
      <Modal
        isOpen={this.state.isPlayingVideo}
        onClosed={this.onVideoPlayerClose}
        toggle={this.onVideoPlayerClose}
        className="mp-media-picker-video-player-modal"
      >
        <ModalHeader toggle={this.onVideoPlayerClose}>
          <Translate id="integration.menu.modal.video_preview.title" />
        </ModalHeader>
        <ModalBody>
          <video
            src={videoUrl}
            controls
            width="500"
            height="281"
            poster={coverUrl}
          />
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          <Button color="secondary" wide onClick={this.onVideoPlayerClose}>
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  onShowMediaLibrary = () => {
    this.setState({ showMediaLibrary: true });
  };

  onCloseMediaLibrary = () => {
    this.setState({ showMediaLibrary: false });
  };

  onMediaSelected = async (item: WeixinPermanentMaterialListItem) => {
    this.props.onMediaSelected && this.props.onMediaSelected(item);
    this.setState({ showMediaLibrary: false });
    weixinService
      .cachePermanentMaterialInfo(item)
      .then(() => {
        console.log('material info of %s has been cached', item.mediaId);
      })
      .catch(err => {
        console.error('error cache material info of %s: ', item.mediaId, err);
      });
  };

  onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    if (this.props.type !== 'image') return;

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      this.setState({ isUploading: true });
      const res = await weixinService.createPermanentMaterial(
        WeixinPermanentMaterialType.Image,
        formData,
      );
      this.props.onChange(res.mediaId, undefined);
      store.dispatch(
        permanentMaterialActions.invalidateListByKey(
          this.props.type as any,
          true,
          undefined,
          true,
        ) as any,
      );
    } catch {
      store.dispatch(
        showAppModal(
          getString('@string/error'),
          getString('integration.menu.error.upload_error'),
        ),
      );
    } finally {
      this.setState({ isUploading: false });
    }
  };

  onNewsClick = (e: MouseEvent<HTMLElement>) => {
    const url = e.currentTarget.getAttribute('data-url');
    window.open(url!);
  };

  onPlayButtonClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const { mediaId } = this.props;

    if (!mediaId || !this.audioRef.current) return;

    const audio = this.audioRef.current;
    const playButton = this.playButtonRef.current!;
    const playingCls = 'mp-media-picker__voice-play-button--playing';

    let playing = false;

    if (!audio.src) {
      audio.src = formatMediaUrl(
        mediaId,
        WeixinPermanentMaterialType.Voice,
        false,
      );
      playing = true;

      audio.addEventListener('ended', () => {
        playButton.classList.remove(playingCls);
      });
    } else if (audio.paused) {
      playing = true;
    } else {
      audio.pause();
    }

    if (playing) {
      void audio.play();
      playing = true;
      playButton.classList.add(playingCls);
    } else {
      playButton.classList.remove(playingCls);
    }
  };

  onVideoCoverClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({ isPlayingVideo: true });
  };

  onVideoPlayerClose = () => {
    this.setState({ isPlayingVideo: false });
  };
}
