/**
 * @file: QuotationMaterialInventoryStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, QuotationMaterialInventoryStatus } from 'model';

export const QuotationMaterialInventoryStatusOptions: Array<
  Option<QuotationMaterialInventoryStatus>
> = [
  {
    value: QuotationMaterialInventoryStatus.InStock,
    label: '@string/quotation_material_inventory_status.in_stock',
  },
  {
    value: QuotationMaterialInventoryStatus.OutOfStock,
    label: '@string/quotation_material_inventory_status.out_of_stock',
  },
  {
    value: QuotationMaterialInventoryStatus.LowStock,
    label: '@string/quotation_material_inventory_status.low_stock',
  },
  {
    value: QuotationMaterialInventoryStatus.BackOrdered,
    label: '@string/quotation_material_inventory_status.back_ordered',
  },
  {
    value: QuotationMaterialInventoryStatus.Discontinued,
    label: '@string/quotation_material_inventory_status.discontinued',
  },
  {
    value: QuotationMaterialInventoryStatus.PreOrder,
    label: '@string/quotation_material_inventory_status.pre_order',
  },
  {
    value: QuotationMaterialInventoryStatus.Reserved,
    label: '@string/quotation_material_inventory_status.reserved',
  },
  {
    value: QuotationMaterialInventoryStatus.InTransit,
    label: '@string/quotation_material_inventory_status.in_transit',
  },
  {
    value: QuotationMaterialInventoryStatus.Pending,
    label: '@string/quotation_material_inventory_status.pending',
  },
];

export const QuotationMaterialInventoryStatusOptionsWithDefault: Array<
  Option<QuotationMaterialInventoryStatus | null>
> = [
  { value: null, label: 'quotation_material_inventory_status.__default__' },
  {
    value: QuotationMaterialInventoryStatus.InStock,
    label: 'quotation_material_inventory_status.in_stock',
  },
  {
    value: QuotationMaterialInventoryStatus.OutOfStock,
    label: 'quotation_material_inventory_status.out_of_stock',
  },
  {
    value: QuotationMaterialInventoryStatus.LowStock,
    label: 'quotation_material_inventory_status.low_stock',
  },
  {
    value: QuotationMaterialInventoryStatus.BackOrdered,
    label: 'quotation_material_inventory_status.back_ordered',
  },
  {
    value: QuotationMaterialInventoryStatus.Discontinued,
    label: 'quotation_material_inventory_status.discontinued',
  },
  {
    value: QuotationMaterialInventoryStatus.PreOrder,
    label: 'quotation_material_inventory_status.pre_order',
  },
  {
    value: QuotationMaterialInventoryStatus.Reserved,
    label: 'quotation_material_inventory_status.reserved',
  },
  {
    value: QuotationMaterialInventoryStatus.InTransit,
    label: 'quotation_material_inventory_status.in_transit',
  },
  {
    value: QuotationMaterialInventoryStatus.Pending,
    label: 'quotation_material_inventory_status.pending',
  },
];
