type EventHandlerType<K extends keyof HTMLElementEventMap> = (
  this: HTMLElement,
  ev: HTMLElementEventMap[K],
) => any;

export function addEventListenerOnce(
  el: HTMLElement,
  type: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions,
): void;
export function addEventListenerOnce<K extends keyof HTMLElementEventMap>(
  el: HTMLElement,
  type: K,
  listener: EventHandlerType<K>,
  options?: boolean | AddEventListenerOptions,
): void {
  const callback: EventHandlerType<K> = function (e) {
    if (e.target?.removeEventListener) {
      e.target.removeEventListener(e.type, callback);
    }
    // eslint-disable-next-line @typescript-eslint/no-invalid-this
    listener.call(this, e);
  };

  el.addEventListener(type, callback, options);
}
