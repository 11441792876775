import { defineActionTypes } from 'lib';

export const ActionTypes = defineActionTypes('app/org', {
  OpenUserAclSettings: '',
  CloseUserAclSettings: '',
  SetCurrentOrgUserAcl: '',
  SetCurrentOrgUserVisibleStores: '',
  OrgUserQrcodeAuth: '',
  OrgMemberQrcodeAuth: '',
});
