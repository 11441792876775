import { AuthorizationState } from 'app/integration/duck/states';
import { CustomMenuContext } from 'app/integration/helpers/context';
import { KnownUrl, KnownUrls } from 'app/integration/helpers/known-urls';
import classNames from 'classnames';
import { config } from 'config';
import { MpMenuItemView } from 'model';
import { FocusEvent, MouseEvent, PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { getString } from 'shared/components';
import { arr2group } from 'utils';

interface Props {
  editorId: number;
  parentIndex: number | undefined;
  index: number;
  url?: string;
  onMenuItemChange: <T = any>(
    editorId: number,
    path: number[],
    changes: T,
  ) => void;
}

export class ViewItemEditor extends PureComponent<Props> {
  render() {
    const { url } = this.props;
    const groups: Array<{ key: string; items: KnownUrl[] }> = arr2group(
      KnownUrls,
      x => x.group,
    );
    return (
      <CustomMenuContext.Consumer>
        {({ authorization }) => (
          <div className="view-item-editor">
            <div className="form-group">
              <label>
                <Translate id="integration.menu.label.url" />
              </label>
              <input
                type="url"
                className="form-control"
                key={url || ''}
                defaultValue={url || ''}
                onBlur={this.onUrlBlur}
              />
              <span className="form-text text-muted">
                <ReactMarkdown>
                  {getString('integration.menu.help_text.url')}
                </ReactMarkdown>
              </span>
            </div>
            <div className="form-group">
              {groups.map(group => (
                <div className="mp-known-urls__group" key={group.key}>
                  <h5 className="mp-known-urls__group-title">
                    <Translate
                      id={`integration.menu.known_urls.${group.key}.group_title`}
                    />
                  </h5>
                  <div className="mp-known-urls">
                    {group.items.map(item => {
                      const knownUrl = this.getKnownUrl(authorization, item);
                      return (
                        <dl
                          className={classNames('mp-known-url', {
                            'mp-known-url--selected': url === knownUrl,
                          })}
                          key={item.group + '.' + item.name}
                          data-url={knownUrl}
                          onClick={this.onKnownUrlClick}
                        >
                          <dt className="mp-known-url__title">
                            <Translate
                              id={`integration.menu.known_urls.${item.group}.${item.name}.title`}
                            />
                          </dt>
                          <dd className="mp-known-url__url">{knownUrl}</dd>
                          <dd className="mp-known-url__desc">
                            <Translate
                              id={`integration.menu.known_urls.${item.group}.${item.name}.desc`}
                            />
                          </dd>
                        </dl>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </CustomMenuContext.Consumer>
    );
  }

  onUrlBlur = (e: FocusEvent<HTMLInputElement>) => {
    const url = e.target.value.trim();
    if (url !== this.props.url) {
      this.props.onMenuItemChange<Partial<MpMenuItemView>>(
        this.props.editorId,
        this.getMenuItemPath(),
        { url },
      );
    }
  };

  onKnownUrlClick = (e: MouseEvent<HTMLElement>) => {
    const url = e.currentTarget.getAttribute('data-url')!;
    this.props.onMenuItemChange<Partial<MpMenuItemView>>(
      this.props.editorId,
      this.getMenuItemPath(),
      { url },
    );
  };

  private getMenuItemPath() {
    if (this.props.parentIndex !== undefined) {
      return [this.props.parentIndex, this.props.index];
    }
    return [this.props.index];
  }

  private getKnownUrl(
    authorization: AuthorizationState,
    url: KnownUrl,
  ): string {
    return (
      config.h5EndPoint.replace(
        '${authorizerAppId}',
        authorization.result!.authorizationInfo.authorizationAppid,
      ) + url.path
    );
  }
}
