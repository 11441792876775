/**
 * @file: ProductAuthorizationRequestStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ProductAuthorizationRequestStatus } from 'model';

export const ProductAuthorizationRequestStatusOptions: Array<
  Option<ProductAuthorizationRequestStatus>
> = [
  {
    value: ProductAuthorizationRequestStatus.Submitted,
    label: 'product_authorization_request_status.submitted',
  },
  {
    value: ProductAuthorizationRequestStatus.Accepted,
    label: 'product_authorization_request_status.accepted',
  },
  {
    value: ProductAuthorizationRequestStatus.Rejected,
    label: 'product_authorization_request_status.rejected',
  },
  {
    value: ProductAuthorizationRequestStatus.Canceled,
    label: 'product_authorization_request_status.canceled',
  },
];

export const ProductAuthorizationRequestStatusOptionsWithDefault: Array<
  Option<ProductAuthorizationRequestStatus | null>
> = [
  { value: null, label: 'product_authorization_request_status.__default__' },
  {
    value: ProductAuthorizationRequestStatus.Submitted,
    label: 'product_authorization_request_status.submitted',
  },
  {
    value: ProductAuthorizationRequestStatus.Accepted,
    label: 'product_authorization_request_status.accepted',
  },
  {
    value: ProductAuthorizationRequestStatus.Rejected,
    label: 'product_authorization_request_status.rejected',
  },
  {
    value: ProductAuthorizationRequestStatus.Canceled,
    label: 'product_authorization_request_status.canceled',
  },
];
