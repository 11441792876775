import { createAsyncActionCreators } from 'lib/duck/actions';
import { weixinService } from 'services';
import { AppState } from 'app/duck/states';
import { WeixinTemplateLibraryConf } from 'model';

export const weixinTemplateLibraryConfActions = createAsyncActionCreators<AppState, WeixinTemplateLibraryConf>(
  'integration.template_library_conf',
  {
    async fetchHandler() {
      return await weixinService.getSystemTemplateLibraryConf();
    }
  }
);