import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import { LinkButton } from 'app/inspection/quotation-template-config/LinkButton';
import { QuotationTemplateCategoryStaged } from 'model';
import { memo, useCallback, useMemo } from 'react';
import { getString } from 'shared/components';

export const WiredCategoryHeader = memo(
  ({
    category,
    index,
    canMoveUp,
    canMoveDown,
  }: {
    category: QuotationTemplateCategoryStaged;
    index: number;
    canMoveUp: boolean;
    canMoveDown: boolean;
  }) => {
    const {
      state,
      editable,
      onCategoryNameBlur,
      onCategoryNameKeyup,
      onToggleCategory,
      onEditCategory,
      onMoveCategory,
      onRemoveCategory,
    } = useQuotationTemplateConfigContext();

    const onToggleCategoryClick = useCallback(() => {
      onToggleCategory(category);
    }, [category, onToggleCategory]);

    const onEditCategoryClick = useCallback(() => {
      onEditCategory(category);
    }, [category, onEditCategory]);

    const onMoveCategoryUpClick = useCallback(() => {
      if (canMoveUp) {
        onMoveCategory(category, index, index - 1);
      }
    }, [canMoveUp, category, index, onMoveCategory]);

    const onMoveCategoryDownClick = useCallback(() => {
      if (canMoveDown) {
        onMoveCategory(category, index, index + 1);
      }
    }, [canMoveDown, category, index, onMoveCategory]);

    const onRemoveCategoryClick = useCallback(() => {
      onRemoveCategory(category);
    }, [category, onRemoveCategory]);

    const count = useMemo(() => {
      const subjects = category.groups.flatMap(g => g.subjects);
      return subjects.length;
    }, [category.groups]);

    return (
      <div className="quotation-tpl__category-hd">
        <i className={`fa fa-folder${category.expanded ? '-open' : ''}`} />
        {state.categoryNameBeingEdited != null &&
          state.categoryIdWhoseNameIsBeingEdited === category.id && (
            <input
              type="text"
              className="form-control form-control-sm m-input"
              placeholder={getString('quotation_tpl.category.placeholder.name')}
              defaultValue={state.categoryNameBeingEdited}
              onBlur={onCategoryNameBlur}
              onKeyUp={onCategoryNameKeyup}
              ref={c => {
                c?.focus();
                c?.select();
              }}
            />
          )}
        {state.categoryIdWhoseNameIsBeingEdited !== category.id && (
          <span className="quotation-tpl__category-name-ct">
            <LinkButton
              className="quotation-tpl__category-name"
              onClick={onToggleCategoryClick}
            >
              {category.name}{' '}
              <span
                style={{
                  color: '#aaa',
                }}
              >{`(${count})`}</span>
            </LinkButton>
            {editable && (
              <span className="quotation-tpl__category-actions">
                <LinkButton
                  className="quotation-tpl__category-name-edit"
                  onClick={onEditCategoryClick}
                >
                  <i className="la la-pencil" />
                </LinkButton>
                {canMoveUp && (
                  <LinkButton
                    className="quotation-tpl__category-move-up"
                    onClick={onMoveCategoryUpClick}
                  >
                    <i className="la la-arrow-up" />
                  </LinkButton>
                )}
                {canMoveDown && (
                  <LinkButton
                    className="quotation-tpl__category-move-down"
                    onClick={onMoveCategoryDownClick}
                  >
                    <i className="la la-arrow-down" />
                  </LinkButton>
                )}
                <LinkButton
                  className="quotation-tpl__category-remove"
                  onClick={onRemoveCategoryClick}
                >
                  <i className="la la-remove" />
                </LinkButton>
              </span>
            )}
          </span>
        )}
        <LinkButton onClick={onToggleCategoryClick}>
          <i className={`la la-angle-${category.expanded ? 'up' : 'down'}`} />
        </LinkButton>
      </div>
    );
  },
);
