import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { OrgWeixinTemplateConfs } from '../states';
import { OrgWeixinTemplateConf } from 'model';

const initialState: OrgWeixinTemplateConfs = {
  isLoading: false,
  error: null,
  result: null
};

export default createAsyncListActionReducers<OrgWeixinTemplateConf, OrgWeixinTemplateConfs, number>(
  'integration.templates.confs',
  initialState,
  {
    mapItemKey(conf) { return conf.id; }
  }
);