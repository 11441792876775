/**
 * @file: DataExportTaskOrigin.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum DataExportTaskOrigin {
  Boss = 'boss',
  Org = 'org',
  Store = 'store',
}

export const DataExportTaskOriginValueSet = new Set([
  DataExportTaskOrigin.Boss,
  DataExportTaskOrigin.Org,
  DataExportTaskOrigin.Store,
]);
