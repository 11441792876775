/**
 * @file: AutoPartNameService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { AutoPartNameListFilter, AutoPartName, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class AutoPartNameServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<AutoPartNameListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<AutoPartName> : AutoPartName[]> {
    return await this.api.get()
      .url('/auto-part-names', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<AutoPartName | null> {
    return await this.api.get()
      .url('/auto-part-names/:id', { id })
      .future();
  }

  async create(autoPartName: Partial<AutoPartName>): Promise<AutoPartName> {
    return await this.api.post()
      .url('/auto-part-names')
      .data(autoPartName)
      .future();
  }

  async update(autoPartName: Partial<AutoPartName>): Promise<AutoPartName> {
    return await this.api.put()
      .url('/auto-part-names/:id', { id: autoPartName.id })
      .data(autoPartName)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/auto-part-names/:id', { id })
      .future();
  }
}