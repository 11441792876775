import { Alert, Button } from 'lib/metronic/components';
import { memo, useCallback, useState } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { getString } from 'shared/components';

export const AsyncConfirmModal = memo(
  ({
    title,
    content,
    open = false,
    onConfirm,
    onCancel,
  }: {
    title: string;
    content: string;
    open?: boolean;
    onConfirm?: () => any;
    onCancel?: () => void;
  }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
    const [closable, setCloseable] = useState(true);

    const handleConfirm = useCallback(async () => {
      const ret = onConfirm?.();
      setError(undefined);
      if (ret?.then != null) {
        setLoading(true);
        setCloseable(false);
        try {
          await ret;
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
          setCloseable(true);
        }
      }
    }, [onConfirm, setLoading]);

    return (
      <Modal
        isOpen={closable ? open : true}
        toggle={closable ? onCancel : undefined}
      >
        <ModalHeader>
          <Translate id={title} />
        </ModalHeader>
        <ModalBody>
          {error != null && (
            <Alert color="danger" style={{ marginBottom: '1rem' }}>
              {error.message}
            </Alert>
          )}
          <ReactMarkdown>{getString(content)}</ReactMarkdown>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" disabled={!closable} onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button
            color="primary"
            spinner={loading}
            disabled={loading}
            onClick={handleConfirm}
          >
            <Translate id="yes_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
