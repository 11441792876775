/**
 * @file: computeChainRelativeDateRange.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { DateRange } from 'model';
import moment from 'moment';

export function computeChainRelativeDateRange(dateRange: DateRange): DateRange {
  let { startDate, endDate } = dateRange;
  const days = moment
    .duration(moment(endDate).diff(moment(startDate)))
    .asDays();
  endDate = moment(startDate).add(-1, 'd').format('YYYY-MM-DD');
  startDate = moment(endDate).add(-days, 'd').format('YYYY-MM-DD');
  return { startDate, endDate };
}
