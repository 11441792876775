import {
  OfficialAccountCertificateType,
  OfficialAccountGrantedPermissionType,
  OfficialAccountType,
} from 'model/enum';

export enum WeixinOpenAuthorizeUrlType {
  Qrcode = 'qrcode',
  Link = 'link',
}

export enum WeixinOpenAuthType {
  MpOnly = 1,
  WeappOnly = 2,
  MpAndWeapp = 3,
}

export interface WeixinOpenAuthorizationFuncInfo {
  funcscopeCategory: { id: OfficialAccountGrantedPermissionType };
}

export interface WeixinOpenAuthorizationInfo {
  authorizerAppid: string;
  authorizerAccessToken: string;
  expiresIn: number;
  authorizerRefreshToken: string;
  funcInfo: WeixinOpenAuthorizationFuncInfo[];
}

export interface WeixinOpenAuthorizerInfo {
  nickName: string;
  headImg: string;
  serviceTypeInfo: { id: OfficialAccountType };
  verifyTypeInfo: { id: OfficialAccountCertificateType };
  userName: string;
  principalName: string;
  businessInfo: {
    openStore: number;
    openScan: number;
    openPay: number;
    openCard: number;
    openShake: number;
  };
  qrcodeUrl: string;
  authorizationInfo: {
    authorizationAppid: string;
    funcInfo: WeixinOpenAuthorizationFuncInfo[];
  };
}

export interface WeixinOpenMpAuthorizerInfo extends WeixinOpenAuthorizerInfo {
  alias: string;
}

export interface WeixinOpenWeappAuthorizerInfo
  extends WeixinOpenAuthorizerInfo {
  signature: string;
  miniprograminfo: any;
}

export interface WeixinOpenAuthorizedInfo {
  authorizerInfo: WeixinOpenAuthorizerInfo;
  authorizationInfo: {
    authorizationAppid: string;
    funcInfo: WeixinOpenAuthorizationFuncInfo[];
    authorizedAt?: string | Date | number;
  };
}

export interface LinkedMiniProgramFuncInfo {
  id: number;
  status: number;
  name: string;
}

export enum LinkedMiniProgramStatus {
  Linked = 1,
  WaitingForAcceptByMiniProgramAdmin = 2,
  Rejected = 3,
  WaitingForAcceptByMpAdmin = 12,
}

export interface LinkedMiniProgramInfo {
  nickname: string;
  headimgUrl: string;
  status: LinkedMiniProgramStatus;
  appid: string;
  source: string;
  funcInfos: LinkedMiniProgramFuncInfo[];
  selected: number;
  username: string;
  email: string;
  nearbyDisplayStatus: number;
  copyVerifyStatus: number;
  release: number;
}
